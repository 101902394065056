import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationState } from '../shared/interface/workspace-alert.interface';
import { AlertRule, AlertRuleResponse, displaySelection, LocationMaterialRuleResponse, RuleCategory, userSubscription } from '../shared/interface/settings.interface';
import { ruleExpression, ruleFields, ruleTypesNom, ruleTypesRP } from '../shared/constants/rule.constants';
import { AlertSaveGuardService } from './alert-save-guard.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public restrictLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sidePanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sidePanelOpenCondition$: BehaviorSubject<NotificationState> = new BehaviorSubject<NotificationState>(NotificationState.UserOpen);
  public sidePanelData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private updateLocation = new BehaviorSubject('')
  private updateMaterial = new BehaviorSubject('')
  private updateRuleName = new BehaviorSubject('')
  private updateEnableEdit = new BehaviorSubject<boolean>(true);
  private createAlertPopup = new BehaviorSubject<boolean>(false);

  currentLocation = this.updateLocation.asObservable()
  currentMaterial = this.updateMaterial.asObservable()
  currentRuleName = this.updateRuleName.asObservable()
  currentEditStatus = this.updateEnableEdit.asObservable()

  //RAN+ refactor logic for various rule logic
  locMaterialRules: Map<string, Map<string, AlertRule[]>> = new Map<string, Map<string, AlertRule[]>>();
  displayMaterialRules: BehaviorSubject<Map<string, Map<string, AlertRule[]>>> = new BehaviorSubject<Map<string, Map<string, AlertRule[]>>>(new Map<string, Map<string, AlertRule[]>>); 

  selectedRule: BehaviorSubject<AlertRule | null> = new BehaviorSubject<AlertRule | null>(null);
  showDeletedRules: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ruleCategory: BehaviorSubject<RuleCategory>  = new BehaviorSubject<RuleCategory>(RuleCategory.NONE); //used for rule creation/edit modal

  constructor(private apiService: ApiService) { }
  
  getTankParams({payload}: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetTankDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }

  getTankParamsNonDeconstructed(payload: any): Observable<any> {
    return this.apiService
      .post(
        `${environment.mdmAPI}/GetTankDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => of({ value: { data: [] }}))
      )
  }


  updateTankParams({payload}: any): Observable<any> {
    return this.apiService
      .put(
        `${environment.mdmAPI}/UpdateTankDetails`,
        'mdm',
        payload,
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  createAlert(alert: any) {

    return this.apiService
      .post(
        `${environment.alertAPI}/CreateRule`,
        'alert', alert
      )
      .pipe(
        map((response: any) => {
          return response
        })
      )
  };

  getWorkspaceAlert(payload: any): Observable<any>{
    
    return this.apiService
      .get(
        `${environment.alertAPI}/GetWorkspaceAlert/?fromdate=${payload.date}&user=${payload.user}`,
        'alert',
      ).pipe(
        map((data) => {
          return data;
        })
      )
  }


  dismissWorkspaceAlert(payload: any): Observable<any>{

    return this.apiService
      .put(
        `${environment.alertAPI}/DimissWorkspaceAlert/?id=${payload.id}&user=${payload.user}&isActive=${payload.isActive}`,
        'alert',
    ).pipe(
      map((response) => {
        return response;
      })
    );

  }

  getAlertRules(): Observable<any> {
    return this.apiService
      .get(
        `${environment.alertAPI}/GetRules`,
        'alert',
      )
      .pipe(
        map((data) => {
          return data;
        })
      )
  };

  updateAlert(alert: any) {
    return this.apiService
    .put(
      `${environment.alertAPI}/UpdateRule`,
      'alert', alert
    )
    .pipe(
      map((response: any) => {
        return response
      })
    )
  };

  deleteAlert(alert: any) {
    return this.apiService
    .trueDelete(
      `${environment.alertAPI}/deleteRule?id=${alert.ruleId}&type=${alert.ruleType}&isActive=${alert.isActive}`,'alert'
    )
    .pipe(
      map((response: any) => {
        return response
      })
    )
  };

  changeLocation(location: string) {
    this.updateLocation.next(location)
  };

  changeMaterial(material: string) {
    this.updateMaterial.next(material)
  }

  changeRuleName(ruleName: string) {
    this.updateRuleName.next(ruleName)
  }

  changeEditStatus(data: any){
    this.updateEnableEdit.next(data);
  }

  openSidePanel(openFrom: NotificationState, alertsToFocusOn: AlertRuleResponse[], openSidePanel: boolean){

    /*If we are using a non-trivial open strategy, 
    we will assign the alerts the side panel should provide interest in */
    if(openFrom === NotificationState.TR){
      this.sidePanelData$.next(alertsToFocusOn);  
    }

    //Let the side panel know what opened it for conditional setup, and if we need to adjust it  
    this.sidePanelOpenCondition$.next(openFrom);

    if(this.sidePanel$.value === false){
      this.sidePanel$.next(openSidePanel);
    }
  }

  getDeskStringSplit(){
    /* There are 3 variations so far: 
    *  1. Single style (ex: panama)
    *  2. Double Style (ex: ethanol + usec)
    *  3. Triple Style (ex: clean products + usec + florida desk)
    */
    return localStorage.getItem("desk")!.split(" + ");
  }

  getDeskTag(){
    //in each case, the first word has been the first instance of the string options
    if(this.getDeskStringSplit()[0].toLowerCase().includes('panama')){
      return "latam";
    }else if(this.getDeskStringSplit()[0].toLowerCase().includes('clean products')){
      return "pipeline";
    }
    return this.getDeskStringSplit()[0];
  }
  
  //NICKNAME IS USED TO MAKE API CALL FOR THE PIPELINE DATA THAT BACKEND NEEDS. AS SUCH, IT IS THE pl + DESK_PARAM NAME
  getDeskNickname(){
    let compareValue = this.getDeskStringSplit();
    if(compareValue.includes("clean products")){
      return "pl"+ localStorage.getItem("deskParam");
    }else if(compareValue.includes("ethanol")){
      return compareValue[1];
    }else if(compareValue.includes("panama")){
      return compareValue[0];
    }
    return null;       
  }

  //REGION IS USWC OR USEC, WILL MOSTLY APPLY TO PIPELINE, BUT INCLUDE FOR ETHANOL BECUASE WHY NOT TO BE HONEST
  getDeskRegion(){
    if(localStorage.getItem("desk")!.toLowerCase().includes("clean products") || localStorage.getItem("desk")!.includes("ethanol")){
      if(localStorage.getItem("desk")!.includes("usec")){
        return "usec"
      }else{
        return "uswc"
      }
    }else if(localStorage.getItem("desk")!.includes("panama")){
      return "panama";
    }
    return null;
  }
  getDesk(){
    let splitList = this.getDeskStringSplit();
    if(splitList.length === 1){
      return splitList[0]; 
    }else if(splitList.length === 2){
      return splitList[1];
    }else{
      return splitList[2].replace(' desk', "");
    }
  }

  setIsCreateAlertsPopupOpen(data: any) {
    this.createAlertPopup.next(data);
  }
  getIsCreateAlertsPopupOpen(): Observable<any> {
    return this.createAlertPopup.asObservable();
  }

  isUserSubscribedToRule(rule: AlertRule): boolean{
    let boolean =  rule.subscription.users.map(user => user.userEmail).includes(localStorage.getItem("userEmail")!);
    return boolean;
  }

  setModifyCategory(ruleType: string): void{

    if(ruleTypesRP.map((x: any) => x.actualValue).includes(ruleType)){
      this.ruleCategory.next(RuleCategory.RP);
    }else if(ruleTypesNom.map((x: any) => x.actualValue).includes(ruleType)){
      this.ruleCategory.next(RuleCategory.NOM);
    }else{
      this.ruleCategory.next(RuleCategory.NONE);
    }
  }


  //RAN+ START

  generateMap(){

    this.getAlertRules().subscribe({
      next: (response: any) => {

        let ruleMap: Map<string, Map<string, AlertRule[]>> = new Map<string, Map<string, AlertRule[]>>();

        for(let location of response.result){
          let materialMap = new Map<string, AlertRule[]>();

          if(!ruleMap.has(location.location)){
            ruleMap.set(location.location, new Map<string, AlertRule[]>);
          }

          for(let material of location.locationMaterials){

              if(!materialMap.has(material.material)){
                  materialMap.set(material.material, material.locationMaterialRules);
                }
          }
          ruleMap.set(location.location, materialMap);
        }      
        this.locMaterialRules = ruleMap;
      },
      error: (error: any) => {},
      complete: () => {
      }
    }) 
  }


  getLocationMaterialsForCurrentDesk(){

    let currentDesk =  parseInt(localStorage.getItem("deskId")!);

    this.locMaterialRules.forEach((innerMap, outerKey) => {
      const filteredInnerMap: Map<string, AlertRule[]> = new Map();
  
      innerMap.forEach((valueArray, innerKey) => {
          const filteredValues: AlertRule[] = valueArray.filter(value => value.deskId === currentDesk && value.isActive === !this.showDeletedRules.value);
          if (filteredValues.length > 0) {
              filteredInnerMap.set(innerKey, filteredValues);
          }else{
            filteredInnerMap.delete(innerKey);
          }
      });
  
      if (filteredInnerMap.size > 0) {
        this.displayMaterialRules.next(this.displayMaterialRules.value.set(outerKey, filteredInnerMap))
      }else{

        let map = this.displayMaterialRules.value;
        map.delete(outerKey);
        this.displayMaterialRules.next(map)
      }
    });
  }


  filterLocationMaterialRules(locationList: string[], materialList: string[], typeList: string[]){

    let currentDesk =  parseInt(localStorage.getItem("deskId")!);

    this.locMaterialRules.forEach((innerMap, outerKey) => {
      const filteredInnerMap: Map<string, AlertRule[]> = new Map();
  
      innerMap.forEach((valueArray, innerKey) => {
          const filteredValues: AlertRule[] = valueArray.filter(value => 
                value.deskId === currentDesk && value.isActive === !this.showDeletedRules.value
                && locationList.includes(value.location) && materialList.includes(value.materialNumber!)
                && typeList.includes(value.ruleType)
            );
          if (filteredValues.length > 0) {
              filteredInnerMap.set(innerKey, filteredValues);
          }else{
            filteredInnerMap.delete(innerKey);
          }
      });
  
      if (filteredInnerMap.size > 0) {
        this.displayMaterialRules.next(this.displayMaterialRules.value.set(outerKey, filteredInnerMap))
      }else{

        let map = this.displayMaterialRules.value;
        map.delete(outerKey);
        this.displayMaterialRules.next(map)
      }
    });

    console.log("THE DISPLAY IS ", this.displayMaterialRules.value)

    // this.changeDetector.detectChanges();
  }



  updateFrontEnd(event: AlertRule, isCreate: boolean){

    if(isCreate){

      let ruleList = this.locMaterialRules.get(event.location)?.get(event.material) == null ? 
        [] :
        this.locMaterialRules.get(event.location)?.get(event.material)!;

      ruleList?.push(event);
      this.locMaterialRules.get(event.location)?.set(event.material, ruleList);
    }else{

      let ruleList = this.locMaterialRules.get(event.location)?.get(event.material);

      //If the particular location material combination does not exist, we have hit a logical fallacy and display toast
      if(ruleList == null){
        //this.toast.setToastNotification("error", "no rules for the location material combination");
        return;
      }
  
      //update the rule in the list with the rule with isActive false
      ruleList[ruleList.map(rule => rule.id).indexOf(event.id)] = event;
  
      //now update the list in the actual map structure 
      let innerMaterialMap = this.locMaterialRules.get(event.location)!;
      innerMaterialMap?.set(event.material, ruleList);
      this.locMaterialRules.set(event.location, innerMaterialMap);


    }

    this.getLocationMaterialsForCurrentDesk()


  }



  generateEmptyRuleStructure(): AlertRule{

    let newRule: AlertRule = {

      ruleName: '',
      ruleDescription: '',
      desk: this.getDesk(),
      deskTag: this.getDeskTag(),
      deskNickName: this.getDeskNickname(),
      deskRegion: this.getDeskRegion(),
      deskId: parseInt(localStorage.getItem("deskId")!),
      materialGroupNumber: '',
      materialNumber: '',
      location: '',
      material: '',
      ruleType: '',
      alertFrequency: '',
      subscription: {
        users: []
      },
      expression: '',
      createdBy: localStorage.getItem("userName")!,
      createdDate: new Date().toISOString(),
      isActive: true,
    }

    return newRule;
  }


  prepareRuleForUpdateSave(rule: AlertRule, isEditingRule: boolean): AlertRule{


    //if user is editing the rule, we update the updated by person and date
    if(isEditingRule){

      //editing impacts the updated value
      rule.updatedBy = localStorage.getItem("userName")!;
      rule.updatedDate = new Date().toISOString();


    }else{
      
      
      //creating requires created Date
      rule.createdBy = localStorage.getItem("userName")!,
      rule.createdDate = new Date().toISOString();
      rule = this.addUserToList(rule);
    }

    rule.ruleDescription = this.generateDescription(rule);

    return rule;
  }


  addUserToList(rule: AlertRule): AlertRule{

    const user: userSubscription = {
      userEmail: localStorage.getItem('userEmail')!,
      isEmail: true,
      isTeams: true,
      isWorkspace: true,
      isActive: true,
    }

    if(rule.subscription.users == null){
      rule.subscription.users = [];
    }
   
    rule.subscription.users.push(user);

    return rule;
  }

  checkForValidFields(rule: AlertRule): boolean{  

    const sharedFieldsToCheck = ruleFields.get('sharedFields');
    const uniqueFieldsToCheck = ruleFields.get(rule.ruleType);

    if(sharedFieldsToCheck == null || uniqueFieldsToCheck == null){
      return false;
    }

    let isValid = true;

    sharedFieldsToCheck.forEach((field: string) => {
      const split = field.split(';');

      if(split.length == 1 && (rule[field] == null || rule[field] == '')){
        isValid = false;  

      }else if(split.length != 1 && (rule[split[0]] == null || rule[split[0]][split[1]] == null || rule[split[0]][split[1]] == '')){
        isValid = false;
      }
    })

    uniqueFieldsToCheck.forEach((field: string) => {

      const split = field.split(';');

      if(split.length == 1 && (rule[field] == null || rule[field] == '')){
        isValid = false;

      }else if(split.length != 1 && (rule[split[0]] == null || rule[split[0]][split[1]] == null || rule[split[0]][split[1]] == '')){
        isValid = false;
      }
    })

    return isValid;
  }

  resetUnusedFields(rule: AlertRule): AlertRule{

    const fieldsToReset = ruleFields.get(rule.ruleType);

    //if no fields to reset, just reset the rule
    if(fieldsToReset == null){
      return rule;
    } 
    fieldsToReset.forEach((field: string) => {

      let split = field.split(';');

      if(split.length == 1){
        rule[split[0]] = null

      }else if(split.length != 1 && rule[split[0]] != null && rule[split[0]][split[1]] != null){
        rule[split[0]][split[1]] = null
      }
    })
    return rule;
  }

  isDuplicate(rule: AlertRule): boolean{

    if(!this.locMaterialRules.has(rule.location) || !this.locMaterialRules.get(rule.location)!.has(rule.material)
        || this.matchesAnotherRule(this.locMaterialRules.get(rule.location)?.get(rule.material)!, rule) 
    ){
      return true;
    }

    return false;
  }

  getIdOfDuplicate(rule: AlertRule): string{

    let id = '-1';

    let compareList = this.locMaterialRules.get(rule.location)?.get(rule.material)!;
    let fieldsToCompare = [...ruleFields.get(rule.ruleType)!];
    fieldsToCompare.push('alertFrequency');

    compareList.forEach(ruleToCompare => {

      let matchArray = fieldsToCompare.map(field => {

        let firstValue: any = '';
        let secondValue: any = '';


        if(field.includes(';')){
          let split = field.split(';');
          firstValue = rule[split[0]][split[1]];
          secondValue = ruleToCompare[split[0]][split[1]];
        }else{
          firstValue = rule[field];
          secondValue = ruleToCompare[field];
        }

        return firstValue == secondValue
      })

      if(!matchArray.includes(false)){
        id = ruleToCompare.id!;
      }
    })
  return id;
  }



  matchesAnotherRule(listToView: AlertRule[], rule: AlertRule){

    let filteredList = listToView.filter(rule => rule.ruleType == rule.ruleType);

    let fields = [...ruleFields.get(rule.ruleType)!];
    fields.push('alertFrequency');

    let matches: boolean = false;
   
    filteredList.forEach(ruleToCompare => {

      let matchArray = fields.map(field => {

        let firstValue: any = '';
        let secondValue: any = '';


        if(field.includes(';')){
          let split = field.split(';');
          firstValue = rule[split[0]][split[1]];
          secondValue = ruleToCompare[split[0]][split[1]];
        }else{
          firstValue = rule[field];
          secondValue = ruleToCompare[field];
        }

        return firstValue == secondValue
      })

      if(!matchArray.includes(false)){
        matches = true;
      }
    })

    return matches;
  }

  generateDescription(rule: AlertRule): string{

    let expressionString = ruleExpression.get(rule.ruleType);

    let replacedvalueString = expressionString?.replace(/\${(.*?)}/g, (_, field) => {

      if(field.includes(';')){
        let split = field.split(';')
        return rule[split[0]][split[1]];
      }else{
        return rule[field];
      }     
    });
    return replacedvalueString!;
  }
}


