<div class="cb-global-search" #searchContainer [class.global-search-bar-enabled]="showCBGlobalSearchInput">
  <div class="cb-global-search-container">
    <span class="global-search-icon" [ngClass]="{'global-search-icon-blue-bg': showCBGlobalSearchInput && cbGlobalSearchText?.trim()?.length }" (click)="openCBSearchBarAndSearch()">
    </span>
    <input type="text" class="global-search-bar" [(ngModel)]="cbGlobalSearchText" placeholder="deal/contract number" [class.show]="showCBGlobalSearchInput" (keyup.enter)="openCBSearchBarAndSearch()" (keyup)="checkCbGlobalSearchTextLength()" #cbGlobalSearchInput/>
  </div>
  @if (searchResults.length > 1) {
    <div class="results-container">
      @for (result of searchResults; track result) {
        <div class="results-container__results-list" (click)="navigateToPopout(result?.dealContractId)">
          <span class="dealno-contract-no">{{result?.dealNoContractNo}}</span>
          <span class="deal-contract-type" [ngClass]="{'buy-deal-type-bg': result?.dealContractType?.toLowerCase() === 'buy', 'sell-deal-type-bg' : result?.dealContractType?.toLowerCase() === 'sell' }">{{result?.dealContractType}}</span>
        </div>
      }
    </div>
  }
</div>