import { Action, createReducer, on } from "@ngrx/store";
import { loadDynamicDealDetailDataFailure, loadDynamicDealDetailDataSuccess, updateIndicatorDealDetails } from "../action/dynamicdeal-detail.action";


export interface State {
    cbDynamicDealDetailData: any;
    selectedIndicators:any;
    dealContractId:any;
    error: any;
}

export const initialState: State = {
    cbDynamicDealDetailData: {},
    selectedIndicators:[],
    dealContractId:"",
    error: null
};

 const cbDynamicDealDetailReducer = createReducer(
    initialState,
    on(loadDynamicDealDetailDataSuccess, (state, {cbDynamicDealDetailData}) => ({
        ...state,
        cbDynamicDealDetailData:cbDynamicDealDetailData,
        selectedIndicators: cbDynamicDealDetailData.udmIndicators,
        dealContractId: cbDynamicDealDetailData.dealContractId
    })),
    on(loadDynamicDealDetailDataFailure, (state, {error}) => ({
        ...state,
        error:error
    })),
    on(updateIndicatorDealDetails, (state, {payLoad}) => {
        return {...state,
        cbDynamicDealDetailData: {
            ...state.cbDynamicDealDetailData,
            udmIndicators : payLoad.udmIndicators
        }
      }
    }
)
) 

export function dynamicDealDetailReducer(state: State | undefined,action: Action){
    return cbDynamicDealDetailReducer(state,action);
}



