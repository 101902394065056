<div class="create-ethanol-nomination">


  <form class="branded" [formGroup]="createTerminalNominationForm">
    <span class="label">mode of transportation</span>
    <div for="mode-of-trans" class="form-row">
      @for (mot of motOptions; track mot; let i = $index) {
        <div
          class="radio-container mb-0 d-flex align-items-center form-group branded">
          <input type="radio" name="mot" id="radio-{{i}}" [value]="mot?.name" tabindex="0" [checked]="mot?.checked === true"
            (change)="motUpdateFormFields(mot?.name)">
            <label  class="label" id="radio-{{i}}" (keydown.enter)="motUpdateFormFields(mot?.name)" tabindex="0">{{mot?.name}}</label>
          </div>
        }
      </div>
      <div class="input-wrap">
        <div class="form-row pb-1 pr-2 d-flex">
          <div class="form-group col-md-9">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="nominationNo" class="label"> <span class="astric">* </span> nomination name/id</label>
                <input type="text" class="form-control" formControlName="nominationNo" placeholder="Type here">
                @if (createTerminalNominationForm.controls['nominationNo'].invalid && (createTerminalNominationForm.controls['nominationNo'].dirty || createTerminalNominationForm.controls['nominationNo'].touched)) {
                  <div class="astric"
                    >
                  Nomination no. limits to 16 characters</div>
                }
              </div>

              <div class="form-group col-md-4">
                <label for="transport-sys" class="label"> <span class="astric">* </span> transport system </label>
                <select class="form-control" (change) = 'onTransportSystemChange()' formControlName="transportSystem" id="transport-sys">
                  <option value="">select</option>
                  @for (transportSystem of transportSystemDropdown; track transportSystem) {
                    <option [value]="transportSystem?.value">
                    {{ transportSystem?.name }}</option>
                  }
                </select>
              </div>

              @if (modeOfTransport === 'truck') {
                <div class="form-group col-md-4">
                  <label for="transport-sys" class="label"> <span class="astric">* </span> truck time frame options </label>
                  <select class="form-control" (change)="updateTruckTimeFrame()"  formControlName="udmTimeFrameOption" id="transport-sys">
                    <option value="">select</option>
                    @for (timeFrame of this.modalPopUpRefData?.TimeFrameOption; track timeFrame) {
                      <option [value]="timeFrame">
                      {{ timeFrame }}</option>
                    }
                  </select>
                </div>
              }


              <div class="form-group col-md-4">
                <label for="supplier" class="label"> supplier </label>
                <app-select-dropdown tabindex="0" #SupplierSelectDropdownComponent [items]="supplierDropdown" formControlName="supplier"
                  id="supplier" [defaultValue]="supplierReferenceData">
                </app-select-dropdown>
              </div>

              <div class="form-group col-md-4">
                <label for="carrier" class="label"> carrier </label>
                <app-select-dropdown tabindex="0" #CarrierSelectDropdownComponent [items]="carrierDropdown" formControlName="carrier"
                  id="carrier" [defaultValue]="carrierReferenceData">
                </app-select-dropdown>
              </div>
              <div class="form-group col-md-4">
                <label for="carrier" class="label"> customer </label>
                <app-select-dropdown #CustomerSelectDropdownComponent [items]="customerDropdown" formControlName="customer"
                  id="customer" [defaultValue]="customerReferenceData" (afterChange)="onValueChange($event)">
                </app-select-dropdown>
                <div class="mt-2">
                  @if (showCustomerWarning) {
                    <span class="text-danger">Note: customer information is only  applicable for sale description </span>
                  }
                </div>
              </div>
              @if (modeOfTransport === 'barge') {
                <div class="form-group col-md-4">
                  <label for="barge-name" class="label">  barge name
                  </label>
                  <app-select-dropdown #BargeNameSelectDropdownComponent [items]="bargeSelectDropdown"
                    [defaultValue]="bargeNameReferenceData" formControlName="bargeName" id="barge">
                  </app-select-dropdown>
                </div>
              }
              <div class="form-group col-md-4">
                <label for="btCustodyChain" class="label">
                  bt custody chain
                </label>
                <input type="text" formControlName="btCustodyChain" class="form-control"
                  id="btCustodyChain">
                </div>
              </div>
            </div>
            <div class="form-group col-md-3">
              <label htmlFor ="nominationNotes" class="label">nomination notes</label>
              <textarea tabindex="0" class="form-control nomNotes" formControlName="nominationNotes" maxlength="120" rows="3"></textarea>
            </div>
          </div>
          <div class="form-row col-md-12 pb-1 pr-2 align-items-center">
            <div class="form-group col-md-3 ">
              <input type="checkbox" #isAdditional [(ngModel)] = 'addCheckbox' (keydown.enter)="toggleAddi()" (change)="onAddititonalData(isAdditional)"
                formControlName="selectMultipleCheckbox1">
                <label class="ml-2 " for="selectMultipleCheckbox1">additional information</label>
              </div>
            </div>
          </div>

          <ng-container formArrayName="transport">
            @for (transportControl of transportArray().controls; track transportControl; let i = $index) {
              <ng-container [formGroupName]="i">
                <div class="table-wrapper">
                  <div>
                    @if (i===0) {
                      <div>
                        @if (transPortDropDownValue !== 'ite' && transPortDropDownValue !== 'itw') {
                          <span class="label table-type">origin</span>
                        }
                        <table aria-describedby="origin">
                          <tr>
                            <th>Confirm</th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                            } description</th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                            } rdi</th>
                            <th><span class="text-danger"></span> TSW Status</th>
                            @if (!showTruckMonthly) {
                              <th>@if (isOriginRequired[i]) {
                                <span class="text-danger">* </span>
                              } scheduled date</th>
                            }
                            @if (showTruckMonthly) {
                              <th>@if (isOriginRequired[i]) {
                                <span class="text-danger">* </span>
                              } scheduled month</th>
                            }
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                            } location</th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                              } scheduled Product
                            </th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                              } demand product
                            </th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                            } qty(bbl)</th>
                            <th>sap contract</th>
                            <th>contract line no.</th>
                            @if (isAdditionalData) {
                              <th>batch origin location</th>
                            }
                            @if (isAdditionalData) {
                              <th>valuation type origin location</th>
                            }
                            @if (isAdditionalData) {
                              <th>batch destination location</th>
                            }
                            @if (isAdditionalData) {
                              <th>valuation type destination location</th>
                            }
                          </tr>
                          <ng-container formArrayName="origin">
                            @for (list of origin(i).controls; track list; let j = $index) {
                              <ng-container [formGroupName]="j">
                                <tr>
                                  <td><input type="checkbox" class="form-control text-field cell-form-checkbox"
                                  formControlName="confirmationIndicator"></td>
                                  <td>
                                    <div class="statwidth">
                                      <select class="form-control" formControlName="nominationType"
                                        (change)="nominationTypeDesc(i,j)">
                                        <option value="">select</option>
                                        @for (nominationTypeDetails of originNominationTypeDropdown; track nominationTypeDetails) {
                                          <option
                                            [ngValue]="nominationTypeDetails?.name">
                                          {{ nominationTypeDetails?.name }}</option>
                                        }
                                      </select>
                                    </div>
                                  </td>
                                  <td class="statwidth">
                                    <select class="form-control rdi-select" formControlName="rdi" (change)="nominationTypeRdi(i,j)">
                                      <option value="">select</option>
                                      @for (nominationTypeDetails of originNominationTypeDropdown; track nominationTypeDetails) {
                                        <option
                                          [ngValue]="nominationTypeDetails?.referenceDocumentIndicator">
                                        {{ nominationTypeDetails?.referenceDocumentIndicator }}</option>
                                      }
                                    </select>
                                  </td>
                                  <td><input  type="number" class="form-control text-field"
                                    formControlName="tswStatus" readonly>
                                  </td>
                                  @if (!showTruckMonthly) {
                                    <td class="statwidth"><input type="date" class="form-control text-field date-field"
                                    formControlName="scheduledDate"></td>
                                  }
                                  @if (showTruckMonthly) {
                                    <td class="statwidth"><input type="month" class="form-control text-field date-field"
                                    formControlName="scheduledDate"></td>
                                  }
                                  <td class="statwidth">
                                    <app-select-dropdown class="location-dropdown"  #OriginSelectDropdownComponent [items]="destinationLocationDropdown"
                                      [defaultValue]="locationOriginArray[i][j]" formControlName="originLocation" id="origin-loc"
                                      (afterChange)="onLocationValueChange($event)">
                                    </app-select-dropdown>
                                  </td>
                                  <td class="statwidth">
                                    <select class="form-control" formControlName="scheduledMaterial">
                                      <option value="">select</option>
                                      @for (productDetails of productsDropdown; track productDetails) {
                                        <option [ngValue]="productDetails?.value">
                                        {{ productDetails?.name }}</option>
                                      }
                                    </select>
                                  </td>
                                  <td class="statwidth">
                                    <select class="form-control" formControlName="demandMaterial">
                                      <option value="">select</option>
                                      @for (productDetails of productsDropdown; track productDetails) {
                                        <option [ngValue]="productDetails?.value">
                                        {{ productDetails?.name }}</option>
                                      }
                                    </select>
                                  </td>
                                  <td class="statwidth"><input type="text" [class.readonly] ='isDisableQTY' class="form-control text-field"
                                    formControlName="scheduledQty" (keyup)="checkQTY(i,j)">
                                    @if ((origin(i).length - 1) == j) {
                                      <span class="qty-row">sum {{originQtyValue[i]}}</span>
                                    }
                                  </td>
                                  <td><input type="text" class="form-control text-field" formControlName="sapcontract"></td>
                                  <td><input type="text" class="form-control text-field" formControlName="contractLineNo" (input)="onContractLineChange($event,j,i,'origin');"></td>
                                  @if (isAdditionalData) {
                                    <td><input type="text" class="form-control text-field"
                                    formControlName="batchOriginLocation"></td>
                                  }
                                  @if (isAdditionalData) {
                                    <td><input type="text" class="form-control text-field"
                                    formControlName="valuationTypeOriginLoc"></td>
                                  }
                                  @if (isAdditionalData) {
                                    <td><input type="text" class="form-control text-field"
                                    formControlName="batchDestinationLocation"></td>
                                  }
                                  @if (isAdditionalData) {
                                    <td><input type="text" class="form-control text-field"
                                    formControlName="valuationTypeDestinationLoc"></td>
                                  }
                                  @if (modeOfTransport !== 'truck') {
                                    <td>
                                      <div class="custom-dropdown" tabindex="0">
                                        <button class="icon-btn action-icon action-button"></button>
                                        <div class="dropdown-menu-wrap">
                                          <div class="dropdown-menu">
                                            <button class="dropdown-item action-btn-item"
                                              (click)="copyOriginRow(i,j)">duplicate row
                                            </button>
                                            @if (isMultipleRowItemOrigin[i]?.length>1) {
                                              <button
                                                class="dropdown-item action-btn-item text-danger"
                                                (click)="deleteOriginRow(i,j)">delete
                                              </button>
                                            }
                                            <button class="dropdown-item action-btn-item text-act-btn"
                                              (click)="clearOriginRow(i,j)">clear all fields
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  }
                                </tr>
                              </ng-container>
                            }
                            <tr class="border-left-hidden">
                              <td style="white-space: nowrap;">
                                {{originMovementsValue[i]}} Movements
                              </td>
                              @if (!isAdditionalData) {
                                <td colspan="10"></td>
                              }
                              @if (isAdditionalData) {
                                <td colspan="14"></td>
                              }
                            </tr>
                          </ng-container>
                        </table>
                        <div class="my-3 d-flex">
                          <button class="button button-text" [class.disabled] = 'disableAddLocation' (click)="addOriginRow(i)">
                            add location
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                  @if (hideDestination) {
                    <div>
                      @if (transPortDropDownValue !== 'ite' && transPortDropDownValue !== 'itw') {
                        <div>
                          <span class="label table-type">destination</span>
                          <table aria-describedby="destination">
                            <tr>
                              <th>Confirm</th>
                              <th>@if (isDestinationRequired[i]) {
                                <span class="text-danger">* </span>
                              } description</th>
                              <th>@if (isDestinationRequired[i]) {
                                <span class="text-danger">* </span>
                              } rdi</th>
                              <th><span class="text-danger"></span> TSW Status</th>
                              @if (!showTruckMonthly ) {
                                <th >@if (isDestinationRequired[i]) {
                                  <span class="text-danger">* </span>
                                } scheduled date</th>
                              }
                              @if (showTruckMonthly) {
                                <th >@if (isDestinationRequired[i]) {
                                  <span class="text-danger">* </span>
                                } scheduled month</th>
                              }
                              <th>@if (isDestinationRequired[i]) {
                                <span class="text-danger">* </span>
                                } location
                              </th>
                              <th>@if (isDestinationRequired[i]) {
                                <span class="text-danger">* </span>
                                } scheduled
                              product</th>
                              <th>@if (isDestinationRequired[i]) {
                                <span class="text-danger">* </span>
                                } demand
                              product</th>
                              <th>@if (isDestinationRequired[i]) {
                                <span class="text-danger">* </span>
                                } qty(bbl)
                              </th>
                              <th>sap contract</th>
                              <th>contract line no.</th>
                              @if (isAdditionalData) {
                                <th>batch origin location</th>
                              }
                              @if (isAdditionalData) {
                                <th>valuation type origin location</th>
                              }
                              @if (isAdditionalData) {
                                <th>batch destination location</th>
                              }
                              @if (isAdditionalData) {
                                <th>valuation type destination location</th>
                              }
                            </tr>
                            @if (transPortDropDownValue !== 'ite' && transPortDropDownValue !== 'itw') {
                              <ng-container formArrayName="destination">
                                @for (list of destination(i).controls; track list; let k = $index) {
                                  <ng-container [formGroupName]="k">
                                    <tr>
                                      <td><input type="checkbox" class="form-control text-field cell-form-checkbox"
                                      formControlName="confirmationIndicator"></td>
                                      <td>
                                        <div class="statwidth">
                                          <select class="form-control" formControlName="nominationTypeDesti"
                                            (change)="nominationTypeDestDesc(i,k)">
                                            <option value="">select</option>
                                            @for (nominationTypeDetails of destNominationTypeDropdown; track nominationTypeDetails) {
                                              <option
                                                [ngValue]="nominationTypeDetails?.name">
                                              {{ nominationTypeDetails?.name }}</option>
                                            }
                                          </select>
                                        </div>
                                      </td>
                                      <td class="statwidth">
                                        <select class="form-control rdi-select" formControlName="nominationTypeRdiDesti"
                                          (change)="nominationTypeRdiDest(i,k)">
                                          <option value="">select</option>
                                          @for (nominationTypeDetails of destNominationTypeDropdown; track nominationTypeDetails) {
                                            <option
                                              [ngValue]="nominationTypeDetails?.referenceDocumentIndicator">
                                            {{ nominationTypeDetails?.referenceDocumentIndicator }}</option>
                                          }
                                        </select>
                                      </td>
                                      <td><input  type="number" class="form-control text-field"
                                        formControlName="tswStatus" readonly>
                                      </td>
                                      @if (!showTruckMonthly) {
                                        <td><input class="form-control text-field date-field" formControlName="scheduledDate" type="date">
                                        </td>
                                      }
                                      @if (showTruckMonthly) {
                                        <td><input class="form-control text-field date-field" formControlName="scheduledDate" type="month">
                                        </td>
                                      }
                                      <td class="statwidth">
                                        <app-select-dropdown class="location-dropdown" #OriginSelectDropdownComponent [items]="destinationLocationDropdown"
                                          [defaultValue]="locationDestiArray[i][k]" formControlName="originLocationDesti" id="origin-loc"
                                          (afterChange)="onLocationValueChange($event)">
                                        </app-select-dropdown>
                                      </td>
                                      <td class="statwidth">
                                        <select class="form-control" formControlName="scheduledMaterial" id="sheduleMate">
                                          <option value="">select</option>
                                          @for (productDetails of productsDropdown; track productDetails) {
                                            <option [ngValue]="productDetails?.value">
                                            {{ productDetails?.name }}</option>
                                          }
                                        </select>
                                      </td>
                                      <td class="statwidth">
                                        <select class="form-control" formControlName="demandMaterial" id="demandMate">
                                          <option value="">select</option>
                                          @for (productDetails of productsDropdown; track productDetails) {
                                            <option [ngValue]="productDetails?.value">
                                            {{ productDetails?.name }}</option>
                                          }
                                        </select>
                                      </td>
                                      <td><input class="form-control text-field" [class.readonly] ='isDisableQTY' formControlName="scheduledQty"
                                        type="text" (keyup)="checkQTY(i,k)">
                                        @if ((destination(i).length - 1) == k) {
                                          <span class="qty-row">sum {{destinQtyValue[i]}}</span>
                                        }
                                      </td>
                                      <td><input class="form-control text-field" type="text" formControlName="sapcontract"></td>
                                      <td><input type="text" class="form-control text-field" formControlName="contractLineNo" (input)="onContractLineChange($event,k,i,'destination');"></td>
                                      @if (isAdditionalData) {
                                        <td><input type="text" class="form-control text-field"
                                        formControlName="batchOriginLocation"></td>
                                      }
                                      @if (isAdditionalData) {
                                        <td><input type="text" class="form-control text-field"
                                        formControlName="valuationTypeOriginLoc"></td>
                                      }
                                      @if (isAdditionalData) {
                                        <td><input type="text" class="form-control text-field"
                                        formControlName="batchDestinationLocation"></td>
                                      }
                                      @if (isAdditionalData) {
                                        <td><input type="text" class="form-control text-field"
                                        formControlName="valuationTypeDestinationLoc"></td>
                                      }
                                      @if (modeOfTransport !== 'truck') {
                                        <td>
                                          <div class="custom-dropdown" tabindex="0">
                                            <button class="icon-btn action-icon action-button"></button>
                                            <div class="dropdown-menu-wrap">
                                              <div class="dropdown-menu">
                                                <button class="dropdown-item action-btn-item"
                                                  (click)="copyDestinationRow(i,k)">duplicate row
                                                </button>
                                                @if (isMultipleRowItemDestination[i]?.length>1) {
                                                  <button
                                                    class="dropdown-item action-btn-item text-danger"
                                                    (click)="deletedestinationRow(i,k)">delete
                                                  </button>
                                                }
                                                <button class="dropdown-item action-btn-item text-act-btn"
                                                  (click)="clearDestinationRow(i,k)">clear all fields
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      }
                                    </tr>
                                  </ng-container>
                                }
                                <tr class="border-left-hidden">
                                  <td style="white-space: nowrap;">
                                    {{destinMovementsValue[i]}} Movements
                                  </td>
                                  @if (!isAdditionalData) {
                                    <td colspan="10"></td>
                                  }
                                  @if (isAdditionalData) {
                                    <td colspan="14"></td>
                                  }
                                </tr>
                              </ng-container>
                            }
                          </table>
                          <div class="my-3">
                            <button class="button button-text" [class.disabled]='disableAddLocation' (click)="addDestinationRow(i)">
                              add location
                            </button>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
                @if (errorMessage[i] && isOriginRequired[i] && isDestinationRequired[i]) {
                  <div class="text-danger mb-5">
                    <span>
                      Origin Total Volume = {{originQtyValue[i]}}; Destination Total Volume = {{destinQtyValue[i]}}
                    </span>
                  </div>
                }
              </ng-container>
            }
          </ng-container>
          <div class="form-row pb-1 pr-2">
          </div>

          <div class="form-row pb-1 form-button-container">
            <div class="form-group">
              <button class="button" (click)="closePopup()" (keydown.enter)="closePopup()" tabindex="0">Cancel</button>
            </div>
            <div class="form-group">
              <button class="button create-btn" [disabled]="!createTerminalNominationForm.valid" (keydown.enter)="submitForm('publish')"  tabindex="0" (click)="submitForm('publish')"> @if (editingNomination) {
                <span>update</span>
                } @if (!editingNomination) {
                <span>Create </span>
                }  <span> + Publish</span> </button>
              </div>
            </div>
          </form>
        </div>
