<div class="filter-panel-wrapper" [class.filter-panel-opened]="!closeFilterPanel"
  [class.filter-panel-closed]="closeFilterPanel">
  <div class="panel-header d-flex justify-content-between pt-10">
    <!-- <div class="panel-title">filters</div> -->
    @if (router.url ==='/nominations' && ((!isCCAPanamaView && selectedDesk.includes('clean products') && !(selectedDesk.includes('clean products + latam')))|| (selectedDesk == 'floridadesk' || selectedDesk == 'pnwdesk'))) {
      <div
        class="tab">
        <button class="btn tablinks p-3 justify-content-between  " [class.active]="true"
        (click)="tabChange($event,'Filter')">Filter</button>
        @if (!iscargoScreenOpened) {
          <button class="btn tablinks  p-3 justify-content-between "
          [class.active]="false" (click)="tabChange($event,'Search')">Search</button>
        }
      </div>
    }
  </div>
  @if (!isSearchOpened) {
    <div id="Filter" class="tabcontent">
      <div class="panel-title">filters</div>
      @if (router.url !== '/contracts/contract-balancing' && router.url !== '/bcpDashboard' && router.url !== '/admin/parameters' && router.url !== '/settings/alerts' && router.url !== '/admin/locations' && router.url !== '/admin/materialMapping' && router.url !== '/admin/desks' && router.url !== '/admin/transport' && router.url !== '/contracts/dynamicdeals') {
        @if (selectedDesk === 'panama' || isCCAPanamaView && router.url!=='/cargoPlanning') {
          <div class="panel-content">
            @if (locationFilters.length) {
              <div class="content__header">location</div>
              <form class="location-filter-block branded">
                @for (value of locationFilters; track value; let i = $index) {
                  <p>
                    <input type="radio" id="{{value.name}}" name="location-filter" [checked]="value.checked"
                      (change)="filteByLocation(value)"
                      [disabled]="(isCCAPanamaView && currentScreen === 'replenishmentplanning'  && locationDisable(value))">
                      <label for="{{value.name}}" [title]="value.name">{{value.name}} </label>
                    </p>
                  }
                </form>
              }
              @if (filtersResponse.length) {
                @for (response of filtersResponse; track response) {
                  @if (response.inputType === 'checkBox') {
                    <div class="content__header">{{response.filterName}}</div>
                    <form class="branded">
                      @for (value of response.filterValues; track value; let i = $index) {
                        <p>
                          <input type="checkbox" name="formcheckbox" attr.id="formcheckbox{{i}}"
                            [value]="value.name" (change)="filterTRData($event,response.filterName)"
                            [checked]="value.checked" >
                            <label attr.for="formcheckbox{{i}}">{{value.name}}</label>
                          </p>
                        }
                      </form>
                      <hr>
                      }
                      @if (response.inputType === 'radioButton') {
                        <div class="content__header">{{response.filterName}}</div>
                        <form class="branded product-filter">
                          @for (value of response.filterValues; track value; let i = $index) {
                            <section><input
                              type="radio" attr.id="radio1{{i}}" name="radio1" [value]="value.name"
                              [checked]="filtersInitialized? i === 0 : value.name===getProductFromEvent"
                              (change)="filterTRData($event, response.filterName)">
                              <label attr.for="radio1{{i}}">{{value.name}}</label>
                            </section>
                          }
                        </form>
                        <hr>
                        }
                      }
                      <date-range-picker [dateRangeData]="dateRangeData" [restrictStartDate]="true"
                        (updateDateRangeData)="dateRangegroup($event)">
                      </date-range-picker>
                    }
                  </div>
                }
                @if (filtersResponse.length && (selectedDesk === 'panama'|| isCCAPanamaView) && router.url!=='/cargoPlanning') {
                  <div class="panel-footer">
                    <span class="clear-filter" (click)="clearFilter()">reset to default</span>
                  </div>
                }
                <!-- for ethanol truck view -->
                @if (selectedDesk === 'ethanol + uswc' || selectedDesk === 'ethanol + usec') {
                  <div class="panel-content">
                    <ng-container>
                      @for (response of updateSearchData; track indexTracker(i); let i = $index) {
                        <div>
                          @if (response.headerName && (!(response.headerName === 'supplier' && switchView === 'carrier' ) && !(response.headerName === 'carrier' && switchView === 'supplier' ) && (response.headerName !== 'unit of measure' || currentScreen !== 'nominations') && !(response.headerName === 'customer' && currentScreen === 'replenishmentplanning'))) {
                            <div
                              >
                              <div class="content__header">{{response.headerName}}
                                <span [class]="response.headerName +'_icon'"></span>
                              </div>
                              @if (response.headerName !== 'unit of measure' && response.headerName !== 'terminal ownership') {
                                <form class="branded"
                                  >
                                  @if (response.headerName !== 'mode of transport' && response.headerName !== 'unit of measure') {
                                    <input class="form-control ethonal-view_search"
                                      [name]="'searchText'+i" [(ngModel)]="searchText[i]" type="search"
                                      placeholder="search" (input)="updateFilterList($any($event.target).value, i)">
                                  }
                                  <div class="ethonal-view_container">
                                    @for (ethanolFilterValues of response.list; track ethanolFilterValues) {
                                      <p
                                        class="align-items-center d-flex"
                                        [ngClass]="{'sticky-header' : ethanolFilterValues.name === 'all'}">
                                        @if (!(currentScreen === 'replenishmentplanning' && ethanolFilterValues.id?.includes('-X'))) {
                                          <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox"
                                            [id]="ethanolFilterValues.name" [value]="ethanolFilterValues.name"
                                            [checked]="ethanolFilterValues.checked"
                                            [disabled]="(response.headerName !== 'mode of transport' && isCheckboxDisabled(response.headerName,ethanolFilterValues, ethanolFilterValues.checked))
                                    || ( response.headerName === 'mode of transport' && ethanolFilterValues.checked && selectedMOTCount === 1)"
                                            (change)="ethanolFilterData($event, response.headerName, i)">
                                        }
                                        @if (!(currentScreen === 'replenishmentplanning' && ethanolFilterValues.id?.includes('-X'))) {
                                          <label class="text-ellipsis" [title]="ethanolFilterValues.name"
                                            [title]="response.headerName==='terminal'? ethanolFilterValues.name + ' (' +ethanolFilterValues.id + ')' : ethanolFilterValues?.name"
                                            [htmlFor]="ethanolFilterValues.name">
                                            {{response.headerName==='product' || response.headerName==='supplier' || response.headerName==='customer' || response.headerName==='carrier' || response.headerName ==='mode of
                                            transport' || ethanolFilterValues.name === 'all' ?
                                            ethanolFilterValues.name : response.headerName==='terminal' ? ethanolFilterValues.id : ethanolFilterValues.id}}
                                          </label>
                                        }
                                      </p>
                                    }
                                  </div>
                                </form>
                              }
                              @if (((response.headerName === 'unit of measure' && currentScreen === 'replenishmentplanning') || response.headerName === 'terminal ownership')) {
                                <form class="branded">
                                  @if (response.headerName === 'unit of measure') {
                                    <p class="align-items-center d-flex mb-2"
                                      >
                                      @if (currentScreen === 'replenishmentplanning') {
                                        <app-toggle-switch
                                          [label]="'mot'" [checked]="response.truck" [displayStatus]="true"
                                          (change)="ethanolTruckToggle($event, response.headerName, i)">
                                        </app-toggle-switch>
                                      }
                                    </p>
                                  }
                                  @for (ethanolViewFilterValues of response.list; track ethanolViewFilterValues; let i = $index) {
                                    <p
                                      class="align-items-center d-flex">
                                      <input type="radio" name="ethanolRadio" class="flex-shrink-0 mb-2"
                                        [id]="ethanolViewFilterValues.name" [value]="ethanolViewFilterValues.name"
                                        [checked]="ethanolViewFilterValues.checked"
                                        [disabled]="(response.headerName === 'unit of measure' && currentScreen === 'nominations')"
                                        (change)="ethanolFilterData($event, response.headerName, i)">
                                        <label class="text-ellipsis mb-2"
                                        [htmlFor]="ethanolViewFilterValues.name">{{ethanolViewFilterValues.name}}</label>
                                      </p>
                                    }
                                  </form>
                                }
                                <hr>
                                </div>
                              }
                            </div>
                          }
                          <date-range-picker [dateRangeData]="ethanolDateRangeData" [restrictStartDate]="true"
                            (updateDateRangeData)="ethanolDateRangegroup($event)">
                          </date-range-picker>
                        </ng-container>
                      </div>
                    }
                    @if (selectedDesk === 'ethanol + uswc'|| selectedDesk === 'ethanol + usec' ) {
                      <div class="panel-footer d-flex justify-content-between p-3">
                        <span class="clear-filter" (click)="ehtanolClearFilter()">clear</span>
                        <span class="clear-filter" (click)="ehtanolApplyFilter()">apply</span>
                      </div>
                    }
                    <!--pipeline filters -->
                    @if ((isPipelineFiltersEnabled || router.url === '/marketElasticity' || selectedDesk.includes('refinery')) && router.url !== '/cargoPlanning' && !isCCAPanamaView) {
                      <div class="panel-content"
                        >
                        <ng-container>
                          @for (response of updateSearchData; track indexTracker(i); let i = $index) {
                            <div>
                              @if (response.headerName) {
                                <div [class]="{'filter-collapse': filterAccord[i]}">
                                  <div (click)="accordionFn(i)"
                                    class="content__header d-flex justify-content-between cursor-pointer">
                                    <span>{{getHeaderName(response)}}
                                      <span
                                      [class]="response.headerName.toLowerCase() === 'location'? 'terminal_icon' : response.headerName +'_icon'"></span></span>
                                      <span
                                      [class]="{'icon-chevron-up': !filterAccord[i], 'icon-chevron-down': filterAccord[i]}"></span>
                                    </div>
                                    <form class="branded">
                                      @if (response.headerName !== 'view type') {
                                        <input class="form-control ethonal-view_search" [name]="'searchText'+i"
                                          [(ngModel)]="searchText[i]" type="search" placeholder="search"
                                          (input)="updatePipelineFilterList($any($event.target).value, i, response.headerName)">
                                      }
                                      <div class="ethonal-view_container">
                                        @for (pipelineFilterValues of response.list; track pipelineFilterValues) {
                                          <p
                                            class="align-items-center d-flex"
                                            [ngClass]="{'sticky-header' : pipelineFilterValues.name === 'all'}">
                                            @if (response.headerName === 'view type') {
                                              <input type="radio" class="flex-shrink-0"
                                                name="pipelineRadio{{response.headerName}}"
                                                [id]="((pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name)+i"
                                                [value]="(pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name"
                                                [checked]="pipelineFilterValues.checked"
                                                (change)="pipelineFilterData($event, response.headerName, i, pipelineFilterValues)">
                                            }
                                            @if (response.headerName !== 'view type') {
                                              <input type="checkbox" class="flex-shrink-0"
                                                name="pipelineCheckbox"
                                                [id]="((pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name)+i"
                                                [value]="(pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name"
                                                [checked]="pipelineFilterValues.checked"
                                                (change)="pipelineFilterData($event, response.headerName, i, pipelineFilterValues)">
                                            }
                                            @if (isPipelineCargotabActive(pipelineFilterValues,response.headerName)) {
                                              <label class="text-ellipsis"
                                                [title]= "getTitle(pipelineFilterValues,response.headerName)"
                                                [htmlFor]="((pipelineFilterValues?.name?.toLowerCase()=='barge') ? 'B' : pipelineFilterValues?.id ?? pipelineFilterValues?.name)+i">
                                            {{ response.headerName ==='view type'|| response.headerName==='product' || response.headerName ==='mode of
                                            transport' || pipelineFilterValues.name === 'all' ?
                                                pipelineFilterValues.name : pipelineFilterValues.id}}
                                              </label>
                                            }
                                          </p>
                                        }
                                      </div>
                                    </form>
                                    <hr>
                                    </div>
                                  }
                                </div>
                              }
                              <date-range-picker [dateRangeData]="pipelineDateRangeData" [restrictStartDate]="true"
                                (updateDateRangeData)="pipelineDateRangegroup($event)">
                              </date-range-picker>
                            </ng-container>
                          </div>
                        }
                        @if ((isPipelineFiltersEnabled || router.url === '/marketElasticity') && router.url !== '/cargoPlanning'  && !isCCAPanamaView || selectedDesk.includes('refinery')) {
                          <div class="panel-footer d-flex justify-content-between p-3"
                            >
                            <span class="clear-filter" (click)="pipelineClearFilter()">clear</span>
                            <span class="clear-filter" (click)="applyPipelineFilter()">apply</span>
                          </div>
                        }
                      } @else {
                        @if (router.url === '/contracts/contract-balancing') {
                          @if (deskListResponse.length) {
                            <app-contract-balancing-filter></app-contract-balancing-filter>
                          }
                        }
                      }
                      @if (selectedDesk.includes('clean products + latam') && router.url==='/cargoPlanning') {
                        <div class="panel-content">
                          @for (value of cargoFiltersCCA; track value) {
                            <span>
                              <div class="content__header">
                              {{ value.filterName }}</div>
                              <form class="location-filter-block branded">
                                @for (response of value.list; track response) {
                                  <section>
                                    <input type="checkbox" [id]="response.value" [name]="response.value"
                                      [value]="response.value" [checked]="response.checked"
                                      (change)="onChangeCargoCCA($event, value.filterName)">
                                      <label [for]="response.value">{{ response.value }}</label>
                                    </section>
                                  }
                                </form>
                              </span>
                            }
                            <date-range-picker [dateRangeData]="cargoCCADateRangeData" [restrictStartDate]="true"
                              (updateDateRangeData)="dateRangegroupCargo($event)">
                            </date-range-picker>
                          </div>
                          <div class="panel-footer d-flex justify-content-between p-3">
                            <span class="clear-filter" (click)="ccaClearFilter()">clear</span>
                            <span class="clear-filter" (click)="applyCargoCCAFilter()">apply</span>
                          </div>
                        }
                        @if (router.url === '/admin/locations') {
                          <app-locations-filter></app-locations-filter>
                        }
                        @if (router.url === '/admin/desks') {
                          <app-desks-filter></app-desks-filter>
                        }
                        @if (router.url === '/admin/materialMapping') {
                          <app-material-mapping-filter></app-material-mapping-filter>
                        }
                        @if (router.url === '/admin/transport') {
                          <app-transport-filter></app-transport-filter>
                        }
                        @if (router.url === '/bcpDashboard') {
                          <app-bcp-dashboard-filter></app-bcp-dashboard-filter>
                        }
                        @if (isSearchOpened) {
                          <app-search-panel></app-search-panel>
                        }
                        @if (router.url === '/settings/alerts') {
                          <rule-filter></rule-filter>
                        }
                        @if (router.url === '/admin/parameters') {
                          <app-settings-parameters-filter></app-settings-parameters-filter>
                        }
                        @if (router.url === '/contracts/dynamicdeals') {
                          <app-contract-dynamic-deals-filter></app-contract-dynamic-deals-filter>
                        }
                      </div>
                    }
                    @if (isSearchOpened) {
                      <app-search-panel></app-search-panel>
                    }
                  </div>