import { setPipelineSwitchView, setSelectedDesk, triggerTRSave } from './../../../shared/store/action/app.action';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { NominationService } from 'src/app/services/nomination.service';
import { screen, selectDesk, getUserDetails, selectUpdateURLParams, selectAdminView } from 'src/app/shared/store/selector/app.selector';
import { NavigationEnd, Router } from '@angular/router';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { ContractBalancingService } from 'src/app/services/contract-balancing.service';
import { AlertsService } from 'src/app/services/alerts.service';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';
import { NotificationState } from 'src/app/shared/interface/workspace-alert.interface';
import { AlertsNotificationService } from 'src/app/services/alerts-notification.service';
import { Location } from '@angular/common';
import { HomeComponent } from 'src/app/home/home.component';
import { CargoPlanningCcaService } from 'src/app/services/cargo-planning-cca.service';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { loadDynamicDeals, updateCustomDateRangePicker } from 'src/app/dynamicdeals/store/action/cb-dynamic-deals.action';
import { getPayloadForDynamicDeals } from 'src/app/dynamicdeals/store/reducer/cb-dynamic-deals.reducer';
import { DynamicDealsSharedStateService } from 'src/app/services/dynamic-deals-shared-state.service';

@Component({
  selector: 'app-np-header',
  templateUrl: './np-header.component.html',
  styleUrls: ['./np-header.component.scss'],
})
export class NpHeaderComponent implements OnInit, OnDestroy {
  @Input() siteName: string = 'unified distribution management';
  @Input() deskList: any;
  @Output() updatedDesk = new EventEmitter();
  selectedDesk!: string;
  selectedDeskId!:number;
  selectedDesk$ = this.store.pipe(select(selectDesk));
  selectedAdminView$ = this.store.pipe(select(selectAdminView));
  screen$ = this.store.pipe(select(screen));
  destroy$: Subject<boolean> = new Subject<boolean>();
  initialDesk: any = localStorage.getItem("desk");
  currentUrl: any;
  desksVisible: string = 'visible';
  initialDeskParam: any = localStorage.getItem("deskParam");
  initialDeskId: any = localStorage.getItem("deskId");
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  userName!: string;
  isDropdownOpen: boolean = false;
  dropdownIcon = "icon-chevron-down";
  userAvatar: any;
  @ViewChild("userDetailsSection") userDetails!: ElementRef;
  urlParams$ = this.store.pipe(select(selectUpdateURLParams));
  routeFilters: any = [];
  getUpdatedFiltersSelection: any;
  isRouteParam: boolean = false;
  filteredDesk: any;
  alreadyRemoved: boolean = false;
  isDealPopOutPage: boolean = false;
  routerSubscription$!: Subscription;


  constructor(private store: Store, private appStore: Store<AppState>, public nominationService: NominationService, public alertService: AlertsService, private router: Router, private contractBalancingService: ContractBalancingService, private location: Location, private alertSaveService: AlertSaveGuardService, public alertsNotificationService: AlertsNotificationService, private homeComponent: HomeComponent, public cargoPlanningCcaService: CargoPlanningCcaService, public cpService: CargoPlanningService
    , public cbStateService: DynamicDealsSharedStateService) {

    this.initialDesk = localStorage.getItem("desk");
    this.initialDeskParam = localStorage.getItem("deskParam");
    this.initialDeskId = localStorage.getItem("deskId");
    this.urlParams$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        this.routeFilters = response;
        this.getUpdatedFiltersSelection = response.updatedFilterSelection;
        if(response.params.filteredLocation != "" && response.params.filteredLocation != undefined){
          this.isRouteParam = true;
          
          this.filteredDesk = response.params.filteredDesk;
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {               
              this.handleCustomViewOptionInWelcomeDropdown();          
      });
  }

  //closing dropdown when clicked on outside of it
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    const dropdown = this.userDetails.nativeElement;

    if (!dropdown.contains(target)) {
      this.isDropdownOpen = false;
      this.dropdownIcon = "icon-chevron-down";
    }
  }

  //dropdown items
  dropdownItems = [
    { name: 'your custom views', path: "", icon: 'icon-user' },
    { name: 'reset to default view', path: "", icon: 'reset' },
    { name: 'admin', path: "", icon: 'settings-icon' },
    { name: 'alerts & notifications', path: "", icon: 'settings-icon'},
    { name: 'help', path: "", icon: 'help-icon' },
  ]

  list = [];

  getDeskName(deskObj: any) {

    if(!this.alertSaveService.canDeactivate()){
      if(!this.alertSaveService.isUsingDeskBlock()){
        this.alertSaveService.setDeskSource(true);
      }
      return;
    }
    this.nominationService.linkNomData$.next([]);
    const desk = deskObj.deskValue;
    const deskParameter = deskObj.deskParameter;
    const deskId = deskObj?.id;
    const prevDesk = localStorage.getItem('desk');
    if (prevDesk?.includes("+ latam +") && this.router.url === "/cargoPlanning" && !desk?.includes("+ latam +")) {
      this.router.navigate(["/nominations"]);
    }
    localStorage.setItem("desk", desk);
    localStorage.setItem("deskParam", deskParameter);
    localStorage.setItem("deskId", deskId);
    let selectedDeskOption = desk;
    this.selectedDesk = selectedDeskOption;
    this.nominationService?.createNominationForPipelineActuation?.next(false);

    this.handleCustomViewOptionInWelcomeDropdown();
    this.alertService.sidePanel$.next(false);
    localStorage.removeItem("selectedDefaultView");
    this.handleCustomView();
    if(!(deskParameter?.toLowerCase() === 'floridadesk' || deskParameter?.toLowerCase() === 'pnwdesk' || deskParameter?.toLowerCase() === 'pascagouladesk' || deskParameter?.toLowerCase() === 'richmonddesk')){
      this.nominationService.isNominationScreen = "nomination";
      this.nominationService.isCargoScreen.next(false);
      this.homeComponent.cpCargoPlanning$.next(false);
    }
    if (this.selectedDesk.includes('+ latam + all desk') && prevDesk !== this.selectedDesk) {
      this.router.navigate(['cargoPlanning'])
    }
    if(this.router.url !== "/nominations" && !this.router.url.includes("/admin/desks") && !this.router.url.includes("/settings/alerts") && this.selectedDesk.includes('refinery + usec + pasadena desk')) {
      this.router.navigate(['/marketElasticity'])
    }
    if(this.router.url !== "/nominations" && !this.router.url.includes("/admin/desks") && !this.router.url.includes("/settings/alerts") && this.selectedDesk.includes("refinery + usec + houston blend center desk")){
      this.router.navigate(['/nominations']);     
    }
    this.store.dispatch(setSelectedDesk({ selectedDesk: selectedDeskOption, selectedDeskParam: deskParameter, selectedDeskId: deskId  }))
    this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false }));
    const deskOj = { deskValue: selectedDeskOption, deskParameter: deskObj.deskParameter ?? null, id: deskObj?.id };
    this.updatedDesk.emit(deskOj);
    this.handleModalPopupsForNominationsAndContractBalancing(deskOj);
    if(deskId)
    {
      this.cbStateService.emitDeskId(this.selectedDeskId);
      this.store.dispatch(updateCustomDateRangePicker({updateCustomDateRangePickerFlag: true}));
      const payload = getPayloadForDynamicDeals();
      this.store.dispatch(loadDynamicDeals({payload,fromDateChange:false}));
      this.cbStateService.emitDesk(this.selectedDesk);
    }
  }

  handleCustomView(): void{
    this.nominationService.sidePanel$.next(false);
    this.nominationService.restrictNomApiGetCall$.next(true);
    this.nominationService.customViewSidePanel$.next(false);
    this.nominationService.createCustomView$.next(false);
    this.nominationService.manageCustomView$.next(false);
  }

  handleCustomViewOptionInWelcomeDropdown(){

    if(this.currentUrl !== "/bcpDashboard" && this.selectedDesk === 'panama'){
      
      if(!this.alreadyRemoved){
        this.alreadyRemoved = true;
        this.dropdownItems.shift()
      }
    
    }else{
      let valIndex = this.dropdownItems.findIndex((val) => val.name === 'your custom views');
      if (valIndex === -1) {
        this.dropdownItems.unshift({ name: 'your custom views', path: "", icon: 'icon-user' })
      }
      this.alreadyRemoved = false;
    }
  }

  handleModalPopupsForNominationsAndContractBalancing(deskOj: any): void{
    if (this.router.url === "/contract-balancing") {
      this.contractBalancingService.updatedDesk$.next(deskOj);
    }
    if(this.router.url === "/nominations" || this.router.url === "/replenishmentPlanning"){
      this.nominationService.isModalPopupOpen$.next(true);
      this.nominationService.isCCANomModalOpen$.next(true);
    }
    if(this.router.url === "/cargoPlanning") {
      this.cargoPlanningCcaService.isCCACargoModalOpen$.next(true);
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.dropdownIcon = "icon-chevron-up";
    } else {
      this.dropdownIcon = "icon-chevron-down";
    }
  }
  
  ngOnInit(): void {
    this.currentUrl = this.location.path();
    if(this.currentUrl.includes('contracts/dealdetail'))
      {
        this.isDealPopOutPage = true;
      }
    this.routerSubscription$ = this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd)
        {
          if(event.url.includes('contracts/dealdetail'))
            {
              this.isDealPopOutPage = true;
            }
            else
            {
              this.isDealPopOutPage = false;
            }
        }
    })
   

    this.handleAlertsUrlDeskChange();

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedDesk = response.selectedDesk;
          this.selectedDeskId = +response.selectedDeskId;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    // getting user details
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userName = response.userDetails.name;          
            if(localStorage.getItem("userName") == null){
              localStorage.setItem("userName", this.userName);
            }
            if(localStorage.getItem("userEmail") == null){
              localStorage.setItem("userEmail", response.userDetails.email);
            }
            localStorage.setItem("userName", this.userName);
            this.createUserAvatarWithInitials(response.userDetails.name);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    
    this.selectedAdminView$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        this.currentUrl = this.router.url;
        this.showDesksDropdown();
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { }
    });
    this.formatDeskList(this.deskList);
    this.hrefGuard();
  }

  handleAlertsUrlDeskChange(): void{
    if(this.isRouteParam){
      const deskSet = this.filteredDesk.toLowerCase();
      this.store.dispatch(setSelectedDesk({ selectedDesk: deskSet, selectedDeskParam: this.initialDeskParam, selectedDeskId: this.initialDeskId }));
    }
    else{
      const deskSet = this.initialDesk ?? "panama";
      this.store.dispatch(setSelectedDesk({ selectedDesk: deskSet, selectedDeskParam: this.initialDeskParam, selectedDeskId: this.initialDeskId }));
    }
  }

  toggleCreateAlertNotificationPanel() {

    this.alertsNotificationService.showBell.next(false);
    this.alertService.sidePanel$.value === false? 
      this.alertService.openSidePanel(NotificationState.UserOpen, [], true) :
      this.alertService.openSidePanel(NotificationState.UserOpen, [], false);;
  }


  showDesksDropdown() {
    if (this.currentUrl === '/admin/locations' || this.currentUrl === '/admin/materialMapping' || this.currentUrl === '/admin/desks' || this.currentUrl === '/admin/transport') {
      this.desksVisible = 'hidden';
    } else {
      this.desksVisible = 'visible';
    }
  }

  formatDeskList(desklist: any) {
    const formattedDeskList: any = [];
    const deskNames = desklist.map((obj: any) => obj.filterName);
    deskNames.forEach((name: any) => {
      desklist.forEach((desk: any) => {
        if (desk.filterName === name) {
          desk.filterValues.forEach((value: any) => {
            let deskObj: any = { id: value.id, name: value.name, groupName: name, value: value.name, parameter: value.parameter };
            deskObj = this.updateDeskObj(deskObj, value, name);
            formattedDeskList.push(deskObj);
          });
        }
      })
    });
    this.list = formattedDeskList;
    if(this.list)
    {
      this.cbStateService.emitDeskInformation(this.list);
      this.cbStateService.emitDesk(this.selectedDesk);
      this.cbStateService.emitDeskId(this.selectedDeskId);
      let region = '';
      if(this.selectedDesk.includes('usec'))
      {
        region = 'usec';
      }
      if(this.selectedDesk.includes('uswc'))
        {
          region = 'uswc';
        }
      this.cbStateService.emitRegion(region);

    }
    
  }

  updateDeskObj(deskObj: any, value: any, name: any) {
    if (value.name === 'panama') {
      deskObj["selected"] = this.initialDesk === value.name || !this.initialDesk;
    }
    if (name === 'ethanol') {
      deskObj["selected"] = this.initialDesk === value.label;
      deskObj["label"] = value.label;
      deskObj["value"] = value.label;
    }
    if (name === 'pipeline' || name === "clean products" || name === "refinery") {
      const subMenu: any = [];
      value.children.forEach((obj: any) => {
        const subMenuObj = { id: obj.id, name: obj.name, label: obj.label, parameter: obj.parameter, value: obj.label };
        subMenu.push(subMenuObj);
      });

      deskObj["subOptions"] = subMenu;
    }
    return deskObj;
  }


  //creating user avatar with initials
  createUserAvatarWithInitials(name: any) {
    let nameArray, firstLetter, secondLetter, finalName;
    nameArray = name.replaceAll(" ", "").split(",");
    firstLetter = nameArray[0] ? nameArray[0][0] : "";
    secondLetter = nameArray[1] ? nameArray[1][0] : "";
    this.userAvatar = firstLetter + secondLetter;
  }

  onClickCustomViews(event: any) {
    if (event.target.innerText === "your custom views") {
      this.nominationService.customViewSidePanel$.next(true);
    }
    if (event.target.innerText === "reset to default view") {
      this.nominationService.triggerClearFilter$.next(true);
      this.nominationService.customViewSidePanel$.next(false);
      this.nominationService.resetToDefaultView$.next(true);
      localStorage.removeItem("selectedView");
      localStorage.removeItem("nomColData");
      localStorage.removeItem("trColExpandData");
      localStorage.removeItem("trColOrder");      
      this.store.dispatch(setPipelineSwitchView({ pipelineSwitchView: 'product_terminal_nomination' , isPipelineSwitchUpdated: true }));      
    }
    if (event.target.innerText === "admin") {
      this.router.navigate(['admin/locations']);
    }
    if (event.target.innerText === 'alerts & notifications') {
      window.location.href = "/settings/alerts";
    }
    if (event.target.innerText === 'help') {
      window.open("https://chevron.sharepoint.com/sites/dscVCOELA/PMMI/Lists/UDM%20Production%20Issue%20Tracker/NewForm.aspx?Source=https:%2f%2fchevron.sharepoint.com%2fsites%2fdscVCOELA%2fPMMI%2fLists%2fUDM%2520Production%2520Issue%2520Tracker%2fAllItems.aspx&ContentTypeId=0x0100D096ABFE11763745BA17F4A3EDBD1C6F008138D0FB7F590947B31BE543B30CE0CE&RootFolder=%2fsites%2fdscVCOELA%2fPMMI%2fLists%2fUDM%20Production%20Issue%20Tracker&OR=Teams-HL&CT=1699484017991&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA5MjkxMTIwNyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D", "WindowName", "noopener")
    }
    this.isDropdownOpen = false;
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.routerSubscription$.unsubscribe();
  }

  //Function used to guard all Href from access if the user has unsaved changes
  //Required as non-router based navigation causes page reloads and don't use standard angular routers for router guards
  hrefGuard(): void{
    
    document.addEventListener('click', (event) =>{
      const target = event.target as HTMLElement;

      if(this.linkInsideHeader(target)){
        event.preventDefault();

        if(target.getAttribute('href')){
          const hrefOfTarget = target.getAttribute('href')!;
  
          if(this.alertSaveService.canDeactivate()){
            window.location.href = hrefOfTarget;
          }else{
            this.alertSaveService.setHrefSource(true);
            this.alertSaveService.setNavigationAddress(hrefOfTarget);
          }
        }
      }
      }
    )
  }

  linkInsideHeader(element: HTMLElement): boolean{
    return element.tagName === "A" && element.closest('app-np-header') !== null;
  }

}
