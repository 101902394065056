<div class="sub-header">
  <div class="d-flex nominations-grid">
    @if (desk === 'panama') {
      <ag-grid-angular class="ag-theme-alpine ag-grid-table" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="rowData" [rowSelection]="rowSelection" [masterDetail]="true" [enableRangeSelection]="true"
        [detailCellRendererParams]="detailCellRendererParams" [animateRows]="true" (gridReady)="onGridReady($event)"
        [groupHideOpenParents]="true" [groupDisplayType]="groupDisplayType" [suppressMenuHide]="true"
        [unSortIcon]="true" [components]="components" [getRowId]="getRowId" (firstDataRendered)="onFirstDataRendered($event)"
        (selectionChanged)="onSelectionChanged($event)" (cellClicked)="onCellClicked($event)"
        (cellValueChanged)="onCellValueChanged($event)" [frameworkComponents]="frameworkComponents"
        [embedFullWidthRows]="true" [stopEditingWhenCellsLoseFocus]="true">
      </ag-grid-angular>
    }
    @if (desk === 'ethanol + uswc' || desk === 'ethanol + usec') {
      <div class="ag-grid-table">
        <app-nomination-ethanol-view></app-nomination-ethanol-view>
      </div>
    }
    @if ((desk?.includes('clean products') && !desk?.includes('latam')) && isCargoPlanning === false && !isSearchOpen) {
      <div class="ag-grid-table">
        <app-nomination-pipeline-view></app-nomination-pipeline-view>
      </div>
    }
    @if (desk?.includes('clean products + latam') && !isSearchOpen) {
      <div class="ag-grid-table">
        <app-nomination-cca-view></app-nomination-cca-view>
      </div>
    }
    @if (isSearchOpen) {
      <div class="ag-grid-table">
        <app-nomination-global></app-nomination-global>
      </div>
    }
    @if (desk?.includes('clean products') && isCargoPlanning === true && !isSearchOpen) {
      <div class="ag-grid-table">
        <app-cargo-planning></app-cargo-planning>
      </div>
    }
    @if (desk?.includes('refinery + usec') && !isSearchOpen) {
      <div class="ag-grid-table">
        <app-nomination-refinery-view></app-nomination-refinery-view>
      </div>
    }
  </div>
  <ng-container>
    <np-modal [show]="terminalNominationModal.open" [headerTitle]="'creating new terminal nomination.'"
      [subHeaderText]="'*required fields'" [noModalButtons]="true">
      @if (desk === 'panama') {
        <app-create-terminal-nomination (closeModalPopup)="closeTerminalNominationModal($event)">
        </app-create-terminal-nomination>
      }
    </np-modal>
    <np-modal [show]="openNominationConfirmationModal.open" class="confirm-modal" [headerTitle]="'Are you Sure?'">
      <app-nomination-edit-delete-confirm-model (closeNominationModalPopup)="closeNominationConfirmModal()">
      </app-nomination-edit-delete-confirm-model>
    </np-modal>
  </ng-container>
  <np-modal [show]="openModal" class="confirm-modal" [headerTitle]="'Alert'">
    <date-change-confirm-model [nominationComponent]='true' (closeModalPopup)="closeConfirmModal($event)"
      (confirmModalPopup)="confirmModalPopup($event)">
    </date-change-confirm-model>
  </np-modal>
</div>