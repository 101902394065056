@if (showUi) {
  <div class="more-menu">
    <ng-container>
      <!-- <ng-container *ngIf="details">
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    </ng-container> -->
  </ng-container>
  <div class="more-menu-dropdown">
    <button  class="ellipses-icon"
    (click)="moremwnu()"></button>
    @if (!isOpen) {
      <div class="dropdown-content" [ngClass]="[(rowIndex < 1) ? '' :(rowIndex === 1) ? 'place-second' : ((rowIndex > 3))?'place-bottom' : 'place-top']">
        <a class="dropdown-item create-nom-with-ref"
        (click)="createNomWithRef(pipelineParentObj)">new nomination</a>
        <a class="dropdown-item create-nom-with-ref"
        (click)="navigateToNom(pipelineParentObj, '/nominations', pipelineParentObj?.udmItineraryPlanName)">link to existing nomination</a>
        <a class="dropdown-item edit-nomination" [ngClass]="{'disablePublish':!pipelineParentObj?.nominationNo}" (click)="delinkCargoCca(pipelineParentObj)">delink nomination</a>
        <a class="dropdown-item edit-nomination" [ngClass]="{'disablePublish':!pipelineParentObj?.nominationNo}" (click)="delinkCargoCca(pipelineParentObj)">delink nomination</a>
        <a class="dropdown-item edit-nomination" (click)="deleteCargoCca(pipelineParentObj)">delete</a>
      </div>
    }
  </div>
</div>
}
