<div class="more-menu" (mouseleave)="isOpen = false">
  @if (!nominationTab) {
    @if (details) {
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    } @else {
      <span [ngClass]="[moremenuIconShow ? '' : '']">{{value}}</span>
    }
  }
  <div class="more-menu-dropdown">
    @if (moremenuIconShow && ((details?.id!== '' && details?.status!== '')) && !isFirstGroupin) {
      <button class="ellipses-icon"
      (click)="moremwnu()"></button>
    }
    @if (isOpen) {
      <div class="dropdown-content" [ngClass]="{'place-top': rowIndex > 8, 'displayHover' : isOpen }" (mouseleave)="moremwnu()">
        @if (!isDetailGrid) {
          <a class="dropdown-item edit-nomination" (click)="editNomination(pipelineParentKey, pipelineCurrentDesk)">edit
          nomination</a>
        }
        @if (!isDetailGrid) {
          <a class="dropdown-item create-nom-with-ref"
            (click)="createNomWithRef(pipelineParentKey, pipelineCurrentDesk)">create nom. with
          reference</a>
        }
        @if (!isDetailGrid) {
          <a class="dropdown-item contract-balancinf"
            (click)="contractBalancing()">contract
          balancing</a>
        }
        <a class="dropdown-item publish" (click)="publish(pipelineParentKey, pipelineCurrentDesk)">publish</a>
        <a class="dropdown-item delete"
        (click)="delete(pipelineParentKey, pipelineCurrentDesk)">delete</a>
        <!-- <a *ngIf="!isDetailGrid" class="dropdown-item see-details" (click)="seeDetails()">see details</a> -->
      </div>
    }
  </div>


</div>