<div class="ethanol-more-menu">
  <span [ngClass]="[isNomPresent ? rowStatus : '']">{{ value }}</span>
  <div class="more-menu-dropdown">
    @if (moremenuIconShow) {
      <button class="ellipses-icon" (click)="moremwnu()"></button>
    }
    @if (!isOpen) {
      <div class="dropdown-content" [ngClass]="[compactView ? 'compact-dropdown':'', rowIndex < 8 ? '' : 'place-top']">
        @if (isDraft) {
          <a class="dropdown-item ethanol-create-nomination" (click)="onCreateDraftNomination()">create nomination</a>
        }
        @if (rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published'|| rowStatus?.toLowerCase() ==='failed') {
          <a class="dropdown-item create-nomination" (click)="onCreateDraftNomination()">create nomination from
          reference</a>
        }
        @if (transLoadSplitConditionCheckUSEC || transLoadSplitConditionCheckUSWC) {
          <a class="dropdown-item transload-split"
          (click)="transloadSplit()">transload transfer split</a>
        }
        @if (rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='failed') {
          <a class="dropdown-item goToNominations"
          (click)="goToNominations()">go to nominations</a>
        }
        @if (rowStatus?.toLowerCase() !=='failed' && !isTransloadColumn) {
          <a class="dropdown-item ethanol-seeInNominationPannel" (click)="seeInNominationPannel()">see in nomination panel</a>
        }
        @if ((rowStatus?.toLowerCase() ==='published' && isEditable) || rowStatus?.toLowerCase() ==='failed') {
          <a class="dropdown-item edit-nomination" (click)="editNomination()">edit nomination</a>
        }
        @if (!isTransloadColumn) {
          <a class="dropdown-item ethanol-delete" (click)="delete()">delete</a>
        }
      </div>
    }
  </div>
</div>
