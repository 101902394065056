<div class="panel-content">
  <ng-container>
    <div>
      <div class="content__header">exposure
      </div>
      <form class="branded">
        <div class="contract-balancing-side-panel-container">
          @for (filterObj of secondaryFiltersObj.exposure.exposure_Array | slice:0:secondaryFiltersObj.exposure.displayCount; track filterObj; let i = $index) {
            <p
              class="align-items-center d-flex">
              <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'exposure'"
                [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'exposure', i)">
                <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'exposure'">
                  {{filterObj.name}}
                </label>
              </p>
            }
            @if (secondaryFiltersObj.exposure.displayCount < secondaryFiltersObj.exposure.exposure_Array.length) {
              <p class="see-more-link" (click)="seeMoreValues('exposure')">see more +</p>
            }
          </div>
        </form>
        <hr>
        </div>
        <div>
          <div class="content__header">term
          </div>
          <form class="branded">
            <div class="contract-balancing-side-panel-container">
              @for (filterObj of secondaryFiltersObj.term.term_Array | slice:0:secondaryFiltersObj.term.displayCount; track filterObj; let i = $index) {
                <p
                  class="align-items-center d-flex">
                  <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'term'"
                    [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'term', i)">
                    <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'term'">
                      {{filterObj.name}}
                    </label>
                  </p>
                }
                @if (secondaryFiltersObj.term.displayCount < secondaryFiltersObj.term.term_Array.length) {
                  <p class="see-more-link" (click)="seeMoreValues('term')">see more +</p>
                }
              </div>
            </form>
            <hr>
            </div>
            @if ((deskState.includes('clean products + usec') || deskState.includes('clean products + uswc'))) {
              <div>
                <div class="content__header">indicators
                </div>
                <form class="branded">
                  <div class="contract-balancing-side-panel-container" [class.indicators-height]="secondaryFiltersObj.indicators.displayCount > 4">
                    @for (filterObj of secondaryFiltersObj.indicators.indicators_Array | slice:0:secondaryFiltersObj.indicators.displayCount; track filterObj; let i = $index) {
                      <p
                        class="align-items-center d-flex">
                        <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'indicators'"
                          [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'indicators', i)">
                          <label [title]="filterObj.name" [htmlFor]="filterObj.name+'indicators'" [ngClass]="{'text-ellipsis-indicators ': filterObj.name !== 'all'}">
                            {{filterObj.name}}
                          </label>
                        </p>
                      }
                    </div>
                  </form>
                  <hr>
                  </div>
                }
                @if (showSchedulerType) {
                  <div>
                    <div class="content__header">type of scheduler
                    </div>
                    <form class="branded">
                      <div class="contract-balancing-side-panel-container">
                        @for (filterObj of secondaryFiltersObj.schedulertypes.schedulertypes_Array | slice:0:secondaryFiltersObj.schedulertypes.displayCount; track filterObj; let i = $index) {
                          <p
                            class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'schedulertypes'"
                              [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'schedulertypes', i)" [disabled]="filterObj.disabled">
                              <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'schedulertypes'">
                                {{filterObj.name}}
                              </label>
                            </p>
                          }
                          @if (secondaryFiltersObj.schedulertypes.displayCount < secondaryFiltersObj.schedulertypes.schedulertypes_Array.length) {
                            <p class="see-more-link" (click)="seeMoreValues('schedulertypes')">see more +</p>
                          }
                        </div>
                      </form>
                      <hr>
                      </div>
                    }
                    <div>
                      <div class="content__header">counterparty
                      </div>
                      <form class="branded">
                        <input class="form-control ethonal-view_search" style="font-family: GothamBold;" [name]="'searchText1'" type="search"
                          placeholder="search" (input)="updateFilterList($any($event.target).value, 'counterParty',0)" [value]="searchText[0]?.value">
                          <div class="contract-balancing-side-panel-container" [class.increase-container-height]="secondaryFiltersObj.counterParty.counterParty_Array.length > 4">
                            @for (filterObj of secondaryFiltersObj.counterParty.counterParty_Array | slice:0:secondaryFiltersObj.counterParty.displayCount; track filterObj; let i = $index) {
                              <p class="align-items-center d-flex">
                                <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'counterParty'" [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'counterParty', i)">
                                <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'counterParty'">
                                  {{filterObj.name}}
                                </label>
                              </p>
                            }
                            @if (secondaryFiltersObj.counterParty.displayCount < secondaryFiltersObj.counterParty.counterParty_Array.length) {
                              <p class="see-more-link" (click)="seeMoreValues('counterParty')">see more +</p>
                            }
                          </div>
                        </form>
                        <hr>
                        </div>
                        <div>
                          <div class="content__header">location
                          </div>
                          <form class="branded">
                            <input class="form-control ethonal-view_search" style="font-family: GothamBold;" [name]="'searchText2'" type="search"
                              placeholder="search" (input)="updateFilterList($any($event.target).value, 'location',1)" [value]="searchText[1]?.value">
                              <div class="contract-balancing-side-panel-container" [class.increase-container-height]="secondaryFiltersObj.location.location_Array.length > 4">
                                @for (filterObj of secondaryFiltersObj.location.location_Array | slice:0:secondaryFiltersObj.location.displayCount; track filterObj; let i = $index) {
                                  <p class="align-items-center d-flex">
                                    <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="filterObj.name+'location'" [value]="filterObj.name" [checked]="filterObj.checked" (change)="filterData($event, 'location', i)">
                                    <label class="text-ellipsis" [title]="filterObj.name" [htmlFor]="filterObj.name+'location'">
                                      {{filterObj.name}}
                                    </label>
                                  </p>
                                }
                                @if (secondaryFiltersObj.location.displayCount < secondaryFiltersObj.location.location_Array.length) {
                                  <p class="see-more-link" (click)="seeMoreValues('location')">see more +</p>
                                }
                              </div>
                            </form>
                            <hr>
                            </div>
                          </ng-container>
                        </div>

                        <div class="panel-footer">
                          <span class="clear-filter" (click)="resetFilters()">reset</span>
                          <span class="apply-filter" (click)="applyFilters()">apply</span>
                        </div>