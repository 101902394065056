import { createAction, props } from "@ngrx/store";



  //dynamic deals
  export const loadDynamicDeals = createAction(
    '[Dynamic Deals API] Load Dynamic Deals',
    props<{payload:any, fromDateChange:boolean}>()
  );
  
  export const loadDynamicDealSuccess = createAction(
    '[Dynamic Deals API] Load Dynamic Deals API Success',
    props<{ cbDynamicDeals: any[],fromDateChange:boolean }>(),
  );
  
  export const loadDynamicDealFailure = createAction(
    '[Dynamic Deals API] Load Dynamic Deals API Failure',
    props<{ error: any }>(),
  );

  export const applyDDPrimaryFilter = createAction(
    '[Dynamic Deals Data Filters] Apply Dynamic Deals Primary Filter',
    props<{filters:any}>()
  );
 
  export const applyDDSecondaryFilter = createAction(
    '[Dynamic Deals Data Filters] Apply Dynamic Deals Secondary Filter',
    props<{filters:any}>()
  );

  export const resetDDSecondaryFilter = createAction(
    '[Dynamic Deals Data Filters] Reset Dynamic Deals Secondary Filter'
  );
  export const resetAllFilters = createAction(
    '[Dynamic Deals Data Filters] Reset Dynamic Deals All Filters'
  );

  export const selectMaterialTab = createAction(
    '[Dynamic Deals Data Filters] Select Material Tab',
    props<{tab:string}>()
  );
  export const toggleDealHeader = createAction(
    '[Dynamic Deals Data] Togggle Deal Header',
    props<{index:any}>()
  );

  export const updateCustomDateRangePicker = createAction(
    '[Dynamic Deals API] Refresh Custom Date Range Picker',
    props<{updateCustomDateRangePickerFlag:boolean}>()
  );

  export const applySelectedCBSort = createAction(
    '[Dynamic Deals Data Sort] Apply Dynamic Deals Sort',
    props<{cbSelectedSortType:any}>()
  );

  export const updateSortedData = createAction(
    '[Dynamic Deals Data Sort] Update Dynamic Deals Sorted Data',
    props<{data:any}>()
  );
  

  export const updateIndicators = createAction(
    '[Dynamic Deals Indicators] Update Indicators',
    props<{payLoad:any}>()
  );
  
  export const updateIndicatorsSuccess = createAction(
    '[Dynamic Deals Indicators] Update Indicators Success',
    props<{ payLoad:any,response:any }>(),
  );
  
export const updateIndicatorsFailure = createAction(
    '[Dynamic Deals Indicators] Update Indicators Failure'
  );

  export const updateIndicatorFilterValues = createAction(
    '[Dynamic Deals Indicators] Update Indicator filter values',
    props<{region:string}>()
  );
