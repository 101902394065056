<div class="panel-content">
  <!--TERMINAL MULTI-SELECTION-->
  <div>
    <hide-list [hideList]="true">
      <white-header header [displayText]="'terminal'" [arrowVisible]="true" [flipArrow]="true">
      </white-header>
      <div content class="d-flex flex-column option-row">
        <input class="form-control input-box" [name]="'locationSearch'" type="search"
          placeholder="search" [(ngModel)]="locationSearchTerm">
          @for (location of deskLocations | ruleFilter:locationSearchTerm; track location) {
            <mat-checkbox [(ngModel)]="location.checked" (change)="processSelection(location, 'deskLocations')"
              [matTooltip]="location.actualValue" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'">
              {{location.displayValue | lowercase}}
            </mat-checkbox>
          }
        </div>
      </hide-list>
    </div>
    <!--MATERIAL MULTI-SELECTION-->
    <div>
      <hide-list [hideList]="true">
        <white-header header [displayText]="'material'" [arrowVisible]="true" [flipArrow]="true">
        </white-header>
        <div content class="d-flex flex-column option-row">
          <input class="form-control input-box" [name]="'materialSearch'" type="search"
            placeholder="search" [(ngModel)]="materialSearchTerm">
            @for (material of materialsList | ruleFilter:materialSearchTerm; track material) {
              <mat-checkbox [(ngModel)]="material.checked" (change)="processSelection(material, 'materialsList')"
                [matTooltip]="material.actualValue" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'">
                {{material.displayValue | lowercase}}
              </mat-checkbox>
            }
          </div>
        </hide-list>
      </div>

      <!--RULE TYPE MULTI-SELECTION-->
      <div>
        <hide-list [hideList]="true">
          <white-header header [displayText]="'rule type'" [arrowVisible]="true" [flipArrow]="true">
          </white-header>
          <div content class="d-flex flex-column option-row">
            <input class="form-control input-box" [name]="'typeSearch'" type="search"
              placeholder="search" [(ngModel)]="ruleTypeSearchTerm">
              @for (type of ruleTypeList | ruleFilter:ruleTypeSearchTerm; track type) {
                <mat-checkbox [(ngModel)]="type.checked" (change)="processSelection(type, 'ruleTypeList')">
                  {{type.displayValue | lowercase}}
                </mat-checkbox>
              }
            </div>
          </hide-list>
        </div>

      </div>
      <div class="panel-footer">
        <span class="clear-filter" (click)="resetAllFilters(['deskLocations', 'materialsList', 'ruleTypeList'])">clear</span>
      </div>