<div class="d-flex flex-column content-width">
  <div (click)="toggleListVisibility()" class="hover-effect">
    @if (this.header !== '') {
      <ng-content select="[header]">
      </ng-content>
    }
  </div>
  @if (this.header === '' || !hideList) {
    <div>
      <ng-content select="[content]">
      </ng-content>
    </div>
  }
</div>