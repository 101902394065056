<div class="push-modal">
  @if (headerIcon.length || header.length) {
    <div class="d-flex justify-content-start">
      @if (modalPopupObjective === 'pushMcat') {
        <div [class]="headerIcon"></div>
      }
      @if (modalPopupObjective === 'linkingNomination') {
        <div [class]="headerLinkIcon"></div>
      }
      <div class="header">{{header}}</div>
    </div>
  }
  <div>&nbsp;</div>
  @if (modalPopupObjective !== 'pushMcat') {
    <div>&nbsp;</div>
  }
  <div class="description">{{linkedMsg}}</div>
  <div class="description">{{description}}</div>
  @if (modalPopupObjective !== 'pushMcat') {
    <div>&nbsp;</div>
  }
  <!-- <div *ngIf="message">&nbsp;</div> -->
  <div class="message" >{{message}}</div>
  <div>&nbsp;</div>
  @if (modalPopupObjective === 'pushMcat') {
    <mat-checkbox>mcat</mat-checkbox>
  }
  <ng-content></ng-content>
</div>