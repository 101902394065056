<div class="create-cca-cargo">
  <form [formGroup]="createCcaNominationForm" (keydown.enter)="$event.preventDefault()">
    <div class="input-wrap">
      <div class="form-row mr-3 pb-1 pr-2 d-flex">
        <div class="form-group form-top-content col-md-3">
          <div class="form-group">
            <label for="cargoName" class="label"><span class="text-danger">* </span>cargo name</label>
            <input type="text" formControlName="cargoName" class="form-control" id="cargoName"
              [defaultValue]="cargoName"
              >
            </div>
            <div class="form-group" >
              <label for="vessel" class="label" > <span class="text-danger">* </span> vessel</label>
              <app-select-dropdown [items]="vesselArray" id="vessel" formControlName="vesselName"
                [defaultValue]="vesselNameValue"
                (afterChange)="onVesselSelect()"
                >
              </app-select-dropdown>
            </div>
          </div>
          <div class="form-group form-top-content col-md-3">
            <div class="form-group" >
              <label for="planName" class="label" > <span class="text-danger">* </span> plan name</label>
              <app-select-dropdown [items]="masterDeskList[0]" id="planName" formControlName="planName"
                [defaultValue]="planNameValue"
                (afterChange)="onPlanSelect()"
                [disabled]="cpService.isEditCargo"
                >
              </app-select-dropdown>
            </div>
            <div class="form-group" >
              <label for="vesselType" class="label" > <span class="text-danger">* </span> vessel type</label>
              <app-select-dropdown [items]="vesselTypeList" id="vesselType" formControlName="vesselType"
                [defaultValue]="vesselTypeValue"
                (afterChange)="onVesselTypeSelect()"
                >
              </app-select-dropdown>
            </div>
          </div>
          <div class="form-group form-top-content col-md-2">
            <div class="form-group" >
              <label for="canalCrossingDate" class="label" > canal crossing date</label>
              <input
                type="date"
                class="form-control text-field date-field"
                formControlName="canalCrossingDate"
                (change)="onSetCanalCrossing()"
                >
              </div>
            </div>
            <div class="form-group col-md-3">
              <div class="form-group col-md-12" >
                <label htmlFor ="comments" class="label">comments </label>
                <textarea  class="form-control nomNotes" formControlName="comments" maxlength="120" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>

        <ng-container formArrayName="transport">
          @for (transportControl of transportArray().controls; track transportControl; let i = $index) {
            <ng-container [formGroupName]="i">
              <div class="table-wrapper">
                <div>
                  <table aria-describedby="origin">
                    <tr>
                      <th><span class="text-danger">* </span> load/discharge</th>
                      <th><span class="text-danger">* </span> desk</th>
                      <th><span class="text-danger">* </span> port</th>
                      <th> terminal</th>
                      <th><span class="text-danger">* </span> supplier/receiver</th>
                      <th><span class="text-danger">* </span> laycan start</th>
                      <th><span class="text-danger">* </span> laycan end</th>
                      @if (planBasedProducts?.length) {
                        <th>total</th>
                        @for (product of planBasedProducts; track product) {
                          <th>{{allProductList?.[product]?.includes('BO') ? 'BO (Base oils)' : allProductList[product]}}</th>
                        }
                      }
                      <th></th>
                    </tr>
                    <ng-container formArrayName="origin">
                      @for (list of origin(i).controls; track list; let j = $index) {
                        <ng-container
                          [formGroupName]="j">
                          <tr>
                            <td>
                              <span>
                                <div class="statwidth"> <app-select-dropdown
                                  [items]="cargoModeList" formControlName="loadDischarge"
                                  [defaultValue]="loadDischargeArray[i][j]"
                                  (afterChange)="onSetLoadDischarge(j)">
                                </app-select-dropdown>
                              </div>
                            </span>
                          </td>
                          <td>
                            <span>
                              <div class="statwidth"> <app-select-dropdown
                                [items]="masterDeskList[j]" formControlName="desk"
                                [defaultValue]="deskList[i][j]"
                                (afterChange)="onDeskPortTerminalChange(j)">
                              </app-select-dropdown>
                            </div>
                          </span>
                        </td>
                        <td>
                          <div class="statwidth"> <app-select-dropdown
                            formControlName="port" [items]="portOptions[j]"
                            [defaultValue]="portcca[i][j]"
                            (afterChange)="onDeskPortTerminalChange(j)">
                          </app-select-dropdown>
                        </div>
                      </td>
                      <td>
                        <div  class="statwidth"> <app-select-dropdown
                          formControlName="terminal" [items]="terminalData[j]"
                          [defaultValue]="terminalCCA[i][j]"
                          (afterChange)="onDeskPortTerminalChange(j)">
                        </app-select-dropdown>
                      </div>
                    </td>
                    <td>
                      <div class="statwidth">
                        <app-select-dropdown [items]="supplierList"
                          formControlName="supplierReceiver"
                          [defaultValue]="supplierCCA[i][j]"
                          (afterChange)="onSetSupplierReceiver(j)"
                          >
                        </app-select-dropdown>
                      </div>
                    </td>
                    <td>
                      <input
                        type="date"
                        class="form-control text-field date-field"
                        formControlName="lyacanStartDate"
                        [min]="loadDischargeArray[0][j] === 'Discharge' ? minDate : ''"
                        (change)="onLyacanStartSet(j)"
                        >
                      </td>
                      <td>
                        <input
                          type="date"
                          class="form-control text-field date-field"
                          formControlName="lyacanEndDate"
                          [min]="dateArray[j] && dateArray[j]?.start ? dateArray[j].start : minDate"
                          (change)="onLyacanEndSet(j)"
                          [ngClass]="{'disable-date-input': !dateArray[j]?.start}"
                          >
                        </td>
                        @if (planBasedProducts?.length) {
                          <td><input class="form-control text-field" readonly  formControlName="loadDischargeTotal"></td>
                          @for (product of planBasedProducts; track product) {
                            <td>
                              <input type="number" (change)="validateProducts()" class="form-control text-field" min="0" onkeydown="javascript: return (event.keyCode == 69 || event.keyCode == 189 || event.keyCode == 190) ? false : true"
                                [formControlName]="product">
                              </td>
                            }
                          }
                          <td>
                            @if (this.transportArray()?.at(i)?.get('origin')?.value?.length > 1) {
                              <div class="custom-dropdown">
                                <button class="icon-btn action-icon action-button"></button>
                                <div class="dropdown-menu-wrap">
                                  <div class="dropdown-menu">
                                    @if (this.transportArray()?.at(i)?.get('origin')?.value?.length > 1) {
                                      <button
                                        class="dropdown-item action-btn-item text-danger"
                                        (click)="deleteOriginRow(i,j)">delete
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>
                            }
                          </td>
                        </tr>
                      </ng-container>
                    }
                    @if (planBasedProducts?.length) {
                      <tr class="loadTotal">
                        <th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                        <th>total</th>
                        @for (product of planBasedProducts; track product) {
                          <td>{{destinQtyValue[product]}}</td>
                        }
                      </tr>
                    }
                  </ng-container>
                </table>
                <div class="my-3 d-flex">
                  <button class="button button-text" (click)="addOriginRow(i)">
                    add row
                  </button>
                </div>
                @if (qtyError) {
                  <div class="text-danger mb-1 mt-3">
                    <div>
                      The load and discharge volumes for {{qtyError}} do not match.
                    </div>
                  </div>
                }
                @if (vesselTypeValue) {
                  <div class="text-danger mb-3">
                    @if (loadMinMaxError) {
                      <div>
                        {{this.loadTotal < tierMin ? 'Total load quantity is less than the capacity of vessel type' :
                        this.loadTotal > tierMax ? 'Total load quantity is more than the capacity of vessel type' : ''}}
                      </div>
                    }
                    @if (dischargeMinMaxError) {
                      <div>
                        {{this.dischargeTotal < tierMin ? 'Total dicharge quantity is less than the capacity of vessel type' :
                        this.dischargeTotal > tierMax ? 'Total discharge quantity is more than the capacity of vessel type' : ''}}
                      </div>
                    }
                    @if (isCanalCrossingError) {
                      <div>
                        {{isCanalCrossingError}}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </ng-container>
        }
      </ng-container>
      <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-6">
          <button class="button" (click)="closePopup()">cancel</button>
        </div>
        <div class="form-group col-md-6" >
          <button class="button" (click)="submitForm() "
          [disabled]="!createCcaNominationForm.valid" >{{cpService.isEditCargo?"update":"create"}}</button>
        </div>
      </div>
    </form>
  </div>