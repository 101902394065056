<div appCloseDropdownDircetive (clickedOutSide)="cancel($event)" class="custom-date-range-wrapper">
  <div class="custom-date-range-input-container">
    <ng-container >
      <mat-form-field class="input-container__form-field">
        <input matInput (focus)="openCustomPicker()" readonly #selectedDateRange class="form-field__input">
        <mat-datepicker-toggle matSuffix (click)="openCustomPicker()"></mat-datepicker-toggle>
      </mat-form-field>
    </ng-container>
  </div>

  @if (showCustomPicker) {
    <div class="custom-picker-wrapper" #customDateRangePickerRef>
      <div class="custom-picker-wrapper__header">
        <div class="header__tab-header">
          @for (tab of pickerTabs; track tab) {
            <div class="tab" (click)="displayTabContent(tab)" [class.tab-selected]="tab.isTabSelected">
              {{tab.label}}
            </div>
          }
        </div>
        <div class="header__spacer"></div>
        <div class="header__tab-content">
          <div class="header__tab-content-info">
            @if (selectedTab?.label === 'month') {
              <div class="tab-content__selected-year-month">{{convertedSelectedYears[0]}}</div>
            }
            @if (selectedTab?.label === 'month') {
              <div class="tab-content__selected-year-month">choose up to 3 consecutive months to filter</div>
            }
            @if (selectedTab?.label === 'year') {
              <div class="tab-content__selected-year-month">{{getSelectedMonths}}</div>
            }
            @if (selectedTab?.label === 'year') {
              <div class="tab-content__selected-year-selection">
                <span class="selected-years">{{selectedYearRange}}</span>
                <div class="year-action-icons">
                  <span class="left-caret-icon" (click)="decreaseYearRange()"></span>
                  <span class="right-caret-icon" (click)="increaseYearRange()"></span>
                </div>
              </div>
            }
          </div>
          @if (selectedTab?.label === 'month') {
            <div class="tab-content-month">
              <div class="month-grid">
                @for (month of monthsArr; track month; let i = $index) {
                  <div class="month-wrapper" [class.selected]="isSelected(month)" (click)="toggleMonthSelection(month,i)">
                    <span class="month-label" [class.month-selected]="isSelected(month)">{{month}}</span>
                    @if (isSelected(month)) {
                      <span class="tick-mark"></span>
                    }
                  </div>
                }
              </div>
            </div>
          }
          @if (selectedTab?.label === 'year') {
            <div class="tab-content-year">
              <div class="year-grid">
                @for (year of years; track year) {
                  <div class="year-wrapper" [class.selected]="isYearSelected(year)" (click)="toggleYearSelection(year)">
                    <span class="year-label" [class.year-selected]="isYearSelected(year)">{{year}}</span>
                  </div>
                }
              </div>
            </div>
          }
          @if (selectedTab?.label === 'custom') {
            <div class="tab-content-custom">
              <div class="custom-grid">
                <mat-calendar class="custom-date-range-picker" [selected]="selectedRangeValue" (selectedChange)="selectedChange($event)" [startAt]="startAt" >
                </mat-calendar>
              </div>
            </div>
          }
        </div>
      </div>
      <div class="custom-picker-wrapper__footer" [class.action-button]="selectedTab.label === 'custom'">
        <div class="footer-reset" (click)="reset()">reset</div>
        <span class="spacer"></span>
        <div class="footer-cancel" (click)="cancel()">cancel</div>
        <div class="footer-apply" (click)="apply()">apply</div>
      </div>
    </div>
  }
</div>
