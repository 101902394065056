import { savePipelineNomFiltersResponse, savePipelineTRFiltersUpdated, setPipelineFiltersResponse } from '../../../shared/store/action/app.action';
import { Component, EventEmitter, OnInit, Output, OnDestroy, ViewChild, Input, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NominationService } from '../../../services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { DatePipe, Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Subject, Subscription, take, takeUntil } from 'rxjs';
import { getPipleineFilteredValues, selectDesk, selectedDeskFromSearch, selectedMdmSearch } from 'src/app/shared/store/selector/app.selector';
import * as Nom from '../../../shared/constants/nominations.constant';
import { SelectDropdownComponent } from 'src/app/shared/modules/select-dropdown/select-dropdown.component';
import { HomeComponent } from 'src/app/home/home.component';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import {  nomPopSpecific } from 'src/app/shared/constants/terminal-replenishment.constant';
import { LoaderService } from 'src/app/services/loader.service';
import { MdmService } from 'src/app/services/mdm.service';

interface RdiDescriptionOrigin {
  rdi: string;
  description: string;
}
interface RdiDescriptionDesc {
  key: string;
  value: string;
}
interface RdidescTransport {
  rdiPort: string;
  descPort: string;
}
@Component({
  selector: 'app-create-refinery-nomination',
  templateUrl: './create-refinery-nomination.component.html',
  styleUrls: ['./create-refinery-nomination.component.scss']
})
export class CreateRefineryNominationComponent implements OnInit, OnDestroy {
  @ViewChild("SelectDropdownComponent") selectDropdown!: SelectDropdownComponent;
  @ViewChild("transportSystemDropdown") transportSystemDropdDown!: SelectDropdownComponent;
  @ViewChild("contractDropdown") contractDropdown!: SelectDropdownComponent;
  //@Input() deskSelectedNom!: string;
  public createPipelineNominationForm!: FormGroup;
  @Output() closeModalPopup = new EventEmitter();
  @Input() isPipelineNomEdit: any;
  isMultipleTransport: boolean = false;
  hideDestination: boolean = true;
  showMultipleTables: boolean = false;
  isAdditionalData: boolean = false;
  isUpdatePublishClicked :boolean=false;
  nominationData!: any;
  trNomination!: any;
  public locationKeyValue: any;
  public demandKeyValue: any;
  trNominationForPipeline: any;
  productList: any = []
  productListMaterialCode:any =[];
  masterProductList: any = []
  terminalList: any = [[]]
  masterTerminalList: any = [[]]
  terminalListDesc: any = [[]]
  demandProductList: any = [[]]
  demandProductListDesc: any = [[]]
  individualList: any = []
  individualListMaterialCode:any =[]
  transportSystemArray: any = []
  transportSystemModeArray: any = []
  transportDescArray: any = []
  selectedDeskParameter: any
  selectedDeskId: number = 0;
  // isCargoDesks: any;
  public referenceNominations: any;
  getRefData: any;
  public userDetails!: any;
  inValidDestinationLocation: boolean = false;
  invalidDestinationLocationsg: string = '';
  intankEast: string = 'ITE'
  intankWest: string = 'ITW'
  isMultipleRowItemOrigin:any = [['O']];
  isMultipleRowItemDestination:any = [['D']];
  isMultipleRowTransport = 0;
  isMultipleRowItemIte:any = [['IT']];
  getPipleineFilteredValues$ = this.store.pipe(select(getPipleineFilteredValues));
  desk$ = this.store.pipe(select(selectDesk))
  selectedMdmSearch$ = this.store.pipe(select(selectedMdmSearch))
  destroy$: Subject<boolean> = new Subject<boolean>();
  getPipelineFilterResponse!: any;
  selectedRdi: any = '';
  selectedDescription: string = '';
  selectedTransportOption: string = '';
  isMultipleTransportDisabled: boolean = false;
  selectedDeskOption!: any;
  iteProduct: any;
  destiProduct: any;
  originProduct: any;
  originRDIArray: string[] = [];
  originRDIDescArray: string[] = [];
  iteRDIDescArray: string[] = [];
  iteRDIArray: string[] = [];
  inValidLocationMsg: any;
  inValidLocation!: boolean;
  isOldTransportITE: boolean = false
  isUserInteraction : boolean = false;
  isOldTransport: boolean = true;
  updateDraftReference: boolean = false
  isnotcreatedFromReforEdited: boolean = true
  destinationRDIArray: string[] = [];
  destinationRDIDescArray: string[] = [];
  qtyDiff: number = 0;
  destinationRDIDescSelected: any = [[]];
  originRDIDescSelected: any = [[]];
  destinationRDISelected: any = [[]];
  inTankRDIDescSelected: any = [[]];
  inTankRDISelected: any = [[]];
  originRDISelected: any = [[]];
  locationOriginArray: any = [[]]
  locationDestiArray: any = [[]]
  locationIteArray: any = [[]]
  demandProductOrigin: any = [[]]
  demandProductDesti: any = [[]]
  demandProductIte: any = [[]]
  region: string = '';
  defaultTransportSystem!: any
  scheduledTime: any =[['00:00']]
  scheduledTimeDesti: any = [['00:00']]
  defaultProduct: any = [[]]
  defaultProductdesti: any = [[]]
  defaultTransportModalSystem: any = []
  isOriginRequired = [false];
  isDestinationRequired = [false];
  isTransportChange:boolean=true;
  isPopupClosed = false;
  originQtyValue = [0];
  destinQtyValue = [0];
  deletelist: any = []
  deletedValue: boolean = false;
  olddeletedValue: boolean = false;
  inCpTab!:boolean;
  inCpNomTab!:boolean;
  deskNumber!: any;
  // motData:any = [];
  isSearchPopup = false;
  scheduledDemandProductList: any = [[]]
  scheduledDemandProductListDesc: any = [[]]
  scheduledDemandProductListMaterialCode:any=[[]]
  scheduledDemandProductListDescMaterialCode:any=[[]]
  demandProductListMaterialCode:any=[[]]
  demandProductListDescMaterialCode:any=[[]]
  defaultProductMaterialCode: any = [[]]
  defaultProductdestiMaterialCode: any = [[]]
  demandProductOriginMaterialCode: any = [[]]
  demandProductDestiMaterialCode: any = [[]]
  demandProductIteMaterialCode: any = [[]]
  errorMessage= [false];
  deletedTransportSys:any=[];
  delEleArr:any=[];
  scenario: any = "barge";
  nomCargoFieldObj ={
    bargeFields:true,
    vesselFields:false,
    pipelineFields:false,
    inTankFields:false,
    railFields:false
  }
  addCheckbox:boolean = false;
  vesselNameValue: any = "";
  vesselArray:any = [[]];
  bargeArray:any = [[]];
  masterVesselArray:any = []; 
  private subscription!: Subscription;
  private subs!: Subscription;
  originPos: any = 0;
  openRefCount: number = 0;
  refOrginCount: any = 0;
  originArray: any;
  destinationArray: any;
  refDesCount: number = 0;
  masterProductListForCp:any =[];
  masterTerminalListForCp: any = [];
  createCargoWithRef: boolean = false;
  masterApiCount: number = 0;
  path$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.location.path()
  );
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  selectedDeskFromSearch$ =  this.appStore.pipe(select(selectedDeskFromSearch))

  modalClosed: any;
  getNomData: any;
  transportData: any;
  deskFilaterData :any = {};
  pipelineAPIFilterTriggered = false;
  transportApiCall: number =0;
  masterVesselApiCount: number = 0;
  cargoId: string='';
  intankItemId: any;
  sapcontractValidation: any;
  sapcontractValidationOrigin: any;
  contractLineItemsOrigin: any = [[]];
  contractLineItemsDestination: any = [[]];
  contractLineItemsITE: any = [[]];
  refContractLineItems: any = [[]];
  refContractLineItemsIte: any = [[]];
  updatedContractLineItems: any = [];
  refContractLineItemsDesti: any = [[]];
  getContractNumber: any;
  fromSearchCalled: any ;
  autoAdjustmentData: any;
  originScheduledDate: any;
  destinationScheduledDate: any;
  originClickScheduledDate: any;
  destinationClickScheduledDate: any;
  originCount = 0;
  destinationCount = 0;
  originClickCount = 0;
  destinationClickCount = 0;
  initialOriginScheduledDate: any;
  initialDestinationScheduledDate: any;
  getLocation: any;
  getScheduledDate: any;
  getMaterial: any;
  currentNominationNo: string = '';
  autoAdjustmentInitialDates: any = [];
  isEveryLineItemIT: any = [];
  originDestinationScheduleTypes = ['O','D'];
  pipelineModalPopupData:any;
  action = 'contract';
  tankMasterData: any;
  defaultTankOrigin: any = [[]];
  defaultTankDesti : any = [[]];
  tankOriginList: any = [[]];
  tankDestiList: any = [[]];
  isTankOriginDisabled : any =[[]];
  isTankDestiDisabled: any =[[]];
  istankEnabled: boolean = false;
  isDestitankEnabled: boolean = false;
  locationTransportSystemMasterData: any;
  
  demandProduct: any;

  constructor(private fb: FormBuilder,
    public nominationService: NominationService,
    private toasterService: ToastService,
    private replenishmentPlanningService: ReplenishmentPlanningService,
    private utilities: Utilities,
    private datePipe: DatePipe,
    private store: Store,
    private homeComponent:HomeComponent,
    private appStore: Store<AppState>,
    private location: Location,
    private cpService: CargoPlanningService,
    private myMonitoringService: MyMonitoringService,
    private loaderService: LoaderService,
    private mdmService: MdmService
  ) {
    this.createPipelineNominationForm = this.fb.group({
      nominationNo: ['', [Validators.required, Validators.maxLength(16)]],
      transportSystem: ['', Validators.required],
      sapcontract: new FormControl(),
      selectMultipleCheckbox: [false],
      selectMultipleCheckbox1: [false], // Initial value set to false (unchecked)
      transport: this.fb.array([this.createFormGroupTransport(null)]),
      nominationNotes:'',
      btCustodyChain:'',
      cycleId: '',
      isoriginDestiTouched: ['', Validators.required],
      isTransportpicked:['', Validators.required],
      nominationKey:'',
      tankCode: ''

    },{ validator: [this.customRequiredValidatorHeader.bind(this)] });


  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   throw new Error('Method not implemented.');
  // }
  rdiDescriptionMap: RdiDescriptionOrigin[] = [
    { rdi: 'K', description: 'Purchase into in-transit / Contract' },
    { rdi: 'G', description: 'Sales into in-transit / Contract' },
    { rdi: 'X', description: 'Transfer into in-transit / Reservation' }
  ];
  keyvalueMap: RdiDescriptionDesc[] = [
    { key: 'K', value: 'Purchase into destination / Contract' },
    { key: 'G', value: 'Sales out of in-transit  / Contract' },
    { key: 'X', value: 'Transfer into location / Reservation' }
  ];

  rdiDescTransport: RdidescTransport[] = [
    { rdiPort: 'K', descPort: 'Purchase into destination / Contract' },
    { rdiPort: 'G', descPort: 'Sales into in-transit / Contract' }
  ];

  toggleAddi(){
      this.addCheckbox = !this.addCheckbox;
      if(this.addCheckbox){
        this.isAdditionalData = true
      }
      else{
        this.isAdditionalData = false
      }
    
  }


  ngOnInit(): void {  
        this.selectedDesk$
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: any) => {
              if((response.selectedDeskParam != this.selectedDeskParameter) || response.isUpdated){
                this.closePopup(true, true);
              }
              this.selectedDeskParameter = response.selectedDeskParam;
              this.selectedDeskId = +response.selectedDeskId;
              // this.isCargoDesks = this.selectedDeskParameter === 'floridadesk' || this.selectedDeskParameter === 'pnwdesk' || this.selectedDeskParameter === 'pascagouladesk' || this.selectedDeskParameter === 'richmonddesk';
              this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
            },
            error: (err: any) => { },
            complete: () => { },
          });
        
       

        if (!!this.selectedDeskParameter && this.selectedDeskParameter !== null && this.selectedDeskParameter !== "null") {
          this.getMasterDataForCargo();
        }
        this.getTankData();      
        // if (this.nominationService?.isNominationScreen === "cargo") {
        //   this.inCpTab = true;
        //   this.inCpNomTab = false;
        //   this.nominationService.isNominationForCP = false;
        //   this.modifyFormForCp();
        //   // this.updateFormFields('barge', false);
        //   this.rdiDescriptionMapFn();
        // } else 
        if (this.nominationService?.isNominationScreen === "nomination") {
          this.inCpTab = false;
          this.inCpNomTab = true;
          this.nominationService.isNominationForCP = true;
          // this.modifyFormForCp();
          // this.updateFormFields('barge', false);
          this.rdiDescriptionMapFn();
        }
        this.initialValues(false)       
        this.rdiDescriptionMapFn();
        this.subs = this.nominationService.triggerMasterCargoAPI.subscribe(res => {
          if (res && this.masterApiCount === 0) {
            this.getMasterDataForCargo();
          }

          if (res && this.masterVesselApiCount === 0) {
            this.getMasterVesselData();
          }
        })
        this.subscription = this.cpService?.createNominationFromCargoWithReference?.subscribe(data => {
          if (data?.initiate) {
            this.inCpNomTab = true;
            this.inCpTab = false;
            this.createNominationWithReference(data?.data);
          } else {
            this.inCpNomTab = (this.nominationService?.isNominationScreen === "nomination" && this.path$.value !== '/replenishmentPlanning') ? true : false;  
            this.inCpTab = (this.nominationService?.isNominationScreen === "cargo") ? true : false;  
          }
        });
      }

  async getTankData(){
    this.tankMasterData = await this.utilities.getTankData();
  }

  async getLocationByTransportSystem(transportSystem: any) {
    let locationData: any;
      const transportSystemIds = transportSystem?.map((item: any) => item.transportSystemCode);
      const payload = {
        "transportSystems" : transportSystemIds
      }
      locationData = await firstValueFrom(this.mdmService.getLocationByTransportSystem(payload));
      
    this.locationTransportSystemMasterData =  locationData?.value;
  }
  
  getMasterVesselData(vessel?:any){
    this.masterVesselApiCount = 1;
    if ((this.scenario?.includes('vessel') || this.scenario?.includes('barge'))) {
      let cargoPayload: any = {
        desk: `cpvessels`,
        mot: this.scenario?.charAt(0).toUpperCase() + this.scenario.slice(1),
      }

      this.mdmService.getVehicles(this.scenario === 'barge' ? 'B' : 'S').subscribe({
        next: (response: any) => {
          if (response?.success && response?.statusCode === 200) {
            this.masterVesselApiCount = 0;
            let vesselResponse = response.value.value.map((ele: any) => {
              return {
                vehicleCode: ele.vehicleIdentifier,
              vesselId: ele.vehicle,
              vesselName: ele.vehicleName
              }
          })
            this.masterVesselArray = vesselResponse;
            this.vesselArray = vesselResponse?.map((ts:any) => ts.vesselName);
            if(vessel){
              this.createPipelineNominationForm.get("vesselName")?.setValue(vessel);
            }
            this.checkForVesselvalue();
          }
        }
      });
    }
  }


  // updateFormFields(data: any, openModal:boolean =true) {  
  //   // if(!(this.isCargoDesks)){
  //     return;
  //   // }
   
  //   this.scenario = data;
    
  //   this.modifyFormForCp(data?.toLowerCase(), true);
  //   if (data?.toLowerCase() === 'barge') {
  //     this.bargeValidations();
  //     this.nomCargoFieldObj.bargeFields = true;
  //     this.nomCargoFieldObj.vesselFields = false;
  //     this.nomCargoFieldObj.pipelineFields = false;
  //     this.nomCargoFieldObj.inTankFields = false;
  //     this.nomCargoFieldObj.railFields = false;
  //   }
  //   else if (data?.toLowerCase() === 'vessel') {
  //     this.vesselValidations();
  //     this.nomCargoFieldObj.bargeFields = false;
  //     this.nomCargoFieldObj.vesselFields = true;
  //     this.nomCargoFieldObj.pipelineFields = false;
  //     this.nomCargoFieldObj.inTankFields = false;
  //     this.nomCargoFieldObj.railFields = false;
  //   }
  //   else if (data?.toLowerCase() === 'pipeline') {
  //     this.pipelineValidations();
  //     this.nomCargoFieldObj.bargeFields = false;
  //     this.nomCargoFieldObj.vesselFields = false;
  //     this.nomCargoFieldObj.pipelineFields = true;
  //     this.nomCargoFieldObj.inTankFields = false;
  //     this.nomCargoFieldObj.railFields = false;
  //   }
  //   else if (data?.toLowerCase() === 'intank') {
  //     this.inTankValidations();
  //     this.nomCargoFieldObj.bargeFields = false;
  //     this.nomCargoFieldObj.vesselFields = false;
  //     this.nomCargoFieldObj.pipelineFields = false;
  //     this.nomCargoFieldObj.inTankFields = true;
  //     this.nomCargoFieldObj.railFields = false;
  //   }else{
  //     this.railValidations();
  //     this.nomCargoFieldObj.bargeFields = false;
  //     this.nomCargoFieldObj.vesselFields = false;
  //     this.nomCargoFieldObj.pipelineFields = false;
  //     this.nomCargoFieldObj.inTankFields = false;
  //     this.nomCargoFieldObj.railFields = true;
  //   }
  //   if (!this.createCargoWithRef && openModal || openModal) {
  //     this.getMasterDataForCargo();
  //     this.getMasterVesselData();
  //   }
  // }
  removeFormControls(){
    // if(this.createPipelineNominationForm.controls['tug']){
    //   this.createPipelineNominationForm.removeControl('tug');
    // }
    if(this.createPipelineNominationForm.controls['vesselName']){
      this.createPipelineNominationForm.removeControl('vesselName');
    }
    // if(this.createPipelineNominationForm.controls['bargeName']){
    //   this.createPipelineNominationForm.removeControl('bargeName');
    // }
    if(this.createPipelineNominationForm.controls['cycleId']){
      this.createPipelineNominationForm.removeControl('cycleId');
    }
    // if(this.createPipelineNominationForm.controls['railName']){
    //   this.createPipelineNominationForm.removeControl('railName');
    // }
  }
  // bargeValidations(){
  //   this.removeFormControls()
  //   if(!this.createPipelineNominationForm.controls['tug']){
  //     this.createPipelineNominationForm.addControl('tug', new FormControl(''));
  //   }
  //   if(!this.createPipelineNominationForm.controls['vesselName']){
  //     this.createPipelineNominationForm.addControl('vesselName',new FormControl('',Validators.required));
  //   }
    
  // }
  // cycleValidations(){
  //   if(!this.createPipelineNominationForm.controls['cycleId']){
  //     this.createPipelineNominationForm.addControl('cycleId', new FormControl(''));
  //   }
    
  // }

  // vesselValidations(){
  //   this.removeFormControls()
  //   if(!this.createPipelineNominationForm.controls['vesselName']){
  //     this.createPipelineNominationForm.addControl('vesselName',new FormControl('',Validators.required));
  //   }
  // }

  pipelineValidations(){
    this.removeFormControls()
    if(!this.createPipelineNominationForm.controls['cycleId']){
      this.createPipelineNominationForm.addControl('cycleId', new FormControl(''));
    }
    this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({ 'invalid': true });

  }

  // inTankValidations(){
  //  this.pipelineValidations();
  // }

  // railValidations(){
  //   this.removeFormControls()
  //   if(!this.createPipelineNominationForm.controls['railName']){
  //     this.createPipelineNominationForm.addControl('railName', new FormControl('', Validators.required));
  //     this.createPipelineNominationForm.get("railName")?.addValidators(Validators.required);
  //   }
  // }


  // modifyFormForCp(scenario?:any, withRef?:boolean){  
  //   this.motData = [];
  //   let tempNomData = Nom.cargoMots;

  //   if(this.inCpTab && !this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
  //     if(withRef && scenario){
  //       tempNomData.forEach((nom:any, index:any) => {
  //         this.motData.push({key:nom.key, value:nom.key, checked: nom.key === scenario})
  //       });
  //     }
  //     else{
  //       tempNomData.forEach((nom:any, index:any) => {
  //         this.motData.push({key:nom.key, value:nom.key, checked:index === 0})
  //       });
  //     }
     
  //   }
  //   else if(withRef && scenario){
  //       let tempNomData = Nom.cargoNomMots;
  //       tempNomData.forEach((nom:any, index:any) => {
  //         if(this.inCpNomTab){
  //           this.motData.push({key:nom.key, value:nom.key, checked:nom.key.toLowerCase() === scenario.toLowerCase()})
  //         }else{
  //           this.motData.push({key:nom.key, value:nom.key, checked:index === scenario})
  //         }
          
  //       });
  //     }
  //     else{
  //       let tempNomData = Nom.cargoNomMots;
  //       tempNomData.forEach((nom:any, index:any) => {
  //         this.motData.push({key:nom.key, value:nom.key, checked:index === 0})
  //       });
  //     }
  // }

  initialValues(accessMethod: boolean = true) {
    this.userDetails = this.utilities.getUserName(this.nominationService.userDetails$.value._email);
    this.selectedDeskParameter = ''
    this.desk$.pipe(takeUntil(this.destroy$), take(1)).subscribe({
      next: (response: any) => {  
        this.selectedDeskParameter = response.selectedDeskParam?.toLowerCase();
        this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        // if (!this.isCargoDesks) {
          this.inCpTab = false;
          this.inCpNomTab = false;
          this.nomCargoFieldObj.vesselFields = false;
          this.nomCargoFieldObj.bargeFields = false;
          this.nomCargoFieldObj.pipelineFields = false;
          this.nomCargoFieldObj.inTankFields = false;
          this.nominationService.isNominationScreen = '';
        // }
        // if ((this.isCargoDesks)) {
        //   this.inCpTab = (this.cpService?.createNominationFromCargoWithReference?.value?.initiate) ? false : !this.nominationService.isNominationForCP;
        //   this.inCpNomTab = (this.cpService?.createNominationFromCargoWithReference?.value?.initiate) ? true : this.nominationService.isNominationForCP;
        //   this.nominationService.isNominationScreen = (this.nominationService.isNominationForCP) ? 'nomination' : 'cargo';
        //   this.modifyFormForCp();
        //   this.updateFormFields(this.scenario, accessMethod)
        // }
        
        this.rdiDescriptionMapFn();
        this.modalClosed = this.selectedDeskParameter;
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => {
      }
    })

    this.getPipleineFilteredValues$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {

        this.filteredListData(response, true, true);
      },
      error: (err: any) => { },
      complete: () => { }

    });
  //  }
   
  // })
  }

  async filteredListData  (response: any, isGetFilteredPipelineCalled: boolean, shouldGetFilteredResp: boolean){
  let urBody: any = {
    desk: `pl${this.selectedDeskParameter}`,
    region: this.region,
    includeXLocations: true,
    requestFor: "dropdown"
  }
  // const isUpdated = isGetFilteredPipelineCalled ? response.isUpdated : true // revisit
  const isUpdated = response.isUpdated;
  if ((!isUpdated || this.isPopupClosed) && (!this.inCpNomTab && !this.inCpTab) && urBody?.desk !== "pl" && urBody?.desk !== "plnull" && !!this.selectedDeskParameter) { //keep the transport array check 1st for short circuit
    // Call filter API whenever Filter list get updated.
    this.filteredListDataRefactor(response, shouldGetFilteredResp)
  }
}
 filteredListDataRefactor(response: any, shouldGetFilteredResp: any){
  if (!this.deskFilaterData[this.selectedDeskParameter] && !(this.pipelineAPIFilterTriggered)) { //keep the transport array check 1st for short circuit
    this.pipelineAPIFilterTriggered = true;
    this.isSearchOpen(response, shouldGetFilteredResp)
  } else {
    this.updatelists(this.deskFilaterData[this.selectedDeskParameter])
  }
 }
  async isSearchOpen(response: any, shouldGetFilteredResp: any){
    let resp: any;
    if (this.isSearchPopup) {
      let desk = this.deskNumber;

      // if (this.isCargoDesks) {

      //   resp = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
      // } else {

        this.fromSearchCalled = false;
        resp = await this.utilities.getPipelineDropdownListforSearch(desk)
      // }
    }
    else{
      // if (this.isCargoDesks) {

      //   resp =  this.utilities.getPipelineCPDropdownList(this.scenario);
      // } else {
        resp = await this.utilities.getPipelineDropdownList();
      // }
    }
   
    this.pipelineAPIFilterTriggered = false;
    this.shouldFilterResponseCalled(resp, response, shouldGetFilteredResp)
  }
 shouldFilterResponseCalled(resp: any, response: any, shouldGetFilteredResp: any){
 
    if (Object.keys(resp?.value).length > 0) {
      if (!this.inCpTab) {
        this.updatelists(resp)
        this.deskFilaterData[this.selectedDeskParameter] = resp;
        if(shouldGetFilteredResp){
          // this.store.dispatch(setPipelineFiltersResponse({ value: resp?.value, isUpdated: false }));
        }
      }
    }
  }
  // updatelistsSearch(response: any){
  //   this.transportSystemArray = [];
  //   this.demandProductList = [[]]
  //   this.demandProductListMaterialCode = [[]]
  //   this.demandProductListDesc = [[]]
  //   this.demandProductListDescMaterialCode = [[]]
  //   this.scheduledDemandProductList = [[]]
  //   this.scheduledDemandProductListMaterialCode = [[]]
  //   this.scheduledDemandProductListDesc = [[]]
  //   this.scheduledDemandProductListDescMaterialCode = [[]]
  //   this.transportSystemModeArray = []
  //   this.masterTerminalList = [[]]
  //   this.productList= [];
  //   this.productListMaterialCode= [];
  //   this.terminalList =[[]];
  //   response?.value?.product.forEach((i: any) => {
  //     this.productList.push(i.productName)
  //     this.productListMaterialCode.push(i.productCode);
  //     this.masterProductList.push(i)
  //   })
  //   this.scheduledDemandProductList[0].push(this.productList)
  //   this.scheduledDemandProductListDesc[0].push(this.productList)
  //   this.demandProductList[0].push(this.productList)
  //   this.demandProductListDesc[0].push(this.productList)
  //   this.scheduledDemandProductListMaterialCode[0].push(this.productListMaterialCode)
  //   this.scheduledDemandProductListDescMaterialCode[0].push(this.productListMaterialCode)
  //   this.demandProductListMaterialCode[0].push(this.productListMaterialCode)
  //   this.demandProductListDescMaterialCode[0].push(this.productListMaterialCode)
  //   response?.value?.transportsystem.forEach((i: any) => {
  //     if ((i.transportSystemCode !== this.intankEast) && (i.transportSystemCode !== this.intankWest)) {
  //       this.transportSystemModeArray.push(i.transportSystemCode)

  //     }
  //     this.transportSystemArray.push(i.transportSystemCode)

  //     this.transportDescArray.push(i)
  //   })
  //   this.transportSystemModeArray = this.removeDuplicates(this.transportSystemModeArray);
  //   response?.value?.terminal.forEach((i: any) => {
  //     this.terminalList[0].push(i.terminalCode)
  //     this.masterTerminalList[0]?.push(i.terminalCode)
  //   })
  //   this.isPopupClosed = false;

  // }
  updatelists(response: any) {
    this.transportSystemArray = [];
    this.demandProductList = [[]]
    this.demandProductListMaterialCode = [[]]
    this.demandProductListDesc = [[]]
    this.demandProductListDescMaterialCode = [[]]
    this.scheduledDemandProductList = [[]]
    this.scheduledDemandProductListMaterialCode = [[]]
    this.scheduledDemandProductListDesc = [[]]
    this.scheduledDemandProductListDescMaterialCode = [[]]
    this.transportSystemModeArray = []
    this.masterTerminalList = [[]]
    this.productList= [];
    this.productListMaterialCode= [];
    response?.value?.product.forEach((i: any) => {
      this.productList.push(i.productName)
      this.productListMaterialCode.push(i.productCode);
      this.masterProductList.push(i)
    })
    this.scheduledDemandProductList[0].push(this.productList)
    this.scheduledDemandProductListDesc[0].push(this.productList)
    this.demandProductList[0].push(this.productList)
    this.demandProductListDesc[0].push(this.productList)
    this.scheduledDemandProductListMaterialCode[0].push(this.productListMaterialCode)
    this.scheduledDemandProductListDescMaterialCode[0].push(this.productListMaterialCode)
    this.demandProductListMaterialCode[0].push(this.productListMaterialCode)
    this.demandProductListDescMaterialCode[0].push(this.productListMaterialCode)
    response?.value?.transportsystem.forEach((i: any) => {
      if ((i.transportSystemCode !== this.intankEast) && (i.transportSystemCode !== this.intankWest)) {
        this.transportSystemModeArray.push(i.transportSystemCode)

      }
      this.transportSystemArray.push(i.transportSystemCode)

      this.transportDescArray.push(i)
    })
    this.transportSystemModeArray = this.removeDuplicates(this.transportSystemModeArray);
    // response?.value?.terminal.forEach((i: any) => {
    //   console.log(response.value)
    //   console.log(i)
    //   this.terminalList[0].push(i.terminalCode)
    //   this.masterTerminalList[0]?.push(i.terminalCode)
    // })
    if(response?.value?.transportsystem){
      this.getLocationByTransportSystem(response?.value?.transportsystem)
    }
    this.isPopupClosed = false;

  }
  rdiDescriptionMapFn() {
    // if(this.inCpTab){
    //   this.rdiDescriptionMap = [
    //     { rdi: 'K', description: 'Purchase into in-transit' },
    //     { rdi: 'G', description: 'Sales into in-transit' },
    //     { rdi: 'X', description: 'Transfer into in-transit' }
    //   ];
    //   this.keyvalueMap = [
    //     { key: 'K', value: 'Purchase into destination' },
    //     { key: 'G', value: 'Sales out of in-transit' },
    //     { key: 'X', value: 'Transfer into destination' }
    //   ];
    // }
    // else{
      this.rdiDescriptionMap = [
        { rdi: 'K', description: 'Purchase into in-transit / Contract' },
        { rdi: 'G', description: 'Sales into in-transit / Contract' },
        { rdi: 'X', description: 'Transfer into in-transit / Reservation' }
      ];
      this.keyvalueMap = [
        { key: 'K', value: 'Purchase into destination / Contract' },
        { key: 'G', value: 'Sales out of in-transit  / Contract' },
        { key: 'X', value: 'Transfer into location / Reservation' }
      ];
    // }
    // Clear the arrays before pushing values
    this.originRDIArray = [];
    this.originRDIDescArray = [];
    this.destinationRDIArray = [];
    this.destinationRDIDescArray = [];
    this.iteRDIArray = [];
    this.iteRDIDescArray = [];
    this.rdiDescriptionMap.forEach((item) => {
      this.originRDIArray.push(item.rdi);
      this.originRDIDescArray.push(item.description);
    })
    this.keyvalueMap.forEach((item) => {
      this.destinationRDIArray.push(item.key);
      this.destinationRDIDescArray.push(item.value);
    })
    this.rdiDescTransport.forEach((item) => {
      this.iteRDIArray.push(item.rdiPort);
      this.iteRDIDescArray.push(item.descPort);
    })
  }
  origin(index: number) {
    return this.transportArray().at(index).get('origin') as FormArray;
  }
  destination(index: number) {
    return this.transportArray().at(index).get('destination') as FormArray;
  }
  ite(index: number) {
    return this.transportArray().at(index).get('ite') as FormArray;
  }
  transportArray() {
    return this.createPipelineNominationForm.get('transport') as FormArray;
  }
  resetOriginFormControl() {
    let transport=this.createPipelineNominationForm.get('transport') as FormArray;
    let iteArray=transport.at(0).get('ite') as FormArray;
    let demandProduct= iteArray.at(0).get('demandProduct')?.value;
    let date= iteArray.at(0).get('scheduledDate')?.value;
    let qty= iteArray.at(0).get('scheduledQty')?.value;
    let scheProduct= iteArray.at(0).get('scheduledProduct')?.value;
    let location= iteArray.at(0).get('location')?.value;
    let demandProductMaterialCode= iteArray.at(0).get('demandProductMaterialCode')?.value;
    let scheduledProductMaterialCode= iteArray.at(0).get('scheduledProductMaterialCode')?.value;
    if (this.updateDraftReference && !this.olddeletedValue) {
      let transportSystem = 0
      while (transportSystem <= this.transportArray().controls?.length - 1) {
        let i = 0;
        while (i <= this.transportArray().controls[transportSystem].value['ite'].length - 1) {
          this.deletedNomList(0, i, 'ite')
          i++;
        }
        transportSystem++;

      }
      this.olddeletedValue = true
      while (this.transportArray()?.controls?.length !== 0) {
        this.transportArray().removeAt(0);
      }
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
      this.transportArray().push(this.createFormGroupTransport(null));
    }
    else {
      this.transportArray().controls.forEach((element: any) => {
        element?.removeControl('origin');
        element?.removeControl('destination');
      });
      while (this.transportArray()?.controls?.length !== 0) {
        this.transportArray().removeAt(0);
      }
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })

      this.transportArray().push(this.createFormGroupTransport(null));


      this.resetEntry(true)
    }
      let count=0;
      let destinPos=0;
      const destiArray = this.transportArray().at(count).get('destination') as FormArray;
      if(iteArray && location){
      destiArray.at(destinPos)?.get('transportSystemMode')?.setValue('');
      destiArray.at(destinPos)?.get('location')?.setValue(location);
      destiArray.at(destinPos)?.get('scheduledProduct')?.setValue(scheProduct);
      destiArray.at(destinPos)?.get('scheduledQty')?.setValue(qty);
      destiArray.at(destinPos)?.get('scheduledDate')?.setValue(date);
      destiArray.at(destinPos)?.get('demandProduct')?.setValue(demandProduct);
      destiArray.at(destinPos)?.get('demandProductMaterialCode')?.setValue(demandProductMaterialCode);
      destiArray.at(destinPos)?.get('scheduledProductMaterialCode')?.setValue(scheduledProductMaterialCode);
      destiArray.at(destinPos)?.get('defaultProduct')?.setValue(scheProduct);
      destiArray.at(destinPos)?.get('defaultProductmaterialCode')?.setValue(scheduledProductMaterialCode);
      this.locationDestiArray[count][destinPos] =location;
      this.demandProductDesti[count][destinPos] =demandProduct;
      this.demandProductDestiMaterialCode[count][destinPos] = demandProductMaterialCode;
      this.defaultProductdesti[count][destinPos] =scheProduct;
      this.defaultProductdestiMaterialCode[count][destinPos] =scheduledProductMaterialCode;
    }

  }
  resetIteFormControl(transportSystemControl: any) {

    let transportSystem = 0;
    let transport=this.createPipelineNominationForm.get('transport') as FormArray;
    let originArray=transport.at(0).get('origin') as FormArray;
    let originObj={
      demandProduct: originArray.at(0).get('demandProduct')?.value,
      date: originArray.at(0).get('scheduledDate')?.value,
      qty: originArray.at(0).get('scheduledQty')?.value,
      scheProduct: originArray.at(0).get('scheduledProduct')?.value,
      location: originArray.at(0).get('location')?.value,
      demandProductMaterialCode: originArray.at(0).get('demandProductMaterialCode')?.value,
      scheduledProductMaterialCode: originArray.at(0).get('scheduledProductMaterialCode')?.value
    };
    let destiArray=transport.at(0).get('destination') as FormArray;
    let destinationObj={
       demandProduct: destiArray.at(0).get('demandProduct')?.value,
       date: destiArray.at(0).get('scheduledDate')?.value,
       qty: destiArray.at(0).get('scheduledQty')?.value,
       scheProduct: destiArray.at(0).get('scheduledProduct')?.value,
       location: destiArray.at(0).get('location')?.value,
       demandProductMaterialCode: destiArray.at(0).get('demandProductMaterialCode')?.value,
       scheduledProductMaterialCode: destiArray.at(0).get('scheduledProductMaterialCode')?.value
    };
    if (this.updateDraftReference && !this.olddeletedValue) {
      while (transportSystem <= this.transportArray().controls?.length - 1) {
        let i = 0;
        while (i <= this.transportArray().controls[transportSystem].value['origin'].length - 1) {
          this.deletedNomList(0, i, 'origin')
          i++;
        }
        transportSystem++;
      }
      transportSystem = 0

      while (transportSystem <= this.transportArray().controls?.length - 1) {
        let j = 0;
        while (j <= this.transportArray().controls[transportSystem].value['destination'].length - 1) {
          this.deletedNomList(0, j, 'destination')
          j++;
        }
        transportSystem++;
      }
      this.olddeletedValue = true

    }

    while (this.transportArray()?.controls?.length !== 0) {
      this.transportArray().removeAt(0);
    }
    this.transportArray().push(this.createFormGroupTransport(transportSystemControl))
    this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors(null)
    this.createPipelineNominationForm.get('isoriginDestiTouched')?.clearValidators()
    this.resetEntry(true)
    this.populateITETable(originObj,destinationObj,destiArray,originArray);
    
  }

  populateITETable(originObj:any,destinationObj:any,destiArray:FormArray,originArray:FormArray){
    let count=0;
    let itePost=0;
    const iteArray = this.transportArray().at(count).get('ite') as FormArray;
    if((destiArray && destinationObj.location && originArray && originObj.location)|| (destiArray && destinationObj.location) ){
    iteArray.at(itePost)?.get('transportSystemMode')?.setValue('');
    iteArray.at(itePost)?.get('location')?.setValue(destinationObj.location);
    iteArray.at(itePost)?.get('scheduledProduct')?.setValue(destinationObj.scheProduct);
    iteArray.at(itePost)?.get('scheduledQty')?.setValue(destinationObj.qty);
    iteArray.at(itePost)?.get('scheduledDate')?.setValue(destinationObj.date);
    iteArray.at(itePost)?.get('demandProduct')?.setValue(destinationObj.demandProduct);
    iteArray.at(itePost)?.get('demandProductMaterialCode')?.setValue(destinationObj.demandProductMaterialCode);
    iteArray.at(itePost)?.get('scheduledProductMaterialCode')?.setValue(destinationObj.scheduledProductMaterialCode);
    iteArray.at(itePost)?.get('defaultProduct')?.setValue(destinationObj.scheProduct);
    iteArray.at(itePost)?.get('defaultProductmaterialCode')?.setValue(destinationObj.scheduledProductMaterialCode);
    this.locationIteArray[count][itePost]= destinationObj.location
    this.demandProductIte[count][itePost]= destinationObj.demandProduct
    this.defaultProduct[count][itePost]= destinationObj.scheProduct
    this.demandProductIteMaterialCode[count][itePost]= destinationObj.demandProductMaterialCode
    this.defaultProductMaterialCode[count][itePost]= destinationObj.scheduledProductMaterialCode
    }
     else if(originArray && originObj.location){
      iteArray.at(itePost)?.get('transportSystemMode')?.setValue('')
      iteArray.at(itePost)?.get('location')?.setValue(originObj.location)
      iteArray.at(itePost)?.get('scheduledProduct')?.setValue(originObj.scheProduct)
      iteArray.at(itePost)?.get('scheduledQty')?.setValue(originObj.qty);
      iteArray.at(itePost)?.get('scheduledDate')?.setValue(originObj.date);
      iteArray.at(itePost)?.get('demandProduct')?.setValue(originObj.demandProduct);
      iteArray.at(itePost)?.get('demandProductMaterialCode')?.setValue(originObj.demandProductMaterialCode)
      iteArray.at(itePost)?.get('scheduledProductMaterialCode')?.setValue(originObj.scheduledProductMaterialCode)
      iteArray.at(itePost)?.get('defaultProduct')?.setValue(originObj.scheProduct)
      iteArray.at(itePost)?.get('defaultProductmaterialCode')?.setValue(originObj.scheduledProductMaterialCode)
      this.locationIteArray[count][itePost] = originObj.location
      this.demandProductIte[count][itePost] = originObj.demandProduct
      this.defaultProduct[count][itePost] = originObj.scheProduct
      this.demandProductIteMaterialCode[count][itePost] = originObj.demandProductMaterialCode
      this.defaultProductMaterialCode[count][itePost] = originObj.scheduledProductMaterialCode
      }
  }

  resetEntry(str: boolean) {

    this.selectedTransportOption = '';
    this.locationOriginArray = [[]]
    this.locationDestiArray = [[]]
    this.isTankOriginDisabled = [[]]
    this.isTankDestiDisabled = [[]]
    this.defaultTankOrigin = [[]]
    this.defaultTankDesti = [[]]
    this.locationIteArray = [[]]
    this.demandProductOrigin = [[]]
    this.demandProductDesti = [[]]
    this.demandProductIte = [[]]
    this.destinationRDIDescSelected = [[]];
    this.originRDIDescSelected = [[]];
    this.destinationRDISelected = [[]];
    this.inTankRDIDescSelected = [[]];
    this.inTankRDISelected = [[]];
    this.originRDISelected = [[]];
    this.scheduledTime = [['00:00']]
    this.scheduledTimeDesti = [['00:00']]
    this.contractLineItemsOrigin = [[]];
    this.contractLineItemsDestination = [[]];
    this.contractLineItemsITE = [[]];
    this.updatedContractLineItems = [];
    this.refContractLineItems = [[]];
    this.refContractLineItemsDesti = [[]];
    this.refContractLineItemsIte =[[]];
    this.getContractNumber = "";
    this.getLocation = "";
    this.getMaterial = "";
    this.demandProduct = "";
    this.getScheduledDate = "";
    if (!str) {
      this.productList = []
      this.productListMaterialCode =[];
      this.transportSystemArray = []
      this.transportSystemModeArray = []
      this.defaultTransportSystem = null
      this.defaultProduct = [[]]
      this.defaultProductdesti = [[]]
      this.defaultProductMaterialCode = [[]]
      this.defaultProductdestiMaterialCode = [[]]
      this.demandProductOriginMaterialCode = [[]]
      this.demandProductDestiMaterialCode = [[]]
      this.defaultTransportModalSystem = []
    }


  }
  createFormGroupOrigin(index: number): FormGroup {
    if(this.homeComponent.cpCargoPlanning$.value && !this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
      return this.fb.group({  
        rdi: '',
        description: '',
        counterParty:'',
        demandProduct: '',
        scheduledProduct: '',
        demandProductMaterialCode: '',
        scheduledProductMaterialCode: '',
        sapcontract: '',
        scheduledQty: '',
        scheduledDate: '',
        location: '',
        contractLineNo :'', 
        endLoadDate:'',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        id: null,
        indicator: index,
        confirmationIndicator: false,
        nominationItemStatus:'',
        tankCode: ''
      },
        { validator: [this.customRequiredValidator.bind(this)] }
      )
    }
    else{
      return this.fb.group({  
        rdi: '',
        description: '',
        demandProduct: '',
        demandProductMaterialCode: '',
        scheduledProduct: '',
        scheduledProductMaterialCode: '',
        sapcontract: '',
        scheduledQty: '',
        scheduledDate: '',
        location: '',
        contractLineNo: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        id: null,
        indicator: index,
        counterParty:'',
        confirmationIndicator: false,
        nominationItemStatus:'',
        tankCode: '',
        // ...(this.cpService?.createNominationFromCargoWithReference?.value?.initiate && {udmEndLoadDate:''}),
        // ...(this.isCargoDesks && {partnerNominationNumber:''})
        
      },
        { validator: [this.customRequiredValidator.bind(this)] }
      )
    }
    
  }
  customRequiredValidatorDesti(g: any) {
    let fields = ['key', 'location', 'demandProduct', 'scheduledProduct', 'scheduledQty', 'scheduledDate', 'value', 'scheduledProductMaterialCode', 'demandProductMaterialCode'];
    if(!this.inCpTab){
      fields.push('scheduledProduct');
    }
      
    
    if (g?.value['key'] || g?.value['location'] || g?.value['demandProduct'] || g?.value['scheduledQty'] || g?.value['scheduledDate'] || g?.value['endLoadDate']  || g?.value['scheduledProduct'] || g?.value['scheduledProductMaterialCode'] || g?.value['demandProductMaterialCode']) {
      this.isDestinationRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
        g.get(ele).setValidators(Validators.required);
        if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
      })
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.clearValidators()
      this.createPipelineNominationForm.get('isTransportpicked')?.setErrors(null)
      this.createPipelineNominationForm.get('isTransportpicked')?.clearValidators()
      
 
    } else {
      this.isDestinationRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();

      })
    }
  }
  customRequiredValidatorHeader(g: any){
    const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
    if(g?.value['nominationNo'] && g?.value['transportSystem']){
      let originheader, destinheader, iteheader
      if ( (g.controls['transportSystem'].value === this.intankEast || g.controls['transportSystem']?.value === this.intankWest) && isITNomSectionRequired) {

          let ite =  g.controls['transport']?.controls[0]?.controls['ite']?.controls[0] as FormArray
          if(ite && ! ite?.value['rdiPort'] && !ite?.value['location'] && !ite?.value['demandProduct'] && !ite?.value['scheduledQty']&& !ite?.value['scheduledDate'] &&   !ite?.value['scheduledProduct']){
            iteheader = true;
            this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({'invalid': true})
           } else{
            this.createPipelineNominationForm.get('isTransportpicked')?.setErrors(null);
            this.createPipelineNominationForm.get('isTransportpicked')?.clearValidators();
           }
          //  if( iteheader ){
          //   this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({'invalid': true})
          //  }
      }
      else{
        let origin = g?.controls['transport']?.controls[0]?.controls['origin']?.controls[0] as FormArray
        let desti = g?.controls['transport']?.controls[0]?.controls['destination']?.controls[0] as FormArray
        this.checkformValue(originheader, origin, desti, destinheader)
      }
    }
  }
   checkformValue(originheader: any, origin: any, desti: any, destinheader: any){
   
        if( !origin?.value['rdi'] && !origin?.value['location']&& !origin?.value['demandProduct'] &&! origin?.value['scheduledQty'] && !origin?.value['scheduledDate'] && ! origin?.value['endLoadDate'] &&   !origin?.value['scheduledProduct']){
            originheader = true
         }
      
        if( !desti?.value['rdi'] && !desti?.value['location'] && !desti?.value['demandProduct'] && !desti?.value['scheduledQty']&& !desti?.value['scheduledDate'] &&  !desti?.value['endLoadDate'] &&  !desti?.value['scheduledProduct']){
           destinheader = true
          }

           // if both origin and destination is empty
       if( originheader && destinheader ){
        this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({'invalid': true})
       }
      }
  customRequiredValidator(g: any) {

    let fields = ['rdi', 'location', 'demandProduct', 'scheduledProduct', 'scheduledQty', 'scheduledDate', 'description', 'scheduledProductMaterialCode', 'demandProductMaterialCode'];
    this.handleInCpTabCondition(fields);
    if (g?.value['rdi'] || g?.value['location'] || g?.value['demandProduct'] || g?.value['scheduledQty'] || g?.value['scheduledDate'] || g?.value['endLoadDate'] ||  g?.value['scheduledProduct'] || g?.value['scheduledProductMaterialCode'] || g?.value['demandProductMaterialCode']) {
      this.isOriginRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
        if(!(this.inCpTab &&  ele === 'rdi')){
          g.get(ele).setValidators(Validators.required);
        }
        if((this.inCpTab &&  ele === 'counterParty')){
          g.get(ele).setValidators(null);
        }
        if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
      })
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.clearValidators()
      this.createPipelineNominationForm.get('isTransportpicked')?.setErrors(null)
      this.createPipelineNominationForm.get('isTransportpicked')?.clearValidators()
    } else {
      this.isOriginRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();

      })
    }
  }

  handleInCpTabCondition(fields: any){
  if (!this.inCpTab) {
    fields.push('scheduledProduct');
  } 
  }

  // checkQTY(index: number, row: number) {
  //   let originQty = 0;
  //   let destiQty = 0
  //   let i = 0;
  //   let j = 0;
  //   let formOri = this.transportArray().at(index).get('origin') as FormArray;
  //   let formDesti = this.transportArray().at(index).get('destination') as FormArray;
  //   this.transportArray().at(index).get('origin')?.value?.forEach((e: any) => {
  //     originQty = originQty + parseFloat(e.scheduledQty)
  //   })
  //   this.transportArray().at(index).get('destination')?.value?.forEach((e: any) => {
  //     destiQty = destiQty + parseFloat(e.scheduledQty)
  //   })
  //   this.originQtyValue[index] = originQty;
  //   this.destinQtyValue[index] = destiQty;
  //   if (this.isOriginRequired[index] === true && this.isDestinationRequired[index] === true) {
  //     if (originQty !== destiQty) {
  //       if(!this.isCargoDesks || this.scenario === 'pipeline'){
  //         formOri?.at(row)?.get('scheduledQty')?.setErrors({ 'invalid': true, })
  //         formDesti?.at(row)?.get('scheduledQty')?.setErrors({ 'invalid': true, })
  //       }
  //       this.errorMessage[index] = true;
  //       this.resetRowManullay(formOri, originQty, formDesti, destiQty, index)
  //     }
  //     else {
  //       while (i <= formOri.length) {
  //         formOri?.at(i)?.get('scheduledQty')?.setErrors(null)
  //         i++;
  //       }
  //       while (j <= formDesti.length) {
  //         formDesti?.at(j)?.get('scheduledQty')?.setErrors(null)
  //         j++;
  //       }
  //       this.errorMessage[index] = false
  //     }
  //   }else{
  //     this.resetRowManullay(formOri, originQty, formDesti, destiQty, index)
  //   }
  // }
  
  calculateOriDestiQuantity(originQty:number,destiQty:number,index:number){
    this.transportArray().at(index).get('origin')?.value?.forEach((e: any) => {
      originQty = originQty + parseFloat(e.scheduledQty)
    })
    this.transportArray().at(index).get('destination')?.value?.forEach((e: any) => {
      destiQty = destiQty + parseFloat(e.scheduledQty)
    })
    this.originQtyValue[index] = originQty;
    this.destinQtyValue[index] = destiQty;
  }
  
  checkFetchContractItemArray(index: number, j: number, formType: string) {
    let updateType = this.transportArray().at(index).get(formType) as FormArray;
    if (this.getContractNumber === updateType?.at(j)?.get('sapcontract')?.value && this.getLocation === updateType?.at(j)?.get('location')?.value && this.getScheduledDate === updateType?.at(j)?.get('scheduledDate')?.value && (this.getMaterial?.productName || "") === updateType?.at(j)?.get('scheduledProduct')?.value) {
      switch (formType) {
        case 'origin':
          this.contractLineItemsOrigin[index][j] = [...this.updatedContractLineItems];
          this.refContractLineItems[index][j] = updateType?.at(j)?.get('contractLineNo')?.value;
          break;
        case 'destination':
          this.contractLineItemsDestination[index][j] = [...this.updatedContractLineItems];
          this.refContractLineItemsDesti[index][j] = updateType?.at(j)?.get('contractLineNo')?.value;
          break;
        case 'ite':
          this.contractLineItemsITE[index][j] = [...this.updatedContractLineItems];
          this.refContractLineItemsIte[index][j] = updateType?.at(j)?.get('contractLineNo')?.value;
          break;
        default:
          break;
      }
      return true;
    }
    this.getContractNumber = updateType?.at(j)?.get('sapcontract')?.value;
    this.getLocation = updateType?.at(j)?.get('location')?.value;
    this.getScheduledDate = updateType?.at(j)?.get('scheduledDate')?.value;
    if (this.inCpTab) {
      this.getMaterial = this.masterProductListForCp.find((element: any) => element.productName === updateType?.at(j)?.get('scheduledProduct')?.value);
      this.demandProduct = this.masterProductListForCp.find((element: any) => element.productName === updateType?.at(j)?.get('demandProduct')?.value);
    }
    else {
      this.getMaterial = this.masterProductList.find((element: any) => element.productName === updateType?.at(j)?.get('scheduledProduct')?.value);
      this.demandProduct = this.masterProductList.find((element: any) => element.productName === updateType?.at(j)?.get('demandProduct')?.value);      
    }
    return false
  }

  fetchContractLineItem(index: number, j: number, formType: any) {
    if (this.checkFetchContractItemArray(index, j, formType)) {
      return;
    }
    let updateMaterialsCode = this.demandProduct?.productCode !== this.getMaterial?.productCode ? [`${this.getMaterial?.productCode}`, `${this.demandProduct?.productCode}`] : [`${this.getMaterial?.productCode}`]
    let payload1: any = {
      desk: `pl${this.selectedDeskParameter}`,
      deskId: this.selectedDeskId,
      contractNumber: this.getContractNumber,
      location: this.getLocation || null,
      scheduledDate: this.getScheduledDate || null,
      materials: updateMaterialsCode || null
    }
    this.replenishmentPlanningService.getContractBalancingSmartFilter(payload1).subscribe({
      next: (response: any) => {
        if (response?.length) {
          switch (formType) {
            case 'origin':
              if (this.matchArray(response, this.contractLineItemsOrigin[index][j])) {
                this.updatedContractLineItems = [...this.contractLineItemsOrigin[index][j]]
                return;
              }
              this.contractLineItemsOrigin[index][j] = [];
              break;
            case 'destination':
              if (this.matchArray(response, this.contractLineItemsDestination[index][j])) {
                this.updatedContractLineItems = [...this.contractLineItemsDestination[index][j]]
                return;
              }
              this.contractLineItemsDestination[index][j] = [];
              break;
            case 'ite':
              if (this.matchArray(response, this.contractLineItemsITE[index][j])) {
                this.updatedContractLineItems = [...this.contractLineItemsITE[index][j]]
                return;
              }
              this.contractLineItemsITE[index][j] = [];
              break;
            default:
              break;
          }
          this.updatedContractLineItems = [];
          response.forEach((data: any) => {
            this.updatedContractLineItems.push(`${data.contractLineNumber} | ${data.sraDealDetailReferenceNumber} | ${data.location} | ${data.modeOfTransportDescription} | ${data.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}`);
          })
          this.setUpdateContractLineItem(index, j, formType, true);
        } else {
          this.updatedContractLineItems = [];
          this.setUpdateContractLineItem(index, j, formType, false);
        }
      }
    })
  }

  setUpdateContractLineItem(index: number, j: number, formType: any, isLength: boolean) {
    switch (formType) {
      case 'origin':
        this.contractLineItemsOrigin[index][j] = [...this.updatedContractLineItems];
        if (!isLength) {
          this.refContractLineItems[index][j] = 'select';
        }
        break;
      case 'destination':
        this.contractLineItemsDestination[index][j] = [...this.updatedContractLineItems];
        if (!isLength) {
          this.refContractLineItemsDesti[index][j] = 'select';
        }
        break;
      case 'ite':
        this.contractLineItemsITE[index][j] = [...this.updatedContractLineItems];
        if (!isLength) {
          this.refContractLineItemsIte[index][j] = 'select';
        }
        break;
      default:
        break;
    }
  }

  objectsEqual(data: any, o2: any) {
    let o1: any = `${data.contractLineNumber} | ${data.sraDealDetailReferenceNumber} | ${data.location} | ${data.modeOfTransportDescription} | ${data.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}`
    let match = false
    if (typeof o1 === 'object' && Object.keys(o1).length > 0) {
      match = (Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => { this.objectsEqual(o1[p], o2[p]) }))
    } else {
      match = (o1 === o2)
    }
    return match
  }

  matchArray(res: any, prevArr: any) {
    let finalMatch: any = []
    let itemFound: any = []
    if (res.length === prevArr?.length) {
      finalMatch = []
      res.forEach((data: any) => {
        itemFound = []
        prevArr.forEach((i2: any) => {
          itemFound.push(this.objectsEqual(data, i2))
        })
        finalMatch.push(itemFound.some((i: any) => i === true))
      })
    }
    return (finalMatch?.length === 0) ? false : finalMatch.every((i: any) => i === true)
  }

  resetRowManullay(formOri:any,originQty:any, formDesti:any, destiQty:any, index:number){
    if(formOri.length === 1  && !originQty){
     
      this.transportArray().at(index).get('origin')?.value?.forEach((e: any, ind:number) => {
        let checkValid = true;
        Object.keys(e).forEach((elem) => {
          if(elem !== 'id' && !!(e[elem])){
            checkValid = false;
          }
        })
        if(checkValid){
          this.isOriginRequired[index] = false;
          this.errorMessage[index] = false
          formOri?.at(ind)?.get('scheduledQty')?.setErrors(null);
        }
      })
      this.transportArray().at(index).get('destination')?.value?.forEach((e: any, ind:number) => {
        if(!(isNaN(parseFloat(e.scheduledQty))))formDesti?.at(ind)?.get('scheduledQty')?.setErrors(null)
      })
    }
    if(formDesti.length === 1 && !destiQty){
      this.transportArray().at(index).get('destination')?.value?.forEach((e: any, ind:number) => {
        let checkValid = true;
        Object.keys(e).forEach((elem) => {
          if(elem !== 'id' && !!(e[elem])){
            checkValid = false;
          }
        })
        if(checkValid){
          this.isDestinationRequired[index] = false;
          this.errorMessage[index] = false
          formDesti?.at(ind)?.get('scheduledQty')?.setErrors(null)
        }
      })
      this.transportArray().at(index).get('origin')?.value?.forEach((e: any, ind:number) => {
        if(!(isNaN(parseFloat(e.scheduledQty))))formOri?.at(ind)?.get('scheduledQty')?.setErrors(null)
      })
    }
  }

  createFormGroupDestination(index: number): FormGroup {
    if(this.homeComponent.cpCargoPlanning$.value && !this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
      return this.fb.group({ 
        key: '',
        value: '',
        demandProduct: '',
        scheduledProduct:'',
        demandProductMaterialCode: '',
        scheduledProductMaterialCode: '',
        sapcontract: '',
        counterParty:'',
        contractLineNo:'',
        scheduledQty: '',
        scheduledDate: '',
        endLoadDate:'',
        location: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        id: null,
        indicator: index,
        confirmationIndicator: false,
        nominationItemStatus:'',
        tankCode: ''
      },
        { validator: [this.customRequiredValidatorDesti.bind(this)] }
      )
    }
    else{
      return this.fb.group({ 
        key: '',
        value: '',
        demandProduct: '',
        demandProductMaterialCode: '',
        scheduledProductMaterialCode: '',
        sapcontract: '',
        scheduledQty: '',
        scheduledDate: '',
        scheduledProduct: '',
        location: '',
        contractLineNo: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        id: null,
        indicator: index,
        counterParty:'',
        confirmationIndicator: false,
        nominationItemStatus:'',
        tankCode: ''
        // ...(this.cpService?.createNominationFromCargoWithReference?.value?.initiate && {udmEndLoadDate:''}),
        // ...(this.isCargoDesks && {partnerNominationNumber:''})
      },
        { validator: [this.customRequiredValidatorDesti.bind(this)] }
      )
    }
    
  }
  createFormGroupIte(): FormGroup {
    if(this.inCpTab){
      return this.fb.group({
        rdiPort: ['', Validators.required],
        descPort: ['', Validators.required],
        demandProduct: ['', Validators.required],
        scheduledProduct: ['', Validators.required],
        demandProductMaterialCode: ['', Validators.required],
        scheduledProductMaterialCode: ['', Validators.required],
        scheduledQty: ['', Validators.required],
        scheduledDate: ['', Validators.required],
        counterParty:'',
        sapcontract: '',
        location: ['', Validators.required],
        contractLineNo: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        confirmationIndicator: false,
        nominationItemStatus:''
  
      })
    }
    else {
      return this.fb.group({
        rdiPort: ['', Validators.required],
        descPort: ['', Validators.required],
        demandProduct: ['', Validators.required],
        scheduledQty: ['', Validators.required],
        scheduledDate: ['', Validators.required],
        scheduledProduct: ['', Validators.required],
        demandProductMaterialCode: ['', Validators.required],
        scheduledProductMaterialCode: ['', Validators.required],
        sapcontract: '',
        location: ['', Validators.required],
        tankCode: '',
        contractLineNo: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',  
        confirmationIndicator: false,
        nominationItemStatus:''  
      })
    }
   
  }
  createFormGroupTransport(transportSystemControl: any, index?: number): FormGroup {
    const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
    if ((transportSystemControl?.value === this.intankEast || transportSystemControl?.value === this.intankWest) && isITNomSectionRequired) {
      return this.fb.group({
        transportSystemMode: new FormControl(),
        ite: this.fb.array([this.createFormGroupIte()]),
      })
    }
    else {
      return this.fb.group({
        transportSystemMode: new FormControl(),
        origin: this.fb.array([this.createFormGroupOrigin(index ?? 0)]),
        destination: this.fb.array([this.createFormGroupDestination(index ?? 0)]),

      })
    }

  }


  trackByFn(index: number, item: FormControl) {
    return index;
  }
  addOriginRow(index: number) {
    const originArray = this.origin(index);
    const newOriginRow = this.createFormGroupOrigin(index);
    originArray.push(newOriginRow);
    this.isMultipleRowItemOrigin[index]?.push('O');
    this.demandProductList[index][originArray.length - 1] = this.productList
    this.scheduledDemandProductList[index][originArray.length - 1] = this.productList
    this.demandProductListMaterialCode[index][originArray.length - 1] = this.productListMaterialCode
    this.scheduledDemandProductListMaterialCode[index][originArray.length - 1] = this.productListMaterialCode
    this.isOriginRequired[index] = false;
    this.scheduledTime[index][originArray.length - 1] = ['00:00']

  }

  addDestinationRow(index: number) {
    const destinationArray = this.destination(index);
    const newDestinationRow = this.createFormGroupDestination(index);
    destinationArray.push(newDestinationRow);
    this.scheduledTimeDesti[index][destinationArray.length - 1] = ['00:00']
    this.isMultipleRowItemDestination[index]?.push('D');
    this.demandProductListDesc[index][destinationArray.length - 1] = this.productList
    this.scheduledDemandProductListDesc[index][destinationArray.length - 1] = this.productList
    this.demandProductListDescMaterialCode[index][destinationArray.length - 1] = this.productListMaterialCode
    this.scheduledDemandProductListDescMaterialCode[index][destinationArray.length - 1] = this.productListMaterialCode
  }



  addIteRow(index: number) {
    const iteArray = this.ite(index);
    const newIteRow = this.createFormGroupIte(); // Create a new ITE row

    iteArray.push(newIteRow);
 
    this.isMultipleRowItemIte[index]?.push('IT');
    this.demandProductList[index][iteArray.length - 1] = this.productList
    this.scheduledDemandProductList[index][iteArray.length - 1] = this.productList
    this.demandProductListMaterialCode[index][iteArray.length - 1] = this.productListMaterialCode
    this.scheduledDemandProductListMaterialCode[index][iteArray.length - 1] = this.productListMaterialCode
    this.scheduledTime[index][iteArray.length - 1] = ['00:00']

  }

  onAddititonalData(ele: any) {
    const selectMultipleCheckbox1 = this.createPipelineNominationForm.get('selectMultipleCheckbox1');
    if (selectMultipleCheckbox1) {
      if (ele.checked) {
        this.isAdditionalData = true
      }
      else {
        this.isAdditionalData = false;
      }
    }

  }

  setIndividualProduct(transportControl: any, selectedValue: any) {
    const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
    if ((this.createPipelineNominationForm.get('transportSystem')?.value === this.intankEast || this.createPipelineNominationForm.get('transportSystem')?.value === this.intankWest) && isITNomSectionRequired) {
      // Update the demandProduct in the Ite table
      const iteArray = (transportControl as FormGroup).get('ite') as FormArray;
      iteArray?.controls.forEach((iteControl) => {
        const demandProductControl = iteControl.get('demandProduct');
        if (demandProductControl?.value === '') {
          demandProductControl.setValue(selectedValue);

        }
      });
    }

    else {
      const originArray = (transportControl as FormGroup).get('origin') as FormArray;
      originArray.controls.forEach((originControl) => {
        const demandProductControl = originControl.get('demandProduct');
        if (demandProductControl?.value === '') {
          demandProductControl.setValue(selectedValue);
        }
      });

      // Update the demandProduct in the destination table
      const destinationArray = (transportControl as FormGroup).get('destination') as FormArray;
      destinationArray.controls.forEach((destinationControl) => {
        const demandProductControl = destinationControl.get('demandProduct');
        if (demandProductControl?.value === '') {
          demandProductControl.setValue(selectedValue);
        }
      });
    }
  }
  // onTransportChange(ele: any) { //NOSONAR lightweight logging
  //   if (ele.checked) {
  //     this.isMultipleTransport = true;
  //     let transportSystemValue = this.createPipelineNominationForm.get('transportSystem')?.value;
  //     let transport = this.createPipelineNominationForm.get('transport') as FormArray
  //     if(transportSystemValue) {
  //       this.defaultTransportModalSystem[0]=transportSystemValue;
  //       transport.at(0).get("transportSystemMode")?.setValue(transportSystemValue);
  //     }
  //     transport.at(0).get("transportSystemMode")?.setValidators(Validators.required);
  //     this.createPipelineNominationForm.get('transportSystem')?.setValue("");
  //     this.createPipelineNominationForm.get('transportSystem')?.clearValidators();
  //     this.createPipelineNominationForm.get('transportSystem')?.setErrors(null)
      
  //     this.olddeletedValue = true;
  //     this.defaultTransportSystem = null
  //     this.onTransportSystemChange()
  //   } else {
  //     let transport = this.createPipelineNominationForm.get('transport') as FormArray
  //     let transportSystemControl=transport.at(0).get("transportSystemMode")?.value;
  //     this.defaultTransportSystem=transportSystemControl;
  //     this.createPipelineNominationForm.get('transportSystem')?.setValue(this.defaultTransportSystem);
  //     this.createPipelineNominationForm.get('transportSystem')?.setValidators(Validators.required);
  //     this.isMultipleTransport = false;
  //   }
  //   this.isUserInteraction = false;

  // }

  onLocChangeOrigin(index: any, rowIndex: any) {

    const originArray = this.transportArray().at(index).get('origin') as FormArray;

    const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createPipelineNominationForm.get('transportSystem');
    const location = originArray.at(rowIndex)?.get('location');
    this.istankEnabled = this.tankMasterData.some((element: any) => element.tswLocationCode === location?.value);
    const formArray = this.transportArray().at(0).get('origin') as FormArray;
    if (this.istankEnabled) {
      const tankValues = this.tankMasterData?.filter((result: any) => result.tswLocationCode === location?.value)[0].tanks
      if(!tankValues?.includes(this.defaultTankOrigin[index][rowIndex])){
        this.defaultTankOrigin[index][rowIndex] = [];
      }
      this.tankOriginList[index][rowIndex] = tankValues;
    } else {
      this.tankOriginList[index][rowIndex] = [];
      this.defaultTankOrigin[index][rowIndex] = [];
      this.isTankOriginDisabled[index][rowIndex] = false;
      formArray.at(rowIndex).get('tankCode')?.setValue('')
    }
    
    this.callApi(transportSystemControl?.value, location?.value, index, rowIndex, 'origin');
    // this.checkQTY(index, rowIndex) // to avoid reset pipelineform call once location is set


  }
  onLocChangeDest(index: any, rowIndex: any) {
    
    const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createPipelineNominationForm.get('transportSystem');
    const destArray = this.transportArray().at(index).get('destination') as FormArray;
    const location = destArray.at(rowIndex)?.get('location');
    this.isDestitankEnabled = this.tankMasterData.some((element: any) => element.tswLocationCode === location?.value);
    const formArray = this.transportArray().at(0).get('destination') as FormArray;
    if (this.isDestitankEnabled) {
      const tankValues = this.tankMasterData?.filter((result: any) => result.tswLocationCode === location?.value)[0].tanks
      if(!tankValues?.includes(this.defaultTankDesti[index][rowIndex])){
        this.defaultTankDesti[index][rowIndex] = [];
      }
      this.tankDestiList[index][rowIndex] = tankValues;

    } else {
      this.tankDestiList[index][rowIndex] = [];
      this.defaultTankDesti[index][rowIndex] = [];
      this.isTankDestiDisabled[index][rowIndex] = false;
      formArray.at(rowIndex).get('tankCode')?.setValue('')
    }
    this.callApi(transportSystemControl?.value, location?.value, index, rowIndex, 'destination')

  }
  onLocChangeIte(index: any, rowIndex: any) {

    const iteArray = this.transportArray().at(index).get('ite') as FormArray;
    const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createPipelineNominationForm.get('transportSystem');
    const location = iteArray.at(rowIndex)?.get('location');
    this.callApi(transportSystemControl?.value, location?.value, index, rowIndex, 'ite')

  }

  onDemandProductDestiChangeMaterialCode(index: any, rowIndex: any) {
    let formArray = this.transportArray()?.at(index).get('destination') as FormArray
    if ((!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'])) {
      this.defaultProductdestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode'];
    }

    this.demandProductDesti[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode'])
    if(!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']){
      formArray.at(rowIndex).get('demandProduct')?.setValue('');
      this.onDemandProductDestiChange(index, rowIndex)
     }
  }

  async onDemandProductDestiChange(index: any, rowIndex: any) {
  
    let desti = this.transportArray()?.at(index).get('destination') as FormArray

    let res = await this.filterProductCategory(desti.at(rowIndex)?.get('demandProduct')?.value, 'destination', index, rowIndex, this.demandProductListDesc[index][rowIndex])
    //response from api will be set to scheduleddemandproductlist
    if (!this.arraysEqual(res.productList, this.scheduledDemandProductListDesc[index][rowIndex])) {
      this.scheduledDemandProductListDesc[index][rowIndex] = []
      this.scheduledDemandProductListDescMaterialCode[index][rowIndex] = []
      res.productList.forEach((item: any) => {
        this.scheduledDemandProductListDesc[index][rowIndex].push(item)
      })
      res.productCode.forEach((item: any) => {
        this.scheduledDemandProductListDescMaterialCode[index][rowIndex].push(item)
      })
    }
    
    if (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct']) {
      desti.at(rowIndex).get('demandProductMaterialCode')?.setValue('');
    }
    if ((!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'])) {
      this.defaultProductdesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'];
      this.defaultProductdestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'])
    }
    else if (this.scheduledDemandProductListDesc[index][rowIndex].includes(desti.at(rowIndex)?.get('scheduledProduct')?.value)) {
      this.defaultProductdesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct']
      this.defaultProductdestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']
    }
    this.demandProductDestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'])
  }
  arraysEqual(a1: any, a2: any) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
  }

  onDemandProductChangeMaterialCode(index: any, rowIndex: any, formType:any) {
    let formArray = this.transportArray()?.at(index).get(formType) as FormArray
    if ((!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode'])) {
        this.defaultProductMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode'];
    }
    if(formType === 'ite'){
      this.demandProductIte[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode']);
    }else{
      this.demandProductOrigin[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode']);
    }
    if(!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode']){
      formArray.at(rowIndex).get('demandProduct')?.setValue('');
      this.onDemandProductChange(index, rowIndex)
     }
  }

  async onDemandProductChange(index: any, rowIndex: any) {
    const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
    //response from api will be set to scheduleddemandproductlist
    if ((this.createPipelineNominationForm.get('transportSystem')?.value === this.intankEast || this.createPipelineNominationForm.get('transportSystem')?.value === this.intankWest) && isITNomSectionRequired) {
      let ite = this.transportArray()?.at(index).get('ite') as FormArray
      let res = await this.filterProductCategory(ite.at(rowIndex)?.get('demandProduct')?.value, 'ite', index, rowIndex, this.demandProductList[index][rowIndex])
      if (!this.arraysEqual(res.productList, this.scheduledDemandProductList[index][rowIndex])) {
        this.scheduledDemandProductList[index][rowIndex] = []
        this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.scheduledDemandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.scheduledDemandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct']) {
        ite.at(rowIndex).get('demandProductMaterialCode')?.setValue('');
      }
      if ((!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProductMaterialCode'])) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'];
        this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'])
      } else if (this.scheduledDemandProductList[index][rowIndex].includes(ite.at(rowIndex)?.get('scheduledProduct')?.value)) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct']
        this.defaultProductMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProductMaterialCode']
      }
      this.demandProductIteMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'])
    }
    else {
      let origin = this.transportArray()?.at(index).get('origin') as FormArray
      let res = await this.filterProductCategory(origin.at(rowIndex)?.get('demandProduct')?.value, 'origin', index, rowIndex, this.demandProductList[index][rowIndex])

      if (!this.arraysEqual(res.productList, this.scheduledDemandProductList[index][rowIndex])) {
        this.scheduledDemandProductList[index][rowIndex] = []
        this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.scheduledDemandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.scheduledDemandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct']) {
        origin.at(rowIndex).get('demandProductMaterialCode')?.setValue('');
      }
      if ((!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProductMaterialCode'])) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'];
        this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'])
      }
      else if (this.scheduledDemandProductList[index][rowIndex].includes(origin.at(rowIndex)?.get('scheduledProduct')?.value)) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'];
        this.defaultProductMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProductMaterialCode'];
      }
      this.demandProductOriginMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'])
    }

  }

  onScheduledProductChangeMaterialCode(index: any, rowIndex: any, formType:any) {
    let formArray = this.transportArray()?.at(index).get(formType) as FormArray
    if ((!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode'])) {
      if(formType ==='origin'){
        this.demandProductOriginMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode'];
      }else{
        this.demandProductIteMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode'];
      }
    }
    this.defaultProduct[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode']);
   if(!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode']){
    formArray.at(rowIndex).get('scheduledProduct')?.setValue('');
    this.onScheduledProductChange(index, rowIndex)
   }
  }

  async onScheduledProductChange(index: any, rowIndex: any) {
    const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
    //response from api will be set to demandproduct list
    if ((this.createPipelineNominationForm.get('transportSystem')?.value === this.intankEast || this.createPipelineNominationForm.get('transportSystem')?.value === this.intankWest) && isITNomSectionRequired) {
      let ite = this.transportArray()?.at(index).get('ite') as FormArray
      let res = await this.filterProductCategory(ite.at(rowIndex)?.get('scheduledProduct')?.value, 'ite', index, rowIndex,  this.scheduledDemandProductList[index][rowIndex])
      if (!this.arraysEqual(res.productList, this.demandProductList[index][rowIndex])) {
        this.demandProductList[index][rowIndex] = []
        this.demandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.demandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.demandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct']) {
        ite.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('');
      }
      
    if ((!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProductMaterialCode'])) {
      this.demandProductIte[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'];
      this.demandProductIteMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'])
    }
    else if (this.demandProductList[index][rowIndex].includes(ite.at(rowIndex)?.get('demandProduct')?.value)) {
        this.demandProductIte[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct']
        this.demandProductIteMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProductMaterialCode']
      } 
      this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'])
    }
    else {
      let origin = this.transportArray()?.at(index).get('origin') as FormArray
      let res = await this.filterProductCategory(origin.at(rowIndex)?.get('scheduledProduct')?.value, 'origin', index, rowIndex, this.scheduledDemandProductList[index][rowIndex]) //error async

      if (!this.arraysEqual(res.productList, this.demandProductList[index][rowIndex])) {
        this.demandProductList[index][rowIndex] = []
        this.demandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.demandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.demandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct']) {
          origin.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('');
      }
      if ((!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProductMaterialCode'])) {
        this.demandProductOrigin[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'];
        this.demandProductOriginMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'])
      }
      else if (this.demandProductList[index][rowIndex].includes(origin.at(rowIndex)?.get('demandProduct')?.value)) {
        this.demandProductOrigin[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct']
        this.demandProductOriginMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProductMaterialCode']
      }
      this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'])
    }
  }

  onScheduledProductDestiChangeMaterialCode(index: any, rowIndex: any) {
    
    let formArray = this.transportArray()?.at(index).get('destination') as FormArray
    if ((!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode'])) {
        this.demandProductDestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'];
    }
    this.defaultProductdesti[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'])
    if(!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']){
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('');
      this.onScheduledProductDestiChange(index, rowIndex)
     }
  }

  async onScheduledProductDestiChange(index: any, rowIndex: any) {
    
    let desti = this.transportArray()?.at(index).get('destination') as FormArray

    let res = await this.filterProductCategory(desti.at(rowIndex)?.get('scheduledProduct')?.value, 'destination', index, rowIndex, this.scheduledDemandProductListDesc[index][rowIndex])
    if (!this.arraysEqual(res.productList, this.demandProductListDesc[index][rowIndex])) {

      this.demandProductListDesc[index][rowIndex] = []
      this.demandProductListDescMaterialCode[index][rowIndex] = []
      res.productList.forEach((item: any) => {
        this.demandProductListDesc[index][rowIndex].push(item)
      })
      res.productCode.forEach((item: any) => {
        this.demandProductListDescMaterialCode[index][rowIndex].push(item)
      })
    }
    //response from api will be set to demandproduct list
    
    //response from api will be set to demandproduct list
    if (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct']) {
      desti.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('');
    }
    if ((!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode'])) {
      this.demandProductDesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'];
      this.demandProductDestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'])
    }
    else if (this.demandProductListDesc[index][rowIndex].includes(desti.at(rowIndex)?.get('demandProduct')?.value)) {
      this.demandProductDesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct']
      this.demandProductDestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']
    }

    this.defaultProductdestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'])
  }

  async filterProductCategory(product: any, type: string, index: number, rowIndex: number, productList: any) {
    // filter list of all product present in productlist and then find category.
    let result = this.masterProductList.filter((e: any)=> {
      if(productList?.includes(e.productName)){
        return e;
      }
     })
    let filteredList: any = []
    let filteredListMaterialCode: any = []
    let category: any = []
    if (product) {
      let productFiltered = result.find((element: any) => element.productName === product);
      category = result.filter((e: any) => {
        return e.productCategory === productFiltered?.productCategory;
      })
      category.forEach((i: any) => {
        filteredList.push(i.productName)
        filteredListMaterialCode.push(i.productCode)
      })
      filteredList = this.removeDuplicates(filteredList)
      filteredListMaterialCode = this.removeDuplicates(filteredListMaterialCode)
      return {productList:filteredList,productCode:filteredListMaterialCode}

    }
    else {
      const formArray = this.transportArray().at(index).get(type) as FormArray;
      const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createPipelineNominationForm.get('transportSystem');
      const location = formArray.at(rowIndex)?.get('location');
      let ts = transportSystemControl?.value ? [transportSystemControl.value] : null;
      let term = location?.value ? [location.value] : null;
      let response: any;
      if (this.isSearchPopup) {
        let desk = this.deskNumber;
  
        // if (this.isCargoDesks) {
  
        //   response = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
        // } else {
  
          this.fromSearchCalled = false;
          response = await this.utilities.getPipelineDropdownListforSearch(desk)
        // }
      }
      else{
      // if (this.isCargoDesks) {
        
      //   response =  this.utilities.getPipelineCPDropdownList(this.scenario, ts, term);
      // } else {
        response = await this.utilities.getPipelineDropdownList(ts, term)
      // }
    }
      response?.value?.product.forEach((i: any) => {
        filteredList.push(i.productName)
        filteredListMaterialCode.push(i.productCode)
      })
    
      this.productListReupload(index, rowIndex, type, filteredList, filteredListMaterialCode)
      filteredList = this.removeDuplicates(filteredList)
      filteredListMaterialCode = this.removeDuplicates(filteredListMaterialCode)
      return {productList:filteredList,productCode:filteredListMaterialCode}
    }
  }
  productListReupload(index: any, rowIndex: any, type: any, res: any,  resCode:any) {

    //reset the default to select  and dropdown of scheduled product and demand product to old data list

    if (type === 'destination') {
      this.demandProductDesti[index][rowIndex] = ''
      this.demandProductListDesc[index][rowIndex] = []
      this.scheduledDemandProductListDesc[index][rowIndex] = []
      this.defaultProductdesti[index][rowIndex] = ''

      this.demandProductDestiMaterialCode[index][rowIndex] = ''
      this.demandProductListDescMaterialCode[index][rowIndex] = []
      this.scheduledDemandProductListDescMaterialCode[index][rowIndex] = []
      this.defaultProductdestiMaterialCode[index][rowIndex] = ''
      res.forEach((e: any) => {
        this.scheduledDemandProductListDesc[index][rowIndex].push(e)
        this.demandProductListDesc[index][rowIndex].push(e)
      })
      resCode.forEach((e: any) => {
        this.scheduledDemandProductListDescMaterialCode[index][rowIndex].push(e)
        this.demandProductListDescMaterialCode[index][rowIndex].push(e)
      })
    }
    if (type === 'origin') {
      this.demandProductOrigin[index][rowIndex] = ''
      this.defaultProduct[index][rowIndex] = ''
      this.scheduledDemandProductList[index][rowIndex] = []
      this.demandProductList[index][rowIndex] = []
      this.demandProductOriginMaterialCode[index][rowIndex] = ''
      this.defaultProductMaterialCode[index][rowIndex] = ''
      this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
      this.demandProductListMaterialCode[index][rowIndex] = []
      res.forEach((e: any) => {
        this.scheduledDemandProductList[index][rowIndex].push(e)
        this.demandProductList[index][rowIndex].push(e)
      })
      resCode.forEach((e: any) => {
        this.scheduledDemandProductListMaterialCode[index][rowIndex].push(e)
        this.demandProductListMaterialCode[index][rowIndex].push(e)
      })
    }
    if (type === 'ite') {
      this.demandProductIte[index][rowIndex] = ''
      this.defaultProduct[index][rowIndex] = ''
      this.scheduledDemandProductList[index][rowIndex] = []
      this.demandProductList[index][rowIndex] = []
      this.demandProductIteMaterialCode[index][rowIndex] = ''
      this.defaultProductMaterialCode[index][rowIndex] = ''
      this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
      this.demandProductListMaterialCode[index][rowIndex] = []
      res.forEach((e: any) => {
        this.scheduledDemandProductList[index][rowIndex].push(e)
        this.demandProductList[index][rowIndex].push(e)
      })
      resCode.forEach((e: any) => {
        this.scheduledDemandProductListMaterialCode[index][rowIndex].push(e)
        this.demandProductListMaterialCode[index][rowIndex].push(e)
      })

    }


  }

 async fetchLocation(index: number) {
  const transportSystemControl = this.transportArray().at(index).get('transportSystemMode');
      let response: any;
      if (this.isSearchPopup) {
        let desk = this.deskNumber;
  
        // if (this.isCargoDesks) {
  
        //   response = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
        // } else {
  
          this.fromSearchCalled = false;
          response = await this.utilities.getPipelineDropdownListforSearch(desk)
        // }
      }
      else{
      // if (this.isCargoDesks) {
        
      //   response =  this.utilities.getPipelineCPDropdownList(this.scenario, [transportSystemControl?.value]);
      // } else {
        response = await this.utilities.getPipelineDropdownList([transportSystemControl?.value])
      // }
    }
        if (response?.value?.terminal.length > 0) {
          this.terminalList[index] = []
        }
        this.setLocation(response, index, false)
  }
  async callApi(transportSystemControl: any, location: any, index: any, rowIndex: any, tableType: string) {
    this.individualList = []
    this.individualListMaterialCode = []
    let urBody: any = {
      desk: `pl${this.selectedDeskParameter}`,
      region: this.region,
      includeXLocations: true,
      terminal: location ? [location] : null,
      transportsystem: transportSystemControl ? [transportSystemControl] : null,
      requestFor: "dropdown",
      // isCargoType: this.isCargoDesks ? true : undefined,
      // isPipelineType: this.isCargoDesks ? true : undefined
    }
    if(this.inCpTab || (this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields))){
      urBody.modeOfTransport = [this.scenario?.charAt(0).toUpperCase() + this.scenario.slice(1)];
    }
    if(this.selectedDeskParameter){
        let response: any;
        if (this.isSearchPopup) {
          let desk = this.deskNumber;
    
          // if (this.isCargoDesks) {
    
          //   response = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
          // } else {
    
            this.fromSearchCalled = false;
            response = await this.utilities.getPipelineDropdownListforSearch(desk)
          // }
        }
        else{
        // if (this.isCargoDesks) {
          
        //   response =  this.utilities.getPipelineCPDropdownList(this.scenario,[transportSystemControl], [location]);
        // } else {
          response = await this.utilities.getPipelineDropdownList([transportSystemControl], [location])
        // }    
      } 
          this.individualList = []
          this.individualListMaterialCode = []

          if (response?.value?.product.length > 0) {
            this.individualList = [];
            this.individualListMaterialCode = []
            response?.value?.product.forEach((i: any) => {
              this.individualList.push(i.productName)
              this.individualListMaterialCode.push(i.productCode)
            })
            if (tableType === 'origin' || tableType === 'ite') {
              this.demandProductList[index][rowIndex] = this.individualList
              this.scheduledDemandProductList[index][rowIndex] = this.individualList
              this.demandProductListMaterialCode[index][rowIndex] = this.individualListMaterialCode
              this.scheduledDemandProductListMaterialCode[index][rowIndex] = this.individualListMaterialCode
            }
            else {
              this.demandProductListDesc[index][rowIndex] = this.individualList
              this.scheduledDemandProductListDesc[index][rowIndex] = this.individualList
              this.demandProductListDescMaterialCode[index][rowIndex] = this.individualListMaterialCode
              this.scheduledDemandProductListDescMaterialCode[index][rowIndex] = this.individualListMaterialCode
            }
            this.checkProductSelected(this.individualList, index, rowIndex, tableType)
          }
          // if (response?.value?.terminal.length > 0) {
          //     this.masterTerminalList[index] = response?.value?.terminal.map((i: any) =>
          //     i.terminalCode
          //   )
          // }
          // if (!this.isCargoDesks) {
            if (this.deletedTransportSys.length) {
              this.checkIndividualLocationProduct('callapi', index, rowIndex)
            } else {
              this.checkLocationProduct('callapi', index, rowIndex)
            }
          // }
    }
   
  }

  removeDuplicates(arr: any) {
    let newArr: any = []
    arr.forEach((ele: any) => {
      if (newArr.length === 0) {
        newArr.push(ele)
      }
      else {
        newArr.forEach((e: any) => {
          if (!newArr.includes(ele)) {
            newArr.push(ele)
          }
        })
      }
    })
    return newArr

  }
  setLocation(response: any, index: any, flag: boolean) {
    this.terminalList[index] = [];
    this.masterTerminalList[index] = [];
    if (response?.value?.product.length > 0) {
      if (flag) {
        this.productList = []
        this.productListMaterialCode =[];
      }
      this.demandProductList[index] = []
      this.scheduledDemandProductList[index] = []
      response?.value?.product.forEach((i: any) => {
        this.productList.push(i.productName)
        this.productListMaterialCode.push(i.productCode);
        this.masterProductList.push(i)
      })

      this.masterProductList = this.removeDuplicates(this.masterProductList)
      this.demandProductList[index].push(this.productList);
      this.scheduledDemandProductList[index].push(this.productList);
      this.demandProductListMaterialCode[index].push(this.productListMaterialCode);
      this.scheduledDemandProductListMaterialCode[index].push(this.productListMaterialCode);

      const transportSystemControl = this.createPipelineNominationForm.get('transportSystem')?.value;
      const locationBasedOnTransportSystem = this.locationTransportSystemMasterData.find((item: any) => {
        return item.transportSystem === transportSystemControl
      }).locationPlantCode;
      const locationIds = locationBasedOnTransportSystem.map((value: any) => value.locationCode);
      this.terminalList[index] = locationIds;
      this.masterTerminalList[index] = locationIds;
      }
    
    // response?.value?.terminal.forEach((i: any) => {
    //   this.terminalList[index].push(i.terminalCode)
    //   this.masterTerminalList[index]?.push(i.terminalCode)

    // })
  }

  setTransportVesselBargeCarrier(response: any) {
    this.transportData = response;
    this.terminalList[0] = [];
    this.productList = [];
    this.productListMaterialCode =[];
    this.demandProductList[0] = [];
    this.masterProductList = [];
    this.masterProductListForCp = [];
    this.transportSystemArray = [];
    this.transportDescArray = [];
    if(this.scenario?.toLowerCase() === 'intank'){
      response.value?.transportsystem.find((ts: any) => {
        if(ts?.modeOfTransportDesc?.toLowerCase() ==='pipeline' && (ts.transportSystemCode === 'ITE' || ts.transportSystemCode === 'ITW')){
          this.transportSystemArray.push(ts.transportSystemCode);
          this.transportDescArray.push(ts);
          this.transportSystemModeArray.push(ts.transportSystemCode)
          return true;
        }else{
          return false;
        }
        
      });
    }else if(this.scenario?.toLowerCase() === 'pipeline'){
      response.value?.transportsystem.map((ts: any) => {
        if(ts.transportSystemCode !== 'ITE' && ts.transportSystemCode !== 'ITW'){
        this.transportSystemArray.push(ts.transportSystemCode);
        this.transportDescArray.push(ts);
        this.transportSystemModeArray.push(ts.transportSystemCode);
        }
        return ts.transportSystemCode;
      });
    }else{
      this.transportSystemArray = response.value?.transportsystem.map((ts: any) => {
        this.transportSystemArray.push(ts.transportSystemCode);
        this.transportDescArray.push(ts);
        this.transportSystemModeArray.push(ts.transportSystemCode)
        return ts.transportSystemCode;
      });
    }
    this.transportSystemModeArray = this.removeDuplicates(this.transportSystemModeArray)
      // response?.value?.terminal.forEach((i: any) => {
      //   this.terminalList[0].push(i.terminalCode)
      //   this.masterTerminalListForCp.push(i);

      // })

    if (!this.masterProductListForCp.length) {
      response?.value?.product.forEach((i: any) => {
        this.productList.push(i.productName)
        this.productListMaterialCode.push(i.productCode);
        this.masterProductList.push(i);
        this.masterProductListForCp.push(i);
      })
      this.demandProductList[0].push(this.productList);
      this.demandProductListDesc[0].push(this.productList);
      this.demandProductListMaterialCode[0].push(this.productListMaterialCode);
      this.demandProductListDescMaterialCode[0].push(this.productListMaterialCode);
  }
}

  async getMasterDataForCargo() {
    if (!this.selectedDeskParameter) {
      return;
    }
    this.masterApiCount = 1;
    this.nominationService.triggerMasterCargoAPI?.next(false);
    if (this.selectedDeskParameter) {
      let response: any;
      // if (this.isCargoDesks) {

      //   response =  this.utilities.getPipelineCPDropdownList(this.scenario);
      // } else {
        response = await this.utilities.getPipelineDropdownList();
      // }
      this.setTransportVesselBargeCarrier(response);
      this.masterApiCount = 0;
    }
  }

  async onTransportSystemChange() {  
    if(this.isnotcreatedFromReforEdited || this.isUserInteraction) {
    this.transportApiCall += 1;
    if (this.transportApiCall === 1) {
      const transportSystemControl = this.createPipelineNominationForm.get('transportSystem');
      let urBody: any = {
        desk: `pl${this.selectedDeskParameter}`,
        region: this.region,
        includeXLocations: true,
        terminal: null,
        transportsystem: transportSystemControl?.value ? [transportSystemControl?.value] : null,
        requestFor: "dropdown",
        // isCargoType: this.isCargoDesks ? true : undefined,
        // isPipelineType: this.isCargoDesks ? true : undefined
      }
      if (this.inCpTab || (this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields || this.nomCargoFieldObj.pipelineFields || this.nomCargoFieldObj.inTankFields))) {
        let setMotVal = (this.scenario.toLowerCase() === 'intank') ? 'pipeline' : this.scenario;
        urBody.modeOfTransport = [setMotVal?.charAt(0).toUpperCase() + setMotVal.slice(1)];
      }
      if (this.selectedDeskParameter) {
        let response: any;
        if (this.isSearchPopup) {
          let desk = this.deskNumber;
    
          // if (this.isCargoDesks) {
    
          //   response = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
          // } else {
    
            this.fromSearchCalled = false;
            response = await this.utilities.getPipelineDropdownListforSearch(desk)
          // }
        }
        else{
        // if (this.isCargoDesks) {

        //   response =  this.utilities.getPipelineCPDropdownList(this.scenario, [transportSystemControl?.value])
        // } else {
          response = await this.utilities.getPipelineDropdownList([transportSystemControl?.value])
        // } 
      }
        this.transportApiCall = 0;
        if (response?.value?.terminal.length > 0) {
          this.terminalList = [[]]
        }
        if (this.inCpNomTab || this.inCpTab) {
          this.defaultTransportSystem = response?.value?.transportsystem.filter((tr: any) => {
            return tr.transportSystemCode === transportSystemControl?.value
          }).length ? transportSystemControl?.value : '';
          if (this.scenario?.toLowerCase() === 'pipeline' && (this.defaultTransportSystem === 'ITE' || this.defaultTransportSystem === 'ITW')) {
            this.defaultTransportSystem = '';
          }
          if (this.scenario?.toLowerCase() === 'intank' && this.defaultTransportSystem !== 'ITE' && this.defaultTransportSystem !== 'ITW') {
            this.defaultTransportSystem = '';
          }
          this.checkAndValidateForm();
        }
        this.setLocation(response, 0, true);
        this.triggerTransportSystem()
      }
    }
  }
  this.isUserInteraction = true;
  }

  checkAndValidateForm(){
    if(!this.defaultTransportSystem){
      this.createPipelineNominationForm.get('transportSystem')?.setValue("");
    }
    this.checkForVesselvalue()
    if(!this.vesselNameValue){
       this.createPipelineNominationForm.get('vesselName')?.setValue("");
    }
  }
  triggerTransportSystem() {
    const transportSystemControl = this.createPipelineNominationForm.get('transportSystem');
    const checkboxControl = this.createPipelineNominationForm.get('selectMultipleCheckbox');
    const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
    if (transportSystemControl && checkboxControl) {
      const selectedOption = transportSystemControl.value;
      this.selectedTransportOption = selectedOption;
      if ((transportSystemControl.value === this.intankEast || transportSystemControl.value === this.intankWest) && isITNomSectionRequired) {
        checkboxControl.disable();
        this.hideDestination = false;
        this.showMultipleTables = true;
        this.isOldTransportITE = true

        this.isOldtransportVal(transportSystemControl)
        //call this if transport ,origin, destination, ite has value

      } else {
        checkboxControl.enable();
        this.hideDestination = true;
        this.showMultipleTables = false;
        this.isOldTransport = true
        this.isOldtransportValITE()
      }

    }

  }
  isOldtransportVal(transportSystemControl: any) {
    if (this.isOldTransport) {
      this.resetIteFormControl(transportSystemControl)
      this.isOldTransport = false

    }

    else {
      this.checkLocationProduct("origin")
    }
  }
  isOldtransportValITE() {
    if (this.isOldTransportITE) {
      this.resetOriginFormControl();
      this.isOldTransportITE = false
    }
    else {
      this.checkLocationProduct("ite")
    }
  }
  checkProductSelected(list: any, index: any, rowIndex: any, val: string) {  
    const formArray = this.transportArray().at(index).get(val) as FormArray;
    if (val === 'origin' && !list.includes(this.demandProductOrigin[index][rowIndex])) {
      this.demandProductOrigin[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProduct')?.setValue('')
      this.demandProductOriginMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProductMaterialCode')?.setValue('')
    }
    if (val === 'origin' && !list.includes(this.defaultProduct[index][rowIndex])) {
      this.defaultProduct[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('')
      this.defaultProductMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('')
    }
    if (val === 'destination' && !list.includes(this.demandProductDesti[index][rowIndex])) {
      this.demandProductDesti[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProduct')?.setValue('')
      this.demandProductDestiMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProductMaterialCode')?.setValue('')
    }
    if (val === 'destination' && !list.includes(this.demandProductDesti[index][rowIndex])) {
      this.defaultProductdesti[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('')
      this.defaultProductdestiMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('')
    }
    if (val === 'ite' && !list.includes(this.demandProductIte[index][rowIndex])) {
      this.demandProductIte[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProduct')?.setValue('')
      this.demandProductIteMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProductMaterialCode')?.setValue('')
    }
    if (val === 'ite' && !list.includes(this.defaultProduct[index][rowIndex])) {
      this.defaultProduct[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('')
      this.defaultProductMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('')
    }
  }
  checkIndividualLocationProduct(val: string,transportCount?:any, rowIndex?: any) {
    this.transportArray().controls.forEach((element: any, index: any) => {
    
      if (element.value['origin'] && rowIndex < element.value['origin'].length) {

        element.controls['origin'].controls.forEach((e: any,originIndex:any) => {
          if (e.controls['location'] && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('');
            e.controls['demandProduct'].setValue('');
            e.controls['demandProductMaterialCode'].setValue('');
            this.locationOriginArray[index][originIndex] = '';
            this.isTankOriginDisabled[index][originIndex] = false;
            this.defaultTankOrigin[index][originIndex] = '';
            this.demandProductOrigin[index][originIndex] = ''
            this.defaultProduct[index][originIndex] = ''
            this.demandProductOriginMaterialCode[index][originIndex] = ''
            this.defaultProductMaterialCode[index][originIndex] = ''
          }
          else {
            this.locationOriginArray[index][originIndex] = e.controls['location'].value
            this.isTankOriginDisabled[index][originIndex] = this.tankMasterData.some((element: any) => element.tswLocationCode === e.controls['location'].value);
            this.defaultTankOrigin[index][originIndex] = e.controls['tankCode'].value
            this.demandProductOrigin[index][originIndex] = e.controls['demandProduct'].value
            this.defaultProduct[index][originIndex] = e.controls['scheduledProduct'].value
            this.demandProductOriginMaterialCode[index][originIndex] = e.controls['demandProductMaterialCode'].value
            this.defaultProductMaterialCode[index][originIndex] = e.controls['scheduledProductMaterialCode'].value
          }
        }
        
        )

      }
      if (element.value['destination'] && rowIndex < element.value['destination'].length) {

        element.controls['destination'].controls.forEach((e: any,destiIndex:any) => {
          if (e.controls['location'] && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('')
            e.controls['demandProduct'].setValue('')
            e.controls['demandProductMaterialCode'].setValue('')
            this.locationDestiArray[index][destiIndex] = ''
            this.isTankDestiDisabled[index][destiIndex] = false
            this.defaultTankDesti[index][destiIndex] = ''
            this.demandProductDesti[index][destiIndex] = ''
            this.defaultProductdesti[index][destiIndex] = ''
            this.demandProductDestiMaterialCode[index][destiIndex] = ''
            this.defaultProductdestiMaterialCode[index][destiIndex] = ''
          }
          else {
            this.locationDestiArray[index][destiIndex] = e.controls['location'].value
            this.isTankDestiDisabled[index][destiIndex] = this.tankMasterData.some((element: any) => element.tswLocationCode === e.controls['location'].value);
            this.defaultTankDesti[index][destiIndex] = e.controls['tankCode'].value
            this.demandProductDesti[index][destiIndex] = e.controls['demandProduct'].value
            this.defaultProductdesti[index][destiIndex] = e.controls['scheduledProduct'].value
            this.demandProductDestiMaterialCode[index][destiIndex] = e.controls['demandProductMaterialCode'].value
            this.defaultProductdestiMaterialCode[index][destiIndex] = e.controls['scheduledProductMaterialCode'].value
          }
        })
      }
    })
  }

  checkLocationProduct(val: string,transportCount?:any, rowIndex?: any) {
    this.transportArray().controls.forEach((element: any, index: any) => {

      let originRow = 0;
      let destiRow = 0;
      let iteRow = 0;
      if (element.value['origin'] && originRow < element.value['origin'].length) {

        element.controls['origin'].controls.forEach((e: any) => {
          if (e.controls['location'] && this.masterTerminalList[index]?.length && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('');
            e.controls['demandProduct'].setValue('');
            this.locationOriginArray[index][originRow] = ''
            this.isTankOriginDisabled[index][originRow] = false
            this.defaultTankOrigin[index][originRow] = ''
            this.demandProductOrigin[index][originRow] = ''
            this.defaultProduct[index][originRow] = ''
            this.demandProductOriginMaterialCode[index][originRow] = ''
            this.defaultProductMaterialCode[index][originRow] = ''
          }
          else {
            this.locationOriginArray[index][originRow] = e.controls['location']?.value
            this.isTankOriginDisabled[index][originRow] = this.tankMasterData.some((element: any) => element.tswLocationCode === e.controls['location'].value);
            this.defaultTankOrigin[index][originRow] =e.controls['tankCode']?.value
            this.demandProductOrigin[index][originRow] = e.controls['demandProduct']?.value
            this.defaultProduct[index][originRow] = e.controls['scheduledProduct']?.value
            this.demandProductOriginMaterialCode[index][originRow] = e.controls['demandProductMaterialCode']?.value
            this.defaultProductMaterialCode[index][originRow] = e.controls['scheduledProductMaterialCode']?.value
          }
          originRow++;
        })

      }
      if (element.value['destination'] && destiRow < element.value['destination'].length) {

        element.controls['destination'].controls.forEach((e: any) => {
          if (e.controls['location'] && this.masterTerminalList[index]?.length && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('')
            e.controls['demandProduct'].setValue('')
            this.locationDestiArray[index][destiRow] = ''
            this.isTankDestiDisabled[index][destiRow] = false
            this.defaultTankDesti[index][destiRow] = ''
            this.demandProductDesti[index][destiRow] = ''
            this.defaultProductdesti[index][destiRow] = ''
            this.demandProductDestiMaterialCode[index][destiRow] = ''
            this.defaultProductdestiMaterialCode[index][destiRow] = ''
          }
          else {
            this.locationDestiArray[index][destiRow] = e.controls['location']?.value
            this.isTankDestiDisabled[index][destiRow] = this.tankMasterData.some((element: any) => element.tswLocationCode === e.controls['location'].value);
            this.defaultTankDesti[index][destiRow] = e.controls['tankCode'].value
            this.demandProductDesti[index][destiRow] = e.controls['demandProduct']?.value
            this.defaultProductdesti[index][destiRow] = e.controls['scheduledProduct']?.value
            this.demandProductDestiMaterialCode[index][destiRow] = e.controls['demandProductMaterialCode']?.value
            this.defaultProductdestiMaterialCode[index][destiRow] = e.controls['scheduledProductMaterialCode']?.value
          }
          destiRow++;
        })
      }
      this.isIteLocCheck(element, index, iteRow)

    })
  }
  isIteLocCheck(element: any, index: any, iteRow: any) {
    if (element.value['ite'] && iteRow < element.value['ite'].length) {

      element.controls['ite'].controls.forEach((e: any) => {
        if (e.controls['location'] && this.masterTerminalList[index].length && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
          e.controls['location'].setValue('')
          e.controls['demandProduct'].setValue('')
          e.controls['demandProductMaterialCode'].setValue('')
          this.locationIteArray[index][iteRow] = ''
          this.demandProductIte[index][iteRow] = ''
          this.defaultProduct[index][iteRow] = ''
          this.demandProductIteMaterialCode[index][iteRow] = ''
          this.defaultProductMaterialCode[index][iteRow] = ''
          
        }
        else {
          this.locationIteArray[index][iteRow] = e.controls['location'].value
          this.demandProductIte[index][iteRow] = e.controls['demandProduct'].value
          this.defaultProduct[index][iteRow] = e.controls['scheduledProduct'].value
          this.demandProductIteMaterialCode[index][iteRow] = e.controls['demandProductMaterialCode'].value
          this.defaultProductMaterialCode[index][iteRow] = e.controls['scheduledProductMaterialCode'].value

        }
        iteRow++;
      })
    }
  }
  addTransport() {
    this.isMultipleRowItemOrigin.push(['O']);
    this.isMultipleRowItemDestination.push(['D']);
    this.isMultipleRowItemIte.push(['IT']);
    let transportIndex = this.transportArray()?.controls?.length
    this.isMultipleRowTransport = this.isMultipleRowTransport + 1;
    this.transportArray().push(this.createFormGroupTransport(null, transportIndex));
    let transport = this.createPipelineNominationForm.get('transport') as FormArray
    transport.at(transportIndex).get("transportSystemMode")?.setValidators(Validators.required);
    transport.at(transportIndex).get("transportSystemMode")?.setErrors({ 'invalid': true });
    this.originRDIDescSelected[transportIndex] = []
    this.originRDISelected[transportIndex] = []
    this.destinationRDIDescSelected[transportIndex] = []
    this.destinationRDISelected[transportIndex] = []
    this.locationOriginArray[transportIndex] = []
    this.demandProductOrigin[transportIndex] = []
    this.locationDestiArray[transportIndex] = []
    this.demandProductDesti[transportIndex] = []
    this.demandProductList[transportIndex] = []
    this.demandProductListDesc[transportIndex] = []
    this.demandProductOriginMaterialCode[transportIndex] = []
    this.demandProductDestiMaterialCode[transportIndex] = []
    this.demandProductListMaterialCode[transportIndex] = []
    this.demandProductListDescMaterialCode[transportIndex] = []
    this.terminalList[transportIndex] = []
    this.defaultProduct[transportIndex] = []
    this.scheduledDemandProductList[transportIndex] = []
    this.scheduledDemandProductListDesc[transportIndex] = []
    this.defaultProductdesti[transportIndex] = []
    this.defaultProductMaterialCode[transportIndex] = []
    this.scheduledDemandProductListMaterialCode[transportIndex] = []
    this.scheduledDemandProductListDescMaterialCode[transportIndex] = []
    this.defaultProductdestiMaterialCode[transportIndex] = []
    this.contractLineItemsOrigin[transportIndex] = []
    this.refContractLineItems[transportIndex] = []
    this.contractLineItemsDestination[transportIndex] = []
    this.refContractLineItemsDesti[transportIndex] = []
    this.contractLineItemsITE[transportIndex] = []
    this.refContractLineItemsIte[transportIndex] = []
    this.demandProductList[transportIndex].push(this.productList)
    this.demandProductListDesc[transportIndex].push(this.productList)
    this.scheduledDemandProductList[transportIndex].push(this.productList)
    this.scheduledDemandProductListDesc[transportIndex].push(this.productList)
    this.demandProductListMaterialCode[transportIndex].push(this.productListMaterialCode)
    this.demandProductListDescMaterialCode[transportIndex].push(this.productListMaterialCode)
    this.scheduledDemandProductListMaterialCode[transportIndex].push(this.productListMaterialCode)
    this.scheduledDemandProductListDescMaterialCode[transportIndex].push(this.productListMaterialCode)
    this.terminalList[transportIndex] = this.terminalList[0]
    this.masterTerminalList[transportIndex] = this.masterTerminalList[0];
    this.scheduledTime[transportIndex] = []
    this.scheduledTimeDesti[transportIndex] = []
    this.scheduledTime[transportIndex].push('00:00')
    this.scheduledTimeDesti[transportIndex].push('00:00')
    this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({'invalid': true})
    const newlyAddedTransportFirstOriginDate = new Date(transport?.value[transport?.value.length - 1]?.origin[0]?.scheduledDate);
    const newlyAddedTransportFirstDestinationDate = new Date(transport?.value[transport?.value.length - 1]?.destination[0]?.scheduledDate);
    this.autoAdjustmentInitialDates.push({initialOriginDate: newlyAddedTransportFirstOriginDate, initialDestinationDate: newlyAddedTransportFirstDestinationDate});
  }

  checkifOriginDestiisEmpty() {
    let alloriginisempty = true;
    let alldestisempty = true;

    this.transportArray().controls.forEach((ele: any) => {
      ele.controls['origin'].controls.forEach((item: any) => {
        Object.keys(item.controls).forEach((e) => {
          // if any row any field has value remove the validation for isoriginDestiTouched
          if (e !== 'id' && item.controls[e].value !== '') {
            alloriginisempty = false
          }
        })
      })

      ele.controls['destination'].controls.forEach((item: any) => {
        Object.keys(item.controls).forEach((e) => {
          if (e !== 'id' && item.controls[e].value !== '') {
            alldestisempty = false
          }
        })

      })

    })
    if (alloriginisempty && alldestisempty) {
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
    }
    else {
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.clearValidators()
    }
  }
  deleteTransportSystem(transportIndex: number) {
    this.deletedTransportSys = [];
    this.isMultipleRowItemOrigin?.splice(transportIndex,1);
    this.isMultipleRowItemDestination.splice(transportIndex,1);
    this.isMultipleRowItemIte.splice(transportIndex,1);
    this.deletedTransportSys.push(this.transportArray().controls[transportIndex].value);
    this.transportArray().removeAt(transportIndex);
    this.originRDIDescSelected.splice(transportIndex, 1);
    this.originRDISelected.splice(transportIndex, 1);
    this.locationOriginArray.splice(transportIndex, 1);
    this.defaultProduct.splice(transportIndex, 1);
    this.demandProductOrigin.splice(transportIndex, 1);
    this.defaultProductMaterialCode.splice(transportIndex, 1);
    this.demandProductOriginMaterialCode.splice(transportIndex, 1);

    this.destinationRDIDescSelected.splice(transportIndex, 1);
    this.destinationRDISelected.splice(transportIndex, 1);
    this.locationDestiArray.splice(transportIndex, 1);
    this.defaultProductdesti.splice(transportIndex, 1);
    this.demandProductDesti.splice(transportIndex, 1);
    this.defaultProductdestiMaterialCode.splice(transportIndex, 1);
    this.demandProductDestiMaterialCode.splice(transportIndex, 1);
    this.scheduledTime.splice(transportIndex,1)
    this.scheduledTimeDesti.splice(transportIndex,1)
    this.defaultTransportModalSystem.splice(transportIndex, 1);
    this.transportSystemModeArray.splice(transportIndex, 1);
    
    this.isMultipleRowTransport = this.isMultipleRowTransport - 1
    this.demandProductList.splice(transportIndex, 1);
    this.scheduledDemandProductList.splice(transportIndex, 1);
    this.demandProductListMaterialCode.splice(transportIndex, 1);
    this.scheduledDemandProductListMaterialCode.splice(transportIndex, 1);
    
    this.demandProductIte.splice(transportIndex, 1);
    this.demandProductIteMaterialCode.splice(transportIndex, 1);
    this.locationIteArray.splice(transportIndex, 1);
    this.inTankRDIDescSelected.splice(transportIndex, 1);
    this.inTankRDISelected.splice(transportIndex, 1);
    this.terminalList.splice(transportIndex, 1);
    this.scheduledDemandProductListDesc.splice(transportIndex, 1);
    this.demandProductListDesc.splice(transportIndex, 1);
    this.refContractLineItems.splice(transportIndex, 1);
    this.refContractLineItemsDesti.splice(transportIndex,1);
    this.refContractLineItemsIte.splice(transportIndex,1)
    this.contractLineItemsOrigin.splice(transportIndex,1);
    this.contractLineItemsDestination.splice(transportIndex,1);
    this.contractLineItemsITE.splice(transportIndex,1);
    this.updatedContractLineItems.splice(transportIndex,1);
    this.scheduledDemandProductListDescMaterialCode.splice(transportIndex, 1);
    this.demandProductListDescMaterialCode.splice(transportIndex, 1);
    this.autoAdjustmentInitialDates?.splice(transportIndex, 1);
    this.checkIfDeleted();
    this.createPipelineNominationForm.get('isTransportpicked')?.setErrors(null)
    this.createPipelineNominationForm.get('isTransportpicked')?.clearValidators()
  }

  checkIfDeleted(){
    this.deskNumber = this.deskNumber? this.deskNumber: this.selectedDeskId;

    const nominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));

    let modeOfTransportDesc = this.deletedTransportSys[0]?.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === this.deletedTransportSys[0]?.transportSystemMode)?.modeOfTransportDesc : this.transportDescArray.find((e: any) => e.transportSystemCode === nominationData.transportSystem)?.modeOfTransportDesc

      const delEle:any= {
      "id":  this.deletedTransportSys[0]?.origin[0]?.rdi?this.deletedTransportSys[0]?.origin[0]?.id:this.deletedTransportSys[0]?.destination[0]?.id,//this.trNominationForPipeline?.id,
      "modeOfTransport": this.deletedTransportSys[0]?.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === this.deletedTransportSys[0]?.transportSystemMode)?.modeOfTransportCode : this.transportDescArray.find((e: any) => e.transportSystemCode === nominationData.transportSystem)?.modeOfTransportCode,
      "modeOfTransportDesc": modeOfTransportDesc,
      "transportSystem":this.deletedTransportSys[0]?.transportSystemMode,
      "nominationNo": nominationData?.nominationNo?.toUpperCase(),
      "textLine":nominationData?.nominationNotes,
      "deskFilter": this.selectedDeskParameter,
      "tdVehNo": "",// mode of transport
      "udmModeoftransportDesc": modeOfTransportDesc,
      "sys_status": Nom.INPROCESS,
      "udmDesk":this.deskNumber,
      "cycle": nominationData?.cycleId,
      "headerToItemNav": [],
      "nominationScenario": "createnomination",
      "deletionIndicator":"X",
      "udmNominationGrouping": nominationData.selectMultipleCheckbox?'multiple': '',

    }

    this.deletedTransportSys.forEach((ele:any)=>{
      const checkValidDestination = ele.destination.every((e:any)=>e.location);
      if(checkValidDestination ){
        ele.destination.forEach((des:any)=>{
          des.deletionIndicator = "X";
          des.referenceDocumentIndicator = 'X'
          des.scheduleType = 'D';
          des.scheduledDate = this.datePipe.transform(des.scheduledDate, "yyyyMMdd");
          des.udmItemStatus = Nom.INPROCESS;
        })
        delEle.headerToItemNav = [...delEle.headerToItemNav,...ele.destination]
      } 
      const checkValidOrigin = ele.origin.every((e:any)=>e.location);
      if(checkValidOrigin){
      ele.origin.forEach((ori:any)=>{
        ori.deletionIndicator = "X";
        ori.referenceDocumentIndicator = 'X'
        ori.scheduleType = 'O';
        ori.scheduledDate = this.datePipe.transform(ori.scheduledDate, "yyyyMMdd");
        ori.udmItemStatus = Nom.INPROCESS;

      })
      
      delEle.headerToItemNav = [...delEle.headerToItemNav,...ele.origin];
    }
    })
    this.delEleArr.push(delEle);
    
  }



  deleteOriginRow(transportIndex: number, rowIndex: number) {
    this.deletedNomList(transportIndex, rowIndex, 'origin')
    this.origin(transportIndex).removeAt(rowIndex);
      this.isMultipleRowItemOrigin[transportIndex]?.splice(rowIndex,1);
      this.demandProductOrigin[transportIndex].splice(rowIndex, 1);
      this.defaultProduct[transportIndex].splice(rowIndex, 1);
      this.demandProductOriginMaterialCode[transportIndex].splice(rowIndex, 1);
      this.defaultProductMaterialCode[transportIndex].splice(rowIndex, 1);
      this.locationOriginArray[transportIndex].splice(rowIndex, 1);
      this.isTankOriginDisabled[transportIndex].splice(rowIndex, 1);
      this.defaultTankOrigin[transportIndex].splice(rowIndex, 1);
      this.originRDIDescSelected[transportIndex].splice(rowIndex, 1);
      this.originRDISelected[transportIndex].splice(rowIndex, 1);
      this.scheduledDemandProductList[transportIndex].splice(rowIndex, 1);
      this.refContractLineItems[transportIndex].splice(rowIndex,1);
      this.contractLineItemsOrigin[transportIndex].splice(rowIndex,1)
      this.scheduledDemandProductListMaterialCode[transportIndex].splice(rowIndex, 1);
      this.origin(transportIndex).value.forEach((element: any) => {
      this.isOriginRequired[transportIndex] = false;
      this.scheduledTime[transportIndex].splice(rowIndex,1);
        Object.keys(element).forEach((e: any) => {
          if (element[e] !== '') {
            this.isOriginRequired[transportIndex] = true;
          }

        })
      });

    this.checkifOriginDestiisEmpty()
    // setTimeout(() => {
    //   this.checkQTY(transportIndex, rowIndex)
    // }, 1);
    
  }


  deletedestinationRow(transportIndex: number, rowIndex: number) {
    this.deletedNomList(transportIndex, rowIndex, 'destination');
    this.destination(transportIndex).removeAt(rowIndex);
      this.isMultipleRowItemDestination[transportIndex]?.splice(rowIndex,1);
      this.locationDestiArray[transportIndex].splice(rowIndex, 1);
      this.isTankDestiDisabled[transportIndex].splice(rowIndex, 1);
      this.defaultTankDesti[transportIndex].splice(rowIndex,1);
      this.demandProductDesti[transportIndex].splice(rowIndex, 1);
      this.defaultProductdesti[transportIndex].splice(rowIndex, 1);
      this.demandProductList[transportIndex].splice(rowIndex, 1);
      this.scheduledDemandProductList[transportIndex].splice(rowIndex, 1);
      this.demandProductDestiMaterialCode[transportIndex].splice(rowIndex, 1);
      this.defaultProductdestiMaterialCode[transportIndex].splice(rowIndex, 1);
      this.demandProductListMaterialCode[transportIndex].splice(rowIndex, 1);
      this.scheduledDemandProductListMaterialCode[transportIndex].splice(rowIndex, 1);
      this.destinationRDIDescSelected[transportIndex].splice(rowIndex, 1);
      this.destinationRDISelected[transportIndex].splice(rowIndex, 1);
      this.scheduledTimeDesti[transportIndex].splice(rowIndex,1)
      this.contractLineItemsDestination[transportIndex].splice(rowIndex,1)
      this.refContractLineItemsDesti[transportIndex].splice(rowIndex,1)

      this.destination(transportIndex).value.forEach((element: any) => {
        this.isDestinationRequired[transportIndex] = false;
  
          Object.keys(element).forEach((e: any) => {
            if (element[e] !== '') {
              this.isDestinationRequired[transportIndex] = true;
            }
  
          })
        });
      

      this.destination(transportIndex).value.forEach((element: any) => {
        this.isDestinationRequired[transportIndex] = false;
  
          Object.keys(element).forEach((e: any) => {
            if (element[e] !== '') {
              this.isDestinationRequired[transportIndex] = true;
            }
  
          })
        });
      

    this.checkifOriginDestiisEmpty()
    // setTimeout(() => {
    //   this.checkQTY(transportIndex, rowIndex)
    // }, 1);
  }
  deleteIteRow(transportIndex: number, rowIndex: number) {
   
      this.deletedNomList(transportIndex, rowIndex, 'ITE')
      this.ite(transportIndex).removeAt(rowIndex);
      this.isMultipleRowItemIte[transportIndex]?.splice(rowIndex,1);
      this.demandProductList[transportIndex].splice(rowIndex, 1)
      this.scheduledDemandProductList[transportIndex].splice(rowIndex, 1)
      this.demandProductIte[transportIndex].splice(rowIndex, 1)
      this.defaultProduct[transportIndex].splice(rowIndex, 1)
      this.demandProductListMaterialCode[transportIndex].splice(rowIndex, 1)
      this.scheduledDemandProductListMaterialCode[transportIndex].splice(rowIndex, 1)
      this.demandProductIte[transportIndex].splice(rowIndex, 1)
      this.defaultProductMaterialCode[transportIndex].splice(rowIndex, 1)
      this.locationIteArray[transportIndex].splice(rowIndex, 1)
      this.inTankRDIDescSelected[transportIndex].splice(rowIndex, 1);
      this.inTankRDISelected[transportIndex].splice(rowIndex, 1);
      this.contractLineItemsITE[transportIndex].splice(rowIndex,1);
      this.refContractLineItemsIte[transportIndex].splice(rowIndex,1);
  }
  deletedNomList(transportIndex: number, rowIndex: number, type: string) {
    let obj: any = {}
    if (type === 'origin') {
      let originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
      let itemid = originArray.at(rowIndex).get('itemId');
      let parentId = originArray.at(rowIndex).get('id');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.referenceDocumentIndicator = 'X'
        obj.scheduleType = 'O';
        obj.deletionIndicator = "X"
        obj.parentId = parentId?.value;
        this.deletelist.push(obj);
      
      }
      

    }
    else if (type === 'destination') {
      let destinArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
      let itemid = destinArray.at(rowIndex).get('itemId');
      let parentId = destinArray.at(rowIndex).get('id');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.referenceDocumentIndicator = 'X'
        obj.scheduleType = 'D';
        obj.deletionIndicator = "X"
        obj.parentId = parentId?.value;
        this.deletelist.push(obj);
      }

    }
    else {
      let iteArray = this.transportArray().at(transportIndex).get('ite') as FormArray;
      let itemid = iteArray.at(rowIndex).get('itemId');
      let parentId = iteArray.at(rowIndex).get('id');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.referenceDocumentIndicator = 'X'
        obj.scheduleType = 'IT';
        obj.deletionIndicator = "X";
        obj.parentId = parentId?.value;
        this.deletelist.push(obj);
      
      }

    }
    this.deletedValue = true;

  }
  closePopup(isCancel? : boolean, isInit? :boolean) {
    this.autoAdjustmentInitialDates = [];
    this.isSearchPopup =false
    this.originCount = 0;
    this.destinationCount = 0;
    this.originClickCount = 0;
    this.destinationClickCount = 0;
    this.intankItemId = '';
    this.cpService?.createNominationFromCargoWithReference?.next({ initiate: false, data: {} });
    this.nominationService?.createNominationForPipelineActuation?.next(false);
    this.nominationService.triggerDeselectForCargo.next(true);
    this.nominationService.isCpGridSelected = false;
    this.vesselNameValue = ''
    this.deskNumber = '';
    this.selectedDeskParameter = '';
    this.createPipelineNominationForm.reset({
      nominationNo: '',
      transportSystem: '',
      vesselName:"",
      tug:"",
      scheduledProduct: '',
      selectMultipleCheckbox: '', // Initial value set to false (unchecked)
      selectMultipleCheckbox1: '',
      nominationNotes:'',
      btCustodyChain:'',
      cycleId: '',
      isoriginDestiTouched: '',
      railName:'',
      isTransportpicked: ''
    });
    while (this.transportArray()?.controls?.length !== 0) {
      this.transportArray().removeAt(0);
    }
    this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true });
    this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({ 'invalid': true });
    this.nominationService.nomSelected$.next(false);

    this.transportArray().push(this.createFormGroupTransport(null))
    this.hideDestination = true;
    this.isMultipleTransport = false
    this.createCargoWithRef = false;
    this.isMultipleRowItemDestination = [['D']];
    this.isMultipleRowItemOrigin = [['O']];
    this.isMultipleRowItemIte = [['IT']];
    this.isAdditionalData = false
    this.isMultipleRowTransport = 0
    this.refOrginCount = 0;
    this.refDesCount = 0;
    this.scheduledTime = [['00:00']]
    this.scheduledTimeDesti =[['00:00']]
    this.scheduledDemandProductList = [[]]
    this.scheduledDemandProductListMaterialCode = [[]]
    this.demandProductList = [[]]
    this.demandProductListMaterialCode = [[]]
    this.transportDescArray = []
    this.productList = []
    this.productListMaterialCode =[];
    this.terminalList = [[]]
    this.vesselArray = [[]];
    this.qtyDiff = 0;
    this.demandProductListDesc = [[]]
    this.demandProductListDescMaterialCode = [[]]
    this.scheduledDemandProductListDesc = [[]]
    this.scheduledDemandProductListDescMaterialCode = [[]]
    this.tankOriginList = [[]];
    this.tankDestiList = [[]];
    this.masterProductList = []
    this.contractLineItemsOrigin = [[]];
    this.contractLineItemsDestination = [[]];
    this.contractLineItemsITE = [[]];
    this.updatedContractLineItems = [];
    this.refContractLineItems = [[]];
    this.refContractLineItemsDesti = [[]];
    this.refContractLineItemsIte = [[]];
    this.getContractNumber = "";
    this.getLocation = "";
    this.getMaterial = "";
    this.demandProduct ="";
    this.getScheduledDate = ""
    this.resetEntry(false)
    this.originQtyValue = [0]
    this.destinQtyValue = [0]
    this.referenceNominations = null;
    this.isPopupClosed = true;
    this.deletelist = [];
    this.deletedValue = false;
    this.isOldTransportITE = false;
    this.isOldTransport = true
    this.updateDraftReference = false
    this.showMultipleTables = false;
    this.olddeletedValue = false;
    this.vesselNameValue = '';
    if(this.inCpNomTab || this.inCpTab){
      this.scenario ='barge';
    }
    
    this.closeModalPopup.emit(false);
    if(this.trNominationForPipeline){
      this.trNominationForPipeline.editNomination = false;
    }
    this.deletedTransportSys = [];
    this.delEleArr= [];
    this.errorMessage= [false];
    // if(!this.isCargoDesks){
      this.pipelineValidations()
    // }
    if(!isInit){
      this.initialValues(false);
    }
    // added newly
    if(!isCancel){
     this.store.dispatch(savePipelineTRFiltersUpdated({ isPipelineFilterUpdated: true }));
    }
    //this.appStore.dispatch(setdeskIDSearchedResponse({ deskSearchValues: '', deskSetUpdated: true }));
  }

  onRdiChange(transportIndex: number, rowIndex: number) {
  
    const originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
    const rdiControl = originArray.at(rowIndex)?.get('rdi');
    const descriptionControl = originArray.at(rowIndex)?.get('description');

    if (rdiControl && descriptionControl) {
      const selectedRdi = rdiControl.value;
      const selectedRdiItem = this.rdiDescriptionMap.find(item => item.rdi === selectedRdi);
      if (selectedRdiItem) {
        descriptionControl.setValue(selectedRdiItem.description);
        this.originRDIDescSelected[transportIndex][rowIndex] = selectedRdiItem.description;
      } else {
        descriptionControl.setValue('');
        this.originRDIDescSelected[transportIndex][rowIndex] = '';
      }
    }

    this.onRdiChangeSplit(rowIndex, originArray, transportIndex);
    
  }

  onRdiChangeSplit(rowIndex: any, originArray: any, transportIndex: any){
    if (rowIndex > 0) {

      const scheduledProductinitial = originArray.at(0)?.get('scheduledProduct')?.value;
      const scheduledProductinitialMaterialCode = originArray.at(0)?.get('scheduledProductMaterialCode')?.value;
      const demandProductinitial = originArray.at(0)?.get('demandProduct')?.value;
      const demandProductinitialMaterialCode = originArray.at(0)?.get('demandProductMaterialCode')?.value;
      const scheduledDateinitial = originArray.at(0)?.get('scheduledDate')?.value;
      const locationinitial = originArray.at(0)?.get('location')?.value;
      const tankCodeinitial = originArray.at(0)?.get('tankCode')?.value;

      const scheduledProductCurrent = originArray.at(rowIndex)?.get('scheduledProduct')?.value;
      const demandProductinitialCurrent = originArray.at(rowIndex)?.get('demandProduct')?.value;
      const scheduledDateCurrent = originArray.at(rowIndex)?.get('scheduledDate')?.value;
      const locationCurrent = originArray.at(rowIndex)?.get('location')?.value;
      const tankCodeCurrent = originArray.at(rowIndex)?.get('tankCode')?.value;
      if (scheduledProductinitial && scheduledProductCurrent == "") {
        this.defaultProduct[transportIndex][rowIndex] = scheduledProductinitial
        this.defaultProductMaterialCode[transportIndex][rowIndex] = scheduledProductinitialMaterialCode
      }
      if (demandProductinitial && demandProductinitialCurrent == "") {
        this.demandProductOrigin[transportIndex][rowIndex] = demandProductinitial
        this.demandProductOriginMaterialCode[transportIndex][rowIndex] = demandProductinitialMaterialCode
      }
      if (scheduledDateinitial && scheduledDateCurrent == "") {
        originArray?.at(rowIndex)?.get('scheduledDate')?.setValue(this.datePipe.transform(scheduledDateinitial, "yyy-MM-dd"))

      }
      if (locationinitial && locationCurrent == "") {
        this.locationOriginArray[transportIndex][rowIndex] = locationinitial
      }
      if (tankCodeinitial && tankCodeCurrent == "") {
        this.defaultTankOrigin[transportIndex][rowIndex] = tankCodeinitial
      }
    }
  }
  onDescriptionChange(transportIndex: number, rowIndex: number) {
  
    const originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
    const descriptionControl = originArray.at(rowIndex)?.get('description');
    const rdiControl = originArray.at(rowIndex)?.get('rdi');
    
    if (descriptionControl && rdiControl) {
      const selectedDescription = descriptionControl.value;
      const selectedRdiItem = this.rdiDescriptionMap.find(item => item.description === selectedDescription);
      if (selectedRdiItem) {
        rdiControl.setValue(selectedRdiItem.rdi);
        this.originRDISelected[transportIndex][rowIndex] = selectedRdiItem.rdi;
      } else {
        this.originRDISelected[transportIndex][rowIndex]  = '';
        rdiControl.setValue('');
      }
     
    }
  }

  // function for destination table
  onDestinationRdiChange(transportIndex: number, rowIndex: number) {
 const destinationArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
    const rdiControl = destinationArray.at(rowIndex)?.get('key');
    const descriptionControl = destinationArray.at(rowIndex)?.get('value');

    if (rdiControl && descriptionControl) {
      const selectedRdi = rdiControl.value;
      const selectedRdiItem = this.keyvalueMap.find(item => item.key === selectedRdi);

      if (selectedRdiItem) {
        descriptionControl.setValue(selectedRdiItem.value);
        this.destinationRDIDescSelected[transportIndex][rowIndex] = selectedRdiItem.value;
      } else {
        descriptionControl.setValue('');
        this.destinationRDIDescSelected[transportIndex][rowIndex] = '';
      }
    }
    this.onDestinationRdiChangeSplit(rowIndex, destinationArray, transportIndex);
    
  }
  onDestinationRdiChangeSplit(rowIndex: any, destinationArray: any, transportIndex: any) {
    if (rowIndex > 0) {

      const scheduledProductinitial = destinationArray.at(0)?.get('scheduledProduct')?.value;
      const scheduledProductinitialMaterialCode = destinationArray.at(0)?.get('scheduledProductMaterialCode')?.value;
      const demandProductinitial = destinationArray.at(0)?.get('demandProduct')?.value;
      const demandProductinitialMaterialCode = destinationArray.at(0)?.get('demandProductMaterialCode')?.value;
      const scheduledDateinitial = destinationArray.at(0)?.get('scheduledDate')?.value;
      const locationinitial = destinationArray.at(0)?.get('location')?.value;
      const tankinitial = destinationArray.at(0)?.get('tankCode')?.value;

      const scheduledProductCurrent = destinationArray.at(rowIndex)?.get('scheduledProduct')?.value;
      const demandProductinitialCurrent = destinationArray.at(rowIndex)?.get('demandProduct')?.value;
      const scheduledDateCurrent = destinationArray.at(rowIndex)?.get('scheduledDate')?.value;
      const locationCurrent = destinationArray.at(rowIndex)?.get('location')?.value;
      const tankCurrent = destinationArray.at(rowIndex)?.get('tankCode')?.value;
      if (scheduledProductinitial && scheduledProductCurrent == "") {
        this.defaultProductdesti[transportIndex][rowIndex] = scheduledProductinitial
        this.defaultProductdestiMaterialCode[transportIndex][rowIndex] = scheduledProductinitialMaterialCode
      }
      if (demandProductinitial && demandProductinitialCurrent == "") {
        this.demandProductDesti[transportIndex][rowIndex] = demandProductinitial
        this.demandProductDestiMaterialCode[transportIndex][rowIndex] = demandProductinitialMaterialCode
      }
      if (scheduledDateinitial && scheduledDateCurrent == "") {
        destinationArray?.at(rowIndex)?.get('scheduledDate')?.setValue(this.datePipe.transform(scheduledDateinitial, "yyy-MM-dd"))

      }
      if (locationinitial && locationCurrent == "") {
        this.locationDestiArray[transportIndex][rowIndex] = locationinitial
      }

      if (tankinitial && tankCurrent == "") {
        this.defaultTankDesti[transportIndex][rowIndex] = tankinitial
      }
    }
  }

  onDestinationDescriptionChange(transportIndex: number, rowIndex: number) {
   
    const destinationArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
    const descriptionControl = destinationArray.at(rowIndex)?.get('value');
    const rdiControl = destinationArray.at(rowIndex)?.get('key');

    if (descriptionControl && rdiControl) {
      const selectedDescription = descriptionControl.value;
      const selectedRdiItem = this.keyvalueMap.find(item => item.value === selectedDescription);

      if (selectedRdiItem) {
        rdiControl.setValue(selectedRdiItem.key);
        this.destinationRDISelected[transportIndex][rowIndex] = selectedRdiItem.key;
      } else {
        rdiControl.setValue('');
        this.destinationRDISelected[transportIndex][rowIndex] = '';
      }
    }
  }

  //function for Ite tabls
  onRdiItetable(transportIndex: number, rowIndex: number) {
 
    const iteArray = this.transportArray().at(transportIndex).get('ite') as FormArray;
    const rdiControl = iteArray.at(rowIndex)?.get('rdiPort');
    const descriptionControl = iteArray.at(rowIndex)?.get('descPort');

    if (rdiControl && descriptionControl) {
      const selectedRdi = rdiControl.value;
      const selectedRdiItem = this.rdiDescTransport.find(item => item.rdiPort === selectedRdi);

      if (selectedRdiItem) {
        descriptionControl.setValue(selectedRdiItem.descPort);
        this.inTankRDIDescSelected[transportIndex][rowIndex] = selectedRdiItem.descPort;
      } else {
        descriptionControl.setValue('');
        this.inTankRDIDescSelected[transportIndex][rowIndex] = '';
      }
    }
  }

  onDescIteTable(transportIndex: number, rowIndex: number) {
 
    const iteArray = this.transportArray().at(transportIndex).get('ite') as FormArray;
    const rdiControl = iteArray.at(rowIndex)?.get('rdiPort');
    const descriptionControl = iteArray.at(rowIndex)?.get('descPort');

    if (descriptionControl && rdiControl) {
      const selectedDescription = descriptionControl.value;
      const selectedRdiItem = this.rdiDescTransport.find(item => item.descPort === selectedDescription);

      if (selectedRdiItem) {
        rdiControl.setValue(selectedRdiItem.rdiPort);
        this.inTankRDISelected[transportIndex][rowIndex] = selectedRdiItem.rdiPort;

      } else {
        rdiControl.setValue('');
        this.inTankRDISelected[transportIndex][rowIndex] = '';
      }
    }
  }

  createCargo(requestBodyArray: any, screenName: any = 'cargo') {
    this.loaderService?.setLoading(false);
    this.nominationService.createCargo(requestBodyArray).subscribe({
      next: (response) => {
        this.inValidDestinationLocation = false;
        this.invalidDestinationLocationsg = '';
        if (response.success) {
          if (screenName === 'cargo') {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: `New cargo plan was successfully created`,
            });
            const filterValues = this.nominationService?.cpFilters?.value;
            this.loaderService.setLoading(true);
            setTimeout(() => {
              this.loaderService.setLoading(false);
              this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterValues?.pipelineNomFilterValues)), pipelineFromDate: filterValues?.pipelineFromDate, pipelineToDate: filterValues?.pipelineToDate, isPipelineNomFilterUpdated: true }));
            }, 5000)
          } else {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: `Corresponding cargo plan has been created`,
            });
          }

        }
        this.closePopup();
        this.closeModalPopup.emit(response);
      },
      error: (err:any) => {
        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err;
        if (screenName !== 'cargo') {
          this.closePopup();
          let response = {
            success:true
          }
          this.closeModalPopup.emit(response);
        }
          this.toasterService.setToastNotification({
            show: true,
            type: 'error',
            msg: err.message,
          });
      },
      complete: () => {
      },
    });
  }

  createNominationSplitUpdatePublishedClick(response: any) {
    if(this.isUpdatePublishClicked){
      this.nominationService?.isTriggeredFromNomModalPopup$?.next(true);
    }
    this.isUpdatePublishClicked=false;
  }

  createNomination(requestBodyArray: any) {
    this.loaderService?.setLoading(false);
    this.nominationService.createPipelineNomination(requestBodyArray).subscribe({
      next: (response) => {
        this.inValidDestinationLocation = false;
        this.invalidDestinationLocationsg = '';
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: this.isUpdatePublishClicked?`Nomination is being sent to TSW`:`nomination has been created`
          });
          this.createNominationSplitUpdatePublishedClick(response);
          
          if(this.isUpdatePublishClicked){
            this.nominationService?.isTriggeredFromNomModalPopup$?.next(true);
          }
          this.isUpdatePublishClicked=false;
          this.handleCpNomination(response)        
        }
        this.cpService?.createNominationFromCargoWithReference?.next({ initiate: false, data: {} });
      },
      error: (err) => {
        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err;
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message,
        });
      },
      complete: () => {
        this.selectDropdown?.resetInput();
      },
    });
 }
 
  handleCpNomination(response:any){
    // if (this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields) && !this.cpService?.createNominationFromCargoWithReference?.value?.initiate) {
    //   this.formCpPayload(response?.result[0], 'nomination');          
    // }else if(this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
    //   this.cargoId = '';
    //   let payload = 
    //   { id: this.getNomData?.id,
    //   nomRefId: response?.result[0].Id,
    //   sys_modifiedBy:`${this.userDetails?.toLowerCase()}@chevron.com`
    //   }
    
    //   this.cpService.updateLinkNominationWithCargo([payload]).subscribe(res => {
    //     if (res?.success) {
    //       const filterValues = this.nominationService?.cpFilters?.value;
    //       this.loaderService?.setLoading(true);
    //       setTimeout(() => {
    //         this.loaderService?.setLoading(false);
    //         this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterValues?.pipelineNomFilterValues)), pipelineFromDate: filterValues?.pipelineFromDate, pipelineToDate: filterValues?.pipelineToDate, isPipelineNomFilterUpdated: true }));
    //       }, 5000)
    //     }
    //   })
    //   this.closePopup()
    //   this.closeModalPopup.emit(response);
    // }else{
      this.closePopup()
      this.closeModalPopup.emit(response);
    // }
 }
  setList(list: any, item: any) {
    list.itemId = null;
    list.udmItemStatus = Nom.INPROCESS;
    list.scheduledDate = item.scheduledDate.replaceAll('-', '');
    list.scheduledQty = item.scheduledQty;
    list.locationId = item.location;
    list.udmTankCode = item.tankCode?.length <= 0 ? '' : item.tankCode;
    list.scheduledUom = 'MB'
    list.scheduledMaterial = this.getDemandMaterialID(item.scheduledProduct);
    list.demandMaterial = this.getDemandMaterialID(item.demandProduct);
    list.textLine = `{Author:${this.userDetails},MdfBy:${this.userDetails},SS:UDM}`;
    list.contractPartner = null;
    list.udmDisableValidations = true;
    list.confirmationIndicator = this.utilities.getConfirmationIndicatorValue(item,this.isPipelineNomEdit);
    
    list.nominationReferenceDocument = item?.sapcontract // set it as per multiple or single selected
    list.nominationReferenceDocumentItem = typeof item?.contractLineNo === 'string' && item?.contractLineNo.includes("|")? item?.contractLineNo.split('|')[0].split(' ')[0]: item?.contractLineNo;
    list.batchOriginLocation = item?.batchOriginLocation;
    list.valuationTypeOriginLoc = item?.valuationTypeOriginLoc;
    list.valuationTypeDestinationLoc = item?.valuationTypeDestinationLoc;
    list.batchDestinationLocation = item?.batchDestinationLocation;
    list.udmDesk = this.deskNumber;
    // if(this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
    //   list.udmEndLoadDate = item?.udmEndLoadDate;
    // }
    // if(this.isCargoDesks){
    //   list.scheduledMaterialDesc = item.scheduledProduct;
    //   list.demandMaterialDesc = item.demandProduct;
    //   list.locationName = item.location;
      // list.partnerNominationNumber = item?.partnerNominationNumber;
    // }
    // if (this.inCpNomTab) {list.contractPartner = item?.counterParty === "" ? " " : item?.counterParty};
      return list
  }

  getLocationName(locationId:any){
    let location = this.masterTerminalListForCp.find((element: any) => element.terminalCode === locationId);
    return location?.terminalName;
  }

  getDemandMaterialID(demandProduct: any) {
    let product = this.masterProductList.find((element: any) => element.productName === demandProduct);
    return product?.productCode;
  }
  getDemandMaterialName(demandProduct: any) {
    let product:any;
    if(this.inCpTab){
     product =  this.masterProductListForCp.find((element: any) => element.productCode === demandProduct);
    }
    else{
      product = this.masterProductList.find((element: any) => element.productCode === demandProduct);
    }
    return product?.productName;
  }
  submitUpdatePublish(){
    this.originCount = 0;
    this.destinationCount = 0;
    this.originClickCount = 0;
    this.destinationClickCount = 0;
    this.isUpdatePublishClicked = true;
    this.submitForm();
  }
  submitForm() {
    this.autoAdjustmentInitialDates = [];
    this.originCount = 0;
    this.destinationCount = 0;
    this.originClickCount = 0;
    this.destinationClickCount = 0;
    if ((this.trNominationForPipeline?.screenName?.toLowerCase() === 'side-panel-tr' && (this.trNominationForPipeline?.sys_status?.toLowerCase() === Nom.DRAFT || this.trNominationForPipeline?.editNomination))) {
      this.submitDraftNominationFromPipeline()
      this.myMonitoringService.logEvent('Updated refinery Nomination', {category:this.trNominationForPipeline?.screenName?.toLowerCase() === 'side-panel-tr' ? "Replenishment Planning" : "Nomination"})
    }
   else if(this.trNominationForPipeline?.editNomination && this.isSearchPopup){
    this.submitNomiFromPipeline()
    this.myMonitoringService.logEvent('Updated refinery Nomination', {category:this.trNominationForPipeline?.screenName?.toLowerCase() === 'side-panel-tr' ? "Replenishment Planning" : "Nomination"})
 
   }
    else {
      this.onFormSubmit()
      this.myMonitoringService.logEvent('Created refinery Nomination', {category: this.trNominationForPipeline?.screenName?.toLowerCase() === 'side-panel-tr'? "Replenishment Planning" : "Nomination"})
    }
  }
  idVal(){
    let id;
    if (this.nominationData?.id) {
      id = this.nominationData?.id;
    } else if (this.referenceNominations?.id) {
      id = this.referenceNominations?.id;
    } else {
      id = null
    }
    return id
}

  getVesselInfo(vesselName:string){
    let vessel = this.masterVesselArray?.find((element: any) => element.vesselName === vesselName);
    return vessel;
  }


  // formCpPayload(data?: any, screen: string = 'cargo') {  
  //   this.nominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));
  //   let arr: any = []
  //   let vesselData = this.getVesselInfo(this.nominationData.vesselName);

  //   this.nominationData.transport.forEach((el: any) => {
  //     let ele: any = {
  //       ...(this.inCpNomTab && { nomRefId: data.Id }),
  //       status: this.inCpTab ? "pendingNomination" : 'unpublished',
  //       nominationNo: this.nominationData?.nominationNo?.toUpperCase(),
  //       vesselName: vesselData?.vesselName,
  //       vesselId: vesselData?.vesselId,
  //       tdVehNo: vesselData?.vesselId,
  //       vesselDesc: vesselData?.vesselName,
  //       modeOfTransport: "S",
  //       tdVehIdentifier: this.scenario.toLowerCase() === 'barge' ? "B" : "S",
  //       loadLaycanStartDate: "",
  //       deliveryLaycanStartDate: "",
  //       mot: this.scenario?.charAt(0).toUpperCase() + this.scenario.slice(1),
  //       tug: this.nominationData?.tug,
  //       transportSystem: this.nominationData.transportSystem ?? el.transportSystemMode,
  //       sys_createdBy: this.nominationService.userDetails$.value._email,
  //       cargoSchedules: []
  //     }
  //     let originlength = 0;
  //     el.origin.forEach((item: any, i: number) => {
  //       let list: any = {}
        
  //       if (item.rdi) { // check if origin has value then only add
  //         originlength = originlength + 1;
  //         list.referenceDocumentIndicator = item.rdi
  //         list.scheduleType = "O";
  //         list.locationId = item.location;
  //         list.endLoadDate = item.endLoadDate === "" ? null : item.endLoadDate;
  //         list.locationName = this.getLocationName(item.location);
  //         // list.contractPartner = item.counterParty === "" ? " " : item.counterParty;
  //         list.nominationReferenceDocument = item.sapcontract;
  //         list.scheduledDate = item.scheduledDate;
  //         list.nominationReferenceDocumentItem = (typeof(item.contractLineNo) === 'number')?JSON.stringify(item.contractLineNo):typeof item?.contractLineNo === 'string' && item?.contractLineNo.includes("|")? item?.contractLineNo.split('|')[0].split(' ')[0]: item?.contractLineNo;
  //         list.deletionIndicator = 0;
  //         list.products = [
  //           {
  //             ...(this.inCpNomTab && {nomRefItemId : data.headerToItemNav[i].itemId}),
  //             demandMaterial: this.getDemandMaterialID(item.demandProduct),
  //             demandMaterialDesc: this.getDemandMaterialName(this.getDemandMaterialID(item.demandProduct)),
  //             scheduledMaterial: this.getDemandMaterialID(item.scheduledProduct),
  //             scheduledMaterialDesc: this.getDemandMaterialName(this.getDemandMaterialID(item.scheduledProduct)),
  //             scheduledQty: item.scheduledQty,
  //             productDeletionIndicator: 0
  //           }
  //         ]
  //         ele.cargoSchedules.push(list)
  //       }
  //     })

  //     el.destination.forEach((item: any, i:number) => {
  //       let list: any = {}
  //       if (item.key) { 
  //         list.referenceDocumentIndicator = item.key;
  //         list.scheduleType = "D";
  //         list.locationId = item.location;
  //         list.locationName = this.getLocationName(item.location);
  //         list.contractPartner = item.counterParty === "" ? " " : item.counterParty;
  //         list.endLoadDate = item.endLoadDate === "" ? null : item.endLoadDate;
  //         list.nominationReferenceDocument = item.sapcontract;
  //         list.scheduledDate = item.scheduledDate;
  //         list.nominationReferenceDocumentItem = (typeof(item.contractLineNo) === 'number')?JSON.stringify(item.contractLineNo):typeof item?.contractLineNo === 'string' && item?.contractLineNo.includes("|")? item?.contractLineNo.split('|')[0].split(' ')[0]: item?.contractLineNo;
  //         list.deletionIndicator = 0;
  //         list.products = [
  //           {
  //             ...(this.inCpNomTab && {nomRefItemId : data.headerToItemNav[i + originlength].itemId}),
  //             demandMaterial: this.getDemandMaterialID(item.demandProduct),
  //             demandMaterialDesc:this.getDemandMaterialName(this.getDemandMaterialID(item.demandProduct)),
  //             scheduledMaterial: this.getDemandMaterialID(item.scheduledProduct),
  //             scheduledMaterialDesc: this.getDemandMaterialName(this.getDemandMaterialID(item.scheduledProduct)),
  //             scheduledQty: item.scheduledQty,
  //             productDeletionIndicator: 0
  //           }
  //         ]
  //         ele.cargoSchedules.push(list)
  //       }
  //     })

  //     arr.push(ele)
  //   });
  //   let [startDate, endDate] = this.getLaycanStartandEndDates(arr);
  //   arr[0].loadLaycanStartDate = startDate ? startDate : null;  
  //   arr[0].deliveryLaycanStartDate = endDate ? endDate : null;  

  //   arr.forEach((eachCargo: any) => {
  //     this.checkForDuplicateAndModify(eachCargo);
  //   })
  //   this.createCargo(arr, screen);
  // }

  checkForDuplicateAndModify(arr:any){
    this.mergeMatchingProducts(arr,'O');
    this.mergeMatchingProducts(arr,'D');
    
  }

   mergeMatchingProducts(data:any, scheduleType:any) {  
    for (let i = 0; i < data.cargoSchedules.length; i++) {
        const currentSchedule = data.cargoSchedules[i];

        for (let j = i + 1; j < data.cargoSchedules.length; j++) {
            const otherSchedule = data.cargoSchedules[j];

            let curr = JSON.stringify(currentSchedule);
            let other = JSON.stringify(otherSchedule);

            if ( (currentSchedule.scheduleType === scheduleType && otherSchedule.scheduleType === scheduleType && curr === other) || (currentSchedule.scheduleType === scheduleType && otherSchedule.scheduleType === scheduleType && currentSchedule.referenceDocumentIndicator === otherSchedule.referenceDocumentIndicator && currentSchedule.locationId === otherSchedule.locationId && currentSchedule.counterParty === otherSchedule.counterParty && currentSchedule.scheduledDate === otherSchedule.scheduledDate &&  currentSchedule.endLoadDate === otherSchedule.endLoadDate && currentSchedule.contractNo === otherSchedule.contractNo && currentSchedule.products[0].demandMaterial === otherSchedule.products[0].demandMaterial && currentSchedule.products[0].scheduledQty !== otherSchedule.products[0].scheduledQty) ) {
                 currentSchedule.products[0].scheduledQty  = Number( currentSchedule.products[0].scheduledQty)  + Number( otherSchedule.products[0].scheduledQty);
                 currentSchedule.products[0].nomRefItemId  =  currentSchedule.products[0].nomRefItemId +';'+ otherSchedule.products[0].nomRefItemId;
                 // Remove the matching schedule from the array
               data.cargoSchedules.splice(j, 1);

               // Decrement j to account for the removed element
               j--;  
            }
            else if((currentSchedule.scheduleType === scheduleType && otherSchedule.scheduleType === scheduleType && currentSchedule.referenceDocumentIndicator === otherSchedule.referenceDocumentIndicator && currentSchedule.locationId === otherSchedule.locationId && currentSchedule.contractPartner === otherSchedule.contractPartner && 
              currentSchedule.nominationReferenceDocumentItem === otherSchedule.nominationReferenceDocumentItem && currentSchedule.scheduledDate === otherSchedule.scheduledDate &&  currentSchedule.endLoadDate === otherSchedule.endLoadDate && currentSchedule.nominationReferenceDocument === otherSchedule.nominationReferenceDocument) && (currentSchedule.products[0].demandMaterial !== otherSchedule.products[0].demandMaterial)) {
             // Merge products from the matching schedule into the current schedule
             currentSchedule.products = currentSchedule.products.concat(otherSchedule.products);
             // Remove the matching schedule from the array
             data.cargoSchedules.splice(j, 1);
             // Decrement j to account for the removed element
             j--; //NOSONAR
             
            } 
            }
        }
    return data;
}

getLaycanStartandEndDates(arr:any){
  let originDates  = arr[0].cargoSchedules.filter((arr: any) => {return arr.scheduleType === "O"}).map((ele: { scheduledDate: any; }) => ele.scheduledDate).sort();
  let destDates =  arr[0].cargoSchedules.filter((arr: any) => {return arr.scheduleType === "D"}).map((ele: { scheduledDate: any; }) => ele.scheduledDate).sort();

   return [originDates[0], destDates[0]];
  

  
}
callTransportCheck(el: any){
  return this.nominationData.transportSystem ? this.nominationData.transportSystem : el.transportSystemMode
}
 checkStatus(){
  return this.isUpdatePublishClicked ? 'InProcess' : 'unpublished'
 }
 callTdVehNo(vesselData: any){
  return (this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields))?vesselData?.vesselId:""
 }
deskParamsCreated(id: any, modeoftransport: any,modeOfTransportDesc: any, el: any,vesselData: any, updateTDVehNo: any){
 const deskTypeValue = this.getDeskTypeValue(); 

 let ele = {
    "id": id,
    "modeOfTransport": modeoftransport, // pl modeOfTransportCode
    "modeOfTransportDesc": modeOfTransportDesc, // set it to pipeline based on transport modeOfTransportdesc
    "transportSystem": this.callTransportCheck(el),
    "nominationNo": this.nominationData?.nominationNo?.toUpperCase(),
    "textLine":this.nominationData?.nominationNotes,
    "btCustodyChain": this.nominationData?.btCustodyChain,
    "deskFilter": this.selectedDeskParameter,
    "tdVehNo": this.callTdVehNo(vesselData),
    "tdVehIdentifier":this.inCpNomTab && this.nomCargoFieldObj.bargeFields? "B": updateTDVehNo, 
    "udmModeoftransportDesc": this.inCpNomTab && this.scenario.toLowerCase() === 'barge' ? "Barge" : modeOfTransportDesc,
    "sys_status": this.checkStatus(),
    "cycle": this.nominationData?.cycleId,
    "udmDesk":this.deskNumber,
    "headerToItemNav": [],
    "deskType": deskTypeValue|| null,
    // "udmNominationGrouping": this.nominationData.selectMultipleCheckbox?'multiple': '',
    // ...(this.inCpNomTab && this.nomCargoFieldObj.bargeFields && {"udmTug" : this.nominationData?.tug}),
    // ...((this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields)) &&{"vehicleName": this.nominationData?.vesselName}),
    // ...((this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields)) &&{"vehicleCode": vesselData?.vesselId}),
    // ...((this.inCpNomTab) &&{"partnerNominationNumber": this.nominationData?.partnerNominationNumber}),
    // ...((this.inCpNomTab) && this.cpService?.createNominationFromCargoWithReference?.value?.initiate &&{"cargoId": this.cargoId}),
    
  }
   return ele;
}
  onFormSubmit() {
    this.deskNumber = this.deskNumber? this.deskNumber: this.selectedDeskId;
    // if(this.inCpTab && !this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
    //   this.formCpPayload();
    // }
    // else{

    const transportSystemControl = this.createPipelineNominationForm.get('transportSystem');
    let arr: any = []
    this.nominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));
    this.nominationData.transport.forEach((el: any) => {  
      let id = this.idVal()
      let modeoftransport:any, modeOfTransportDesc:any;
      if(this.inCpNomTab){
        let updateMotValue = this.scenario.toLowerCase() === 'barge' ? "vessel" : this.scenario
        let setMotVal = (this.scenario.toLowerCase() === 'intank')?'pipeline': updateMotValue;
        modeoftransport = this.transportDescArray.find((e: any) => (e?.modeOfTransportDesc?.toLowerCase() === setMotVal && e.transportSystemCode === this.nominationData.transportSystem))?.modeOfTransportCode;
        modeOfTransportDesc = this.transportDescArray.find((e: any) =>(e?.modeOfTransportDesc?.toLowerCase() === setMotVal &&  e.transportSystemCode === this.nominationData.transportSystem))?.modeOfTransportDesc;
      }else{
        modeoftransport = el.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === el.transportSystemMode)?.modeOfTransportCode : this.transportDescArray.find((e: any) => e.transportSystemCode === this.nominationData.transportSystem)?.modeOfTransportCode
        modeOfTransportDesc = el.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === el.transportSystemMode).modeOfTransportDesc : this.transportDescArray.find((e: any) => e.transportSystemCode === this.nominationData.transportSystem)?.modeOfTransportDesc
      }
      
      let list: any = {}
      let vesselData = this.getVesselInfo(this.nominationData.vesselName);
      let updateTDVehNo = this.inCpNomTab &&  this.nomCargoFieldObj.vesselFields ? "S" : "";
      let ele: any = this.deskParamsCreated(id, modeoftransport,modeOfTransportDesc, el,vesselData, updateTDVehNo);
      const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
      if ((transportSystemControl?.value === this.intankEast || transportSystemControl?.value === this.intankWest) && isITNomSectionRequired) {
        el.ite.forEach((item: any) => {
          list.referenceDocumentIndicator = item.rdiPort
          list.scheduleType = 'IT';
          this.setList(list, item)
          let cloneObject = { ...list };
          ele.headerToItemNav.push(cloneObject)
        })
      }
      else {
        const transportSystemControl = this.createPipelineNominationForm.get('transportSystem')?.value;
        const fetchLocationsForTransportSystem = this.locationTransportSystemMasterData?.find((val: any) => {
          return val.transportSystem === transportSystemControl
        })?.locationPlantCode;

        el.origin.forEach((item: any) => {
          if (item.rdi) { // check if origin has value then only add
            
            const fetchLocationSelectedObjectFromMasterData = fetchLocationsForTransportSystem?.find((val: any) => {
              return val.locationCode === item.location
            });
            list.referenceDocumentIndicator = item.rdi;
            list.scheduleType = 'O';
            list.locationPartnerPlant = fetchLocationSelectedObjectFromMasterData?.plantCode;
            // this.utilities.globalPipelineAggregatorResponseForDropdown?.deskLocations?.find((i:any)=> i.locationCode === item.location)?.plantCode;
            this.setList(list, item)
            let cloneObject = { ...list };
            ele.headerToItemNav.push(cloneObject)
          }
        })
        
        el.destination.forEach((item: any) => {
          if (item.key) { // check if desti has value then only add
            const fetchLocationSelectedObjectFromMasterData = fetchLocationsForTransportSystem?.find((val: any) => {
              return val.locationCode === item.location
            });
            list.referenceDocumentIndicator = item.key
            list.scheduleType = 'D';
            list.locationPartnerPlant = fetchLocationSelectedObjectFromMasterData?.plantCode;
            // this.utilities.globalPipelineAggregatorResponseForDropdown?.deskLocations?.find((i:any)=> i.locationCode === item.location)?.plantCode;
            this.setList(list, item)
            let cloneObject = { ...list };
            ele.headerToItemNav.push(cloneObject)
          }
        })
      }
      arr.push(ele);
    });
    const requestBodyArray = arr;
    this.createNomination(requestBodyArray);
    
    // if(this.createPipelineNominationForm.controls['partnerNominationNumber']){
    //   this.createPipelineNominationForm.removeControl('partnerNominationNumber');
    // }
  // }
  }
  resetModal() {
    this.createPipelineNominationForm.reset({
      nominationNo: '',
      transportSystem: '',
      scheduledProduct: '',
      selectMultipleCheckbox: '',
      selectMultipleCheckbox1: '',
      nominationNotes:'',
      btCustodyChain: '',
      cycleId: '',
      isoriginDestiTouched: '',
      isTransportpicked: ''


    });
    this.createPipelineNominationForm.get('isTransportpicked')?.setErrors({ 'invalid': true });

  }
  getIdForCorrespondingName(value: any, type: any) {
    if (type === 'location') {
      return this.locationKeyValue?.filter((ele: any) => ele.name === value).map((item: any) => item.id);
    } else if (type === 'demand material') {
      return this.demandKeyValue?.filter((ele: any) => ele.name === value).map((item: any) => item.id);
    }
  }

  fetchId(el:any){
    let id =null;
    if(el?.origin || el?.destination ){
      if(el?.origin[0]?.id && typeof(el?.origin[0]?.id)!=='number'){
        id = el?.origin[0]?.id;
      }
      if(el?.destination[0]?.id && typeof(el?.destination[0]?.id)!=='number'){
        id = el?.destination[0]?.id;
      }
      // if(!el?.destination[0]?.id && !el?.origin[0]?.id && this.isCargoDesks){
      //   id=this.trNominationForPipeline?.id;
      // }
    }else{
      id=this.trNominationForPipeline?.id;
    }
    if(el?.ite || el?.itw || el?.transportSystemMode){
      id=this.trNominationForPipeline?.headerToItemNav[0][0].id;
    }
   return id;
  }
  submitNomiFromPipeline(){  //NOSONAR lightweight logging
      this.deskNumber = this.deskNumber? this.deskNumber: this.selectedDeskId;
      this.nominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));
      const transportSystemControl = this.createPipelineNominationForm.get('transportSystem');
      let arr: any = []
      const currentNominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));
      this.deskNumber = this.deskNumber? this.deskNumber: Object.keys(nomPopSpecific).find(key => nomPopSpecific[key] === this.selectedDeskParameter)
  
      currentNominationData.transport.forEach((el: any) => {
  
        let modeoftransport:any, modeOfTransportDesc:any;
        if(this.inCpNomTab){
          let setMotVal = (this.scenario.toLowerCase() === 'intank')?'pipeline':this.scenario;
          setMotVal = (setMotVal.toLowerCase() === 'barge') ? "vessel" : setMotVal;
          modeoftransport = this.transportDescArray.find((e: any) => (e.modeOfTransportDesc?.toLowerCase() === setMotVal && e.transportSystemCode === this.nominationData.transportSystem))?.modeOfTransportCode;
          modeOfTransportDesc = (this.scenario.toLowerCase() === 'barge') ? "Barge" : this.transportDescArray.find((e: any) =>(e.modeOfTransportDesc?.toLowerCase() === setMotVal &&  e.transportSystemCode === this.nominationData.transportSystem)).modeOfTransportDesc;
        }else{
          modeoftransport = el.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === el.transportSystemMode)?.modeOfTransportCode : this.transportDescArray.find((e: any) => e.transportSystemCode === this.nominationData.transportSystem)?.modeOfTransportCode
          modeOfTransportDesc = el.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === el.transportSystemMode).modeOfTransportDesc : this.transportDescArray.find((e: any) => e.transportSystemCode === this.nominationData.transportSystem)?.modeOfTransportDesc
        }
  
        let vesselData = this.getVesselInfo(this.nominationData.vesselName);
        const getId = this.fetchId(el);
        let updateTDVehNo = modeOfTransportDesc?.toLowerCase() === 'vessel' ? "S" : null;
        let list: any = {}
        let ele: any = {
          "id": getId,
          "modeOfTransport": modeoftransport,
          "modeOfTransportDesc": modeOfTransportDesc,
          "transportSystem": currentNominationData?.transportSystem ? currentNominationData?.transportSystem : el?.transportSystemMode,
          "nominationNo": currentNominationData?.nominationNo?.toUpperCase(),
          "textLine":currentNominationData?.nominationNotes,
          "btCustodyChain": currentNominationData?.btCustodyChain,
          "deskFilter": this.selectedDeskParameter,
          // "tdVehNo": (this.isCargoDesks && currentNominationData?.vesselName)?vesselData?.vesselId:"",
          "udmModeoftransportDesc": modeOfTransportDesc,
          "sys_status":this.isUpdatePublishClicked ? 'InProcess' : 'unpublished',
          "udmDesk":this.deskNumber,
          "cycle": currentNominationData?.cycleId,
          "headerToItemNav": [],
          "nominationScenario": "createnomination",
          "udmNominationGrouping": currentNominationData.selectMultipleCheckbox?'multiple': '',
          // ...(this.isCargoDesks && { "tdVehIdentifier": (modeOfTransportDesc?.toLowerCase() === 'barge')? "B": updateTDVehNo }),
          // ...(this.isCargoDesks && { "udmPublishEvent": true }),
          // ...(currentNominationData?.tug && { "udmTug": currentNominationData.tug }),
          ...(currentNominationData?.vesselName && { vehicleName: currentNominationData.vesselName }),
          ...(currentNominationData?.vesselName && { vehicleCode: vesselData?.vesselId }),
        }
        
        if (transportSystemControl?.value === this.intankEast || transportSystemControl?.value === this.intankWest) {
          el.ite.forEach((item: any) => {
            list.referenceDocumentIndicator = item.rdiPort
            list.scheduleType = 'IT';
            this.setList(list, item)
            list.itemId = item.itemId
            this.intankItemId = item.itemId;
            let cloneObject = { ...list };
            ele.headerToItemNav.push(cloneObject)
          })
        }
        else {
  
          el.origin.forEach((item: any) => {
            if (item.rdi) { // check if origin has value then only add
              list.referenceDocumentIndicator = item.rdi
              list.scheduleType = 'O';
              list.locationPartnerPlant = this.utilities.globalPipelineAggregatorResponseForDropdown?.deskLocations?.find((i:any)=> i.locationCode === item.location).plantCode;
              this.setList(list, item)
              list.udmItemStatus = Nom.INPROCESS
              list.itemId = item.itemId;
              let cloneObject = { ...list };
              ele.headerToItemNav.push(cloneObject)
            }
          })
  
          el.destination.forEach((item: any) => {
            if (item.key) { // check if destination has value then only add
              list.referenceDocumentIndicator = item.key
              list.scheduleType = 'D';
              list.locationPartnerPlant = this.utilities.globalPipelineAggregatorResponseForDropdown?.deskLocations?.find((i:any)=> i.locationCode === item.location)?.plantCode;
              this.setList(list, item)
              list.udmItemStatus = Nom.INPROCESS
              list.itemId = item.itemId;
              let cloneObject = { ...list };
              ele.headerToItemNav.push(cloneObject)
            }
          })
        }
  
        arr.push(ele)
      });
  
      //If we delete entire Transport system
      this.updatePayloadForTransportSysDeleted(arr);
       //If we delete individual line item(origin row or destination row)
      this.updatePayloadForLineItemDeleted(arr);
      // For handling ITE and ITW Transport system nom
      if(arr.some((ts:any)=>(ts.transportSystem === 'ITE' || ts.transportSystem === 'ITW'))){
        const findDeletedItem = arr[0]?.headerToItemNav.find((e:any)=>e.deletionIndicator === 'X');
        const findDeletedItemIndex = arr[0]?.headerToItemNav.findIndex((e:any)=>e.deletionIndicator === 'X');
        if(findDeletedItem && findDeletedItemIndex){
          arr[0].headerToItemNav[0].itemId = findDeletedItem.itemId;
          arr[0]?.headerToItemNav.splice(findDeletedItemIndex,1);
        }
      }
      const requestBodyArray = arr;
      this.updateAPI(requestBodyArray);
      
  }
  deskParamForDraft(getId: any, modeoftransport: any,modeOfTransportDesc: any, currentNominationData: any,el: any,vesselData: any, updateTDVehNo: any){
    const deskTypeValue = this.getDeskTypeValue(); 
   let ele = {
      "id": getId,
      "modeOfTransport": modeoftransport,
      "modeOfTransportDesc": modeOfTransportDesc,
      "transportSystem": currentNominationData?.transportSystem ? currentNominationData?.transportSystem : el?.transportSystemMode,
      "nominationNo": currentNominationData?.nominationNo?.toUpperCase(),
      "textLine":currentNominationData?.nominationNotes,
      "btCustodyChain": currentNominationData?.btCustodyChain,
      "deskFilter": this.trNominationForPipeline?.editNomination ? 'refinerydesk' : this.selectedDeskParameter,
      // "tdVehNo": (this.isCargoDesks&& currentNominationData?.vesselName)?vesselData?.vesselId:"",
      "udmModeoftransportDesc": this.inCpNomTab && this.scenario.toLowerCase() === 'barge' ? "Barge" : modeOfTransportDesc,
      "sys_status":this.isUpdatePublishClicked ? 'InProcess' : 'unpublished',
      "udmDesk":this.deskNumber,
      "cycle": currentNominationData?.cycleId,
      "deskType": deskTypeValue|| null,
      "headerToItemNav": [],
      "nominationScenario": "createnomination",
      "udmNominationGrouping": currentNominationData.selectMultipleCheckbox?'multiple': '',
      // ...(this.isCargoDesks && { "tdVehIdentifier": (modeOfTransportDesc?.toLowerCase() === 'barge')? "B": updateTDVehNo }),
      // ...(this.isCargoDesks && { "udmPublishEvent": true }),
      // ...(currentNominationData?.tug && { "udmTug": currentNominationData.tug }),
      ...(currentNominationData?.vesselName && { vehicleName: currentNominationData.vesselName }),
      ...(currentNominationData?.vesselName && { vehicleCode: vesselData?.vesselId }),
    }
     return ele
  }
  submitDraftNominationFromPipeline() {
    this.deskNumber = this.deskNumber? this.deskNumber: this.selectedDeskId;
    this.nominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));
    const transportSystemControl = this.createPipelineNominationForm.get('transportSystem');
    let arr: any = []
    const currentNominationData = JSON.parse(JSON.stringify(this.createPipelineNominationForm.value));
    this.deskNumber = this.deskNumber? this.deskNumber: Object.keys(nomPopSpecific).find(key => nomPopSpecific[key] === this.selectedDeskParameter)

    currentNominationData.transport.forEach((el: any) => {

      let modeoftransport:any, modeOfTransportDesc:any;
      if(this.inCpNomTab){
        let setMotVal = (this.scenario.toLowerCase() === 'intank')?'pipeline':this.scenario;
        setMotVal = (setMotVal.toLowerCase() === 'barge') ? "vessel" : setMotVal;
        modeoftransport = this.transportDescArray.find((e: any) => (e.modeOfTransportDesc?.toLowerCase() === setMotVal && e.transportSystemCode === this.nominationData.transportSystem))?.modeOfTransportCode;
        modeOfTransportDesc = (this.scenario.toLowerCase() === 'barge') ? "Barge" : this.transportDescArray.find((e: any) =>(e.modeOfTransportDesc?.toLowerCase() === setMotVal &&  e.transportSystemCode === this.nominationData.transportSystem)).modeOfTransportDesc;
      }else{
        modeoftransport = el.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === el.transportSystemMode)?.modeOfTransportCode : this.transportDescArray.find((e: any) => e.transportSystemCode === this.nominationData.transportSystem)?.modeOfTransportCode
        modeOfTransportDesc = el.transportSystemMode ? this.transportDescArray.find((e: any) => e.transportSystemCode === el.transportSystemMode).modeOfTransportDesc : this.transportDescArray.find((e: any) => e.transportSystemCode === this.nominationData.transportSystem)?.modeOfTransportDesc
      }

      let vesselData = this.getVesselInfo(this.nominationData.vesselName);
      const getId = this.fetchId(el);
      let updateTDVehNo = modeOfTransportDesc?.toLowerCase() === 'vessel' ? "S" : null;
      let list: any = {}
      let ele: any = this.deskParamForDraft(getId, modeoftransport,modeOfTransportDesc, currentNominationData,el,vesselData, updateTDVehNo)
      const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
      if ((transportSystemControl?.value === this.intankEast || transportSystemControl?.value === this.intankWest) && isITNomSectionRequired) {
        el.ite.forEach((item: any) => {
          list.referenceDocumentIndicator = item.rdiPort
          list.scheduleType = 'IT';
          this.setList(list, item)
          list.itemId = item.itemId
          this.intankItemId = item.itemId;
          let cloneObject = { ...list };
          ele.headerToItemNav.push(cloneObject)
        })
      }
      else {
        const fetchLocationsForTransportSystem = this.locationTransportSystemMasterData?.find((val: any) => {
          return val.transportSystem === transportSystemControl?.value
        })?.locationPlantCode;

        el.origin.forEach((item: any) => {
          if (item.rdi || item.itemId) { // check if origin has value then only add
            const fetchLocationSelectedObjectFromMasterData = fetchLocationsForTransportSystem?.find((val: any) => {
              return val.locationCode === item.location
            });
            list.referenceDocumentIndicator = item.rdi;
            list.scheduleType = 'O';
            list.locationPartnerPlant = fetchLocationSelectedObjectFromMasterData?.plantCode;
            if(!item.rdi)
              list.deletionIndicator = "X";
            else
              list.deletionIndicator = null;
            this.setList(list, item);
            list.udmItemStatus = 'unpublished';
            list.itemId = item.itemId;
            let cloneObject = { ...list };
            ele.headerToItemNav.push(cloneObject)
          }
        })

        el.destination.forEach((item: any) => {
          if (item.key || item.itemId) { // check if destination has value then only add
            const fetchLocationSelectedObjectFromMasterData = fetchLocationsForTransportSystem?.find((val: any) => {
              return val.locationCode === item.location
            });
            if(!item.key)
              list.deletionIndicator = "X";
            else
              list.deletionIndicator = null;
            list.referenceDocumentIndicator = item.key
            list.scheduleType = 'D';
            list.locationPartnerPlant = fetchLocationSelectedObjectFromMasterData?.plantCode;
            this.setList(list, item);
            list.udmItemStatus = 'unpublished';
            list.itemId = item.itemId;
            let cloneObject = { ...list };
            ele.headerToItemNav.push(cloneObject)
          }
        })
      }

      arr.push(ele)
    });

    //If we delete entire Transport system
    this.updatePayloadForTransportSysDeleted(arr);
     //If we delete individual line item(origin row or destination row)
    this.updatePayloadForLineItemDeleted(arr);
    // For handling ITE and ITW Transport system nom
    if(arr.some((ts:any)=>(ts.transportSystem === 'ITE' || ts.transportSystem === 'ITW'))){
      const findDeletedItem = arr[0]?.headerToItemNav.find((e:any)=>e.deletionIndicator === 'X');
      const findDeletedItemIndex = arr[0]?.headerToItemNav.findIndex((e:any)=>e.deletionIndicator === 'X');
      if(findDeletedItem && findDeletedItemIndex){
        arr[0].headerToItemNav[0].itemId = findDeletedItem.itemId;
        arr[0]?.headerToItemNav.splice(findDeletedItemIndex,1);
      }
    }
    const requestBodyArray = arr;
    this.updateAPI(requestBodyArray);
    
  }
 
  updatePayloadForLineItemDeleted(arr:any){
    if (this.deletedValue) {
      arr.forEach((a:any)=>{
        this.deletelist.forEach((e: any) => {
          if(e.parentId === a.id){
            delete e.parentId;
            a.headerToItemNav.push(e);
          }
          this.deletedValue = false
        })
      })
    }
  }

  updatePayloadForTransportSysDeleted(arr:any){
    if(this.trNominationForPipeline.editNomination && this.delEleArr.length){
      this.delEleArr.forEach((ele:any)=>{
        arr.push(ele)
      })
    }
  }

  updateAPI(requestBodyArray: any) {
    this.nominationService.updateNomination(requestBodyArray).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: this.isUpdatePublishClicked?`Nomination is being sent to TSW`:`Nomination has been updated`
          });
        }
        if(this.isUpdatePublishClicked){
          this.nominationService?.isTriggeredFromNomModalPopup$?.next(true);
        }
        this.isUpdatePublishClicked=false;
        this.resetModal();
        this.deletelist = []
        this.closePopup()

        this.closeModalPopup.emit(response);
        
      },
      error: (err) => {
        this.inValidLocation = true;
        this.inValidLocationMsg = err;
      }
    });
  }

  isItemVal(item: any) {

    if(this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
      if (item.headerToItemNav[0].scheduleType === 'O' || item.headerToItemNav[0].scheduleType === 'D') {
        this.transportArray().push(this.createFormGroupTransport(null));
        this.showMultipleTables = false;
        this.hideDestination = true;
      }
      else if (item.headerToItemNav[0].scheduleType === 'IT') {
        const transport: any = { value: 'ITE' }
        this.transportArray().push(this.createFormGroupTransport(transport));
        this.hideDestination = false;
        this.showMultipleTables = true;
        this.createPipelineNominationForm.get('selectMultipleCheckbox')?.disable();
      }
    }else{
    let noofTransport = 0
    item.headerToItemNav.forEach((elem: any,transportIndex:any) => {
    
     if(elem.every((ele:any) => ele.scheduleType === 'O' || ele.scheduleType === 'D')){
        this.transportArray().push(this.createFormGroupTransport(null,noofTransport)); 
        this.showMultipleTables = false;
        this.hideDestination = true;
     } else if(elem.every((ele:any) => ele.scheduleType === 'IT')){
        const transport: any = { value: 'ITE' }
        
        this.transportArray().push(this.createFormGroupTransport(transport));
        
        this.hideDestination = false;
        this.showMultipleTables = true;
        this.createPipelineNominationForm.get('selectMultipleCheckbox')?.disable();
     }
     noofTransport=noofTransport+1
     this.originRDIDescSelected[transportIndex] = []
     this.originRDISelected[transportIndex] = []
     this.destinationRDIDescSelected[transportIndex] = []
     this.destinationRDISelected[transportIndex] = []
     this.locationOriginArray[transportIndex] = []
     this.isTankOriginDisabled[transportIndex] = []
     this.defaultTankOrigin[transportIndex] = []
     this.demandProductOrigin[transportIndex] = []
     this.demandProductOriginMaterialCode[transportIndex] = []
     this.locationDestiArray[transportIndex] = []
     this.isTankDestiDisabled[transportIndex] = []
     this.defaultTankDesti[transportIndex] = []
     this.demandProductDesti[transportIndex] = []
     this.demandProductList[transportIndex] = []
     this.demandProductListDesc[transportIndex] = []
     this.scheduledDemandProductList[transportIndex] = []
     this.scheduledDemandProductListDesc[transportIndex] = []
     this.defaultProduct[transportIndex] = []
     this.defaultProductdesti[transportIndex] = []
     this.demandProductDestiMaterialCode[transportIndex] = []
     this.demandProductListMaterialCode[transportIndex] = []
     this.demandProductListDescMaterialCode[transportIndex] = []
     this.scheduledDemandProductListMaterialCode[transportIndex] = []
     this.scheduledDemandProductListDescMaterialCode[transportIndex] = []
     this.tankOriginList[transportIndex] = [];
     this.tankDestiList[transportIndex] = [];
     this.defaultProductMaterialCode[transportIndex] = []
     this.defaultProductdestiMaterialCode[transportIndex] = []
     this.contractLineItemsOrigin[transportIndex] = []
     this.refContractLineItems[transportIndex] = []
     this.contractLineItemsDestination[transportIndex] = []
     this.refContractLineItemsDesti[transportIndex] = []
     this.contractLineItemsITE[transportIndex] = []
     this.refContractLineItemsIte[transportIndex] = []
     this.demandProductList[transportIndex].push(this.productList)
     this.demandProductListDesc[transportIndex].push(this.productList)
     this.scheduledDemandProductList[transportIndex].push(this.productList)
     this.scheduledDemandProductListDesc[transportIndex].push(this.productList)
     this.demandProductListMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.demandProductListDescMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.scheduledDemandProductListMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.scheduledDemandProductListDescMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.terminalList[transportIndex] = this.terminalList[0];
     this.scheduledTimeDesti[transportIndex]  = ['00:00']
     this.scheduledTime[transportIndex] = ['00:00']

    })
  }
  }

  populateCargoNestedData(ele:any,count:any,originPos:any,destinPos:any){  
    if (ele.scheduleType === 'O' || ele.scheduleType === 'D') {
      if (ele.scheduleType === 'O') {
        if(originPos === 0){
         this.originArray = this.transportArray().at(0).get('origin') as FormArray;
      }

        ele?.products?.forEach((product: any, index:any) => {

          if (this.refOrginCount > 0) {
            this.originArray.push(this.createFormGroupOrigin(this.refOrginCount))
            this.isMultipleRowItemOrigin[count]?.push('O');
          }
          
        this.originArray.at(this.refOrginCount)?.get('description')?.setValue(this.rdiDescriptionMap.find(item => item.rdi === ele.referenceDocumentIndicator)?.description);
        this.originArray.at(this.refOrginCount)?.get('rdi')?.setValue(ele.referenceDocumentIndicator);
        // this.originArray.at(this.refOrginCount)?.get('counterParty')?.setValue(ele.contractPartner);
        this.originArray.at(this.refOrginCount)?.get('location')?.setValue(ele.locationId);
        this.originArray.at(this.refOrginCount)?.get('demandProduct')?.setValue(this.getDemandMaterialName(product.demandMaterial));
        this.originArray.at(this.refOrginCount)?.get('demandProductMaterialCode')?.setValue(product.demandMaterial);
        this.originArray.at(this.refOrginCount)?.get('scheduledProduct')?.setValue(this.getDemandMaterialName(product.scheduledMaterial));
        this.originArray.at(this.refOrginCount)?.get('scheduledProductMaterialCode')?.setValue(product.scheduledMaterial);
        this.originArray.at(this.refOrginCount)?.get('scheduledDate')?.setValue(this.datePipe.transform(ele.scheduledDate, "yyy-MM-dd"));
        // this.originArray.at(this.refOrginCount)?.get('endLoadDate')?.setValue(this.datePipe.transform(ele.endLoadDate, "yyy-MM-dd"));
        this.originArray.at(this.refOrginCount)?.get('scheduledQty')?.setValue(product.scheduledQty);
        product.confirmationIndicator = (!this.trNominationForPipeline?.editNomination)? null:product.confirmationIndicator;
        let confirmationIndicatorValue = product.confirmationIndicator;
        confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
        this.originArray.at(this.refOrginCount)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
        if(product.nominationItemStatus === "5"){
          this.originArray.at(this.refOrginCount)?.get('confirmationIndicator')?.disable();
         }
        this.originArray.at(this.refOrginCount)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument);
        this.originArray.at(this.refOrginCount)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem);
        
        this.originRDISelected[0][this.refOrginCount] = ele.referenceDocumentIndicator;
        this.originRDIDescSelected[0][this.refOrginCount] = this.rdiDescriptionMap.find(item => item.rdi === ele.referenceDocumentIndicator)?.description
        this.locationOriginArray[0][this.refOrginCount] = ele.locationId;
        this.demandProductOrigin[0][this.refOrginCount] =  this.getDemandMaterialName(product.demandMaterial);
        this.demandProductOriginMaterialCode[0][this.refOrginCount] = product.demandMaterial
        this.defaultProduct[0][this.refOrginCount] = this.getDemandMaterialName(product.scheduledMaterial)
        this.defaultProductMaterialCode[0][this.refOrginCount] = product.scheduledMaterial

        this.isOriginRequired[0] = true;
        this.qtyDiff = this.qtyDiff + parseFloat(product.scheduledQty);
        this.refOrginCount += 1;
        });
      }
      else {
        if(destinPos === 0){
         this.destinationArray = this.transportArray().at(0).get('destination') as FormArray;
        }
        ele?.products?.forEach((product: any, index:any) => {
        if (this.refDesCount > 0) {
          this.destinationArray.push(this.createFormGroupDestination(this.refDesCount))
          this.isMultipleRowItemDestination[count]?.push('D');
        }
        this.destinationArray.at(this.refDesCount)?.get('key')?.setValue(ele.referenceDocumentIndicator)
        this.destinationArray.at(this.refDesCount)?.get('value')?.setValue(this.keyvalueMap.find(item => item.key === ele.referenceDocumentIndicator)?.value)
        this.destinationArray.at(this.refDesCount)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument)
        this.destinationArray.at(this.refDesCount)?.get('transportSystemMode')?.setValue('')
        this.destinationArray.at(this.refDesCount)?.get('location')?.setValue(ele.locationId)
        this.destinationArray.at(this.refDesCount)?.get('scheduledDate')?.setValue(this.datePipe.transform(ele.scheduledDate, "yyy-MM-dd"))
        this.destinationArray.at(this.refDesCount)?.get('endLoadDate')?.setValue(this.datePipe.transform(ele.endLoadDate, "yyy-MM-dd"));
        this.destinationArray.at(this.refDesCount)?.get('scheduledQty')?.setValue(product.scheduledQty);
        product.confirmationIndicator = (!this.trNominationForPipeline?.editNomination)? null:product.confirmationIndicator;
        let confirmationIndicatorValue = product.confirmationIndicator;
        confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
        this.destinationArray.at(this.refDesCount)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
        if(product.nominationItemStatus === "5"){
          this.destinationArray.at(this.refDesCount)?.get('confirmationIndicator')?.disable();
         }
        this.destinationArray.at(this.refDesCount)?.get('demandProduct')?.setValue(this.getDemandMaterialName(product.demandMaterial))
        this.destinationArray.at(this.refDesCount)?.get('demandProductMaterialCode')?.setValue(product.demandMaterial)
        this.destinationArray.at(this.refDesCount)?.get('scheduledProduct')?.setValue(this.getDemandMaterialName(product.scheduledMaterial))
        this.destinationArray.at(this.refDesCount)?.get('scheduledProductMaterialCode')?.setValue(product.scheduledMaterial)
        this.destinationArray.at(this.refDesCount)?.get('itemId')?.setValue(ele.itemId)
        this.destinationArray.at(this.refDesCount)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem)
        // this.destinationArray.at(this.refDesCount)?.get('counterParty')?.setValue(ele.contractPartner);
        this.destinationRDISelected[0][this.refDesCount] = ele.referenceDocumentIndicator
        this.destinationRDIDescSelected[0][this.refDesCount] = this.keyvalueMap.find(item => item.key === ele.referenceDocumentIndicator)?.value
      
        this.locationDestiArray[0][this.refDesCount] = ele.locationId;
        this.demandProductDesti[0][this.refDesCount] = this.getDemandMaterialName(product.demandMaterial)
        this.demandProductDestiMaterialCode[0][this.refDesCount] = product.demandMaterial;
        this.defaultProductdesti[0][this.refDesCount] = this.getDemandMaterialName(product.scheduledMaterial)
        this.defaultProductdestiMaterialCode[0][this.refDesCount] = product.scheduledMaterial;
        this.qtyDiff = this.qtyDiff - parseFloat(product.scheduledQty);
        this.refDesCount += 1;
        this.isDestinationRequired[0] = false;
      })
    }

    }
  }
originDestiSet(ele: any, originPos: number, count: number, destinPos: number,){
  if (ele.scheduleType === 'O') {
    this.originDestiSetScheduledTypeO(ele, originPos, count);
  }
  else {
    const destinationArray = this.transportArray().at(count).get('destination') as FormArray;
    if (destinPos > 0) {
      destinationArray.push(this.createFormGroupDestination(count))
      this.isMultipleRowItemDestination[count]?.push('D');
    }
    this.populateDestinationForm(destinationArray, ele, destinPos, count);
  }
}

populateDestinationForm(destinationArray: FormArray, ele: any, destinPos: number, count: number) {
  // destinationArray.at(destinPos)?.get('counterParty')?.setValue(ele.contractPartner)
  destinationArray.at(destinPos)?.get('key')?.setValue(ele.referenceDocumentIndicator)
  destinationArray.at(destinPos)?.get('value')?.setValue(this.keyvalueMap.find(item => item.key === ele.referenceDocumentIndicator)?.value)
  destinationArray.at(destinPos)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument)

  destinationArray.at(destinPos)?.get('transportSystemMode')?.setValue('')
  destinationArray.at(destinPos)?.get('location')?.setValue(ele.locationId);
  destinationArray.at(destinPos)?.get('tankCode')?.setValue(ele.udmTankCode);
  destinationArray.at(destinPos)?.get('scheduledDate')?.setValue(this.datePipe.transform(ele.scheduledDate, "yyy-MM-dd"))
  destinationArray.at(destinPos)?.get('scheduledQty')?.setValue(ele.scheduledQty);
  ele.confirmationIndicator = (!this.trNominationForPipeline?.editNomination)? null:ele.confirmationIndicator;
  let confirmationIndicatorValue = ele.confirmationIndicator;
  confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
  destinationArray.at(destinPos)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
  if(this.trNominationForPipeline?.editNomination){
  if(ele.nominationItemStatus === "5"){
    destinationArray.at(destinPos)?.get('confirmationIndicator')?.disable();
   }
  }
  destinationArray.at(destinPos)?.get('scheduledProduct')?.setValue(this.getDemandMaterialName(ele.scheduledMaterial) || ele.scheduledMaterialName || ele.scheduledMaterialDesc)
  destinationArray.at(destinPos)?.get('scheduledProductMaterialCode')?.setValue(ele.scheduledMaterial)
  destinationArray.at(destinPos)?.get('demandProduct')?.setValue(this.getDemandMaterialName(ele.demandMaterial) || ele.demandMaterialName || ele.demandMaterialDesc)
  destinationArray.at(destinPos)?.get('demandProductMaterialCode')?.setValue(ele.demandMaterial)
  destinationArray.at(destinPos)?.get('batchDestinationLocation')?.setValue(ele.batchDestinationLocation)
  destinationArray.at(destinPos)?.get('valuationTypeDestinationLoc')?.setValue(ele.valuationTypeDestinationLoc)
  destinationArray.at(destinPos)?.get('valuationTypeOriginLoc')?.setValue(ele.valuationTypeOriginLoc)
  destinationArray.at(destinPos)?.get('batchOriginLocation')?.setValue(ele.batchOriginLocation)
  destinationArray.at(destinPos)?.get('itemId')?.setValue(ele.itemId)
  destinationArray.at(destinPos)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem)
  destinationArray.at(destinPos)?.get('id')?.setValue(ele.id)
  // destinationArray.at(destinPos)?.get('udmEndLoadDate')?.setValue(ele.endLoadDate);
  destinationArray.at(destinPos)?.get('indicator')?.setValue(count)
  // destinationArray.at(destinPos)?.get('partnerNominationNumber')?.setValue(ele?.partnerNominationNumber);
  destinationArray.at(destinPos)?.get('nominationItemStatus')?.setValue(ele?.nominationItemStatus);
  this.scheduledTimeDesti[count][destinPos] = ele.scheduledTime?  ele.scheduledTime:'00:00';
  this.destinationRDISelected[count][destinPos] = ele.referenceDocumentIndicator
  this.destinationRDIDescSelected[count][destinPos] = this.keyvalueMap.find(item => item.key === ele.referenceDocumentIndicator)?.value
  this.locationDestiArray[count][destinPos] = ele.locationId
  this.isTankDestiDisabled[count][destinPos] = this.tankMasterData.some((element: any) => element.tswLocationCode === ele.locationId);
  this.demandProductDesti[count][destinPos] = this.getDemandMaterialName(ele.demandMaterial)
  this.refContractLineItemsDesti[count][destinPos] = ele.nominationReferenceDocumentItem;
  this.demandProductDestiMaterialCode[count][destinPos] = ele.demandMaterial
  this.qtyDiff = this.qtyDiff - parseFloat(ele.scheduledQty)
  this.isDestinationRequired[count] = true;
  this.defaultProductdesti[count][destinPos] = this.getDemandMaterialName(ele.scheduledMaterial)
  this.defaultProductdestiMaterialCode[count][destinPos] =ele.scheduledMaterial
}

originDestiSetScheduledTypeO(ele: any, originPos: number, count: number) {
    const originArray = this.transportArray()?.at(count)?.get('origin') as FormArray;
    if (originPos > 0) {
      this.isMultipleRowItemOrigin[count]?.push('O');
      originArray.push(this.createFormGroupOrigin(count))
    }


    // originArray.at(originPos)?.get('counterParty')?.setValue(ele.contractPartner)
    originArray.at(originPos)?.get('rdi')?.setValue(ele.referenceDocumentIndicator)
    originArray.at(originPos)?.get('description')?.setValue(this.rdiDescriptionMap.find(item => item.rdi === ele.referenceDocumentIndicator)?.description)
    originArray.at(originPos)?.get('transportSystemMode')?.setValue('')
    originArray.at(originPos)?.get('location')?.setValue(ele.locationId);
    originArray.at(originPos)?.get('tankCode')?.setValue(ele.udmTankCode);
    originArray.at(originPos)?.get('scheduledDate')?.setValue(this.datePipe.transform(ele.scheduledDate, "yyy-MM-dd"))
    originArray.at(originPos)?.get('scheduledQty')?.setValue(ele.scheduledQty);
    ele.confirmationIndicator = (!this.trNominationForPipeline?.editNomination)? null:ele.confirmationIndicator;
    let confirmationIndicatorValue = ele.confirmationIndicator;
    confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
    originArray.at(originPos)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
    if(this.trNominationForPipeline?.editNomination){
    if(ele.nominationItemStatus === "5"){
      originArray.at(originPos)?.get('confirmationIndicator')?.disable();
     }
    }
    originArray.at(originPos)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument)
    originArray.at(originPos)?.get('batchDestinationLocation')?.setValue(ele.batchDestinationLocation)
    originArray.at(originPos)?.get('valuationTypeDestinationLoc')?.setValue(ele.valuationTypeDestinationLoc)
    originArray.at(originPos)?.get('valuationTypeOriginLoc')?.setValue(ele.valuationTypeOriginLoc)
    originArray.at(originPos)?.get('batchOriginLocation')?.setValue(ele.batchOriginLocation)
    originArray.at(originPos)?.get('itemId')?.setValue(ele.itemId)
    originArray.at(originPos)?.get('scheduledProduct')?.setValue(this.getDemandMaterialName(ele.scheduledMaterial) || ele.scheduledMaterialName || ele.scheduledMaterialDesc)
    originArray.at(originPos)?.get('scheduledProductMaterialCode')?.setValue(ele.scheduledMaterial)
    originArray.at(originPos)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem)
    originArray.at(originPos)?.get('demandProduct')?.setValue((this.getDemandMaterialName(ele.demandMaterial) || ele.demandMaterialName || ele.demandMaterialDesc))
    originArray.at(originPos)?.get('demandProductMaterialCode')?.setValue(ele.demandMaterial)
    originArray.at(originPos)?.get('id')?.setValue(ele.id);
    // originArray.at(originPos)?.get('udmEndLoadDate')?.setValue(ele.endLoadDate);
    originArray.at(originPos)?.get('indicator')?.setValue(count);
    // originArray.at(originPos)?.get('partnerNominationNumber')?.setValue(ele?.partnerNominationNumber);
    originArray.at(originPos)?.get('nominationItemStatus')?.setValue(ele?.nominationItemStatus);
    this.scheduledTime[count][originPos] = ele.scheduledTime?  ele.scheduledTime:'00:00';
    this.originRDISelected[count][originPos] = ele.referenceDocumentIndicator;
    this.originRDIDescSelected[count][originPos] = this.rdiDescriptionMap.find(item => item.rdi === ele.referenceDocumentIndicator)?.description
    this.locationOriginArray[count][originPos] = ele.locationId
    this.isTankOriginDisabled[count][originPos] = this.tankMasterData.some((element: any) => element.tswLocationCode === ele.locationId);
    this.defaultTankOrigin[count][originPos] = ele.udmTankCode
    this.demandProductOrigin[count][originPos] = this.getDemandMaterialName(ele.demandMaterial)
    this.refContractLineItems[count][originPos] = ele?.nominationReferenceDocumentItem;
    this.demandProductOriginMaterialCode[count][originPos] = ele.demandMaterial
    this.isOriginRequired[count] = true;
    this.defaultProduct[count][originPos] = this.getDemandMaterialName(ele.scheduledMaterial)
    this.defaultProductMaterialCode[count][originPos] = ele.scheduledMaterial
    this.qtyDiff = this.qtyDiff + parseFloat(ele.scheduledQty)
}
  isOriginOrIte(ele: any, originPos: number, count: number, destinPos: number, itePost: number,eleIndex:any) {
    this.createPipelineNominationForm.get('isTransportpicked')?.setErrors(null)
    this.createPipelineNominationForm.get('isTransportpicked')?.clearValidators();
    if (ele.scheduleType === 'O' || ele.scheduleType === 'D') {
    this.originDestiSet(ele, originPos, count, destinPos)
    }
    else if (ele.scheduleType === 'IT') {
      const iteArray = this.transportArray().at(count).get('ite') as FormArray;
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.clearValidators();
      if (itePost > 0) {
        iteArray.push(this.createFormGroupIte());
        this.isMultipleRowItemIte[count]?.push('IT');
      }
      this.populateIteForm(iteArray, ele, itePost, count);
        }
  }

  populateIteForm(iteArray: FormArray, ele: any, itePost: number, count: number){
    iteArray.at(itePost)?.get('rdiPort')?.setValue(ele.referenceDocumentIndicator)
    iteArray.at(itePost)?.get('descPort')?.setValue(this.rdiDescTransport.find(item => item.rdiPort === ele.referenceDocumentIndicator)?.descPort)
    iteArray.at(itePost)?.get('transportSystemMode')?.setValue('')
    iteArray.at(itePost)?.get('location')?.setValue(ele.locationId)
    iteArray.at(itePost)?.get('scheduledDate')?.setValue(this.datePipe.transform(ele.scheduledDate, "yyy-MM-dd"))
    iteArray.at(itePost)?.get('scheduledQty')?.setValue(ele.scheduledQty);
    ele.confirmationIndicator = (!this.trNominationForPipeline?.editNomination)? null:ele.confirmationIndicator;
    let confirmationIndicatorValue = ele.confirmationIndicator;
    confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
    iteArray.at(itePost)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
    if(this.trNominationForPipeline?.editNomination){
    if(ele.nominationItemStatus === "5"){
      iteArray.at(itePost)?.get('confirmationIndicator')?.disable();
      }
    }
    iteArray.at(itePost)?.get('demandProduct')?.setValue(this.getDemandMaterialName(ele.demandMaterial) || ele.demandMaterialName)
    iteArray.at(itePost)?.get('demandProductMaterialCode')?.setValue(ele.demandMaterial)
    iteArray.at(itePost)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument)
    iteArray.at(itePost)?.get('itemId')?.setValue(ele.itemId)
    iteArray.at(itePost)?.get('defaultProduct')?.setValue(this.getDemandMaterialName(ele.scheduledMaterial))
    iteArray.at(itePost)?.get('defaultProductmaterialCode')?.setValue(ele.scheduledMaterial)
    iteArray.at(itePost)?.get('batchDestinationLocation')?.setValue(ele.batchDestinationLocation)
    iteArray.at(itePost)?.get('valuationTypeDestinationLoc')?.setValue(ele.valuationTypeDestinationLoc)
    iteArray.at(itePost)?.get('valuationTypeOriginLoc')?.setValue(ele.valuationTypeOriginLoc)
    iteArray.at(itePost)?.get('batchOriginLocation')?.setValue(ele.batchOriginLocation)
    iteArray.at(itePost)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem)
    iteArray.at(itePost)?.get('scheduledProduct')?.setValue(this.getDemandMaterialName(ele.scheduledMaterial) || ele.scheduledMaterialName)
    iteArray.at(itePost)?.get('scheduledProductMaterialCode')?.setValue(ele.scheduledMaterial)
    iteArray.at(itePost)?.get('nominationItemStatus')?.setValue(ele?.nominationItemStatus);
    iteArray.at(itePost)?.get('id')?.setValue(ele.id);
    this.scheduledTime[count][itePost] = ele.scheduledTime?  ele.scheduledTime:'00:00';
    this.inTankRDIDescSelected[count][itePost] = this.rdiDescTransport.find(item => item.rdiPort === ele.referenceDocumentIndicator)?.descPort
    this.inTankRDISelected[count][itePost] = ele.referenceDocumentIndicator
    this.locationIteArray[count][itePost] = ele.locationId
    this.demandProductIte[count][itePost] = this.getDemandMaterialName(ele.demandMaterial)
    this.defaultProduct[count][itePost] = this.getDemandMaterialName(ele.scheduledMaterial)
    this.refContractLineItemsIte[count][itePost] = ele?.nominationReferenceDocumentItem;
    this.demandProductIteMaterialCode[count][itePost] = ele.demandMaterial
    this.defaultProductMaterialCode[count][itePost] = ele.scheduledMaterial

  }
  isCreateNomfromScratchorDraft(item: any, hasValue: boolean) {

    if (item.sys_status?.toLowerCase() === Nom.DRAFT || item.sys_status?.toLowerCase() === Nom.UNPUBLISHED || item.sys_status?.toLowerCase() === Nom.PUBLISHED) {
      this.updateDraftReference = true

    }
    if (!hasValue) {
      this.createPipelineNominationForm.get('selectMultipleCheckbox1')?.setValue(false);
      this.isAdditionalData = false
    }

  }

  checkForVesselvalue() {
    this.vesselNameValue = this.masterVesselArray?.filter((ts: any) => {
      return ts.vesselName === this.createPipelineNominationForm.get('vesselName')?.value
    }).length ? this.createPipelineNominationForm.get('vesselName')?.value : '';
  }



  // createCargonWithReference(data: any) {
  //   this.pipelineModalPopupData = JSON.parse(JSON.stringify(data)); 
  //   // To check if ITE ITW nomination with IT schedule Type
  //   if(data?.transportSystem){
  //     this.checkIfITEITWNomination();
  //   }
  //   this.isnotcreatedFromReforEdited = false
  //   this.scenario = data?.mot.toLowerCase();
  //   this.createCargoWithRef = true;
  //   this.getMasterDataForCargo();
  //   this.getMasterVesselData(data?.vesselName);
  //   this.rdiDescriptionMapFn();
  //   let obj:any = [data]
  //   let count = 0;
  //   let hasValue = false;
  //   this.isOriginRequired[0] = false;
  //   while (this.transportArray()?.controls?.length !== 0) {
  //     this.transportArray().removeAt(0);
  //   }
  //   this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
  //   this.modifyFormForCp(this.scenario, true);
  //   this.updateFormFields(this.scenario, false);
  //   obj.forEach((item:any) => {
  //     this.createPipelineNominationForm.get("tug")?.setValue(item?.tug);
  //     this.createPipelineNominationForm.get("nominationNo")?.setValue(item?.nominationNo);
  //     if( this.trNominationForPipeline?.editNomination){
  //       this.createPipelineNominationForm.get('nominationNotes')?.setValue(item?.headerTextLine);
  //       }
  //     this.createPipelineNominationForm.get("btCustodyChain")?.setValue(item?.btCustodyChain);      
  //     this.createPipelineNominationForm.get("transportSystem")?.setValue(item?.transportSystem);
  //     this.defaultTransportSystem = item?.transportSystem;
  //     this.isCreateNomfromScratchorDraft(item, hasValue);
  //     let originPos = 0;
  //     let destinPos = 0;
  //     let itePost = 0;
  //     this.transportArray().push(this.createFormGroupTransport(null));
  //     item.cargoSchedules.forEach((ele: any,index:any) => {
  //       this.populateCargoNestedData(ele, count,originPos,destinPos);
  //       if (ele.scheduleType === 'O') {
  //         this.isOldTransport = true;
  //         originPos = originPos + 1;
  //       }
  //       else {
  //         destinPos = destinPos + 1
  //         this.isOldTransport = true;
  //       }
  //      })
  //      count = +1;
  //   })
  //   if(this.isTransportChange){
  //     this.isTransportChange = false;
  //     this.onTransportSystemChange()
  //   }
  //   this.isEveryLineItemIT = [...new Set(...this.isEveryLineItemIT)];
  // }

  checkAdditionalData(ele: any, hasValue: boolean){
    if ((ele.batchDestinationLocation || ele.valuationTypeDestinationLoc || ele.valuationTypeOriginLoc || ele.batchOriginLocation) && !hasValue) {
      this.createPipelineNominationForm.get('selectMultipleCheckbox1')?.setValue(true)
      hasValue = true;
      this.isAdditionalData = true
    }
    return hasValue
  }
  //  isInCP(){
  //   if (this.inCpNomTab) {
  //     this.modifyFormForCp(this.scenario, true);
  //   }
  //   // if (this.isCargoDesks) {
  //   //   if(!this.createPipelineNominationForm.controls['partnerNominationNumber']){
  //   //     this.createPipelineNominationForm.addControl('partnerNominationNumber', new FormControl(''));
  //   //   }
  //   // }
  //  }
   isDefaultValueForNonCP(data: any){
      let isMultiTransportSystem = false
      if(data?.headerToItemNav?.length>1){
        isMultiTransportSystem = true;
        this.showMultipleTables = true;
        this.createPipelineNominationForm.get('selectMultipleCheckbox')?.setValue(true);
        this.isMultipleTransport = true;
        this.createPipelineNominationForm.get('transportSystem')?.setValue("");
        this.createPipelineNominationForm.get('transportSystem')?.clearValidators();
        this.createPipelineNominationForm.get('transportSystem')?.setErrors(null)
        let transport = this.createPipelineNominationForm.get('transport') as FormArray
        transport.at(0).get("transportSystemMode")?.setValidators(Validators.required);
        transport.at(0).get("transportSystemMode")?.setErrors({ 'invalid': true });
        this.olddeletedValue = true;
        this.defaultTransportSystem = null
      }
      if(data?.vehicleName){
        this.getMasterVesselData(data?.vehicleName);
      }
      return isMultiTransportSystem;
   
    }
  //  async createNominationForSearch(data: any){
  //   this.pipelineModalPopupData = JSON.parse(JSON.stringify(data)); 
  //   // To check if ITE ITW nomination with IT schedule Type
  //   if(data?.transportSystem){
  //     this.checkIfITEITWNomination();
  //   }
  //    this.isSearchPopup = true;
  //   this.deskNumber = data.udmDesks;
  //    let resp : any
  //   if(data.udmDesks === '5'|| data.udmDesks === '17' || data.udmDesks === '16' || data.udmDesks === '9'){
  //      this.inCpTab = false;
  //      this.inCpNomTab = true
  //      this.isCargoDesks = true;
  //      this.scenario = (this.cpService?.createNominationFromCargoWithReference?.value?.initiate)?data?.mot?.toLowerCase():data?.modeOfTransportDesc?.toLowerCase();
  //      this.scenario = ((data?.transportSystem?.toLowerCase() === 'itw' || data?.transportSystem?.toLowerCase() === 'ite') && this.scenario === 'pipeline')?'inTank':this.scenario;
  //      this.updateFormFields(this.scenario, false);       
  //      this.selectedDeskParameter = nomPopSpecific[data.udmDesks];
  //      resp = this.utilities.getPipelineCPDropdownListForSearch(this.scenario, data.udmDesks);
  //      this.updatelists(resp)
 
  //     }
  //     else if(data.udmDesks === '4'|| data.udmDesks === '6' ||data.udmDesks === '7'|| data.udmDesks === '8' || data.udmDesks === '9' ||data.udmDesks ===  '10' || data.udmDesks === '11' ){
  //      this.isCargoDesks = false;
  //      this.inCpTab = false;
  //      this.inCpNomTab = false;
  //      this.nomCargoFieldObj.vesselFields = false;
  //      this.nomCargoFieldObj.bargeFields = false;
  //      this.removeFormControls()
  //      this.cycleValidations()
  //      this.selectedDeskParameter = nomPopSpecific[data.udmDesks];
     
  //          resp = await this.utilities.getPipelineDropdownListforSearch(this.deskNumber)
  //          this.updatelistsSearch(resp)
 
  //     }  
  //    if (!data) {
  //     return;
  //   }
  //   if (!this.inCpTab || this.path$.value === '/replenishmentPlanning' || this.cpService?.createNominationFromCargoWithReference?.value?.initiate) {

  //   this.cargoId = data?.id
  //   let isMultiTransportSystem = this.isDefaultValueForNonCP(data);
  //   let obj = [data]
  //   let count = 0;
  //   let originPos = 0;
  //   let destinPos = 0;
  //   let itePost = 0;
  //   let hasValue = false;
  //   this.isOriginRequired[0] = false
  //   while (this.transportArray()?.controls?.length !== 0) {
  //     this.transportArray().removeAt(0);
  //   }
  //   this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
  //   this.isInCP()
  //  this.generatePopupFromSearch(obj,isMultiTransportSystem,hasValue, originPos, count, destinPos, itePost)
  // }

  // }
generatePopupFromSearch(obj: any, isMultiTransportSystem: any,hasValue: any, originPos: any, count: any,
   destinPos: any, itePost: any){
  obj.forEach((item: any) => {
    this.createPipelineNominationForm.get("tug")?.setValue(item?.udmTug ?? item?.tug);
    if( this.trNominationForPipeline?.editNomination){
      this.isPipelineNomEdit = true;
      this.createPipelineNominationForm.get('nominationNo')?.setValue(item?.nominationNo);
      this.createPipelineNominationForm.get('nominationNotes')?.setValue(item?.headerTextLine);
      }else{
        this.isPipelineNomEdit = false;
      }
    this.createPipelineNominationForm.get('btCustodyChain')?.setValue(item?.btCustodyChain);   
    // this.createPipelineNominationForm.get('partnerNominationNumber')?.setValue(item?.partnerNominationNumber);
    this.vesselNameValue = item?.vehicleName;
    this.setDefaultTSCycleId(isMultiTransportSystem,item);
    this.isCreateNomfromScratchorDraft(item, hasValue);
    this.isItemVal(item)

    if(this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
      item.headerToItemNav.forEach((ele: any) => {

        this.checkAdditionalData(ele, hasValue)

      })
      item.headerToItemNav.forEach((ele: any, index:any) => {
  
        this.isOriginOrIte(ele, originPos, count, destinPos, itePost, index)
        if (ele.scheduleType === 'IT') {
          itePost = itePost + 1
          this.isOldTransportITE = true
          this.isOldTransport = false

        }
        if (ele.scheduleType === 'O') {
          this.isOldTransport = true;
          this.isOldTransportITE = false

          originPos = originPos + 1
        }
        if (ele.scheduleType === 'D') {
          destinPos = destinPos + 1
          this.isOldTransport = true;
          this.isOldTransportITE = false


        }
      })
      count = +1;
      this.getRefData = JSON.parse(JSON.stringify(item))
    }else{
    item.headerToItemNav.forEach((elem: any, index: any) => {

      elem.forEach((ele: any) => {
        this.checkAdditionalData(ele, hasValue);
      })
    })
    item.headerToItemNav.forEach((elem: any,index:any) => {
      originPos = 0;
      destinPos = 0;
      itePost = 0;
      this.isMultipleRowTransport = this.isMultipleRowTransport +1;
     // For setting transport system for each iteration below the header info
     if(index>0){
      this.isMultipleRowItemOrigin.push(['O']);
      this.isMultipleRowItemDestination.push(['D']);
      this.isMultipleRowItemIte.push(['IT']);
     }
      let transport = this.createPipelineNominationForm.get('transport') as FormArray
      transport.at(index).get("transportSystemMode")?.patchValue(elem[0].transportSystem);

      this.defaultTransportModalSystem[index]=elem[0].transportSystem;
      
     elem.forEach((ele: any,eleIndex:any) => {




      this.isOriginOrIte(ele, originPos, count, destinPos, itePost,eleIndex)
      if (ele.scheduleType === 'IT') {
        itePost = itePost + 1
        this.isOldTransportITE = true
        this.isOldTransport = false
      }
      if (ele.scheduleType === 'O') {
        this.isOldTransport = true;
        this.isOldTransportITE = false
        originPos = originPos + 1
      }
      if (ele.scheduleType === 'D') {
        destinPos = destinPos + 1
        this.isOldTransport = true;
        this.isOldTransportITE = false
      }
    })
    count = count+1;
 })
    this.getRefData = JSON.parse(JSON.stringify(item))
}
  })
  this.isEveryLineItemIT = [...new Set(...this.isEveryLineItemIT)];
}
  createNominationWithReference(data: any) { 
    this.isPipelineNomEdit = data.pipelineNomEditFlag;
    this.pipelineModalPopupData = JSON.parse(JSON.stringify(data)); 
    // To check if ITE ITW nomination with IT schedule Type
    if(data?.transportSystem){
      this.checkIfITEITWNomination();
    }
    this.isnotcreatedFromReforEdited = false
    this.autoAdjustmentData = JSON.parse(JSON.stringify(data));
    const vehcleName = (this.cpService?.createNominationFromCargoWithReference?.value?.initiate)?JSON.parse(JSON.stringify(data?.vehicleName)):JSON.parse(JSON.stringify(data?.headerToItemNav[0][0]?.vehicleName));
    const datesArray = (this.cpService?.createNominationFromCargoWithReference?.value?.initiate)?JSON.parse(JSON.stringify(data?.headerToItemNav)):JSON.parse(JSON.stringify(data?.headerToItemNav[0]));
    const fromDates = datesArray?.filter((el: any) => el?.scheduleType === "O");
    const toDates = datesArray?.filter((el: any) => el?.scheduleType === "D" || el?.scheduleType === "IT");
    this.initialOriginScheduledDate = new Date(fromDates[0]?.scheduledDate);
    this.initialDestinationScheduledDate = new Date(toDates[0]?.scheduledDate);
    const transportArray: any = this.transportArray();
    // this.autoAdjustmentInitialDates.push({ initialOriginDate: this.initialOriginScheduledDate, initialDestinationDate: this.initialDestinationScheduledDate });
    if (!data) {
      return;
    }
    
    this.getNomData = data;
    if (!this.inCpTab || this.path$.value === '/replenishmentPlanning' || this.cpService?.createNominationFromCargoWithReference?.value?.initiate) {
      this.cargoId = data?.id
      let isMultiTransportSystem = false;
      // if(data?.headerToItemNav?.length>1){
      //   isMultiTransportSystem = true;
      //   this.showMultipleTables = true;
      //   this.createPipelineNominationForm.get('selectMultipleCheckbox')?.setValue(true);
      //   this.isMultipleTransport = true;
      //   this.createPipelineNominationForm.get('transportSystem')?.setValue("");
      //   this.createPipelineNominationForm.get('transportSystem')?.clearValidators();
      //   this.createPipelineNominationForm.get('transportSystem')?.setErrors(null)
      //   let transport = this.createPipelineNominationForm.get('transport') as FormArray
      //   transport.at(0).get("transportSystemMode")?.setValidators(Validators.required);
      //   transport.at(0).get("transportSystemMode")?.setErrors({ 'invalid': true });
      //   this.olddeletedValue = true;
      //   this.defaultTransportSystem = null
      // }
      
      // if(this.isCargoDesks){
      //   this.scenario = (this.cpService?.createNominationFromCargoWithReference?.value?.initiate)?data?.mot?.toLowerCase():data?.headerToItemNav[0][0]?.modeOfTransportDesc?.toLowerCase();
      //   this.scenario = ((data?.transportSystem?.toLowerCase() === 'itw' || data?.transportSystem?.toLowerCase() === 'ite') && this.scenario === 'pipeline')?'inTank':this.scenario;
      // }
      if(vehcleName){
        this.getMasterVesselData(vehcleName);
      }
      this.initialValues();
      let obj = [data]
      let count = 0;
      let originPos = 0;
      let destinPos = 0;
      let itePost = 0;
      let hasValue = false;
      this.isOriginRequired[0] = false
      while (this.transportArray()?.controls?.length !== 0) {
        this.transportArray().removeAt(0);
      }
      this.createPipelineNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
      // if (this.inCpNomTab) {
      //   this.modifyFormForCp(this.scenario, true);
      // }
      // if (this.isCargoDesks) {
      //   if(!this.createPipelineNominationForm.controls['partnerNominationNumber']){
      //     this.createPipelineNominationForm.addControl('partnerNominationNumber', new FormControl(''));
      //   }
      // }
      obj.forEach((item: any) => {
        // this.createPipelineNominationForm.get("tug")?.setValue(item?.udmTug ?? item?.tug);
        if( this.trNominationForPipeline?.editNomination || item?.cargoSchedules){
          this.createPipelineNominationForm.get('nominationNo')?.setValue(item?.nominationNo);
          this.createPipelineNominationForm.get('nominationNotes')?.setValue(item?.headerTextLine);
          this.createPipelineNominationForm.get('nominationKey')?.setValue(this.trNominationForPipeline?.nominationKey);         
          }
        this.createPipelineNominationForm.get('btCustodyChain')?.setValue(item?.btCustodyChain);
        // this.createPipelineNominationForm.get('partnerNominationNumber')?.setValue(item?.partnerNominationNumber);
        this.vesselNameValue = item?.vehicleName;
        this.setDefaultTSCycleId(isMultiTransportSystem,item);
        this.isCreateNomfromScratchorDraft(item, hasValue);
        this.isItemVal(item)

        if(this.cpService?.createNominationFromCargoWithReference?.value?.initiate){
          item.headerToItemNav.forEach((ele: any) => {

            this.checkAdditionalData(ele, hasValue)
  
          })
          item.headerToItemNav.forEach((ele: any, index:any) => {
      
            this.isOriginOrIte(ele, originPos, count, destinPos, itePost, index)
            if (ele.scheduleType === 'IT') {
              itePost = itePost + 1
              this.isOldTransportITE = true
              this.isOldTransport = false
  
            }
            if (ele.scheduleType === 'O') {
              this.isOldTransport = true;
              this.isOldTransportITE = false
  
              originPos = originPos + 1
            }
            if (ele.scheduleType === 'D') {
              destinPos = destinPos + 1
              this.isOldTransport = true;
              this.isOldTransportITE = false
  
  
            }
          })
          count = +1;
          this.getRefData = JSON.parse(JSON.stringify(item))
        }else{
        item.headerToItemNav.forEach((elem: any, index: any) => {

          elem.forEach((ele: any) => {
            this.checkAdditionalData(ele, hasValue);
          })
        })
        item.headerToItemNav.forEach((elem: any,index:any) => {
          originPos = 0;
          destinPos = 0;
          itePost = 0;
          this.isMultipleRowTransport = this.isMultipleRowTransport +1;
         // For setting transport system for each iteration below the header info
         if(index>0){
          this.isMultipleRowItemOrigin.push(['O']);
          this.isMultipleRowItemDestination.push(['D']);
          this.isMultipleRowItemIte.push(['IT']);
         }
          let transport = this.createPipelineNominationForm.get('transport') as FormArray
          transport.at(index).get("transportSystemMode")?.patchValue(elem[0].transportSystem);
  
          this.defaultTransportModalSystem[index]=elem[0].transportSystem;

         elem.forEach((ele: any,eleIndex:any) => {




          this.isOriginOrIte(ele, originPos, count, destinPos, itePost,eleIndex)
          if (ele.scheduleType === 'IT') {
            itePost = itePost + 1
            this.isOldTransportITE = true
            this.isOldTransport = false
          }
          if (ele.scheduleType === 'O') {
            this.isOldTransport = true;
            this.isOldTransportITE = false
            originPos = originPos + 1
          }
          if (ele.scheduleType === 'D') {
            destinPos = destinPos + 1
            this.isOldTransport = true;
            this.isOldTransportITE = false
          }
        })
        count = count+1;
     })
        this.getRefData = JSON.parse(JSON.stringify(item))
    }
      })
      const isITNomSectionRequired = this.isITEITWNominationWithITScheduleType();
      for (let dt of this.transportArray().value) {
        if ((dt?.transportSystemMode === "ITE" || dt?.transportSystemMode === "ITW") && isITNomSectionRequired) {
          this.autoAdjustmentInitialDates.push({ initialDestinationDate: new Date(`${dt?.ite[0]?.scheduledDate}T00:00:00`) });
        } else {
          this.autoAdjustmentInitialDates.push({ initialOriginDate: new Date(`${dt?.origin[0]?.scheduledDate}T00:00:00`), initialDestinationDate: new Date(`${dt?.destination[0]?.scheduledDate}T00:00:00`) });
        }
      }
  }
  if(this.isTransportChange){
    this.isTransportChange = false;
    this.onTransportSystemChange()
  }
  this.isEveryLineItemIT = [...new Set(...this.isEveryLineItemIT)];
  }


  setDefaultTSCycleId(isMultiTransportSystem:any,item:any){
    if(!isMultiTransportSystem){
      this.createPipelineNominationForm.get('transportSystem')?.setValue(item.transportSystem);
      this.defaultTransportSystem = item?.transportSystem ? item.transportSystem : ''
  }
    if(item?.headerToItemNav?.length){
      this.createPipelineNominationForm.get('cycleId')?.setValue(item.headerToItemNav[0][0]?.cycleId);
    }else{
      this.createPipelineNominationForm.get('cycleId')?.setValue(item.cycleId);
    }
  }

  //pre set data to modal fields'
  presetNominationDataForPipeline() {
    // if ((this.path$.value === '/nominations') && (this.inCpTab) && this.isCargoDesks) {
    //   this.presetNominationDataForCargo();
    // }
    // else{
      this.currentNominationNo = this.trNominationForPipeline.nominationNo;
      this.createNominationWithReference(this.trNominationForPipeline);
    // }

  }
  // presetNominationDataForPipelineSearch(){
  //   if(this.trNominationForPipeline.udmDesks === '5'|| this.trNominationForPipeline.udmDesks === '17' || this.trNominationForPipeline.udmDesks === '16' || this.trNominationForPipeline.udmDesks === '9'){
  //     this.inCpTab = false;
  //     this.inCpNomTab = true
  //     this.isCargoDesks = true;
  //     this.selectedDeskParameter = nomPopSpecific[this.trNominationForPipeline.udmDesks];
  //    }
  //    else if(this.trNominationForPipeline.udmDesks === '4'|| this.trNominationForPipeline.udmDesks === '6' ||this.trNominationForPipeline.udmDesks === '7'|| this.trNominationForPipeline.udmDesks === '8' || this.trNominationForPipeline.udmDesks === '9' ||this.trNominationForPipeline.udmDesks ===  '10' || this.trNominationForPipeline.udmDesks === '11' ){
  //     this.isCargoDesks = false;
  //     this.inCpTab = false;
  //     this.inCpNomTab = true
  //     this.selectedDeskParameter = nomPopSpecific[this.trNominationForPipeline.udmDesks];
  //    }
  //    this.createNominationForSearch(this.trNominationForPipeline);

  // }

  //pre set data to modal fields'
  // presetNominationDataForCargo() {
  // this.createCargonWithReference(this.nominationService.isCpGridSelected[0]);
  // }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.destroy$?.next(true);
    this.destroy$?.unsubscribe();
    this.subs?.unsubscribe();
  }
  onContractLineChange(event: any, index: number, formGroupIndex:number, formArrayName: string) {
    const replaceLeadingZero = event.target.value.replace(/\b0+/g, "")?.trim();
    if (formArrayName == 'origin') {
      this.origin(formGroupIndex).at(index)?.get('sapcontract')?.setValue(replaceLeadingZero);
    }
    else if (formArrayName == 'destination') {
      this.destination(formGroupIndex).at(index)?.get('sapcontract')?.setValue(replaceLeadingZero);
    }
    else if (formArrayName == 'ite') {
     this.ite(formGroupIndex).at(index)?.get('sapcontract')?.setValue(replaceLeadingZero);
    }
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  getDateFromMS(event: any, ms: any) {
    const lineDate = new Date(`${event}T00:00:00`);
    const milliSeconds = ms ? Math.abs(lineDate?.getTime() + ms) : Math.abs(lineDate?.getTime());
    const finalDate = this.datePipe?.transform(new Date(milliSeconds), 'yyyy-MM-dd');
    return finalDate;
  }

  onOriginScheduledDateInput(event: any, originScheduledDates: any, destinationScheduledDates: any, index1: any, index2: any) {
    if (index2 === 0 && !this.isPipelineNomEdit && originScheduledDates[0]?.value?.scheduledDate !== "") {
      const originDate = new Date(`${event?.target?.value}T00:00:00`);
      const difference_In_Time = originDate?.getTime() - this.autoAdjustmentInitialDates[index1]?.initialOriginDate?.getTime();
      originScheduledDates?.forEach((el: any, i: any) => {
        if (i !== 0) {
          console.log(this.getDateFromMS(el?.controls["scheduledDate"]?.value, difference_In_Time));
          el?.controls["scheduledDate"]?.setValue(this.getDateFromMS(el?.controls["scheduledDate"]?.value, difference_In_Time));
        }
      });
      if (destinationScheduledDates[0]?.value?.scheduledDate !== "") {
        destinationScheduledDates?.forEach((el: any) => {
          el?.controls["scheduledDate"]?.setValue(this.getDateFromMS(el?.controls["scheduledDate"]?.value, difference_In_Time));
        });
        this.autoAdjustmentInitialDates[index1]["initialDestinationDate"] = new Date(`${destinationScheduledDates[0]?.value?.scheduledDate}T00:00:00`);
      }
      this.autoAdjustmentInitialDates[index1]["initialOriginDate"] = new Date(`${event?.target?.value}T00:00:00`);
    }
  }

  onDestinationScheduledDateInput(event: any, originScheduledDates: any, destinationScheduledDates: any, index1: any, index2: any) {
    if (index2 === 0 && !this.isPipelineNomEdit && !(originScheduledDates?.length === 1 && destinationScheduledDates?.length === 1) && destinationScheduledDates[0]?.value?.scheduledDate !== "") {
      const destinationDate = new Date(`${event?.target?.value}T00:00:00`);
      const difference_In_Time = destinationDate?.getTime() - this.autoAdjustmentInitialDates[index1]?.initialDestinationDate?.getTime();
      destinationScheduledDates?.forEach((el: any, i: any) => {
        if (i !== 0) {
          el?.controls["scheduledDate"]?.setValue(this.getDateFromMS(el?.controls["scheduledDate"]?.value, difference_In_Time));
        }
      });
      if (originScheduledDates[0]?.value?.scheduledDate !== "") {
        originScheduledDates?.forEach((el: any) => {
          el?.controls["scheduledDate"]?.setValue(this.getDateFromMS(el?.controls["scheduledDate"]?.value, difference_In_Time));
        });
        this.autoAdjustmentInitialDates[index1]["initialDestinationDate"] = new Date(`${originScheduledDates[0]?.value?.scheduledDate}T00:00:00`);
      }
      this.autoAdjustmentInitialDates[index1]["initialDestinationDate"] = new Date(`${event?.target?.value}T00:00:00`);
    }
  }

  copyOriginRow(formIndex:number, rowIndex:number){
    this.addOriginRow(formIndex)
    const copyRowData = this.origin(formIndex).at(rowIndex)
    const originList = this.origin(formIndex);
    const lastRowNo = originList.length - 1;
    const pasteNewOriginRowData = this.origin(formIndex).at(lastRowNo);
    pasteNewOriginRowData.patchValue(copyRowData.value);
    pasteNewOriginRowData.get('itemId')?.setValue('');
    this.originRDIDescSelected[formIndex][lastRowNo] = copyRowData.value.description;
    this.originRDISelected[formIndex][lastRowNo] = copyRowData.value.rdi;
    this.locationOriginArray[formIndex][lastRowNo] = copyRowData.value.location;
    this.isTankOriginDisabled[formIndex][lastRowNo] = this.tankMasterData.some((element: any) => element.tswLocationCode === copyRowData.value.location);
    this.defaultTankOrigin[formIndex][lastRowNo] = copyRowData.value.tankCode;
    this.defaultProductMaterialCode[formIndex][lastRowNo] = copyRowData.value.scheduledProductMaterialCode;
    this.defaultProduct[formIndex][lastRowNo] = copyRowData.value.scheduledProduct;
    this.demandProductOriginMaterialCode[formIndex][lastRowNo] = copyRowData.value.demandProductMaterialCode;
    this.demandProductOrigin[formIndex][lastRowNo] = copyRowData.value.demandProduct;
    this.refContractLineItems[formIndex][lastRowNo] = copyRowData.value.contractLineNo;
    // this.checkQTY(formIndex, rowIndex);
  }
  clearOriginRow(formIndex:number, rowIndex:number){
    const originList = this.origin(formIndex);
    if(originList){
      const formGroup =  originList.at(rowIndex)
      const itemId = formGroup.value.itemId;
      formGroup.get('itemId')?.setValue(itemId);
      const dropdownvalue = 'select';
      this.originRDIDescSelected[formIndex][rowIndex] = dropdownvalue;
      this.originRDISelected[formIndex][rowIndex] = dropdownvalue;
      this.locationOriginArray[formIndex][rowIndex] = dropdownvalue;
      this.isTankOriginDisabled[formIndex][rowIndex] = false;
      this.defaultTankOrigin[formIndex][rowIndex] = dropdownvalue;
      this.defaultProductMaterialCode[formIndex][rowIndex] = dropdownvalue;
      this.defaultProduct[formIndex][rowIndex] = dropdownvalue;
      this.demandProductOriginMaterialCode[formIndex][rowIndex] = dropdownvalue;
      this.demandProductOrigin[formIndex][rowIndex] = dropdownvalue;
      this.refContractLineItems[formIndex][rowIndex] = dropdownvalue;
      this.isOriginRequired[formIndex] = false;
      // this.checkQTY(formIndex, rowIndex);
      this.origin(formIndex).value.forEach((element: any) => {
          Object.keys(element).forEach((e: any) => {
            if(e !== "indicator" && e !== "itemId" && e !== "id"){
            formGroup.get(e)?.setValue('');
            }
          })
        });
    }
  }
  copyDestinationRow(formIndex:number, rowIndex:number){
    this.addDestinationRow(formIndex)
    const copyRowData = this.destination(formIndex).at(rowIndex);
    const destinationList = this.destination(formIndex);
    const lastRowNo = destinationList.length - 1;
    const pasteNewdestinationRowData = this.destination(formIndex).at(lastRowNo);
    pasteNewdestinationRowData.patchValue(copyRowData.value);
    pasteNewdestinationRowData.get('itemId')?.setValue('');
    this.destinationRDIDescSelected[formIndex][lastRowNo] = copyRowData.value.value;
    this.destinationRDISelected[formIndex][lastRowNo] = copyRowData.value.key;
    this.locationDestiArray[formIndex][lastRowNo] = copyRowData.value.location;
    this.isTankDestiDisabled[formIndex][lastRowNo] = this.tankMasterData.some((element: any) => element.tswLocationCode === copyRowData.value.location);
    this.defaultTankDesti[formIndex][lastRowNo] = copyRowData.value.tankCode;
    this.defaultProductdestiMaterialCode[formIndex][lastRowNo] = copyRowData.value.scheduledProductMaterialCode;
    this.defaultProductdesti[formIndex][lastRowNo] = copyRowData.value.scheduledProduct;
    this.demandProductDestiMaterialCode[formIndex][lastRowNo] = copyRowData.value.demandProductMaterialCode
    this.demandProductDesti[formIndex][lastRowNo] = copyRowData.value.demandProduct;
    this.refContractLineItemsDesti[formIndex][lastRowNo] = copyRowData.value.contractLineNo;
    // this.checkQTY(formIndex, rowIndex);
  }
  clearDestinationRow(formIndex:number, rowIndex:number){
    const destinationnList = this.destination(formIndex)
    if(destinationnList){
      const formGroup =  destinationnList.at(rowIndex);
      const itemId = formGroup.value.itemId;
      formGroup.get('itemId')?.setValue(itemId);
      const dropdownvalue = 'select';
      this.destinationRDIDescSelected[formIndex][rowIndex] = dropdownvalue;
      this.destinationRDISelected[formIndex][rowIndex] = dropdownvalue;
      this.locationDestiArray[formIndex][rowIndex] = dropdownvalue;
      this.isTankDestiDisabled[formIndex][rowIndex] = false;
      this.defaultTankDesti[formIndex][rowIndex] = dropdownvalue;
      this.defaultProductdestiMaterialCode[formIndex][rowIndex] = dropdownvalue;
      this.defaultProductdesti[formIndex][rowIndex] = dropdownvalue;
      this.demandProductDestiMaterialCode[formIndex][rowIndex] = dropdownvalue;
      this.demandProductDesti[formIndex][rowIndex] = dropdownvalue;
      this.refContractLineItemsDesti[formIndex][rowIndex] = dropdownvalue;
      // this.checkQTY(formIndex, rowIndex);
      this.isDestinationRequired[formIndex] = false;
      this.destination(formIndex).value.forEach((element: any) => {
          Object.keys(element).forEach((e: any) => {
            if(e !== "indicator" && e !== "itemId" && e !== "id"){
            formGroup.get(e)?.setValue('');
            }
          })
        });
    }
  }
  copyIteRow(formIndex:number, rowIndex:number){
    this.addIteRow(formIndex)
    const copyRowData = this.ite(formIndex).at(rowIndex);
    const iteList = this.ite(formIndex);
    const lastRowNo = iteList.length - 1;
    const pasteNewiteRowData = this.ite(formIndex).at(lastRowNo);
    pasteNewiteRowData.patchValue(copyRowData.value);
    pasteNewiteRowData.get('itemId')?.setValue('');
    this.inTankRDIDescSelected[formIndex][lastRowNo] = copyRowData.value.descPort;
    this.inTankRDISelected[formIndex][lastRowNo] = copyRowData.value.rdiPort;
    this.locationIteArray[formIndex][lastRowNo]= copyRowData.value.location;
    this.defaultProductMaterialCode[formIndex][lastRowNo] = copyRowData.value.scheduledProductMaterialCode;
    this.defaultProduct[formIndex][lastRowNo] = copyRowData.value.scheduledProduct;
    this.demandProductIteMaterialCode[formIndex][lastRowNo] = copyRowData.value.demandProductMaterialCode;
    this.demandProductIte[formIndex][lastRowNo] = copyRowData.value.demandProduct
    this.refContractLineItemsIte[formIndex][lastRowNo] = copyRowData.value.contractLineNo;
  }
  
  clearIteRow(formIndex:number, rowIndex:number){
    const iteList = this.ite(formIndex);
    if(iteList){
      const formGroup =  iteList.at(rowIndex)
      const itemId = formGroup.value.itemId;
      formGroup.reset();
      formGroup.get('itemId')?.setValue(itemId);
      const dropdownvalue = 'select';
      this.inTankRDIDescSelected[formIndex][rowIndex] = dropdownvalue;
      this.inTankRDISelected[formIndex][rowIndex] = dropdownvalue;
      this.locationIteArray[formIndex][rowIndex]= dropdownvalue;
      this.defaultProductMaterialCode[formIndex][rowIndex] = dropdownvalue;
      this.defaultProduct[formIndex][rowIndex] = dropdownvalue;
      this.demandProductIteMaterialCode[formIndex][rowIndex] = dropdownvalue;
      this.demandProductIte[formIndex][rowIndex] = dropdownvalue;
      this.refContractLineItemsIte[formIndex][rowIndex] = dropdownvalue;
    }
  }
  getDeskTypeValue(){
    let deskTypeValue = this.selectedDeskParameter;
    return (this.currentNominationNo !== this.nominationData?.nominationNo?.toUpperCase() && this.trNominationForPipeline?.editNomination && deskTypeValue) || (!this.trNominationForPipeline?.editNomination && deskTypeValue);
  }

  checkIfITEITWNomination(){
    this.isEveryLineItemIT = [];
    if(this.pipelineModalPopupData?.transportSystem === 'ITE' || this.pipelineModalPopupData?.transportSystem === 'ITW' ){
      this.pipelineModalPopupData?.headerToItemNav?.forEach((innerArray:any)=>{
        Array.isArray(innerArray) && innerArray.forEach((obj:any)=>{
          this.isEveryLineItemIT.push(obj.scheduleType);
        });
      });
    }
  }

  isITEITWNominationWithITScheduleType(){    
    return !this.originDestinationScheduleTypes.filter(item=>this.isEveryLineItemIT.includes(item)).length;
  }
}
