<div class="panel-wrapper">
  <ng-container>
    <div class="content-details row">
      @if (addDetails==true) {
        <div class="block-data col-4" >
          <div class="block-header"> nom key </div>
          <div class="block-value"> <strong> {{nominationDetails.originNomin[0]?.nominationKey}} </strong> </div>
        </div>
      }
      <div class="block-data col-4">
        <div class="block-header"> transport </div>
        <div class="block-value"> <strong> {{nominationDetails.originNomin[0]?.transportSystem}} </strong> </div>
      </div>
      <div class="block-data col-4">
        <div class="block-header"> status</div>
        <div class="block-value"> <strong> {{nominationDetails.originNomin[0]?.nominationItemStatus}} </strong> </div>
      </div>
    </div>
  </ng-container>

  @for (data of nominationDetails.originNomin; track data; let i = $index) {
    @if (i==0) {
      <div >
        <span>origin</span>
      </div>
    }
    <div class="panel-header-nom" (click)="collapseMenu($event)">
      <span class="icon-chevron-down dropdown-trigger"></span> {{data.locationId}} |  {{data.scheduledDate}} {{data.scheduledTime}}  <strong>{{data.scheduledQty}}</strong>
    </div>
    <div class="panel-content collapse" id="nomCollpase">
      <div class="content-details row">
        <div class="block-data col-4">
          <div class="block-header"> line key </div>
          <div class="block-value"> <strong> {{data.nominationKeyItem}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> line Status</div>
          <div class="block-value"> <strong> {{data.nominationItemStatus}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> s type </div>
          <div class="block-value"> <strong> {{data.scheduleType}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> doc ind </div>
          <div class="block-value"> <strong> {{data.referenceDocumentIndicator}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> ref do </div>
          <div class="block-value"> <strong> {{data.nominationReferenceDocument}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> ref Doc No </div>
          <div class="block-value"> <strong> {{data.nominationReferenceDocumentItem}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> scheduled product</div>
          <div class="block-value"> <strong> {{ data.scheduledMaterialName != null ? data.scheduledMaterialName : data.scheduledMaterial }} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> demand product</div>
          <div class="block-value"> <strong> {{data.demandMaterialName != null ? data.demandMaterialName : data.demandMaterial }} </strong> </div>
        </div>
      </div>
      @if (addDetails==true) {
        <div class="content-details row" >
          <div class="block-data col-4">
            <div class="block-header"> batch origin location</div>
            <div class="block-value"> <strong> {{data.batchOriginLocation}} </strong> </div>
          </div>
          <div class="block-data col-4">
            <div class="block-header"> valuation type origin location</div>
            <div class="block-value"> <strong> {{data.valuationTypeDestinationLoc}} </strong> </div>
          </div>
        </div>
      }
    </div>
  }

  @for (data of nominationDetails.destNomin; track data; let i = $index) {
    @if (i==0) {
      <div >
        <span>destination</span>
      </div>
    }
    <div class="panel-header-nom" (click)="collapseMenu($event)">
      <span class="icon-chevron-down dropdown-trigger"></span> {{data.locationId}} |  {{data.scheduledDate}} {{data.scheduledTime}}  <strong>{{data.scheduledQty}}</strong>
    </div>
    <div class="panel-content collapse" id="nomCollpase">
      <div class="content-details row">
        <div class="block-data col-4">
          <div class="block-header"> line key </div>
          <div class="block-value"> <strong> {{data.nominationKeyItem}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> line Status</div>
          <div class="block-value"> <strong> {{data.nominationItemStatus}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> s type </div>
          <div class="block-value"> <strong> {{data.scheduleType}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> doc ind </div>
          <div class="block-value"> <strong> {{data.referenceDocumentIndicator}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> ref do </div>
          <div class="block-value"> <strong> {{data.nominationReferenceDocument}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> ref Doc No </div>
          <div class="block-value"> <strong> {{data.nominationReferenceDocumentItem}} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> scheduled product</div>
          <div class="block-value"> <strong> {{ data.scheduledMaterialName != null ? data.scheduledMaterialName : data.scheduledMaterial }} </strong> </div>
        </div>
        <div class="block-data col-4">
          <div class="block-header"> demand product</div>
          <div class="block-value"> <strong> {{data.demandMaterialName != null ? data.demandMaterialName : data.demandMaterial }} </strong> </div>
        </div>
      </div>
      @if (addDetails==true) {
        <div class="content-details row" >
          <div class="block-data col-4">
            <div class="block-header"> batch origin location</div>
            <div class="block-value"> <strong> {{data.batchOriginLocation}} </strong> </div>
          </div>
          <div class="block-data col-4">
            <div class="block-header"> valuation type origin location</div>
            <div class="block-value"> <strong> {{data.valuationTypeDestinationLoc}} </strong> </div>
          </div>
        </div>
      }
    </div>
  }


</div>

