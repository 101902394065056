<div class="custom-views-manage-container" [class.expanded]="isExpanded">
  @for (view of nominationService.viewsList; track view; let i = $index) {
    <div class="each-custom-view-name">
      <button class="icon-btn favourite-icon add-font" (click)="defaultView(view)" alt="default icon" title="Set Default" [class.active]="view.isDefaultView"></button>
      <p class="view-name font-gotham-narrow-book" [class.active]="view.applied">{{view.name}}</p>
      <div class="actions-icon-container">
        <span class="apply-icon action-icons" (click)="applyDeleteView('apply',view)"><img
          src="../../../../../assets/icons/16_Circle_Checkmark.svg" [class.active]='view.applied'
        title="Apply" alt="apply icon"></span>
        <span class="edit-icon action-icons" (click)="editView(view.name)"> <img
        src="../../../../../assets/icons/Edit_CustomView.svg" title="Edit" alt="edit icon"> </span>
        <span class="delete-icon action-icons" (click)="applyDeleteView('delete',view)"> <img
        src="../../../../../assets/icons/16_Trashcan.svg" title="delete" alt="delete icon"> </span>
      </div>
    </div>
  }
</div>