<div class="more-menu">
  @if (!nominationTab) {
    @if (details) {
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    } @else {
      <span [ngClass]="[moremenuIconShow ? '' : '']">{{value}}</span>
    }
  }
  <div class="more-menu-dropdown">

    @if (moremenuIconShow && ( (details?.id!== '' && details?.status!== ''))) {
      <button class="ellipses-icon"
      (click)="moremwnu()"></button>
      @if (!isOpen) {
        <div class="dropdown-content" [ngClass]="[compactView ? 'compact-dropdown':'',(getRowIndex(params?.node) < 8 && !isDetailGrid) || (getRowIndex(params?.node) < 2 && isDetailGrid) ? '' : 'place-top']">
          @if (details?.status?.toLowerCase()==='unpublished' || details?.status?.toLowerCase()==='created' || details?.status?.toLowerCase()==='published') {
            <a
              class="dropdown-item create-nomination" (click)="createNomWithRef()">create nomination from
            reference</a>
          }
          @if (details?.status?.toLowerCase() ==='draft' || (!details?.status && !nominationTab)) {
            <a
            class="dropdown-item create-nomination" (click)="onCreateDraftNomination()">create nomination</a>
          }
          @if (details?.status?.toLowerCase()==='unpublished' || details?.status?.toLowerCase()==='created') {
            <a class="dropdown-item goToNominations"
            (click)="goToNominations()">go to nominations</a>
          }
          @if (details?.status) {
            <a class="dropdown-item seeInNominationPannel" (click)="seeInNominationPannel()">see
              in nomination
            pannel</a>
          }
          @if ((!(desk?.includes('ethanol') && nominationTab) && !(details?.status?.toLowerCase() ==='draft') && ((details?.status && isEditable && details?.modeOfTransport?.toLowerCase() !== 'pipeline')||(isEditable && (details?.sys_status?.toLowerCase() === 'unpublished' || details?.sys_status?.toLowerCase() === 'published'|| details?.sys_status?.toLowerCase() === 'multiple'|| params.data['sys_status']?.toLowerCase() === 'failed')))) || ethanolMoreMenuEditable) {
            <a class="dropdown-item edit-nomination" (click)="editNomination(details)">edit
            nomination</a>
          }
          @if (nominationTab && !lineItem) {
            <a class="dropdown-item create-nom-with-ref"
              (click)="createNomWithRef()">create nom. with
            reference</a>
          }
          @if (nominationTab && !lineItem && desk !== 'clean products + usec + florida desk') {
            <a class="dropdown-item contract-balancinf"
              (click)="contractBalancing()">contract
            balancing</a>
          }
          @if (nominationTab && !lineItem && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk || desk === PipelineUswcPNWDesk)) {
            <a class="dropdown-item contract-balancinf"
            (click)="vesselScheduleUpdate()" [ngClass]="{'disableVesselOption': disableVesselOption(details)}">vessel schedule update</a>
          }
          @if (nominationTab) {
            <a class="dropdown-item publish" [ngClass]="{'disablePublish': disablePublish}" (click)="publish()">publish</a>
          }
          @if ((details?.status && isEditable) || nominationTab) {
            <a class="dropdown-item delete"
            (click)="delete()">delete</a>
          }
          @if (nominationTab && !lineItem) {
            <a class="dropdown-item see-details" (click)="seeDetails()">see details</a>
          }
          <a class="dropdown-item see-details" (click)="openActivityTracker()"> see nomination activity </a>
          @if (!isDetailGrid) {
            <a class="dropdown-item see-details" (click)="openNominationComments()"> see nomination comments </a>
          }
        </div>
      }
    }
  </div>


</div>