@if (!this.alertDisabled) {
  <div class="notification-wrapper">
    <div class="blue-header">
      <p class="header-text">
        <span>
          <span class="header-boldText">{{alertDesk | lowercase}}</span>
          <img src="../../../../assets/icons/icon-chevron-grey-r.svg" alt="Directional Indicator Right">
          <span class="header-boldText">{{alertTerminal | lowercase}}</span>
          <img src="../../../../assets/icons/icon-chevron-grey-r.svg" alt="Directional Indicator Right">
          <span class="header-boldText">{{alertMaterial | lowercase}}</span>
        </span>
      </p>
      <!--<button style="border:none;background:none;cursor:pointer;" matTooltip="edit rule"
      [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"> <img
      src="../../assets/icons/icon-alerts-pencil.svg" alt="edit-icon"
    (click)="gotoAlertNotifications()"></button>-->
  </div>
  <div class="text-body">
    @if (this.cardAlert.ruleType === 'Reconciliation') {
      <p class="body-boldText">{{this.alertBoldText}}</p>
    }
    <p>
      @if (this.cardAlert.ruleType === 'Inventory' || this.cardAlert.ruleType === 'ActualDemand') {
        <span class='body-boldText'>{{this.alertBoldText}}</span>
      }
      {{this.alertIndicatorText}} @if (this.cardAlert.ruleType === 'Reconciliation') {
      <span class="control-links"
      >see details </span>
    }
  </p>
  <p class="dateText">{{this.cardDateText}}</p>
</div>
<div class="button-row">
  <div [ngClass]="{'rightAlign': !this.showDismiss}">
    <p class="control-links" (click)="this.openDismissModal()">dismiss
      <span><img src="../../../../assets/icons/icon_circle_x.svg" alt="right arrow" class="blue-x"></span>
    </p>
  </div>
  @if (this.showDismiss) {
    <div>
      <p class="control-links" (click)="takeUserToIssue()">take me to issue <span><img
      src="../../../../assets/icons/icon-chevron-blue-r.svg" alt="right arrow"></span></p>
    </div>
  }
</div>
</div>
}