import { ToggleSwitchComponent } from './../toggle-switch/toggle-switch.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { FilterPanelComponent } from 'src/app/core/components/filter-panel/filter-panel.component';
import { NextPage } from '@cvx/nextpage';
import { NpModalComponent } from './np-modal/np-modal.component';
import { ChipRendererComponent } from './ag-grid-renderers/chip-renderer/chip-renderer.component';
import { NpNotificationComponent } from './np-notification/np-notification.component';
import { CustomHeaderComponent } from 'src/app/nominations/custom-header.component';
import { DatePickerComponent } from './datapicker/datepicker.component';
import { NpHeaderComponent } from 'src/app/core/components/np-header/np-header.component';
import { CreateNominationsSidePanelComponent } from '../../core/components/create-nominations-side-panel/create-nominations-side-panel.component';
import { CreateTreminalNominationComponent } from '../../core/components/create-treminal-nomination/create-treminal-nomination.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { MoreMenuComponent } from './more-menu/more-menu.component';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { EditNominationComponent } from 'src/app/core/components/edit-nomination/edit-nomination.component';
import { DateChangeConfirmModelComponent } from 'src/app/core/components/date-change-confirm-model/date-change-confirm-model.component';
import { CustomDropdownComponent } from '../custom-dropdown/custom-dropdown.component';
import { CreateNominationEthanolComponent } from 'src/app/core/components/create-nomination-ethanol/create-nomination-ethanol.component';
import { NumberCellEditorComponent } from './number-cell-editor/number-cell-editor.component';
import { ContractBalancingFilterComponent } from 'src/app/core/components/contract-balancing-filter/contract-balancing-filter.component';
import { EthanolMoreMenuComponent } from './ethanol-more-menu/ethanol-more-menu.component';
import { CreateNominationEthanolSidePanelComponent } from 'src/app/core/components/create-nomination-ethanol-side-panel/create-nomination-ethanol-side-panel.component';
import { CreateAlertsComponent } from 'src/app/core/components/create-alerts/create-alerts.component';
import { DenyEditAlertComponent } from '../../core/components/create-alerts/deny-edit-alert/deny-edit-alert.component';;
import { MatRadioModule } from '@angular/material/radio';
import { CreatePipelineNominationComponent } from 'src/app/core/components/create-pipeline-nomination/create-pipeline-nomination.component';
import { CreateNominationPipelineSidePanelComponent } from 'src/app/core/components/create-nomination-pipeline-side-panel/create-nomination-pipeline-side-panel.component';
import { PipelineMoreMenuComponent } from './pipeline-more-menu/pipeline-more-menu.component';
import { BCPDashboardFilterComponent } from 'src/app/core/components/bcp-dashboard-filter/bcp-dashboard-filter.component';
import { DeleteAlertsComponent } from 'src/app/core/components/delete-alerts/delete-alerts.component';
import { DenyDeleteAlertComponent } from '../../core/components/delete-alerts/deny-delete-alert/deny-delete-alert.component';
import { RestoreAlertsComponent } from 'src/app/core/components/restore-alerts/restore-alerts.component';
import { VesselScheduleUpdateComponent } from '../../core/components/vessel-schedule-update/vessel-schedule-update.component';
import { LocationsFilterComponent } from '../..//core/components/admin-filter/locations-filter/locations-filter.component';
import { DesksFilterComponent } from '../../../app/core/components/admin-filter/desks-filter/desks-filter.component';
import { MaterialMappingFilterComponent } from '../../core/components/admin-filter/material-mapping-filter/material-mapping-filter.component';
import { SubscriberAlertsComponent } from 'src/app/core/components/subscriber-alerts/subscriber-alerts.component';
import { CreateAlertNotificationsSidePanelComponent } from '../../core/components/create-alert-notifications-side-panel/create-alert-notifications-side-panel.component';
import { WorkspaceAlertsSummaryCardComponent } from 'src/app/core/components/workspace-alerts-summary-card/workspace-alerts-summary-card.component';
import { DismissWorkspaceAlertComponent } from 'src/app/core/components/dismiss-workspace-alert/dismiss-workspace-alert.component';
import { FilterDeskBlockPanelComponent } from 'src/app/core/components/create-alert-notifications-side-panel/filter-desk-block-panel/filter-desk-block-panel.component';
import { HeaderHideListComponent } from '../header-hide-list/header-hide-list.component';
import { SaveAlertsComponent } from 'src/app/core/components/save-alerts/save-alerts.component';
import { GridAlertTooltipRendererComponent } from 'src/app/core/components/create-alert-notifications-side-panel/grid-alert-tooltip-renderer/grid-alert-tooltip-renderer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SettingsAlertRulesFilterComponent } from 'src/app/core/components/settings-alert-rules-filter/settings-alert-rules-filter.component';
import { SettingsParametersFilterComponent } from 'src/app/core/components/settings-parameters-filter/settings-parameters-filter.component';
import { TransportFilterComponent } from '../../../app/core/components/admin-filter/transport-filter/transport-filter.component';
import { RestrictedInputLengthCellEditorComponent } from './ag-grid-renderers/restricted-input-length-cell-editor-component/restricted-input-length-cell-editor.component';
import { CellInfoHoverComponent } from './cell-info-hover/cell-info-hover.component';
import { MoreMenuNonCPComponent } from './more-menu-nonCP/more-menu-nonCP.component';
import {SearchPanelComponent} from 'src/app/core/components/search-panel/search-panel.component';
import { MoreMenuSearchComponent } from './more-menu-search/more-menu-search.component'
import { PushMcatModalComponent } from 'src/app/core/components/push-mcat-modal/push-mcat-modal.component';
import { InventoryFieldComponent } from 'src/app/core/components/create-alerts/rule-creation-fields/inventory-field/inventory-field.component';
import { ReconciliationFieldComponent } from 'src/app/core/components/create-alerts/rule-creation-fields/reconciliation-field/reconciliation-field.component';
import { DemandFieldComponent } from 'src/app/core/components/create-alerts/rule-creation-fields/demand-field/demand-field.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateNominationCcaComponent } from 'src/app/core/components/create-nomination-cca/create-nomination-cca.component';
import { DuplicateCargoPopupCcaComponent } from 'src/app/core/components/duplicate-cargo-popup-cca/duplicate-cargo-popup-cca.component';
import { MoreMenuCcaCpComponent } from './more-menu-cca-cp/more-menu-cca-cp.component';
import { CreateCargoCcaPopupComponent } from 'src/app/core/components/create-cargo-cca-popup/create-cargo-cca-popup.component';
import { MoreMenuCargoParentComponent } from './more-menu-cargo-parent/more-menu-cargo-parent.component';
import { MarketElasticityFilterComponent } from 'src/app/core/components/market-elasticity-filter/market-elasticity-filter.component';
import { CreateNominationElasticitySidePanelComponent } from 'src/app/core/components/create-nomination-elasticity-side-panel/create-nomination-elasticity-side-panel.component';
import { NominationPanelComponent } from 'src/app/core/components/create-nomination-elasticity-side-panel/nomination-child-panels/nomination-panel/nomination-panel.component';
import { FilterFormComponent } from 'src/app/core/components/create-nomination-elasticity-side-panel/nomination-child-panels/filter-form/filter-form.component';
import { ProductViewComponent } from 'src/app/core/components/create-nomination-elasticity-side-panel/nomination-child-panels/product-view/product-view.component';
import { NomTooltipComponent } from 'src/app/core/components/create-nomination-elasticity-side-panel/nomination-child-panels/nom-tooltip/nom-tooltip.component';
import { TerminalViewComponent } from 'src/app/core/components/create-nomination-elasticity-side-panel/nomination-child-panels/terminal-view/terminal-view.component';
import { NominationEditDeleteConfirmModelComponent } from '../nomination-edit-delete-confirm-model/nomination-edit-delete-confirm-model.component';
import { CreateRefineryNominationComponent } from 'src/app/core/components/create-refinery-nomination/create-refinery-nomination.component';
import { NpLoaderComponent } from '../np-loader/np-loader.component';
import { FlipCardComponent } from '../flip-card/flip-card.component';
import { GrayHeaderComponent } from '../hide-headers/gray-header/gray-header.component';
import { LightHeaderComponent } from '../hide-headers/light-header/light-header.component';
import { AddTagComponent } from '../tags/add-tag/add-tag.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CardBackComponent } from 'src/app/core/components/information-panel/card-back/card-back.component';
import { CardFrontComponent } from 'src/app/core/components/information-panel/card-front/card-front.component';
import { InfoContentComponent } from 'src/app/core/components/information-panel/info-content/info-content.component';
import { MatTableModule } from '@angular/material/table';
import { PopoutWindowModule } from 'angular-popout-window';
import { InformationPanelComponent } from 'src/app/core/components/information-panel/information-panel.component';
import { ActivityTrackernComponent } from 'src/app/core/components/activity-tracker/new AT/activity-trackern/activity-trackern.component';
import { ActivityTableComponent } from 'src/app/core/components/activity-tracker/old AT/activity-table/activity-table.component';
import { ActivityTrackerComponent } from 'src/app/core/components/activity-tracker/old AT/activity-tracker.component';
import { CommentPanelComponent } from 'src/app/core/components/comments/comment-panel/comment-panel.component';
import { CommentCardComponent } from 'src/app/core/components/comments/comment-card/comment-card.component';
import { AddCommentComponent } from 'src/app/core/components/comments/comment-panel/add-comment/add-comment.component';
import { MatButtonModule } from '@angular/material/button';
import { NameHeaderComponent } from '../hide-headers/name-header/name-header.component';
import { ReusableDropdownComponent } from '../reusable-dropdown/reusable-dropdown.component';
import { ChangeListDisplayComponent } from '../change-list-display/change-list-display.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RuleFilterComponent } from 'src/app/core/components/rule-filter/rule-filter.component';
import { WhiteHeaderComponent } from '../hide-headers/white-header/white-header.component';
import { RulePipe } from 'src/app/core/components/rule-filter/rule.pipe'
import { LightBlueHeaderComponent } from '../hide-headers/light-blue-header/light-blue-header.component';
import { UserVacationComponent } from '../user-vacation/user-vacation.component';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepickerModule } from '@angular/material/datepicker';
import { ContractDynamicDealsFilterComponent } from 'src/app/core/components/contract-dynamic-deals-filter/contract-dynamic-deals-filter.component';
import { CbGlobalSearchComponent } from '../cb-global-search/cb-global-search.component';
import { MoreMenuMktElsNomComponent } from './more-menu-mkt-els-nom/more-menu-mkt-els-nom.component';
import { InTransitMoreMenuComponent } from './more-menu-in-transit/more-menu-in-transit.component';

import { CustomDialogBoxComponent } from 'src/app/shared/custom-dialog-box/custom-dialog-box.component';
import { MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    ContractBalancingFilterComponent,
    BCPDashboardFilterComponent,
    LocationsFilterComponent,
    DesksFilterComponent,
    MaterialMappingFilterComponent,
    TransportFilterComponent,
    FilterPanelComponent,
    NpModalComponent,
    ChipRendererComponent,
    NpNotificationComponent,
    CustomHeaderComponent,
    DatePickerComponent,
    NpHeaderComponent,
    CreateNominationsSidePanelComponent,
    CreateNominationPipelineSidePanelComponent,
    CreateTreminalNominationComponent,
    CreateAlertsComponent,
    DenyEditAlertComponent,
    DeleteAlertsComponent,
    DenyDeleteAlertComponent,
    InventoryFieldComponent,
    ReconciliationFieldComponent,
    DemandFieldComponent,
    SubscriberAlertsComponent,
    RestoreAlertsComponent,
    SaveAlertsComponent,
    SelectDropdownComponent,
    EditNominationComponent,
    DateChangeConfirmModelComponent,
    CustomDropdownComponent,
    ToggleSwitchComponent,
    CreateNominationEthanolComponent,
    NumberCellEditorComponent,
    CreateNominationEthanolSidePanelComponent,
    CreatePipelineNominationComponent,
    CreateNominationCcaComponent,
    CreateAlertNotificationsSidePanelComponent,
    WorkspaceAlertsSummaryCardComponent,
    DismissWorkspaceAlertComponent,
    FilterDeskBlockPanelComponent,
    HeaderHideListComponent,
    GridAlertTooltipRendererComponent,
    SettingsAlertRulesFilterComponent,
    SettingsParametersFilterComponent,
    VesselScheduleUpdateComponent,
    RestrictedInputLengthCellEditorComponent,
    CellInfoHoverComponent,
    SearchPanelComponent,
    MoreMenuComponent,
    EthanolMoreMenuComponent,
    PipelineMoreMenuComponent,
    MoreMenuNonCPComponent,
    MoreMenuSearchComponent,
    MoreMenuCcaCpComponent,
    PushMcatModalComponent,
    CreateCargoCcaPopupComponent,
    PushMcatModalComponent,
    DuplicateCargoPopupCcaComponent,
    MoreMenuCargoParentComponent,
    MarketElasticityFilterComponent,
    CreateNominationElasticitySidePanelComponent,
    NominationPanelComponent,
    FilterFormComponent,
    ProductViewComponent,
    NomTooltipComponent,
    TerminalViewComponent,
    NominationEditDeleteConfirmModelComponent,
    CreateRefineryNominationComponent,

    NpLoaderComponent,
    FlipCardComponent,
    GrayHeaderComponent,
    LightHeaderComponent,
    MoreMenuCargoParentComponent,
    AddTagComponent,
    CardBackComponent,
    CardFrontComponent,
    InfoContentComponent,
    InformationPanelComponent,
    ActivityTrackernComponent,
    ActivityTableComponent,
    ActivityTrackerComponent,
    CommentPanelComponent,
    AddCommentComponent,
    CommentCardComponent,

    CustomDialogBoxComponent,
  

    CustomDialogBoxComponent,
  

    MoreMenuCargoParentComponent,
    AddTagComponent,
    CardBackComponent,
    CardFrontComponent,
    InfoContentComponent,
    InformationPanelComponent,
    ActivityTrackernComponent,
    ActivityTableComponent,
    ActivityTrackerComponent,
    CommentPanelComponent,
    AddCommentComponent,
    CommentCardComponent,
    MoreMenuMktElsNomComponent,
    UserVacationComponent, 
    ContractDynamicDealsFilterComponent,
    CbGlobalSearchComponent,
    MoreMenuMktElsNomComponent,
    InTransitMoreMenuComponent,
    ReusableDropdownComponent,
    ChangeListDisplayComponent,
    RuleFilterComponent,
    WhiteHeaderComponent,
    RulePipe,
    LightBlueHeaderComponent,
    InTransitMoreMenuComponent,
    ReusableDropdownComponent,
    ChangeListDisplayComponent,
    RuleFilterComponent,
    WhiteHeaderComponent,
    RulePipe,
    LightBlueHeaderComponent,
    ],



  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NextPage,
    DateRangePickerComponent,
    MatRadioModule,
    MatTooltipModule,
    MatCheckboxModule,
    AgGridModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    PopoutWindowModule,
    NameHeaderComponent,
    MatButtonModule,
    MatDialogModule,
    DragDropModule,
    MatDialogModule,
    DragDropModule,
  ],
  providers: [DatePipe, 
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
  exports: [
    CommonModule,
    CustomHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ContractBalancingFilterComponent,
    BCPDashboardFilterComponent,
    LocationsFilterComponent,
    DesksFilterComponent,
    MaterialMappingFilterComponent,
    TransportFilterComponent,
    FilterPanelComponent,
    DatePipe,
    NpModalComponent,
    ChipRendererComponent,
    NpNotificationComponent,
    NpHeaderComponent,
    CreateNominationsSidePanelComponent,
    CreateNominationPipelineSidePanelComponent,
    CreateTreminalNominationComponent,
    CreateAlertsComponent,
    DenyEditAlertComponent,
    DeleteAlertsComponent,
    DenyDeleteAlertComponent,
    SubscriberAlertsComponent,
    RestoreAlertsComponent,
    SaveAlertsComponent,
    SelectDropdownComponent,
    MoreMenuComponent,
    EditNominationComponent,
    DateChangeConfirmModelComponent,
    CustomDropdownComponent,
    CreateNominationEthanolComponent,
    ToggleSwitchComponent,
    CreateNominationEthanolComponent,
    CreateNominationEthanolSidePanelComponent,
    CreatePipelineNominationComponent,
    CreateNominationCcaComponent,
    VesselScheduleUpdateComponent,
    MoreMenuNonCPComponent,
    MoreMenuCcaCpComponent,
    SearchPanelComponent,
    CreateAlertNotificationsSidePanelComponent,
    DismissWorkspaceAlertComponent,
    FilterDeskBlockPanelComponent,
    HeaderHideListComponent,
    PushMcatModalComponent,
    CreateCargoCcaPopupComponent,
    DuplicateCargoPopupCcaComponent,
    MoreMenuCargoParentComponent,
    MarketElasticityFilterComponent,
    CreateNominationElasticitySidePanelComponent,
    NominationPanelComponent,
    FilterFormComponent,
    ProductViewComponent,
    NomTooltipComponent,
    TerminalViewComponent,
    NominationEditDeleteConfirmModelComponent,
    CreateRefineryNominationComponent,

    NpLoaderComponent,
    FlipCardComponent,
    GrayHeaderComponent,
    LightHeaderComponent,
    AddTagComponent,
    CardBackComponent,
    CardFrontComponent,
    InfoContentComponent,
    InformationPanelComponent,
    ActivityTrackernComponent,
    ActivityTableComponent,
    ActivityTrackerComponent,
    CommentPanelComponent,
    AddCommentComponent,
    CommentCardComponent,
    AddTagComponent,
    CardBackComponent,
    CardFrontComponent,
    InfoContentComponent,
    InformationPanelComponent,
    ActivityTrackernComponent,
    ActivityTableComponent,
    ActivityTrackerComponent,
    CommentPanelComponent,
    AddCommentComponent,
    CommentCardComponent,

    CustomDialogBoxComponent,
    


    CustomDialogBoxComponent,
    

    MoreMenuMktElsNomComponent,
    UserVacationComponent,
    ContractDynamicDealsFilterComponent,
    CbGlobalSearchComponent,

    ReusableDropdownComponent,
    ChangeListDisplayComponent,
    RuleFilterComponent,
    WhiteHeaderComponent,
    LightBlueHeaderComponent,
    AgGridModule
  ],
})
export class SharedModule {}
