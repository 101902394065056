import { selectNominationDetail } from './../../../nominations/store/selector/nomination.selector';
import { EthanolTruckService } from './../../../services/ethanol-truck.service';
import { takeUntil, Subject } from 'rxjs';
import { selectEthanolTRFiltersResponse, getUserDetails, selectPipelineSwitchView, selectPipelineTRFiltersResponse, selectPipelineNomFiltersResponse, selectDesk, selectedPipelineCompactView, selectUDMScreen } from './../../store/selector/app.selector';
import { DatePipe } from '@angular/common';
import { NominationService } from './../../../services/nomination.service';
import { ToastService } from './../../../services/toast.service';
import { Router } from '@angular/router';
import { Utilities } from './../../utilities/utilities';
import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ColumnApi, GridApi, ICellRendererParams } from 'ag-grid-community';
import { select, Store } from '@ngrx/store';
import { initialisePipelineNomFilters, invokeTrPipelineNominationList, savePipelineNomFiltersUpdated, savePipelineTRFiltersUpdated, updateNomination } from '../../store/action/app.action';
import { AppState } from '../../store/reducer/app.reducer';
import { UDMDesk, deskName } from '../../constants/terminal-replenishment.constant';
import { FilterPanelComponent } from 'src/app/core/components/filter-panel/filter-panel.component';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { MdmService } from 'src/app/services/mdm.service';
@Component({
  selector: 'app-pipeline-more-menu',
  templateUrl: './pipeline-more-menu.component.html',
  styleUrls: ['./pipeline-more-menu.component.scss']
})
export class PipelineMoreMenuComponent implements ICellRendererAngularComp {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public isOpen: any = false;
  public value!: any;
  public nominationTab: any;
  public isEditable = false;
  public moremenuIconShow = false;
  public lineItem = false;
  public isDraft = false;
  public rowStatus: any;
  public params: any;
  public createdBy = '';
  pipelineSwitch: any;
  getEhtanolFilterResponse: any = [];
  public filterList: any = {}
  terminalOweneshipValue: any;
  userEmail: any;
  userDetails$ = this.store.pipe(select(getUserDetails));
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))
  selectSwitchView$ = this.store.pipe(select(selectPipelineSwitchView));
  selectPipelineTRFiltersResponse$ = this.store.pipe(select(selectPipelineTRFiltersResponse));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  @ViewChild('FilterPanelComponent') filterPanelComponent!: FilterPanelComponent;
  pipelineNomFilterResponse: any;
  isNomPresent = false;
  pipelineFilterResponse: any;
  payLoad: any;
  public desk: any;
  tempVarSPNFR : any;
  public nomDetail$ = this.store.pipe(select(selectNominationDetail));
  SelectedDesk = '';
  SelectedDeskParam = '';
  SelectedDeskId : any = ''
  selectedDataContent: any = '';
  SelectedDeskName: any = '';
  selectedDesk$ = this.store.pipe(select(selectDesk));
  public gridApi2!: GridApi;
  gridColumnApi2!: ColumnApi;
  selectedPipelineCompactView$ = this.store.pipe(select(selectedPipelineCompactView))
  compactView: boolean = false;
  isCargo: any = false;
  public rowIndex: any;
  isCCAView: boolean = false;
  PipelineUswcPNWDesk: string = UDMDesk.PipelineUswcPNWDesk;
  PipelineUsecFloridaDesk: string = UDMDesk.PipelineUsecFloridaDesk;
  PipelineUsecRichmondDesk: string = UDMDesk.PipelineUsecRichmondDesk;
  PipelineUsecpascagoulaDesk: string = UDMDesk.PipelineUsecPascagoulaDesk;
  vesselScheduleUpdateData : any;
  constructor(
    private utilities: Utilities,
    private router: Router,
    private toasterService: ToastService,
    private nominationService: NominationService,
    private datePipe: DatePipe,
    private store: Store,
    private ethanolTruckService: EthanolTruckService,
    private appState: Store<AppState>,
    private myMonitoringService: MyMonitoringService,
    private data: NominationService,
    private mdmService : MdmService) {

  }

  agInit(params: ICellRendererParams<any, any, any>): void {
    this.params = params;
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    if (this.params?.colDef?.colId?.split('_').pop().toLowerCase() === 'daysofsupply' || this.params?.colDef?.colId?.split('_').pop().toLowerCase() === 'whatifdos') {
      this.value = this.utilities.formatDaysOfSupplyValue(params?.value);
    } else {
      this.value = (!isNaN(params?.value) && (params?.value !== "")) ? this.utilities.formatNumberPipeline(params?.value) : params?.value;
    }

    //console.log(" this.value " + this.value);
    //203410990_PASCAGOU_nominationId_itemStatus_Child: "published"
    const fieldType = params?.colDef?.field;
    let fieldDataName = fieldType!.split('_')[3] ? fieldType!.split('_').slice(0, 3).join('_') : fieldType;

    this.rowStatus = params.data[fieldDataName + '_itemStatus_Child'] ?? params.data[fieldDataName + '_status_Child'];
    this.isDraft = this.rowStatus === 'draft';
    this.nominationTab = this.router.url === '/nominations';
    this.isEditable = params.data[fieldDataName + '_isEditable_Child'];
    this.createdBy = params.data[fieldDataName + '_createdBy_Child'];
    this.rowIndex = params.node.rowIndex;

    this.vesselScheduleUpdateData = {
      'modeOfTransportDesc' : params.data[fieldDataName + '_modeOfTransportDesc_Child'],
      'tdVehicleIdentifier' : params.data[fieldDataName + '_tdVehicleIdentifier_Child'],
      'nominationItemStatus' : params.data[fieldDataName + '_nominationItemStatus_Child'],
      'rowIndex' : this.rowIndex

    }

    // this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
    //   next: (response: any) => {
    //     this.getEhtanolFilterResponse = response
    //     response?.ethanolFilterValues?.forEach((element: any) => {
    //       this.filterList[element.headerName] = element.list
    //     });
    //     let terminalOweneshipList = this.getEhtanolFilterResponse?.ethanolFilterValues?.filter((ele: any) => ele.headerName === 'terminal ownership')[0]
    //     this.terminalOweneshipValue = terminalOweneshipList?.list.filter((ele: any) => ele.checked)[0]
    //   }, error: (err: any) => {
    //     console.log(err);
    //   }, complete: () => { }

    // })

    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

    this.selectSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.pipelineSwitch = response.pipelineSwitchView;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    //subscribing to get filter data and forming payload for sidepanel api
    this.selectPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.pipelineFilterResponse = JSON.parse(JSON.stringify(response));
        if (response || response.isPipelineFilterUpdated) {
          this.payLoad = {
            desk: "pipeline",
            modeOfTransport: ['pipeline'],
            fromDate: response.pipelineFromDate,
            toDate: response.pipelineToDate,
            terminal: this.filterResponse(response, 'terminal'),
            product: this.filterResponse(response, 'product'),
            status: "All"
            // carrier: this.filterResponse(response, 'carrier'),
            // supplier: this.filterResponse(response, 'supplier')
          };
        }
      },

    })
    this.selectedPipelineCompactView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.pipelineCompactView) {
            this.compactView = true
          }
          else {
            this.compactView = false
          }
        },
        error: (err: any) => {
        },
        complete: () => { },
      });
    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isPipelineNomFilterUpdated) {
          this.pipelineNomFilterResponse = response;
        }
      },
      error: (err: any) => { },
      complete: () => { }

    });

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.desk = response.selectedDesk;
          this.SelectedDesk = response.selectedDesk;
          this.SelectedDeskParam = response.selectedDeskParam;
          this.SelectedDeskId = response.selectedDeskId;
          this.SelectedDeskName = this.desk.split('+')[this.desk.split('+').length -1];
          this.isCargo = (this.desk?.toLowerCase() === UDMDesk.PipelineUsecFloridaDesk || this.desk?.toLowerCase() === UDMDesk.PipelineUswcPNWDesk || this.desk === UDMDesk.PipelineUsecRichmondDesk || this.desk === UDMDesk.PipelineUsecPascagoulaDesk);
          this.isCCAView = this.utilities.checkIfCCAView(this.desk);
          if(this.isCCAView){
            this.value = (!isNaN(params?.value) && (params?.value !== "")) ? this.utilities.formatNumberCCA(params?.value) : params?.value;
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    //subscribing to get filter data and forming payload for sidepanel api
    this.selectPipelineTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.pipelineFilterResponse = JSON.parse(JSON.stringify(response));
        if (response || response.isPipelineFilterUpdated) {
          this.payLoad = {
            desk: this.isCargo ? "pipeline" : "newPipeline",
            modeOfTransport: ['pipeline'],
            fromDate: response.pipelineFromDate,
            toDate: response.pipelineToDate,
            terminal: this.filterResponse(response, 'terminal'),
            product: this.filterResponse(response, 'product'),
            status: "All"
            // carrier: this.filterResponse(response, 'carrier'),
            // supplier: this.filterResponse(response, 'supplier')
          };
        }
      },

    })
    if (this.SelectedDesk) {
      const deskOj = { deskValue: this.SelectedDesk, deskParameter: this.SelectedDeskParam, id: this.SelectedDeskId  };
    } else {
      this.SelectedDesk = "Select one";
    }

    this.moremenuIconShow = this.isMoreMenuVisible(this.params)
  }

  moremwnu() {
    this.isOpen = !this.isOpen;
  }

  isMoreMenuVisible(params: any) {
    let isStatusPresent = ['unpublished', 'published'].includes(this.rowStatus?.toLowerCase());
    let isStatusDPresent = this.rowStatus?.toLowerCase() === 'draft';
    const fieldType = params?.colDef?.field;
    let nomField = fieldType.split('_')[3] ? fieldType.split('_').slice(0, 3).join('_') : fieldType;
    let nomFieldData = fieldType.split('_')[3] ?? fieldType.split('_')[2]
    const nominationVolArr = ['nominationvolume', 'receiptvolume', 'deliveryvolume'];

    let isNomVolColumn = nominationVolArr.includes(nomFieldData.toLowerCase());
    let checkisMultipleNomId = false;
    if (isNomVolColumn) {
      checkisMultipleNomId = params.data[`${nomField}`] === "multiple";
    }

    let isMultipleRow = params.data[params.colDef.field] === "multiple";




    // For highlighting draft, published and unpublished nominations in different colors ignoring negative nominations
    this.isNomPresent = (isStatusPresent || (isStatusDPresent && params?.colDef?.colId?.split('_').pop()?.toLowerCase() === 'whatif')) &&
      !isMultipleRow &&
      (
        ((params?.colDef?.colId?.split('_').pop()?.toLowerCase() === 'nominationvolume' || params?.colDef?.colId?.split('_').pop()?.toLowerCase() === 'receiptvolume' || params?.colDef?.colId?.split('_').pop()?.toLowerCase() === 'deliveryvolume') && params.data[`${nomField}`] !== "multiple") ||
        (params?.colDef?.colId?.split('_').pop()?.toLowerCase() === 'whatif' && isStatusDPresent)
      ) &&
      params?.data[`${nomField}_scheduleType_Child`] !== 'O';
    return this.isCase(params, isMultipleRow, checkisMultipleNomId)

  }
  isCase(params: any, isMultipleRow: any, checkisMultipleNomId: any) {
    const nominationArr = ['nominationvolume', 'receiptvolume', 'deliveryvolume'];
    let isStatusPresent = ['unpublished', 'published'].includes(this.rowStatus?.toLowerCase());
    let isStatusDPresent = this.rowStatus?.toLowerCase() === 'draft';
    const fieldType = params?.colDef?.field;
    let checkIfMultipleChildRows = params.data["date"] === "" && params.data["scheduledDate"] !== "";
    let checkIfNoMultipleChildRows = params.data["date"] !== "" && !params.data["scheduledDate"];
    const getDate = params?.data?.date === "" ? params?.data?.scheduledDate : params?.data?.date;
    let isNomColumn = nominationArr.includes(params?.colDef?.colId?.split('_').pop()?.toLowerCase());

    let fieldDataName = fieldType.split('_').slice(0, 2).join('_');
    const receiptIdVal = params?.data[fieldDataName + '_' + 'ReceiptId'];
    const deliveryIdVal = params?.data[fieldDataName + '_' + 'DeliveryId'];
    const nominationIdVal = params?.data[fieldDataName + '_' + 'nominationId'];
    if (params?.colDef?.colId?.split('_').pop()?.toLowerCase() === 'whatif') {
      return isStatusDPresent && (new Date(getDate).getTime() >= new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) && !isMultipleRow;
    }
    else if (receiptIdVal === 'multiple' || deliveryIdVal === 'multiple' || nominationIdVal === 'multiple') {
      return false;
    }  else {
      const colId = params?.colDef?.colId?.split('_').pop()?.toLowerCase();
      const nominationId_nominationItemStatus = params?.data[fieldDataName + '_' + 'nominationId' + '_' + "nominationItemStatus" + '_' + 'Child'];
      const receiptId_nominationItemStatus = params?.data[fieldDataName + '_' + 'ReceiptId' + '_' + "nominationItemStatus" + '_' + 'Child'];
      const deliveryId_nominationItemStatus = params?.data[fieldDataName + '_' + 'DeliveryId' + '_' + "nominationItemStatus" + '_' + 'Child'];
      return isStatusPresent && ((colId === "nominationvolume" && nominationId_nominationItemStatus !== "5") || (colId === "receiptvolume" && receiptId_nominationItemStatus !== "5") || (colId === "deliveryvolume" && deliveryId_nominationItemStatus !== "5")) && isNomColumn && (!isMultipleRow && checkIfMultipleChildRows || !isMultipleRow && checkIfNoMultipleChildRows && !checkisMultipleNomId);
    }
  }
  closeNominationsPanel() {
    this.nominationService.sidePanel$.next(false);
  }

  async onCreateDraftNomination(actionType?: any) {
    const event = { rowIndex: this.params?.node?.rowIndex, column: { colDef: { field: this.params?.colDef?.field, colId: this.params?.column?.colId } } }
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);
    const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    pipelineNomination["screenName"] = 'side-panel-tr';
    if (actionType === 'edit') {
      pipelineNomination["editNomination"] = true;
      this.nominationService.ccaEditNomination = true
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'onCreatePipelineNominationFromTR',
      type: '',
      values: pipelineNomination
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);
    this.closeNominationsPanel();
  }

  seeInNominationPannel() {
    this.nominationService.sidePanel$.next(true);
    this.nominationService.pipelineSidePanelData$.next(this.params)
  }

  async delete(){
    const nominationDetails: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
   // To get number of nomkeys for nomination
   let nomKey : Array<string> = [];
   let scheduleType : Array<string> = [];
   let scheduleTypeOriginCount =0;
   let scheduleTypeDestinationCount =0;
   nominationDetails.headerToItemNav.forEach((elem: any) => {
     elem.forEach((ele: any) => {
       const obj = {
         nominationKey:ele.nominationKey,
         scheduledtype : ele.scheduleType
        }
        scheduleType.push(obj.scheduledtype);
        if(!nomKey.includes(ele.nominationKey)){
         nomKey.push(obj.nominationKey);
        }
     })
   })
   scheduleType.forEach(element => {
    if(element=='O'){
      scheduleTypeOriginCount=scheduleTypeOriginCount+1;
    }
    if(element=='D'){
      scheduleTypeDestinationCount = scheduleTypeDestinationCount+1;
    }
  });
  if(nomKey.length>1 || scheduleTypeOriginCount>1 || scheduleTypeDestinationCount>1){
    let action = 'multiplelineitems';
    this.data.popupAction("multiplelineitems");
    this.mdmService.invokeReplenishmentToggleMethod(true);
   }
   else{
    let action = 'delete';
    this.data.popupAction("delete");
    this.mdmService.invokeReplenishmentToggleMethod(true);
    this.mdmService.actionConfirmed$.subscribe((actionConfirmed)=>{
      if(actionConfirmed){
        if(action !== ''){
          this.deleteAfterConfirm();
          this.mdmService.actionConfirm(false);
          this.mdmService.invokeReplenishmentToggleMethod(false);
          action = '';
        }

      }
    });
   }
  }

  async deleteAfterConfirm() {
    const nominationDetails: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    const reqPayload = [];
    const deletedItemNomKey = nominationDetails.nominationKey;

// deleting entire nomination
    let requestBody =
    {
      id: nominationDetails.id,
      modeOfTransportDesc: nominationDetails.modeOfTransportDesc,
      deskFilter: "pipelinedesk",
      sys_status: deletedItemNomKey ? "InProcess" : nominationDetails.sys_status,
      deletionIndicator: "X"
    };

    reqPayload.push(requestBody);
    this.store.dispatch(updateNomination({ payload: reqPayload, publishOrDeleteNominationApiResponse: {} }));
    this.myMonitoringService.logEvent(`Deleted clean products nomination`, { category: "Replenishment Planning" })
    this.toasterService.setToastNotification({
      show: true,
      type: 'success',
      msg: "Nomination has been deleted",
    });
    setTimeout(() => {
      if(this.desk?.includes('clean products + latam')){
        this.store.dispatch(savePipelineTRFiltersUpdated({ isPipelineFilterUpdated: true }))
      } else {
        this.getTerminalNominationAF(this.payLoad);
      }
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'deletePipelineNomination',
        type: '',
      }
      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
    }, 3000)
  }


  goToNominations() {
    // This set of code is for the transfer of desk
      let deskOj = { deskValue:this.SelectedDesk , deskParameter: this.SelectedDeskParam , id: this.SelectedDeskId };
      if(!this.SelectedDesk && !this.SelectedDeskParam){
        deskOj = this.SelectedDeskName;
      }
    localStorage.setItem('gTNDeskValue',JSON.stringify(deskOj));
    // this set of code is for the transfer of nomiantion details
    var deskListArray =["clean products + usec + florida desk" , "clean products + usec + pascagoula desk" , "clean products + uswc + pnw desk" , "clean products + uswc + richmond desk" ]
    if(deskListArray.includes(this.desk)){
    const fieldType = this.params?.colDef?.field;
    let fieldDataName = fieldType!.split('_')[3] ? fieldType!.split('_').slice(0, 3).join('_') : fieldType;
    fieldDataName += "_nomKey_Child";
    this.nominationService.restrictNomApiGetCall$.next(true);
    localStorage.setItem('nomKey', this.params.data[fieldDataName]);
  }
  else{
    const fieldType = this.params?.colDef?.field;
    let fieldDataName = fieldType!.split('_')[3] ? fieldType!.split('_').slice(0, 3).join('_') : fieldType;
    this.nominationService.restrictNomApiGetCall$.next(true);
    localStorage.setItem('nomId', this.params.data[fieldDataName]);
  }
    //this set of code is for the transfer of filters
    this.tempVarSPNFR = this.pipelineFilterResponse;
    localStorage.setItem('sPNFR',JSON.stringify(this.tempVarSPNFR));
    this.nominationService.goToNominationData$.next({ isTrue: true, data: this.params });
    if (!this.pipelineNomFilterResponse?.pipelineNomFilterValues) {
      this.store.dispatch(initialisePipelineNomFilters({ isPipelineNomFilterInitialised: false, screenName: "nominations" }));
    } else {
      this.store.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: true }));
    }
    // this set of code onpens the nomination in a new tab
    window.open("/nominations");
  }


  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  async editNomination() {
    const fieldType = this.params?.colDef?.field;
    let fieldDataName = fieldType.split('_')[3] ? fieldType.split('_').slice(0, 3).join('_') : fieldType;
    const pipelineDate = this.params?.data.date === "" ? this.params?.data.scheduledDate : this.params?.data.date;
    const nomination: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);
    nomination.screenName = 'pipelinetr';
    nomination.product = 'PIPELINE';
    nomination.rowItemStatus = this.params.data[`${fieldDataName}_itemStatus_Child`];
    nomination.scheduledDate = this.datePipe.transform(pipelineDate, 'MM-dd-yyy')?.split('-').join('/');

    const MOTAttributes = {
      modeOfTransportDesc: "Pipeline",
      modeOfTransport: "PL",
      transportSystem: "ITW",
      tdVehNo: "ITW",
      scheduledUom: "BR",
    }
    const selectedTabFunction = {
      tab: this.router.url,
      function: 'editNomination',
      type: this.params,
      values: { ...nomination, ...MOTAttributes }
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);


  }

  getTerminalNominationAF(payload: any) {
    this.utilities.getPipelineNominationMasterData(payload)
    payload.screenName = this.isCCAView && this.router.url === '/replenishmentPlanning' ? 'TR' : undefined,
    this.appState.dispatch(invokeTrPipelineNominationList({ payload }));
  }

  //filtering id's for sidepanel api payload from filter data
  filterResponse(response: any, filter: any) {
    let data = response.pipelineFilterValues.find(
      (value: any) =>
        value?.headerName?.toLowerCase() === filter
    )?.list;

    data = data?.length ? data.filter((ele: any) => ele.id).map((item: any) => item.id) : "";
    return data;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.utilities.clearDataToChild();
    this.utilities.unsubscribeDataToChild();
  }

  disableVesselOption(details: any) {
    if (((details?.modeOfTransportDesc?.toLowerCase() === 'pipeline' || details?.modeOfTransportDesc?.toLowerCase() === 'intank') && (details?.tdVehicleIdentifier?.toLowerCase() !== 's' || details?.tdVehicleIdentifier?.toLowerCase() !== 'b')) || details?.nominationItemStatus === 5) {
      return true
    }
    return false
  }

  async vessalScheduleUpdate() {
    const pipelineNomination: any = await this.utilities.getPipelineNomPayload(this.params, this.userEmail, this.desk);

    let selectedTabFunction: any
    selectedTabFunction = {
      tab: this.router.url,
      function: 'pipelineVesselScheduleUpdate',
      type: '',
      values: pipelineNomination
    };
    const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
    this.utilities.addUnsubscribableList(modalPopupAction);

  }


}

