<div class="ethanol-nomination-list">
  <ag-grid-angular class="ag-theme-alpine ag-grid-table" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
    [rowData]="rowData" [rowSelection]="rowSelection" [masterDetail]="true" [isRowMaster]="isRowMaster" [enableRangeSelection]="true"
    [detailCellRendererParams]="detailCellRendererParams" (columnMoved) = "onColumnMoved($event)" (gridReady)="onGridReady($event)" [embedFullWidthRows]="true"
    [frameworkComponents]="frameworkComponents" (firstDataRendered)="onFirstDataRendered($event)" (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged($event)" (selectionChanged)="onSelectionChanged($event)" (rowSelected)="onRowSelect($event)" (cellClicked)="onSAPContractSelect($event)"  [tooltipShowDelay]="tooltipShowDelay"
    [tooltipHideDelay]="tooltipHideDelay" (dragStopped) ="columnValueChanged()" [stopEditingWhenCellsLoseFocus]="true">
  </ag-grid-angular>
</div>

@if (warningPopup) {
  <div class="product-warning-modal">
    <p class="modal-warning-title">publishing error</p>
    <p class="modal-warning-content">
      a product grade has not been selected. please select a scheduled material and demand material before publishing.
    </p>
    <div class="button-container">
      <button class="button" (click)="warningPopup=false">ok</button>
    </div>
  </div>
}
@if (SAPContractPopup) {
  <div class="sapcontract-warning-modal">
    <p class="modal-warning-title">sap contract error</p>
    <p class="modal-warning-content">
      a product grade has not been selected. please select a scheduled material and demand material before selecting a contract number.
    </p>
    <div class="button-container">
      <button class="button" (click)="SAPContractPopup=false">ok</button>
    </div>
  </div>
}

<np-modal class="ethanol-modal" [show]="createNominationEthanolModal.open" [headerTitle]="isPipelineNomEdit ? 'updating terminal nomination.' : 'creating new terminal nomination.'" [subHeaderText]="'*required fields'" [noModalButtons]="true">
  <app-create-nomination-ethanol (closeModalPopup)="closeTerminalNominationModalForEthanol($event)">
  </app-create-nomination-ethanol>
</np-modal>
<np-modal class="vendor-notification-modal" [show]="vendorNotificationPreview.open" [headerTitle]="'selected nominations'"
  [noModalButtons]="true">
  <app-vendor-notification-preview (closeModalPopup)="closePreviewModal()">
  </app-vendor-notification-preview>
</np-modal>
