import { getUserDetails } from './../../shared/store/selector/app.selector';
import { ToastService } from 'src/app/services/toast.service';
import { EthanolMoreMenuComponent } from './../../shared/modules/ethanol-more-menu/ethanol-more-menu.component';
import { FullWidthCellRenderer } from 'src/app/shared/modules/FullWidthCellRenderer/FullWidthCellRenderer';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import {invokeGetTRTruckDataAPI, saveEthanolTRFiltersResponse, setTableHeaders } from './../../shared/store/action/app.action';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { BodyScrollEvent, ColDef, ColGroupDef, Column, ColumnApi, GridApi, GridReadyEvent, IsFullWidthRowParams, RowHeightParams, StatusPanelDef } from 'ag-grid-community';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectDesk, selectEthanolTRFiltersResponse, selectSwitchView, trTruckApiResponse, selectisTRSave } from 'src/app/shared/store/selector/app.selector';
import { HomeService } from 'src/app/services/home.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { triggerTRSave } from 'src/app/shared/store/action/app.action';
import { UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { NominationService } from 'src/app/services/nomination.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ConfirmModalService } from 'src/app/services/comfirm-modal.service';
import { StatusbarInfoComponent } from 'src/app/shared/modules/statusbar-info/statusbar-info.component';
import { GridAlertTooltipRendererComponent } from 'src/app/core/components/create-alert-notifications-side-panel/grid-alert-tooltip-renderer/grid-alert-tooltip-renderer.component';
import { AlertsNotificationService } from 'src/app/services/alerts-notification.service';
import { AlertRuleResponse } from 'src/app/shared/interface/settings.interface';
import { AlertsService } from 'src/app/services/alerts.service';
import { UnitConversionService } from 'src/app/services/unit-conversion.service';import { MyMonitoringService } from 'src/app/services/logging.service';


@Component({
  selector: 'app-ethanol-view',
  templateUrl: './ethanol-view.component.html',
  styleUrls: ['./ethanol-view.component.scss']
})
export class EthanolViewComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public gridApi2!: GridApi;
  gridColumnApi2!: ColumnApi;
  onGridReadyParams!: GridReadyEvent<any, any>;

  ethonalSwitch: any;
  getAllColumnGroupId: any = [];
  selectedDeskOption: any;
  getEhtanolFilterResponse: any = []
  updatedRows: any = { tr: [], nomination: { draft: [], others: [] } };
  private ethanolDecimalFields: any = [];

  selectSwitchView$ = this.store.pipe(select(selectSwitchView));
  selectedDesk$ = this.store.pipe(select(selectDesk));
  selectEthanolTRFiltersResponse$ = this.store.pipe(select(selectEthanolTRFiltersResponse))
  // getTRTruckDataAPISuccess$ = this.store.pipe(select(getTRTruckDataAPISuccess))
  trTruckList$ = this.store.pipe(select(trTruckApiResponse));
  processData: any = []
  public fullWidthCellRenderer: any = FullWidthCellRenderer;
  selectisTRSave$ = this.store.pipe(select(selectisTRSave));
  currentAppliedFilter: any;
  fullWidthRowTexts = ['this week', 'next week', 'last week', ' '];
  isTableAutoSizeColumns: boolean = false;
  isNominationDataEdit: boolean = false;
  terminalOweneshipValue: any;
  currentUOM = '';
  userEmail:any;
  userDetails$ = this.appState.pipe(select(getUserDetails));
  colOrderAndExpandData!: any;
  apiCallCount:any = 0;
  isShellCarson:boolean= false;
  editField:string='';
  confirmModal = { open: false, modal: 'confirmModal' };
  isDemandOverrideEdit:boolean=false;
  public statusBar: {
    statusPanels: StatusPanelDef[]
  } = {
      statusPanels: [
        {
          statusPanel: 'agAggregationComponent', align: 'left',
          statusPanelParams: {
            aggFuncs: ['min', 'max', 'avg', 'sum', 'count']
          }
        }
      ],
    };
  getInvDetails: any = [];
  ethanolAlerts: AlertRuleResponse[] = [];
  parametersMapping: Map<string, any[]> = new Map<string, any[]>();
  processedDesk!: string;
  editedInvObj:any = {};
  gridInitialized:boolean = false;
  selectedDeskParams: any;
  destTransloadSplitQty: any;
  ogdenTransLoadQty: any;
  tooeleTransLoadQty: any
  editedRowIndexs: any = [];
  editedColumn: any = [];
  isAPItriggered : any =false;

  constructor(private datePipe: DatePipe,
    private store: Store,
    private homeService: HomeService,
    private utilities: Utilities,
    private ethanolTruckService: EthanolTruckService,
    private appState: Store<AppState>,
    private toasterService: ToastService,
    private replenishmentService : ReplenishmentPlanningService,
    private nominationService: NominationService,
    private loaderService:LoaderService,
    private confirmService: ConfirmModalService,
    private detectChanges: ChangeDetectorRef,
    private myMonitoringService: MyMonitoringService,
    private alertsNotificationService: AlertsNotificationService,
    private alertService: AlertsService,
    private unitConversionService: UnitConversionService,
    private ethanolService : EthanolTruckService
  ) { }

  ngOnInit(): void {
    this.statusBar.statusPanels.push(
      {
        statusPanel: StatusbarInfoComponent, align: 'right',
        statusPanelParams: {
          displayInfo: "",
          pstDate:  this.datePipe.transform(
            this.utilities.getPSTDateObj(),
            'MM/dd/yyyy'
          )
        }
      },
    )
    sessionStorage.removeItem("updatedCellNode");
    sessionStorage.removeItem("agGridState");
    this.utilities.colWidthObserver.subscribe((desk: string) => {
       if(desk === 'ethanol + uswc' || desk === 'ethanol + usec'){
        this.utilities.savedWidths =  this.gridColumnApi2.getColumns()?.map(col => {
          return {
            colId: col.getColId(),
            width: col.getActualWidth()
          };
        });
       }
    });

    this.selectSwitchView$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.ethonalSwitch = response.switchView
          this.isTableAutoSizeColumns = false;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if(this.selectedDeskOption !== response.selectedDesk){
            this.myMonitoringService.logPageView(response.selectedDesk.split(" + ").join(' ') + ' Replenishment Planning');
          }
          this.selectedDeskOption = response.selectedDesk;
          this.selectedDeskParams = response.selectedDeskParam;
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

    this.selectEthanolTRFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isfilterUpdated && !this.isAPItriggered) {
          this.isAPItriggered = true;
          this.getEhtanolFilterResponse = response;
          this.generateCellBoundaries();
          let terminalOweneshipList = this.getEhtanolFilterResponse?.ethanolFilterValues?.filter((ele: any) => ele.headerName === 'terminal ownership')[0]
          this.terminalOweneshipValue = terminalOweneshipList?.list.filter((ele: any) => ele.checked)[0]
          if(this.nominationService?.isTriggeredFromEthanolNomModalPopup$.value){
            setTimeout(() => {
              this.getTruckData()
            }, 10000);
          }else{
            this.getTruckData()
          }
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }

    })

    this.trTruckList$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.isAPItriggered = false;
          this.gridInitialized = true;
          this.editedInvObj= {};
          this.columnDefs2 = [];
          this.rowData2 = [];
          this.gridApi2?.setColumnDefs([]);
          this.gridApi2?.setRowData([]);
          this.getFormated(response.value)
          this.generateCol();
          if (response?.value && this.colOrderAndExpandData) {
            this.apiCallCount += 1;
            this.applyColOrderAndExpansion()
          }
          //  Retain user's place in RP screen after updating a nomination
          const fetchUpdatedCellNode = this.utilities.getUpdatedCellNode();
          if(this.editedRowIndexs.length || fetchUpdatedCellNode){
            const updatedRowIndex = this.editedRowIndexs[0] ?? fetchUpdatedCellNode.rowIndex;
            const updatedColId = this.editedColumn[0]?.colId ?? fetchUpdatedCellNode.colId;
            setTimeout(() => {
              this.gridApi2.ensureIndexVisible(updatedRowIndex, "middle");
              this.gridApi2.ensureColumnVisible(updatedColId, 'middle');
              this.editedRowIndexs = [];
              this.editedColumn = [];
            },1000)
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectisTRSave$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.triggeredTRDataSave) {
            if(this.isShellCarson && this.isDemandOverrideEdit){
              this.openModalPopup();
            }else{
              this.onSave()
            }

          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.homeService.allGroupAction$.subscribe((value: any) => {
      if (this.selectedDeskOption?.toLowerCase() === 'ethanol + uswc' || this.selectedDeskOption?.toLowerCase() === 'ethanol + usec') {
        this.utilities.setExpandAllCollapseAll(this.gridColumnApi2, value, this.getAllColumnGroupId)
      }
    })

    this.replenishmentService.applyColOrder$.subscribe(res => {
      let val:any = Object.values(res);
      if( val.length && localStorage.getItem("selectedView")){
        this.apiCallCount += 1;
        this.colOrderAndExpandData = res;
       this.applyColOrderAndExpansion();

      } else {
        this.loaderService.setLoading(false);
      }
    })

    this.alertsNotificationService.userAlertsSubscribeEmitter.subscribe(response => {

      this.ethanolAlerts = response.filter(alert => alert.isActive);
      //force cell rerender so if dismiss/SignalR provides a change, it will render in AG grid
      if(this.gridApi2 !== undefined){
        this.gridApi2.refreshCells({force: true});
      }
    });

    this.nominationService.resetToDefaultView$.subscribe(res => {
      if (res) {
        if (this.colOrderAndExpandData) {
          if (this.colOrderAndExpandData.colOrder) {
            this.colOrderAndExpandData.colOrder.replenishmentColumnOrder.length = 0;
          }
          if (this.colOrderAndExpandData.colExpand) {
            this.colOrderAndExpandData.colExpand.length = 0;
          }
        }
        this.gridColumnApi2?.resetColumnState();
        this.gridColumnApi2?.resetColumnGroupState();
      }
    });

    this.utilities.unsubscribeDataToChild();
  }

  dataList = [];
  columnAPIResponse: any = {};

  getFormated(list: any = []) {
    let colConfig: any = [];
    let coldata: any = [];
    let uniqueIds: any = [];
    this.getInvDetails = [];
    this.utilities.getInvDetailsArr=[];
    list.forEach((ele: any, index: any) => {
      let dataConfig = this.utilities.getLowLevelConf(ele, this.ethonalSwitch)
      colConfig = dataConfig.col;
      let rowdata = dataConfig.data;
      uniqueIds = dataConfig.uniqueIds;
      this.getInvDetails.push(...dataConfig.getInvDetails)
      for (let key in rowdata) {
        if (rowdata[key] === undefined) {
          delete rowdata[key];
        }
      }
      coldata.push(rowdata)
    })
    this.appState.dispatch(setTableHeaders({ allTableHeaders: uniqueIds, isTableHeadersUpdated: true }));
    this.columnAPIResponse = { colDef: colConfig, data: coldata }
    this.rowData2 = this.columnAPIResponse.data;
    this.fullWidthRow(this.columnAPIResponse.data)
  }

  isReceiptEditable(params:any){
    return new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime() && params.data[params.colDef.field+'_isEditable']
   }

  getCloumnAddedtoChildren(e: any, dataObj: any) {
    let cList: any = []
    for (let key in e) {
      if ((typeof (e[key])) === 'object' && !['headerName', 'headerType', 'children', 'nomination'].includes(key)) {
        let nfieldName = e[key].headerId ? this.getCamelCaseString(e[key].headerId) : 'misc'
        cList.push({
          headerName: (e[key].headerName ? e[key].headerName : 'misc')?.toLowerCase(),
          type: e[key].headerType,
          field: this.getCamelCaseString(e.headerId) + "_" + nfieldName,
          children: null,
          headerId: e[key].headerId ? e[key].headerId : this.getCamelCaseString(e.headerId) + "_" + nfieldName
        })
        dataObj[this.getCamelCaseString(e.headerId) + "_" + nfieldName] = e[key].value
      }
    }
    return { cList: cList, dataObj: dataObj }
  }

  getCamelCaseString(str: any) {
    return str?.replace(" ", "-").split(' ').reduce((s: any, c: any) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
  }

  rowData2: any = [];

  fullWidthRow(truckList: any): void {
    let processData: any = [];
    const rowData = [...truckList];
    let currentWeekDate = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
    let dayIndex = this.utilities.convertDatesToCurrentTZ(new Date().toISOString()).getDay();
    let currentWeek = currentWeekDate.setDate(currentWeekDate.getDate() - dayIndex);
    let nextWeek = new Date(currentWeek).setDate(new Date(currentWeek).getDate() + 7);
    let lastWeek = new Date(currentWeek).setDate(new Date(currentWeek).getDate() - 7);
    let nextMonth = new Date(currentWeek).setDate(new Date(currentWeek).getDate() + 14);

    rowData.forEach((val: any, index: number) => {
      if (new Date(currentWeek).toDateString() === new Date(val.date).toDateString()) {

        let fullWidthRow:any = {
          fullWidth: true,
          fullWidthText: 'this week',
        };
        processData.push(fullWidthRow);
        processData.push(val);
      }
      else if (new Date(nextWeek).toDateString() === new Date(val.date).toDateString()) {
        let fullWidthRow:any = {
          fullWidth: true,
          fullWidthText: 'next week',
        };
        processData.push(fullWidthRow);
        processData.push(val);
      }
      else if (new Date(lastWeek).toDateString() === new Date(val.date).toDateString()) {
        let fullWidthRow:any = {
          fullWidth: true,
          fullWidthText: 'last week',
        };
        processData.push(fullWidthRow);
        processData.push(val);
      }
      else if (new Date(nextMonth).toDateString() === new Date(val.date).toDateString()) {
        let fullWidthRow:any = {
          fullWidth: true,
          fullWidthText: ' ',
        };
        processData.push(fullWidthRow);
        processData.push(val);
      }
      else {
        processData.push(val);
      }

    })

    this.rowData2 = processData;

    // Find last updated cell with position
    this.utilities.retrieveLastUpdatedCellWithPosition(this.gridApi2, this.gridColumnApi2);
  }

  isFullWidthRow: (params: IsFullWidthRowParams) => boolean = (
    params: IsFullWidthRowParams
  ) => {
    return this.isFullWidth(params.rowNode.data);

  };

  createEthanolPayload(reqFilter: any, currentView: any, SortOrder: any, terminalOwnership: any, modeOfTransport: any, unitOfMeasurement: any, uomToggle: any) {
    this.getEhtanolFilterResponse.ethanolFilterValues.forEach((element: any) => {
      switch (true) {
        case (element.headerName === 'terminal'):
          reqFilter['Terminal'] = this.getCheckedIds(element);
          this.isShellCarson = reqFilter['Terminal'].length >= 1 && reqFilter['Terminal'].includes('CARSONSH') && this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc;
          break;
        case (element.headerName === 'supplier' && (currentView === 'terminal' || currentView === 'supplier' || currentView === 'carrier')):
          reqFilter['Supplier'] = this.getCheckedIds(element)
          SortOrder = currentView === 'supplier' ? { "2": "Terminal", "1": "Supplier", "3": "Carrier" } : SortOrder;
          break;
        case (element.headerName === 'carrier' && (currentView === 'terminal' || currentView === 'carrier')):
          reqFilter['Carrier'] = this.getCheckedIds(element)
          SortOrder = currentView === 'carrier' ? { "2": "Terminal", "3": "Supplier", "1": "Carrier" } : SortOrder;
          break;
        case (element.headerName === 'terminal ownership'):
          terminalOwnership = this.getTerminalOwnerShip(element, terminalOwnership);
          break;
        case (element.headerName === 'mode of transport'):
          modeOfTransport = element.list.filter((ele: any) => ele.checked).map((mot: any) => (mot.name !== 'tank' ? mot.name : 'pipeline'))
          break;
        case (element.headerName === 'unit of measure'):
          unitOfMeasurement = element.list.filter((ele: any) => ele.checked)[0]?.value
          uomToggle = element.truck
          this.currentUOM = unitOfMeasurement
          break;
        default:
          break;
      }
    });

    const UsecDeskAddition: any = {
      [UDMDesk.EthanolUsec]: { desk: UDMDesk.EthanolUsecVal }, 
      [UDMDesk.EthanolUswc]: { desk: UDMDesk.EthanolUswcVal }
    }

    const payload: any = {
      RequestFilter: reqFilter,
      startDate: this.datePipe.transform(this.getEhtanolFilterResponse.ethanolFromDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(this.getEhtanolFilterResponse.ehtanolToDate, 'yyyy-MM-dd'),
      modeOfTransport: modeOfTransport,
      unitOfMeasurement: unitOfMeasurement,
      uomToggle: uomToggle,
      terminalOwnership,
      ...(UsecDeskAddition[this.selectedDeskOption])
    }
    return payload
  }
  applyColOrderAndExpansion() {
    if (this.apiCallCount === 1) {
      this.loaderService?.setLoading(true);
      setTimeout(() => {
        if (this.colOrderAndExpandData?.colOrder.replenishmentColumnOrder?.length) {
          this.gridColumnApi2.applyColumnState({
            state: this.colOrderAndExpandData?.colOrder?.replenishmentColumnOrder,
            applyOrder: true,
          })
        }
        else {
          this.gridColumnApi2?.resetColumnState();
        }
        if (this.colOrderAndExpandData?.colExpand?.length) {
          this.gridColumnApi2?.setColumnGroupState(this.colOrderAndExpandData?.colExpand);
          this.colOrderAndExpandData?.colWidths.forEach((item: { colId: string | Column; width: number; })=>{
            this.gridColumnApi2.setColumnWidth(item.colId, item.width);
          });
          this.apiCallCount = 0;
        } else {
          this.gridColumnApi2?.resetColumnGroupState();
        }
        this.loaderService.setLoading(false);
        this.apiCallCount = 0;
      }, 5000)
    }
  }



  getTerminalOwnerShip(element: any, terminalOwnership: any) {
    element.list.forEach((ele: any) => {
      if (ele.checked) {
        terminalOwnership = ele.name === 'single entity' ? 'singleentity' : 'multientity'
      }
    })
    return terminalOwnership;
  }
  getTruckData() {
    this.nominationService?.isTriggeredFromEthanolNomModalPopup$?.next(false);
    if (this.getEhtanolFilterResponse.ethanolFromDate !== null && this.getEhtanolFilterResponse.ehtanolToDate !== null) {
      let reqFilter: any = {}
      let currentView = this.ethonalSwitch.split('_')[0]?.toLowerCase();
      let SortOrder = { "1": "Terminal", "2": "Supplier", "3": "Carrier" }
      let unitOfMeasurement = 'bbl';
      let uomToggle = true;
      let terminalOwnership = 'singleentity'
      let modeOfTransport: any = [];
      const getType: any = {
        [UDMDesk.EthanolUswc]: 'ethanolfilterdata',
        [UDMDesk.EthanolUsec]: 'ethanolusecfilterdata'
      };

      this.currentAppliedFilter = this.createEthanolPayload(reqFilter, currentView, SortOrder, terminalOwnership, modeOfTransport, unitOfMeasurement, uomToggle)
      this.columnDefs2 = [];
      this.rowData2 = []
      if (!this.utilities.globalethanolAggregatorResponse) {
        this.utilities.getEthanolFilterData(terminalOwnership,getType[this.selectedDeskOption]).subscribe(result => {
          this.utilities.globalethanolAggregatorResponse = result;
          this.utilities.getEthanolTRNomPyaloadMasterData(this.currentAppliedFilter);
          this.appState.dispatch(invokeGetTRTruckDataAPI({ payload: this.currentAppliedFilter, isPivotActive: currentView }));
          this.appState.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: false, ethanolTruckToggle: this.getEhtanolFilterResponse.ethanolTruckToggle, unitOfMeasure: this.getEhtanolFilterResponse.unitOfMeasure }));
        })
      } else {
        this.utilities.getEthanolTRNomPyaloadMasterData(this.currentAppliedFilter);
        this.appState.dispatch(invokeGetTRTruckDataAPI({ payload: this.currentAppliedFilter, isPivotActive: currentView }));
        this.appState.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: false, ethanolTruckToggle: this.getEhtanolFilterResponse.ethanolTruckToggle, unitOfMeasure: this.getEhtanolFilterResponse.unitOfMeasure }));
      }
    }
  }


  getCheckedIds(element: any) {
    const list = element.list.filter((ele: any) => ele.id && ele.checked && !ele?.id?.includes("-X")).map((item: any) => item.id);
    return list;
  }

  isFullWidth = (data: any) => {
    return ['this week', 'next week', 'last week', ' '].indexOf(data.fullWidthText) >= 0;
  }

  getRowHeight: (params: RowHeightParams) => number | undefined | null = (params: RowHeightParams) => {
    if (params.data.fullWidth) {
      return 24;
    }
    else {
      return null;
    }
  };

  public columnDefs2: (ColDef | ColGroupDef)[] = [];
  defaultColDef2: ColDef = {
    sortable: false,
    filter: true,
    resizable: true,
  };
  onGridReady2(params: GridReadyEvent) {
    this.onGridReadyParams = params
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    this.rowData2 = [{
      field: 'date',
      width: 150,
      filter: 'agNumberColumnFilter',
      headerName: 'date',
      colId: 'date',
      pinned: 'left',
      spanHeaderHeight: true,
      cellClass: 'text-right',
      headerClass: "ag-right-aligned-header",
      cellStyle: (params: any) => {
        if (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
          return { fontWeight: 'bold' };
        }
        return null;
      }

    }]
    this.rowData2 = this.columnAPIResponse.data;
  }

  getEthanolGridColFields(): any {
    let getAllFieldsName: any = [];
    let getGridColData = this.gridColumnApi2?.getAllDisplayedColumns();

    getGridColData?.forEach((colData: any) => {
      if (colData.colId !== "date") {
        getAllFieldsName.push(colData.colDef.field);
      }
    });
    return getAllFieldsName
  }

  onEhtanolCellValueChanged(event: any) {
    //  Retain user's place in RP screen after updating a nomination
    const allColumns = this.gridColumnApi2.getAllGridColumns();
    const targetColumn = allColumns?.find((col:any) => col.colDef?.field  === event?.colDef?.field)
    this.editedColumn.push(targetColumn);
    this.editedRowIndexs?.push(event?.rowIndex);
    this.utilities.saveGridStateWithCellNode(event, this.gridColumnApi2);

    let getAllFields = this.getEthanolGridColFields()
    let isValidValue = this.utilities.validateValue(
      event,
      [],
      [],
      getAllFields
    );
    if (isValidValue) {
      let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
      let editaedCell = event.colDef.field.split('_')[IsMultiTerminal ? 2 : 1]?.toLowerCase()
      let isNominationEdit = editaedCell === 'receipts' || editaedCell === 'sales'

      if (isNominationEdit) {
        this.updateNomination(event)
      } else {
        this.updateTrValues(event)
      }
      this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: true, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false }));
    }
    else {
      event.api.refreshCells();
    }
  }
updateDemandPayload (event:any,totalDemandOverrideQty:any,convertedValue:any,currentField:any,demandQty:any,actualRackIssues:any){
  if (event.colDef.headerName?.toLowerCase()=== 'total' && this.isShellCarson) {
    totalDemandOverrideQty = convertedValue;
    currentField = 'totalDemandOverrideQty';
  }
  else {
    demandQty = convertedValue;
    actualRackIssues = convertedValue;
    currentField = 'demandQty';
  }
  return {totalDemandOverrideQty, currentField, demandQty, actualRackIssues}
}

  updateTrValues(event: any) {
    let rowDate = new Date(event.data?.date).getTime()
        let editedRowID = event.data[event.colDef.field + '_id']
        let hubOrSpoke = event.data[event.colDef.field.split('_')[0] + '_headerHubSpoke']
        let ishubTerminal = hubOrSpoke?.toLowerCase() === 'hub'
        let editedField = ishubTerminal ? event.colDef.field.split('_')[1]?.toLowerCase() : event.colDef.headerName?.toLowerCase();
        let currentIndex = this.updatedRows?.tr?.findIndex((ele: any) => ele.id === editedRowID && rowDate === new Date(ele?.stockProjectionItemDate).getTime());
        let locationIDTemp = event.colDef.field.split('_')[0].replace("-", " ");
        let locationID = locationIDTemp.toLowerCase() === 'pasetoh' ? "PASCAGOU" : locationIDTemp;
        let currentField = ''
        let InventoryQty = null
        let demandQty = null
        let totalDemandOverrideQty = null
        let actualRackIssues = undefined;
        let tankTransferQty = null
        let convertedValue = +this.getConvertedUOMValue(+event.newValue, 'mb');
        this.editField = event.colDef.headerName?.toLowerCase();
        let commentsValue = '';
        let getData;
        switch (editedField) {
          case "totalinventory":
            InventoryQty = convertedValue;
            currentField = 'InventoryQty';
            currentIndex = this.getIndexForHubtreminal(ishubTerminal, editedRowID, rowDate, currentIndex, editedField)
            break;
          case "sales":
              tankTransferQty = convertedValue;
              currentField = 'tankTransferQty';
              currentIndex = this.getIndexForHubtreminal(ishubTerminal, editedRowID, rowDate, currentIndex, editedField)
            break;
          case "demand override":
          case "demand":
           getData = this.updateDemandPayload (event,totalDemandOverrideQty,convertedValue,currentField,demandQty,actualRackIssues);
           totalDemandOverrideQty = getData?.totalDemandOverrideQty;
           currentField = getData?.currentField;
           demandQty = getData?.demandQty;
            locationID = ishubTerminal ? event.colDef.field.split('_')[2].replace("-", " ") : locationID;
            currentIndex = this.getIndexForHubtreminal(ishubTerminal, editedRowID, rowDate, currentIndex, editedField)
            break;
            case "comments":
              currentField = 'comments';
              commentsValue  =  event?.newValue;
              convertedValue = event?.newValue;
            break;
          default:
            break;
        }

        if (currentIndex === -1) {
          this.updatedRowsValue(event,locationID, InventoryQty, demandQty, totalDemandOverrideQty, tankTransferQty, currentField, commentsValue);
        }
        else {
          this.updateTRValueCurrentIndex(currentIndex, currentField, convertedValue)
        }
        setTimeout(() => {
          event.api.refreshCells();
        }, 500);

  }

  updatedRowsValue(event: any, locationID: any, InventoryQty: any, demandQty: any, totalDemandOverrideQty: any, tankTransferQty: any, currentField: any, commentsValue:any){
    let currentDate = new Date().toISOString();
    let editedRowID = event.data[event.colDef.field + '_id'];
    let materialId = event.data[event.colDef.field + '_headerMaterial'];
    let materialDesc = event.data[event.colDef.field + '_headerMaterialDesc'];
    let batchValuationType = event.data[event.colDef.field + '_batchValuationType'] || '';
    this.updatedRows.tr.push({
      id: editedRowID,
      batchValuationType: batchValuationType && batchValuationType !== null ? batchValuationType : '',
      InventoryQty :InventoryQty,
      demandQty: demandQty,
      totalDemandOverrideQty,
      tankTransferQty: tankTransferQty,
      product: materialDesc || "ETHANOL - FUEL GRADE",
      stockProjectionItemDate: event.data.date,
      editedDate: currentDate,
      material: materialId || '296660990',
      locationId: locationID,
      modifiedBy: this.userEmail,
      isInventoryEdited: currentField === 'InventoryQty'? true:false, //NOSONAR lightweight logging
      isDemandEdited: currentField === 'demandQty'? true:false, //NOSONAR lightweight logging
      ...(commentsValue && {comments: commentsValue}),
    })
  }

  updateTRValueCurrentIndex(currentIndex: any, currentField: any, convertedValue: any) {
    this.updatedRows.tr[currentIndex][`${currentField}`] = convertedValue
    if (currentField === 'InventoryQty') {
      this.updatedRows.tr[currentIndex][`isInventoryEdited`] = true;
    }
    if (currentField === 'demandQty') {
      this.updatedRows.tr[currentIndex][`isDemandEdited`] = true;
    }
  }

  getIndexForHubtreminal(ishubTerminal: any, editedRowID: any, rowDate: any, currentIndex: any, editedField: any) {
    let index;
    switch (editedField) {
      case "totalinventory":
        index = ishubTerminal ? this.updatedRows?.tr?.findIndex((ele: any) => ele.id === editedRowID && rowDate === new Date(ele?.stockProjectionItemDate).getTime() && ele.demandQty === null) : currentIndex;
        break;
      case "sales":
        index = ishubTerminal ? this.updatedRows?.tr?.findIndex((ele: any) => ele.id === editedRowID && rowDate === new Date(ele?.stockProjectionItemDate).getTime() && ele.demandQty === null) : currentIndex;
        break;
      case "demand override":
      case "demand":
        index = ishubTerminal ? this.updatedRows?.tr?.findIndex((ele: any) => ele.id === editedRowID && rowDate === new Date(ele?.stockProjectionItemDate).getTime() && ele.InventoryQty === null) : currentIndex;
        break;
      default:
        break;
    }
    return index;
  }

  getConvertedUOMValue(val: any, requiredUOM: any) {
    switch (this.currentUOM) {
      case 'mb':
        if (requiredUOM === 'mb') {
          return +(Number.parseFloat(val).toFixed(3));
        }
        break;
      case 'br':
        if (requiredUOM === 'mb') {
          let convertedVal: any = val / 1000
          return +(Number.parseFloat(convertedVal).toFixed(3));
        }
        break;
      case 'ga':
        if (requiredUOM === 'mb') {
          let convertedVal: any = val / 42000
          return +(Number.parseFloat(convertedVal).toFixed(3));
        }
        break;
      default:
        return val
    }
  }

  updateNomination(event: any) {
    let isMOTOn: boolean = false;
    let getTruckVolume: any;
    this.ethanolService.motToggleSwitch$.subscribe((isToggled: boolean) => {
      isMOTOn = isToggled;
    })
    let getTruckId = event?.colDef?.field?.split('_')[0].includes('-') ? event?.colDef?.field?.split('_')[0]?.replace("-", " ") : event?.colDef?.field?.split('_')[0];
    this.ethanolService.getTruckVolume$.subscribe((data: []) => {
      data.forEach((getTruckName: any) => {
        if (getTruckName?.id === getTruckId) {
          getTruckVolume = getTruckName.volume;
        }
      })
    })
    this.isNominationDataEdit = true;
    let isEstreceipts = event.colDef.colId === "estreceipts"
    let rowID = event.data[event.colDef.field + '_id'];
    let rowItemId = event.data[event.colDef.field + '_itemId'];
    let rowItemStatus;
    if(event.colDef.field.toLowerCase().includes('truck')){
      rowItemStatus = rowID && rowItemId && isEstreceipts ? "unpublished" : "draft";
    }
    else{
      rowItemStatus = rowID && rowItemId ? event.data[event.colDef.field + '_itemStatus'] : "draft";
    }
    let rowStatus = rowID && rowItemId ? event.data[event.colDef.field + '_status'] : "draft";
    let rowItemDate = this.datePipe.transform(event.data.date, 'yyyy-MM-dd')?.split('-').join('')
    event.data[event.colDef.field +'_calculatedNomination'] = isMOTOn  &&  event.colDef.field.toLowerCase().includes('truck') ? +(event.newValue * getTruckVolume) : +(event.newValue);
    if ((rowID && rowItemId) || isEstreceipts) {
      this.updateNominationForSave(event, rowID, rowItemId, rowStatus, rowItemStatus, rowItemDate)
    } else {
      this.updateNominationAsDraft(event, rowStatus, rowItemStatus, rowItemDate)
    }
    event.api.refreshCells();
  }

  setContractPartnerValue(heirchyData: any, IsMultiTerminal: any) {
    let supplierVal = heirchyData[IsMultiTerminal ? 4 : 3];
    let textLineSupplier = supplierVal?.toLowerCase() === 'misc' ? '' : supplierVal;
    return textLineSupplier === null ? '' : textLineSupplier;
  }

  getSchdlQuantity(isMOTOn:any, event:any){
    return isMOTOn === true && event?.colDef?.field?.toLowerCase().includes('f-worthm_receipts_rail') === true ? +(event.newValue * 690) : +(event.newValue);
  }

  getPipelineTs(){
    return this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : "ITE";
  }

  getPipelineCarrier(isSalesEdited:any){
   return this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc && !isSalesEdited ? '0000040001' : null;
  }

  isDraftNomCurrentIndex(rowStatus: any, rowItemStatus: any, rowItemDate: any, event: any, heirchyData: any, textLineObj: any, scenarioType: any) {
    let EditedRowObj:any = {}
    let isMOTOn:any;
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity';
    let isSalesEdited = this.isSalesNomCellEdited(event, IsMultiTerminal);
    let currentMOT = event.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase();
    let valuationType = heirchyData[1].toLowerCase() === 'chevron' ? 'CHEVRONTEX' : heirchyData[1].toUpperCase();
    let scheduledTypeForRail = IsMultiTerminal ? "D" : this.getScheduleType(isSalesEdited);
    let refDocIndicatorForRail = IsMultiTerminal ? "K" : this.getReferenceDocumentIndicator(IsMultiTerminal, isSalesEdited);
    let scheduledTypeForPipeline = this.getScheduleType(isSalesEdited, 'pipeline');
    let refDocIndicatorForPipeline = this.getReferenceDocumentIndicator(IsMultiTerminal, isSalesEdited);
    let contractPartnerValue = this.setContractPartnerValue(heirchyData, IsMultiTerminal);
    let locationIdValue = heirchyData[0].replace("-", " ");
    let locationName =  event.data[`${heirchyData[0]}_locationName`]?.split('(')[0];
    let valuationTypeDestinationLocValue = IsMultiTerminal ? valuationType : undefined;
    this.ethanolService.motToggleSwitch$.subscribe((isToggled:boolean)=>{
      isMOTOn = isToggled;
    })
    let refDocIndicatorForBarge = this.getReferenceDocumentIndicator(IsMultiTerminal, isSalesEdited); // considering K(purchase will only be there for SDD)
        switch (currentMOT) {
          case 'rail':
            EditedRowObj = {
              id: null,
              modeOfTransport: "R_",
              modeOfTransportDesc: "Rail",
              transportSystem: "RAIL-US",
              udmModeOfTransportDesc: "Rail",
              deskFilter: this.selectedDeskParams,
              tdVehNo: "RAIL-US",
              sys_status: rowStatus,
              udmTimeFrameOption:null,
              headerToItemNav: [
                {
                  itemId: null,
                  udmItemStatus: rowItemStatus,
                  scheduledDate: rowItemDate,
                  scheduledQty: this.getSchdlQuantity(isMOTOn, event),
                  scheduledUom: "BR",
                  confirmationIndicator: "X",
                  scheduleType : scheduledTypeForRail,
                  referenceDocumentIndicator: refDocIndicatorForRail,
                  textLine: textLineObj.rail,
                  contractPartner: contractPartnerValue,
                  locationId: locationIdValue,
                  locationName: locationName,
                  valuationTypeDestinationLoc : valuationTypeDestinationLocValue,
                  udmDailyBreakdownQty: null,
                  udmDisableValidations: true,
                }
              ]
            }

            break;
          case 'barge':

            EditedRowObj = {
              id: null,
              modeOfTransport: "S",
              tdVehIdentifier: "B",
              modeOfTransportDesc: "Barge",
              transportSystem: "CA-N",
              udmModeOfTransportDesc: "Barge",
              deskFilter: this.selectedDeskParams,
              tdVehNo: null,
              sys_status: rowStatus,
              udmTimeFrameOption:null,
              headerToItemNav: [
                {
                  itemId: null,
                  udmItemStatus: rowItemStatus,
                  scheduledDate: rowItemDate,
                  scheduledQty: +(event.newValue),
                  scheduledUom: "BR",
                  confirmationIndicator: "X",
                  textLine: textLineObj.barge,
                  scheduleType : "D",
                  referenceDocumentIndicator: refDocIndicatorForBarge,
                  contractPartner: contractPartnerValue,
                  locationId: locationIdValue,
                  locationName: locationName,
                  valuationTypeDestinationLoc : valuationTypeDestinationLocValue,
                  udmDailyBreakdownQty: null,
                  udmDisableValidations: true,
                }
              ]
            }

            break;
          case 'pipeline':
            EditedRowObj = {
              id: null,
              modeOfTransport: "PL",
              modeOfTransportDesc: "Pipeline",
              transportSystem: this.getPipelineTs(),
              udmModeOfTransportDesc: "Pipeline",
              deskFilter: this.selectedDeskParams,
              tdVehNo: this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : null,
              carrier:this.getPipelineCarrier(isSalesEdited),
              carrierName:this.selectedDeskOption.toLowerCase() === UDMDesk.EthanolUswc && !isSalesEdited ? 'CHEVRON USA INC': null,
              sys_status: rowStatus,
              udmTimeFrameOption:null,
              headerToItemNav: [
                {
                  itemId: null,
                  udmItemStatus: rowItemStatus,
                  scheduledDate: rowItemDate,
                  scheduledQty: +(event.newValue),
                  scheduledUom: "BR",
                  confirmationIndicator: "X",
                  scheduleType : scheduledTypeForPipeline,
                  referenceDocumentIndicator: refDocIndicatorForPipeline,
                  textLine: textLineObj.pipeline,
                  contractPartner: contractPartnerValue,
                  locationId: locationIdValue,
                  locationName: locationName,
                  valuationTypeDestinationLoc : valuationTypeDestinationLocValue,
                  udmDailyBreakdownQty: null,
                  udmDisableValidations: true,
                }
              ]
            }
            break;

          default:
            break;
        }

        if(EditedRowObj.headerToItemNav[0].scheduleType === 'O'){
          EditedRowObj.headerToItemNav[0].valuationTypeOriginLoc  =  valuationTypeDestinationLocValue;
        }
        else {
          EditedRowObj.headerToItemNav[0].valuationTypeDestinationLoc  = valuationTypeDestinationLocValue;
        }
    this.updatedRows.nomination.draft.push(EditedRowObj)
    this.checkIfTRSaveDisabledForDraft();
  }



  updateEditedDraftNomPayload(currentIndex: any, rowItemStatus: any, rowItemDate: any, event: any, heirchyData: any, textLineObj: any) {
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity';
    let currentMOT = event.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase();
    let isheaderitemDatePresent = this.updatedRows.nomination.draft[currentIndex].headerToItemNav.findIndex((ele: any) => rowItemDate === ele?.scheduledDate && textLineObj[currentMOT] === ele?.textLine)
    if (isheaderitemDatePresent === -1) {
      this.addNewlyUpdatedNomObj(event, rowItemStatus, rowItemDate, heirchyData, currentIndex, textLineObj)
    } else {
      this.updatedRows.nomination.draft[currentIndex].headerToItemNav[isheaderitemDatePresent].scheduledQty = +(event.newValue);
    }
  }

  addNewlyUpdatedNomObj(event: any, rowItemStatus: any, rowItemDate: any, heirchyData: any, currentIndex: any, textLineObj: any) {
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity';
    let isSalesEdited = event.colDef.field.split('_')[IsMultiTerminal ? 2 : 1]?.toLowerCase() === 'sales';
    let supplierVal = isSalesEdited ? undefined : heirchyData[IsMultiTerminal ? 4 : 3];
    let textLineSupplier = supplierVal?.toLowerCase() === 'misc' ? '' : supplierVal;
    let currentMOT = event.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase();
    let valuationType = heirchyData[1].toLowerCase() === 'chevron' ? 'CHEVRONTEX' : heirchyData[1].toUpperCase();
    let scheduledTypeRail = IsMultiTerminal ? 'D' : this.getScheduleType(isSalesEdited);
    let refDocIndicatorForRail = IsMultiTerminal ? 'K' : this.getReferenceDocumentIndicator(IsMultiTerminal, isSalesEdited);
    let scheduledTypePipeline = this.getScheduleType(isSalesEdited, 'pipeline');
    let refDocIndicatorForPipeline = this.getReferenceDocumentIndicator(IsMultiTerminal, isSalesEdited);
    let locationIdValue = heirchyData[0].replace("-", " ");
    let contractPartnerValue = textLineSupplier === null ? '' : textLineSupplier;
    let valuationTypeDestinationLocValue = IsMultiTerminal ? valuationType : undefined;
    let headerItemObje = {};
    let refDocIndicatorForBarge = this.getReferenceDocumentIndicator(IsMultiTerminal); // considering K(purchase will only be there for SDD)
    switch (currentMOT) {
      case 'rail':
        headerItemObje = {
          itemId: null,
          udmItemStatus: rowItemStatus,
          scheduledDate: rowItemDate,
          scheduledQty: +(event.newValue),
          scheduledUom: "BR",
          confirmationIndicator: "X",
          scheduleType : scheduledTypeRail,
          referenceDocumentIndicator: refDocIndicatorForRail,
          textLine: textLineObj.rail,
          contractPartner: contractPartnerValue,
          locationId: locationIdValue,
          valuationTypeDestinationLoc: valuationTypeDestinationLocValue,
          udmDailyBreakdownQty: null
        }
        break;
      case 'barge':
        headerItemObje = {
          itemId: null,
          udmItemStatus: rowItemStatus,
          scheduledDate: rowItemDate,
          scheduledQty: +(event.newValue),
          scheduledUom: "BR",
          confirmationIndicator: "X",
          textLine: textLineObj.barge,
          scheduleType: "D",
          referenceDocumentIndicator: refDocIndicatorForBarge,
          contractPartner: contractPartnerValue,
          locationId: locationIdValue,
          valuationTypeDestinationLoc: valuationTypeDestinationLocValue,
          udmDailyBreakdownQty: null
        }
        break;
      case 'pipeline':
        headerItemObje = {
          itemId: null,
          udmItemStatus: rowItemStatus,
          scheduledDate: rowItemDate,
          scheduledQty: +(event.newValue),
          scheduledUom: "BR",
          confirmationIndicator: "X",
          textLine: textLineObj.pipeline,
          scheduleType : scheduledTypePipeline,
          referenceDocumentIndicator: refDocIndicatorForPipeline,
          contractPartner: contractPartnerValue,
          locationId: locationIdValue,
          valuationTypeDestinationLoc: valuationTypeDestinationLocValue,
          udmDailyBreakdownQty: null
        }
        break;

      default:
        break;
    }
    this.updatedRows.nomination.draft[currentIndex].headerToItemNav.push(headerItemObje)
  }

  updateNominationAsDraft(event: any, rowStatus: any, rowItemStatus: any, rowItemDate: any) {
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
    let heirchyData = event.colDef.field.split('_');
    let currentMOT =  event.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase();
    // currentMOT = IsMultiTerminal ? 'pipeline' : currentMOT;
    let isSalesEdited = this.isSalesNomCellEdited(event,IsMultiTerminal);
    let carrierPlaceHolder = isSalesEdited ? 3: 4;
    let carrier = heirchyData[IsMultiTerminal ? 5 : carrierPlaceHolder];
    let textLineCarrier = carrier?.toLowerCase() === 'misc' ? '' : carrier;
    let supplierVal = isSalesEdited ? undefined : heirchyData[IsMultiTerminal ? 4 : 3];
    let textLineSupplier = supplierVal?.toLowerCase() === 'misc' ? '' : supplierVal;
    let scenarioType = this.getScenarioType(textLineSupplier, textLineCarrier);
    let textLineObj: any = {
      truck: `{Carrier:${textLineCarrier},Supplier:${textLineSupplier}}`,
      rail: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
      barge: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
      pipeline: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
    }

    if (currentMOT === 'truck') {
      this.saveTruckNomDraft(event, rowStatus, rowItemStatus, rowItemDate)
    } else {
      let currentIndex = this.updatedRows?.nomination?.draft?.findIndex((ele: any) => (ele.modeOfTransportDesc?.toLowerCase() === currentMOT))
      if (currentIndex === -1) {
        this.isDraftNomCurrentIndex(rowStatus, rowItemStatus, rowItemDate, event, heirchyData, textLineObj, scenarioType)
        this.checkIfTRSaveDisabledForDraft();
        this.updateIsNominationDataDraft();
      } else {
        this.updateEditedDraftNomPayload(currentIndex, rowItemStatus, rowItemDate, event, heirchyData, textLineObj)
        if(+event.newValue.toString().replace("-", "") === 0){
          this.updatedRows.nomination.draft.splice(currentIndex,1);
          this.checkIfTRSaveDisabledForDraft();
          this.updateIsNominationDataDraft();
          return;
        }
      }
    }
  }

  getReferenceDocumentIndicator(IsMultiTerminal:any, isSalesEdited? : any){
    if(isSalesEdited){
      return 'G'
    }
    return 'K'
  }

  getScheduleType(isSalesEdited:any, mot?: any){
    let scheduleType = (mot === 'pipeline' && !isSalesEdited) ? 'IT' : 'O';
    return (isSalesEdited || mot === 'pipeline') ? scheduleType : "D"
  }

  getScenarioType(textLineSupplier:any, textLineCarrier:any){
    if(this.selectedDeskOption === UDMDesk.EthanolUswc){
     return this.scenarioTypeForUswc(textLineSupplier, textLineCarrier);
    }
    else if(this.selectedDeskOption === UDMDesk.EthanolUsec){
      if(textLineSupplier && textLineSupplier !== null){ // considering for misc(truck and barge)(rail, tankt there wont be misc col), Fob should be by default selected
        return 'Supplier direct delivered';
      }
      else{
        return 'FOB';
      }
    }

    return null;
  }

  scenarioTypeForUswc(textLineSupplier: any, textLineCarrier: any){
    if((textLineSupplier && textLineSupplier !== null) && (textLineCarrier && textLineCarrier !== null)){
      return 'FOB';
    } else if ((textLineSupplier && textLineSupplier !== null) && (!textLineCarrier || textLineCarrier === null)){
      return 'Supplier direct delivered';
    } else if((textLineCarrier && textLineCarrier !== null) && (!textLineSupplier || textLineSupplier === null)){
      return 'Carrier managed inventory';
    } else return 'Supplier direct delivered'; // multiple entity sales column
  }

  getTextLineSupplierForSaveTruck(supplierVal: any) {
    return (supplierVal?.toLowerCase() === 'misc') ? null : supplierVal;
  }

  getTextLineCarrierForSaveTruck(carrier: any) {
    return (carrier?.toLowerCase() === 'misc') ? null : carrier;
  }

  getIsSalesEditedForSaveTruckNomDraft(isSalesEdited:any){
    return isSalesEdited ? 'Sale' : 'Supplier direct delivered';
  }

  saveTruckNomDraft(event: any, rowStatus: any, rowItemStatus: any, rowItemDate: any) {
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
    let newDraftDate: any = this.datePipe.transform(event.data.date, 'yyyy-MM-dd')?.split('-');
    newDraftDate[2] = '01';
    newDraftDate = newDraftDate.join('');
    let heirchyData = event.colDef.field.split('_');
    let isSalesEdited = this.isSalesNomCellEdited(event,IsMultiTerminal);
    let currentMOT = event.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase();
    let carrierPlaceHolder = isSalesEdited ? 3: 4;
    let carrier = heirchyData[IsMultiTerminal ? 5 : carrierPlaceHolder];
    let textLineCarrier = this.getTextLineCarrierForSaveTruck(carrier);
    let supplierVal = isSalesEdited ? undefined : heirchyData[IsMultiTerminal ? 4 : 3];
    let textLineSupplier = this.getTextLineSupplierForSaveTruck(supplierVal);
    let scenarioType = isSalesEdited ? 'Sale' : this.getScenarioType(textLineSupplier, textLineCarrier);
    let isMOTOn:any;
    let getTruckVolume:any;
    this.ethanolService.motToggleSwitch$.subscribe((isToggled:boolean)=>{
      isMOTOn = isToggled;
    })
    let getTruckId = event?.colDef?.field?.split('_')[0].includes('-') ?event?.colDef?.field?.split('_')[0]?.replace("-", " ") :event?.colDef?.field?.split('_')[0];
    this.ethanolService.getTruckVolume$.subscribe((data:[])=>{
      data.forEach((getTruckName:any)=>{
        if (getTruckName?.id === getTruckId) {
          getTruckVolume = getTruckName.volume;
        }

      })

    })
    let textLineObj: any = {
      truck: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
      rail: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
      barge: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
      pipeline: `{Author:${this.userEmail},MdfBy:null,SS:UDM}`,
    }

    let currentIndex = this.updatedRows?.nomination?.draft?.findIndex((ele: any) => (ele.modeOfTransportDesc?.toLowerCase() === currentMOT && newDraftDate === ele?.headerToItemNav[0].scheduledDate && textLineObj[currentMOT] === ele?.headerToItemNav[0].textLine))
    let valuationType = heirchyData[1].toLowerCase() === 'chevron' ? 'CHEVRONTEX' : heirchyData[1].toUpperCase();
    if (currentIndex === -1) {
      let EditedRowObj:any = {
        id: null,
        modeOfTransport: "J1",
        modeOfTransportDesc: "Truck",
        udmModeOfTransportDesc: "Truck",
        transportSystem: "TRUCK-US",
        deskFilter: this.selectedDeskParams,
        tdVehNo: "TRUCK-US",
        sys_status: rowStatus,
        udmTimeFrameOption:'Truck-Monthly',
        headerToItemNav: [
          {
            itemId: null,
            udmItemStatus: rowItemStatus,
            textLine: textLineObj.truck,
            shipperText:`{Type:null,Carrier:${textLineCarrier ? this.utilities.padSupplierCarrier(textLineCarrier) : null},Supplier:${textLineSupplier ? this.utilities.padSupplierCarrier(textLineSupplier) : null}}`, //NOSONAR lightweight logging
            scheduledDate: newDraftDate,
            scheduledQty: isMOTOn === true? +(event.newValue * getTruckVolume) : +(event.newValue),
            scheduledUom: "BR",
            confirmationIndicator: "X",
            scheduleType: isSalesEdited ? this.getScheduleType(isSalesEdited) : "D",
            referenceDocumentIndicator: this.getReferenceDocumentIndicator(IsMultiTerminal, isSalesEdited), // considering K(purchase will only be there for SDD)
            locationId: heirchyData[0].replace("-", " "),
            locationName: event.data[`${heirchyData[0]}_locationName`]?.split('(')[0],
            udmDisableValidations: true,
            udmDailyBreakdownQty: [
              {
                scheduledDate: rowItemDate,
                scheduledQty: isMOTOn === true? +(event.newValue * getTruckVolume) : +(event.newValue)
              }
            ]
          }
        ]
      }
      if(EditedRowObj.headerToItemNav[0].scheduleType === 'O'){
        EditedRowObj.headerToItemNav[0].valuationTypeOriginLoc  =  IsMultiTerminal ? valuationType : undefined;
      }
      else {
        EditedRowObj.headerToItemNav[0].valuationTypeDestinationLoc  =  IsMultiTerminal ? valuationType : undefined;
      }

      this.updatedRows.nomination.draft.push(EditedRowObj);
    } else {
      this.updateUDMUpdateRows(currentIndex,rowItemDate,event, isMOTOn, getTruckVolume);

      this.updatedRows.nomination.draft[currentIndex].headerToItemNav[0].scheduledQty = isMOTOn === true? +(event.newValue * getTruckVolume) : +(event.newValue);
    }
  }

  updateUDMUpdateRows(currentIndex:any,rowItemDate:any,event:any, isMOTOn:any, getTruckVolume:any){
    let udmBreakDownPresent = this.updatedRows.nomination.draft[currentIndex].headerToItemNav[0].udmDailyBreakdownQty.findIndex((ele: any) => ele.scheduledDate === rowItemDate)
    if (udmBreakDownPresent === -1) {
      this.updatedRows.nomination.draft[currentIndex].headerToItemNav[0].udmDailyBreakdownQty.push(
        {
          scheduledDate: rowItemDate,
          scheduledQty: isMOTOn === true? +(event.newValue * getTruckVolume) : +(event.newValue)
        }
      )
    } else {
      this.updatedRows.nomination.draft[currentIndex].headerToItemNav[0].udmDailyBreakdownQty[udmBreakDownPresent].scheduledQty = isMOTOn === true? +(event.newValue * getTruckVolume) : +(event.newValue);
    }
  }

  setMot(motDeSC: any){
    switch (motDeSC.toLowerCase()) {
      case "rail": {
        return 'R_';
      }
      case "barge": {
        return 'S';
      }
      case "pipeline": {
        return 'PL';
      }
    }
    return;
  }

  getCurrentIndexForUpdateNom(rowID:any,currentMOT:any){
    return this.updatedRows?.nomination?.others?.findIndex((ele: any) => (ele.id === rowID && ele.modeOfTransportDesc?.toLowerCase() === currentMOT))
  }

  updateNominationForSave(event: any, rowID: any, rowItemId: any, rowStatus: any, rowItemStatus: any, rowItemDate: any) {
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity';
    let isSalesEdited = this.isSalesNomCellEdited(event,IsMultiTerminal);
    let currentMOT = event.colDef.field.split('_')[IsMultiTerminal ? 3 : 2]?.toLowerCase();
    let isMOTOn:any;
    this.ethanolService.motToggleSwitch$.subscribe((isToggled:boolean)=>{
      isMOTOn = isToggled;
    })
    if (currentMOT === 'truck') {
      this.updateTruckForSave(event, rowID, rowItemId, rowStatus, rowItemStatus, rowItemDate)
    }
    else {
    let currentIndex = this.getCurrentIndexForUpdateNom(rowID,currentMOT)
    let createdBy = event.data[event.colDef.field + '_createdBy']
    if (currentIndex === -1) {
        this.updatedRows.nomination.others.push({
          id: rowID,
          modeOfTransportDesc: currentMOT.charAt(0).toUpperCase() + currentMOT.slice(1),
          udmModeOfTransportDesc: currentMOT.charAt(0).toUpperCase() + currentMOT.slice(1),
          modeOfTransport: this.setMot(currentMOT.charAt(0).toUpperCase() + currentMOT.slice(1)),
          deskFilter: this.selectedDeskParams,
          sys_status: rowStatus,
          headerToItemNav: [
            {
              itemId: rowItemId,
              textLine: `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
              udmItemStatus: rowItemStatus,
              scheduledDate: rowItemDate,
              scheduledQty: this.getSchdlQuantity(isMOTOn,event),
            }
          ]
        })
        this.checkIfTRSaveDisabledForOthers();
        this.updateIsNominationDataOthers();
      } else {
        let isItemAvailable = this.updatedRows?.nomination?.others[currentIndex].headerToItemNav?.findIndex((ele: any) => (ele.itemId === rowItemId))
        if (isItemAvailable === -1) {
          this.updatedRows.nomination.others[currentIndex].headerToItemNav.push({
            itemId: rowItemId,
            textLine: `{Author:${createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
            udmItemStatus: rowItemStatus,
            scheduledDate: rowItemDate,
            scheduledQty: event.newValue,
          });
          this.checkIfTRSaveDisabledForOthers();
          this.updateIsNominationDataOthers();
        } else {
          if(+event.newValue.toString().replace("-", "") === 0){
            this.updatedRows.nomination.others.splice(currentIndex,1);
            this.checkIfTRSaveDisabledForOthers();
            this.updateIsNominationDataOthers();
            return;
          }
          this.updatedRows.nomination.others[currentIndex].headerToItemNav[isItemAvailable].scheduledQty = event.newValue;
        }
      }
    }
  }

  isSalesNomCellEdited(event:any,IsMultiTerminal:any){
    return event.colDef.field.split('_')[IsMultiTerminal ? 2 : 1]?.toLowerCase() === 'sales'
  }

  updateTruckForSave(event: any, rowID: any, rowItemId: any, rowStatus: any, rowItemStatus: any, rowItemDate: any) {
    let isMOTOn:any;
    let getTruckVolume:any;
    this.ethanolService.motToggleSwitch$.subscribe((isToggled:boolean)=>{
      isMOTOn = isToggled;
    })
    let getTruckId = event?.colDef?.field?.split('_')[0].includes('-') ?event?.colDef?.field?.split('_')[0]?.replace("-", " ") :event?.colDef?.field?.split('_')[0];
    this.ethanolService.getTruckVolume$.subscribe((data:[])=>{
      data.forEach((getTruckName:any)=>{
        if (getTruckName?.id === getTruckId) {
          getTruckVolume = getTruckName.volume;
        }

      })

    })
    let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity'
    let heirchyData = event.colDef.field.split('_');
    let isSalesEdited = this.isSalesNomCellEdited(event,IsMultiTerminal);
    let carrierPlaceHolder = isSalesEdited ? 3: 4;
    let carrier = heirchyData[IsMultiTerminal ? 5 : carrierPlaceHolder];
    let textLineCarrier = this.getTextLineCarrierForSaveTruck(carrier);
    let supplierVal = isSalesEdited ?  undefined : heirchyData[IsMultiTerminal ? 4 : 3]
    let textLineSupplier = this.getTextLineSupplierForSaveTruck(supplierVal);
    let currentIndex = this.updatedRows?.nomination?.others?.findIndex((ele: any) => (ele.id === rowID))
    let isEstreceipts = event.colDef.colId === "estreceipts"
    let NomValueData =  this.getNomValues(event, isEstreceipts)
    let actualNom = isMOTOn === true? +(event.newValue * getTruckVolume) : +NomValueData.actualNom
    let estNom = +NomValueData.estNom;
    let udmTimeFrame = event.data[event.colDef.field + "_udmTimeFrame"];


    if (currentIndex === -1) {
      this.updatedRows.nomination.others.push({
        id: rowID,
        modeOfTransportDesc: "Truck",
        udmModeOfTransportDesc: "Truck",
        modeOfTransport: "J1",
        deskFilter: this.selectedDeskParams,
        sys_status: rowStatus,
        udmTimeFrameOption :udmTimeFrame,
        headerToItemNav: [
          {
            itemId: rowItemId,
            udmItemStatus: rowItemStatus,
            scheduledDate: rowItemDate,
            scheduledQty: isMOTOn === true? +(event.newValue * getTruckVolume) : event.newValue,
            textLine: `{Author:${this.userEmail},MdfBy:${this.userEmail},SS:UDM}`,
            shipperText:`{Type:null,Carrier:${textLineCarrier ? this.utilities.padSupplierCarrier(textLineCarrier) : null},Supplier:${textLineSupplier ? this.utilities.padSupplierCarrier(textLineSupplier) : null}}`, //NOSONAR lightweight logging
            udmDailyBreakdownQty: [
              {
                scheduledDate: rowItemDate,
                scheduledQty: actualNom,
                udmEstReceipts: estNom
              }
            ]
          }
        ]
      })
    } else {
      let isItemAvailable = this.updatedRows?.nomination?.others[currentIndex].headerToItemNav?.findIndex((ele: any) => (ele.itemId === rowItemId))
      if (isItemAvailable === -1) {
        this.updatedRows.nomination.others[currentIndex].headerToItemNav.push({
          itemId: rowItemId,
          udmItemStatus: rowItemStatus,
          deskFilter: this.selectedDeskParams,
          textLine: `{Carrier:${textLineCarrier},Supplier:${textLineSupplier}}`,
          udmDailyBreakdownQty: [
            {
              scheduledDate: rowItemDate,
              scheduledQty: actualNom,
              udmEstReceipts: estNom
            }
          ]
        });
      } else {
        let itemDateAvailable = this.updatedRows.nomination.others[currentIndex].headerToItemNav[isItemAvailable].udmDailyBreakdownQty?.findIndex((ele: any) => (ele.scheduledDate === rowItemDate))
        if (itemDateAvailable === -1) {
          this.updatedRows.nomination.others[currentIndex].headerToItemNav[isItemAvailable].udmDailyBreakdownQty.push(
            {
              scheduledDate: rowItemDate,
              scheduledQty: actualNom,
              udmEstReceipts: estNom
            }
          )
        } else {
          this.updateNomVlaueForEditedRow(currentIndex, isItemAvailable, itemDateAvailable, event, isEstreceipts,isMOTOn,getTruckVolume);
        }
      }
    }
  }

  getNomValues(event: any, isEstreceipts: any) {
    let parentField = event.colDef.field.split('_').slice(0, -1).join('_');
    let nomValues = {
      actualNom: event.data[parentField + "_nomactual_actualNomination"],
      estNom: event.data[parentField + "_estreceipts_actualNomination"]
    }
    if (isEstreceipts) {
      nomValues.estNom = event.newValue
    } else {
      nomValues.actualNom = event.newValue
    }
    return nomValues
  }

  updateNomVlaueForEditedRow(currentIndex: any, isItemAvailable: any, itemDateAvailable: any, event: any, isEstreceipts: any, isMOTOn:any, getTruckVolume:any){
    if (isEstreceipts) {
      this.updatedRows.nomination.others[currentIndex].headerToItemNav[isItemAvailable].udmDailyBreakdownQty[itemDateAvailable].udmEstReceipts = event.newValue;
    } else {
      this.updatedRows.nomination.others[currentIndex].headerToItemNav[isItemAvailable].udmDailyBreakdownQty[itemDateAvailable].scheduledQty = isMOTOn === true? +(event.newValue * getTruckVolume) : event.newValue;
    }
  }


  openModalPopup() {
    const modal = { open: true, modal: 'confirmModal' };
    this.confirmModal = {...modal};

  }

  closeConfirmModal(event: any) {
    const modal = { open: false, modal: 'confirmModal' }
    this.confirmModal = {...modal};
    this.confirmService.setModalAction({
      show: false,
      msg: 'cancel'
    })
  }

  confirmModalPopup(event: any) {
    this.confirmService.setModalAction({
      show: false,
      msg: 'confirm'
    });
    const modal = { open: false, modal: 'confirmModal' }
    this.confirmModal = {...modal};
    this.onSave();
  }

  checkAndDeleteEmptyValues() {
    this.updatedRows.nomination.draft.forEach((item: any, outerIndex: any, outObj: any) => {
      item.headerToItemNav = item.headerToItemNav.filter((each: any) => each.scheduledQty !== 0);
      if (!item.headerToItemNav.length) {
        outObj.splice(outerIndex, 1)
      }
    })
  }

  onSave() {
    if (this.isNominationDataEdit) {
      let sources: any = []
      this.checkAndDeleteEmptyValues();


      if (this.updatedRows.nomination.draft.length > 0) {
        this.utilities.getCreateUpdateMasterDataPayload(this.updatedRows.nomination.draft);
        sources.push(this.ethanolTruckService.saveEthanolNominationDataAsDraft(this.updatedRows.nomination.draft));
        this.myMonitoringService.logEvent('Save Draft Ethanol Nomination', {category:"Replenishment Planning"});
      }
      if (this.updatedRows.nomination.others.length > 0) {
        this.utilities.getCreateUpdateMasterDataPayload(this.updatedRows.nomination.others);
        sources.push(this.ethanolTruckService.saveEthanolNominationData(this.updatedRows.nomination.others));
        this.myMonitoringService.logEvent('Updated Ethanol Nomination', {category:"Replenishment Planning"});
      }
      forkJoin(sources).subscribe({
        next: (data: any) => {
          if (data[0].success) {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: `nomination has been created/updated`,
            });
          }
          this.updatedRows.nomination.draft = [];
          this.updatedRows.nomination.others = [];
          this.isNominationDataEdit = false;
          this.updatetrData()
        },
        error: (err: any) => {
          console.log(err);
          this.updatedRows.nomination.draft = [];
          this.updatedRows.nomination.others = [];
          this.isNominationDataEdit = false;
          this.updatetrData()
        },
        complete: () => {
          this.isDemandOverrideEdit = false;
        }
      }
      );
    } else {
      this.updatetrData()
    }
  }

  updatetrData() {
    if (this.updatedRows.tr.length > 0) {
      this.ethanolTruckService.saveEthanolTRData(this.updatedRows.tr).subscribe({
        next: (response: any) => {
          this.updatedRows.tr = []
          let currentView = this.ethonalSwitch.split('_')[0]?.toLowerCase();
          this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false }));
          this.utilities.getEthanolTRNomPyaloadMasterData(this.currentAppliedFilter);
          this.appState.dispatch(invokeGetTRTruckDataAPI({ payload: this.currentAppliedFilter, isPivotActive: currentView }));
          this.appState.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: false, ethanolTruckToggle: this.getEhtanolFilterResponse.ethanolTruckToggle, unitOfMeasure: this.getEhtanolFilterResponse.unitOfMeasure }));
        },
        error: (err: any) => {
          console.log(err);
          this.updatedRows.tr = []
          let currentView = this.ethonalSwitch.split('_')[0]?.toLowerCase();
          this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false }));
          this.utilities.getEthanolTRNomPyaloadMasterData(this.currentAppliedFilter);
          this.appState.dispatch(invokeGetTRTruckDataAPI({ payload: this.currentAppliedFilter, isPivotActive: currentView }));
          this.appState.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: false, ethanolTruckToggle: this.getEhtanolFilterResponse.ethanolTruckToggle, unitOfMeasure: this.getEhtanolFilterResponse.unitOfMeasure }));
        },
        complete: () => {
          this.isDemandOverrideEdit = false;
        }
      })
    }else {
          let currentView = this.ethonalSwitch.split('_')[0]?.toLowerCase();
          this.store.dispatch(triggerTRSave({ triggeredTRDataSave: false, isTRSaveEnabled: false, isClearFilter: false, receivedTotalFiltersResponse: false, receivedUpdatedFilterSelection: false }));
          this.utilities.getEthanolTRNomPyaloadMasterData(this.currentAppliedFilter);
          this.appState.dispatch(invokeGetTRTruckDataAPI({ payload: this.currentAppliedFilter, isPivotActive: currentView }));
          this.appState.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: false, ethanolTruckToggle: this.getEhtanolFilterResponse.ethanolTruckToggle, unitOfMeasure: this.getEhtanolFilterResponse.unitOfMeasure  }));
    }
  }

  updateHeaderStyle(colData: any, col: any) {
    if (colData?.type?.toLowerCase() === "carrier") {
      col.headerClass = (params: any) => {
        return 'ethonal-view triangle-icon ethanol-header-text_carrier ethanol_collapse_ellipsis'
      }
    }
    if (colData?.type?.toLowerCase() === "terminal") {
      col.headerClass = (params: any) => {
        return 'ethonal-view circle-icon ethanol-header-text_terminal ethanol_collapse_ellipsis'

      }
    }
    if (colData?.type?.toLowerCase() === "supplier") {
      col.headerClass = (params: any) => {
        return 'ethonal-view square-icon ethanol-header-text_supplier ethanol_collapse_ellipsis'
      }
    }

    if (['demand', 'forecast', 'totalInventory', 'nomination', 'mot', 'truck', 'rail', 'barge', 'receipts', 'offtaker', 'sales', 'pipeline'].includes(colData?.type?.toLowerCase())) {
      col.headerClass = (params: any) => {
        return `ethonal-view ethanol-header-text ethanol-header-text_terminal`
      }
    }
  }

  isTSNC(child: any, col: any, colData: any, i: any) {
    if (this.ethonalSwitch === 'terminal_supplier_nomination_carrier') {
      if (child && (col.headerName !== 'total') && (colData.headerName !== 'totalInventory')) {
        col.columnGroupShow = 'open';
      }
    } else if (i) {
      col.columnGroupShow = 'open';
    }
    return col
  }

  generateColumnFn(colData: any, child: boolean = false, i: number = 0) {
    let col: any = {
      children: []
    };
    if (colData.children !== null) {
      if(colData.type == "terminal"){
        col['headerName'] = colData.headerId;
      }else{
        col['headerName'] = colData.headerName;
      }
      col['colId'] = colData.headerId;
      if (!['nomination', 'demand', 'totalInventory'].includes(colData.headerName)) {
        col.headerClass = (params: any) => {
          return 'my-class-2';
        }
      }
      this.updateHeaderStyle(colData, col)
      let IsMultiTerminal = this.terminalOweneshipValue?.name !== 'single entity' && colData.field.split('_').length === 2;
      if (this.ethonalSwitch === 'terminal_supplier_nomination_carrier' && colData.type?.toLowerCase() !== 'terminal' && !IsMultiTerminal && colData.type?.toLowerCase() !== 'carrier') {
        colData = this.addTotalColumn(colData)
      }

      col.stickyLabel = true;
      col.marryChildren = true;
      col.openByDefault = true;
      col.groupId = colData.field ? `${colData.field}_${colData.type}` : col.headerName;
      col = this.isTSNC(child, col, colData, i)

      col['children'] = colData.children?.map((child: any, index: number) => {
        return this.generateColumnFn(child, true, index);
      });
      if (col.groupId) {
        this.getAllColumnGroupId.push(col)
      }
      return col;
    } else {
      let res = this.columnLowLevel(colData, i);
      this.updateHeaderStyle(colData, col)
      return res;
    }
  }

  addTotalColumn(colData: any) {
    let totalIndex = colData.children.findIndex((ele: any) => ele.headerName === 'totalinventory' || ele.headerName === 'total')
    if (totalIndex === -1) {
      let total: any = {
        type: colData.children[0].type,
        field: colData.field + '_Total',
        headerName: "total",
        columnGroupShow: 'closed',
        children: null,
        editable: false,
        valueGetter: (params: any) => this.getTotalColumnValue(params, colData.children)
      }
      colData.children.forEach((element: any) => {
        if (element.children !== null && element.children.length > 0 && element.type?.toLowerCase() !== 'carrier') {
          element = this.addTotalColumn(element) //NOSONAR lightweight logging
        }
      });
      if (colData.children.length > 1) {
        colData.children.push(total);
      }
    } else if(colData.headerId.toLowerCase() === 'demand') {
      colData.children[totalIndex].valueGetter = (params: any) => this.getTotalVal(params, colData.children)
    }
    else {
      colData.children[totalIndex].valueGetter = (params: any) => this.getTotalColumnValue(params, colData.children)
    }
    return colData;
  }

  getTotalVal(params: any, children: any) {
    let index = children.findIndex((ele: any) => ele.headerName === 'total')
    let value =(params.data[children[index].field] === 0)? this.getRowSum(params, children):params.data[children[index].field]
    return value
  }
  getTotalColumnValue(params: any, children: any) {
    let value = this.getRowSum(params, children)
    return value
  }

  isReceiptCellEditable(params:any){
    let parentField = params.colDef.field.split('_').slice(0, -1).join('_');
    let isEstreceipts = params.colDef.colId === "estreceipts"
    let dataField = parentField + "_nomactual_itemStatus"
    let nomActualStatus = params.data[`${dataField}`]
    let isFutureDate = new Date(params.data.date).getTime() >= new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
    let isPastDate = new Date(params.data.date).getTime() < new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
    let isEditabe = false;
    let isValEditable = params.data[params?.colDef?.field + '_isEditable']
    if(isEstreceipts){
      isEditabe = isPastDate && ['unpublished','published','draft'].includes(nomActualStatus?.toLowerCase()) && isValEditable;
    } else {
      isEditabe = isFutureDate && isValEditable
    }
    return isEditabe
  }

  isSalesCellEditable(params:any, fieldType: any){
    if(fieldType === "sales"){
      return ((new Date(params.data.date).getTime() >= new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) && params.data[params.colDef.field+'_isEditable'])
    } else {
      return (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime() || this.utilities.checkPast14DaysEditable(params))
    }
  }

  updateInventoryCol(colData:any,col:any,){
    if (["totalinventory"].includes(colData.headerName?.toLowerCase())) {
      col['editable'] = (params: any) => this.utilities.checkPast14DaysEditable(params)

      col['valueGetter'] = (param: any) => this.calculateTotalForcastInventry(param)
    } else if (["availableinventory"].includes(colData.headerName?.toLowerCase())) {
      col['valueGetter'] = (param: any) => this.calculateInventoryAvailable(param)
    }

    if (['totalinventory'].includes(colData.headerName?.toLowerCase()) && colData.field.split('_')[1]?.toLowerCase() !== 'totalinventory') {
      col['editable'] = (params: any) => this.utilities.checkPast14DaysEditable(params)

      col['valueGetter'] = (param: any) => this.calculateForcastInventry(param)
    } else if (['totalinventory'].includes(colData.headerName?.toLowerCase()) && this.terminalOweneshipValue?.name !== 'single entity') {
      col['valueGetter'] = (param: any) => this.calculateMultEntityTotalForcastInventry(param)
    }
  }
  isColumnLowLevelTSNC(col: any, colData: any) {
    col['cellRendererFramework'] = EthanolMoreMenuComponent;

    let fieldType = this.terminalOweneshipValue?.name === 'single entity' ? colData.field.split('_')[1] : colData.field.split('_')[2]
    if (['receipts'].includes(fieldType?.toLowerCase())) {
      col['editable'] = (params: any) => this.isReceiptCellEditable(params)
    }
    if (["demand", "sales"].includes(fieldType?.toLowerCase())) {
      col['editable'] = (params: any) => this.isSalesCellEditable(params, fieldType?.toLowerCase())
    }
    if (fieldType?.toLowerCase() === 'totalinventory') {
      this.updateInventoryCol(colData,col);
    }

    if (['totalinventory'].includes(colData.headerName.toLowerCase()) && colData.field.split('_')[1].toLowerCase() !== 'totalinventory') {
      col['editable'] = (params: any) => this.utilities.checkPast14DaysEditable(params)

      col['valueGetter'] = (param: any) => this.calculateForcastInventry(param)
    } else if (['totalinventory'].includes(colData.headerName.toLowerCase()) && colData.field.split('_')[1].toLowerCase() === 'totalinventory' && this.terminalOweneshipValue?.name !== 'single entity') {
      col['valueGetter'] = (param: any) => this.calculateMultEntityTotalForcastInventry(param)
    }
    if (fieldType?.toLowerCase() === 'demand' && !["total"].includes(colData.headerName?.toLowerCase()) && colData?.field.split('_')[0] === 'CARSONSH') {
      col['editable'] = false;
    }

    if(colData.headerName === 'totalinventory' || colData.headerName === 'availableinventory' || colData.headerName.toLowerCase() == "demand override"){
      col['cellRendererFramework'] = GridAlertTooltipRendererComponent;
      col['cellRendererSelector'] = (params: any) => this.renderAlertTooltip(params, colData);
    }

    if(colData.headerName === 'totalinventory' || colData.headerName === 'availableinventory'){
      let compareCheckResult = false;
      let minValue: any;
      let maxValue: any;
      let minEsl: any;
      let maxEsl: any;
      //pick current date value for min and max inventory
      let currentDate = new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
      const filterarray=this.rowData2.filter((element:any)=>
          new Date(element.date).getTime()==currentDate
      )
      if(filterarray.length>0 ){
         let value=filterarray[0];
         let fieldDataName = colData.field.split('_')
             fieldDataName =  fieldDataName.splice(0, fieldDataName.length-1).join('_')+'_'+colData.headerId;
             minValue=value[fieldDataName + '_minInventory'];
             maxValue=value[fieldDataName + '_maxInventory'];
             minEsl=value[colData.field + '_minESL'];
        maxEsl=value[colData.field + '_maxESL'];
      }

      const cellClassRules = {
        'warn-min-max-limit': (params: any) => this.utilities.warnMinMaxLimit(params, colData,minValue,maxValue),
        'esl-warn-min-max-limit': (params: any) => this.utilities.warnEslMinMaxLimit(params, colData,minEsl,maxEsl),
        'compare-check-true': (params: any) => {
          if(!this.utilities.checkForPastDays(params, 8)) return false;
          const result:boolean = this.utilities.compareInventoryWithPhyInv(params, this.currentUOM);
          compareCheckResult = result;
          return result;
        },
        'compare-check-false': (params: any) => this.utilities.checkForPastDays(params, 8) && !compareCheckResult
      };
      col['cellClassRules'] = cellClassRules;
    }

    if ((col.headerName !== 'total') && (colData.headerName !== 'totalinventory')) {
      col.columnGroupShow = 'open'
    }
    switch (fieldType?.toLowerCase()) {
      case 'daysofsupply':
        col['valueGetter'] = (param: any) => this.calculateDaysOfSupply(param);
        break;
      case 'availableinventory':
        col['valueGetter'] = (param: any) => this.calculateAvailableForecastedInventory(param);
        break;
      case 'comments':
          col['editable'] = true;
          col.tooltipField = colData.field;
          break;
      default:
        break;
    }
    return col
  }

  calculateAvgDailyLifting(params: any, fieldDataName: string): number {
    const next14Days = Array.from({ length: 14 }).map((_, dayIndex) => {
      const forecastDate = new Date(params.data.date);
      if(isNaN(forecastDate.getTime()))
        return;

      forecastDate.setDate(forecastDate.getDate() + dayIndex);
      return this.datePipe.transform(forecastDate, 'yyyy-MM-dd');
    });

    const validDemands = this.rowData2
      .filter((row: any) => {
        const dateMatch = next14Days.includes(this.datePipe.transform(row.date, 'yyyy-MM-dd') || '');
        const value = +row[`${fieldDataName}_Demand`];
        return dateMatch && value > 0;
      })
      .map((row: any) => +row[`${fieldDataName}_Demand`]);

    const totalDemand = validDemands.reduce((sum: number, value: number) => sum + value, 0);
    const daysWithDemand = validDemands.length;

    return Number((daysWithDemand > 0 ? totalDemand / daysWithDemand : 0));
  }

  calculateDaysOfSupply(params: any) {
    const fieldType = params.colDef.field;
    const currentRowNode = params.api.getRowNode(`${params.node.rowIndex}`)!;
    const fieldName = params.colDef.field;

    if (new Date(params.data.date).getTime() >= new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {

      let fieldDataName = fieldType.split('_')
      fieldDataName = fieldDataName.splice(0, fieldDataName.length-1).join('_');

      const availableInventory = (+currentRowNode.data[fieldDataName + '_totalInventory'] || 0) -
                                   (+currentRowNode.data[`${fieldDataName}_totalInventory_minInventory`] || 0);

        const avgDailyLifting = this.calculateAvgDailyLifting(params, fieldDataName);

        currentRowNode.data[`${fieldDataName}_daysOfSupply_avgDailyLifting`] = avgDailyLifting;

        let daysOfSupply = avgDailyLifting ? (availableInventory / avgDailyLifting) : 0;
        daysOfSupply = (daysOfSupply !== Infinity && daysOfSupply !== -Infinity && daysOfSupply > 0) ? daysOfSupply : 0;

        params.data[fieldName] = daysOfSupply;

        return daysOfSupply;
    } else {
        return +currentRowNode.data[fieldName];
    }
  }

  calculateAvailableForecastedInventory(params: any) {
    const fieldType = params.colDef.field; //.split('_').slice(0, 2).join('_');
    const currentRowNode = params.api.getRowNode(`${params.node.rowIndex}`)!;
    const fieldName = params.colDef.field;
    let fieldDataName = fieldType.split('_')
      fieldDataName =  fieldDataName.splice(0, fieldDataName.length-1).join('_');
    const foreCastedValue = (+currentRowNode.data[fieldDataName + '_' + 'totalInventory'] || 0) - (+currentRowNode.data[`${fieldDataName}_totalInventory_minInventory`] || 0);
    if(foreCastedValue===0)
    return '';
    params.data[fieldName] = foreCastedValue;
    return foreCastedValue;
  }


//To find previous date's actual data ignoring multiple child rows
findPreviousDateData(params: any) {
  const givenDate: any = this.datePipe.transform(params.data.date, 'yyyy-MM-dd');
  const findPreviousDate = this.getPreviousDate(givenDate);
  let prevDateIndex = this.rowData2.findIndex((val: any, i: any) =>
    (val.date === '' ? '1970-01-01T00:00:00' : val.date) === findPreviousDate
  )
  prevDateIndex = prevDateIndex === -1 ? 0 : prevDateIndex;
  return params.api.getRowNode(
    prevDateIndex
  )
}

getPreviousDate(inputDate: any) {
  // Create a new Date object using the inputDate
  const currentDate = new Date(inputDate);
  // Subtract one day by setting the date to the previous day
  currentDate.setDate(currentDate.getDate() - 1);
  // Return the previous date as a string in the format "YYYY-MM-DD"
  return `${currentDate.toISOString().split('T')[0]}T00:00:00`; //.toISOString().split('T')[0];
}

findPreviousReceiptDelVol(params: any, isDelivery = false) {
  let gridDate = params.data.date;
  const givenDate: any = this.datePipe.transform(gridDate, 'yyyy-MM-dd');
  const findPreviousDate = this.getPreviousDate(givenDate);
  const fieldName = params.colDef.field;
  const fieldDataName = fieldName.split('_').slice(0, 2).join('_');
  let prevDateRow = this.rowData2.find((val: any, i: any) =>
    (val.date === '' ? '1970-01-01T00:00:00' : val.date) === findPreviousDate
  );
  let prevDateIndex = this.rowData2.findIndex((val: any, i: any) =>
    (val.date === '' ? '1970-01-01T00:00:00' : val.date) === findPreviousDate
  );
  if (!isDelivery) {
    prevDateIndex = this.getPreviousDateIndex(prevDateRow, fieldDataName, findPreviousDate, prevDateIndex);
  } else {
    prevDateIndex = this.getPreviousDelVolDateIndex(prevDateRow, fieldDataName, findPreviousDate, prevDateIndex);
  }
  return params.api.getRowNode(
    prevDateIndex
  )
}

getPreviousDateIndex(prevDateRow: any, fieldDataName: any, findPreviousDate: any, prevDateIndex: any) {
  if (prevDateRow) {
    if ((prevDateRow[`${fieldDataName}_ReceiptId`] === 'multiple') || (prevDateRow[`${fieldDataName}_ReceiptId`] !== 'multiple' && (prevDateRow[`${fieldDataName}_ReceiptId`] !== ''))) {
      prevDateIndex = this.rowData2.findIndex((val: any, i: any) =>
        (val.date === '' ? '1970-01-01T00:00:00' : val.date) === findPreviousDate
      )
    } else if (prevDateRow[`${fieldDataName}_ReceiptId`] === '')  {
      prevDateIndex = this.rowData2.findIndex((val: any, i: any) =>
        (val.scheduledDate ?? '') === findPreviousDate
      )
    }
  }
  return prevDateIndex;
}

getPreviousDelVolDateIndex(prevDateRow: any, fieldDataName: any, findPreviousDate: any, prevDateIndex: any) {
  if (prevDateRow) {
    if ((prevDateRow[`${fieldDataName}_DeliveryId`] === 'multiple')||(prevDateRow[`${fieldDataName}_DeliveryId`] !== 'multiple' && (prevDateRow[`${fieldDataName}_DeliveryId`] !== ''))) {
      prevDateIndex = this.rowData2.findIndex((val: any, i: any) =>
        (val.date === '' ? '1970-01-01T00:00:00' : val.date) === findPreviousDate
      )
    } else if (prevDateRow[`${fieldDataName}_DeliveryId`] === '')  {
      prevDateIndex = this.rowData2.findIndex((val: any, i: any) =>
        (val.scheduledDate ?? '') === findPreviousDate
      )
    }
  }
  return prevDateIndex;
  }

  renderAlertTooltip(params: any, colData: any) {

    let returnRenderer = {};

    //index 0: location code; index 1: "Inventory" string
    let stringSplit = colData.field.split("_");

    this.ethanolAlerts.forEach(currentAlert => {

      if(currentAlert.alertDate == null){
        return;
      }

      //The alert must match the material number, location name, and the date to be rendered in a tooltip manner
      if(currentAlert.alertDate.split(" ")[0] !== this.formatDate(params.data.date)
          || currentAlert.location.toLowerCase() !== stringSplit[0].toLowerCase()
          || (currentAlert.desk.toLowerCase() !== "uswc" && currentAlert.desk.toLowerCase() !== "usec")){
        return;
      }
      //Finally we make sure that we are looking at total inventory alert type to render the tooltip
      if (stringSplit[1].toLowerCase().includes("inventory") && currentAlert.ruleType.toLowerCase() === "inventory"
      || (stringSplit[1].toLowerCase().includes("demand") && currentAlert.ruleType.toLowerCase() === "actualdemand")) {
        returnRenderer = {
          component: GridAlertTooltipRendererComponent,
          params: {
            alert: currentAlert,
          },
        };
      }
    })

    return returnRenderer;
  }

  formatDate(dateString: string) {
    let parts = dateString.split('T')[0].split('-');
    let date = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));

    let day = String(date.getDate());
    let month = String(date.getMonth() + 1);
    let year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  alertWarningCheck(params: any): boolean{
    let locationName = params.colDef.field.split("_")[0].toLowerCase();
    //If we do not have parameters for this location, we will not show anything
    if(this.parametersMapping.get(locationName) !== null ){
      return false;
    }

    const uom = this.parametersMapping.get(locationName)![0].uom;
    let minEslLimit = this.parametersMapping.get(locationName)![0].minESL;
    let maxEslLimit = this.parametersMapping.get(locationName)![0].maxESL;

    minEslLimit = this.unitConversionService.convertUnits(minEslLimit, uom, this.currentUOM);
    maxEslLimit = this.unitConversionService.convertUnits(maxEslLimit, uom, this.currentUOM);

    //If we never got a proper material response for the selected dropdowns, we will not highlight
    if(params.value <= minEslLimit || params.value >= maxEslLimit){
      return true;
    }
    return false;
  }

  alertDangerCheck(params: any): boolean{

    let locationName = params.colDef.field.split("_")[0].toLowerCase();
    //If we do not have parameters for this location, we will not show anything
    if(this.parametersMapping.get(locationName) == null){
      return false;
    }
    const uom = this.parametersMapping.get(locationName)![0].uom;
    let minInventoryLimit = this.parametersMapping.get(locationName)![0].minInventory;
    let maxInventoryLimit = this.parametersMapping.get(locationName)![0].maxInventory;

    minInventoryLimit = this.unitConversionService.convertUnits(minInventoryLimit, uom, this.currentUOM);
    maxInventoryLimit = this.unitConversionService.convertUnits(maxInventoryLimit, uom, this.currentUOM);

    //If we never got a proper material response for the selected dropdowns, we will not highlight
    if(params.value <= minInventoryLimit || params.value >= maxInventoryLimit){
      return true;
    }
    return false;
  }

  columnLowLevel(colData: any, i: number = 0) {
    let col: ColDef = {};
    col.field = colData.field;
    col.headerName = colData.headerName;
    col.field = colData.field;
    col['colId'] = colData.headerId;
    col.columnGroupShow = colData.columnGroupShow;
    col['editable'] = colData.editable
    col.spanHeaderHeight = true;
    col['valueGetter'] = colData.valueGetter;
    col['cellStyle'] = (params: any) => {
      if (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
     // To highlight last updated cell
     this.utilities.highlightLastUpdatedCell(col);
    if (this.ethonalSwitch === 'terminal_supplier_nomination_carrier') {
      col = this.isColumnLowLevelTSNC(col, colData)
    } else if (i) {
      col.columnGroupShow = 'open'
    }
    col['valueFormatter'] = (params: any) => {
      if(colData?.headerId?.toLowerCase() === 'comments'){
        return params.data[colData.field]
      }
      return this.utilities.formatNumber(params.data[colData.field])
    }


    if (colData.headerId?.toLowerCase() === 'totalinventory' || colData.headerId?.toLowerCase() === 'availableinventory') {
      //pick current date value for min and max inventory
      let currentDate = new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
      let minValue;
      let maxValue;
      const filterarray=this.rowData2.filter((element:any)=>
          new Date(element.date).getTime()==currentDate
      )
      if(filterarray.length>0 ){
         let value=filterarray[0];
         let fieldDataName = colData.field.split('_')
         fieldDataName =  fieldDataName.splice(0, fieldDataName.length-1).join('_')+'_'+colData.headerId;
             minValue=value[fieldDataName + '_minInventory'];
             maxValue=value[fieldDataName + '_maxInventory'];
      }


      col.width = 210;
      col.headerClass = (params: any) => {
        return `ethonal-view ethanol-header-text ethanol-header-text_terminal`
      }
      this.detectChanges.detectChanges();
      const lastUpdatedInv = this.getInvDetails.find((e:any)=> (  [`${e.field}_totalInventory`,`${e.field}_availableInventory`].includes(colData.field)) && e.physicalInventoryTimeStamp !=='');
      col.headerComponentParams = {
        template:
          `<div class="ag-cell-label-container" role="presentation">
          <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
          <div ref="eLabel" class="ag-header-cell-label test" role="presentation">
            <div>
              <div ref="eText" class="ag-header-cell-text"></div>
              <div class="ag-header-cell-text min-max">min: ${this.utilities.formatNumberPipeline(minValue,false)} | max: ${this.utilities.formatNumberPipeline(maxValue,false)}</div>
              <div class="ag-header-cell-text inv-timestamp">opening inv. update: ${this.datePipe.transform(lastUpdatedInv?.physicalInventoryTimeStamp, 'MMM dd, HH:mm')??'NA'}
              </div>
            </div>
          </div>
      </div>`
        ,
      };
    }

    this.updateHeaderStyle(colData, col)
    if (col.editable) {
      this.columneLowLevelcondition(col)
    }
    return col;
  }
  columneLowLevelcondition(col: any){
      if( col?.colId?.toLowerCase() === 'demand'){
      col['cellClass'] = (params: any) => this.utilities.checkPast14DaysEditable(params)?'highlightEditableCell':''
      }else {
        col['cellClass'] = (params: any) => {
          if(new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
           return 'highlightEditableCell';
          }
        return null;
      }
    }
  }
  generateCol() {
    const testData: any = this.columnAPIResponse?.colDef
    this.ethonalSwitch = this.ethonalSwitch ? this.ethonalSwitch : 'terminal_supplier_nomination_carrier'
    let structure = testData.map((response: any) => {
      return this.generateColumnFn(response);
    })

    this.columnDefs2 = [
      {
        field: 'date',
        colId: 'date',
        width: 150,
        filter: 'agNumberColumnFilter',
        headerName: 'date',
        pinned: 'left',
        spanHeaderHeight: true,
        cellClass: 'text-right',
        cellRenderer: (data: any) => {
          return this.datePipe.transform(new Date(data.data.date), 'EEEEEE, MM/dd/yy')
        },
        headerClass: "ag-right-aligned-header ethonal-view_header-cell_date",
        cellStyle: (params: any) => {
          if (new Date(params.data?.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
            return { fontWeight: 'bold' };
          }
          return null;
        }

      },
      ...structure];

  }

  getRowStyle: any = (data: any) => {
    let rowDate = new Date(data.data?.date).getTime()
    let currentDate = new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()
    if (rowDate === currentDate) {
      return { 'background-color': '#E4F4FA', 'color': '#0B2D71', 'fontWeight': 'bold' }
    }
    return null
  }

  checkEditableForInventory(params: any) {
    return new Date(params.data.date).getTime() <= new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
  }

  calculateForcastInventry(params: any) {
    const fieldType = params.colDef.field.split('_')[0];
    const offtakerName = params.colDef.field.split('_')[1];
    const currentRowNode = params.api.getRowNode(`${params.node.rowIndex}`)!;
    const fieldName = params.colDef.field;

    const lastUpdatedInv = this.getInvDetails.find((e:any)=>params.colDef.field === `${e.field}_TotalInventory` && e.isUdmManagedInv); // && e.physicalInventoryTimeStamp
    const isUdmInvEdited =params.data[`${fieldName}_udmInvEditedDatetime`];
    if(lastUpdatedInv){
      if(isUdmInvEdited){
         this.editedInvObj[`isInvEditedForRowDate_${fieldName}`] = params.data.date;
         this.editedInvObj[`lastUpdatedInvForRowDate_${fieldName}`]= params.data[`${fieldName}_TotalInventory`];
         return currentRowNode.data[fieldName];
      }else if (!isUdmInvEdited && this.editedInvObj[`isInvEditedForRowDate_${fieldName}`] && (new Date(params.data.date).getTime() > new Date(this.editedInvObj[`isInvEditedForRowDate_${fieldName}`]).getTime()) && this.gridInitialized) {
        let previousRow = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
        previousRow = this.findPreviousRowData(previousRow,params);
        let fieldDataName = this.findFieldName(fieldType,offtakerName)
        let nom = this.getRecieptTotal(previousRow, fieldType, offtakerName);
        let previousRowInv = +(previousRow ? previousRow.data[fieldName] : 0);

        const currentRowIndex = this.rowData2.findIndex((obj:any)=>obj.date === params?.data?.date);
        const key = `${fieldName}_udmInvEditedDatetime`;
        const array = this.rowData2;
        const previousUpdatedRow = this.utilities.searchBackwards(array,key,currentRowIndex);
        if(previousUpdatedRow.date === previousRow?.data?.date){
          previousRowInv =previousUpdatedRow[fieldName];
        }

        let newValue =  previousRowInv + nom - +previousRow?.data[fieldDataName + '_' + 'Demand'] - (+previousRow?.data[fieldDataName + '_' + 'Sales'])
        params.data[fieldName] = newValue;
        return +newValue
      } else if(params.newValue){
        const updatedColumn = params.column.colId;
        const rowNode = this.gridApi2.getRowNode(params.node.rowIndex)!;
        rowNode.setDataValue(updatedColumn,params.newValue);
      } else{
        return this.invCalcforNonUdmManagedInventory(params,fieldType,fieldName,lastUpdatedInv,currentRowNode,offtakerName);
      }
    } else{
       return this.invCalcforNonUdmManagedInventory(params,fieldType,fieldName,lastUpdatedInv,currentRowNode,offtakerName);
    }
  }

  findPreviousRowData(previousRow:any,params:any){
    if (this.fullWidthRowTexts.find(value =>
      value === previousRow?.data[`fullWidthText`]
    )) {
      previousRow = params.api.getRowNode(
        `${params.node.rowIndex! - 2}`
      )!;
    } else {
      previousRow = params.api.getRowNode(
        `${params.node.rowIndex! - 1}`
      )!;
    }
    return previousRow;
  }

  findFieldName(fieldType:any,offtakerName:any){
    let fieldDataName = fieldType;
    if (this.terminalOweneshipValue?.name !== 'single entity') {
      fieldDataName = fieldType + '_' + offtakerName
    }
    return fieldDataName;
  }

  invCalcforNonUdmManagedInventory(params:any,fieldType:any,fieldName:any,lastUpdatedInv:any,currentRowNode:any,offtakerName:any){
    if (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
      let previousRow = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
      if (this.fullWidthRowTexts.find(value =>
        value === previousRow?.data[`fullWidthText`]
      )) {
        previousRow = params.api.getRowNode(
          `${params.node.rowIndex! - 2}`
        )!;
      } else {
        previousRow = params.api.getRowNode(
          `${params.node.rowIndex! - 1}`
        )!;
      }
      let fieldDataName = fieldType;
      if (this.terminalOweneshipValue?.name !== 'single entity') {
        fieldDataName = fieldType + '_' + offtakerName
      }
      let nom = this.getRecieptTotal(previousRow, fieldType, offtakerName);
      let sales = this.getSalesTotal(previousRow, fieldType, offtakerName);
      let previousRowInv = +(previousRow ? previousRow.data[fieldName] : 0);
      let newValue =  previousRowInv + nom - +previousRow?.data[fieldDataName + '_' + 'Demand'] - sales;
      params.data[fieldName] = newValue;
      return +newValue
    } else {
      return +currentRowNode.data[fieldName]
    }
  }

  getRecieptNomTotal(element: any, params: any, nom: any) {
    if (element.headerName?.toLowerCase() === "receipts") {
      nom = + (element?.children?.length > 0 ? this.getRowSum(params, element.children, true) : params.data[element.field])
    }
    return nom
  }

  getSalesNomTotal(element: any, params: any, nom: any){
    if (element.headerName?.toLowerCase() === "sales") {
      nom = + (element?.children?.length > 0 ? this.getRowSum(params, element.children, true) : params.data[element.field]);
    }
    return nom
  }

  getSalesTotal(params: any, fieldType: any, offtakerName: any){
    let nom = 0;
    this.columnDefs2.forEach((ele: any) => {
      if (this.getCamelCaseString(ele.colId)?.toLowerCase() === fieldType?.toLowerCase()) {
        ele?.children?.forEach((element: any) => {
          if(this.terminalOweneshipValue?.name === 'multiple entities' && element.headerName?.toLowerCase() === offtakerName?.toLowerCase()){
            element?.children?.forEach((offTakerEle: any) => {
            nom = this.getSalesNomTotal(offTakerEle,params, nom)
            })
      }
        })
      }
    })
    return nom;
  }

  getRecieptTotal(params: any, fieldType: any, offtakerName: any) {
    let nom = 0
    this.columnDefs2.forEach((ele: any) => {
      if (this.getCamelCaseString(ele.colId)?.toLowerCase() === fieldType?.toLowerCase()) {
        ele?.children?.forEach((element: any) => {
          if(this.terminalOweneshipValue?.name === 'single entity'){
           nom = this.getRecieptNomTotal(element,params, nom)
          }else if(element.headerName?.toLowerCase() === offtakerName?.toLowerCase()){
              element?.children?.forEach((offTakerEle: any) => {
              nom = this.getRecieptNomTotal(offTakerEle,params, nom)
              })
          }
        });
      }
    })

    return nom;
  }

  calculateTotalForcastInventry(params: any) {
    let receiptTotal = 0
    let demandTotal = 0
    let salesTotal = 0
    const fieldType = params.colDef.field.split('_')[0];
    const currentRowNode = params.api.getRowNode(`${params.node.rowIndex}`)!;
    const fieldName = params.colDef.field;
    const lastUpdatedInv = this.getInvDetails.find((e:any)=>params.colDef.field === `${e.field}_TotalInventory` && e.isUdmManagedInv);     //&& e.physicalInventoryTimeStamp
    const isUdmInvEdited =params.data[`${fieldName}_udmInvEditedDatetime`];
    if(lastUpdatedInv){
      if(isUdmInvEdited){
         this.editedInvObj[`isInvEditedForRowDate_${fieldName}`] = params.data.date;
         this.editedInvObj[`lastUpdatedInvForRowDate_${fieldName}`]= params.data[`${fieldName}_TotalInventory`];
         return currentRowNode.data[fieldName];
      }else if (!isUdmInvEdited && this.editedInvObj[`isInvEditedForRowDate_${fieldName}`] && (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) && this.gridInitialized) {
        let previousRow = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
        previousRow = this.findPreviousRowData(previousRow,params);
        let fieldDataName = fieldType;
        let allTotal = this.getHubRecieptAndDemandTotal(previousRow, fieldType)
        receiptTotal = allTotal.receiptTotal
        demandTotal = allTotal.demandTotal;
        salesTotal = allTotal.salesTotal;
        let previousRowInv = (+previousRow.data[fieldName]);

        const currentRowIndex = this.rowData2.findIndex((obj:any)=>obj.date === params?.data?.date);
        const key = `${fieldName}_udmInvEditedDatetime`;
        const array = this.rowData2;
        const previousUpdatedRow = this.utilities.searchBackwards(array,key,currentRowIndex);
        if(previousUpdatedRow.date === previousRow?.data?.date){
          previousRowInv =previousUpdatedRow[fieldName];
        }

       params.data[fieldName] = previousRowInv + receiptTotal - demandTotal - salesTotal
        return previousRowInv + receiptTotal - demandTotal - salesTotal
      } else if(params.newValue){
        const updatedColumn = params.column.colId;
        const rowNode = this.gridApi2.getRowNode(params.node.rowIndex)!;
        rowNode.setDataValue(updatedColumn,params.newValue);
      } else{
        return this.invCalcforNonUdmManagedTotalForcastInventry(params,fieldType,fieldName,currentRowNode,receiptTotal,demandTotal, salesTotal);
      }
    } else{
       return this.invCalcforNonUdmManagedTotalForcastInventry(params,fieldType,fieldName,currentRowNode,receiptTotal,demandTotal, salesTotal);
    }
  }

  invCalcforNonUdmManagedTotalForcastInventry(params:any,fieldType:any,fieldName:any,currentRowNode:any,receiptTotal:any,demandTotal:any, salesTotal: any){
    if (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
      let previousRow = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1);
      if (this.fullWidthRowTexts.find(value =>
        value === previousRow?.data[`fullWidthText`]
      )) {
        previousRow = params.api.getRowNode(
          `${params.node.rowIndex! - 2}`
        )!;
      } else {
        previousRow = params.api.getRowNode(
          `${params.node.rowIndex! - 1}`
        )!;
      }
      let fieldDataName = fieldType;
      let allTotal = this.getHubRecieptAndDemandTotal(previousRow, fieldType)
      receiptTotal = allTotal.receiptTotal
      demandTotal = allTotal.demandTotal;
      salesTotal = allTotal.salesTotal
      let previousRowInv = (+previousRow.data[fieldName]);
      let totalInventory = previousRowInv + receiptTotal - demandTotal - salesTotal;
      if(this.selectedDeskOption?.toLowerCase() === 'ethanol + usec'){
        this.destTransloadSplitQty = +previousRow.data['MOB-RADC_Transload'];
        if(fieldDataName?.toUpperCase() === 'MOB-RADC') {
          totalInventory = totalInventory + this.destTransloadSplitQty;
        } else if (fieldDataName?.toUpperCase() === 'MOBTRANS'){
          totalInventory = totalInventory - this.destTransloadSplitQty;
        }

      } else if(this.selectedDeskOption?.toLowerCase() === 'ethanol + uswc'){
        this.ogdenTransLoadQty = +previousRow.data['SLAKETRK_OGDEN-Transload'];
        this.tooeleTransLoadQty = +previousRow.data['SLAKETRK_TOOELE-Transload'];
        this.destTransloadSplitQty  = this.ogdenTransLoadQty + this.tooeleTransLoadQty;

        if(fieldDataName?.toUpperCase() === 'OGDEN') {
          totalInventory = totalInventory - this.ogdenTransLoadQty;
        } else if(fieldDataName?.toUpperCase() === 'TOOELE') {
          totalInventory = totalInventory - this.tooeleTransLoadQty;
        }else if(fieldDataName?.toUpperCase() === 'SLAKETRK') {
          totalInventory = totalInventory +this.destTransloadSplitQty
        }
      }
     params.data[fieldName] = totalInventory;
      return totalInventory;
    } else {
      return currentRowNode.data[fieldName]
    }
  }

  getHubRecieptAndDemandTotal(params: any, fieldType: any) {
    let receiptTotal = 0
    let demandTotal = 0
    let salesTotal = 0
    this.columnDefs2.forEach((ele: any) => {
      if (this.getCamelCaseString(ele.colId)?.toLowerCase() === fieldType?.toLowerCase()) {
        ele?.children?.forEach((element: any) => {

          if (element.headerName?.toLowerCase() === "receipts") {
            receiptTotal = +(element?.children?.length > 0 ? this.getRowSum(params, element.children, true) : params.data[element.field])

          }
          if (element.headerName?.toLowerCase() === "sales") {
            salesTotal = +(element?.children?.length > 0 ? this.getRowSum(params, element.children, true) : params.data[element.field])

          }
          if (element.headerName?.toLowerCase() === "demand override") {
            //demandTotal = +(element?.children?.length > 0 ? this.getRowSum(params, element.children, false, true) : params.data[element.field])
            demandTotal = +(element?.children?.length > 0 ? 0 : params.data[element.field])
          }
        });
      }
    })
    return { receiptTotal: receiptTotal, demandTotal: demandTotal, salesTotal: salesTotal }
  }

  calculateInventoryAvailable(params: any) {
    let totalValue = 0
    const fieldType = params.colDef.field.split('_')[0];
    this.columnDefs2.forEach((ele: any) => {
      if (this.getCamelCaseString(ele.colId)?.toLowerCase() === fieldType?.toLowerCase()) {
        ele?.children?.forEach((element: any) => {
          if (element.headerName?.toLowerCase() === "totalinventory") {
            // element.children.forEach((repsonse: any) => {
              // if (repsonse.headerName?.toLowerCase() === "total") {
                totalValue = +(params.api.getRowNode(`${params.node.rowIndex}`).data[element.field])
            //   }
            // })

          }

        });
      }
    })
    return totalValue - 21600
  }

  calculateMultEntityTotalForcastInventry(params: any) {
    let totalValue = 0
    const fieldType = params.colDef.field.split('_')[0];
    const currentRowNode = params.api.getRowNode(`${params.node.rowIndex}`)!;
    const fieldName = params.colDef.field;

    if (new Date(params.data.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
      this.columnDefs2.forEach((ele: any) => {
        if (this.getCamelCaseString(ele.colId)?.toLowerCase() === fieldType?.toLowerCase()) {
          ele?.children?.forEach((element: any) => {
            if (element.headerName !== 'total') {
              totalValue += +(params.data[element.groupId + '_totalInventory'])
            }
          })
        }
      });
      return totalValue
    } else {
      return currentRowNode.data[fieldName]
    }
  }
  updateDemandShellCarsonTotal (child:any,params:any, sum:any){
    let totalDemandOverride = child.findIndex((ele: any) => ele.headerName?.toLocaleLowerCase() === 'total');
      if (totalDemandOverride > -1) {
        if(this.editField?.toLocaleLowerCase() !== 'total'){
          params.data[child[totalDemandOverride].field] = sum;
        }
      }
      return sum
  }
  getRowSum(params: any, child: any, isReceipt?: any, demandOverride:boolean = false) {
    let sum = 0;
    child.forEach((item: any) => {

      if (item.children && item?.children !== null && item?.children.length > 0 && !this.isShellCarson && !demandOverride) {
        sum += +this.getRowSum(params, item.children, isReceipt, demandOverride);
      } else if(!this.isShellCarson && !demandOverride) {
        let val = params.data[isReceipt ? (item.field + '_calculatedNomination') : item.field]
        sum += item.headerName === 'total' ? 0 : +val;
      }else{
        sum = this.getSumValue(sum, item, params, isReceipt, demandOverride)
      }
    })
    let TotalIndex = child.findIndex((ele: any) => ele.headerName === 'total');
    if (TotalIndex > -1) {
      params.data[child[TotalIndex].field] = sum
    }
    if(demandOverride && this.isShellCarson){
      sum = this.updateDemandShellCarsonTotal (child,params, sum);
    }
    return sum;
  }

  updateEstrReceipts(sum:any, demandOverride:any, item:any, val:any){
    if (this.isShellCarson && demandOverride) {
      sum += (item.headerName?.toLowerCase() === 'total') ? val : 0;
    } else {
      sum += (item.headerName?.toLowerCase() === 'total') ? 0 : +val;
    }
    return sum;
  }
  getSumValue(sum: any, item: any, params: any, isReceipt: any, demandOverride: boolean = false) {
    if (item.children && item?.children !== null && item?.children.length > 0) {
      sum += +this.getRowSum(params, item.children, isReceipt);
    } else {
      let isEstReceipt = item.colId === "estreceipts" || item.headerId === "estreceipts";
      let val = params.data[isReceipt ? (item.field + '_calculatedNomination') : item.field]
      if (!isEstReceipt || (sum === 0 && isReceipt)) {
       sum = this.updateEstrReceipts(sum, demandOverride, item, val);
      }
    }
    return sum;
  }

  columnValueChanged(event:any){
    const allState = this.gridColumnApi2.getColumnState();
    let columnOrderId = allState.map(state => {
       return {colId: state.colId, hide: state.hide}
      })
    const trColData = { colOrder: columnOrderId };
    localStorage.setItem("trColOrder", JSON.stringify(trColData));
  }

  onColumnGroupOpened(event:any){
    const allState = event.columnApi.getColumnGroupState();
    const trColExpandData = { colOrder: allState };
    localStorage.setItem("trColExpandData", JSON.stringify(trColExpandData));
  }

  onColumnMoved(event: any) {
    const allState = event.columnApi.getColumnState();
    const columnOrderId = allState.map((state: { colId: any; hide: any; }) => {
      return {colId: state.colId, hide: state.hide}
     })
    const trColData = { colOrder: columnOrderId };
    localStorage.setItem("trColOrder", JSON.stringify(trColData));
  }

  generateCellBoundaries(){

    if(this.processedDesk != null && this.processedDesk === localStorage.getItem("desk")!){
      return;
    }

    this.parametersMapping.clear();
    let materialList: [] = this.getEhtanolFilterResponse.ethanolFilterValues.filter((_: any) => _.headerName === "terminal")[0].list.map((_: any) => _.id).filter((_:any) => _ != null)

    //paylaod of the entire set of locations that we have in the RP screen, as well as for rins/no rins material
    let payload: any = {
      "locationCodes": materialList,
      "materialNumbers": [
        296660990, //rins
        296669990  //no rins
      ]
    }

    this.alertService.getTankParamsNonDeconstructed(payload).subscribe(response => {

        let objectToMapFrom = response.value;

        objectToMapFrom.forEach((element: any) => {

          if(!this.parametersMapping.has(element.locationCode)){

            let processArray: any[] = [];
            element.locationMaterials.forEach((locationMaterial: any) => {

              let currentItem = locationMaterial.valuationTypes.filter((_: any) => _.valuationType === "physical tank")[0];
              currentItem['uom'] = locationMaterial.uom;
              processArray.push(currentItem);
            });

            /*Note: Map will be of following format...
            * String => [no rins object, rins object]
            */
            this.parametersMapping.set(element.locationCode.toLowerCase(), processArray);
          }
        });

      });
      this.processedDesk = localStorage.getItem("desk")!;
    }

  onEthanolCellEditingStopped(event:any){
    const field = event?.colDef?.colId;
    if(field?.toLowerCase() === 'totalinventory'){
      this.gridInitialized = false;
      this.calculateForcastInventry(event);
    }
    if(field?.toLowerCase() === 'total'){
      this.gridInitialized = false;
      this.calculateTotalForcastInventry(event);
    }
  }

  onEthanolBodyScrolled(event: BodyScrollEvent){
    //For fixing inv refresh issue - 7700534
    this.gridApi2.refreshCells();
  }

  checkIfTRSaveDisabledForDraft(){
    if( this.updatedRows.nomination.draft.length === 0 && this.updatedRows.tr.length === 0){
      this.nominationService.isTRSaveDisabled$.next(true);
    }else {
      this.nominationService.isTRSaveDisabled$.next(false);
    }
  }

  checkIfTRSaveDisabledForOthers(){
    if( this.updatedRows.nomination.others.length === 0 && this.updatedRows.tr.length === 0){
      this.nominationService.isTRSaveDisabled$.next(true);
    } else {
      this.nominationService.isTRSaveDisabled$.next(false);
    }
  }

  updateIsNominationDataDraft(){
    this.isNominationDataEdit = this.updatedRows.nomination.draft.length !== 0;
  }
  updateIsNominationDataOthers(){
    this.isNominationDataEdit = this.updatedRows.nomination.others.length !== 0;
  }

  setDemandCellRenderer(colData: any, columnDef: ColDef){

    if(colData?.headerId?.toLowerCase() === 'demandforecast'){
      columnDef['cellRendererFramework'] = GridAlertTooltipRendererComponent;
      columnDef['cellRendererSelector'] = (params: any) => this.renderAlertTooltip(colData, params);
    }
  }


  

  ngOnDestroy() { 
    sessionStorage.removeItem("updatedCellNode");
    sessionStorage.removeItem("agGridState");
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
