<div class="create-nominations-panel-wrapper">
  <div class="panel-header d-flex justify-content-between">
    <div class="panel-title">nominations</div>
    <button class="nominations-btn nominations-panel nominations" (click)="closeNominationsPanel()">
      <span class="icon-remove open"></span>
    </button>
  </div>

  <div class="panel-content">
    <div class="accordion-item">
      <div class="accordion-header" (click)="expandDraftAccordion()">
        draft({{draftNominations[0]?.headerToItemNav?.length}})@if (!isDraftAccordionExpanded) {
        <span class="expand-icon">+</span>
        }@if (isDraftAccordionExpanded) {
        <span
        class="expand-icon">-</span>
      }
    </div>
    @if (isDraftAccordionExpanded) {
      <div class="accordion-content">
        @for (draftNom of draftNominations[0].headerToItemNav; track draftNom; let i = $index) {
          <div class="draft-nominations">
            <div class="row counter-part" [ngClass]="{'highlight_counterpart': getMatchedlineItem?.itemId=== draftNom?.itemId}">
              <div class="col-md-10 offtaker">
                <span class="content-header cpname">{{draftNom?.nominationNo}}</span>
                <span class="content-body created-date">created on {{draftNom?.createdOn
                | date:'mediumDate'}} </span>
              </div>
              <div class="col-md-2">
                <div class="list">
                  <button class="ellipses-icon"></button>
                  <div class="list-content">
                    <a class="list-item create-nomination" (click)="onCreatePipelineNominationFromTR(draftNom)">create
                    nomination</a>
                    <a class="list-item delete-nomination" (click)="deleteNomination(draftNom)">delete</a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">nominated</span>
                  <span class="content-body">{{draftNom?.scheduledQty | number:'1.2'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">scheduled date</span>
                  <span class="content-body">{{draftNom?.scheduledDate}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">location</span>
                  <span class="content-body">{{draftNom?.locationId}}</span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
<div class="panel-content">
  <div class="accordion-item">
    <div class="accordion-header" (click)="expandPublishedAccordion()">
      Published({{publishedNominations?.length}})@if (!isPublishedAccordionExpanded) {
      <span class="expand-icon"
      >+</span>
      }@if (isPublishedAccordionExpanded) {
      <span class="expand-icon"
      >-</span>
    }
  </div>
  @if (isPublishedAccordionExpanded) {
    <div class="accordion-content">
      @for (publishedNom of publishedNominations; track publishedNom) {
        <div class="published-nominations">
          @for (pubItem of publishedNom.headerToItemNav; track pubItem; let i = $index) {
            <div>
              @if (i === 0) {
                <div class="row" [ngClass]="{'highlight_counterpart-published': getMatchedlineItem?.nominationNo=== publishedNom?.nominationNo}">
                  <div class=" col-md-12 accordion-item-internal">
                    <div class="accordion-header counter-part" (click)="expandPublishedAccordionInternal(publishedNom?.id)">
                      <div class="row headerPadding">
                        <div class="col-md-1 alligncentercontent">
                          @if (isPublishedAccordionExpandedInternal==publishedNom?.id) {
                            <span class="expand-icon"
                            >-</span>
                          } @else {
                            <span class="expand-icon">+</span>
                          }
                        </div>
                        <div class="col-md-9 offtaker">
                          <span class="content-header cpname">{{pubItem?.nominationNo}} </span>
                          <span class="content-body created-date">created on {{pubItem?.createdOn |
                          date:'mediumDate'}}</span>
                        </div>
                        <div class="col-md-1 alligncentercontent">
                          <div class="list">
                            <button class="ellipses-icon"></button>
                            <div class="list-content">
                              <a class="list-item create-nomination"
                              (click)="onCreatePipelineNominationFromTR(publishedNom)">create nom from reference</a>
                              <a class="list-item create-nomination" (click)="goToNominations(publishedNom)">go to
                              nomination</a>
                              <a class="list-item create-nomination" (click)="deleteNomination(publishedNom)">delete</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              @if (isPublishedAccordionExpandedInternal==publishedNom?.id) {
                <div class="accordion-content">
                  <div [class.muliti_item]="i>0">
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">nominated</span>
                        <span class="content-body">{{pubItem?.scheduledQty}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">transport system</span>
                        <span class="content-body">{{pubItem?.transportSystem}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">scheduled material</span>
                        <span class="content-body">{{pubItem?.scheduledMaterialName}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">demand material</span>
                        <span class="content-body">{{pubItem?.demandMaterialName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">scheduled date</span>
                        <span class="content-body">{{pubItem?.scheduledDate}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">location</span>
                        <span class="content-body">{{pubItem?.locationId}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">Arrival Time</span>
                        <span class="content-body">{{pubItem?.scheduledTime}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>
</div>
<div class="panel-content">
  <div class="accordion-item">
    <div class="accordion-header" (click)='expandUnpublishedAccordion()'>
      Unpublished({{unpublishedNominations?.length}})@if (!isUnpublishedAccordionExpanded) {
      <span class="expand-icon"
      >+</span>
      }@if (isUnpublishedAccordionExpanded) {
      <span class="expand-icon"
      >-</span>
    }
  </div>
  @if (isUnpublishedAccordionExpanded) {
    <div class="accordion-content">
      @for (unpublishedNom of unpublishedNominations; track unpublishedNom) {
        <div class="unpublished-nominations"
          [ngStyle]="{'display': (unpublishedNom?.headerToItemNav?.length === 0) ? 'none' : 'block'}">
          <!-- {{ unpublishedNom }} -->
          @for (unPubItem of unpublishedNom.headerToItemNav; track unPubItem; let i = $index) {
            <div>
              <!-- {{ unPubItem.nominationNo }} -->
              @if (i === 0) {
                <div class="row" [ngClass]="{'highlight_counterpart-unpublished': getMatchedlineItem?.nominationNo=== unpublishedNom?.nominationNo}">
                  <div class=" col-md-12 accordion-item-internal">
                    <div class="accordion-header counter-part" (click)="expandUnPublishedAccordionInternal(unpublishedNom?.id)">
                      <div class="row headerPadding">
                        <div class="col-md-1 alligncentercontent">
                          @if (isUnPublishedAccordionExpandedInternal==unpublishedNom?.id) {
                            <span class="expand-icon"
                            >-</span>
                          } @else {
                            <span class="expand-icon">+</span>
                          }
                        </div>
                        <div class="col-md-9 offtaker">
                          <span class="content-header cpname">{{unPubItem?.nominationNo}} </span>
                          <span class="content-body created-date">created on {{unPubItem?.createdOn |
                          date:'mediumDate'}}</span>
                        </div>
                        <div class="col-md-1 alligncentercontent">
                          <div class="list">
                            <button class="ellipses-icon"></button>
                            <div class="list-content">
                              <a class="list-item create-nomination"
                              (click)="onCreatePipelineNominationFromTR(unpublishedNom)">create nom from reference</a>
                              <a class="list-item create-nomination" (click)="goToNominations(unpublishedNom)">go to
                              nomination</a>
                              <a class="list-item create-nomination" (click)="deleteNomination(unpublishedNom)">delete</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              @if (isUnPublishedAccordionExpandedInternal==unpublishedNom?.id) {
                <div class="accordion-content">
                  <div [class.muliti_item]="i>0">
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">nominated</span>
                        <span class="content-body">{{unPubItem?.scheduledQty}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">transport system</span>
                        <span class="content-body">{{unPubItem?.transportSystem}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">scheduled material</span>
                        <span class="content-body">{{unPubItem?.scheduledMaterialName}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">demand material</span>
                        <span class="content-body">{{unPubItem?.demandMaterialName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">scheduled date</span>
                        <span class="content-body">{{unPubItem?.scheduledDate}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">location</span>
                        <span class="content-body">{{unPubItem?.locationId}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>
</div>

</div>
