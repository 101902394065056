@if (getActiveFlag== true) {
  <div >
    <button style="border:none;background:none;cursor:pointer;color: #0066B2;" matTooltip="list of subscribers" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"><img src="../../assets/icons/icon-refresh.svg" alt="subscriber-icon" (click)="subscriberList()"></button>
    <button style="border:none;background:none;cursor:pointer;" matTooltip="delete rule" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"><img src="../../assets/icons/icon-alerts-delete.svg" alt="delete-icon" (click)="deleteAlert()"></button>
    <button style="border:none;background:none;cursor:pointer;" matTooltip="edit rule" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"><img src="../../assets/icons/icon-alerts-pencil.svg" alt="edit-icon"(click)="updateAlert(); allowUpdate=true"></button>
  </div>
}
@if (getActiveFlag == false) {
  <div >
    <button style="border:none;background:none;cursor:pointer;" matTooltip="restore alert" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"><img src="../../assets/icons/icon-restore-deleted.svg" alt="restore-deleted" (click)="restoreAlert()"></button>
  </div>
}

