<div class="create-alerts">
  <div class="form-row pb-0 pr-3">
    <div class="form-group col-md-4">
      <label for="terminal" class="label">terminal</label>
      <select
        class="form-control"
        (change)="getMaterialData(selectedTerminal); setCreateBoolean()"
        [(ngModel)]="selectedTerminal"
        [disabled]="selectionDisable()"
        >
        <option value="" disabled selected>select terminal</option>
        @for (terminal of locList; track terminal) {
          <option>
            {{ terminal }}
          </option>
        }
      </select>
    </div>
    <div class="form-group col-md-4">
      <label class="label">material</label>
      <select
        class="form-control"
        [(ngModel)]="selectedMaterial"
        [disabled]="selectedTerminal == '' || selectionDisable()"
        (change)="
          getRulesByTerminalMaterial(selectedTerminal, selectedMaterial);
          setCreateBoolean()
        "
        >
        <option value="" disabled selected>select material</option>
        @for (material of matNameList; track material) {
          <option [value]="material">
            {{ material }}
          </option>
        }
      </select>
    </div>
    <div class="form-group col-md-4">
      <label for="ruletype" class="label">rule type</label>
      <select
        class="form-control"
        [disabled]="
          selectedMaterial == '' || selectedTerminal == '' || selectionDisable()
        "
        [(ngModel)]="selectedRuleType"
        (change)="setCreateBoolean()"
        >
        <option value="" disabled selected>select type</option>
        @for (ruletype of ruletypeDropdown; track ruletype) {
          <option>
            {{ ruletype }}
          </option>
        }
      </select>
    </div>
  </div>
  <hr />
  @if (selectedRuleType == '') {
    <div style="text-align: center">
      <label class="screen-lable">select rule type</label>
    </div>
  }
  @if (selectedRuleType == 'Inventory') {
    <app-inventory-field
      [selectedReference]="selectedReference"
      [selectedNoOfDays]="selectedNoOfDays"
      [selectedRules]="selectedRules"
      [selectedParameters]="selectedParameters"
      (inventoryData)="getInventoryDetails($event)"
      >
    </app-inventory-field>
  }

  @if (selectedRuleType == 'Reconciliation') {
    <app-reconciliation-field
      [selectedPercent]="selectedPercent"
      (reconciliationData)="getReconciliationDetails($event)"
      >
    </app-reconciliation-field>
  }

  @if (selectedRuleType == 'ActualDemand') {
    <app-demand-field
      [selectedNoOfDays]="selectedNoOfDays"
      [selectedRules]="selectedRules"
      [selectedPercent]="generateNumber(selectedPercent)"
      (demandData)="getActualDemandAlertDetails($event)"
      >
    </app-demand-field>
  }

  @if (selectedRuleType != '') {
    <div>
      <label class="label-2">select frequency</label>
      <mat-radio-group
        [disabled]="radioButtonDisable()"
        [(ngModel)]="selectedFreq"
        (change)="setCreateBoolean()"
        >
        <div>
          <mat-radio-button value="I"
            ><span class="frequency-bold">immediately </span
            ><span class="frequency-italic"
            >(as soon as an alert occurs)</span
            ></mat-radio-button
            >
          </div>
          <div>
            <mat-radio-button value="H"
              ><span class="frequency-bold">hourly </span
              ><span class="frequency-italic"
              >(an hourly roundup)</span
              ></mat-radio-button
              >
            </div>
            <div>
              <mat-radio-button value="D"
                ><span class="frequency-bold">daily </span
                ><span class="frequency-italic"
                >(a daily summary of all alerts at 10:30am ct)</span
                ></mat-radio-button
                >
              </div>
            </mat-radio-group>
          </div>
        }

        @if (disableCreate) {
          <span class="label-4r"
            >this rule already exists or was recently deleted. please change the values
            or check deleted alerts</span
            >
          }

          &nbsp;
          @if (!allowUpdate) {
            <div class="form-row pl-2">
              <div class="form-group col-md-4">
                <button class="cancel-btn" (click)="closePopup()">cancel</button>
              </div>
              <div class="form-group col-md-4" style="margin-left: 0px">
                <button
                  class="create-rule-btn"
                  [disabled]="checkDisableCreate()"
                  (click)="checkForDuplicatesCreate(selectedTerminal, selectedMaterial)"
                  >
                  add rule
                </button>
              </div>
            </div>
          }
          @if (allowUpdate) {
            <div class="form-row">
              <div class="form-group col-md-3" style="margin-left: 0px">
                <button
                  class="delete-rule-btn"
                  [disabled]="checkDisableDelete()"
        (click)="
          showDeleteRule(selectedTerminal, selectedMaterial, selectedRuleName)
        "
                  >
                  delete rule
                </button>
              </div>
              <div class="form-group col-md-3" style="margin-right: 0px">
                <button class="cancel-btn" (click)="closePopup()">cancel</button>
              </div>
              <div class="form-group col-md-3" style="margin-left: 0px">
                <button
                  class="create-rule-btn"
                  [disabled]="checkDisableUpdate()"
        (click)="
          checkForDuplicatesUpdate(
            selectedTerminal,
            selectedMaterial,
            selectedRuleName
          )
        "
                  >
                  update rule
                </button>
              </div>
            </div>
          }
        </div>
