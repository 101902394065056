@if (!listVisible) {
  <div class="d-flex flex-row blue-icon" (click)="showList()">
    <p class="textValues m-0">+tag</p>
  </div>
}

<div [hidden]="!listVisible">
  <select #selectElement (change)="addTag($event)" (focusout)="showAddButton()">
    <option value="">Add tag</option>
    @for (tag of selectableTagOptions; track tag) {
      <option>{{tag}}</option>
    }
  </select>
</div>