import { createAction, props } from '@ngrx/store';


export const loadDynamicDealDetailData = createAction(
    '[Dynamic deal Detail Data API] Load Dynamic Deal Detail Data',
    props<{ dealContractId: string}>()

);

export const loadDynamicDealDetailDataSuccess = createAction(
    '[Dynamic deal Detail Data API] Load Dynamic Deal Detail Data API Success',
    props<{ cbDynamicDealDetailData: any }>(),
  );
  
  export const loadDynamicDealDetailDataFailure = createAction(
    '[Dynamic deal Detail Data API] Load Dynamic Deal Detail Data API Failure',
    props<{ error: any }>(),
  );

  export const updateIndicatorDealDetails = createAction(
    '[Dynamic Deal Detail Indicators] Update Indicators Success',
    props<{ payLoad:any }>(),
  );