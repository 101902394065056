<div class="panel-content">
  <ng-container>
    <div>
      <div class="content__header">functional group</div>
      <form class="branded">
        <div class="admin-side-panel">
          @for (obj of filters.functionalGroup; track obj; let i = $index) {
            @if (obj.show) {
              <p class="align-items-center d-flex">
                <input type="checkbox" class="flex-shrink-0" name="functionalGroupCheckbox" [id]="obj.name"
                  [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('functionalGroup', $event, i)">
                  <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                    {{ obj.name }}
                  </label>
                </p>
              }
            }
          </div>
        </form>
        <hr>
        </div>
        <div>
          <div class="content__header">region</div>
          <form class="branded">
            <div class="admin-side-panel">
              @for (obj of filters.region; track obj; let i = $index) {
                @if (obj.show) {
                  <p class="align-items-center d-flex">
                    <input type="checkbox" class="flex-shrink-0" name="regionCheckbox" [id]="obj.name"
                      [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('region', $event, i)">
                      <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                        {{ obj.name }}
                      </label>
                    </p>
                  }
                }
              </div>
            </form>
            <hr>
            </div>
            <div>
              <div class="content__header">location</div>
              <form class="branded">
                <input class="form-control admin-search" [name]="'searchText0'" type="search"
                  placeholder="search" (input)="updateSearchFilterList('locationName', $any($event.target).value)"
                  [(ngModel)]="searchValue[0]">
                  <div class="admin-side-panel">
                    @for (obj of filters.locationName; track obj; let i = $index) {
                      @if (obj.show) {
                        <p class="align-items-center d-flex">
                          <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.name"
                            [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('locationName', $event, i)" >
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                              {{ obj.name }}
                            </label>
                          </p>
                        }
                      }
                    </div>
                  </form>
                  <hr>
                  </div>
                  <div>
                    <div class="content__header">SIS location code</div>
                    <form class="branded">
                      <input class="form-control admin-search" [name]="'searchText1'" type="search"
                        placeholder="search" (input)="updateSearchFilterList('locationCode', $any($event.target).value)"
                        [(ngModel)]="searchValue[1]">
                        <div class="admin-side-panel">
                          @for (obj of filters.locationCode; track obj; let i = $index) {
                            @if (obj.show) {
                              <p class="align-items-center d-flex">
                                <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.name "
                                  [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('locationCode', $event, i)">
                                  <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                    {{ obj.name }}
                                  </label>
                                </p>
                              }
                            }
                          </div>
                        </form>
                        <hr>
                        </div>
                      </ng-container>
                    </div>

                    <div class="panel-footer d-flex justify-content-between p-3">
                      <span class="clear-filter" (click)="clearFilters()">clear</span>
                      <span class="clear-filter" (click)="filterDesks()">apply</span>
                    </div>
