import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CbDynamicDealsService } from 'src/app/services/cb-dynamic-deals.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-cb-global-search',
  templateUrl: './cb-global-search.component.html',
  styleUrls: ['./cb-global-search.component.scss']
})
export class CbGlobalSearchComponent implements OnInit {
  showCBGlobalSearchInput: boolean = false;
  cbGlobalSearchText:any;
  searchResults:any=[];
  @Output() isCbGlobalSearchBarOpened = new EventEmitter(false);
  @ViewChild('cbGlobalSearchInput') cbGlobalSearchInput!:ElementRef;
  @ViewChild('searchContainer') searchContainer!:ElementRef;
  constructor(
    private cbService: CbDynamicDealsService,
    private toasterService: ToastService,
    private router: Router
    ) { }

  ngOnInit(): void {
  }

  openCBSearchBarAndSearch(){
    if(this.showCBGlobalSearchInput && this.cbGlobalSearchText?.trim()?.length){
      this.getCBSearchResults(this.cbGlobalSearchText);
    }
    this.showCBGlobalSearchInput = true;
    if(this.showCBGlobalSearchInput){
      setTimeout(() => {
        this.cbGlobalSearchInput.nativeElement.focus();
        this.isCbGlobalSearchBarOpened.emit(true);
      }, 300);
    }
  }

  getCBSearchResults(dealNoContractNo:any){
    const payload = {
      "logicalOperator": "OR",
      "conditions": [
        {
          "field": "DealNumber",
          "Value": dealNoContractNo.trim(),
          "operator": "eq"
        },
        {
          "field": "ContractNumber",
          "Value": dealNoContractNo.trim(),
          "operator": "eq"
        }
      ]
    }
   
    this.cbService.getCBSearchResults(payload).subscribe({
      next: (response: any) => {
       console.log(response);
       const searchResponse = response;
       if(searchResponse.length){
        this.searchResults = [];
        if(searchResponse.length>1){
          searchResponse.forEach((res:any)=>{
            const obj = {
              dealNoContractNo: `${res?.dealNumber} | ${res?.contractNumber===''?'pending':res?.contractNumber}`, 
              dealContractType: res?.udmDealContractType,
              dealContractId: res?.dealContractId
            };
            this.searchResults.push(obj);
          })
          
         } else {
          this.navigateToDealDetailPopout(searchResponse[0]?.dealContractId);
         }
       } else {
        this.toasterService.setToastNotification({
          show: true,
          type: 'info',
          msg: `deal/contract not found`,
        });
       }
      
      },
      error: (err: any) => {
       console.log(err)
      },
      complete: () => { }
    })
  }

  navigateToPopout(dealContractId:any) {
    this.navigateToDealDetailPopout(dealContractId);
  }

  navigateToDealDetailPopout(dealContractId:any){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['contracts/dealdetail',dealContractId?.replace('|','-')])
    );
    window.open(url, '_blank');
  }

  checkCbGlobalSearchTextLength() {
    if(!this.cbGlobalSearchText.length) {
      this.searchResults=[];
    }
  }

  @HostListener('document:click',['$event'])
  onClickOutside(event: MouseEvent) {
    if(this.showCBGlobalSearchInput && !this.searchContainer.nativeElement.contains(event?.target)){
      this.showCBGlobalSearchInput = false;
      this.cbGlobalSearchText = '';
      this.searchResults = [];
      this.isCbGlobalSearchBarOpened.emit(false);
    }
  }

}
