
<div class="d-flex">
  <div class="more-menu d-flex justify-content-between w-100">
    <span> {{ value | number:'1.2' }} </span>
    @if (showUi) {
      <div class="more-menu-dropdown">
        <button class="ellipses-icon" (click)="moreMenu()"></button>
        @if (!isOpen) {
          <div class="dropdown-content"
            [ngClass]="{'place-top': rowIndex > 8, 'displayHover' : isOpen, 'place-bottom': rowIndex < 8 }">
            <a class="dropdown-item" (click)="editNomination()">create nomination</a>
            <a class="dropdown-item" (click)="delete()">delete</a>
          </div>
        }
      </div>
    }
  </div>
</div>