<div class="dynamic-deal-detail-indicator-container" appCloseDropdownDircetive
  (clickedOutSide)="closeDropdown()">
  <div [class.active]="showDropDown" class="assign-indicator-container" (click)="toggleDropdown()">
    <span class="drop-down-header">assign indicator(s)</span>
    <span [class.drop-down-expand-icon]="!showDropDown"
    [class.drop-down-collapse-icon]="showDropDown"></span>
  </div>
  @if (showDropDown) {
    <div class="drop-down-indicator-selection" >
      <form class="branded">
        @for (
          item of indicatorDropDownValues; track
          item; let i = $index) {
          @for (
            item of indicatorDropDownValues; track
            item; let i = $index) {
            <div
              >
              <input
                type="checkbox"
                name="formcheckbox"
                attr.id="formcheckbox-{{ i }}"
                [value]="item.name"
                (change)="updateIndicators(item)"
                [checked]="isIndicatorSelected(item.name)"
                />
                <label attr.for="formcheckbox-{{ i }}" style="text-transform: uppercase;">{{ item.name }}</label>
              </div>
            }
          }
        </form>
      </div>
    }

  </div>
