<div class="ethanol-more-menu">
  <span class="text-truncate" [ngClass]="[isNomPresent ? rowStatus : '']">{{ value }}</span>
  <div class="more-menu-dropdown">
    @if (moremenuIconShow) {
      <button class="ellipses-icon"  [ngClass]="[compactView ? 'compact':'']"  (click)="moremwnu()"></button>
    }
    @if (!isOpen) {
      <div class="dropdown-content"[ngClass]="[compactView ? 'compact-dropdown':'', rowIndex < 8 ? '' : 'place-top']">
        @if (isDraft) {
          <a class="dropdown-item ethanol-create-nomination" (click)="onCreateDraftNomination()">create nomination</a>
        }
        @if (rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published') {
          <a class="dropdown-item create-nomination" (click)="onCreateDraftNomination()">create nomination from
          reference</a>
        }
        @if (rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published') {
          <a class="dropdown-item goToNominations"
          (click)="goToNominations()">go to nominations</a>
        }
        <a class="dropdown-item ethanol-seeInNominationPannel" (click)="seeInNominationPannel()">see in nomination panel</a>
        @if (rowStatus?.toLowerCase() ==='unpublished' || rowStatus?.toLowerCase() ==='published') {
          <a class="dropdown-item edit-nomination" (click)="onCreateDraftNomination('edit')">edit nomination</a>
        }
        <a class="dropdown-item ethanol-delete" (click)="delete()">delete</a>
        @if (!isDraft && isCargo && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk || desk === PipelineUswcPNWDesk)) {
          <a
            class="dropdown-item contract-balancinf"
            [ngClass]="{'disableVesselOption': disableVesselOption(vesselScheduleUpdateData)}"
          (click)="vessalScheduleUpdate()">vessel schedule update</a>
        }
      </div>
    }
  </div>
</div>