<div class="subscriber-alerts">
  <div class="label-1"><img src="../../assets/icons/icon-refresh.svg" alt="subscriber-icon"> list of users getting notifications by this rule <span class="close-button" (click)="closePopup();">X</span></div>
  <div>
    <hr>
      @if (selectedUserList.length === 0) {
        <p class = "styled-text">No users are recieving notifications for this rule, you may edit, delete, or subscribe </p>
      }
      @if (selectedUserList.length !== 0) {
        <ul>
          @for (i of selectedUserList; track i) {
            <li class="label-2">
              {{ i }}
            </li>
          }
        </ul>
      }
    </div>
  </div>


