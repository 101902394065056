
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AlertsService } from 'src/app/services/alerts.service';
import { AlertLocationData, UpdateRuleInventory, UpdateRuleReconciliation, SubscriptionUsers, LocationMaterialRules, LocationMaterials, SettingsAlertFilters, SettingsAlertLocationsFilter, SettingsAlertUniqueMaterialsFilter, SettingsAlertRulesFilter, UpdateRuleActualDemand } from 'src/app/shared/interface/settings.interface';
import { CalAngularService } from '@cvx/cal-angular';
import { ColDef, GridApi, GridReadyEvent, IDetailCellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { AlertCheckboxComponent } from '../alert-checkbox/alert-checkbox.component';
import { AlertToggleComponent } from '../alert-toggle/alert-toggle.component';
import { AlertActionsComponent } from '../alert-actions/alert-actions.component';
import { selectDesk, selectisAlertDeletedShowHide, selectisNotifParamSave } from 'src/app/shared/store/selector/app.selector';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { HomeComponent } from 'src/app/home/home.component';
import { triggerNotifParamSave } from 'src/app/shared/store/action/app.action';
import { AlertToggleAllComponent } from '../alert-toggle/alert-toggle-all/alert-toggle-all.component';
import { MdmService } from 'src/app/services/mdm.service';
import { MDMDeskLocations, MDMDeskValues, MDMLocationMaterials } from 'src/app/shared/interface/mdm.interface';
import { loadRulesData } from '../store/action/settings-alerts.action';
import { selectAlertRulesData, getAlertFilters } from '../store/selector/settings-alerts.selector';
import { NavigationCancel, Router } from '@angular/router';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-alerts-notifications',
  templateUrl: './alerts-notifications.component.html',
  styleUrls: ['./alerts-notifications.component.scss']
})
export class AlertsNotificationsComponent implements OnInit {
  public gridApi!: GridApi;
  createAlertModal = { open: false, modal: 'createAlert' }
  denyUpdateAlertModal = { open: false, modal: 'denyUpdateAlert' }
  updateAlertModal = { open: false, modal: 'updateAlert' }
  deleteAlertModal = { open: false, modal: 'deleteAlert' }
  denyDeleteAlertModal = { open: false, modal: 'denyDeleteAlert' }
  restoreDeletedAlertModal = { open: false, modal: 'restoreDeleteAlert' }
  subscriberAlertModal = { open: false, modal: 'subscriberAlert' }
  saveAlertModal = { open: false, modal: 'saveAlert' }
  userVacationModal = { open: false, modal: 'userVacation' }
  public rulesGridData: AlertLocationData[] = [];
  alertFiltersMaterialData: LocationMaterials[] = [];
  alertFiltersRulesData: LocationMaterialRules[] = [];
  currentUserEmail!: string;
  isDeletedRules: boolean = true
  selectedDesk$ = this.store.pipe(select(selectDesk));
  selectisAlertDeleteHideShow$ = this.store.pipe(select(selectisAlertDeletedShowHide));
  destroy$: Subject<boolean> = new Subject<boolean>();
  public updateNotifsInventoryPayload: UpdateRuleInventory[] = [];
  public updateNotifsReconciliationPayload: UpdateRuleReconciliation[] = [];
  public updateNotifsActualDemandPayload: UpdateRuleActualDemand[] = [];
  selectisNotifParamSave$ = this.store.pipe(select(selectisNotifParamSave));
  totalNotifPayload!: any[];
  public subscriptions!: SubscriptionUsers[];
  deskValues: MDMDeskValues[] = [];
  deskList: number[] = [];
  locValues: MDMDeskLocations[] = [];
  locList: string[] = [];
  public matValues: MDMLocationMaterials[] = [];
  public matList: string[] = [];
  public matNameList: string[] = [];
  desk: any;
  alertFilters: SettingsAlertFilters = { locationsFilter: [], materialsFilter: [], rulesFilter: [] };
  terminalList: any[] = [];
  materialList: any[] = [];
  ruleList: any[] = [];
  alertRulesData$ = this.store.pipe(select(selectAlertRulesData));
  alertFilters$ = this.store.pipe(select(getAlertFilters));
  private hrefBlockSubscription!: Subscription;
  private deskBlockSubscription!: Subscription;




  constructor(private authService: CalAngularService, private store: Store, private utilities: Utilities, private alertsService: AlertsService,
    private toasterService: ToastService, private homeComponent: HomeComponent, private mdmService: MdmService,
    private router: Router, private saveAlert: AlertSaveGuardService, private dateService: DateService,) { }


  ngOnInit(): void {

    this.currentUserEmail = this.authService.cvxClaimsPrincipal.email;
    localStorage.setItem('email', this.currentUserEmail)

    this.utilities.sendActionToChild$.subscribe(action => {
      if (action === 'expand') {
        this.gridApi.forEachNode(index => {
          index.setExpanded(true);
        })
      }
      else {
        this.gridApi.forEachNode(index => {
          index.setExpanded(false);
        })
      }
    });

    this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {

      if (response.function === 'createAlert') {
        this.toggleCreateAlertModal();
      }
      if (response.function === 'denyUpdateAlert') {
        this.toggleDenyUpdateAlertModal();
      }
      if (response.function === 'deleteAlert') {
        this.toggleDeleteAlertModal();
      }
      if (response.function === 'denyDeleteAlert') {
        this.toggleDenyDeleteAlertModal();
      }
      if (response.function === 'restoreDeleteAlert') {
        this.toggleRestoreDeletedAlertModal();
      }
      if (response.function === 'subscriberAlert') {
        this.toggleSubscriberAlertModal();
      }
      if (response.function === 'saveAlert') {
        this.toggleSaveAlertModal();
      }
      if (response.function === 'userVacation') {
        this.toggleUserVacationModal();
      }
    });

    this.utilities.sendActionToChild$.subscribe()

    this.alertFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.alertFilters = response.SettingsAlertFilters;
          this.getAlertfilters();
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectisAlertDeleteHideShow$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.triggeredDeletedRules === true) {
            this.isDeletedRules = true;
            this.alertRulesData$
              .pipe()
              .subscribe({
                next: (response: any) => {
                  this.conductRuleFiltering(response);
                },
                error: (err) => {
                  console.log(err);
                },
                complete: () => {
                },
              })
          } else if (response.triggeredDeletedRules === false) {
            this.isDeletedRules = false;
            let filteredDeskData: AlertLocationData[] = [];
            this.alertRulesData$
              .pipe()
              .subscribe({
                next: (response: any) => {
                  this.conductRuleFiltering(response, true);
                },
                error: (err) => {
                  console.log(err);
                },
                complete: () => {
                },
              })
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.getDeskData()


    this.selectisNotifParamSave$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.triggeredNotifParamDataSave) {
            if (this.updateNotifsInventoryPayload.length) {
              for (let i of this.updateNotifsInventoryPayload) {
                this.updateNotifsDataInventory(i)
              }

            }
            if (this.updateNotifsReconciliationPayload.length) {
              for (let i of this.updateNotifsReconciliationPayload) {
                this.updateNotifsDataReconciliation(i)
              }
            }
            if (this.updateNotifsActualDemandPayload.length) {
              for (let i of this.updateNotifsActualDemandPayload) {
                this.updateNotifsDataActualDemand(i)
              }
            }
          }
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });






    //Subscribe to router events that are un-allowed leaving (NavigationCancel) 
    this.router.events.subscribe((navigationEvent) => {

      if (navigationEvent instanceof NavigationCancel) {

        if (!this.saveAlert.canDeactivate()) {
          this.saveAlert.setNavigationAddress(navigationEvent.url);
          this.toggleSaveAlertModal();
        }
      }
    }
    );
    //subscribe to anchor status based on if the user has selected an anchor tag 
    this.hrefBlockSubscription = this.saveAlert.hrefObservable$.subscribe(showBoolean => {
      if (showBoolean) {
        this.toggleSaveAlertModal();
      }
    });

    //subscribe to anchor status based on if the user has selected an anchor tag 
    this.deskBlockSubscription = this.saveAlert.deskObservable$.subscribe(showBoolean => {
      if (showBoolean) {
        this.toggleSaveAlertModal();
      }
    });


  }

  toggleCreateAlertModal(): void {
    const modal = { open: !this.createAlertModal.open, modal: 'createAlert' }
    this.createAlertModal = { ...modal };
    this.alertsService.setIsCreateAlertsPopupOpen(modal.open);
  }

  toggleDenyUpdateAlertModal(): void {
    const modal = { open: !this.denyUpdateAlertModal.open, modal: 'denyUpdateAlert' }
    this.denyUpdateAlertModal = { ...modal };
  }

  toggleDeleteAlertModal(): void {
    const modal = { open: !this.deleteAlertModal.open, modal: 'deleteAlert' }
    this.deleteAlertModal = { ...modal };
  }

  toggleDenyDeleteAlertModal(): void {
    const modal = { open: !this.denyDeleteAlertModal.open, modal: 'denyDeleteAlert' }
    this.denyDeleteAlertModal = { ...modal };
  }

  toggleRestoreDeletedAlertModal(): void {
    const modal = { open: !this.restoreDeletedAlertModal.open, modal: 'restoreDeleteAlert' }
    this.restoreDeletedAlertModal = { ...modal };
  }

  toggleSubscriberAlertModal(): void {
    const modal = { open: !this.subscriberAlertModal.open, modal: 'subscriberAlert' }
    this.subscriberAlertModal = { ...modal };
  }

  toggleSaveAlertModal(): void {
    const modal = { open: !this.saveAlertModal.open, modal: 'saveAlert' }
    this.saveAlertModal = Object.assign({}, modal);
  }

  toggleUserVacationModal(): void {
    const modal = { open: !this.userVacationModal.open, modal: 'userVacation' }
    this.userVacationModal = Object.assign({}, modal);
  }

  closeCreateAlertModal() {
    const modal = { open: false, modal: 'createAlert' }
    this.createAlertModal = { ...modal };
  }

  closeDenyUpdateAlertModal() {
    const modal = { open: false, modal: 'denyUpdateAlert' }
    this.denyUpdateAlertModal = { ...modal };
  }

  closeDeleteAlertModal() {
    const modal = { open: false, modal: 'deleteAlert' }
    this.deleteAlertModal = { ...modal };
  }


  closeDenyDeleteAlertModal() {
    const modal = { open: false, modal: 'denyDeleteAlert' }
    this.denyDeleteAlertModal = { ...modal };

  }


  closeRestoreDeletedAlertModal() {
    const modal = { open: false, modal: 'restoreDeleteAlert' }
    this.restoreDeletedAlertModal = { ...modal };

  }


  closeSubscriberAlertModal() {
    const modal = { open: false, modal: 'subscriberAlert' }
    this.subscriberAlertModal = { ...modal };
  }

  closeSaveAlertModal() {
    const modal = { open: false, modal: 'saveAlert' }
    this.saveAlertModal = Object.assign({}, modal);
  }

  closeUserVacationModal() {
    const modal = { open: false, modal: 'userVacation' }
    this.userVacationModal = Object.assign({}, modal);
  }

  getAlertRulesData() {
    this.store.dispatch(loadRulesData())
  }

  getAlertfilters(): void {   
    if (null != this.alertFilters) {
      this.ruleList = [];
      this.alertFilters.rulesFilter
        .filter((ruleFilter: SettingsAlertRulesFilter) => ruleFilter.ruleid != 'all' && ruleFilter.checked)
        .forEach((ruleFilter: SettingsAlertRulesFilter) => {
          this.ruleList.push(ruleFilter.ruleid);
        });

      this.materialList = [];
      this.alertFilters.materialsFilter
        .filter((materialFilter: SettingsAlertUniqueMaterialsFilter) => materialFilter.material !== 'all' && materialFilter.checked)
        .forEach((materialFilter: SettingsAlertUniqueMaterialsFilter) => {
          this.materialList.push(materialFilter.material);
        });

      this.terminalList = [];
      this.alertFilters.locationsFilter
        .filter((locationFilter: SettingsAlertLocationsFilter) => locationFilter.location !== 'all' && locationFilter.checked)
        .forEach((locationFilter: SettingsAlertLocationsFilter) => {
          this.terminalList.push(locationFilter.location);
        });
    }
  }

  updateNotifsDataInventory(payload: UpdateRuleInventory) {
    this.store.dispatch(triggerNotifParamSave({ triggeredNotifParamDataSave: false, isNotifParamSaveEnabled: false }));
    this.alertsService.updateAlert(payload).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log(err);
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "error saving updated notification preferences" });
      },
      complete: () => {
        this.toasterService.setToastNotification({ show: true, type: "success", msg: "notification preferences changes saved" });
        this.getAlertRulesData()
        this.gridApi.redrawRows();
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
        this.updateNotifsInventoryPayload = [];
      }
    })
  }

  updateNotifsDataReconciliation(payload: UpdateRuleReconciliation) {
    this.store.dispatch(triggerNotifParamSave({ triggeredNotifParamDataSave: false, isNotifParamSaveEnabled: false }));
    this.alertsService.updateAlert(payload).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log(err);
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "error saving updated notification preferences" });
      },
      complete: () => {
        this.toasterService.setToastNotification({ show: true, type: "success", msg: "notification preferences changes saved" });
        this.getAlertRulesData()
        this.gridApi.redrawRows();
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
        this.updateNotifsReconciliationPayload = [];
      }  
    })
  }

  updateNotifsDataActualDemand(payload: UpdateRuleActualDemand) {
    this.store.dispatch(triggerNotifParamSave({ triggeredNotifParamDataSave: false, isNotifParamSaveEnabled: false }));
    this.alertsService.updateAlert(payload).subscribe({
      next: () => {
      },
      error: (err) => {
        console.log(err);
        this.toasterService.setToastNotification({ show: true, type: "error", msg: "error saving updated notification preferences" });
      },
      complete: () => {
        this.toasterService.setToastNotification({ show: true, type: "success", msg: "notification preferences changes saved" });
        this.getAlertRulesData()
        this.gridApi.redrawRows();
        this.homeComponent.expandedMode$.next(false);
        this.homeComponent.collpaseMode$.next(true);
        this.updateNotifsActualDemandPayload = [];
      }
    })
  }

  public columnDefs: ColDef[] = [
    {
      headerName: 'terminal',
      field: 'location',
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: '',
      field: 'location',
      cellRenderer: AlertToggleAllComponent,
    }
  ];

  public defaultColDef: ColDef = {
    flex: 1,
  };
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  public groupDefaultExpanded = 0;

  public detailCellRendererParams: any = {
    detailGridOptions: {
      columnDefs: [
        { headerName: 'material', field: 'material', cellRenderer: 'agGroupCellRenderer' },
      ],
      defaultColDef: {
        flex: 1,
      },
      groupDefaultExpanded: 1,
      masterDetail: true,
      detailRowHeight: 240,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            {
              field: 'ruleName', headerName: 'rule', maxWidth: 140, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'ruleType', headerName: 'type', cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'ruleDescription', headerName: 'summary', minWidth: 550, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'alertFrequency', headerName: 'alert frequency', cellRenderer: lowercaseFormatter, maxWidth: 160, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'subscription', headerName: 'notification', cellRenderer: AlertToggleComponent, cellRendererParams: { editable: true }, maxWidth: 160, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'subscription', headerName: 'workspace', cellRenderer: AlertCheckboxComponent, cellRendererParams: { editable: true }, maxWidth: 120, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'subscription', headerName: 'email', cellRenderer: AlertCheckboxComponent, cellRendererParams: { editable: true }, maxWidth: 120, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'subscription', headerName: 'teams', cellRenderer: AlertCheckboxComponent, cellRendererParams: { editable: true }, maxWidth: 120, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: '', headerName: 'actions', cellRenderer: AlertActionsComponent, maxWidth: 140, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'createdBy', headerName: 'created by', cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'createdDate', headerName: 'created date', cellRenderer: dateFormatterCreated, cellRendererParams: {dateService: this.dateService}, cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'updatedBy', headerName: 'last modified by', cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            {
              field: 'updatedDate', headerName: 'last modified on', cellRenderer: dateFormatterUpdate, cellRendererParams: {dateService: this.dateService} ,cellStyle: (params: any) => {
                if (params.data.isActive === false) {
                  return { background: 'rgba(200,0,0,0.2)' }
                } else {
                  return { background: 'rgba(200,0,0,0)' }
                }
              }
            },
            { maxWidth: 1 }
          ],
          defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            cellClass: 'subtitle-6 font-gotham-narrow-book',
            headerClass: 'font-gotham-bold',
            suppressMovable: true,
          },
        },
        getDetailRowData: (params) => {
          params.successCallback(params.data.locationMaterialRules)
        },
      } as IDetailCellRendererParams,
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.locationMaterials);
    }
  } as IDetailCellRendererParams;

  getDeskData() {
    this.mdmService.getAllDesks().subscribe({
      next: (response: any) => {
        this.deskValues = response.value
        for (let k of this.deskValues) {
          if (k.name.toLowerCase() === this.desk) {
            this.deskList.push(Number(k.id))
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getLocationData()
      },
    })
  }

  getLocationData() {
    const locPayload = {
      "deskIds": this.deskList
    };
    this.mdmService.getDeskLocation(locPayload).subscribe({
      next: (response: any) => {
        this.locValues = response.value
        for (let i of this.locValues) {
          this.locList.push(i.locationCode)
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getMaterialData()
      },

    })
  }

  getMaterialData() {
    const matPayload = {
      "deskIds": [],
      "activeOnly": false,
      "locationIds": this.locList
    }

    this.mdmService.getMaterialLocationMappings(matPayload).subscribe({
      next: (response: any) => {
        this.matValues = response.value.value
        for (let i of this.matValues) {
          for (let j of i.locationMaterials) {
            this.matList.push(j.materialNumber)
            this.matNameList.push(j.materialDescription)
          }
        }
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => {
        this.getAlertRulesData()
      },
    })

  }



  conductRuleFiltering(response: any, showHiddenRules?: boolean ){

    let arrayData: AlertLocationData[] = [];
    response.result?.forEach((element: AlertLocationData) => {
      if (this.terminalList.length > 0 || this.materialList.length > 0 || this.ruleList.length > 0) {
        if (null != this.terminalList && this.terminalList.includes(element.location)) {
          this.alertFiltersMaterialData = [];
          element.locationMaterials?.forEach((matelement: LocationMaterials) => {
            this.filterRulesForMaterial(matelement, showHiddenRules);
          });
          arrayData.push({ location: element.location, locationMaterials: this.alertFiltersMaterialData })
        }
      }
      else {
        if (this.alertFilters.locationsFilter.length == 1 && this.alertFilters.locationsFilter[0].location === 'all')
          arrayData.push(element)
      }
    });
    this.rulesGridData = JSON.parse(JSON.stringify(arrayData));
  }

  filterRulesForMaterial(matelement: LocationMaterials, showHiddenRules?: boolean){
    if (null != this.materialList && this.materialList.includes(matelement.material)) {
      this.alertFiltersRulesData = [];
      matelement.locationMaterialRules?.forEach((rulelement: LocationMaterialRules) => {
        if (null != this.ruleList && this.ruleList.includes(rulelement.ruleId) && showHiddenRules? rulelement.isActive: true) {
          this.alertFiltersRulesData.push(rulelement);
        }
      });
      this.alertFiltersMaterialData.push({ material: matelement.material, locationMaterialRules: this.alertFiltersRulesData })
    }
  }
}

function dateFormatterUpdate(params: any) {

  if (params.data.updatedDate == null) {
    return "";
  }

  return params.dateService.getStandardDateFromISOString(params.data.updatedDate)
          + " - " + params.dateService.getLocalTimeFromISO(params.data.updatedDate);
}

function dateFormatterCreated(params: any) {

  if (params.data.createdDate == null) {
    return "";
  }

  return params.dateService.getStandardDateFromISOString(params.data.createdDate)
          + " - " + params.dateService.getLocalTimeFromISO(params.data.createdDate);
}

function lowercaseFormatter(params: ValueFormatterParams) {
  return params.value.toLowerCase()
}

function deletedFormatter(params: ValueFormatterParams) {
  if (params.data.isActive === false) {
    return { background: 'rgba(200,0,0,0.2)' }
  } else {
    return { background: 'rgba(200,0,0,0)' }
  }
}
