<!-- Activity Tracker Table within Pop Out Window Component -->

<div class="main-container d-flex flex-column">
  <p class="header ml-3 mt-1 mb-0">{{tableTitle}}</p>

  <div class="table-container mb-0 mr-3 ml-3 mt-3">

    @if (rowData.length != 0) {
      <table mat-table [dataSource]="rowData">
        <!-- New Value Column -->
        <ng-container matColumnDef="New">
          <th mat-header-cell *matHeaderCellDef> New {{this.selectedOption.displayName}}</th>
          <td mat-cell *matCellDef="let element"> {{element.newValue}} </td>
        </ng-container>
        <!-- Old Value Column -->
        <ng-container matColumnDef="Old">
          <th mat-header-cell *matHeaderCellDef> Old {{this.selectedOption.displayName}}</th>
          <td mat-cell *matCellDef="let element"> {{element.oldValue}} </td>
        </ng-container>
        <!-- Change By Column -->
        <ng-container matColumnDef="Changed By">
          <th mat-header-cell *matHeaderCellDef> Changed By </th>
          <td mat-cell *matCellDef="let element"> {{element.changedBy}} </td>
        </ng-container>
        <!-- Change On Column -->
        <ng-container matColumnDef="Changed On">
          <th class="dateHeader" mat-header-cell *matHeaderCellDef> Changed On </th>
          <td mat-cell *matCellDef="let element"> {{element.changedAt}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="bottom-border"></tr>
        <tr class="height" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    }
  </div>


  @if (this.rowData.length == 0) {
    <p class="ml-3 noChangeText"> No {{this.selectedOption.displayName}} Changes To Show</p>
  }
</div>