<div class="comment-card maxHeight">

  <!-- Header for card with name/date & edit/delete action ellipsis -->
  <div class="d-flex flex-row justify-content-between">
    @if (commentPublished) {
      <name-header [userName]="comment.createdBy" [dateToShow]="comment.createdDate"></name-header>
    }
    @if (!commentPublished) {
      <name-header [creatingNewItem]="true" [userName]="''" [dateToShow]="''"></name-header>
    }
    @if (commentPublished && comment.email == currentUserEmail) {
      <div class="more-menu">
        <div class="more-menu-dropdown">
          <button  class="ellipses-icon"></button>
          <div class="dropdown-content place-top">
            <a class="dropdown-item" (click)="setEditComment()">edit</a>
            <a class="dropdown-item" (click)="deleteComment()">delete</a>
          </div>
        </div>
      </div>
    }
  </div>

  <!-- Section used to display the comment text, either a  -->
  <div class="content-section">
    @if (!isInputActive) {
      <p class="mt-4 mb-0" >{{commentContent}}</p>
    }
    @if (isInputActive) {
      <form class="p-0">
        <mat-form-field class="text-input-section">
          <mat-label>Add a comment</mat-label>
          <textarea matInput [(ngModel)]="commentContent" name="commentContent"
          class="commentArea"></textarea>
        </mat-form-field>
      </form>
    }
  </div>

  <!-- Bottom row section used for viewing tags and for cacel/create/edit  -->
  <div class="d-flex flow-row justify-content-between content-section">
    <div class="d-flex flex-row flex-wrap m-2 tagColumn">
      @for (tag of tags; track tag; let indx = $index) {
        <span class="blueSelector" (click)="removeTag(indx)" [ngClass]="{'tagRemover': isInputActive}">
          <img src="../../../../../assets/icons/icon_tag.svg">
        {{tag}},&nbsp;</span>
      }
      @if (isInputActive) {
        <add-tag [hidden]="totalTagLengthOptions <= tags.length"
        [selectedTags]="tags" (selectedTag)="updateTagList($event)" (lengthOfAllOptions)="updateTotalLength($event)"></add-tag>
      }
    </div>
    @if (isInputActive) {
      <div class="d-flex flex-row justify-content-between button-holder">
        <button mat-flat-button (click)="cancelChanges()" class="hoverable no-background flexOne mr-2 p-0">
          cancel
        </button>
        @if (isInputActive && createNewComment) {
          <button mat-flat-button class="hoverable flexOne blue p-0"
            [disabled]="commentContent.length == 0" [ngClass]="{'disabled': commentContent.length == 0}"
            (click)="createComment()">
            create
          </button>
        }
        @if (isInputActive && !createNewComment) {
          <button mat-flat-button
            [disabled]="commentContent.length == 0" [ngClass]="{'disabled': commentContent.length == 0}"
            (click)="updateComment()" class="hoverable blue p-0">
            update
          </button>
        }
      </div>
    }
  </div>
</div>
