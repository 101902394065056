<div>
  @if (label) {
    <div class="switch-label text-color">{{label}}</div>
  }
  <div class="align-items-center d-flex justify-content-center">
    <label class="switch">
      <input type="checkbox" (change)="toggle($event)" [checked]="checked">
      <span class="slider round"></span>
    </label>
    @if (displayStatus) {
      <span class="mx-2 status-label text-color">{{checked ? 'on' : 'off'}}</span>
    }
  </div>
</div>