<div class="panel-content">
  <ng-container>
    <div>
      <div class="content__header" (click)="changeLocationAccordion()">terminal
        <span><img src="../../../../assets/icons/down-arrow.svg" [ngClass]="{'image-rotate': this.locationAccordionClosed}" alt="up down direction indicator"></span></div>
        <form class="branded">
          <input class="form-control settings-alert-rules-search " [name]="'searchText0'" type="search"
            placeholder="search" (input)="updateAlertLocationFilterList($any($event.target).value)"
            [(ngModel)]="locationsearchValue">
            @if (!this.locationAccordionClosed) {
              <div class="settings-alert-rules-side-panel">
                @for (obj of updateSettingsAlertLocationSearchData; let i = $index; track obj) {
                  <p class="align-items-center d-flex">
                    <input type="checkbox" class="flex-shrink-0" name="locationCheckbox" [id]="obj.location"
                      [value]="obj.location" [checked]="obj.checked" (change)="filterByAlertLocation($event)">
                      <label class="text-ellipsis" [title]="obj.location" [htmlFor]="obj.location">
                        {{obj.location}}
                      </label>
                    </p>
                  }
                </div>
              }
            </form>
            <hr>
            </div>
            @if (updateSettingsAlertUniqueMaterialSearchData.length) {
              <div>
                <div class="content__header" (click)="changeMaterialAccordion()">material <span>
                  <img src="../../../../assets/icons/down-arrow.svg" [ngClass]="{'image-rotate': this.materialAccordionClosed}" alt="Directional Indicator Right"></span>
                </div>
                <form class="branded">
                  <input class="form-control settings-alert-rules-search " [name]="'searchText1'" type="search"
                    placeholder="search" (input)="updateAlertMaterialsFilterList($any($event.target).value)"
                    [(ngModel)]="materialsearchValue">
                    @if (!this.materialAccordionClosed) {
                      <div class="settings-alert-rules-side-panel">
                        @for (obj of updateSettingsAlertUniqueMaterialSearchData; track obj; let i = $index) {
                          <p class="align-items-center d-flex">
                            <input type="checkbox" class="flex-shrink-0" name="MaterialCheckbox" [id]="obj.material"
                              [value]="obj.material" [checked]="obj.checked" (change)="filterByAlertMaterial($event)">
                              <label class="text-ellipsis" [title]="obj.material" [htmlFor]="obj.material">
                                {{obj.material}}
                              </label>
                            </p>
                          }
                        </div>
                      }
                    </form>
                    <hr>
                    </div>
                  }
                  @if (updateSettingsAlertRuleSearchData.length) {
                    <div>
                      <div class="content__header" (click)="changeRuleAccordion()">rule <span>
                        <img src="../../../../assets/icons/down-arrow.svg" [ngClass]="{'image-rotate': this.ruleAccordionClosed}" alt="Directional Indicator Right"></span>
                      </div>
                      <form class="branded">
                        <input class="form-control settings-alert-rules-search " [name]="'searchText2'" type="search"
                          placeholder="search" (input)="updateAlertRulesFilterList($any($event.target).value)"
                          [(ngModel)]="rulesearchValue">
                          @if (!this.ruleAccordionClosed) {
                            <div class="settings-alert-rules-side-panel">
                              @for (obj of updateSettingsAlertRuleSearchData; track obj; let i = $index) {
                                <p class="align-items-center d-flex">
                                  <input type="checkbox" class="flex-shrink-0" name="RuleCheckbox" [id]="obj.ruleid"
                                    [value]="obj.ruleid" [checked]="obj.checked" (change)="filterByAlertRule($event)">
                                    @if (obj.rulename.includes('all')) {
                                      <label class="text-ellipsis" [title]="obj.ruleid" [htmlFor]="obj.ruleid">
                                        {{obj.rulename}}
                                      </label>
                                    }
                                    @if (!obj.rulename.includes('all')) {
                                      <label class="text-ellipsis" [title]="obj.ruleid" [htmlFor]="obj.ruleid">
                                        {{"R" + obj.rulename.split(" ")[1] + ": " + obj.material + " - " + obj.location}}
                                      </label>
                                    }
                                  </p>
                                }
                              </div>
                            }
                          </form>
                        </div>
                      }
                    </ng-container>
                  </div>

                  <div class="panel-footer">
                    <span class="clear-filter" (click)="clearFilters()">clear</span>
                  </div>