import { savePipelineNomFiltersResponse, savePipelineNomFiltersUpdated } from '../../../shared/store/action/app.action';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CellClickedEvent, CellValueChangedEvent, ColDef, GetMainMenuItemsParams, GridApi, GridReadyEvent, SelectionChangedEvent, IsRowMaster, FirstDataRenderedEvent, IRowNode, ColumnApi, IAggFunc, IAggFuncParams } from 'ag-grid-community';
import { Subject,take, takeUntil } from 'rxjs';
import { CreateRefineryNominationComponent } from 'src/app/core/components/create-refinery-nomination/create-refinery-nomination.component';
import { NominationService } from 'src/app/services/nomination.service';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getPipleineFilteredValues, getUserDetails, selectDesk, selectPipelineNomFiltersResponse, selectUDMScreen, selectRefineryNomFiltersResponse } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import * as Nom from '../../../shared/constants/nominations.constant'
import { ToastService } from 'src/app/services/toast.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AutoCompleteComponent } from '../../autocomplete/autocomplete.component';
import { VendorNotificationPreviewComponent } from 'src/app/core/components/vendor-notification-preview/vendor-notification-preview.component';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { VesselScheduleUpdateComponent } from 'src/app/core/components/vessel-schedule-update/vessel-schedule-update.component';
import { UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MyMonitoringService } from 'src/app/services/logging.service';
import { CellInfoHoverComponent } from 'src/app/shared/modules/cell-info-hover/cell-info-hover.component';
import { MoreMenuNonCPComponent } from 'src/app/shared/modules/more-menu-nonCP/more-menu-nonCP.component';

@Component({
  selector: 'app-nomination-refinery-view',
  moduleId: module.id,
  templateUrl: './nomination-refinery-view.component.html',
  styleUrls: ['./nomination-refinery-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NominationRefineryViewComponent implements OnInit {

  private gridApi!: GridApi<any>;
  frameworkComponents;
  destroy$: Subject<boolean> = new Subject<boolean>();
  gridColumnApi!: ColumnApi;
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  selectedRefineryNomFiltersResponse$ = this.store.pipe(select(selectRefineryNomFiltersResponse));
  //selectedRefineryNomFiltersResponse$ = this.store.pipe(select(selectedRefineryNomFiltersResponse$));
  selectedDeskOption!: any;
  selectedDeskId: number = 0;
  getRefineryFilterResponse!: any;
  terminalFilterData!: any[];
  productFilterData!: any[];
  transportSystemFilterData!: any[];
  public selectedRows: any[] = [];
  updatePayload: any[] = [];
  headerToItemNav: any[] = [];
  currentParentRow!: any;
  detailHeaderToItemNav: any[] = [];
  detailHeaderToItemNavHeader: any[] = [];
  public rowData!: any[];
  createNominationPipelineModal = { open: false, modal: 'createPipelineNomination' };
  headerPayload: any[] = [];
  detailPayload: any[] = [];
  warningPopup: boolean = false;
  vendorNotificationPreview = { open: false, modal: 'createPreviewForNomination' }
  @ViewChild(CreateRefineryNominationComponent) createRefineryNominationComponent!: CreateRefineryNominationComponent;
  @ViewChild(VesselScheduleUpdateComponent) pipelineVesselScheduleUpdate!: VesselScheduleUpdateComponent;
  @ViewChild(VendorNotificationPreviewComponent) vendorPreviewNotificationComponent!: VendorNotificationPreviewComponent;
  pipelineNominationModal = { open: false, modal: 'createPipelineNomination' }
  vesselScheduleUpdateModal = { open: false, modal: 'pipelineVesselScheduleUpdate' }
  masterGridSelectedRows: any[] = [];
  detailGridSeectedRows: any[] = [];
  copyRowData: any[] = [];
  parentGridIndex: any;
  mainGridIndex!: number;
  modeOfTransport!: any[];
  public bblTrucks: boolean = false;
  public bblTrucks3: boolean = false;
  public materialCode: any[] = [];
  public materialFilteredCode: any[] = [];
  public locationCodeNonCp: any[] = [];
  getonRowSelect: any = [];
  suppliers: any = [];
  sapContracts: any[] = [];
  currentlyActiveLineRow: any;
  differentHeaderId: any;
  nomType: any = [];
  SAPContractPopup: boolean = false;
  userEmail: any;
  userFullEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  allowForGetCustomView: boolean = false;
  motType: any;
  goToNominationId: any;
  goToNominationNumber:any
  currentScreen: any;
  region: any;
  selectedDeskParameter: any;
  isPipelineNomEdit: boolean = false;
  modalTitle = "creating new refinery nomination";
  secondLevelGridColumnDef: any = [];

 isCargo:any=false;
  dummySecondLevelGridColumnDef: any = [];
  updateUdmCargoPascagoulaData: any = {};
  updateUdmCargoPascagoulaDataChildLevel: any = {};
  applyColOrderExpansionParameters: any = {};
  getPipleineFilteredValues$ = this.store.pipe(select(getPipleineFilteredValues));
  isPipelineNomEditRestrict = false;

  constructor(private appStore: Store<AppState>, private nominationService: NominationService, private store: Store, private router: Router, private utilities: Utilities, private serv: NominationService, private datePipe: DatePipe, private toaterService: ToastService, private ethanolService: EthanolTruckService, private cpService: CargoPlanningService, private loaderService: LoaderService, private myMonitoringService: MyMonitoringService) {
    this.frameworkComponents = { datePicker: DatePickerComponent };
  }

  ngOnInit(): void {
    this.columnDefs.forEach((columnDef: any) => {
      if (columnDef.editable) {
        columnDef.cellClass = 'highlightEditableCell';
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.goToNominationId = null
        this.goToNominationNumber = null;
      }
    })

    this.nominationService.triggerDeselectForCargo.subscribe(res => {
      if(res){
        this.gridApi?.deselectAll();
      }
    })
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
            this.userFullEmail = response.userDetails.email;
            localStorage.setItem("userEmail", this.userFullEmail);
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if(this.selectedDeskOption !== response.selectedDesk){
            this.myMonitoringService.logPageView(response.selectedDesk.split(" + ").join(' ') + ' Nominations');
          }
          this.selectedDeskOption = response.selectedDesk;
          this.selectedDeskId = +response.selectedDeskId;
          this.selectedDeskParameter = response.selectedDeskParam;

        },
        error: (err: any) => { },
      });
    this.selectedRefineryNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isPipelineNomFilterUpdated && !this.nominationService?.isCargoScreen?.value) {
          this.getRefineryFilterResponse = response;
          const productFilterArray = this.getRefineryFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "product");
          this.materialCode = productFilterArray[0]?.list.filter((item: any) => item.id).map((el: any) => {
            return { name: el.name, value: el.id }
          })
          const locationFilterArray = this.getRefineryFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "terminal");
          this.locationCodeNonCp = locationFilterArray[0]?.list.filter((item: any) => item.id).map((el: any) => {
            return { name: el.name, value: el.id }
         })
          this.getRefineryNominations(this.getRefineryFilterResponse);
        }
      },
      error: (err: any) => { },
      complete: () => { }

    });
    this.utilities.sendActionToChild$.subscribe(action => {
      if (action === 'expand') {
        this.gridApi.forEachNode(index => {
          index.setExpanded(true);
        })
      }
      else {
        this.gridApi.forEachNode(index => {
          index.setExpanded(false);
        })
      }
    });

    this.nominationService.applyEditedViewToUi$.subscribe(res => {
      if (res && this.router.url === '/nominations' && localStorage.getItem("selectedView")) {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    });

    this.nominationService.applyCustomView$.subscribe(res => {
      if (res && localStorage.getItem("selectedView") && this.router.url === '/nominations') {
        this.getCustomViewByName(res);
        this.allowForGetCustomView = true;
      }
    })

    this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
      if (response.function === 'createRefineryNomination') {
        this.isPipelineNomEdit = false;
        this.toggleCreatePipelineNominationsModal();
      }
      else if (response.function === 'pipelineCreateNominationWithReference') {
        this.isPipelineNomEdit = false;
        this.createNominationWithReference(response.values)
      }
      else if(response.function === 'pipelineVesselScheduleUpdate'){
        this.updateVesselScheduleDate(response)
      } else if(response.function === 'onCreatePipelineNominationFromTR'){
        if(response?.values?.headerToItemNav.length){
          this.ethanolService.modalPopoOpen$.next(true);
          this.createRefineryNominationComponent.trNominationForPipeline = response.values;
          const modal = { open: true, modal: 'createPipelineNomination' };
          this.isPipelineNomEdit = response.values?.editNomination;
          this.pipelineNominationModal = { ...modal };
          this.createRefineryNominationComponent.presetNominationDataForPipeline();
        }
    }else if(response.function === 'editPopupNomNo' && !this.selectedDeskOption?.includes("+ latam +")){
      this.isPipelineNomEdit = true;
      this.isPipelineNomEditRestrict = true;
      this.createNominationWithReference(response);
    } else if(response.function === 'pipelineCreateNominationWithReferenceNonCP'){
      this.isPipelineNomEdit = false;
      this.isPipelineNomEditRestrict = false;
      this.createNominationWithReference(response);
    }
    })

    this.nominationService.goToNominationData$.subscribe((value: any) => {
      if (Object.keys(value).length && value?.isTrue) {
        const fieldType = value?.data?.colDef?.field;
        let fieldDataName = fieldType?.split('_')[3] ? fieldType?.split('_').slice(0, 3).join('_') : fieldType;
        const getId = fieldDataName ? value?.data?.data[`${fieldDataName}_id_Child`] : value?.data?.id;
        this.goToNominationId = getId;
        this.goToNominationNumber = fieldDataName? value?.data?.data[`${fieldDataName}`]: value?.data?.nominationNo;
      }
    })

    this.nominationService.applyTrCoulmnOrder$.subscribe(res => {
      this.callColOrderAndExpansion(res);
    })
    this.nominationService.resetToDefaultView$.subscribe(res => {
      if (res) {
        this.gridColumnApi?.resetColumnState();
      }
    });
    this.callGetViewByNameMethod();
    this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
      if (response.function === "previewVendor") {
        this.toggleNominationPreviewModal();
      }

    });
    this.nominationService.getTerminalNominationAFAPI$.subscribe(res => {
      if (res === true) {
        this.getRefineryNominations(this.getRefineryFilterResponse);
      }
  })
  }

  toggleNominationPreviewModal(): void {
    const selectedRows = this.getSelectedRows();
    const uniqueRows = [...new Map(selectedRows.map((item:any) =>
      [item['id'], item])).values()];
    this.vendorPreviewNotificationComponent.selectedRows(uniqueRows)
    const modal = { open: !this.vendorNotificationPreview.open, modal: 'createPreviewForNomination' }
    this.vendorNotificationPreview = JSON.parse(JSON.stringify(modal));
  }


  updateModalTitle(){
    if(this.selectedDeskParameter?.toLowerCase() === 'pasadena desk' || 'houston blend center desk'){
      this.modalTitle = "create new nomination";
    }
  }

  callGetViewByNameMethod() {
    let selectedView = localStorage.getItem("selectedView");
    if (selectedView) {
      this.getCustomViewByName(selectedView);
    }
  }

  getCustomViewByName(res: any) {
    let payload = { emailId: this.userFullEmail, viewName: res, desk: this.utilities.updateAPIParams() };
    this.nominationService.applyView(payload).subscribe(res => {
      if (res.statusCode === 200) {
        this.formPayloadForFilters(res.value)
      }
    })
  }

  formPayloadForFilters(res: any) {
    let filterList: any[] = [];
    if (this.selectedDeskOption.startsWith("refinery") || this.selectedDeskOption.startsWith("refinery + usec")) {
      this.customViewPipelineFiltersApply(res, filterList);
    }
    this.nominationService.updatedFIlterValuesFromView$.next(filterList);
  }

  customViewPipelineFiltersApply(res: any, filterList: any) {
    let sDate, eDate;
    let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = res.customViews[0].pipelineFilters;
    if (res.customViews[0].pipelineFilters.modeOfTransport && (this.selectedDeskOption === UDMDesk?.PipelineUsecFloridaDesk || this.selectedDeskOption === UDMDesk?.PipelineUsecPascagoulaDesk || this.selectedDeskOption === UDMDesk?.PipelineUsecRichmondDesk || this.selectedDeskOption === UDMDesk?.PipelineUswcPNWDesk)) {
      filterList.push({ headerName: "mode of transport", list: [...modeOfTransport] });
    }
    if (res.customViews[0].pipelineFilters.transportSystemStatus) {
      filterList.push({ headerName: "transport system", list: [...transportSystem] });
    }
    else {
      transportSystem.forEach((transportSystem: any) => {
        transportSystem.checked = true;
      });
      filterList.push({ headerName: "transport system", list: [...transportSystem] });
    }
    /* Fetching existing filter values from state for finding -x locations */
    let selectedRefineryFilterValues:any;
    this.getPipleineFilteredValues$.pipe(take(1)).subscribe({
      next: (response: any) => {
        selectedRefineryFilterValues = response.value??[];
      },
      error: (err: any) => { },
      complete: () => { }
    });
    //Check for dash-x locations in current terminal filters before pushing selected view's terminals and add it
    if(Object.keys(selectedRefineryFilterValues)?.length){
      const existingTerminalsWithDashXloc = selectedRefineryFilterValues.terminal;
      const selectedViewTerminals = terminal;
      terminal = this.utilities.addExistingDashXLocToSelectedViewTerminals(existingTerminalsWithDashXloc,selectedViewTerminals);
    }
    terminal = [...new Set(terminal)];
    if (res.customViews[0].pipelineFilters.terminalStatus) {
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }
    else {
      terminal.forEach((terminal: any) => {
        terminal.checked = true;
      });
      terminal = [...new Set(terminal)];
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }

    if (res.customViews[0].pipelineFilters.productStatus) {
      filterList.push({ headerName: "product", list: [...product] });
    }
    else {
      product.forEach((product: any) => {
        product.checked = true;
      });
      filterList.push({ headerName: "product", list: [...product] });
    }
    sDate = this.nominationService?.defaultFromDate;
    eDate = this.nominationService?.defaultToDate;
    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.nominationService.nomDefaultSetCustomView = { pipelineNomFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }
    this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }));
    this.applyColOrderExpansionParameters = {
      colOrder: res.customViews[0].pipelineFilters?.columnOrders,
      colExpand: res.customViews[0].pipelineFilters?.expandedColumns
    }
  }

  public isRowMaster: IsRowMaster = (dataItem: any) => {
    if (dataItem?.headerToItemNav?.length > 1) {
      return true;
    } else if (dataItem?.headerToItemNav?.length === 1 && dataItem?.headerToItemNav[0]?.udmDailyBreakdownQty?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  onFirstDataRendered(params: FirstDataRenderedEvent) {
    var rpFilterData : any = localStorage.getItem('sPNFR');
    var filterListDataFromRP : any = JSON.parse( rpFilterData );
    let rowFound = false;
    let sDate, eDate;
    let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = filterListDataFromRP.pipelineFilterValues ;

    sDate = filterListDataFromRP.pipelineFromDate;
    eDate = filterListDataFromRP.pipelineToDate;

    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.nominationService.nomDefaultSetCustomView = { pipelineNomFilterValues: JSON.parse(JSON.stringify(filterListDataFromRP.pipelineFilterValues)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true };
    this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterListDataFromRP.pipelineFilterValues)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }));

    const nodesToSelect: IRowNode[] = [];
    setTimeout(() => {
      params.api.forEachNode((node: IRowNode) => {
        if (node?.data?.id === this.goToNominationId) {
          this.nominationService.goToNominationData$.next({ isTrue: false, data: null });
          params.api.getRowNode(`${node.id}`)?.setSelected(true);
          params.api.ensureIndexVisible(Number(node.id), 'top');
        }
        if (localStorage.getItem('nomId')) {
          let tempValue = localStorage.getItem('nomId');
          if( node.key === tempValue )
            {
              params.api.getRowNode(`${node.id}`)?.setSelected(true);
              params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
              localStorage.setItem('nomId', '');
              rowFound = true ;
            }
        }
      });
    }, 1000);
  }

  onFirstDataRenderedNonCP(params: FirstDataRenderedEvent) {
    var rpFilterData : any = localStorage.getItem('sPNFR');
    var filterListDataFromRP : any = JSON.parse( rpFilterData );
    let rowFound = false;
    let sDate, eDate;
    let { modeOfTransport, transportSystem, product, terminal, fromDate, toDate } = filterListDataFromRP.pipelineFilterValues ;

    sDate = filterListDataFromRP.pipelineFromDate;
    eDate = filterListDataFromRP.pipelineToDate;

    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.nominationService.nomDefaultSetCustomView = { pipelineNomFilterValues: JSON.parse(JSON.stringify(filterListDataFromRP.pipelineFilterValues)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true };
    this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterListDataFromRP.pipelineFilterValues)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }));

    setTimeout(() => {
      params.api.forEachNode((node: IRowNode) => {
        if (node?.key === this.goToNominationNumber && !rowFound) {
          this.nominationService.goToNominationData$.next({ isTrue: false, data: null });
          params.api.getRowNode(`${node.id}`)?.setSelected(true);
          params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
          rowFound = true;
        }
        if (localStorage.getItem('nomId')) {
          let tempValue = localStorage.getItem('nomId');
          if( node.key === tempValue )
            {
              params.api.getRowNode(`${node.id}`)?.setSelected(true);
              params.api.ensureIndexVisible(Number(node.rowIndex), 'top');
              localStorage.setItem('nomId', '');
              rowFound = true ;
            }
        }
      });
    }, 1000);
  }

  getSelectedRows(){
    let selectedRowNodes = this.gridApi.getSelectedNodes();
    if(selectedRowNodes.length === 0) this.masterGridSelectedRows = [];
    let selectedRowsFromChildrenNode:any=[];
    selectedRowNodes.forEach((rowNode:any) => {
      rowNode.allLeafChildren.forEach((row:any)=>{
        selectedRowsFromChildrenNode.push(row.data);
      })
    })
    return selectedRowsFromChildrenNode;
  }

  onRowSelectNonCp(event: any) {

    let selectedRowsFromChildrenNode = this.getSelectedRows();


      if(selectedRowsFromChildrenNode){
      this.selectedRows = selectedRowsFromChildrenNode;

      // Adding udmNominationGrouping flag for selected rows
      this.selectedRows?.forEach((obj:any)=>{
      obj["udmNominationGrouping"] = false;
      obj["isNonCPDesk"] = true;
      })

      let truckNoms = this.selectedRows?.filter(eachRow => {
        return eachRow?.modeOfTransportDesc?.toLowerCase() === 'truck';
      })
      this.serv.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
      this.serv.nomSelected$.next(false);
      this.serv.enableNominationsSaveButton$.next(true);
      this.serv.selectedNom$.next(this.selectedRows);

      // As event.api.getSelectedRows() wont work for grouped rows, manually pushing and removing
      if(event?.event?.target?.checked || event?.source?.toLowerCase() === 'rowclicked' || event?.source?.toLowerCase() === 'api'){
        this.masterGridSelectedRows= this.selectedRows;
      }else{
        this.selectedRows?.forEach((data:any,index:any)=>{
          this.masterGridSelectedRows = this.masterGridSelectedRows.filter((c:any)=>c.itemId !== data.itemId);
        })
      }
      if (truckNoms?.length) {
        this.serv.enableHeaderButton$.next({ deleteButton: true, publishButton: false, vendorNotificationButton: true });
      }
    // Retrieve unique nomination number from this.masterGridSelectedRows
      this.retrieveUniqueNomNo();
    }
    console.log(this.selectedRows);
  }

  retrieveUniqueNomNo(){
    const uniqueNominationNo = [...new Set(this.masterGridSelectedRows.map((a:any) => a.nominationNo))];
      if (uniqueNominationNo?.length === 1) {
        this.serv.nomSelected$.next(true);
      }
      const unPublishedNomArray: string[] = [];
      this.selectedRows?.forEach((data: any) => {
        if ((data.udmItemStatus?.toLowerCase() === 'published') || (data?.nominationStatus?.toLowerCase() === 'published')) {
          unPublishedNomArray.push("published")
        }
        else {
          unPublishedNomArray.push("unpublished")
        }
      })
      if (this.selectedRows?.length > 0) {
        this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: unPublishedNomArray?.every((status: any) => status === 'published') });
      } else {
        this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
      }
  }

  onRowSelect(event: any) {
    this.isSameSupplierSelected()
  }

  isSameSupplierSelected() {
    this.suppliers = this.gridApi.getSelectedRows().map((item: { supplier: any; }) => item.supplier)
    this.motType = this.gridApi.getSelectedRows().map(item => item.modeOfTransportDesc);
    this.nomType = this.gridApi.getSelectedRows().map((item => item.headerToItemNav?.every((lineItem: { udmItemStatus: string; }) => lineItem.udmItemStatus?.toLowerCase() === 'published')));
    this.suppliers = [...new Set(this.suppliers)];
    this.nomType = [... new Set(this.nomType)];
    this.motType = [... new Set(this.motType)];
    if (this.suppliers.length > 1) {
      this.nominationService.isSameRowSelected$.next(false);
    }
    else {
      this.nominationService.isSameRowSelected$.next(true)
    }
    if (this.nomType[0] || this.motType.length === 1 && this.motType[0]?.toLowerCase() === 'truck') {
      this.nominationService.isSelectedPublished$.next(true);
    }
    else {
      this.nominationService.isSelectedPublished$.next(false);
    }
  }


  getRefineryNominations(response: any) {
    this.getRefineryFilterResponse = response;
    const terminalFilterArray = this.getRefineryFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "terminal");
    const productFilterArray = this.getRefineryFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "product");
    const transportsystemArray = this.getRefineryFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "transport system");
    const motArray = this.getRefineryFilterResponse?.pipelineNomFilterValues?.filter((el: any) => el?.headerName?.toLowerCase() === "mode of transport");
    this.terminalFilterData = [];
    this.productFilterData = [];
    this.modeOfTransport = [];
    this.transportSystemFilterData = [];
    this.setTerminalFilterData(terminalFilterArray);
    this.setProductFilterData(productFilterArray);
    this.setTransportSystemFilterData(transportsystemArray);
    this.setMotFilterData(motArray);
    let postData!: any;
    /**selectedDesk$ and if else desk conditions to be removed and maintain only postData code here after routing changes implmented */
    if (this.selectedDeskOption.includes("refinery")) {
      postData = {
         desk: "refinery",
        fromDate: this.getRefineryFilterResponse?.pipelineFromDate,
        modeOfTransport: this.modeOfTransport,
        toDate: this.getRefineryFilterResponse?.pipelineToDate,
        terminal: this.terminalFilterData,
        product: this.productFilterData,
        transportsystem: this.transportSystemFilterData,
        region: this.region,
        deskFilter: `${this.selectedDeskParameter}`,
      }
      this.serv.getPipelineNominationListPayload = postData;
    }
    if (postData) {
      this.getNominationsAf(postData);
    }
  }

  setTerminalFilterData(terminalFilterArray: any) {
    if (terminalFilterArray?.length > 0) {
      terminalFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.terminalFilterData.push(dt?.id);
        }
      });
    }
  }
  setProductFilterData(productFilterArray: any) {
    if (productFilterArray?.length > 0) {
      productFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.productFilterData.push(dt?.id);
        }
      });
    }
  }

  setTransportSystemFilterData(transportSystemFilterArray: any) {
    if (transportSystemFilterArray?.length > 0) {
      transportSystemFilterArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.transportSystemFilterData.push(dt?.id);
        }
      });
    }
  }

  setMotFilterData(motArray: any){
    if (motArray?.length > 0) {
      motArray[0]?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          this.modeOfTransport.push(dt?.name);
        }
      });
    }
  }


  getNominationsAf(postData: any) {
    this.changeHeaderNames();
    this.nominationService?.isTriggeredFromNomModalPopup$?.next(false);
    this.appStore.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: false }));
    this.utilities.getPipelineNominationMasterData(postData)
    this.serv.getFilterNominations(postData).subscribe(data => {
      this.rowData = data.result;
      if(this.rowData)
      this.rowData.forEach(el => {
        el.nominationReferenceDocumentItem = this.utilities.removeLeadingZeros(el.nominationReferenceDocumentItem);
        el.scheduledDate = this.dateCellRenderer(el.scheduledDate);
        el.udmLaycanStartDate = this.dateCellRenderer(el.udmLaycanStartDate);
        el.udmLaycanEndDate = this.dateCellRenderer(el.udmLaycanEndDate);
        el.modeOfTransportDesc = this.setMotIfVessel(el);
        if (el?.headerToItemNav?.length > 0) {
          const headerToItemNavValue = el?.headerToItemNav;
          headerToItemNavValue.forEach((val: any) => {
            val.lineItem = true;
            val.modeOfTransportDesc = this.setMotIfVessel(val);
            val.scheduledDate = this.dateCellRenderer(val?.scheduledDate);
            val.udmEndLoadDate = this.dateCellRenderer(val?.udmEndLoadDate);
            if (val?.udmDailyBreakdownQty?.length > 0) {
              const udmDailyBreakdownQtyValue = val?.udmDailyBreakdownQty;
              udmDailyBreakdownQtyValue.forEach((dt: any) => {  //NOSONAR lightweight logging
                dt.scheduledDate = this.dateCellRenderer(dt?.scheduledDate);
              });
            }
          });
        }
      });
      this.copyRowData = JSON.parse(JSON.stringify(this.rowData));

    });
    console.log(postData);
  }

  nominationAfForPascagoulaDesk(){
    this.dummyColumnDefs = [...(this.columnDefs)];
        ['voyageStatus','compon','date','VOY','equipment','status B','HOU/DEST','ETDK','Current Location','RA','Navarick','Nav Nom','Inspection'].forEach((ele:any)=>{
          if (!this.columnDefs.map((el:any)=>el.headerName).includes(ele)) {
            if (ele === 'status B') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__statusB`,
                editable: true
              })
            }else if (ele === 'HOU/DEST') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__HOU_DEST`,
                editable: true,
                cellEditor: DatePickerComponent
              })
            }else if (ele === 'ETDK') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__ETDK`,
                editable: true,
                cellEditor: DatePickerComponent
              })
            }
            else if (ele === 'date') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__date`,
                editable: true,
                cellEditor: DatePickerComponent
              })
            }
            else if (ele === 'Current Location') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__currentLocation`,
                editable: true
              })
            }else if (ele === 'Navarick') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__navarick`,
                editable: true
              })
            }else if (ele === 'Nav Nom') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__navNom`,
                editable: true
              })
            }else if (ele === 'Inspection') {
              this.columnDefs.push({
                headerName: ele,
                field: `udmCargoPascagoulaData__inspection`,
                editable: true
              })
            }
            else{
            this.columnDefs.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__${ele}`,
              editable: true
            })
          }
          }

        })
  }

  setMotIfVessel(value: any) {
    return value.modeOfTransportDesc;
  }
  isNotMultiple(event: any, nomPayload: any) {
    this.masterGridSelectedRows?.forEach(val => {
      const hToItemNavValue: any = [];
      const isStatusDelete = event.trim() === 'delete';
      if (val) {
        if(val?.udmNominationGrouping){ // check if the data is for multi transport

          if (val.headerToItemNav?.length > 0) {
            this.setHeaderToItemNavMultiple(val, hToItemNavValue, isStatusDelete, nomPayload, event);
          }
        }

      else{
        this.isNotMultipleElseCondition(event, val, nomPayload, hToItemNavValue, isStatusDelete);
      }

      }
    })
  }

  isNotMultipleElseCondition(event: any, val: any, nomPayload: any, hToItemNavValue: any, isStatusDelete: any){
    if (val.headerToItemNav?.length > 0) {
      this.setHeaderToItemNav(val, hToItemNavValue, isStatusDelete,event);
    }
    let updateSys_status = val?.nominationStatus?.toLowerCase() === "published" ? Nom.INPROCESS : val?.nominationStatus
    nomPayload.push(
      {
        id: val.id,
        deskFilter: "refinerydesk",
        sys_status:event.trim() === 'delete' ? (updateSys_status.toLowerCase()==='failed'?'unpublished':updateSys_status)  : Nom.INPROCESS,
        udmPublishEvent: event.trim() === 'delete' ? this.accessForNecessaryDesk() : undefined,
        headerToItemNav: hToItemNavValue
      }
    )
  }
  masterGridPublishDeleteRecord(event: any, nomPayload: any) {
    const isStatusDelete = event.trim() === 'delete';
    if (!this.masterGridSelectedRows[0].udmNominationGrouping) {
      this.isNotMultiple(event, nomPayload)
    }
    else {
      this.groupTransport(nomPayload, event, isStatusDelete)
    }
  }
  groupTransport(nomPayload: any, event: any, isStatusDelete: any) {
    let obj: any = {};
    this.masterGridSelectedRows[0]?.headerToItemNav.forEach((el: any, i: any) => {
      if (Object.keys(obj).length === 0 || !Object.keys(obj).includes(el.id)) {
        obj[el.id] = [el]
      }
      else {
        obj[el.id].push(el)
      }
    })
    Object.keys(obj).forEach((key: any) => {
      let hToItemNav: any = []

      obj[key].forEach((elem: any) => {
        const deletedUdmItemStatus = elem?.udmItemStatus;
        let deletedItemNomKey = elem?.nominationKey;
        deletedItemNomKey = deletedItemNomKey?.length;
        let updatedStatus =deletedUdmItemStatus;
        if(deletedItemNomKey){
          updatedStatus ="InProcess";
        }
        hToItemNav.push({
          itemId: elem.itemId,
          udmItemStatus:   event.trim() === 'delete' ? (updatedStatus.toLowerCase()==='failed'?'unpublished':updatedStatus) : "InProcess",
        })
      })

      const deletedNomStatus = this.masterGridSelectedRows[0]?.nominationStatus;
      let deletedItemNomKey = this.masterGridSelectedRows[0]?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedStatus =deletedNomStatus;
      if(deletedItemNomKey){
        updatedStatus ="InProcess";
      }

      nomPayload.push({

        id: key,
        deskFilter: "refinerydesk",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        sys_status: event.trim() === 'delete' ? (updatedStatus.toLowerCase()==='failed'?'unpublished':updatedStatus) : "InProcess",
         headerToItemNav: hToItemNav,
      })
    })

  }
  setHeaderToItemNav(val: any, hToItemNavValue: any, isStatusDelete: any, event:any) {
    val?.headerToItemNav?.forEach((el: any) => {
      const deletedUdmItemStatus = el?.udmItemStatus;
      let deletedItemNomKey = el?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedNomStatus =deletedUdmItemStatus;
      if(deletedItemNomKey){
        updatedNomStatus ="InProcess";
      }
      hToItemNavValue?.push({
        itemId: el.itemId,
        udmItemStatus:  isStatusDelete ? (updatedNomStatus.toLowerCase()==='failed'?'unpublished':updatedNomStatus) : "InProcess",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
      });
    });
  }
  setHeaderToItemNavMultiple(val: any, hToItemNavValue: any, isStatusDelete: any, nomPayload: any, event: any) {
    val?.headerToItemNav?.forEach((el: any) => {
      const deletedUdmItemStatus = el?.udmItemStatus;
      let deletedItemNomKey = el?.nominationKey;
      deletedItemNomKey = deletedItemNomKey?.length;
      let updatedStatus =deletedUdmItemStatus;
      if(deletedItemNomKey){
        updatedStatus ="InProcess";
      }
      let headerVal: any = []
      headerVal?.push({
        itemId: el.itemId,
        udmItemStatus: event.trim() === 'delete' ? (updatedStatus.toLowerCase()==='failed'?'unpublished':updatedStatus) : "InProcess",
        deletionIndicator: isStatusDelete ? 'X' : undefined,
        textLine: `{Author:${el.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`

      });
      const deletedNomStatus = val?.nominationStatus;
      let deletedNomKey = val?.nominationKey;
      deletedItemNomKey = deletedNomKey?.length;
      let updatedNomStatus =deletedNomStatus;
      if(deletedItemNomKey){
        updatedNomStatus ="InProcess";
      }
      nomPayload.push(
        {
          id: el.id,
          deskFilter: "refinerydesk",
          sys_status: event.trim() === 'delete' ? (updatedStatus.toLowerCase()==='failed'?'unpublished':updatedStatus) : "InProcess",
          headerToItemNav: headerVal
        }
      )
      hToItemNavValue = [...hToItemNavValue, ...headerVal]
    });
  }

  async publishOrDeleteNomination(event: any) {
    this.selectedRows = [...this.masterGridSelectedRows, ...this.detailGridSeectedRows];
    let nomPayload: any[] = [];
    // Checking if masterGridSelectedRows from Non Cp Desk
    if(this.masterGridSelectedRows.some((obj:any)=>obj.isNonCPDesk)){
      // Finding unique id's from masterGridSelectedRows
      let uniqueIDs = [...new Set(this.masterGridSelectedRows.map((a:any) => a.id))];
      // Transforming masterGridSelectedRows with headerToItemNav
      uniqueIDs.forEach((uniqueId:any)=>{
        let temp = this.masterGridSelectedRows;
        if(this.masterGridSelectedRows.some((obj:any)=>obj.headerToItemNav)){
          temp = this.masterGridSelectedRows.filter((a:any)=>!a.headerToItemNav)
        }
        let tempMasterGridSelectedRows = JSON.parse(JSON.stringify(temp));
        const tempHeaderToItemNav = JSON.parse(JSON.stringify(tempMasterGridSelectedRows.filter((b:any)=>b.id === uniqueId)));
        const uniqueNominationIdIndex = tempMasterGridSelectedRows.findIndex((obj:any)=>obj.id === uniqueId);
        tempMasterGridSelectedRows[uniqueNominationIdIndex]["headerToItemNav"] = JSON.parse(JSON.stringify(tempHeaderToItemNav));
        // Removing other nomination objects without headerToItemNav from this.masterGridSelectedRows
        // Filtering obj with headerToItemNav
        tempMasterGridSelectedRows = tempMasterGridSelectedRows.filter((c:any)=>c.headerToItemNav);
        // Removing existing objs from with matching uniqueId
        this.masterGridSelectedRows = this.masterGridSelectedRows.filter((c:any)=>c.id !== uniqueId);
        // Adding back filtered obj with headerToItemNav to this.masterGridSelectedRows
        this.masterGridSelectedRows.push(...tempMasterGridSelectedRows);
      })


    }

    this.masterGridPublishDeleteRecord(event, nomPayload);
    // only one innergrid for now
    this.setNomPayload(nomPayload, event);
    /*  */
    this.setWarningMsgorApi(nomPayload, event)

  }
  setNomPayload(nomPayload: any, event: any){
    let headerItem: any[] = [];
    /* Can be removed not required */
    this.detailGridSeectedRows?.forEach(val => {
      val.detailType?.forEach((lineItem: any) => {
        headerItem.push({
          itemId: lineItem.itemId,   // need to change  id to itemId
          udmItemStatus: event.trim() === 'delete' ? Nom.INPROCESS : Nom.PUBLISHED,
          deletionIndicator: event.trim() === 'delete' ? 'X' : undefined,
          textLine: `{Author:${lineItem.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`
        })
      })
      headerItem = [...new Set(headerItem)];
      nomPayload.push(
        {
          id: val.id,// check what should be for single nom with detailtype=[]
          deskFilter: "refinerydesk",
          sys_status: event.trim() === 'delete' ? (val.nominationStatus.toLowerCase()==='failed'?'unpublished':val.nominationStatus) : Nom.PUBLISHED,
          headerToItemNav: headerItem,
          udmPublishEvent: event.trim() === 'delete' ? this.accessForNecessaryDesk() : undefined,
        }
      );
    });
  }
  setWarningMsgorApi(nomPayload: any, event: any) {
    let i = 0;
    while (i < this.selectedRows.length && event.trim() !== 'delete') {
      /* Do not call update api for missing values, send error popup */
      if ((nomPayload?.length > 0) && (this.selectedRows[i].demandMaterial === null) || (this.selectedRows[i].scheduledMaterial === null)) {
        this.warningPopup = true;
      }
      i = i + 1
    }
    /**Call update nominations api */
    if (nomPayload?.length > 0 && !this.warningPopup) {
      this.publishDeleteApiCall(event, nomPayload);

    }
  }

  showWarningPopup() {
    this.warningPopup = true;
  }
  publishDeleteApiCall(event: any, nomPayload: any) {
    let nomPayloadLength = nomPayload.length + this.updatePayload.length;
    let matchCount = 0;


   let payload = nomPayload.map((obj1: { headerToItemNav: { itemId: any; }[]; }) => {
    const matchedObj2 = this.updatePayload.find ((obj2: { headerToItemNav: any[]; }) =>
      obj2.headerToItemNav.some ((header2: { itemId: any; }) =>
        obj1.headerToItemNav.some((header1: { itemId: any; }) => header1.itemId === header2.itemId)
      )
    );
    if (matchedObj2) {
      matchCount += 1;
      matchedObj2.headerToItemNav.forEach((header2: { itemId: any; }) => {
        obj1.headerToItemNav.forEach((header1: { itemId: any; }) => {
          if (header1.itemId === header2.itemId) {
            Object.assign(header1, header2); // Merge matched header objects
          }
        });
      });
    }
    return obj1;
  });

  if(payload.length !== (nomPayloadLength - matchCount)){
    payload = [...nomPayload, ...this.updatePayload];
  }

    if (this.selectedDeskOption.includes('refinery')) {
      this.serv.updateNomination(payload).subscribe({
        next: () => {
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.selectedRows = [];
          nomPayload = []
          this.headerPayload = [];
          this.detailPayload = [];
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
          this.updateUdmCargoPascagoulaData = null;
          this.updateUdmCargoPascagoulaDataChildLevel = null;
          this.toaterService.setToastNotification({
            show: true,
            type: 'success',
            msg: event.trim() === "delete" ? "Nomination has been deleted" : "Nomination is being sent to TSW",
          });
        },
        error: (err: any) => {
          this.headerPayload = [];
          this.detailPayload = [];
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
         },
        complete: () => { },
      });
      setTimeout(() => {
        this.getRefineryNominations(this.getRefineryFilterResponse);
        this.gridApi.deselectAll();
      }, 5000)
    }
  }
  updatePayloadFormation() {
    if (this.headerPayload?.length > 0 && this.detailPayload?.length > 0) {
      this.updatePayload = JSON.parse(JSON.stringify(this.headerPayload));
      this.detailPayload?.map((element: any) => {
        if (!(this.updatePayload?.map((el: any) => el?.id).includes(element?.id))) {
          this.updatePayload?.push(element);
        } else {
          const rowIndex = this.updatePayload?.map((el: any) => el?.id).indexOf(element?.id);
          this.updatePayload[rowIndex]["headerToItemNav"] = element?.headerToItemNav;
        }
      });
    } else if (this.headerPayload?.length > 0 && this.detailPayload?.length === 0) {
      this.updatePayload = JSON.parse(JSON.stringify(this.headerPayload));
    } else if (this.headerPayload?.length === 0 && this.detailPayload?.length > 0) {
      this.updatePayload = JSON.parse(JSON.stringify(this.detailPayload));
    }
  }

  save() {
    this.updatePayloadFormation()
    if (this.updatePayload?.length > 0 && !( this.masterGridSelectedRows.length ||  this.detailGridSeectedRows.length )) {
      this.masterGridSelectedRows = [];
      this.detailGridSeectedRows = [];
      this.serv.updateNomination(this.updatePayload).subscribe({
        next: (response: any) => {
          this.publishOrDeleteNomination('publish');
          this.masterGridSelectedRows = [];
          this.detailGridSeectedRows = [];
          this.headerPayload = [];
          this.detailPayload = [];
          this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
          setTimeout(() => {
            this.getRefineryNominations(this.getRefineryFilterResponse);
            this.gridApi.deselectAll();
          }, 5000)
          this.gridApi.deselectAll();
          this.toaterService.setToastNotification({
            show: true,
            type: "success",
            msg: "Nomination has been updated",
          });
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
          this.updateUdmCargoPascagoulaData = {}
          this.updateUdmCargoPascagoulaDataChildLevel = {}
        },
        error: (err: any) => {
          this.headerPayload = [];
          this.detailPayload = [];
          this.detailHeaderToItemNav = [];
          this.updatePayload = [];
        },
        complete: () => { },
      });
    }
    else{
      this.publishOrDeleteNomination('publish');
    }
  }

  public rowSelection: 'single' | 'multiple' = 'multiple';
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    cellClass: 'subtitle-6 font-gotham-narrow-book',
    headerClass: 'font-gotham-bold'
  };
  columnDefs:any =[];
  dummyColumnDefs: any = [];
  isCargoClone:any;

autoGroupColumnDef = {
  headerName: 'nomination no',
  filter: 'agGroupColumnFilter',
  cellRendererParams: {
      suppressCount: true,
      checkbox: true,
      innerRenderer: CellInfoHoverComponent,
  }
};
public aggFuncs: {
  [key: string]: IAggFunc;
} = {
  checkMultiple: (params: IAggFuncParams) => {
    return params.values.every((val:string) => val === params.values[0])?params.values[0]:'multiple';
  },
  originVolSum:(params:IAggFuncParams)=>{
    let totalOrigin = 0;
    let totalDestination = 0;
    params.rowNode.allLeafChildren.forEach((row:any)=>{
      if(row.data.scheduleType === 'O' || row.data.scheduleType === 'IT')
        totalOrigin+=+row.data.scheduledQty;
      else
        totalDestination+=+row.data.scheduledQty;
    });
    return totalOrigin || totalDestination;
  },
  checkEarlyDate: (params: IAggFuncParams) => {
    let arr = params.rowNode.allLeafChildren.length?params.rowNode.allLeafChildren:[];
    if(!arr.length) return;
    let param:any= arr?.reduce((a:any, b:any) => {
      if(a.data.scheduledDate)
        return new Date(a.data.scheduledDate) < new Date(b.data.scheduledDate) ? a : b
    }, arr[0]);
    let date = param.data.scheduledDate;
    return date;
  }
};

  changeHeaderNames() {
    this.isCargoClone = this.isCargo;
    this.columnDefs = [
    {
      field: "actions", headerName: 'actions', width: 100, wrapHeaderText: true, autoHeaderHeight: true,
      maxWidth: 100,
      cellRendererFramework: MoreMenuNonCPComponent
    },
    {
      field: "udmItemStatus", headerName: "status", width: 125,
      aggFunc: 'checkMultiple',
      cellClass: (params: any) => {
        if (params?.value?.toLowerCase() === "unpublished" || params?.value?.toLowerCase() === "multiple") {
          return "unpublished";
        } else if (params?.value?.toLowerCase() === "published") {
          return "published";
        } else {
          return "";
        }
      }
    },
    { field: "nominationNo", headerName:this.isCargo?"nomno.":"nomination no", editable: false, width: 130, wrapHeaderText: true, autoHeaderHeight: true, rowGroup: true, hide: true},
    { field: "nominationKeyItem", headerName:"TSW nom line no", editable: false, width: 130, wrapHeaderText: true, autoHeaderHeight: true},
    {
      field: "partnerNominationNumber", headerName: "partner nom no", width: 150, wrapHeaderText: true, autoHeaderHeight: true, editable: (params: any) => params?.data,
      valueGetter: (params: any) => {
        let sameValue = false;
        if (params?.data?.headerToItemNav?.length > 1) {
          const headerToItemNav = params?.data?.headerToItemNav;
          for (let i = 0; i < headerToItemNav.length - 1; i++) {
            if (headerToItemNav[i]?.partnerNominationNumber === headerToItemNav[i + 1]?.partnerNominationNumber) {
              sameValue = true;
            } else {
              sameValue = false;
              break;
            }
          }
        } else {
          sameValue = true;
        }
        if (sameValue) {
          return params?.data?.partnerNominationNumber;
        } else {
          return "multiple";
        }
      },
    },
    {
      field: "contractPartner", headerName: "counterparty", hide: !this.isCargo, width: 250, editable: true
    },
    {
      field: "vehicleName",
      headerName: "vessel/barge/pipeline name",
      hide: !this.isCargo, width: 125,
      editable: true,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          region: this.region,
          desk: this.selectedDeskOption,
          deskParameter: this.selectedDeskParameter,
          rowData: {},
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.name}_(${data?.id})` : '';
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      },
      aggFunc: 'checkMultiple'
    },
    {
      field:(this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond"))?"carrierName": "carrier", headerName: "carrier", hide: !this.isCargo, width: 125, editable:!((this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond"))),
      aggFunc: 'checkMultiple',
    },
    {
      field: "nominationKey", headerName: "nomkey", width: 125,
      aggFunc: 'checkMultiple',
    },
    {
      field: "udmTankCode", headerName: "Tank", width: 150,
      aggFunc: 'checkMultiple',
    },
    {
      field: "scheduledMaterialName",
      headerName: "scheduled material",
      editable: (params: any) => params?.data,
      cellEditor: AutoCompleteComponent,
      aggFunc: 'checkMultiple',
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          rowData: this.materialCode,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.name;
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: "scheduledMaterial",
      headerName: "scheduled material code",
      editable: (params: any) => params?.data,
      cellEditor: AutoCompleteComponent,
      aggFunc: 'checkMultiple',
      cellEditorParams: () => {
        return {
          propertyRendered: 'id',
          showedColumn: 'id',
          rowData: this.materialCode,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'id',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.id;
              },
            },
            {
              field: 'id',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: "demandMaterialName",
      headerName: "demand material",
      editable: (params: any) => params?.data,
      cellEditor: AutoCompleteComponent,
      aggFunc: 'checkMultiple',
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          rowData: this.materialCode,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.name;
              },
            },
            {
              field: 'name',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: "demandMaterial",
      headerName: "demand material code",
      editable: (params: any) => params?.data,
      cellEditor: AutoCompleteComponent,
      aggFunc: 'checkMultiple',
      cellEditorParams: () => {
        return {
          propertyRendered: 'id',
          showedColumn: 'id',
          rowData: this.materialCode,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'id',
              valueFormatter: (event: any) => {
                let data = event.data;
                return data.id;
              },
            },
            {
              field: 'id',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      field: "transportSystem", headerName: "transport system",
      aggFunc: 'checkMultiple',
      width: 120, wrapHeaderText: true, autoHeaderHeight: true,
    },
    {
      field: "scheduledQty", headerName: "volume", width: 125, editable: (params: any) => params?.data,
      valueFormatter: (params: any) => (this.utilities.formatNumberPipeline(params?.value)), aggFunc: 'originVolSum'
    },
    {
      field:"actualPostedQuantity",headerName: "actual volume", width: 125,
    },
    { field: "unitOfMeasurementName", headerName: "unit of measure",
      aggFunc: 'checkMultiple',
    width: 120, wrapHeaderText: true, autoHeaderHeight: true, },
    {
      field: "udmLaycanStartDate", headerName: "laycan start date", hide: !this.isCargo, width: 200, cellEditor: DatePickerComponent
    },
    {
      field: "udmLaycanEndDate",
      headerName: "laycan end date",
      hide: !this.isCargo, width: 200,
      editable: true, cellEditorParams: {resetToMin: true},
      cellEditor: DatePickerComponent
    },
    {
      field: "scheduledDate",
      headerValueGetter: (params:any)=>this.isCargo?'TSW schedule date':"schedule date",
      width: 140,
      aggFunc: 'checkEarlyDate',
      wrapHeaderText: true, autoHeaderHeight: true,
      editable: true,
      cellEditor: DatePickerComponent,
      comparator: Utilities.dateComparator
    },
    {
      field: "scheduledTime",
      headerName: "arrival time",
      aggFunc: 'checkMultiple',
      width: 120,
      wrapHeaderText: true, autoHeaderHeight: true,
    },
    {
      field: "locationId",
      headerName: "location",
      width: 183,
      aggFunc: 'checkMultiple',
      editable: (params: any) => params?.data,
      wrapHeaderText: true, autoHeaderHeight: true,
      valueGetter: (params: any) => {
        let sameValue = false;
        if (params?.data?.headerToItemNav?.length > 1) {
          const headerToItemNav = params?.data?.headerToItemNav;
          for (let i = 0; i < headerToItemNav.length - 1; i++) {
            if (headerToItemNav[i]?.locationId === headerToItemNav[i + 1]?.locationId) {
              sameValue = true;
            } else {
              sameValue = false;
              break;
            }
          }
        } else {
          sameValue = true;
        }
        if (sameValue) {
          return params?.data?.locationId;
        } else {
          return "multiple";
        }
      },
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'value',
          showedColumn: 'value',
          rowData: this.locationCodeNonCp,
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'value',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.value}` : '';
              },
            },
            {
              field: 'value',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    { field: "tswNominationType", headerName: "nomination type", width: 150, wrapHeaderText: true, autoHeaderHeight: true,
    aggFunc: 'checkMultiple',},
    { field: "scheduleType", headerName: "TSW nomination type",
    width: 150, wrapHeaderText: true, autoHeaderHeight: true,
    aggFunc: 'checkMultiple'},
    {
      field: "nominationReferenceDocument", headerName: "sap contract no", wrapHeaderText: true, autoHeaderHeight: true, width: 150, editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data,
      aggFunc: 'checkMultiple',
      onCellValueChanged:(params:any)=> {params.node.setDataValue(params.column.colId, params.newValue?.replace(/\b0+/g, "")?.trim())
      }
    },
    {
      field: "nominationReferenceDocumentItem", headerName: "contract line item no",
      width: 150, wrapHeaderText: true, autoHeaderHeight: true, editable: (params: any) => params?.data?.scheduledMaterialName && params?.data?.demandMaterialName && params?.data?.nominationReferenceDocument && params?.data,
      aggFunc: 'checkMultiple',
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'contractLineNumber',
          showedColumn: 'contractName',
          region: this.region,
          desk: this.selectedDeskOption,
          deskId: this.selectedDeskId,
          deskParameter: this.selectedDeskParameter,
          rowData: {},
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'contractName',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.contractLineNumber} | ${data?.sraDealDetailReferenceNumber} | ${data?.location} | ${data?.modeOfTransportDescription} | ${data?.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}` : '';
              },
            },
            {
              field: 'contractName',
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      },
    },
    { field: "referenceDocumentIndicator", headerName: "ref doc id", width: 100, wrapHeaderText: true, autoHeaderHeight: true,
    aggFunc: 'checkMultiple',},
    {
      field: 'nominationItemStatus',
      headerName: 'TSW status',
      aggFunc: 'checkMultiple',
      width: 120,
      wrapHeaderText: true, autoHeaderHeight: true,
      valueGetter: (params: any) => {
        let sameValue = false;
        if (params?.data?.headerToItemNav?.length > 1) {
          const headerToItemNav = params?.data?.headerToItemNav;
          for (let i = 0; i < headerToItemNav.length - 1; i++) {
            if (headerToItemNav[i]?.nominationItemStatus?.toLowerCase() === headerToItemNav[i + 1]?.nominationItemStatus?.toLowerCase()) {
              sameValue = true;
            } else {
              sameValue = false;
              break;
            }
          }
        } else {
          sameValue = true;
        }
        if (sameValue) {
          return params?.data?.nominationItemStatus;
        } else {
          return "multiple";
        }
      },
    },
    {
      field: "udmComments", headerName: "comments",  width: 150, editable: (params: any) => params?.data,
      aggFunc: 'checkMultiple',
    },
    // adding additional properties which are needed for delete and publish from three dots in parent level
    {
      field: "createdBy",
      headerName: "createdBy",
      hide: true
    },
    {
      headerName: "Confirm",
      field: "confirmationIndicator",
      editable:true,
      cellRenderer: (params:any) => {
        if(!params?.node?.group){
          const input = document.createElement('input');
          input.type = 'checkbox';
          input.checked = params.value;
          const clonedParamsDataObj = {...params.data};
          input.addEventListener('change', (event :any)=> {
            clonedParamsDataObj.confirmationIndicator = event.target.checked;
            const confirmationIndicatorValue = this.utilities.getConfirmationIndicatorValue(clonedParamsDataObj,true);
            params.node.setDataValue(params.colDef.field, confirmationIndicatorValue);
          });
          return input;
        }
        return null;
      },
      valueGetter: (params: any) => {
        return params?.data?.confirmationIndicator === "X";
      },
      cellClassRules: {
        'disable-option disable-option-with-opacity': (params : any) => !params?.node?.group && (params?.data?.nominationItemStatus === "5")
      },
    }
  ];
  this.columnDefs.forEach((columnDef: any) => {
    if (columnDef.editable) {
      columnDef.cellClass = 'highlightEditableCell';
    }
  });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  public groupDefaultExpanded = 1;


  colCreationForPascagoulaDesk(){
    this.dummySecondLevelGridColumnDef = JSON.parse(JSON.stringify(this.secondLevelGridColumnDef));
      ['voyageStatus','compon','date','VOY','equipment','status B','HOU/DEST','ETDK','Current Location','RA','Navarick','Nav Nom','Inspection'].forEach((ele:any)=>{
        if (!this.secondLevelGridColumnDef.map((el:any)=>el.headerName).includes(ele)) {
          if (ele === 'status B') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__statusB`,
              editable: true
            })
          }else if (ele === 'HOU/DEST') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__HOU_DEST`,
              editable: true,
              cellEditor: DatePickerComponent
            })
          }else if (ele === 'ETDK') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__ETDK`,
              editable: true,
              cellEditor: DatePickerComponent
            })
          }else if (ele === 'date') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__date`,
              editable: true,
              cellEditor: DatePickerComponent
            })
          }
          else if (ele === 'Current Location') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__currentLocation`,
              editable: true
            })
          }else if (ele === 'Navarick') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__navarick`,
              editable: true
            })
          }else if (ele === 'Nav Nom') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__navNom`,
              editable: true
            })
          }else if (ele === 'Inspection') {
            this.secondLevelGridColumnDef.push({
              headerName: ele,
              field: `udmCargoPascagoulaData__inspection`,
              editable: true
            })
          }
          else{
          this.secondLevelGridColumnDef.push({
            headerName: ele,
            field: `udmCargoPascagoulaData__${ele}`,
            editable: true
          })
        }
        }

      })
  }

  getUpdatedChildItems(childItems:any,combinedItems:any){
    childItems.forEach((item:any)=>{
      if (Array.isArray(item) && item?.length) {
         combinedItems.push(...item)
      }
    });
    if(combinedItems.length){
      childItems = combinedItems;
      childItems.forEach((ele:any)=>{
        ele['udmItemStatus']=ele?.sys_status;
        ele['nominationNo']=ele?.nominationNumber;
        ele['scheduledUom']=ele?.scheduledUOM;
      });
    }
    return childItems;
  }


  handleDetailCellValueChanged(event:any,isNonCargo?:boolean){
    this.existanceOfPayloadCheck(event);
        let rowDetail:any;
        // Find id inside headerToItemNav as it is different from multi transport system
        if(!isNonCargo){
          rowDetail = this.copyRowData.find((val:any)=>val.itemId ===event?.data?.itemId);
        }else{
          rowDetail = event?.data;
        }

        let keys = Object.keys(rowDetail);
        const headerToItemPayload: any = {
          itemId: event?.data?.itemId,
          udmItemStatus: 'inprocess',
          textLine: `{Author:${event?.data?.createdBy || null},MdfBy:${this.userEmail},SS:UDM}`,
          scheduledDate: this.datePipe.transform(event?.data?.scheduledDate, "yyyMMdd"),
        }
        this.updateUdmCargoPascagoulaDataChildLevel = {};
        // Filtering unwanted keys for noncargo desks
        if(isNonCargo){
          const keysToInclude = ["itemId","udmItemStatus","scheduledDate","scheduledQty","nominationReferenceDocument","nominationReferenceDocumentItem","partnerNominationNumber","locationName","locationId","scheduledMaterial","scheduledMaterialName","demandMaterial","demandMaterialName","udmComments","contractPartner","udmEndLoadDate","confirmationIndicator"];
          keys = keys.filter(value=>keysToInclude.includes(value));
        }
        for (const key of keys) {
          this.addUpdatedRowsToPayload(rowDetail, key, event, headerToItemPayload,isNonCargo)
        }
        if (!(this.detailHeaderToItemNav?.map((el: any) => el.itemId).includes(event?.data?.itemId))) {
          this.detailHeaderToItemNav.push(headerToItemPayload);
        } else {
          const detailPayloadIndex = this.detailHeaderToItemNav?.map((el: any) => el.itemId)?.indexOf(event?.data?.itemId);
          this.detailHeaderToItemNav[detailPayloadIndex] = headerToItemPayload;
        }
        const updatedComments = this.detailHeaderToItemNav?.filter((el: any) => !!el?.udmComments);
        const payload = {
          id: event?.data?.id,
          sys_status:'inprocess',
          deskFilter: "refinerydesk",
          modeOfTransportDesc: event?.data?.modeOfTransportDesc,
          headerToItemNav: this.detailHeaderToItemNav,
          udmPublishEvent: this.accessForNecessaryDesk(),
          udmNominationGrouping: rowDetail?.udmNominationGrouping ? this.uniqueUID() : '',
          isUdmCommentsUpdated: updatedComments?.length > 0 ? true : undefined,
        }
        this.finalUpdatePayloadFormation(payload,event);
        this.serv.enableNominationsSaveButton$.next(true);
  }
  uniqueUID() {
    return (new Date()).getTime().toString(36);
  }
  finalUpdatePayloadFormation(payload: any,event:any) {
    if (!(this.detailPayload?.map(el => el.id).includes(event?.data?.id))) {
      this.detailPayload.push(payload);
    } else {
      const payloadIndex = this.detailPayload.map(el => el.id).indexOf(event?.data?.id);
      this.detailPayload[payloadIndex] = payload;
    }
  }

  existanceOfPayloadCheck(event:any) {
    if (this.differentHeaderId !== event?.data?.id) {
      this.detailHeaderToItemNav = [];
    }
    this.differentHeaderId = JSON.parse(JSON.stringify(event?.data?.id));
    if (this.detailPayload?.map(el => el?.id)?.includes(event?.data?.id)) {
      const ind = this.detailPayload?.map(el => el?.id)?.indexOf(event?.data?.id);
      this.detailHeaderToItemNav = this.detailPayload[ind]?.headerToItemNav
    }
  }

  existanceOfPayloadCheckHeader(event:any) {
    if (this.differentHeaderId !== event?.data?.id) {
      this.detailHeaderToItemNavHeader = [];
    }
    this.differentHeaderId = JSON.parse(JSON.stringify(event?.data?.id));
    if (this.headerPayload?.map(el => el?.id)?.includes(event?.data?.id)) {
      const ind = this.headerPayload?.map(el => el?.id)?.indexOf(event?.data?.id);
      this.detailHeaderToItemNavHeader = this.headerPayload[ind]?.headerToItemNav
    }
  }

  addUpdatedRowsToPayload(rowDetail: any, key: any, event: any, headerToItemPayload: any,isNonCargo?:boolean) {
    if ((rowDetail[key] !== event?.data[key] && key !== "itemId" && key !== "udmItemStatus" && key !== "udmDailyBreakdownQty" && !isNonCargo) || (key !== "itemId" && key !== "udmItemStatus" && key !== "udmDailyBreakdownQty" && isNonCargo)) {
      if (key === "scheduledMaterialName") {
        headerToItemPayload["scheduledMaterialDesc"] = event?.data[key];
        this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
      } else if (key === "demandMaterialName") {
        headerToItemPayload["demandMaterialDesc"] = event?.data[key];
        this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
      } else if (key === "scheduledDate") {
        headerToItemPayload["scheduledDate"] = `${event?.data[key].substring(6, 10)}${event?.data[key].substring(0, 2)}${event?.data[key].substring(3, 5)}`;
      } else if (key === "locationName") {
        headerToItemPayload["locationName"] = event?.data[key];
        this.updatePayloadForCargoDesks(event,key,headerToItemPayload,isNonCargo);
      } else this.splitAddUpdatedRowsToPayload(key, event, headerToItemPayload);
      }
  }

  splitAddUpdatedRowsToPayload( key: any, event: any, headerToItemPayload: any){
    if (key?.toLowerCase()?.includes('udmcargopascagouladata_')) {
      const updateNewcellPayload = key.split('__');
      this.updateUdmCargoPascagoulaDataChildLevel[updateNewcellPayload[1]] = event?.data[key];
      headerToItemPayload['udmCargoPascagoulaData'] = this.updateUdmCargoPascagoulaDataChildLevel;
    } else if (key === "nominationReferenceDocument" && event?.data[key] === "") {
      headerToItemPayload["nominationReferenceDocument"] = " ";
      headerToItemPayload["nominationReferenceDocumentItem"] = " ";
    } else if (event?.data[key] === "" && key !== "confirmationIndicator") {
      headerToItemPayload[key] = " ";
    } else if(key === "nominationReferenceDocumentItem"){
          headerToItemPayload[key] =this.utilities.removeLeadingZeros(headerToItemPayload[key] = event?.data[key])
    } else if (key === "udmComments") {
      headerToItemPayload[key] = event?.data[key];
      headerToItemPayload["isUdmCommentsUpdated"] = true
    }
     else if (key === "udmComments") {
      headerToItemPayload[key] = event?.data[key];
      headerToItemPayload["isUdmCommentsUpdated"] = true
     } {
          headerToItemPayload[key] = event?.data[key];
        }
  }

  updatePayloadForCargoDesks(event:any,key:any,headerToItemPayload:any,isNonCargo:any){
    if(!isNonCargo){
      if (key === "scheduledMaterialName") {
        const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
        headerToItemPayload["scheduledMaterial"] = scheduledMaterial.find((el: any) => el.name === event?.data[key])?.id;

      } else if (key === "demandMaterialName") {
        const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
        headerToItemPayload["demandMaterial"] = demandMaterial.find((el: any) => el.name === event?.data[key])?.id;

      } else if (key === "locationName") {
        const locations = this.locationCodeNonCp?.find((el: any) => el?.name === event?.data[key]);
        headerToItemPayload["locationId"] = locations?.value;
      }
    }

  }

  thirdLevelGridFormation(res: any) {
    res.getDetailRowData = function (params: any) {
      params.successCallback(params.data.headerToItemNav[0].udmDailyBreakdownQty);
    }
    res.template = '<div ref="eDetailGrid" style="width: 600px; margin-left: 450px"></div>';
    res.detailGridOptions = {
      stopEditingWhenCellsLoseFocus : true,
      getMainMenuItems: (params: GetMainMenuItemsParams) => {
        if (params.column.getId() === "bbl190Truck" || params.column.getId() === "bbl250Truck") {
          return [
            {
              name: "190 bbl truck",
              action: () => {
                this.bblTrucks = false;
                this.gridApi.redrawRows();
              },
            },
            {
              name: "250 bbl truck",
              action: () => {
                this.bblTrucks = true;
                this.gridApi.redrawRows();
                this.bblTrucks = false;
              }
            }
          ];
        } else {
          return params.defaultItems
        }
      },
      columnDefs: this.bblTrucks ? [
        {
          field: 'scheduledDate',
          headerName: 'date',
          width: 200
        },
        {
          field: 'scheduledQty',
          headerName: 'scheduled mov. volumes',
          width: 200
        },
        {
          headerName: '250 bbl truck',
          field: 'bbl250Trucks',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [1, 2, 3],
          },
          width: 200,
        }
      ] : [
        {
          field: 'scheduledDate',
          headerName: 'date',
          width: 200
        },
        {
          field: 'scheduledQty',
          headerName: 'scheduled mov. volumes',
          width: 200
        },
        {
          headerName: '190 bbl truck',
          field: 'bbl190Truck',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [1, 2, 3],
          },
          width: 200,
        }
      ],
      enableRangeSelection:true,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellClass: 'subtitle-6 font-gotham-narrow-book',
        headerClass: 'font-gotham-bold',
        autoHeight: true,
      },
    };
  }

  formationOfNominationUpdateHeaderPayload(key: any, rowIndex: any, event: any, payload: any) {
    if (this.copyRowData[rowIndex][key] !== event?.data[key] && key !== "id" && key !== "modeOfTransportDesc" && key !== "sys_status" && key !== "headerToItemNav") {
      if (key === "vehicleName") {
        this.vehicleNameAdditionToUpdatePayload(event, payload, key);
      }  else if (key === 'udmLaycanEndDate') {
        payload[key] = event?.data[key];
      }
      else {
        this.positionOfUpdatedFieldInPayload(key, rowIndex, payload, event);
      }
    }
  }

  vehicleNameAdditionToUpdatePayload(event: any, payload: any, key: any) {
    const vehicleNames: any = this.cpService?.vesselNameOrVehicleName?.value;
    payload["vehicleName"] = event?.data[key];
    payload["vehicleCode"] = vehicleNames?.find((el: any) => el?.name === event?.data[key])?.id;
    if ((this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond")) && ['barge', 'vessel'].includes(payload['modeOfTransportDesc']?.toLowerCase())) {
      payload["tdVehNo"] = vehicleNames?.find((el: any) => el?.name === event?.data[key])?.id;
    }
  }

  positionOfUpdatedFieldInPayload(key: any, rowIndex: any, payload: any, event: any) {
    if (key === 'scheduledQty' || key === "scheduledDate" || key === "locationName" || key === "scheduledMaterialName" || key === "demandMaterialName" || key === "partnerNominationNumber" || key === "contractPartner" || key === "nominationReferenceDocument" || key === "nominationReferenceDocumentItem") {
      this.formateNomUpdateHeaderPayload(key, rowIndex, event, payload);
    }
  }


  formateNomUpdateHeaderPayload(key: any, rowIndex: any, event: any, payload: any){
    if (key === 'scheduledQty' && payload.headerToItemNav) {
      payload.headerToItemNav[0][key] = event?.data[key];
    }
    else if (key === 'scheduledDate' && payload.headerToItemNav) {
      payload.headerToItemNav[0][key] = `${event?.data[key].substring(6,10)}${event?.data[key].substring(0,2)}${event?.data[key].substring(3,5)}`;
    } else if (key === 'locationName' && payload.headerToItemNav) {
      const locations = this.cpService?.locationNameAndId?.value;
      payload.headerToItemNav[0][key] = event?.data[key];
      payload.headerToItemNav[0]["locationId"] = locations?.find((el: any) => el?.name === event?.data[key])?.id
    } else if (key === 'nominationReferenceDocument' && payload.headerToItemNav && event?.data[key] === "") {
      payload.headerToItemNav[0][key] = " ";
      payload.headerToItemNav[0]["nominationReferenceDocumentItem"] = " ";
    } else if (key === 'nominationReferenceDocumentItem' && payload.headerToItemNav && event?.data[key] === "") {
      payload.headerToItemNav[0][key] = " ";
    } else if (key === 'nominationReferenceDocument' && payload.headerToItemNav && event?.data[key] !== "") {
      payload.headerToItemNav[0]["nominationReferenceDocument"] = event?.data[key];
    } else if (key === 'nominationReferenceDocumentItem' && payload.headerToItemNav && event?.data[key] !== "") {
      payload.headerToItemNav[0]["nominationReferenceDocumentItem"] = event?.data[key];
    } else {
      this.formateNomUpdateHeaderToItemNavPayloadForScheduledMaterialOrDemandMaterial(key, payload, event, rowIndex);
    }
  }

  formateNomUpdateHeaderToItemNavPayloadForScheduledMaterialOrDemandMaterial(key: any, payload: any, event: any, rowIndex: any) {
    if (key === 'scheduledMaterialName' && payload.headerToItemNav) {
      const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload.headerToItemNav[0]["scheduledMaterialDesc"] = event?.data[key];
      payload.headerToItemNav[0]["scheduledMaterial"] = scheduledMaterial.find((el: any) => el.name === event?.data[key])?.id;
    } else if (key === 'demandMaterialName' && payload.headerToItemNav) {
      const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload.headerToItemNav[0]["demandMaterialDesc"] = event?.data[key];
      payload.headerToItemNav[0]["demandMaterial"] = demandMaterial.find((el: any) => el.name === event?.data[key])?.id;
    } else if (key === 'partnerNominationNumber' && payload.headerToItemNav) {
      payload.headerToItemNav[0]["partnerNominationNumber"] = event?.data[key] === "" ? " " : event?.data[key];
    } else if (key === 'contractPartner' && payload.headerToItemNav) {
      payload.headerToItemNav[0]["contractPartner"] = event?.data[key] === "" ? " " : event?.data[key];
    }
    else {
      this.firstEditOfHeaderToItemNav(key, event, payload, rowIndex);
    }
  }

  firstEditOfHeaderToItemNav(key: any, event: any, payload: any, rowIndex: any) {
    if (key === 'scheduledQty' && !payload.headerToItemNav) {
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        scheduledQty: event?.data[key]
      }]
    }
    else if (key === 'scheduledDate' && !payload.headerToItemNav) {
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        scheduledDate: `${event?.data[key].substring(6, 10)}${event?.data[key].substring(0, 2)}${event?.data[key].substring(3, 5)}`
      }]
    }else if (key === 'locationName' && !payload.headerToItemNav) {
      const locations = this.cpService?.locationNameAndId?.value;
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        locationName: event?.data[key],
        locationId: locations?.find((el: any) => el?.name === event?.data[key])?.id
      }]
    } else if (key === 'partnerNominationNumber' && !payload.headerToItemNav) {
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        partnerNominationNumber: event?.data[key] === "" ? " " : event?.data[key]
      }]
    }
    else {
      this.firstEditDisplay(key, payload, rowIndex, event)
    }
  }
 firstEditDisplay(key: any, payload: any, rowIndex: any, event: any){
   if (key === 'contractPartner' && !payload.headerToItemNav) {
    payload['headerToItemNav'] = [{
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
      contractPartner: event?.data[key] === "" ? " " : event?.data[key]
    }]
  } else if (key === 'nominationReferenceDocument' && !payload.headerToItemNav && event?.data[key] === "") {
    payload['headerToItemNav'] = [{
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
      nominationReferenceDocument: " ",
      nominationReferenceDocumentItem: " "
    }]
  } else if (key === 'nominationReferenceDocumentItem' && !payload.headerToItemNav && event?.data[key] === "") {
    payload['headerToItemNav'] = [{
      itemId: event?.data?.itemId,
      udmItemStatus: event?.data?.udmItemStatus,
      textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
      nominationReferenceDocumentItem: " "
    }]
  } else {
    this.firstEditOfHeaderToItemNavScheduledMaterialNameOrDemandMaterialName(key, payload, rowIndex, event)
  }
 }
  firstEditOfHeaderToItemNavScheduledMaterialNameOrDemandMaterialName(key: any, payload: any, rowIndex: any, event: any) {
    if (key === 'scheduledMaterialName' && !payload.headerToItemNav) {
      const scheduledMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        scheduledMaterialDesc: event?.data[key],
        scheduledMaterial: scheduledMaterial.find((el: any) => el.name === event?.data[key])?.id
      }]
    } else if (key === 'demandMaterialName' && !payload.headerToItemNav) {
      const demandMaterial = this.cpService?.scheduledMaterialAndDemandMaterialName?.value;
      payload['headerToItemNav'] = [{
        itemId: event?.data?.itemId,
        udmItemStatus: event?.data?.udmItemStatus,
        textLine: `{Author:${this.copyRowData[rowIndex]['createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,
        demandMaterialDesc: event?.data[key],
        demandMaterial: demandMaterial.find((el: any) => el.name === event?.data[key])?.id
      }]
    }
  }

  /* For clicking on SAP contract without product filled in */
  onSAPContractSelect(event: CellClickedEvent) {
    if (event.column.getColId() === 'nominationReferenceDocument' && (event.data.demandMaterial === null || event.data.scheduledMaterial === null)) {
      this.SAPContractPopup = true;
    }
  }

  accessForNecessaryDesk() {
    if (this.selectedDeskOption?.includes("florida") || this.selectedDeskOption?.includes("pnw") || this.selectedDeskOption?.includes("pascagoula") || this.selectedDeskOption?.includes("richmond")) {
      return true;
    } else {
      return false;
    }
  }


  onCellValueChangedNonCP(event:CellValueChangedEvent){

    if(event?.colDef?.field === 'scheduledMaterialName' || event?.colDef?.field === 'demandMaterialName' || event?.colDef?.field === 'scheduledMaterial' || event?.colDef?.field === 'demandMaterial' || event?.colDef?.field === 'locationName' ){
      let response = this.utilities.getFilterDataList(true, [event?.data?.transportSystem], [event?.data?.locationId])
      this.materialFilteredCode = response?.value?.product?.map((data: any) => { return { name: data?.productName, id: data?.productCode } });
    }

    if(event?.colDef?.field === 'scheduledMaterialName' || event?.colDef?.field === 'demandMaterialName'){
      event.data[event.colDef.field] = event?.newValue;
      const id = event?.colDef?.field === 'scheduledMaterialName'? 'scheduledMaterial' : event?.colDef?.field === 'demandMaterialName'? 'demandMaterial' : event?.colDef?.field;
      event.data[id] = this.materialFilteredCode.filter(code=>code.name === event?.newValue)[0]?.id;
    }
    if(event?.colDef?.field === 'scheduledMaterial' || event?.colDef?.field === 'demandMaterial'){
      event.data[event.colDef.field] = event?.newValue;
      const id = event?.colDef?.field === 'scheduledMaterial' ? 'scheduledMaterialName': event?.colDef?.field === 'demandMaterial'? 'demandMaterialName' : event?.colDef?.field;
      event.data[id] = this.materialFilteredCode.filter(code=>code.id === event?.newValue)[0]?.name;
    }
    if(event?.colDef?.field === 'locationName'){
      event.data[event.colDef.field] = event?.newValue;
      const id = event.colDef.field.replace("Name", "Id");
      event.data[id] = this.locationCodeNonCp.filter(code=>code.name === event?.newValue)[0]?.value;
    }
    this.onCellValueChanged(event,!this.isCargo);
    this.gridApi.refreshCells();
  }

  statusDeclaration(event: any, column: any, additionalParamStatus?: any) {
    if (event?.toLowerCase() === Nom.PUBLISHED && column === "udmComments") {
      return Nom.PUBLISHED;
    } else if ((event?.toLowerCase() === Nom.PUBLISHED || event?.toLowerCase() === "failed") && column !== "udmComments") {
      return Nom.UNPUBLISHED;
    } else if (additionalParamStatus) {
      return additionalParamStatus;
    } else {
      return event;
    }
  }

  onCellValueChanged(event: CellValueChangedEvent, isNonCp?: boolean, checkBoxEvent?: any) {
    let colDefAttr: string = event?.colDef?.field ? event?.colDef?.field : '';
    // Checking if non CP desk and forming payload with headerToItemNav array manually from event.data obj
    if (isNonCp) {
      this.handleNonCPDeskCellValueChanged(event);
    } else if (!['vehicleName', 'udmLaycanEndDate'].includes(colDefAttr)) {
      this.handleDetailCellValueChanged(event, !this.isCargo);
    } else {
      this.existanceOfPayloadCheckHeader(event);
      this.mainGridIndex = this.copyRowData.map(el => el.id).indexOf(event?.data?.id);
      if (this.headerPayload?.find((val: any) => val.id !== event?.data?.id)) {
        this.detailHeaderToItemNavHeader = [];
      }
      const rowIndex = this.mainGridIndex;


      const headerToItemPayload: any = {
        itemId: event?.data?.itemId,
        udmItemStatus: this.statusDeclaration(event?.data?.headerToItemNav[0]?.udmItemStatus, event?.column?.getColId()),
        textLine: `{Author:${this.copyRowData[rowIndex]['sys_createdBy'] || null},MdfBy:${this.userEmail},SS:UDM}`,

      }

      if (!(this.detailHeaderToItemNavHeader?.map((el: any) => el.itemId).includes(event?.data?.itemId))) {
        this.detailHeaderToItemNavHeader.push(headerToItemPayload);
      } else {
        const detailPayloadIndex = this.detailHeaderToItemNavHeader?.map((el: any) => el.itemId)?.indexOf(event?.data?.itemId);
        this.detailHeaderToItemNavHeader[detailPayloadIndex] = headerToItemPayload;
      }
      this.setHeaderPayload(event);
      this.gridApi?.redrawRows();
      this.serv.enableNominationsSaveButton$.next(true);
    }
  }

  setHeaderPayload(event: any){
    this.mainGridIndex = this.copyRowData.map(el => el.id).indexOf(event?.data?.id);
    const rowIndex = this.mainGridIndex;
    let keys:any;
    if(rowIndex >=0){
      keys = Object.keys(this.copyRowData[rowIndex]);
    }
    const updatedComments = this.detailHeaderToItemNavHeader?.filter((el: any) => !!el?.udmComments);
    const payload:any = {
      id: event?.data?.id,
      sys_status: this.statusDeclaration(event?.data?.nominationStatus, event?.column?.getColId()),
      deskFilter: "refinerydesk",
      modeOfTransportDesc: event?.data?.modeOfTransportDesc,
      udmPublishEvent: this.accessForNecessaryDesk(),
      headerToItemNav: this.detailHeaderToItemNavHeader,
      isUdmCommentsUpdated: updatedComments?.length > 0 ? true : undefined,
    }

    this.updateUdmCargoPascagoulaData = {};
    for (const key of keys) {
      this.formationOfNominationUpdateHeaderPayload(key, rowIndex, event, payload)
    }
    if (!(this.headerPayload.map(el => el.id).includes(event?.data?.id))) {
      this.headerPayload.push(payload);
    } else {
      const payloadIndex = this.headerPayload.map(el => el.id).indexOf(event?.data?.id);
      this.headerPayload[payloadIndex] = payload;
    }
  }

  handleNonCPDeskCellValueChanged(event:any){
    for (const [key, value] of Object.entries(event?.data)) {
      event.data["headerToItemNav"]={...event?.data["headerToItemNav"],[key]:value}
    }
     this.handleDetailCellValueChanged(event,true);

  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.selectedRows = event.api.getSelectedRows();
    let truckNoms = this.selectedRows.filter(eachRow => {
      return eachRow?.modeOfTransportDesc?.toLowerCase() === 'truck';
    })

    this.serv.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
    this.serv.nomSelected$.next(false);
    this.serv.enableNominationsSaveButton$.next(true);
    this.serv.selectedNom$.next(this.selectedRows);
    this.masterGridSelectedRows = this.selectedRows;

    if (truckNoms.length) {
      this.serv.enableHeaderButton$.next({ deleteButton: true, publishButton: false, vendorNotificationButton: true });
    }
    if (this.selectedRows.length === 1) {
      this.serv.nomSelected$.next(true);
    }

    const unPublishedNomArray: string[] = [];
    this.selectedRows?.forEach((data: any) => {
      if ((data?.headerToItemNav?.length > 0 && data.headerToItemNav?.every((status: any) => status?.udmItemStatus?.toLowerCase() === 'published') || (data?.headerToItemNav?.length === 0 && data?.nominationStatus?.toLowerCase() === 'published'))) {
        unPublishedNomArray.push("published")
      }
      else {
        unPublishedNomArray.push("unpublished")
      }
    })
    if (this.selectedRows.length > 0) {
      this.nominationService.enableHeaderButton$.next({ deleteButton: true, publishButton: true, vendorNotificationButton: unPublishedNomArray?.every((status: any) => status === 'published') });
    } else {
      this.nominationService.enableHeaderButton$.next({ deleteButton: false, publishButton: false, vendorNotificationButton: false });
    }

  }

  formattedDate(value: any) {
    const date = new Date(value);
    const fmdt = date.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return fmdt;
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }
  createNominationModal() {
    const modal = { open: true, modal: 'createNominationModal' }
    this.createNominationPipelineModal = JSON.parse(JSON.stringify(modal));
  }

  getCheckedNames(element: any) {
    const list = element.list.filter((ele: any) => ele.id && ele.checked).map((item: any) => item.name);
    return list;
  }

  closePipelineNominationModal(val: any) {
    if (val && val.success === true) {
      this.gridApi.deselectAll();
      let postData!: any;
      /**selectedDesk$ and if else desk conditions to be removed and maintain only postData code here after routing changes implmented */
      if (this.selectedDeskOption.includes("refinery")) {
        postData = {
          desk: "refinery",
          fromDate: this.getRefineryFilterResponse?.pipelineFromDate,
          toDate: this.getRefineryFilterResponse?.pipelineToDate,
          terminal: this.terminalFilterData,
          product: this.productFilterData,
          transportsystem: this.transportSystemFilterData,
          region: this.region,
          deskFilter: `${this.selectedDeskParameter}`
        }
        this.getNominationOnClose(postData);
      }
      if (postData) {
        this.getNominationApiCallOnCloseModal(postData);
      }
    }
    const modal = { open: false, modal: 'createPipelineNomination' }
    this.pipelineNominationModal = { ...modal };
  }

  getNominationApiCallOnCloseModal(postData: any) {
    if(this.nominationService?.isTriggeredFromNomModalPopup$.value){
      setTimeout(() => {
        this.getNominationsAf(postData);
      }, 5000);
    }else{
      this.getNominationsAf(postData);
    }
  }

  getNominationOnClose(postData: any) {
    this.getRefineryFilterResponse.pipelineNomFilterValues.forEach((element: any) => {
      if(element.headerName === 'mode of transport'){
        postData['modeOfTransport'] = this.getCheckedNames(element);
      }
    });
    this.serv.getPipelineNominationListPayload = postData;
  }

  closePipelineVNModal(){
    this.gridApi?.deselectAll();
    const modal = { open: false, modal: 'createPreviewForNomination' }
    this.vendorNotificationPreview = JSON.parse(JSON.stringify(modal));
  }
  closePipelinevesselScheduleUpdateModal(val:any){
    this.gridApi?.deselectAll();
    const modal = { open: false, modal: 'pipelineVesselScheduleUpdate' };
    this.vesselScheduleUpdateModal = { ...modal };
  }

  toggleCreatePipelineNominationsModal(): void {
    this.isPipelineNomEditRestrict = true;
    const modal = { open: !this.pipelineNominationModal.open, modal: 'createRefineryNomination' }
    this.pipelineNominationModal = { ...modal };
    this.createRefineryNominationComponent.initialValues()

  }
  async createNominationWithReference(data: any) {
    if (data?.type==='group') {
      data.values= !data.values? this.masterGridSelectedRows[0]?.nominationNo : data.values;
      const pipelineNomination = await this.utilities.getPipelineNomPayload(data, this.userEmail, this.selectedDeskOption, true);
      pipelineNomination.nominationNo = !data.values? this.masterGridSelectedRows[0]?.nominationNo : data.values;

      pipelineNomination["screenName"] = 'side-panel-tr';
      pipelineNomination["editNomination"] = data.editNomination;
      pipelineNomination["pipelineNomEditFlag"] = this.isPipelineNomEdit;
      const selectedTabFunction = {
        tab: this.router.url,
        function: 'onCreatePipelineNominationFromTR',
        type: '',
        values: pipelineNomination
      };

      const modalPopupAction = this.utilities.sendDataToChild(selectedTabFunction);
      this.utilities.addUnsubscribableList(modalPopupAction);
      this.nominationService.sidePanel$.next(false);

    }
    else if(data){
        this.createRefineryNominationComponent.createNominationWithReference(data);
      } else {
        console.log(this.selectedRows[0]);
        const params ={data:this.selectedRows[0]};
        if(params.data){
          console.log(params.data);
          console.log("from pipeline view component - 2");
          const pipelineNomination: any = await this.utilities.getPipelineNomPayload(params,this.userEmail,this.selectedDeskOption,true);
          pipelineNomination["pipelineNomEditFlag"] = this.isPipelineNomEdit;
          this.createRefineryNominationComponent.createNominationWithReference(pipelineNomination);
        }
      }

      const modal = { open: true, modal: 'createPipelineNomination' };
      this.pipelineNominationModal = JSON.parse(JSON.stringify(modal));
  }

  updateVesselScheduleDate(response:any) {
    this.pipelineVesselScheduleUpdate.updateVesselScheduleDate(response)
    const modal = { open: true, modal: 'pipelineVesselScheduleUpdate' };
    this.vesselScheduleUpdateModal = JSON.parse(JSON.stringify(modal));
  }

  saveColumnOrder(payload: any) {
    const allState = this.gridColumnApi?.getColumnState();
    const columnOrderId = allState.map(state => {
      return { colId: state.colId, hide: state.hide }
    })
    payload.payload["nominationColumnOrderState"] = payload.doColumnOrder ? columnOrderId : [];
    this.ethanolService.customViewPayload$.next(payload.payload);
  }

  editCustomViewFilters(payload: any) {
    if (payload.columnOrderChecked) {
      const allState = this.gridColumnApi?.getColumnState();
      const columnOrderId = allState?.map(state => {
        return { colId: state.colId, hide: state.hide }
      })
      payload.filterValues.pipelineFilters.columnOrders["nominationColumnOrder"] = columnOrderId;
    } else {
      payload.filterValues.pipelineFilters.columnOrders["nominationColumnOrder"] = [];
    }
  }

  callColOrderAndExpansion(res: any) {
    let val: any = Object.values(res);
    if (val.length && localStorage.getItem("selectedView")) {
      this.applyColOrderAndExpansion(res);
    }
  }

  applyColOrderAndExpansion(res: any) {
    if (res.colOrder?.nominationColumnOrder?.length > 0) {
      this.loaderService.setLoading(true);
      setTimeout(() => {
        this.gridColumnApi?.applyColumnState({
          state: res.colOrder?.nominationColumnOrder,
          applyOrder: true,
        })
        this.allowForGetCustomView = false;
        this.loaderService.setLoading(false);
      }, 5000)
    }
    else {
      this.gridColumnApi?.resetColumnState();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
