import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'light-blue-header',
  templateUrl: './light-blue-header.component.html',
  styleUrls: ['./light-blue-header.component.scss']
})
export class LightBlueHeaderComponent implements OnInit {


  @Input() displayText: string = ''
  @Input() arrowVisible: boolean = false;
  @Input() flipArrow: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  changeArrowDirection(){
    this.flipArrow = !this.flipArrow;
  }

}
