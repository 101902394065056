<div class="create-pipeline-nomination">
  <form [formGroup]="createPipelineNominationForm" (keydown.enter)="$event.preventDefault()">
    <ng-container>
      @if (selectedDeskParameter === 'floridadesk' || selectedDeskParameter === 'pnwdesk' || selectedDeskParameter === 'pascagouladesk' || selectedDeskParameter === 'richmonddesk') {
        <div class="mot-container"
          >
          <span class="small-text label mot-text"><span class="text-danger">* </span> vehicle identifier</span>
          <div class="form-row">
            @for (scenario of motData; track scenario; let i = $index) {
              <div
                class="radio-container mb-0 d-flex align-items-center form-group branded">
                <input type="radio" class="mot-btns" (change)="updateFormFields(scenario?.value)"
                  name="scenario" [value]="scenario?.name" [checked]="scenario?.checked === true"
                  id="radio-{{i}}">
                  <label for="radio-{{i}}" class="label" (keydown.enter)="updateFormFields(scenario?.value)"
                  tabindex="0">{{scenario.value}}</label>
                </div>
              }
            </div>
          </div>
        }
      </ng-container>
      <div class="input-wrap">
        <div class="form-row pb-1 pr-2 d-flex">
          <div class="form-group col-md-9">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="nominationNoPipeline" class="label"><span class="text-danger">*
                </span>nomination
              name/id</label>
              <input type="text" formControlName="nominationNo" class="form-control"
                id="nominationNoPipeline">
                @if (createPipelineNominationForm.controls['nominationNo'].invalid && (createPipelineNominationForm.controls['nominationNo'].dirty || createPipelineNominationForm.controls['nominationNo'].touched)) {
                  <div class="text-danger"
                    >
                    nomination
                  no. limits to 16 characters</div>
                }
              </div>

              <div class="form-group col-md-4">
                <div class="d-flex justify-content-between">
                  <label htmlFor="transportSystem" class="label"><span class="text-danger">* </span>
                  transport
                system</label>
                @if ((selectedDeskParameter !== 'floridadesk' && selectedDeskParameter !== 'pnwdesk' && selectedDeskParameter !== 'pascagouladesk' && selectedDeskParameter !== 'richmonddesk')) {
                  <label class="text-black"
                    ><input
                    type="checkbox" #isMultiple formControlName="selectMultipleCheckbox"
                    (change)="onTransportChange(isMultiple)" [disabled]="showMultipleTables">
                  multiple</label>
                }
              </div>

              <app-select-dropdown [items]="transportSystemArray" formControlName="transportSystem"
                [defaultValue]="defaultTransportSystem" #transportSystemDropdown
                (afterChange)="onTransportSystemChange()" [disabled]="isMultipleTransport">
              </app-select-dropdown>
            </div>
            @if (((!inCpTab) && (!nomCargoFieldObj.vesselFields && !nomCargoFieldObj.bargeFields))) {
              <div class="form-group col-md-4">
                <label htmlFor="cycleId" class="label">cycle Id</label>
                <div class="d-flex align-items-center checkbox-container">
                  <input type="text" formControlName="cycleId" class="form-control">
                </div>
              </div>
            }
            @if ((nomCargoFieldObj.bargeFields && (inCpTab || inCpNomTab)) ) {
              <div class="form-group col-md-4">
                <div class="d-flex justify-content-between">
                  <label for="tug" class="label">tug</label>
                </div>
                <input type="text" class="form-control text-field" formControlName="tug">
              </div>
            }

            @if (((inCpTab || inCpNomTab) && (nomCargoFieldObj.vesselFields || nomCargoFieldObj.bargeFields)
              )) {
              <div class="form-group col-md-4">
                <div class="d-flex justify-content-between">
                  @if (nomCargoFieldObj.vesselFields) {
                    <label for="vessel" class="label"> <span class="text-danger">* </span> vessel
                  name</label>
                }
                @if (nomCargoFieldObj.bargeFields) {
                  <label for="barge" class="label"> <span class="text-danger">* </span> barge name</label>
                }
              </div>
              <app-select-dropdown [items]="vesselArray" id="vessel" formControlName="vesselName"
                [defaultValue]="vesselNameValue">
              </app-select-dropdown>
            </div>
          }
          @if (((inCpTab || inCpNomTab) && (nomCargoFieldObj.railFields) )) {
            <div class="form-group col-md-4">
              <div class="d-flex justify-content-between">
                <label for="rail" class="label"><span class="text-danger">* </span> rail name</label>
              </div>
              <app-select-dropdown [items]="vesselArray" id="vessel" formControlName="railName"
                [defaultValue]="vesselNameValue">
              </app-select-dropdown>
            </div>
          }
          <div class="form-group col-md-4">
            <label for="btCustodyChain" class="label">
              bt custody chain
            </label>
            <input type="text" formControlName="btCustodyChain" class="form-control"
              id="btCustodyChain">
            </div>
            @if (((trNominationForPipeline?.editNomination) && (trNominationForPipeline.nominationKey!=null)
              && (isSearchPopup==false) && (isCargoDesks==true) )) {
              <div class="form-group col-md-4">
                <label for="nominationKey" class="label">
                  nom key
                </label>
                <input type="text" formControlName="nominationKey" class="form-control" readonly="true"
                  id="nominationKey">
                </div>
              }
            </div>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="nominationNotes" class="label">nomination notes</label>
            <textarea class="form-control nomNotes" formControlName="nominationNotes" maxlength="120"
            rows="3"></textarea>
          </div>
        </div>
        @if (!inCpTab) {
          <div class="form-row pb-1 pr-2 d-flex align-items-center">
            <div class="form-group col-md-3">
              <input type="checkbox" #isAdditional [(ngModel)]='addCheckbox' (keydown.enter)="toggleAddi()"
                (change)="onAddititonalData(isAdditional)" formControlName="selectMultipleCheckbox1"
                id="selectMultipleCheckbox1">
                <label class="ml-2 " for="selectMultipleCheckbox1">additional information</label>
              </div>

            </div>
          }
        </div>
        <ng-container formArrayName="transport">
          @for (transportControl of transportArray().controls; track transportControl; let i = $index) {
            <ng-container [formGroupName]="i">
              <div class="table-wrapper">
                @if (isMultipleTransport && hideDestination) {
                  <div class="form-group">
                    <div class="row">
                      <div class="col-3">
                        <div class="d-flex mb-2">
                          <label for="transportMode" class="label mr-2 mb-0">transport system {{i +
                            1}} <span class="text-danger">* </span></label>
                            @if (isMultipleRowTransport >= 1) {
                              <button class="button button-text icon-remove"
                                style="margin-left: 10px !important;" (click)="deleteTransportSystem(i)"
                                title="delete selected transport">
                                <span class="sr-only">delete transport system</span>
                              </button>
                            }
                          </div>
                          <app-select-dropdown [items]="transportSystemModeArray"
                            [defaultValue]="defaultTransportModalSystem[i]"
                            formControlName="transportSystemMode" (afterChange)="fetchLocation(i)">
                          </app-select-dropdown>
                        </div>
                      </div>
                    </div>
                  }
                  @if (hideDestination) {
                    <div>
                      @if (isMultipleTransport || i===0) {
                        <div>
                          <span class="table-type">origin</span>
                          <table aria-describedby="origin">
                            <tr>
                              @if (nominationService.isNominationScreen !== 'cargo') {
                                <th>Confirm</th>
                              }
                              <th>@if (isOriginRequired[i]) {
                                <span class="text-danger">* </span>
                              } description</th>
                              <th>@if (isOriginRequired[i]) {
                                <span class="text-danger">* </span>
                              } rdi</th>
                              <th><span class="text-danger"></span> TSW Status</th>
                              @if (inCpTab || inCpNomTab) {
                                <th> counter party</th>
                              }
                              <th>@if (isOriginRequired[i]) {
                                <span class="text-danger">* </span>
                                } scheduled date
                              </th>
                              <th>@if (isOriginRequired[i]) {
                                <span class="text-danger"></span>
                              } Arrival Time</th>
                              @if (inCpTab) {
                                <th>@if (isOriginRequired[i]) {
                                  <span class="text-danger"> </span>
                                }
                              end load date</th>
                            }
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                            } location</th>
                            @if (showOriginTankList[i]) {
                              <th>@if (isOriginRequired[i] && tankList[i].length>0) {
                                <span class="text-danger"
                                >* </span>
                              } tank</th>
                            }
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                              } scheduled
                              product material code
                            </th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                              } scheduled
                              product
                            </th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                              } demand product
                              material code
                            </th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                              } demand product
                            </th>
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger">* </span>
                            } qty(mb)</th>
                            <th>sap contract</th>
                            <th>contract line no.</th>
                            @if (isAdditionalData) {
                              <th>batch origin location</th>
                            }
                            @if (isAdditionalData) {
                              <th>valuation type origin location</th>
                            }
                            @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                              <th>batch destination
                              location</th>
                            }
                            @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                              <th>valuation type
                              destination location</th>
                            }
                          </tr>
                          <ng-container formArrayName="origin">
                            @for (list of origin(i).controls; track list; let j = $index) {
                              <ng-container
                                [formGroupName]="j">
                                <tr>
                                  @if (nominationService.isNominationScreen !== 'cargo') {
                                    <td><input
                                      type="checkbox" class="form-control text-field cell-form-checkbox"
                                    formControlName="confirmationIndicator"></td>
                                  }
                                  <td>
                                    <div class="statwidth">
                                      <app-select-dropdown [items]="originRDIDescArray"
                                        formControlName="description"
                                        [defaultValue]="originRDIDescSelected[i][j]"
                                        (afterChange)="onDescriptionChange(i, j)">
                                      </app-select-dropdown>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="rdiWidth">
                                      <app-select-dropdown [items]="originRDIArray" formControlName="rdi"
                                        [defaultValue]="originRDISelected[i][j]"
                                        (afterChange)="onRdiChange(i, j)">
                                      </app-select-dropdown>
                                    </div>
                                  </td>
                                  <td><input type="number" class="form-control text-field"
                                    formControlName="tswStatus" readonly>
                                  </td>
                                  @if (inCpTab || inCpNomTab) {
                                    <td><input type="text"
                                    class="form-control text-field" formControlName="counterParty"></td>
                                  }
                                  <td><input type="date" class="form-control text-field date-field"
                                    formControlName="scheduledDate"
                                    (input)="onOriginScheduledDateInput($event, origin(i).controls, destination(i).controls, i, j)">
                                  </td>
                                  <td>{{scheduledTime[i][j]}}</td>
                                  @if (inCpTab) {
                                    <td><input class="form-control text-field date-field"
                                    formControlName="endLoadDate" type="date"></td>
                                  }
                                  <td>
                                    @if (terminalList[i]?.length>0) {
                                      <span>
                                        <div class="statwidth"> <app-select-dropdown
                                          [items]="terminalList[i]" formControlName="location"
                                          [defaultValue]="locationOriginArray[i][j]"
                                          (afterChange)="onLocChangeOrigin(i, j)">
                                        </app-select-dropdown>
                                      </div>
                                    </span>
                                  }
                                </td>
                                @if (showOriginTankList[i]) {
                                  <td>
                                    @if (terminalList[i]?.length>0 && tankList[i][j]?.length>0) {
                                      <span>
                                        <div class="statwidth">
                                          <app-select-dropdown formControlName="tank"
                                            [defaultValue]="tankOriginArray[i][j]"
                                            [items]="tankList[i][j]"
                                            (afterChange)="onTankChangeOrign(i,j)">
                                          </app-select-dropdown>
                                        </div>
                                      </span>
                                    }
                                  </td>
                                }
                                @if (terminalList[i]?.length>0) {
                                  <td>
                                    <div class="statwidth">
                                      <app-select-dropdown
                                        [items]="scheduledDemandProductListMaterialCode[i][j]"
                                        formControlName="scheduledProductMaterialCode"
                                        [defaultValue]="defaultProductMaterialCode[i][j]"
                                        (afterChange)="scheduledDemandProductList[i][j]?.length > 0 && onScheduledProductChangeMaterialCode(i, j, 'origin')">
                                      </app-select-dropdown>
                                    </div>
                                  </td>
                                }
                                @if (terminalList[i]?.length>0) {
                                  <td>
                                    <div class="statwidth">
                                      <app-select-dropdown [items]="scheduledDemandProductList[i][j]"
                                        formControlName="scheduledProduct"
                                        [defaultValue]="defaultProduct[i][j]"
                                        (afterChange)="scheduledDemandProductList[i][j]?.length > 0 && onScheduledProductChange(i, j)">
                                      </app-select-dropdown>
                                    </div>
                                  </td>
                                }
                                <td>
                                  @if (terminalList[i]?.length > 0) {
                                    <span>
                                      <div class="statwidth">
                                        <app-select-dropdown
                                          [items]="demandProductListMaterialCode[i][j]"
                                          [defaultValue]="demandProductOriginMaterialCode[i][j]"
                                          formControlName="demandProductMaterialCode"
                                          (afterChange)="onDemandProductChangeMaterialCode(i, j, 'origin')">
                                        </app-select-dropdown>
                                      </div>
                                    </span>
                                  }
                                </td>
                                <td>
                                  @if (terminalList[i]?.length > 0) {
                                    <span>
                                      <div class="statwidth">
                                        <app-select-dropdown [items]="demandProductList[i][j]"
                                          [defaultValue]="demandProductOrigin[i][j]"
                                          formControlName="demandProduct"
                                          (afterChange)="onDemandProductChange(i, j)">
                                        </app-select-dropdown>
                                      </div>
                                    </span>
                                  }
                                </td>
                                <td><input type="number" class="form-control text-field"
                                  formControlName="scheduledQty" (keyup)="checkQTY(i,j)">
                                  @if ((origin(i).length - 1) == j) {
                                    <span class="qty-row">sum
                                    {{originQtyValue[i]}}</span>
                                  }
                                </td>
                                <td><input type="text" class="contractWidth form-control text-field"
                                  formControlName="sapcontract"
                                (input)="onContractLineChange($event,j,i,'origin');"></td>
                                <td>
                                  <div class="statwidth contractLineNo">
                                    <app-select-dropdown [action]="action"
                                      [items]="contractLineItemsOrigin[i][j]"
                                      [defaultValue]="refContractLineItems[i][j]"
                                      formControlName="contractLineNo"
                                      (triggerClick)="fetchContractLineItem(i,j,'origin')">
                                    </app-select-dropdown>
                                  </div>
                                </td>
                                @if (isAdditionalData) {
                                  <td><input type="text"
                                    class="statwidth form-control text-field"
                                  formControlName="batchOriginLocation"></td>
                                }
                                @if (terminalList[i]?.length>0 && isAdditionalData) {
                                  <td>
                                    <div class="valTypeWidth">
                                      <app-select-dropdown
                                        [defaultValue]="defaultvaluationTypeOriginLoc[i][j]"
                                        [items]="valuationTypeOriginLoc[i][j]"
                                        formControlName="valuationTypeOriginLoc">
                                      </app-select-dropdown>
                                    </div>
                                  </td>
                                }
                                @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                                  <td><input
                                    type="text" class="form-control text-field"
                                  formControlName="batchDestinationLocation"></td>
                                }
                                @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                                  <td><input
                                    type="text" class="form-control text-field"
                                  formControlName="valuationTypeDestinationLoc"></td>
                                }
                                <td>
                                  <div class="custom-dropdown">
                                    <button class="icon-btn action-icon action-button"></button>
                                    <div class="dropdown-menu-wrap">
                                      <div class="dropdown-menu">
                                        <button class="dropdown-item action-btn-item"
                                          (click)="copyOriginRow(i,j)">duplicate row
                                        </button>
                                        @if (isMultipleRowItemOrigin[i]?.length>1) {
                                          <button
                                            class="dropdown-item action-btn-item text-danger"
                                            (click)="deleteOriginRow(i,j)">delete
                                          </button>
                                        }
                                        <button class="dropdown-item action-btn-item text-act-btn"
                                          (click)="clearOriginRow(i,j)">clear all fields
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </ng-container>
                          }
                          <tr class="border-left-hidden">
                            <td style="white-space: nowrap;">
                              {{originMovementsValue[i]}} Movements
                            </td>
                            @if (!isAdditionalData) {
                              <td
                              [attr.colspan]="(inCpTab || inCpNomTab) ? 14 : 13"></td>
                            }
                            @if (isAdditionalData) {
                              <td [attr.colspan]="(inCpTab || inCpNomTab) ? 18 : 17">
                              </td>
                            }
                          </tr>
                        </ng-container>
                      </table>
                      <div class="my-3 d-flex">
                        <button class="button button-text" (keydown.enter)="addOriginRow(i)"
                          (click)="addOriginRow(i)">
                          add location
                        </button>
                      </div>
                    </div>
                  }
                </div>
              }
              @if (hideDestination) {
                <div>
                  @if (isMultipleTransport || i===0) {
                    <div>
                      <span class="table-type">destination</span>
                      <table aria-describedby="destination">
                        <tr>
                          @if (nominationService.isNominationScreen !== 'cargo') {
                            <th>Confirm</th>
                          }
                          <th>@if (isDestinationRequired[i]) {
                            <span class="text-danger">* </span>
                            } description
                          </th>
                          <th>@if (isDestinationRequired[i]) {
                            <span class="text-danger">* </span>
                          } rdi</th>
                          <th><span class="text-danger"></span> TSW Status</th>
                          @if (inCpTab || inCpNomTab) {
                            <th> counter party</th>
                          }
                          <th>@if (isDestinationRequired[i]) {
                            <span class="text-danger">* </span>
                            } scheduled
                          date</th>
                          <th>@if (isDestinationRequired[i]) {
                            <span class="text-danger"></span>
                            } Arrival Time
                          </th>
                          @if (inCpTab) {
                            <th>@if (isOriginRequired[i]) {
                              <span class="text-danger"> </span>
                            }
                          end discharge date</th>
                        }
                        <th>@if (isDestinationRequired[i]) {
                          <span class="text-danger">* </span>
                          } location
                        </th>
                        @if (showDestTankList[i]) {
                          <th>@if (isDestinationRequired[i]) {
                            <span class="text-danger"
                            >* </span>
                            } tank
                          </th>
                        }
                        <th>@if (isDestinationRequired[i]) {
                          <span class="text-danger">* </span>
                          } scheduled
                        product material code</th>
                        <th>@if (isDestinationRequired[i]) {
                          <span class="text-danger">* </span>
                          } scheduled
                        product</th>
                        <th>@if (isDestinationRequired[i]) {
                          <span class="text-danger">* </span>
                          } demand
                        product material code</th>
                        <th>@if (isDestinationRequired[i]) {
                          <span class="text-danger">* </span>
                          } demand
                        product</th>
                        <th>@if (isDestinationRequired[i]) {
                          <span class="text-danger">* </span>
                          } qty(mb)
                        </th>
                        <th>sap contract</th>
                        <th>contract line no.</th>
                        @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                          <th>batch origin
                          location</th>
                        }
                        @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                          <th>valuation type
                          origin location</th>
                        }
                        @if (isAdditionalData) {
                          <th>batch destination location</th>
                        }
                        @if (isAdditionalData) {
                          <th>valuation type destination location</th>
                        }
                      </tr>
                      <ng-container formArrayName="destination">
                        @for (list of destination(i).controls; track list; let k = $index) {
                          <ng-container
                            [formGroupName]="k">
                            <tr>
                              @if (nominationService.isNominationScreen !== 'cargo') {
                                <td><input
                                  type="checkbox" class="form-control text-field cell-form-checkbox"
                                formControlName="confirmationIndicator"></td>
                              }
                              <td>
                                <div class="statwidth">
                                  <app-select-dropdown [items]="destinationRDIDescArray"
                                    formControlName="value"
                                    [defaultValue]="destinationRDIDescSelected[i][k]"
                                    (afterChange)="onDestinationDescriptionChange(i, k)">
                                  </app-select-dropdown>
                                </div>
                              </td>
                              <td>
                                <div class="rdiWidth">
                                  <app-select-dropdown [items]="destinationRDIArray"
                                    formControlName="key"
                                    [defaultValue]="destinationRDISelected[i][k]"
                                    (afterChange)="onDestinationRdiChange(i, k)">
                                  </app-select-dropdown>
                                </div>
                              </td>
                              <td><input type="number" class="form-control text-field"
                                formControlName="tswStatus" readonly>
                              </td>
                              @if (inCpTab || inCpNomTab) {
                                <td><input type="text"
                                class="form-control text-field" formControlName="counterParty"></td>
                              }
                              <td><input class="form-control text-field date-field"
                                formControlName="scheduledDate" type="date"
                                (input)="onDestinationScheduledDateInput($event, origin(i).controls, destination(i).controls, i, k)">
                              </td>
                              <td>{{scheduledTimeDesti[i][k]}}</td>
                              @if (inCpTab) {
                                <td><input class="form-control text-field date-field"
                                formControlName="endLoadDate" type="date"></td>
                              }
                              <td>
                                @if (terminalList[i]?.length>0) {
                                  <span>
                                    <div class="statwidth">
                                      <app-select-dropdown [items]="terminalList[i]"
                                        formControlName="location"
                                        [defaultValue]="locationDestiArray[i][k]"
                                        (afterChange)="onLocChangeDest(i, k)">
                                      </app-select-dropdown>
                                    </div>
                                  </span>
                                }
                              </td>
                              @if (showDestTankList[i]) {
                                <td>
                                  @if (terminalList[i]?.length>0 && tankList[i][k]?.length>0) {
                                    <span>
                                      <div class="statwidth">
                                        <app-select-dropdown formControlName="tank"
                                          [defaultValue]="tankDestiArray[i][k]"
                                          [items]="tankList[i][k]"
                                          (afterChange)="onTankChangeDest(i,k)">
                                        </app-select-dropdown>
                                      </div>
                                    </span>
                                  }
                                </td>
                              }
                              <td>
                                <div class="statwidth">
                                  <app-select-dropdown
                                    [items]="scheduledDemandProductListDescMaterialCode[i][k]"
                                    formControlName="scheduledProductMaterialCode"
                                    [defaultValue]="defaultProductdestiMaterialCode[i][k]"
                                    (afterChange)="onScheduledProductDestiChangeMaterialCode(i, k)">
                                  </app-select-dropdown>
                                </div>
                              </td>
                              @if (terminalList[i]?.length > 0) {
                                <td>
                                  <div class="statwidth">
                                    <app-select-dropdown [items]="scheduledDemandProductListDesc[i][k]"
                                      formControlName="scheduledProduct"
                                      [defaultValue]="defaultProductdesti[i][k]"
                                      (afterChange)="onScheduledProductDestiChange(i, k)">
                                    </app-select-dropdown>
                                  </div>
                                </td>
                              }
                              <td>
                                @if (demandProductListDesc?.length>0) {
                                  <span>
                                    <div class="statwidth">
                                      <app-select-dropdown
                                        [items]="demandProductListDescMaterialCode[i][k]"
                                        [defaultValue]="demandProductDestiMaterialCode[i][k]"
                                        formControlName="demandProductMaterialCode"
                                        (afterChange)="onDemandProductDestiChangeMaterialCode(i, k)">
                                      </app-select-dropdown>
                                    </div>
                                  </span>
                                }
                              </td>
                              <td>
                                @if (terminalList[i]?.length > 0 && demandProductListDesc?.length > 0) {
                                  <span
                                    >
                                    <div class="statwidth">
                                      <app-select-dropdown [items]="demandProductListDesc[i][k]"
                                        [defaultValue]="demandProductDesti[i][k]"
                                        formControlName="demandProduct"
                                        (afterChange)="onDemandProductDestiChange(i, k)">
                                      </app-select-dropdown>
                                    </div>
                                  </span>
                                }
                              </td>
                              <td><input class="form-control text-field" formControlName="scheduledQty"
                                (keyup)="checkQTY(i, k)" type="number">
                                @if ((destination(i).length - 1) == k) {
                                  <span class="qty-row">sum
                                  {{destinQtyValue[i]}}</span>
                                }
                              </td>
                              <td><input class="contractWidth form-control text-field" type="text"
                                formControlName="sapcontract"
                              (input)="onContractLineChange($event,k,i,'destination');"></td>
                              <td>
                                <div class="statwidth contractLineNo">
                                  <app-select-dropdown [action]="action"
                                    [items]="contractLineItemsDestination[i][k]"
                                    [defaultValue]="refContractLineItemsDesti[i][k]"
                                    formControlName="contractLineNo"
                                    (triggerClick)="fetchContractLineItem(i,k, 'destination')">
                                  </app-select-dropdown>
                                </div>
                              </td>
                              @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                                <td><input
                                  type="text" class="form-control text-field"
                                formControlName="batchOriginLocation"></td>
                              }
                              @if (isAdditionalData && hideBatchValuationTypeOriginDest) {
                                <td><input
                                  type="text" class="form-control text-field"
                                formControlName="valuationTypeOriginLoc"></td>
                              }
                              @if (isAdditionalData) {
                                <td><input type="text"
                                  class="statwidth form-control text-field"
                                formControlName="batchDestinationLocation"></td>
                              }
                              @if (terminalList[i]?.length>0 && isAdditionalData ) {
                                <td>
                                  <div class="valTypeWidth">
                                    <app-select-dropdown
                                      [defaultValue]="defaultvaluationTypeDestinationLoc[i][k]"
                                      [items]="valuationTypeDestinationLoc[i][k]"
                                      formControlName="valuationTypeDestinationLoc">
                                    </app-select-dropdown>
                                  </div>
                                </td>
                              }
                              <td>
                                <div class="custom-dropdown">
                                  <button class="icon-btn action-icon action-button"></button>
                                  <div class="dropdown-menu-wrap">
                                    <div class="dropdown-menu">
                                      <button class="dropdown-item action-btn-item"
                                        (click)="copyDestinationRow(i,k)">duplicate row
                                      </button>
                                      @if (isMultipleRowItemDestination[i]?.length>1) {
                                        <button
                                          class="dropdown-item action-btn-item text-danger"
                                          (click)="deletedestinationRow(i,k)">delete
                                        </button>
                                      }
                                      <button class="dropdown-item action-btn-item text-act-btn"
                                        (click)="clearDestinationRow(i,k)">clear all fields
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        }
                        <tr class="border-left-hidden">
                          <td style="white-space: nowrap;">
                            {{destinMovementsValue[i]}} Movements
                          </td>
                          @if (!isAdditionalData) {
                            <td
                            [attr.colspan]="(inCpTab || inCpNomTab) ? 14 : 13"></td>
                          }
                          @if (isAdditionalData) {
                            <td [attr.colspan]="(inCpTab || inCpNomTab) ? 18 : 17">
                            </td>
                          }
                        </tr>
                      </ng-container>
                    </table>
                    <div class="my-3">
                      <button class="button button-text" (keydown.enter)="addDestinationRow(i)"
                        (click)="addDestinationRow(i)">
                        add location
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          @if (showMultipleTables && (createPipelineNominationForm.get('transportSystem')?.value === 'ITE'|| createPipelineNominationForm.get('transportSystem')?.value === 'ITW')) {
            <div
              >
              @if (hideDestination || i===0) {
                <div>
                  <table aria-describedby="ite">
                    <thead>
                      <tr>
                        @if (nominationService.isNominationScreen !== 'cargo') {
                          <th>Confirm</th>
                        }
                        <th><span class="text-danger">* </span> description</th>
                        <th><span class="text-danger">* </span> rdi</th>
                        <th><span class="text-danger"></span> TSW Status</th>
                        <th><span class="text-danger">* </span> scheduled date</th>
                        <th>Arrival Time</th>
                        <th><span class="text-danger">* </span> location</th>
                        @if (showDestTankList[i]) {
                          <th><span class="text-danger">* </span> tank</th>
                        }
                        <th><span class="text-danger">* </span> scheduled product material code</th>
                        <th><span class="text-danger">* </span> scheduled product</th>
                        <th><span class="text-danger">* </span> demand product material code</th>
                        <th><span class="text-danger">* </span> demand product</th>
                        <th><span class="text-danger">* </span> qty(mb)</th>
                        <th>sap contract</th>
                        <th>contract line no.</th>
                        @if (isAdditionalData) {
                          <th>batch origin location</th>
                        }
                        @if (isAdditionalData) {
                          <th>valuation type origin location</th>
                        }
                        @if (isAdditionalData) {
                          <th>batch destination location</th>
                        }
                        @if (isAdditionalData) {
                          <th>valuation type destination location</th>
                        }
                      </tr>
                    </thead>
                    <ng-container formArrayName="ite">
                      @for (list of ite(i).controls; track list; let j = $index) {
                        <ng-container [formGroupName]="j">
                          <tr>
                            @if (nominationService.isNominationScreen !== 'cargo') {
                              <td><input
                                type="checkbox" class="form-control text-field cell-form-checkbox"
                              formControlName="confirmationIndicator"></td>
                            }
                            <td>
                              <div class="statwidth">
                                <app-select-dropdown [items]="iteRDIDescArray"
                                  formControlName="descPort"
                                  [defaultValue]="inTankRDIDescSelected[i][j]"
                                  (afterChange)="onDescIteTable(i, j)">
                                </app-select-dropdown>
                              </div>
                            </td>
                            <td>
                              <div class="rdiWidth">
                                <app-select-dropdown [items]="iteRDIArray" formControlName="rdiPort"
                                  [defaultValue]="inTankRDISelected[i][j]"
                                  (afterChange)="onRdiItetable(i, j)">
                                </app-select-dropdown>
                              </div>
                            </td>
                            <td><input type="number" class="form-control text-field"
                              formControlName="tswStatus" readonly>
                            </td>
                            <td><input class="form-control text-field date-field"
                              formControlName="scheduledDate" type="date"
                              (input)="onDestinationScheduledDateInput($event, [], ite(i).controls, i, j)">
                            </td>
                            <td>{{scheduledTime[i][j]}}</td>
                            <td>
                              @if (terminalList[i]?.length>0) {
                                <span>
                                  <div class="statwidth">
                                    <app-select-dropdown [items]="terminalList[i]"
                                      formControlName="location"
                                      [defaultValue]="locationIteArray[i][j]"
                                      (afterChange)="onLocChangeIte(i, j)">
                                    </app-select-dropdown>
                                  </div>
                                </span>
                              }
                            </td>
                            @if (showDestTankList[i]) {
                              <td>
                                @if (terminalList[i]?.length>0 && tankList[i][j]?.length>0) {
                                  <span>
                                    <div class="statwidth">
                                      <app-select-dropdown formControlName="tank"
                                        [defaultValue]="tankIteArray[i][j]"
                                        (afterChange)="onTankChangeIte(i,j)" [items]="tankList[i][j]">
                                      </app-select-dropdown>
                                    </div>
                                  </span>
                                }
                              </td>
                            }
                            <td>
                              <div class="statwidth">
                                <app-select-dropdown
                                  [items]="scheduledDemandProductListMaterialCode[i][j]"
                                  formControlName="scheduledProductMaterialCode"
                                  [defaultValue]="defaultProductMaterialCode[i][j]"
                                  (afterChange)="onScheduledProductChangeMaterialCode(i, j, 'ite')">
                                </app-select-dropdown>
                              </div>
                            </td>
                            @if (!inCpTab && terminalList[i]?.length > 0 && scheduledDemandProductList[i]?.length > 0) {
                              <td
                                >
                                <div class="statwidth">
                                  <app-select-dropdown [items]="scheduledDemandProductList[i][j]"
                                    formControlName="scheduledProduct"
                                    [defaultValue]="defaultProduct[i][j]"
                                    (afterChange)="scheduledDemandProductList[i][j]?.length > 0 && onScheduledProductChange(i, j)">
                                  </app-select-dropdown>
                                </div>
                              </td>
                            }
                            <td>
                              @if (demandProductList?.length>0) {
                                <span>
                                  <div class="statwidth">
                                    <app-select-dropdown [items]="demandProductListMaterialCode[i][j]"
                                      [defaultValue]="demandProductIteMaterialCode[i][j]"
                                      formControlName="demandProductMaterialCode"
                                      (afterChange)="onDemandProductChangeMaterialCode(i, j, 'ite')">
                                    </app-select-dropdown>
                                  </div>
                                </span>
                              }
                            </td>
                            <td>
                              @if (terminalList[i]?.length > 0 && demandProductList?.length > 0) {
                                <span>
                                  <div class="statwidth">
                                    <app-select-dropdown [items]="demandProductList[i][j]"
                                      [defaultValue]="demandProductIte[i][j]"
                                      formControlName="demandProduct"
                                      (afterChange)="onDemandProductChange(i, j)">
                                    </app-select-dropdown>
                                  </div>
                                </span>
                              }
                            </td>
                            <td class="sticky-cell">
                              <input class="form-control text-field" formControlName="scheduledQty"
                                type="number" (keyup)="checkQTY(i,j)">
                                @if ((ite(i).length - 1) == j) {
                                  <span class="qty-row">sum
                                  {{iteQtyValue[i]}}</span>
                                }
                              </td>
                              <td><input type="text" class="contractWidth form-control text-field"
                                formControlName="sapcontract"
                              (input)="onContractLineChange($event,j,i,'ite');"></td>
                              <td>
                                <div class="statwidth contractLineNo">
                                  <app-select-dropdown [action]="action"
                                    [items]=" contractLineItemsITE[i][j]"
                                    [defaultValue]="refContractLineItemsIte[i][j]"
                                    formControlName="contractLineNo"
                                    (triggerClick)="fetchContractLineItem(i,j,'ite')">
                                  </app-select-dropdown>
                                </div>
                              </td>
                              @if (isAdditionalData) {
                                <td><input type="text" class="form-control text-field"
                                formControlName="batchOriginLocation"></td>
                              }
                              @if (isAdditionalData) {
                                <td><input type="text" class="form-control text-field"
                                formControlName="valuationTypeOriginLoc"></td>
                              }
                              @if (isAdditionalData) {
                                <td><input type="text" class="form-control text-field"
                                formControlName="batchDestinationLocation"></td>
                              }
                              @if (isAdditionalData) {
                                <td><input type="text" class="form-control text-field"
                                formControlName="valuationTypeDestinationLoc"></td>
                              }
                              <td>
                                <div class="custom-dropdown">
                                  <button class="icon-btn action-icon action-button"></button>
                                  <div class="dropdown-menu-wrap">
                                    <div class="dropdown-menu">
                                      <button class="dropdown-item action-btn-item"
                                        (click)="copyIteRow(i,j)">duplicate row
                                      </button>
                                      @if (isMultipleRowItemIte[i]?.length>1) {
                                        <button
                                          class="dropdown-item action-btn-item text-danger"
                                          (click)="deleteIteRow(i,j)">delete
                                        </button>
                                      }
                                      <button class="dropdown-item action-btn-item text-act-btn"
                                        (click)="clearIteRow(i,j)">clear all fields
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        }
                        <tr class="border-left-hidden">
                          <td style="white-space: nowrap;"> {{iteMovementsValue[i]}} Movements
                          </td>
                          @if (!isAdditionalData) {
                            <td colspan="13"></td>
                          }
                          @if (isAdditionalData) {
                            <td colspan="17"></td>
                          }
                        </tr>
                      </ng-container>
                    </table>
                    <div class="my-3">
                      <button class="button button-text" (click)="addIteRow(i)">
                        add location
                      </button>
                    </div>
                  </div>
                }
              </div>
            }
            @if (errorMessage[i] && isOriginRequired[i] && isDestinationRequired[i]) {
              <div class="text-danger mb-5"
                >
                <span>
                  Origin Total Volume = {{originQtyValue[i]}}; Destination Total Volume = {{destinQtyValue[i]}}
                </span>
              </div>
            }
            @if (tankErrorOMsg[i] || tankErrorDMsg[i]) {
              <div class="text-danger mb-5">
                <span>
                  Tank is mandatory for selected Location
                </span>
              </div>
            }
          </ng-container>
        }
      </ng-container>
      @if (isMultipleTransport && hideDestination) {
        <div class="add-transport mb-5">
          <button class="button button-text float-left" (click)="addTransport()">
            + add transport
          </button>
        </div>
      }

      <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-6"
          [ngClass]="[!(selectedDeskParameter?.toLowerCase() === 'floridadesk' || selectedDeskParameter?.toLowerCase() === 'pnwdesk' || selectedDeskParameter?.toLowerCase() === 'pascagouladesk' || selectedDeskParameter?.toLowerCase() === 'richmonddesk') ? 'col-md-4' : 'col-md-6']">
          <button class="button" tabindex="0" (keydown.enter)="closePopup(true)"
          (click)="closePopup(true)">cancel</button>
        </div>
        @if (isCargoDesks && inCpTab) {
          <div class="form-group col-md-6">
            <button class="button" tabindex="0" (keydown.enter)="submitForm()" (click)="submitForm() "
              [disabled]="!createPipelineNominationForm.valid">{{this.trNominationForPipeline?.editNomination?
            "update" : "create"}}</button>
          </div>
        }
        @if (!inCpTab) {
          <div class="form-group col-md-6">
            <button class="button" tabindex="0" (keydown.enter)="submitUpdatePublish()"
              (click)="submitUpdatePublish()"
              [disabled]="!createPipelineNominationForm.valid">{{this.trNominationForPipeline?.editNomination?
            "update + publish" : "create + publish"}}</button>
          </div>
        }
      </div>
    </form>
  </div>