<div class="create-cca-nomination">
  <form [formGroup]="createCcaNominationForm" (keydown.enter)="$event.preventDefault()">
    <ng-container >
      <div class="mot-container">
        <span class="small-text label mot-text"><span class="text-danger">* </span> vehicle identifier</span>
        <div class="form-row">
          @for (scenario of motData; track scenario; let i = $index) {
            <div
              class="radio-container mb-0 d-flex align-items-center form-group branded">
              <input type="radio" class="mot-btns" (change)="updateFormFields(scenario?.value)"
                name="scenario" id="radio-1" [value]="scenario?.identifier"
                [checked]="scenario?.checked === true">
                <label for="radio-1" class="label">{{scenario.value}}</label>
              </div>
            }
          </div>
        </div>
      </ng-container>
      <div class="input-wrap">
        <div class="form-row mr-3 pb-1 pr-2 d-flex">
          <div class="form-group" class="form-group col-md-12">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label for="nominationNoPipeline" class="label"><span class="text-danger">*
                </span>nomination no</label>
                <input type="text" formControlName="nominationNo" class="form-control"
                  id="nominationNoPipeline">
                  @if (createCcaNominationForm.controls['nominationNo'].invalid && (createCcaNominationForm.controls['nominationNo'].dirty || createCcaNominationForm.controls['nominationNo'].touched)) {
                    <div class="text-danger"
                      >
                      nomination
                    no. limits to 16 characters</div>
                  }
                </div>
                @if (isCargoDesks) {
                  <div class="form-group col-md-6">
                    <label for="cargoName" class="label"><span class="text-danger">* </span>voyage name</label>
                    <input type="text" formControlName="cargoName" class="form-control" id="cargoName">
                  </div>
                }
                <div class="form-group col-md-4">
                  <div class="d-flex justify-content-between">
                    <label htmlFor="transportSystem" class="label"><span class="text-danger">* </span>
                    transport
                  system</label>
                </div>
                <app-select-dropdown [items]="transportSystemArray" formControlName="transportSystem"
                  [defaultValue]="defaultTransportSystem" #transportSystemDropdown (afterChange)="ontransportSystemChange()">
                </app-select-dropdown>
              </div>
              <div class="form-group col-md-4">
                <div class="d-flex justify-content-between">
                  <label for="vessel" class="label"> <span class="text-danger">* </span>
                  @if (!isBarge) {
                    <span>vessel name</span>
                  }
                  @if (isBarge) {
                    <span>barge name</span>
                  }</label>
                </div>
                <app-select-dropdown [items]="vesselArray" id="vessel" formControlName="vesselName"
                  [defaultValue]="vesselNameValue">
                </app-select-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-container">
          <label>
            <input type="checkbox" [checked]="isToggleChecked" (change)="toggleChange()">
            additional information
          </label>
        </div>
      </div>

      <ng-container formArrayName="transport">
        @for (transportControl of transportArray().controls; track transportControl; let i = $index) {
          <ng-container [formGroupName]="i">
            <div class="table-wrapper">
              <div>
                <span class="table-type">origin</span>
                <table aria-describedby="origin">
                  <tr>
                    <th>Confirm</th>
                    <th [hidden]="!isToggleChecked">@if (isOriginRequired[i]) {
                      <span class="text-danger">* </span>
                    }
                  description</th>
                  <th >@if (isOriginRequired[i]) {
                    <span class="text-danger">* </span>
                  }
                rdi</th>
                <th><span class="text-danger"></span> TSW Status</th>
                <th >@if (isOriginRequired[i]) {
                  <span class="text-danger">* </span>
                }
              scheduled date</th>
              <th hidden> counter party</th>
              <th hidden>@if (isOriginRequired[i]) {
                <span class="text-danger"></span>
              }
            Arrival Time</th>
            <th >@if (isOriginRequired[i]) {
              <span class="text-danger">* </span>
            }
          location</th>
          <th>@if (isOriginRequired[i]) {
            <span class="text-danger">*
            </span>
            } scheduled
          product material code</th>
          <th>@if (isOriginRequired[i]) {
            <span class="text-danger">* </span>
            } scheduled product
          </th>
          <th [hidden]="!isToggleChecked">@if (isOriginRequired[i]) {
            <span class="text-danger">*
            </span>
            } demand
          product material code</th>
          <th [hidden]="!isToggleChecked">@if (isOriginRequired[i]) {
            <span class="text-danger">* </span>
          }
          demand product
        </th>
        <th>@if (isOriginRequired[i]) {
          <span class="text-danger">* </span>
        } qty</th>
        <th>@if (isOriginRequired[i]) {
          <span class="text-danger">* </span>
        } uom</th>
        <th >sap contract</th>
        <th>contract line no.</th>
      </tr>
      <ng-container formArrayName="origin">
        @for (list of origin(i).controls; track list; let j = $index) {
          <ng-container
            [formGroupName]="j">
            <tr>
              <td ><input type="checkbox" class="form-control text-field cell-form-checkbox"
                formControlName="confirmationIndicator">
              </td>
              <td [hidden]="!isToggleChecked">
                <div class="statwidth">
                  <app-select-dropdown [items]="originRDIDescArray"
                    formControlName="description"
                    [defaultValue]="originRDIDescSelected[i][j]"
                    (afterChange)="onDescriptionChange(i, j)">
                  </app-select-dropdown>
                </div>
              </td>
              <td >
                <div class="rdiWidth">
                  <app-select-dropdown [items]="originRDIArray" formControlName="rdi"
                    [defaultValue]="originRDISelected[i][j]"
                    (afterChange)="onRdiChange(i, j)">
                  </app-select-dropdown>
                </div>
              </td>
              <td><input  type="number" class="form-control text-field"
                formControlName="tswStatus" readonly>
              </td> <td><input type="date"
              class="form-control text-field date-field"
            formControlName="scheduledDate"></td>
            <td hidden><input type="text" class="form-control text-field"
            formControlName="counterParty"></td>
            <td hidden>{{scheduledTime[i][j]}}</td>
            <td >
              <span>
                <div class="statwidth"> <app-select-dropdown [items]="terminalList[i]"
                  formControlName="location"
                  [defaultValue]="locationOriginArray[i][j]"
                  (afterChange)="onLocChangeOrigin(i, j)">
                </app-select-dropdown>
              </div>
            </span>
          </td>
          <td >
            @if (terminalList[i]?.length > 0) {
              <span>
                <div class="statwidth">
                  <app-select-dropdown
                    [ngClass]="{'disabled' : !locationOriginArray[i][j]}"
                    [items]="scheduledDemandProductListMaterialCode[i][j]"
                    formControlName="scheduledProductMaterialCode"
                    [defaultValue]="defaultProductMaterialCode[i][j]"
                    (afterChange)="scheduledDemandProductListMaterialCode[i][j]?.length > 0 && onScheduledProductChangeMaterialCode(i, j, 'origin')">
                  </app-select-dropdown>
                </div>
              </span>
            }
          </td>
          <td>
            @if (terminalList[i]?.length > 0) {
              <span>
                <div class="statwidth">
                  <app-select-dropdown [ngClass]="{'disabled' : !locationOriginArray[i][j]}"
                    [items]="scheduledDemandProductList[i][j]"
                    formControlName="scheduledProduct"
                    [defaultValue]="defaultProduct[i][j]"
                    (afterChange)="scheduledDemandProductList[i][j]?.length>0 && onScheduledProductChange(i, j)">
                  </app-select-dropdown>
                </div>
              </span>
            }
          </td>
          <td [hidden]="!isToggleChecked">
            @if (terminalList[i]?.length > 0) {
              <span>
                <div class="statwidth">
                  <app-select-dropdown [ngClass]="{'disabled' : !locationOriginArray[i][j]}"
                    [items]="demandProductListMaterialCode[i][j]"
                    [defaultValue]="demandProductOriginMaterialCode[i][j]"
                    formControlName="demandProductMaterialCode"
                    (afterChange)="onDemandProductChangeMaterialCode(i, j, 'origin')">
                  </app-select-dropdown>
                </div>
              </span>
            }
          </td>
          <td [hidden]="!isToggleChecked">
            @if (terminalList[i]?.length > 0) {
              <span>
                <div class="statwidth">
                  <app-select-dropdown [ngClass]="{'disabled' : !locationOriginArray[i][j]}"
                    [items]="demandProductList[i][j]"
                    [defaultValue]="demandProductOrigin[i][j]"
                    formControlName="demandProduct"
                    (afterChange)="onDemandProductChange(i, j)">
                  </app-select-dropdown>
                </div>
              </span>
            }
          </td>
          <td><input type="number" class="form-control text-field quantity"
            formControlName="scheduledQty" (keyup)="checkQTY(i,j)">
            @if ((origin(i).length - 1) == j) {
              <span class="qty-row">sum {{originQtyValue[i]}}</span>
            }
          </td>
          <td>
            <div class="uomWidth">
              <app-select-dropdown
                [items]="uomListOrigin[i][j]"
                [defaultValue]="uomOrigin[i][j]"
                formControlName="Uom">
              </app-select-dropdown>
            </div>
          </td>
          <td ><input type="text" class="form-control text-field sap-contract"
            formControlName="sapcontract"
          (input)="onContractLineChange($event,j,i,'origin');"></td>
          <td >
            <div class="statwidth contractLineNo">
              <app-select-dropdown [items]="contractLineItemsOrigin[i][j]"
                [defaultValue]="refContractLineItems[i][j]"
                formControlName="contractLineNo"
                (triggerClick)="fetchContractLineItem(i,j,'origin')">
              </app-select-dropdown>
            </div>
          </td>
          <td>
            <div class="custom-dropdown">
              <button class="icon-btn action-icon action-button"></button>
              <div class="dropdown-menu-wrap">
                <div class="dropdown-menu">
                  <button class="dropdown-item action-btn-item"
                    (click)="copyOriginRow(i,j)">duplicate row
                  </button>
                  @if (isMultipleRowItemOrigin[i]?.length>1) {
                    <button
                      class="dropdown-item action-btn-item text-danger"
                      (click)="deleteOriginRow(i,j)">delete
                    </button>
                  }
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    }
    <tr class="border-left-hidden">
      <td style="white-space: nowrap;">
        {{originMovementsValue[i]}} Movements
      </td>
      <td colspan="10"></td>
    </tr>
  </ng-container>
</table>
<div class="my-3 d-flex">
  <button class="button button-text" (click)="addOriginRow(i)">
    add row
  </button>
</div>
</div>
@if (!isPascagoulaRichmond) {
  <div>
    <span class="table-type">destination</span>
    <table aria-describedby="destination">
      <tr>
        <th>Confirm</th>
        <th [hidden]="!isToggleChecked">@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
        } description</th>
        <th>@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
        } rdi</th>
        <th><span class="text-danger"></span> TSW Status</th>
        <th >@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
        } scheduled date</th>
        <th hidden> counter party</th>
        <th hidden>@if (isDestinationRequired[i]) {
          <span class="text-danger"
          ></span>
        } Arrival Time</th>
        <th >@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
        } location</th>
        <th >@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
          } scheduled
        product material code</th>
        <th>@if (isDestinationRequired[i]) {
          <span class="text-danger">* </span>
          } scheduled
        product</th>
        <th [hidden]="!isToggleChecked">@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
          } demand
        product material code</th>
        <th [hidden]="!isToggleChecked">@if (isDestinationRequired[i]) {
          <span class="text-danger">*
          </span>
          } demand
        product</th>
        <th>@if (isDestinationRequired[i]) {
          <span class="text-danger">* </span>
        } qty</th>
        <th>@if (isDestinationRequired[i]) {
          <span class="text-danger">* </span>
        } uom</th>
        <th >sap contract</th>
        <th >contract line no.</th>
      </tr>
      <ng-container formArrayName="destination">
        @for (list of destination(i).controls; track list; let k = $index) {
          <ng-container
            [formGroupName]="k">
            <tr>
              <td><input type="checkbox" class="form-control text-field cell-form-checkbox"
                formControlName="confirmationIndicator">
              </td>
              <td [hidden]="!isToggleChecked">
                <div class="statwidth">
                  <app-select-dropdown [items]="destinationRDIDescArray"
                    formControlName="value"
                    [defaultValue]="destinationRDIDescSelected[i][k]"
                    (afterChange)="onDestinationDescriptionChange(i, k)">
                  </app-select-dropdown>
                </div>
              </td>
              <td >
                <div class="rdiWidth">
                  <app-select-dropdown [items]="destinationRDIArray" formControlName="key"
                    [defaultValue]="destinationRDISelected[i][k]"
                    (afterChange)="onDestinationRdiChange(i, k)">
                  </app-select-dropdown>
                </div>
              </td>
              <td><input  type="number" class="form-control text-field"
                formControlName="tswStatus" readonly>
              </td> <td ><input class="form-control text-field date-field"
              formControlName="scheduledDate" type="date">
            </td>
            <td hidden><input type="text" class="form-control text-field"
            formControlName="counterParty"></td>
            <td hidden>{{scheduledTimeDesti[i][k]}}</td>
            <td >
              <span>
                <div class="statwidth">
                  <app-select-dropdown [items]="terminalList[i]"
                    formControlName="location"
                    [defaultValue]="locationDestiArray[i][k]"
                    (afterChange)="onLocChangeDest(i, k)">
                  </app-select-dropdown>
                </div>
              </span>
            </td>
            <td >
              @if (terminalList[i]?.length > 0 && demandProductListDesc?.length > 0) {
                <span
                  >
                  <div class="statwidth">
                    <app-select-dropdown [ngClass]="{'disabled' : !locationDestiArray[i][k]}"
                      [items]="scheduledDemandProductListDescMaterialCode[i][k]"
                      formControlName="scheduledProductMaterialCode"
                      [defaultValue]="defaultProductdestiMaterialCode[i][k]"
                      (afterChange)="onScheduledProductDestiChangeMaterialCode(i, k)">
                    </app-select-dropdown>
                  </div>
                </span>
              }
            </td>
            <td >
              @if (terminalList[i]?.length > 0 && demandProductListDesc?.length > 0) {
                <span
                  >
                  <div class="statwidth">
                    <app-select-dropdown [ngClass]="{'disabled' : !locationDestiArray[i][k]}"
                      [items]="scheduledDemandProductListDesc[i][k]"
                      formControlName="scheduledProduct"
                      [defaultValue]="defaultProductdesti[i][k]"
                      (afterChange)="onScheduledProductDestiChange(i, k)">
                    </app-select-dropdown>
                  </div>
                </span>
              }
            </td>
            <td [hidden]="!isToggleChecked">
              @if (terminalList[i]?.length > 0) {
                <span
                  >
                  <div class="statwidth">
                    <app-select-dropdown [ngClass]="{'disabled' : !locationDestiArray[i][k]}"
                      [items]="demandProductListDescMaterialCode[i][k]"
                      [defaultValue]="demandProductDestiMaterialCode[i][k]"
                      formControlName="demandProductMaterialCode"
                      (afterChange)="demandProductListDescMaterialCode[i][k]?.length>0 && onDemandProductDestiChangeMaterialCode(i, k)">
                    </app-select-dropdown>
                  </div>
                </span>
              }
            </td>
            <td [hidden]="!isToggleChecked">
              @if (terminalList[i]?.length > 0) {
                <span
                  >
                  <div class="statwidth">
                    <app-select-dropdown [ngClass]="{'disabled' : !locationDestiArray[i][k]}"
                      [items]="demandProductListDesc[i][k]"
                      [defaultValue]="demandProductDesti[i][k]"
                      formControlName="demandProduct"
                    (afterChange)="demandProductListDesc[i][k]?.length>0 && onDemandProductDestiChange(i, k)"></app-select-dropdown>
                  </div>
                </span>
              }
            </td>
            <td><input class="form-control text-field quantity" formControlName="scheduledQty"
              (keyup)="checkQTY(i, k)" type="number">
              @if ((destination(i).length - 1) == k) {
                <span class="qty-row">sum {{destinQtyValue[i]}}</span>
              }
            </td>
            <td>
              <div class="uomWidth">
                <app-select-dropdown
                  [items]="uomListDesc[i][k]"
                  [defaultValue]="uomDestination[i][k]"
                  formControlName="Uom">
                </app-select-dropdown>
              </div>
            </td>
            <td ><input class="form-control text-field sap-contract" type="text"
              formControlName="sapcontract"
            (input)="onContractLineChange($event,k,i,'destination');"></td>
            <td >
              <div class="statwidth contractLineNo">
                <app-select-dropdown [items]="contractLineItemsDestination[i][k]"
                  [defaultValue]="refContractLineItemsDesti[i][k]"
                  formControlName="contractLineNo"
                  (triggerClick)="fetchContractLineItem(i,k, 'destination')">
                </app-select-dropdown>
              </div>
            </td>
            <td>
              <div class="custom-dropdown">
                <button class="icon-btn action-icon action-button"></button>
                <div class="dropdown-menu-wrap">
                  <div class="dropdown-menu">
                    <button class="dropdown-item action-btn-item"
                      (click)="copyDestinationRow(i,k)">duplicate row
                    </button>
                    @if (isMultipleRowItemDestination[i]?.length>1) {
                      <button
                        class="dropdown-item action-btn-item text-danger"
                        (click)="deletedestinationRow(i,k)">delete
                      </button>
                    }
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      }
      <tr class="border-left-hidden">
        <td style="white-space: nowrap;">
          {{destinMovementsValue[i]}} Movements
        </td>
        <td colspan="10"></td>
      </tr>
    </ng-container>
  </table>
  <div class="my-3">
    <button class="button button-text" (click)="addDestinationRow(i)">
      add row
    </button>
  </div>
</div>
}
</div>
@if (errorMessage[i] && isOriginRequired[i] && isDestinationRequired[i]) {
  <div class="text-danger mb-5"
    >
    <span>
      Origin Total Volume = {{originQtyValue[i]}}; Destination Total Volume = {{destinQtyValue[i]}}
    </span>
  </div>
}
</ng-container>
}
</ng-container>
<div class="form-row pb-1 pr-2">
  <div class="form-group col-md-6">
    <button class="button" (click)="closePopup()">cancel</button>
  </div>
  <div class="form-group col-md-6">
    <button class="button" (click)="submitForm() "
    [disabled]="!createCcaNominationForm.valid">{{!nominationService.ccaEditNomination ? "create + publish" : "update + publish"}}</button>
  </div>
</div>
</form>
</div>