<div class="panel-content">
  <ng-container>
    <div>
      <div class="content__header">functional group</div>
      <form class="branded">
        <div class="admin-side-panel">
          @for (obj of filters.transportFunctionalGroup; track obj; let i = $index) {
            @if (obj.show) {
              <p class="align-items-center d-flex">
                <input type="checkbox" class="flex-shrink-0" name="functionalGroupCheckbox" [id]="obj.name"
                  [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('transportFunctionalGroup', $event, i)">
                  <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                    {{ obj.name }}
                  </label>
                </p>
              }
            }
          </div>
        </form>
        <hr>
        </div>
        <div>
          <div class="content__header">region</div>
          <form class="branded">
            <div class="admin-side-panel">
              @for (obj of filters.region; track obj; let i = $index) {
                @if (obj.show) {
                  <p class="align-items-center d-flex">
                    <input type="checkbox" class="flex-shrink-0" name="regionCheckbox" [id]="obj.name"
                      [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('region', $event, i)">
                      <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                        {{ obj.name }}
                      </label>
                    </p>
                  }
                }
              </div>
            </form>
            <hr>
            </div>
            <div>
              <div class="content__header">transport system</div>
              <form class="branded">
                <input class="form-control admin-search" [name]="'searchText0'" type="search"
                  placeholder="search" (input)="updateSearchFilterList('transportSystem', $any($event.target).value)"
                  [(ngModel)]="searchValue[0]">
                  <div class="admin-side-panel">
                    @for (obj of filters.transportSystem; track obj; let i = $index) {
                      @if (obj.show) {
                        <p class="align-items-center d-flex">
                          <input type="checkbox" class="flex-shrink-0" name="transportSystemCheckbox" [id]="obj.name"
                            [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('transportSystem', $event, i)" >
                            <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                              {{ obj.name }}
                            </label>
                          </p>
                        }
                      }
                    </div>
                  </form>
                  <hr>
                  </div>
                  <div>
                    <div class="content__header">transport system name</div>
                    <form class="branded">
                      <input class="form-control admin-search" [name]="'searchText1'" type="search"
                        placeholder="search" (input)="updateSearchFilterList('transportSystemName', $any($event.target).value)"
                        [(ngModel)]="searchValue[1]">
                        <div class="admin-side-panel">
                          @for (obj of filters.transportSystemName; track obj; let i = $index) {
                            @if (obj.show) {
                              <p class="align-items-center d-flex">
                                <input type="checkbox" class="flex-shrink-0" name="transportSystemNameCheckbox" [id]="obj.name"
                                  [value]="obj.name" [checked]="obj.checked" (change)="applyCheckboxFilters('transportSystemName', $event, i)" >
                                  <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                    {{ obj.name }}
                                  </label>
                                </p>
                              }
                            }
                          </div>
                        </form>
                        <hr>
                        </div>
                        <div>
                          <div class="content__header">mode of transport</div>
                          <form class="branded">
                            <input class="form-control admin-search" [name]="'searchText2'" type="search"
                              placeholder="search" (input)="updateSearchFilterList('modeOfTransport', $any($event.target).value)"
                              [(ngModel)]="searchValue[2]">
                              <div class="admin-side-panel">
                                @for (obj of filters.modeOfTransport; track obj; let i = $index) {
                                  @if (obj.show) {
                                    <p class="align-items-center d-flex">
                                      <input type="checkbox" class="flex-shrink-0" name="modeOfTransportCheckbox" [id]="obj.name "
                                        [value]="obj.name " [checked]="obj.checked" (change)="applyCheckboxFilters('modeOfTransport', $event, i)">
                                        <label class="text-ellipsis" [title]="obj.name " [htmlFor]="obj.name ">
                                          {{ obj.name }}
                                        </label>
                                      </p>
                                    }
                                  }
                                </div>
                              </form>
                              <hr>
                              </div>
                            </ng-container>
                          </div>

                          <div class="panel-footer d-flex justify-content-between p-3">
                            <span class="clear-filter" (click)="clearFilters()">clear</span>
                            <span class="clear-filter" (click)="filterTransport();">apply</span>
                          </div>
