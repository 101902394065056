import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import { Router, CanDeactivate, NavigationEnd } from '@angular/router';
import { AlertRule } from '../shared/interface/settings.interface';
import { AlertsService } from './alerts.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AlertSaveGuardService implements CanDeactivate<boolean>{

  changeMap: Map<string, null>;

  changeList = new BehaviorSubject<string[]>([]);
  listObservable$: Observable<string[]> = this.changeList.asObservable();
  changedRuleList = new BehaviorSubject<AlertRule[]>([]);

  private hrefSource = new BehaviorSubject<boolean>(false);
  hrefObservable$: Observable<boolean> = this.hrefSource.asObservable();

  private deskSource = new BehaviorSubject<boolean>(false);
  deskObservable$: Observable<boolean> = this.deskSource.asObservable();

  private navigationSource = new BehaviorSubject<string>('');
  navigationObservable$: Observable<string> = this.navigationSource.asObservable();

  constructor(private router: Router, private alertService: AlertsService, private toastService: ToastService){
    this.changeMap = new Map<string, null>();
  }

  ngOnInit(){
    this.router.events.subscribe((event) =>{

      if(event instanceof NavigationEnd){
        this.clearSettings();       
      }
    });
  }
  
  addContent(toggledValue: string){
    if(this.isNaNOrEmpty(toggledValue))
    {
      return;
    }
    this.changeMap.has(toggledValue) ? 
        this.changeMap.delete(toggledValue) : this.changeMap.set(toggledValue, null);
    
    this.changeList.next(Array.from(this.changeMap.keys()));
  }

  setHrefSource(updateValue: boolean){
    this.hrefSource.next(updateValue);
  }

  setDeskSource(updateValue: boolean){
    this.deskSource.next(updateValue);
  }

  setNavigationAddress(updateValue: string){
    this.navigationSource.next(updateValue);
  }

  isUsingHrefBlock(): boolean{
    return this.hrefSource.value;
  }

  isUsingDeskBlock(): boolean{
    return this.deskSource.value;
  }


  extractURL(): string{
    return this.navigationSource.value;
  }

  clearSettings(){
    this.changeMap = new Map<string, null>();
    this.setHrefSource(false);
    this.setDeskSource(false);
    this.setNavigationAddress('');
    this.clearChanges();
  }

  routeAway(){
    if(this.isUsingHrefBlock()){
      window.location.href = this.extractURL();
    }else if(this.isUsingDeskBlock()){
      console.log(this.router.url)
      this.router.navigateByUrl(this.router.url);
      this.clearSettings();
    }else{
      this.router.navigateByUrl(this.extractURL());
      this.clearSettings();
    }
  }

  canDeactivate(){
    if(this.changeMap.size === 0){
      return true;
    }else{
      return false;
    }
  }

  clearChangeList(){
    this.changeMap = new Map<string, null>();   
    this.changeList.next([]);
  }


  //RAN+
  updateRuleList(rule: AlertRule){
    
    let ruleList = this.changedRuleList.value;
    let indexOfUpdate = ruleList.map(itRule => itRule.id!).indexOf(rule.id!);
    let changeListHasRule = this.changeList.value.map(_ => _.split(';')[1].includes(rule.id!)).includes(true);

    if(changeListHasRule){
      indexOfUpdate == -1? ruleList.push(rule): ruleList[indexOfUpdate] = rule; ;
    }else if(indexOfUpdate != -1){
       ruleList.splice(indexOfUpdate, 1);
    }
    this.changedRuleList.next(ruleList);
  }

  clearChanges(){
    this.changeList.next([]);
    this.changedRuleList.next([]);
  }

   isNaNOrEmpty(str: string | null | undefined): boolean {
    if (str === null || str === undefined || str.trim() === "") {
      return true;
    }
    return false;
  }

  saveRuleChanges(){

    let ruleUpdateList = this.changedRuleList.value;

    ruleUpdateList.forEach(rule => {

      this.alertService.updateAlert(rule).subscribe({
        next: (response: any) => {
        },
        error: (err) => {
          console.log(err);
          this.toastService.setToastNotification({
            show: true,
            type: 'error',
            msg: 'error - rule could not be updated',
          });
        },
        complete: () => {
          this.toastService.setToastNotification({
            show: true,
            type: 'success',
            msg: 'rule updated',
          });


        },
      });

    });
  }
}
