<div class="create-alert-notification-panel-wrapper">
  <div class="panel-header d-flex justify-content-between">
    <div class="panel-title">notifications</div>
    <button class="notification-btn notification-panel notification" (click)="closeAlertNotificationPanel()">
      <span class="icon-remove open"></span>
    </button>
  </div>
  <div class="panel-content">
    <div class="accordion-item">
      <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-6 branded">
          <label for="" class="label">desk</label>
          <select class="form-control dropDown-select" [(ngModel)]="selectedDesk" (change)="updateForTerminalSelection()">
            <option value="" selected>all</option>
            @for (desk of desksList; track desk) {
              <option>
              {{desk}}</option>
            }
          </select>
        </div>
        <div class="form-group col-md-6 branded">
          <label class="label">terminal</label>
          <select class="form-control dropDown-select" [(ngModel)]="this.selectedTerminal" (change)="updateForMaterialSelection()">
            <option class="dropDown-select-option" value="" selected>all</option>
            @for (terminal of locList; track terminal) {
              <option class="dropDown-select-option">
              {{terminal}}</option>
            }
          </select>
        </div>
      </div>
      <div class="form-row pb-1 pr-2">
        <div class="form-group col-md-6 branded">
          <label for="terminal" class="label">material</label>
          <select class="form-control dropDown-select" [(ngModel)]="selectedMaterial" (change)="updateForRuleSelection()">
            <option class = "dropDown-select-option" value="" selected>all</option>
            @for (material of matNameList; track material) {
              <option class = "dropDown-select-option" [value]="material">
              {{material}}</option>
            }
          </select>
        </div>
        <div class="form-group col-md-6 branded">
          <label class="label">rule type</label>
          <select class="form-control dropDown-select"  [(ngModel)]="selectedRuleType" (change)="this.ruleDropDownUpdate()">
            <option class="dropDown-select-option" value="" selected>all</option>
            @for (ruleType of rulesDropdown; track ruleType) {
              <option class="dropDown-select-option" [value]="ruleType">
              {{ruleType}}</option>
            }
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

@if (this.deskFilterList.length > 0) {
  <ul>
    @for (deskFilter of deskFilterList; track deskFilter) {
      <app-filter-desk-block-panel [cardValues]="deskFilter" (choseFilterOption)="changeDeskFilterUponClick($event)">
      </app-filter-desk-block-panel>
    }
  </ul>
}

<div>
  @for (divider of this.dividedCardList; track divider) {
    <hide-list  [header]="divider.divider" >
      <app-light-header header [listLength]="divider.alertList.length" [arrowVisible]="true" [flipArrow]="true" [header]="divider.divider"></app-light-header>
      @for (alertPayload of divider.alertList; track alertPayload) {
        <div content>
          <app-workspace-alerts-summary-card [cardAlert]="alertPayload" (closeCard)="removeDismissedAlert($event)">
          </app-workspace-alerts-summary-card>
        </div>
      }
    </hide-list>
  }
</div>

