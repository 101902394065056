import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class DynamicDealsSharedStateService {
       private deskInformationSubject = new BehaviorSubject<any[]>([]);
       private regionSubject = new BehaviorSubject<string>('');
       private deskSubject = new BehaviorSubject<string>('');
       private deskIdSubject = new BehaviorSubject<number>(0);

       deskInfoState$ = this.deskInformationSubject.asObservable();
       regionState$ = this.regionSubject.asObservable();
       deskState$ = this.deskSubject.asObservable();
       deskIdState$ = this.deskIdSubject.asObservable();

       

       emitDeskInformation(desks: any[])
       {
           this.deskInformationSubject.next(desks);
       }

       emitRegion(region: string)
       {
        this.regionSubject.next(region);

       }

       emitDesk(desk: string)
       {
        this.deskSubject.next(desk);

       }

       emitDeskId(deskId: number)
       {
        this.deskIdSubject.next(deskId);

       }
}