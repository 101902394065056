<div class="confirm-modal">
  @if (!uswcShellCarson && nominationComponent) {
    <p>After clicking save, this value will get applied to other rows as well</p>
  }
  @if (!uswcShellCarson && !nominationComponent) {
    <p>this action will place the volumes in the new date row of the same offtaker and it will also affect data in
    the nominations page.</p>
  }
  @if (uswcShellCarson) {
    <p>The value entered in the spoke(s) demand override column will change the value entered in the total demand forecast column.</p>
  }
  <div class="confirm-buttons">
    <button class="cancel-button button" (click)="closePopup()">cancel</button>
    <button class="ok-button button" (click)="confirm()">yes</button>
  </div>
</div>