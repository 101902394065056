 
import { Action, createReducer, on } from "@ngrx/store";
import { applyDDPrimaryFilter, applyDDSecondaryFilter, applySelectedCBSort, loadDynamicDealSuccess, resetAllFilters, resetDDSecondaryFilter, selectMaterialTab, toggleDealHeader, updateCustomDateRangePicker, updateSortedData, updateIndicatorFilterValues, updateIndicatorsSuccess, updateIndicatorsFailure } from "../action/cb-dynamic-deals.action";

export interface State {
    cbDynamicDealsResponse: any[];
    filters: { 
        primary : {
             transaction: string[],
              material: { tsw: string[], sra: string[] },
               cycle: string[] 
            },
        secondary: {
             exposure: string[];
              term: string[]; 
              counterParty: string[]; 
              location: string[];
              indicators: string[];
              schedulertypes: string[];
             },
        cbSortOptions: string[];
        cbSelectedSortType: string;
            };
    selectedFilters: {
          primary : {
             transaction: string[],
              material: { tab: string, values: string[] },
               cycle: string[]
             },
          secondary : {
             exposure: string[],
              term: string[],
               counterParty: string[],
                location: string[],
                indicators: string[],
                schedulertypes: string[]
            },
          cbSortOptions: string[];
          cbSelectedSortType: string;
        };
    filteredData: any[];
    updateCustomDateRangePickerFlag: boolean;
    isIndicatorUpdateFailed: any;
    // cbSortOptions: string[];
    // cbSelectedSortType: string;
}

export const initialState: State = {
  cbDynamicDealsResponse: [],
    filters: {
        primary: {
            transaction: ['all'],
            material: {
                tsw: ['all'],
                sra: ['all']
            },
            cycle: ['all']
        },
        secondary: {
            exposure: ['all'],
            term: ['all'],
            counterParty: ['all'],
            location: ['all'],
            indicators:['all'],
            schedulertypes: ['all']
        },
        cbSortOptions: ['newest','oldest','a-z','z-a'],
        cbSelectedSortType: 'newest'
    },
    selectedFilters: {
        primary: {
            transaction: ['all'],
            material: {
                tab: "tsw",
                values: ['all']
            },
            cycle: ['all']
        },
        secondary: {
            exposure: ['all'],
            term: ['all'],
            counterParty: ['all'],
            location: ['all'],
            indicators:['all'],
            schedulertypes: ['all']
        },
        cbSortOptions: ['newest','oldest','a-z','z-a'],
        cbSelectedSortType: 'newest'
    },
        filteredData: [],
        updateCustomDateRangePickerFlag: false,
        isIndicatorUpdateFailed:'',
        // cbSortOptions: ['newest','oldest','a-z','z-a'],
        // cbSelectedSortType: 'newest'
    };

  export const months:string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  export function indicatorFilters(region:string)
  {
    let filters: string[] = [];

    if(region == '')
    {
    const selectedDesk = localStorage.getItem('desk');
    if(selectedDesk?.includes('usec'))
    {
      region = 'usec';
    }

    if(selectedDesk?.includes('uswc'))
      {
        region = 'uswc';
      }
    }


   if(region == 'usec')
   {
    filters = [
        "EXH",
         "LS",
        "TXO"]
   }
   if(region == 'uswc')
   {
    filters = ["LAX","HOP","RICH","ITT","DEL"];
   }
   return filters;
  }
  
   export function formatStringToNA(value:any)
  {
      if(!value)
      {
         return 'n/a';
      }
      if(value?.length == 0)
        {
           return 'n/a';
        }
      return value;
  }
  
  export function formatStringToUnAssigned(value:any)
  {
      if(!value)
      {
         return 'unassigned';
      }
      if(value?.length == 0)
        {
           return 'unassigned';
        }
      return value;
  }
  
  function sortfilterValues(data:any)
  {
    return data.sort((a:any,b:any)=>{
      if(a == 'all') return -1;
      if(b == 'all') return 1;
      if(a == 'n/a') return -1;
      if(b == 'n/a') return 1;
      return a.localeCompare(b);
    });
  }
  
  export function getStoredSelectedFilters()
  {
    const getSelectedFiltersForDesk = `desk${getSelectedDeskId()}selectedFilters`;
    const temp = localStorage.getItem((getSelectedFiltersForDesk));
          const storedFilters = temp ? JSON.parse(temp) : {
              primary: {
                  transaction: ['all'],
                  material: {
                      tab: "tsw",
                      values: ['all']
                  },
                  cycle: ['all']
              },
              secondary: {
                  exposure: ['all'],
                  term: ['all'],
                  counterParty: ['all'],
                  location: ['all'],
                  indicators:['all'],
                  schedulertypes: ['all']
              },
              cbSortOptions: ['newest','oldest','a-z','z-a'],
              cbSelectedSortType: 'newest'
          };
          if(!storedFilters.secondary.hasOwnProperty('indicators'))
          {
              storedFilters.secondary.indicators = ['all'];
              const getSelectedFiltersForDesk = `desk${getSelectedDeskId()}selectedFilters`;
              localStorage.setItem(getSelectedFiltersForDesk,JSON.stringify(storedFilters));

          }
          if(!storedFilters.secondary.hasOwnProperty('schedulertypes'))
            {
                storedFilters.secondary.schedulertypes = ['all'];
                const getSelectedFiltersForDesk = `desk${getSelectedDeskId()}selectedFilters`;
                localStorage.setItem(getSelectedFiltersForDesk,JSON.stringify(storedFilters));
  
            }
          return storedFilters;
  }
  
  export function getSelectedDeskId()
  {
    const storedDeskId = localStorage.getItem("deskId");
    const deskId = storedDeskId? parseInt(storedDeskId,10):0;
    return deskId;
  }
  
  export function getSelectedDates()
  {
    const selectedDates = localStorage.getItem(
      `datefilterPayload_Cb_desk${getSelectedDeskId()}`);
    if(selectedDates)
    {
      const parsedDates = JSON.parse(selectedDates);
  
      return {
        startDate: parsedDates.startDate ? parsedDates.startDate : null,
        endDate: parsedDates.endDate ? parsedDates.endDate : null
      }
    }
    // Add current month as default
    const currentMonth = months[new Date().getMonth()];
    
    const currentMonthIndex = months.indexOf(currentMonth);
    
    const firstDate = new Date(new Date().getFullYear(), currentMonthIndex,1);
    const lastDate = new Date(new Date().getFullYear(), currentMonthIndex + 1, 0); // Last day of the last selected month

    return {startDate:firstDate,endDate:lastDate};
  }
  
  export function getPayloadForDynamicDeals()
  {
    const selectedDates = getSelectedDates();
    if(!selectedDates.startDate || !selectedDates.endDate)
    {
      return {deskId:getSelectedDeskId(),filterDealLeg:'true'};
    }
    return {deskId:getSelectedDeskId(),fromDate:selectedDates.startDate,toDate:selectedDates.endDate,filterDealLeg:'true'};
  }

    
function setUpDynamicDealsData(deals:any,selectedTab:any) {
    let dynamicDeals = deals?.map((dealInfo:any,i:any) => {
             const newdealInfo = {...dealInfo};
            const {counterpartyName,udmMaterialTSW,udmDeliveryWindow,dealRevisionDate,trader,dealTerm,dealNumber,contractNumber} = newdealInfo;

            //adding formatted dealNoContractNo key to each dealInfo obj
             newdealInfo.dealNoContractNo = `${dealNumber} | ${contractNumber===''?'pending':contractNumber}`;
             newdealInfo.udmMaterialTSW = formatStringToNA(newdealInfo?.udmMaterialTSW);
             newdealInfo.udmMaterialSRA = formatStringToNA(newdealInfo?.udmMaterialSRA);
             const detailsList = [{
                label:'counterparty',
                value:counterpartyName??'n/a',
                marginRightValue: "119px"
              },
              {
                label: selectedTab === 'tsw'?'material (TSW)':'material (SRA)',
                value: selectedTab === 'tsw'? newdealInfo.udmMaterialTSW:newdealInfo.udmMaterialSRA,
                marginRightValue: "167px"
              },
              {
                label:'delivery window',
                value:udmDeliveryWindow??'n/a',
                marginRightValue: "88px"
              },
              {
                label:'date revised',
                value: formatDate(dealRevisionDate),
                marginRightValue: "51px"
              },
              {
                label:'trader',
                value:trader??'n/a',
                marginRightValue: "86px"
              },
              {
                label:'term',
                value:dealTerm??'n/a'
              }];
              newdealInfo['dealDetailList']=  detailsList;
              //For expand-collapse deal leg container
              newdealInfo['isExpanded']=  false;
             if(newdealInfo.dealLegs) {
               newdealInfo.dealLegs =  newdealInfo?.dealLegs?.map((dealLegInfo:any,j:any) =>{
                 const newDealLegInfo = {...dealLegInfo};
                 newDealLegInfo.udmMaterialTSW = formatStringToNA(newDealLegInfo?.udmMaterialTSW)
                 newDealLegInfo.udmMaterialSRA = formatStringToNA(newDealLegInfo?.udmMaterialSRA)
                 newDealLegInfo.pipelineCycle = formatStringToNA(newDealLegInfo?.pipelineCycle)
                 newDealLegInfo.locationAbbreviation = formatStringToNA(newDealLegInfo?.locationAbbreviation)



                 const dealLegAttributes = [
                    {
                      label:'location',
                      value:newDealLegInfo?.location??'n/a',
                      marginRightValue: "145px"
                    },
                    {
                      label: selectedTab === 'tsw'?'material (TSW)':'material (SRA)',
                      value: selectedTab === 'tsw'? newDealLegInfo.udmMaterialTSW:newDealLegInfo.udmMaterialSRA,
                      marginRightValue: "161px"
                    },
                    {
                      label:'delivery window',
                      value:newDealLegInfo?.udmDeliveryWindow??'n/a',
                      marginRightValue: "88px"
                    },
                    {
                      label:'price curve',
                      value:newDealLegInfo?.curveName??'n/a',
                      marginRightValue: "176px"
                    },
                    {
                      label:'offset',
                      value:newDealLegInfo?.offset??'n/a',
                      marginRightValue: "62px"
                    },
                    {
                      label:'cycle',
                      value:newDealLegInfo?.pipelineCycle??'n/a'
                    }
                  ];
                  newDealLegInfo['dealLegAttributes']=  dealLegAttributes;
                   //For expand-collapse deal leg info
                  newDealLegInfo['expandDealLegInfo']=  {isExpanded: false,index:j};
                 if(newDealLegInfo.nominationCycles)
                 {
                   newDealLegInfo.nominationCycles = newDealLegInfo?.nominationCycles?.map((nominationCycle:any,i:any) => {
                       const newNominationCycle = {...nominationCycle};
                       const fullfilledQuantity = newNominationCycle?.nominations?.reduce((acc:any,curr:any)=>{
                           acc = acc+(+curr.nominatedQuantity);
                           return acc;
                         },0)
                         newNominationCycle["nominationCycleFullfilledQuantity"] = roundToThree(fullfilledQuantity);
                         newNominationCycle["nominationCycleTotalQuantity"] = "null";
                         newNominationCycle.cycle = formatStringToUnAssigned(newNominationCycle.cycle);
                         if(newNominationCycle.nominations) {
                            newNominationCycle.nominations =   newNominationCycle?.nominations?.map((nominationList:any,k:any)=>{
                                const newnominationList = {...nominationList};
                                const nomCycleNomList = [
                                  {
                                    label:'',
                                    value:'',
                                    marginRightValue: "6px",
                                    isActionButton: true
                                  },
                                  {
                                    label:'nom name',
                                    value:newnominationList?.nominationName??'n/a',
                                    marginRightValue: "80px"
                                  },
                                  {
                                    label:'origin',
                                    value:newnominationList?.origin??'n/a',
                                    marginRightValue: "86px"
                                  },
                                  {
                                    label:'sch qty(mb)',
                                    value:newnominationList?.nominatedQuantity??'n/a',
                                    marginRightValue: "67px"
                                  },
                                  {
                                    label:'act qty(mb)',
                                    value:newnominationList?.actualPostedQuantity??'n/a',
                                    marginRightValue: "68px"
                                  },
                                  {
                                    label:'batch date',
                                    value: formatDate(newnominationList?.batchDate),
                                    marginRightValue: "60px"
                                  },
                                  {
                                    label:'status',
                                    value:newnominationList?.status??'n/a',
                                    marginRightValue: "60px"
                                  },
                                  {
                                    label:'rdi',
                                    value:newnominationList?.rdi??'n/a',
                                    marginRightValue: "70px"
                                  },
                                  {
                                    label:'chain',
                                    value:newnominationList?.chain??'n/a',
                                  }
                                ];
                                newnominationList['nomCycleNomList']=  nomCycleNomList;
                                return newnominationList;
                              });
                            }
                              // initial space between each nom list in collapsed state
                              const spacing =35;
                              newNominationCycle["top"] =  spacing; //i * spacing;
                              newNominationCycle["isExpanded"] = false;
                         return newNominationCycle;
                   });
                 }
                   const dealLegFullfilledQuantity = newDealLegInfo?.nominationCycles?.reduce((acc:any,curr:any)=>{
                       acc = acc+(+curr.nominationCycleFullfilledQuantity);
                       return acc;
                     },0);
                     newDealLegInfo.udmMaterialTSW = newDealLegInfo.udmMaterialTSW??'n/a';
                     newDealLegInfo.udmMaterialSRA = newDealLegInfo.udmMaterialSRA??'n/a';
                     newDealLegInfo["dealLegFullfilledQuantity"] = roundToThree(dealLegFullfilledQuantity);
                     return newDealLegInfo;
               });
             }
              const [dealFullfilledQuantity,dealTotalQuantity] = calculateDealFullFilledAndTotalQty(dealInfo);
              newdealInfo["udmDealFullfilledQuantity"] = dealFullfilledQuantity;
              newdealInfo["udmDealTotalQuantity"] = dealTotalQuantity;
              return newdealInfo;
           });

    return dynamicDeals;
}

function setUpMaterialFilterChange(deals:any,selectedTab:any)
{
  let dynamicDeals = deals?.map((dealInfo:any,i:any) => {
    const newdealInfo = {...dealInfo};
    newdealInfo.udmMaterialTSW = formatStringToNA(newdealInfo?.udmMaterialTSW)
    newdealInfo.udmMaterialSRA = formatStringToNA(newdealInfo?.udmMaterialSRA)


    const detailsList = [{
       label:'counterparty',
       value:newdealInfo.counterpartyName??'n/a',
       marginRightValue: "119px"
     },
     {
       label: selectedTab === 'tsw'?'material (TSW)':'material (SRA)',
       value: selectedTab === 'tsw'? newdealInfo.udmMaterialTSW:newdealInfo.udmMaterialSRA,
       marginRightValue: "167px"
     },
     {
       label:'delivery window',
       value:newdealInfo.udmDeliveryWindow??'n/a',
       marginRightValue: "88px"
     },
     {
       label:'date revised',
       value: formatDate(newdealInfo.dealRevisionDate),
       marginRightValue: "51px"
     },
     {
       label:'trader',
       value:newdealInfo.trader??'n/a',
       marginRightValue: "86px"
     },
     {
       label:'term',
       value:newdealInfo.dealTerm??'n/a'
     }];
     newdealInfo['dealDetailList']=  detailsList;
    if(newdealInfo.dealLegs) {
      newdealInfo.dealLegs =  newdealInfo?.dealLegs?.map((dealLegInfo:any,j:any) =>{
        const newDealLegInfo = {...dealLegInfo};
        newDealLegInfo.udmMaterialTSW = formatStringToNA(newDealLegInfo?.udmMaterialTSW)
        newDealLegInfo.udmMaterialSRA = formatStringToNA(newDealLegInfo?.udmMaterialSRA)
        const dealLegAttributes = [
           {
             label:'location',
             value:newDealLegInfo?.location??'n/a',
             marginRightValue: "145px"
           },
           {
            label: selectedTab === 'tsw'?'material (TSW)':'material (SRA)',
            value: selectedTab === 'tsw'? newDealLegInfo.udmMaterialTSW:newDealLegInfo.udmMaterialSRA,
             marginRightValue: "161px"
           },
           {
             label:'delivery window',
             value:newDealLegInfo?.udmDeliveryWindow??'n/a',
             marginRightValue: "88px"
           },
           {
             label:'price curve',
             value:newDealLegInfo?.curveName??'n/a',
             marginRightValue: "176px"
           },
           {
             label:'offset',
             value:newDealLegInfo?.offset??'n/a',
             marginRightValue: "62px"
           },
           {
             label:'cycle',
             value:newDealLegInfo?.pipelineCycle??'n/a'
           }
         ];
         newDealLegInfo['dealLegAttributes']=  dealLegAttributes;
            return newDealLegInfo;
      });
    }
     return newdealInfo;
  });

return dynamicDeals;
}




function filterData(data: any[], filters: any,filterValues:any) : any[]  {
       
    let filteredData = data;

    // to update deal header quantities
    filteredData = updateDealsFullfilledAndTotalQty(filteredData);

    if(filters.primary.transaction?.length == 0 || filters.primary.material.values.length == 0 || filters.primary.cycle.length == 0)
    {
      filteredData = []
      return filteredData;
    }

    if(filters.secondary.exposure?.length == 0 || filters.secondary.term?.length == 0 || filters.secondary.counterParty?.length == 0)
      {
        filteredData = [];
        return filteredData;
      }

       
    if(filters.primary.transaction && filters.primary.transaction.length > 0 && !filters.primary.transaction.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => filters.primary.transaction.includes(dealInfo.udmDealContractType.toLocaleLowerCase()));
    }
    if(filters.secondary.exposure && filters.secondary.exposure.length > 0 && !filters.secondary.exposure.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => {
            const temp =  +dealInfo.udmDealFullfilledQuantity === +dealInfo.udmDealTotalQuantity ? 'balanced':
                              +dealInfo.udmDealFullfilledQuantity > +dealInfo.udmDealTotalQuantity ? 'over': 'under';
            return filters.secondary.exposure.includes(temp);
        });
    }
    if(filters.secondary.term && filters.secondary.term.length > 0 && !filters.secondary.term.includes('all'))
    {
        const isEvergreenSelected = filters.secondary.term.includes("evergreen");
        filteredData = filteredData.filter(dealInfo => filters.secondary.term.includes(dealInfo.dealTerm.toLocaleLowerCase()) || (isEvergreenSelected && dealInfo.dealTerm.toLocaleLowerCase() === 'term evergreen'));

    }
    if(filters.secondary.schedulertypes && filters.secondary.schedulertypes.length > 0 && !filters.secondary.schedulertypes.includes('all'))
    {
      if(filters.secondary.schedulertypes.length+1 != filterValues.schedulertypes.length)
        {
          filteredData = filteredData.filter(dealInfo => filters.secondary.schedulertypes.includes(dealInfo.udmDeskOriginOrDest));
        }
    }

    if(filters.secondary.counterParty && filters.secondary.counterParty.length > 0 && !filters.secondary.counterParty.includes('all'))
    {
        filteredData = filteredData.filter(dealInfo => filters.secondary.counterParty.includes(dealInfo.counterpartyAbbreviation));
    }

    if(filters.secondary.indicators && filters.secondary.indicators.length > 0 && !filters.secondary.indicators.includes('all'))
      {
        if(filters.secondary.indicators.length+1 != filterValues.indicators.length)
        {
          filteredData = filteredData.filter(dealInfo => 
                      dealInfo.udmIndicators.some((ind:any) =>
                         filters.secondary.indicators.includes(ind.name)));
        }
            
      }
      else{
        if(filters.secondary.indicators?.length == 0)
          {
            filteredData = filteredData.filter(dealInfo => 
              dealInfo.udmIndicators.length == 0);
              return filteredData;
          }
      }

    if(filters.primary.material.values && filters.primary.material.values.length > 0)
    {
      if(!filters.primary.material.values.includes('all'))
      {
          if(filters.primary.material.tab === 'tsw')
          {
          let data = filteredData.map(dealInfo =>{
              const newDealInfo = {...dealInfo};
              newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.primary.material.values.includes(dealLeg.udmMaterialTSW))
            return newDealInfo;
          } 
          ).filter(dealInfo => dealInfo.dealLegs?.length > 0).map(dealInfoData=>{
            const newDealInfoData = {...dealInfoData};
            // to display multiple if we have multiple material names under filtered deal legs and material name directly if we have unique list
            const getFirstMaterialName = newDealInfoData?.dealLegs[0]?.udmMaterialTSW;
            const checkIfAllMaterialSame = newDealInfoData?.dealLegs?.every((dealLeg:any)=>dealLeg?.udmMaterialTSW === getFirstMaterialName);
            newDealInfoData['udmMaterialTSW'] = checkIfAllMaterialSame? getFirstMaterialName:'multiple';
            return newDealInfoData;
          });
          filteredData = setUpMaterialFilterChange(data,'tsw');
          }
          else
          {
              let data = filteredData.map(dealInfo =>{
                  const newDealInfo = {...dealInfo};
                  newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.primary.material.values.includes(dealLeg.udmMaterialSRA))
                return newDealInfo;
              } 
              ).filter(dealInfo => dealInfo.dealLegs?.length > 0).map(dealInfoData=>{
                const newDealInfoData = {...dealInfoData};
                // to display multiple if we have multiple material names under filtered deal legs and material name directly if we have unique list
                const getFirstMaterialName = newDealInfoData?.dealLegs[0]?.udmMaterialSRA;
                const checkIfAllMaterialSame = newDealInfoData?.dealLegs?.every((dealLeg:any)=>dealLeg?.udmMaterialSRA === getFirstMaterialName);
                newDealInfoData['udmMaterialSRA'] =  checkIfAllMaterialSame? getFirstMaterialName:'multiple';
                return newDealInfoData;
              });
              filteredData = setUpMaterialFilterChange(data,'sra');
          }
      }
      else{
        if(filters.primary.material.tab === 'tsw')
        {
          filteredData = setUpMaterialFilterChange(filteredData,'tsw');
        } else{
          filteredData = setUpMaterialFilterChange(filteredData,'sra');
        }
        
      }
        

    }

    if(filters.secondary.location && filters.secondary.location.length > 0 && !filters.secondary.location.includes('all'))
        {
            
            filteredData = filteredData.map(dealInfo =>{
                const newDealInfo = {...dealInfo}
              newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.secondary.location.includes(dealLeg.locationAbbreviation))
              return newDealInfo;
            } 
            ).filter(dealInfo => dealInfo.dealLegs?.length > 0);

        }

    if(filters.primary.cycle && filters.primary.cycle.length > 0 && !filters.primary.cycle.includes('all'))
    {

      filteredData = filteredData.map(dealInfo =>{
        const newDealInfo = {...dealInfo}
      newDealInfo.dealLegs = newDealInfo.dealLegs.filter((dealLeg: any) => filters.primary.cycle.includes(dealLeg.pipelineCycle))
      return newDealInfo;
    } 
    ).filter(dealInfo => dealInfo.dealLegs?.length > 0);


    }

    // to update deal header quantities
    filteredData = updateDealsFullfilledAndTotalQty(filteredData);

    return filteredData;

}

function updateDealsFullfilledAndTotalQty (filteredData:any){
  filteredData= filteredData.map((dealInfo:any) =>{
    const newDealInfo = {...dealInfo};
    const [dealFullfilledQuantity,dealTotalQuantity] = calculateDealFullFilledAndTotalQty(newDealInfo);
    newDealInfo["udmDealFullfilledQuantity"] = dealFullfilledQuantity;
    newDealInfo["udmDealTotalQuantity"] = dealTotalQuantity;
    return newDealInfo;
  });
  return filteredData;
}

export function findFilteredDealsContractTypeFullfilledAndTotalQty (filteredData:any) {
  //buy
  const buyContractTypeDeals = filteredData?.filter((data:any)=>data.udmDealContractType?.toLowerCase() === 'buy');
  let buyContractTypeData= buyContractTypeDeals.map((dealInfo:any) =>{
    const newDealInfo = {...dealInfo};
    const [dealFullfilledQuantity,dealTotalQuantity] = calculateDealFullFilledAndTotalQty(newDealInfo);
    newDealInfo["buyContractTypeFullfilledQuantity"] = dealFullfilledQuantity;
    newDealInfo["buyContractTypeTotalQuantity"] = dealTotalQuantity;
    return newDealInfo;
  });
  // To calculate total buy fullfilled quantity for filtered deals
  let buyContractTypeDataFullFilledQty = buyContractTypeData?.reduce((acc:any,curr:any)=>{
    acc = acc+(+curr.buyContractTypeFullfilledQuantity);
    return acc;
  },0);
  // To calculate total buy total quantity for filtered deals
  let buyContractTypeDataTotalQty = buyContractTypeData?.reduce((acc:any,curr:any)=>{
    acc = acc+(+curr.buyContractTypeTotalQuantity);
    return acc;
  },0);
  buyContractTypeDataFullFilledQty = roundToThree(buyContractTypeDataFullFilledQty);
  buyContractTypeDataTotalQty = roundToThree(buyContractTypeDataTotalQty);
  //return [buyContractTypeDataFullFilledQty,buyContractTypeDataTotalQty];

  //sell
  const sellContractTypeDeals = filteredData?.filter((data:any)=>data.udmDealContractType?.toLowerCase() === 'sell');
  const sellContractTypeData= sellContractTypeDeals.map((dealInfo:any) =>{
    const newDealInfo = {...dealInfo};
    const [dealFullfilledQuantity,dealTotalQuantity] = calculateDealFullFilledAndTotalQty(newDealInfo);
    newDealInfo["sellContractTypeFullfilledQuantity"] = dealFullfilledQuantity;
    newDealInfo["sellContractTypeTotalQuantity"] = dealTotalQuantity;
    return newDealInfo;
  });
 // To calculate total sell fullfilled quantity for filtered deals
  let sellContractTypeDataFullFilledQty = sellContractTypeData?.reduce((acc:any,curr:any)=>{
    acc = acc+(+curr.sellContractTypeFullfilledQuantity);
    return acc;
  },0);
  // To calculate total sell total quantity for filtered deals
  let sellContractTypeDataTotalQty = sellContractTypeData?.reduce((acc:any,curr:any)=>{
    acc = acc+(+curr.sellContractTypeTotalQuantity);
    return acc;
  },0);
  sellContractTypeDataFullFilledQty = roundToThree(sellContractTypeDataFullFilledQty);
  sellContractTypeDataTotalQty = roundToThree(sellContractTypeDataTotalQty);
  /*  */

  // To calculate total buy and sell fullfilled and total quantity for filtered deals
  const totalBuySellFullFilledQty = roundToThree(buyContractTypeDataFullFilledQty - sellContractTypeDataFullFilledQty);
  const totalBuySellTotalQty = roundToThree(buyContractTypeDataTotalQty - sellContractTypeDataTotalQty);
  return {
    buyContractTypeDataFullFilledQty,
    buyContractTypeDataTotalQty,
    sellContractTypeDataFullFilledQty,
    sellContractTypeDataTotalQty,
    totalBuySellFullFilledQty,
    totalBuySellTotalQty
  };
}


const cbDynamicDealsReducer = createReducer(
    initialState,
    on(loadDynamicDealSuccess, (state, { cbDynamicDeals,fromDateChange }) => {
        let storedFilters = getStoredSelectedFilters();
        const deals = setUpDynamicDealsData(cbDynamicDeals,storedFilters.primary.material.tab);
        if(fromDateChange)
        {
          storedFilters.primary.cycle = ['all'];
          localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters',JSON.stringify(storedFilters));
          
        }

        const getFilterData = filterData(deals,storedFilters,state.filters.secondary);
        
        // Apply selected sorting after transforming filtered data
        const sortedData =  sortArray(JSON.parse(JSON.stringify(getFilterData)));
        return {
        ...state,
        cbDynamicDealsResponse: deals,
        filters:  {
            primary : 
            {
            transaction: ['all', 'buy', 'sell'],
            material: {
                tsw: sortfilterValues(['all', ...new Set<string>(deals.flatMap((obj:any) => obj.dealLegs).map((l1:any) => l1.udmMaterialTSW || 'n/a'))]),
                sra: sortfilterValues(['all', ...new Set<string>(deals.flatMap((d:any) => d.dealLegs).map((l1:any) => l1.udmMaterialSRA || 'n/a'))])
            },
            cycle: sortfilterValues(['all', ...new Set<string>(deals.flatMap((obj:any) => obj?.dealLegs).map((l1:any) => l1?.pipelineCycle || 'n/a'))])
        },
        secondary: {
            exposure: ['all', 'balanced', 'over', 'under'],
            term: ['all', 'evergreen', 'spot', 'term'],
            schedulertypes: ['all', 'origin', 'destination'],
            counterParty: sortfilterValues(['all', ...new Set<string>(deals.map((l1:any) => l1.counterpartyAbbreviation || 'n/a'))]),
            location: sortfilterValues(['all', ...new Set<string>(deals.flatMap((l1:any) => l1.dealLegs).map((l2:any) => l2.locationAbbreviation || 'n/a'))]),
            indicators: sortfilterValues(['all', ...new Set<string>(indicatorFilters(''))])
            },
          cbSortOptions: ['newest','oldest','a-z','z-a'],
          cbSelectedSortType: 'newest'
         },
         selectedFilters:storedFilters,
         filteredData: JSON.parse(JSON.stringify(sortedData))
     }
    }),
    on(updateIndicatorsSuccess, (state, { payLoad,response }) => {
      let msg = '';
      if(response.includes('Not able') || response.includes('Forbidden Access'))
      {
       msg = 'error';
      }else
      {
        msg = 'success'
      }
      return{
        ...state,
        cbDynamicDealsResponse: state.cbDynamicDealsResponse.map(deal => 
          deal.dealContractId === payLoad.dealContractId
          ?{
            ...deal,
            udmIndicators : payLoad.udmIndicators
          }:deal
        ),
        filteredData: state.filteredData.map(deal => 
          deal.dealContractId === payLoad.dealContractId
          ? {
            ...deal,
            udmIndicators : payLoad.udmIndicators
          } : deal
        ),
        isIndicatorUpdateFailed:msg
      }
    }),
    on(updateIndicatorsFailure, (state) => {
      
      return{
        ...state,
        isIndicatorUpdateFailed:'error'
      }
    }),
    on(applyDDPrimaryFilter, (state, { filters }) => {
        const updatedFilters = {
            ...state.selectedFilters,
            primary: filters
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters,state.filters.secondary);

        // Apply selected sorting after transforming filtered data
        const sortedData =  sortArray(JSON.parse(JSON.stringify(filteredData)));
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData: JSON.parse(JSON.stringify(sortedData)) //filteredData
        };
    }),
    on(applyDDSecondaryFilter, (state, { filters }) => {
        const updatedFilters = {
            ...state.selectedFilters,
            secondary: filters
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters, state.filters.secondary);
        const dynamicDealsDataSetUp = setUpDynamicDealsData(filteredData,updatedFilters.primary.material.tab);
        
        // Apply selected sorting after transforming filtered data
        const sortedData =  sortArray(JSON.parse(JSON.stringify(dynamicDealsDataSetUp)));
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData: JSON.parse(JSON.stringify(sortedData)),//setUpDynamicDealsData(filteredData,updatedFilters.primary.material.tab)
        };
    }),
    on(resetDDSecondaryFilter, (state) => {
        const filters =  {
                exposure: ['all'],
                term: ['all'],
                counterParty: ['all'],
                location: ['all'],
                indicators: ['all'],
                schedulertypes:['all']
        };
        const updatedFilters =  {
           ...state.selectedFilters,
           secondary: filters
    };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters, state.filters.secondary);

        // Apply selected sorting after transforming filtered data
        const sortedData =  sortArray(JSON.parse(JSON.stringify(filteredData)));
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData: JSON.parse(JSON.stringify(sortedData)) //filteredData
        };
    }),
    on(resetAllFilters, (state) => {
        const updatedFilters =  {
            primary: {
                transaction: ['all'],
                material: {
                    tab: "tsw",
                    values: ['all']
                },
                cycle: ['all']
            },
            secondary: {
                exposure: ['all'],
                term: ['all'],
                counterParty: ['all'],
                location: ['all'],
                indicators: ['all'],
                schedulertypes:['all']
            },
            cbSortOptions: ['newest','oldest','a-z','z-a'],
            cbSelectedSortType: 'newest'
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters, state.filters.secondary);

         // Apply selected sorting after transforming filtered data
         const sortedData =  sortArray(JSON.parse(JSON.stringify(filteredData)));
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData:sortedData //filteredData
        };
    }),
    on(selectMaterialTab, (state, { tab }) => {
        const updatedFilters = {
            ...state.selectedFilters,
            primary: {
                ...state.selectedFilters.primary,
                material:{
                    ...state.selectedFilters.primary.material,
                    tab: tab,
                    values: ['all']
                }
            }
        };
        localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
        
        const filteredData = filterData(state.cbDynamicDealsResponse,updatedFilters,state.filters.secondary);
         
        // Apply selected sorting after transforming filtered data
        const sortedData =  sortArray(JSON.parse(JSON.stringify(filteredData)));
        return {
            ...state,
            selectedFilters: updatedFilters,
            filteredData: JSON.parse(JSON.stringify(sortedData)) //filterData(state.cbDynamicDealsResponse,updatedFilters)
        };
    }),
    on(toggleDealHeader, (state, { index }) => {
       const deals = state.filteredData.map((item:any,i:any) =>
        i === index ? {...item,isExpanded:!item.isExpanded }: item
       );
        return {
            ...state,
            filteredData: deals
        };
    }),
    on(updateCustomDateRangePicker, (state, { updateCustomDateRangePickerFlag }) => {
       return {
           ...state,
           updateCustomDateRangePickerFlag: updateCustomDateRangePickerFlag
       };
   }),
   on(applySelectedCBSort, (state, { cbSelectedSortType }) => {
    const updatedFilters = {
        ...state.selectedFilters,
        cbSelectedSortType: cbSelectedSortType
    };
    localStorage.setItem('desk'+getSelectedDeskId()+'selectedFilters', JSON.stringify(updatedFilters));
    // const filteredData = filterData(state.cbDynamicDealsResponse, updatedFilters);
    return {
        ...state,
        selectedFilters: {
          primary: {...state.selectedFilters.primary},
          secondary: {...state.selectedFilters.secondary},
          cbSortOptions: {...state.selectedFilters.cbSortOptions},
          cbSelectedSortType: cbSelectedSortType
        }
    };
  }),
  on(updateSortedData, (state, { data }) => {
    return {
        ...state,
        filteredData: data
    };
  }),
  
   on(updateIndicatorFilterValues, (state, { region }) => {
      return {
          ...state,
          filters : {
            ...state.filters, 
            secondary: {
                 ...state.filters.secondary,
                 indicators: sortfilterValues(['all', ...new Set<string>(indicatorFilters(region))])
            }
          }
      };
  })
);

function roundToThree(num:any) {
    if(!num)
    {
        return 0;
    }
    return +(Math.round(Number(num + "e+3"))  + "e-3");
  }

  export function formatDate(date:any) {
    if(!date)
    {
        return 'n/a';
    }
    const getFullDateInReverse = date?.split("T")[0]?.split("-");
    return `${getFullDateInReverse[1]}/${getFullDateInReverse[2]}/${getFullDateInReverse[0]}`;
  };

  function calculateDealFullFilledAndTotalQty(newdealInfo:any) {
    let dealFullfilledQuantity = newdealInfo?.dealLegs?.reduce((acc:any,curr:any)=>{
      acc = acc+(+curr.dealLegFullfilledQuantity);
      return acc;
    },0);
    // To calculate total quantity for each deal
    let dealTotalQuantity = newdealInfo?.dealLegs?.reduce((acc:any,curr:any)=>{
      acc = acc+(+curr.quantityInMB);
      return acc;
    },0);
    dealFullfilledQuantity = roundToThree(dealFullfilledQuantity);
    dealTotalQuantity = roundToThree(dealTotalQuantity);   
    return [dealFullfilledQuantity,dealTotalQuantity];
  }

  export function sortArray(array: any[],option?: string):any[] {
    const type = getStoredSelectedFilters()?.cbSelectedSortType??'newest';
    // to sort the results in ascending order for type newest or oldest as the results are getting modified while switching back from either z-a or a-z to newest and oldest
    const sortInAscOrder = array.sort((a,b)=>alphaNumericSort(a.dealNoContractNo,b.dealNoContractNo));
    switch(type) {
      case 'newest':
        return sortInAscOrder.sort((a,b)=>new Date(b.tradeDate).getTime() - new Date(a.tradeDate).getTime());
      case 'oldest':
        return sortInAscOrder.sort((a,b)=>new Date(a.tradeDate).getTime() - new Date(b.tradeDate).getTime());
      case 'a-z':
          return sortInAscOrder;
      case 'z-a':
          return array.sort((a,b)=>alphaNumericSort(b.dealNoContractNo,a.dealNoContractNo));     
      default:
        return array;
    }
  }
  
  export function alphaNumericSort(a:string,b:string):number {
    let partsA:any = a.match(/(\d+|\D+)/g);
    let partsB:any = b.match(/(\d+|\D+)/g);
  
    for(let i=0; i< Math.min(partsA?.length, partsB.length); i++) {
      let partA = partsA[i];
      let partB = partsB[i];
  
      if(!isNaN(partA) && !isNaN(partB)) {
        let diff = parseInt(partA) - parseInt(partB);
        if(diff !== 0) {
          return diff;
        }
      } else {
        if(partA !== partB) {
          return partA.localeCompare(partB);
        }
      }
    }
    return partsA.length - partsB.length;
  }

export function dynamicDealsReducer(state: State | undefined, action: Action) {
    return cbDynamicDealsReducer(state, action);
}
