import { Component, OnInit, Output, Input, HostListener, EventEmitter } from '@angular/core';
import { NominationService } from 'src/app/services/nomination.service';
import { getPipleineFilteredValues, getUserDetails, selectDesk, selectUDMScreen } from 'src/app/shared/store/selector/app.selector';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { Subject, take, takeUntil } from 'rxjs';
import { saveEthanolNomFiltersResponse, saveEthanolTRFiltersResponse, savePipelineNomFiltersResponse, savePipelineTRFiltersResponse } from 'src/app/shared/store/action/app.action';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { ToastService } from 'src/app/services/toast.service';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { TRScreen, UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { Router } from '@angular/router';
import { BCPFilters } from 'src/app/shared/interface/bcp-dashboard.interface';
import { setDefaultBCPFilters } from 'src/app/bcp-dashboard/store/action/bcp-dashboard.action';

@Component({
  selector: 'app-manage-custom-view',
  templateUrl: './manage-custom-view.component.html',
  styleUrls: ['./manage-custom-view.component.scss']
})
export class ManageCustomViewComponent implements OnInit {
  @Input('isExpanded') isExpanded!: any;
  @Input() desk!: string;
  @Input() deskParam!: string;
  filters: BCPFilters = { locationsFilter: [], productsFilter: [] ,desksFilter:[], offTakers:[] };
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDesk$ = this.store.pipe(select(selectDesk));
  @Output() customViewDetails = new EventEmitter<any>();
  userDetails: any;
  gotUserDetails: any = false;
  isViewPresentInLocal = false;
  selectedDesk: any;
  selectedDeskId : any = '';
  viewPresent: any;
  viewsList: any = [];
  viewName!: string;
  viewApplied: any;
  isDefaultView:boolean = false;
  currentScreen: any;
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen));
  initialDeskId: any = localStorage.getItem("deskId");
 

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    localStorage.removeItem("selectedView");
  }
  getPipleineFilteredValues$ = this.store.pipe(select(getPipleineFilteredValues));

  constructor(public nominationService: NominationService, private toasterService: ToastService, private appStore: Store<AppState>, private trService: ReplenishmentPlanningService, private store: Store,private utilities: Utilities, private router: Router) { }
  ngOnInit(): void {

    // getting user details
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userDetails = response.userDetails;            
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });

    //deleting the view once user confirms in modal
    this.nominationService.triggerDeleteApi$.subscribe(res => {
      let payload = res?.payload;
      if (res?.deleteView && payload.viewName && this.nominationService?.triggerDeleteflag) {
        this.nominationService.triggerDeleteflag = false;
        this.nominationService.deleteView(payload).subscribe(res => {
          if (res.statusCode === 200) {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: res.value
            });
            this.gotUserDetails = false;
            this.viewName = "";
            if(this.viewApplied || payload.viewApplied){
              localStorage.removeItem("selectedView");
              this.nominationService.triggerClearFilter$.next(true);
              this.nominationService.customViewSidePanel$.next(false);
              this.nominationService.resetToDefaultView$.next(true);
              this.nominationService?.triggerDeleteApi$?.next({ deleteView: false, payload: {} });
              this.nominationService?.triggerDelete$?.next({ deleteView: false, payload: {} });
            }
            if(this.isDefaultView || payload.isDefaultView)
            {
              localStorage.removeItem("selectedDefaultView");
            }
            this.getAllTheViews();
          }
        })
      }
    });

    this.nominationService.triggerGetApiCallAfterUpdate$.subscribe(res => {
      if(res){
        this.gotUserDetails = false;
        this.getAllTheViews();
      }
    });
    this.nominationService.getallCustomViewFromHome$.subscribe(res => {
      if (res.length) {
        this.viewsList.forEach((view: { applied: boolean; }) => {
          view.applied = false;
        });
        this.viewsList.forEach((view: {
          applied: boolean; name: string | null; isDefaultView:boolean;
        }) => {
          if (view.name === localStorage.getItem("selectedView"))
            view.applied = true;
        })
      }
    })
    this.getUDMScreen$
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response: any) => {
        this.currentScreen = response.screen; 
        this.getAllTheViews();         
      },
      error: (err: any) => {
        console.log(err);
      },
      complete: () => { },
    });

  }

 

  //api call to get all the view
  getAllTheViews() {
    this.viewPresent = localStorage.getItem("selectedView");
    let defaultViewInLocal = localStorage.getItem("selectedDefaultView");
    const screenType = this.router.url.toLowerCase().includes(TRScreen.BCPDashboard) ? TRScreen.BCPDashboard : '';
    if (!this.gotUserDetails && (this.router.url.toLowerCase().includes(TRScreen.BCPDashboard) || this.utilities.updateAPIParams() !== 'panama')) {
      this.nominationService.getAllViews({ emailId: this.userDetails?.email, desk: this.utilities.updateAPIParams() }, screenType).subscribe(res => {
       
        if (res.statusCode === 200) {
          this.viewsList = [];
          this.gotUserDetails = true;
          this.getViewList(res, defaultViewInLocal);
          this.nominationService.allCustomView$.next(this.viewsList);
        }
      })
    }
  }

  getViewList(res: any, defaultViewInLocal: any) {
    this.nominationService.viewsListSource.next([]);
    const currentValue = this.nominationService.viewsListSource.getValue();
    let newArray:any = [...currentValue];
    res.value.forEach((view: any, index:any) => {
      const viewObj:any = { name: view.viewName, applied: true , isDefaultView: view.isDefaultView ?? false};
      if (this.viewPresent && this.viewPresent === view.viewName) {
        this.viewsList = [...this.viewsList,viewObj];
      }
      else {
        viewObj.applied = false;
        this.viewsList = [...this.viewsList,viewObj];
      }
      newArray= newArray.concat(viewObj);
     
      if(defaultViewInLocal && defaultViewInLocal == view.viewName){
        this.viewsList[index].isDefaultView = true;
        newArray[index]={...newArray[index],isDefaultView:true}
      }
      else{
        this.viewsList[index].isDefaultView = false;     
        newArray[index]={...newArray[index],isDefaultView:false}       
      }
      this.nominationService.viewsListSource.next(newArray)
    })
  }

  editView(data: any) {
    const viewDetails = { emailId: this.userDetails.email, viewName: data };
    this.nominationService?.editCustomView$?.next(true);
    this.customViewDetails.emit(viewDetails);
  }

  //showing a popup on home page when clicked on delete
  showDeleteConfirmation(data: any) {
    this.nominationService?.triggerDelete$?.next({ deleteView: true, payload: data });
  }
  applyDeleteView(action: string, view: any) {
    this.viewName = view.name;
    this.viewApplied = view.applied;
    this.isDefaultView = view.isDefaultView;
    
    let payload = { emailId: this.userDetails.email, viewName: this.viewName, desk: this.utilities.updateAPIParams(), viewApplied:this.viewApplied, isDefaultView:this.isDefaultView };
      if (action === 'apply' && view.name && !view.applied) {
      this.nominationService.restrictLoader$.next(false);
      this.nominationService.applyView(payload).subscribe(res => {
        if (res.statusCode === 200) {
          this.nominationService.setSelectedView = this.viewName;
          localStorage.setItem("selectedView", this.viewName);
          this.viewsList.forEach((view: { applied: boolean; }) => {
            view.applied = false;
          });
           const viewsListObs:any = this.nominationService.viewsListSource.getValue();
          viewsListObs.forEach((view: { applied: boolean; }) => {
            view.applied = false;
          });
          let index = this.viewsList?.findIndex((view: { name: string; }) => view.name === this.viewName);
          this.viewsList[index].applied = true;
          viewsListObs[index].applied = true;
          this.nominationService.allCustomView$.next(this.viewsList);
          this.formPayloadForFilters(res.value);
        }
      })
    }
    else if (action === 'delete') {
      this.nominationService.restrictLoader$.next(true);
      this.showDeleteConfirmation(payload);
    }
  }

  defaultView(view:any)
  {
    this.viewName = view.name;    
    let payload = { emailId: this.userDetails.email, viewName: this.viewName, desk: this.utilities.updateAPIParams() };
    if(view.name)
    {
        this.nominationService.restrictLoader$.next(false);
        this.nominationService.getViewsByName(payload,payload.desk).subscribe(res => {
          if (res.statusCode === 200) {            
            res.value.customViews[0].isDefaultView = !view.isDefaultView;            
            this.updateDefaultView(res.value);            
          }
        });
    }
  }
 
  updateDefaultView(result:any)
  {
    const viewsListObs:any = this.nominationService.viewsListSource.getValue();
    const payload = {
      id: result.id,
      emailId: result.emailId.toString(),
      desk: this.utilities.updateAPIParams(),
      customView: result.customViews[0],
      screen:this.router.url.toLowerCase().includes(TRScreen.BCPDashboard) ? TRScreen.BCPDashboard : ''
    };
 
    this.nominationService.updateCustomView(payload).subscribe({
      next: data => {        
        this.viewsList.forEach((view: { applied: boolean; isDefaultView : boolean }) => {
          view.isDefaultView = false;
        });
        viewsListObs.forEach((view: { applied: boolean; isDefaultView : boolean }) => {
          view.isDefaultView = false;
        });
        let index = this.viewsList?.findIndex((view: { name: string; }) => view.name === this.viewName);
        if(result.customViews[0].isDefaultView)
          localStorage.setItem("selectedDefaultView", payload.customView.viewName);
        else
          localStorage.removeItem("selectedDefaultView");
        this.viewsList[index].isDefaultView = result.customViews[0].isDefaultView;
        viewsListObs[index].isDefaultView = result.customViews[0].isDefaultView;
        this.nominationService.allCustomView$.next(this.viewsList);
        
      }, error: err => {
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message
        });
      }
    });
  }

  formPayloadForFilters(res: any) {
    if(!this.router.url.toLowerCase().includes('bcpdashboard')){
      if (res.desk.startsWith("ethanol")) {
        this.customViewEthanolApply(res);
      } else if (res.desk.startsWith("clean products") || res.desk.startsWith("pipeline")) {
        this.customPipelineViewApply(res);
      }
    }else{
       this.customViewBcpApply(res);
    }
  }

  customPipelineViewApply(res: any) {
    let filterList: any[] = [], sDate, eDate;
    let rpTerminal: any[] = [];
    let { modeOfTransport, transportSystem, product, terminal } = res.customViews[0].pipelineFilters;
    if (res.customViews[0].pipelineFilters.viewType) {

      filterList.push({ headerName: "view type", list: [...res.customViews[0].pipelineFilters.viewType] });
    }
    if (res.customViews[0].pipelineFilters.modeOfTransport && (this.desk === UDMDesk?.PipelineUsecFloridaDesk || this.desk === UDMDesk?.PipelineUsecPascagoulaDesk || this.desk === UDMDesk?.PipelineUsecRichmondDesk || this.desk === UDMDesk?.PipelineUswcPNWDesk)) {
      filterList.push({ headerName: "mode of transport", list: [...modeOfTransport] });
    }
    if (res.customViews[0].pipelineFilters.transportSystemStatus) {
      filterList.push({ headerName: "transport system", list: [...transportSystem] });
    }
    else {
      transportSystem.forEach((transportSystem: any) => {
        transportSystem.checked = true;
      });
      filterList.push({ headerName: "transport system", list: [...transportSystem] });
    }

    /* Fetching existing filter values from state for finding -x locations */
    let selectedPipelineFilterValues:any;
    this.getPipleineFilteredValues$.pipe(take(1)).subscribe({
      next: (response: any) => {
        selectedPipelineFilterValues = response.value??[];
      },
      error: (err: any) => { },
      complete: () => { }
    });
    if(Object.keys(selectedPipelineFilterValues)?.length){
      const existingTerminalsWithDashXloc = selectedPipelineFilterValues.terminal;
      const selectedViewTerminals = terminal;
      terminal = this.utilities.addExistingDashXLocToSelectedViewTerminals(existingTerminalsWithDashXloc,selectedViewTerminals);
    }

    if (res.customViews[0].pipelineFilters.terminalStatus) {
      rpTerminal = this.router.url.includes("replenishmentPlanning")? terminal.filter((i: any) => (!i?.id?.includes("-X"))):terminal;
      rpTerminal = [...new Set(rpTerminal)];
      filterList.push({ headerName: "terminal", list: [...rpTerminal] });
    }
    else {
      let rpTerminallist = []
     
      rpTerminallist = this.router.url.includes("replenishmentPlanning")? terminal.filter((i: any) => (!i?.id?.includes("-X"))):terminal;
      rpTerminal = rpTerminallist.map((terminal: any) => { terminal.checked = true; return terminal; });
      rpTerminal = [...new Set(rpTerminal)];
      filterList.push({ headerName: "terminal", list: [...rpTerminal] });
    }
    let checkedTerminal = rpTerminal.filter(i=> i.checked)
    let response = this.utilities.globalPipelineAggregatorResponse;
    let {filterObj, copyOfFilterObj} = this.utilities.getRequiredPipelineFilterJsonStructure(response, true, true) ;
    let rpProduct :any=[];

    if (res.customViews[0].pipelineFilters.productStatus) {
      product.forEach((prod: any)=>{
        filterObj?.product.forEach((e: any) => {
         if((e.productCode === prod.id && e.trackInv && checkedTerminal?.filter((terminal:any) => e.terminalCode.includes(terminal.id)).length) || (prod.name === 'all' && !rpProduct.map((val:any)=>val.name).includes('all')))
           rpProduct.push(prod)
         })
       });

      filterList.push({ headerName: "product", list: [...rpProduct] });
    }
    else {
      product.forEach((prod: any) => {
        prod.checked = true;
        filterObj?.product.forEach((e: any) => {
          if((e.productCode === prod.id && e.trackInv && checkedTerminal?.filter((terminal:any) => e.terminalCode.includes(terminal.id)).length) || (prod.name === 'all' && !rpProduct.map((val:any)=>val.name).includes('all')))
            rpProduct.push(prod)
          })
      });
      filterList.push({ headerName: "product", list: [...product] });
    }
    let currentSelectedView:any;
    if (res.customViews[0].pipelineFilters?.isTerminalToProductView === undefined || res.customViews[0].pipelineFilters?.isTerminalToProductView === null) {

      let colId = res?.customViews[0]?.pipelineFilters?.columnOrders?.replenishmentColumnOrder[1]?.colId;
      if (colId && isNaN(colId.split("_")[0])) {
        currentSelectedView = 'terminal';
      }
      else {
        currentSelectedView = 'product';
      }
    }
    else {
      currentSelectedView = res.customViews[0].pipelineFilters?.isTerminalToProductView  ? 'terminal' : 'product';
    }
    sDate = this.nominationService?.defaultFromDate;
    eDate = this.nominationService?.defaultToDate;
    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.nominationService.allowNomApiCalls$.next(true);
    this.appStore.dispatch(savePipelineTRFiltersResponse({ pipelineFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineFilterUpdated: true, pipelineCurrentSelectedView:currentSelectedView }));
    this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterList)), pipelineFromDate: sDate, pipelineToDate: eDate, isPipelineNomFilterUpdated: true }));
    this.nominationService.applyTrCoulmnOrder$.next({ colOrder: res.customViews[0].pipelineFilters?.columnOrders, colExpand: res.customViews[0].pipelineFilters?.expandedColumns });
    this.trService.applyColOrder$.next({ colOrder: res.customViews[0].pipelineFilters?.columnOrders, colExpand: res.customViews[0].pipelineFilters?.expandedColumns })
    this.nominationService.setCustomDropDownValue$.next(currentSelectedView);
  }
  
  customViewEthanolApply(res: any) {
    let filterList: any[] = [], sDate, eDate, truck_unit_option: any = 'bbl', ethanolTruckUOMSwitch: any = false;
    const { supplier, terminalOwnership, modeOfTransport, terminal, unitOfMeasure, carrier } = res.customViews[0].ethanolFilters;
    filterList.push({ headerName: "terminal ownership", list: [...terminalOwnership] })
    filterList.push({ headerName: "mode of transport", list: [...modeOfTransport] })
    filterList.push({ headerName: "unit of measure", list: [...unitOfMeasure] })
    if (res.customViews[0].ethanolFilters.terminalStatus) {
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }
    else {
      terminal.forEach((terminal: any) => {
        terminal.checked = true;
      });
      filterList.push({ headerName: "terminal", list: [...terminal] });
    }

    if (res.customViews[0].ethanolFilters.supplierStatus) {
      filterList.push({ headerName: "supplier", list: [...supplier] });
    }
    else {
      supplier.forEach((supplier: any) => {
        supplier.checked = true;
      });
      filterList.push({ headerName: "supplier", list: [...supplier] });
    }
    if (res.customViews[0].ethanolFilters.carrierStatus) {
      filterList.push({ headerName: "carrier", list: [...carrier] });
    }
    else {
      carrier.forEach((carrier: any) => {
        carrier.checked = true;
      });
      filterList.push({ headerName: "carrier", list: [...carrier] });
    }

    sDate = this.nominationService?.defaultFromDate;
    eDate = this.nominationService?.defaultToDate;
    this.nominationService.setFilterDateRange$.next({ fromDate: sDate, toDate: eDate });
    this.nominationService.allowNomApiCalls$.next(true);
    this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(filterList)), ethanolFromDate: sDate, ethanolToDate: eDate, isfilterUpdated: true, ethanolTruckToggle: ethanolTruckUOMSwitch, unitOfMeasure: truck_unit_option }));
    this.appStore.dispatch(saveEthanolNomFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(filterList)), ethanolFromDate: sDate, ethanolToDate: eDate, isNomFilterUpdated: true }));
    this.nominationService.applyTrCoulmnOrder$.next({ colOrder: res.customViews[0].ethanolFilters?.columnOrders, colExpand: res.customViews[0].ethanolFilters?.expandedColumns });
    this.trService.applyColOrder$.next({ colOrder: res.customViews[0].ethanolFilters?.columnOrders, colExpand: res.customViews[0].ethanolFilters?.expandedColumns })
  }

  customViewBcpApply(res: any) {
    this.filters = { locationsFilter: [], productsFilter: [] ,desksFilter:[], offTakers:[] };
      const { multiDesks, locations, products,offTakers} = res.customViews[0]?.bcpFilters;
     
      if(res.customViews[0]?.bcpFilters!.multiDeskStatus)
        this.filters.desksFilter = [...multiDesks]
      else
      {
        multiDesks.forEach((desk:any) => { desk.checked = true; });        
        this.filters.desksFilter = [...multiDesks];
      }
      if(this.router.url.toLowerCase().includes("panama"))
      {
          this.filters.offTakers = [...offTakers];
      }

      if(res.customViews[0]?.bcpFilters!.locationStatus)
      {
          this.filters.locationsFilter = [...locations];
      }
      else
      {
          locations.forEach((location:any)=> { location.checked = true; });
          this.filters.locationsFilter = [...locations];
      }

      if(res.customViews[0]?.bcpFilters!.productStatus)
      {
          this.filters.productsFilter = [...products];
      }
      else
      {
          products.forEach((product:any)=> { product.checked = true; });
          this.filters.productsFilter = [...products];
      }
      this.store.dispatch(setDefaultBCPFilters({ BCPFilters: JSON.parse(JSON.stringify(this.filters)), updateBCPSummaryTable:true }));    
      this.nominationService.applyBcpCoulmnOrder$.next({ colOrder: res.customViews[0].bcpFilters?.columnOrders});   
      this.nominationService.applyEditedViewToUi$.next(res.customViews[0].viewName)   
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
