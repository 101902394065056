<label class="label">Port/Terminal</label>

@if (!hasData) {
  <div>
    There are no changes to display.
  </div>
}

@if (hasData) {
  <div class="d-flex flex-row justify-content-left overflowScroll">
    @for (header of headerNavigation; track header; let i = $index) {
      <p (click)="updateSelection(i)"
        class="m-0 mt-1 mr-1 ml-1 header-size"
        [ngClass]="this.selectedTabIndex == i ? 'tab-button-selected': 'tab-button'">
        {{header.displayname}}
      </p>
    }
  </div>
}
<hr class="seletion-line m-0 mb-2">
@if (this.activityTrackerFormedData != null) {
  <div>
    @for (activityTrackerData of getPortDeterminalData(); track activityTrackerData) {
      <div>
        @for (selection of activityTrackerData.activityTrackerData; track selection) {
          <hide-list class="margin-bottom mt-2" [hideList]="false">
            <gray-header header [displayText]="selection.displayName" [arrowVisible]="true" [flipArrow]="false">   </gray-header>
            <div content>
              <div class="blueRectangle mt-1">
                <p class="m-0 ml-3 optionName">{{selection.optionalName}}</p>
              </div>
              <div class="totalWidth mt-3 d-flex flex-row">
                @for (card of selection.groupTypechanges; track card) {
                  <expand-card [hasContent]="card.changes.length > 0"
                    class="m-1">
                    <at-card-front [cardInfo]="card" front></at-card-front>
                    <activity-table [tableTitle]="card.displayname" [rowData]="card.changes" back></activity-table>
                  </expand-card>
                }
              </div>
            </div>
          </hide-list>
        }
      </div>
    }
  </div>
}