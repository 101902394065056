<div class="dropdown">
  <button class="dropdown-button" #toggleButton [ngClass]="{'active': true}" (click)="displayMenu($event)">
    @if (!selectedDataContent) {
      <span class="ml-1">{{SelectedDesk}}</span>
    }
    @if (selectedDataContent) {
      <span class="ml-1">
        <ng-container [ngTemplateOutlet]="selectedDataContent"></ng-container>
      </span>
    }

  </button>
  <div class="dropdown-menu-wrap">
    @if (displayMenuItems) {
      <div class="dropdown-menu" #menu>
        @for (desk of deskList; track desk; let i = $index) {
          <div>
            @if (desk.items && desk.items.length > 0) {
              <div>
                <span class="dropdown-text pl-3 option-item">{{desk.name}}</span>
                @for (item of desk.items; track item) {
                  <div [ngClass]="item.subOptions && item.subOptions.length > 0 ? 'parent': ''"
                    class="branded radio-btn p-0 m-0">
                    <div class="d-flex justify-content-between sub-menu-link" [class.disable-option]="item.name === 'ecca' || item.name === 'wcca'">
                      <button class="dropdown-item button-text pl-3"
                        (click)="!(item.subOptions && item.subOptions.length > 0) && updateSelectedDesk($event, item)" [disabled]="item.name === 'ecca' || item.name === 'wcca'">{{item.name}}
                      </button>
                      @if (item.subOptions && item.subOptions.length > 0) {
                        <img src="../../../assets/icons/chevron_right.svg" alt="right icon" class="right-icon"
                          >
                      }
                    </div>
                    @if (item.subOptions && item.subOptions.length > 0) {
                      <div class="dropdown-menu sub-menu" #subMenu>
                        @for (subItem of item.subOptions; track subItem) {
                          <div>
                            <div>
                              <div class="branded radio-btn p-0 m-0">
                                <button class="dropdown-item button-text pl-3 w-100"
                                (click)="updateSelectedDesk($event, subItem)">{{subItem.name}}</button>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                <div class="dropdown-divider"></div>
              </div>
            } @else {
              <div class="branded radio-btn p-0 m-0">
                @if (!desk.dataContent) {
                  <button class="dropdown-item button-text pl-3" [disabled]="desk.disable"
                  (click)="updateSelectedDesk($event, desk)">{{desk.name}}</button>
                }
                @if (desk.dataContent) {
                  <button class="dropdown-item button-text pl-3"
                    [class.dropdown-contetnt-element]="'dropdown-contetnt-element'"
                    (click)="updateSelectedDesk($event, desk)">
                    <ng-container [ngTemplateOutlet]="desk.dataContent"></ng-container>
                  </button>
                }
                <div class="dropdown-divider my-1"></div>
              </div>
            }
          </div>
        }
      </div>
    }
  </div>

</div>