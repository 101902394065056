@if (headerName == 'teams') {
  <mat-checkbox [(ngModel)]="teamsBool" (change)="onCheckboxClick(params)"
    matTooltip="check if you want to see notifications in your teams app, uncheck if you don't"
  [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"></mat-checkbox>
}
@if (headerName == 'workspace') {
  <mat-checkbox [(ngModel)]="workspaceBool" (change)="onCheckboxClick(params)"
    matTooltip="check if you want to see notifications in your workspace, uncheck if you don't"
  [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"></mat-checkbox>
}
@if (headerName == 'email') {
  <mat-checkbox [(ngModel)]="emailBool" (change)="onCheckboxClick(params)"
    matTooltip="check if you want to see notifications in your email, uncheck if you don't"
  [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'"></mat-checkbox>
}