@if (showSpinner) {
  <app-np-loader></app-np-loader>
}
<div class="main-container d-flex flex-row justify-content-center">
  <div class="activity-container d-flex flex-column">
    <div class="d-flex align-items-center">
      <h1 class="title-text">@if (isMultipleView) {
        <span>multi-nomination </span>
      }activity tracker - {{this.selectedNomination.nominationNo}}</h1>
    </div>
    @if (isMultipleView) {
      <div class="d-flex flex-column mb-2">
        <label class="m-0 label-2">View Nomination</label>
        <select class="form-control wide-dropdown" [(ngModel)]="selectedNomination" (change)="updateViewActivityNomination()">
          @for (option of nominationDataSet; track option; let i = $index) {
            <option [ngValue]="option">
              @if (option.nominationKey != null && option.nominationKeyItem != null) {
                <span>{{option.nominationKey}}-{{option.nominationKeyItem}}</span>
              }
              @if (option.nominationKey != null && option.nominationKeyItem == null) {
                <span>{{option.nominationKey}}- missing key item value</span>
              }
              @if (option.nominationKey == null && option.nominationKeyItem == null) {
                <span> Line {{i}} (Missing nom key and key item)</span>
              }
            </option>
          }
        </select>
      </div>
    }
    <div class="d-flex">
      @for (header of tooltipHeader; track header; let i = $index) {
        <button (click)="updateDropdown(header, i)"
          [ngClass]="this.selectedHeaderIndex == i ? 'header-button-selected': 'header-button'">
        {{header}}</button>
      }
    </div>
    <hr class="m-0 gray-color mb-3">

    <div class="dropdown mb-1">
      <select class="form-control" [(ngModel)]="selectedOption" (change)="updateRowData()">
        @for (option of this.selectedDropdown; track option) {
          <option [ngValue]="option">{{option.displayName}}</option>
        }
      </select>
    </div>
    <gray-header [displayText]="selectedOption.displayName + ' Changes'" [arrowVisible]="false"></gray-header>
    <activity-table [selectedOption]="selectedOption" [rowData]="rowData"></activity-table>
    @if (true) {
      <div>
        <hr>
          <p class="title-text">Comments</p>
          <p>Coming soon!</p>
        </div>
      }
    </div>
  </div>