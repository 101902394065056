import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, catchError, map, Observable, shareReplay, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { selectPipelineNomDateDetails } from '../shared/store/selector/app.selector';
import { AppState } from '../shared/store/reducer/app.reducer';
import { Store } from '@ngrx/store';
import { nomPopSpecific } from '../shared/constants/terminal-replenishment.constant';
import { latamDesks } from '../shared/constants/cca-dummy.constatnt';
import { reInitiateFiltersForCCANomLinking, saveCargoFiltersCCA, saveEthanolNomFiltersResponse, savelinkedNomFiltersResponse, setSelectedDesk } from '../shared/store/action/app.action';
import { Router } from '@angular/router';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

@Injectable({
  providedIn: 'root',
})
export class NominationService {
  public enableHeaderButton$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public enableLinkToNomButton$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public triggerClearFilter$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public applyDefaultCustomView$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public enableNominationsSaveButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sidePanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public sidePanelData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public ethanolSidePanelData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public clearFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public getEthanolNominationListPayload!: any;
  public motCheck$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public nomSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public isSameRowSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSelectedPublished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public restrictNomApiGetCall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public restrictUpdateFromTRApiCall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public restrictUpdateNomApiCall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public customViewSidePanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userDetails$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public selectedNom$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public goToNominationData$: BehaviorSubject<any> = new BehaviorSubject<any>({ isTrue: false, data: null });
  public getPipelineNominationListPayload!: any;
  public pipelineSidePanelData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public editCustomView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public createCustomView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public manageCustomView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isModalPopupOpen$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public triggerDelete$: BehaviorSubject<any> = new BehaviorSubject<any>({ deleteView: false, payload: {} });
  public triggerDeleteApi$: BehaviorSubject<any> = new BehaviorSubject<any>({ deleteView: false, payload: {} });
  public triggerDeleteflag: boolean = false;
  public allCustomView$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public triggerApplyView$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public setFilterDateRange$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public applyTrCoulmnOrder$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public applyBcpCoulmnOrder$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public applyCustomView$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public allowNomApiCalls$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public getallCustomViewFromHome$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public triggerGetAllFromCreateView$: BehaviorSubject<any> = new BehaviorSubject<any>({ isTrigger: false, selectedView: null });
  public resetToDefaultView$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public triggerGetApiCallAfterUpdate$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public updatedFIlterValuesFromView$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public updateViewNameForStateManagement$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public applyEditedViewToUi$: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public getTruckBBLs$: BehaviorSubject<string> = new BehaviorSubject<string>("bbl190Truck");
  public getTerminalNominationAFAPI$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public restrictLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public setSelectedView!: any;
  public triggerUpdateViewname: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public isCargoScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public triggerMasterCargoAPI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public triggerDeselectForCargo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isNominationForCP = true;
  public cpFilters: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public isCpGridSelected: any;
  public isNominationScreen: string = "nomination";
  public createNominationForPipelineActuation: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public nomDefaultSetCustomView: any = {};
  public isTriggeredFromNomModalPopup$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public isTriggeredFromNomModalCCAPopup$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public getSearchNominationAFAPI$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public isFilterScreen: string = "Filter";
  public isTriggeredFromEthanolNomModalPopup$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public defaultFromDate: any;
  public defaultToDate: any;
  public isTRSaveDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public ccaGridNonEditable: boolean = true;
  public linkNomData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public passLinkNomId$: any = new Subject<any>();
  public linkCargoData: any = [];
  public cargoCCAFilterOnFirstLoad: any = {};
  public setInitalCargoCCAFilterValues: boolean = true;
  getCargoFilterResponse: any;
  public ccaFilters: any = {};
  public ccaDefaultFilters: any = {};
  public routingRestrictionFromDetailsPage = true;
  viewsListSource = new BehaviorSubject([]);
  viewsList$: any = this.viewsListSource.asObservable();
  public isCCANomModalOpen$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public ccaEditNomination: any = false;
  public panamaCCAFilters: any
  public applyPanamaCCAFilters$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public popUpMsg = new BehaviorSubject<string>("");
  currentPopupMsg = this.popUpMsg.asObservable();
  public delinkMsg = new BehaviorSubject<string>("");
  delinkPopupMsg = this.delinkMsg.asObservable();
  public setCustomDropDownValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public isNomEditorRefCca: any = false;

  constructor(private apiService: ApiService, private appStore: Store<AppState>, private router: Router, private store: Store) { }

  popupAction(action: string) {
    this.popUpMsg.next(action);
  }

  delinkAction(action: string){
    this.delinkMsg.next(action);
  }
  
  getFilterNominations(postData: any) {
    return this.apiService
      .post(
        `${environment.nominationAPI}/GetTerminalNominationAF`, 'nomination', postData
      )
      .pipe(shareReplay());
  }
  getAllNominations(requestBody: any) {
    return this.apiService.get(`${environment.nominationAPI}/SearchNominations?${requestBody.dropdown}=${requestBody.no}&deskFilter=pipeline`, 'nomination').pipe(map((response) => { return response }));
  }
  getNominationByFields(requestBody: any) :Observable<any> {
    let payload = {
      "logicalOperator": "AND",
      "conditions": [
          {
              "field": "headerToItemNav.locationId",
              "operator": "eq",
              "value": requestBody.location
          },
          {
            "field": "headerToItemNav.scheduledDate",
            "operator": "eq",
            "value": requestBody.sDate
          },
          {
          "field": "headerToItemNav.scheduledMaterial",
          "operator": "ne", //ne
          "value": requestBody.smaterial
          },
          {
          "field": "headerToItemNav.tankCode",
          "operator": "eq",
          "value": requestBody.stank
          },
          {
          "field": "headerToItemNav.scheduleType",
          "operator": "eq",
          "value": requestBody.sState
          },
          ]
      }
    return this.apiService
      .post(`${environment.nominationAPI}/SearchNominationsByFields`, 'nomination', payload) 
      .pipe(
        (map((response) => { return response })),
      )
  }
  
  updateNomination(updatedValues: any) {
    this.sidePanel$.next(false);
    this.restrictUpdateNomApiCall$.next(false);
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.patch(`${environment.nominationAPI}/UpdateNominations`, 'nomination', updatedValues).pipe(map((response) => { return response }));
  }

  updateNominationFromTRScreen(updatedValues: any) {
    this.sidePanel$.next(false);
    this.restrictUpdateFromTRApiCall$.next(false);
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/UpdateNominationsFromTR`, 'nomination', updatedValues).pipe(map((response) => { return response }));
  }


  createTerminalNomination(nomination: any) {
    this.sidePanel$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/CreateNomination`, 'nomination', nomination).pipe(map((response) => { return response }));
  }

  createPipelineNomination(nomination: any) {
    this.sidePanel$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/CreateNomination`, 'nomination', nomination).pipe(map((response) => { return response }));
  }

  createCcaNomination(nomination: any) {
    this.sidePanel$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/CreateNomination`, 'nomination', nomination).pipe(map((response) => { return response }));
  }

  createCargo(nomination: any) {
    return this.apiService.post(`${environment.cargoApi}/CreateCargoPlan`, 'cargo', nomination).pipe(map((response) => { return response }));
  }

  createCargoCca(cargoPlan: any) {
    return this.apiService.post(`${environment.ccaCargoApi}/CreateCcaCargoPlan`, 'cargoPlanning', cargoPlan).pipe(map((response) => { return response }));
  }
  publishOrdeleteNomination(requestBody: any) {
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/UpdateNominationOnActions`, 'nomination', requestBody).pipe(map((response) => { return response }));
  }

  getNominationByNominationNumber(nomNumber: any, region: any, deskType: any = '') {
    const deskParam = localStorage.getItem("deskParam");
    const desk = localStorage.getItem("desk");
    let selectedDesk;
    if (desk?.includes("ethanol")) {
      selectedDesk = deskParam;
    } else if (deskParam !== "null") {
      selectedDesk = `pl${deskParam}${region}`;
    } else {
      selectedDesk = region?.includes("=") ? region.split("=")[1] : region;
    }
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.get(`${environment.nominationAPI}/GetNominationDetailsByNomGrouping?nomId=${nomNumber}&desk=${selectedDesk}`, 'nomination')
      .pipe(map((data) => {
        return data;
      }))
  }

  getNominationByNominationNumberPostCall(nomNumber: any, region: any, appliedFilter: any) {
    const deskParam = localStorage.getItem("deskParam");
    const desk = localStorage.getItem("desk");
    let selectedDesk;
    if (desk?.includes("ethanol")) {
      selectedDesk = deskParam;
    } else if (deskParam !== "null") {
      selectedDesk = `pl${deskParam}${region}`;
    } else {
      selectedDesk = region?.includes("=") ? region.split("=")[1] : region;
    }
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    let payload = {
      nomId: nomNumber,
      desk: selectedDesk,
      masterData: appliedFilter.masterData
    }
    let url = `${environment.nominationAPI}/GetNominationDetailsByNomGrouping`
    return this.apiService.post(url, 'nomination', payload)
      .pipe(map((data) => {
        return data;
      }))
  }

  getNominationsRefData(requestBody: any[]) {
    return this.apiService.post(`${environment.nominationAPI}/GetNominationReferenceData`, 'nomination', requestBody).pipe((map((response) => { return response })));
  }

  getTankCodes(payload: any) {
    return this.apiService.post(`${environment.nominationAPI}/GetTankAssignedByLocation`, 'nomination', payload).pipe((map((response) => { 
    return response })));
  }

  getTRNominationsList(data: any) {
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/GetTRNominationSidePanel`, 'nomination', data[0]['payload']).pipe(map((response) => { return response }));
  }

  getEthanolNominationData() {
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.get(`${environment.nominationAPI}/GetNominations?transportSystem=HN_MAR`, 'nomination').pipe(map((response) => { return response }));
  }

  getSapContracts(locationId: any, materialCode: any, scheduleDate: any) {
    return this.apiService.get(`${environment.nominationAPI}/GetSupplyContracts?GeographicalAreaCode=${locationId}&MaterialCode=${materialCode}&ScheduledDate=${scheduleDate}`, 'nomination').pipe(map((response) => { return response }));
  }

  getVendorNotificationsData(requestBody: any) {
    return this.apiService.post(`${environment.nominationAPI}/GetVendorNotifications`, 'nomination', requestBody).pipe((map((response) => { return response })));
  }
  triggerEmail(requestBody: any[]) {
    return this.apiService.post(`${environment.nominationAPI}/SendEmail`, 'nomination', requestBody).pipe((map((response) => { return response })));
  }
  applyView(requestBody: any) {
    return this.apiService.get(`${environment.stateManagementApi}/GetViewByName?emailId=${requestBody.emailId}&viewName=${encodeURIComponent(requestBody.viewName)}&desk=${requestBody.desk}`, 'stateManagement', requestBody).pipe(map((response) => { return response }));
  }
  deleteView(requestBody: any) {
    return this.apiService.get(`${environment.stateManagementApi}/DeleteCustomView?emailId=${requestBody.emailId}&viewName=${encodeURIComponent(requestBody.viewName)}&desk=${requestBody.desk}`, 'stateManagement').pipe(map((response) => { return response }));
  }
  getAllViews(requestBody: any, screen: string = '') {
    return this.apiService.get(`${environment.stateManagementApi}/GetNamesOfAllViews?emailId=${requestBody.emailId}&desk=${requestBody.desk}&screen=${screen}`, 'stateManagement').pipe(shareReplay());
  }
  createCustomViews(requestBody: any) {
    return this.apiService.post(`${environment.stateManagementApi}/CreateCustomView`, 'stateManagement', requestBody).pipe(map((response) => { return response }));
  }
  getViewsByName(requestBody: any, selectedDesk?: any) {
    return this.apiService.get(`${environment.stateManagementApi}/GetViewByName?EmailId=${requestBody.emailId}&ViewName=${encodeURIComponent(requestBody.viewName)}&desk=${selectedDesk}`, 'stateManagement').pipe(map((response) => { return response }));
  }
  getCarrierMasterDateForCargo(requestBody: any) {
    return this.apiService.get(`${environment.nominationAPI}/GetCarrierDetailsByMOT?desk=${requestBody.desk}&mot=${requestBody.mot}`, 'nomination').pipe(map((response) => { return response }));
  }

  updateCustomView(requestBody: any) {
    return this.apiService.put(`${environment.stateManagementApi}/UpdateCustomView`, 'stateManagement', requestBody).pipe(map((response) => { return response }));
  }
  vesselScheduleUpdate(requestBody: any) {
    return this.apiService.post(`${environment.nominationAPI}/VesselScheduleUpdate`, 'nomination', requestBody).pipe((map((response) => { return response })));
  }
  getNominationByUdmGroupingIdSearch(nomId: any, udmGroupingId: any, region: any, deskID: any, masterData?: any,) {
    if (masterData) {
      return this.getNominationByUdmGroupingIdPostCall({ nomId, udmgroupingid: udmGroupingId, desk: region, masterData: masterData })
    } else {
      const deskParam = nomPopSpecific[deskID]
      let selectedDesk;
      if (deskParam !== "null") {
        selectedDesk = `pl${deskParam}${region}`;
      } else {
        selectedDesk = region?.includes("=") ? region.split("=")[1] : region;
      }
      this.customViewSidePanel$.next(false);
      this.createCustomView$.next(false);
      this.manageCustomView$.next(false);
      let url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping?nomId=${nomId}&desk=${selectedDesk}`;
      if (udmGroupingId && udmGroupingId !== '' && udmGroupingId !== "null") {
        url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping?nomId=${nomId}&udmgroupingid=${udmGroupingId}&desk=${selectedDesk}`;
      }
      return this.apiService.get(url, 'nomination')
        .pipe(map((data) => {
          return data;
        }))
    }
  }

  getNominationByUdmGroupingId(nomId: any, udmGroupingId: any, region: any, masterData?: any,) {
    if (masterData) {
      return this.getNominationByUdmGroupingIdPostCall({ nomId, udmgroupingid: udmGroupingId, desk: region, masterData: masterData })
    } else {
      const deskParam = localStorage.getItem("deskParam");
      let selectedDesk;
      if (deskParam !== "null") {
        selectedDesk = `${deskParam}`;
      } else {
        selectedDesk = region?.includes("=") ? region.split("=")[1] : region;
      }
      this.customViewSidePanel$.next(false);
      this.createCustomView$.next(false);
      this.manageCustomView$.next(false);
      let url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping?nomId=${nomId}&desk=${selectedDesk}`;
      if (udmGroupingId && udmGroupingId !== '' && udmGroupingId !== "null") {
        url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping?nomId=${nomId}&udmgroupingid=${udmGroupingId}&desk=${selectedDesk}`;
      }
      return this.apiService.get(url, 'nomination')
        .pipe(map((data) => {
          return data;
        }))
    }
  }

  // getNominationByUdmGroupingIdforSearch(nomId: any, udmGroupingId: any, region: any, deskParamRes?: any,  masterData?: any,) {
  //   if (masterData) {
  //     return this.getNominationByUdmGroupingIdPostCall({ nomId, udmgroupingid: udmGroupingId, desk: region, masterData: masterData })
  //   } else {
  //     const deskParam = deskParamRes;
  //     let selectedDesk;
  //     if (deskParam !== "null") {
  //       selectedDesk = `pl${deskParam}${region}`;
  //     } else {
  //       selectedDesk = region?.includes("=") ? region.split("=")[1] : region;
  //     }
  //     this.customViewSidePanel$.next(false);
  //     this.createCustomView$.next(false);
  //     this.manageCustomView$.next(false);
  //     let url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping?nomId=${nomId}&desk=${selectedDesk}`;
  //     if (udmGroupingId && udmGroupingId !== '' && udmGroupingId !== "null") {
  //       url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping?nomId=${nomId}&udmgroupingid=${udmGroupingId}&desk=${selectedDesk}`;
  //     }
  //     return this.apiService.get(url, 'nomination')
  //       .pipe(map((data) => {
  //         return data;
  //       }))
  //   }
  // }
  getNominationByUdmGroupingIdPostCallSearch(udmGroupingPayload: any, desk: any) {
    const deskParam = nomPopSpecific[desk];
    if (deskParam !== "null") {
      udmGroupingPayload.desk = `pl${deskParam}${udmGroupingPayload.desk}`;
    } else {
      udmGroupingPayload.desk = udmGroupingPayload.desk?.includes("=") ? udmGroupingPayload.desk.split("=")[1] : udmGroupingPayload.desk;
    }
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    let url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping`
    return this.apiService.post(url, 'nomination', udmGroupingPayload)
      .pipe(map((data) => {
        return data;
      }))
  }
  getNominationByUdmGroupingIdPostCall(udmGroupingPayload: any) {
    const deskParam = localStorage.getItem("deskParam");
    let selectedDesk;
    if (deskParam !== "null") {
      udmGroupingPayload.desk = `pl${deskParam}${udmGroupingPayload.desk}`;
    } else {
      udmGroupingPayload.desk = udmGroupingPayload.desk?.includes("=") ? udmGroupingPayload.desk.split("=")[1] : udmGroupingPayload.desk;
    }
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    let url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping`
    return this.apiService.post(url, 'nomination', udmGroupingPayload)
      .pipe(map((data) => {
        return data;
      }))
  }

  // getNominationByUdmGroupingIdPostSearchCall(udmGroupingPayload: any, deskParam: any) {
  //   let selectedDesk;
  //   if (deskParam !== "null") {
  //     udmGroupingPayload.desk = `pl${deskParam}${udmGroupingPayload.desk}`;
  //   } else {
  //     udmGroupingPayload.desk = udmGroupingPayload.desk?.includes("=") ? udmGroupingPayload.desk.split("=")[1] : udmGroupingPayload.desk;
  //   }
  //   this.customViewSidePanel$.next(false);
  //   this.createCustomView$.next(false);
  //   this.manageCustomView$.next(false);
  //   let url = `${environment.nominationAPI}/GetNominationDetailsByUdmGrouping`
  //   return this.apiService.post(url, 'nomination', udmGroupingPayload)
  //     .pipe(map((data) => {
  //       return data;
  //     }))
  // }
  // For pipeline non cargo desks
  getNominationByNominationNo(nomId: any, nominationNo: any, region: any, udmGrouplingPayload: any) {
    let selectedfromDate = "";
    let selectedtoDate = "";
    this.appStore.select(selectPipelineNomDateDetails).subscribe(state => selectedfromDate = state.pipelineFromDate);
    this.appStore.select(selectPipelineNomDateDetails).subscribe(state => selectedtoDate = state.pipelineToDate);
    const deskParam = localStorage.getItem("deskParam");
    let selectedDesk;
    if (deskParam !== "null") {
      selectedDesk = `pl${deskParam}${region}`;
    } else {
      selectedDesk = region?.includes("=") ? region.split("=")[1] : region;
    }
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    // For ethanol CB dynamic deals and dynamic deal detail popout page to pass correct from and to dates as few transport systems are being missed while opening edit/create from ref nom modal popup
    if(udmGrouplingPayload?.selectedFromDateCB && udmGrouplingPayload?.selectedToDateCB){
      selectedfromDate= udmGrouplingPayload?.selectedFromDateCB;
      selectedtoDate= udmGrouplingPayload?.selectedToDateCB;
    }
   const requestBody:any = {
      nominationno: nominationNo,
      fromDate: selectedfromDate,
      toDate: selectedtoDate,
      nomid: nomId,
      desk: selectedDesk,
      masterData: udmGrouplingPayload.masterData,
      screenName: (udmGrouplingPayload.desk === 'ecca' || udmGrouplingPayload.desk === 'wcca') && this.router.url === '/replenishmentPlanning' ? 'TR' : undefined,
    };
    let url = `${environment.nominationAPI}/GetNominationDetailsByNominationNo`;
    return this.apiService.post(url, 'nomination', requestBody)
      .pipe(map((data) => {
        return data;
      }));
  }


  getNominationByUdmGroupingNo(params: any) {
    let param = JSON.parse(JSON.stringify(params));
    const deskParam = localStorage.getItem("deskParam");
    if (deskParam !== "null" && (!params?.desk?.includes('panama') || params?.desk?.includes('refinery'))) {
      param.desk = `pl${deskParam}${params.desk}`;
    }
    let selectedfromDate = "";
    let selectedtoDate = "";
    this.appStore.select(selectPipelineNomDateDetails).subscribe(state => selectedfromDate = state.pipelineFromDate);
    this.appStore.select(selectPipelineNomDateDetails).subscribe(state => selectedtoDate = state.pipelineToDate);
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    const requestBody: any = {
      ...param,
      fromDate: selectedfromDate,
      toDate: selectedtoDate,
      screenName: (params?.desk === 'ecca' || params?.desk === 'wcca') && this.router.url === '/replenishmentPlanning' ? 'TR' : undefined,
    };
    let url = `${environment.nominationAPI}/GetNominationDetailsByNominationNo`;
    return this.apiService.post(url, 'nomination', requestBody)
      .pipe(map((data) => {
        return data;
      }))
  }

  getTransloadSplitById(payload: any) {
    this.customViewSidePanel$.next(false);
    this.createCustomView$.next(false);
    this.manageCustomView$.next(false);
    return this.apiService.post(`${environment.nominationAPI}/GetTransloadSpitDetails`, 'nomination', payload)
      .pipe(map((data) => {
        return data;
      }))
  }


  navigateToNom(data: any, routeUrl: string, routeDesk: any, returnDeskId: string = '') {
    let deskObj = latamDesks.find((res: any) => { return (res.region === routeDesk?.toString() || res.deskId === routeDesk?.toString()) });
    let cargoData = { ...data, returnDeskId: returnDeskId };
    let screenName = 'cargoPlanning'
    this.ccaGridNonEditable = false;

    if (routeUrl === '/nominations') {
      screenName = 'nominations';
      this.linkNomData$.next([cargoData]);
    }
    const navigateToDesk: any = {
      deskValue: deskObj?.deskValue,
      deskParameter: deskObj?.deskName,
      id: deskObj?.deskId,
      screenName: screenName,
    }
    this.store.dispatch(setSelectedDesk({ selectedDesk: navigateToDesk?.deskValue, selectedDeskParam: navigateToDesk?.deskParameter, selectedDeskId: navigateToDesk?.id }));
    this.store.dispatch(reInitiateFiltersForCCANomLinking({ reInitiateFilters: true, deskFilterValue: navigateToDesk }));
    this.router?.navigate([routeUrl]);
  }


  public getNominations(id: string, desk: string): Observable<any> {
    const url = `${environment.nominationAPI}/GetNominationDetailsByNomGrouping?nomId=${id}&desk=${desk}`;
    return this.apiService.get(url, 'nomination')
      .pipe(
        map((data) => data),
        catchError((error) => {
          console.error('Error fetching nominations:', error);
          return throwError(() => new Error('Error fetching nominations. Please try again later.'));
        })
      );
  }
  get viewsList(): any {
    return this.viewsList$;
  }

  set viewsList(value: any) {
    this.viewsList$ = value;
  }

}
