import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { updateIndicators } from 'src/app/dynamicdeals/store/action/cb-dynamic-deals.action';
import { getDealContractId, getSelectedIndicators } from '../../store/selector/dynamicdeal-detail.selector';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { udmIndicator } from 'src/app/shared/interface/cb-dynamic-deal-interface';
import { DynamicDealsSharedStateService } from 'src/app/services/dynamic-deals-shared-state.service';
import { ToastService } from 'src/app/services/toast.service';
import { selectIsIndicatorsUpdateFailed } from 'src/app/dynamicdeals/store/selector/cb-dynamic-deals.selector';

@Component({
  selector: 'app-assign-indicator-dropdown',
  templateUrl: './assign-indicator-dropdown.component.html',
  styleUrls: ['./assign-indicator-dropdown.component.scss']
})
export class AssignIndicatorDropdownComponent implements OnInit,OnDestroy {

  selectedIndicators$ = this.store.select(getSelectedIndicators);
  dealContractId$ = this.store.select(getDealContractId);
  isIndicatorUpdateFailed$ = this.store.select(selectIsIndicatorsUpdateFailed);
  showErrorBanner!:boolean;
  region!:string;
  region$!:Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  showDropDown = false;
  dealContractId:string="";
  selectedIndicators:udmIndicator[] = [];


  indicatorsEC:udmIndicator[] = [
    {
      "name": "EXH",
      "value": "exchange deals"
    },
    {
        "name": "LS",
        "value": "Line space deals"
    },
    {
        "name": "TXO",
        "value": "Texas origin"
    }
];

indicatorsWC:udmIndicator[] = [
  {
      "name": "LAX",
      "value": "deals done at LAX"
  },
  {
      "name": "HOP",
      "value": "Head of Pipe"
  },
  {
    "name": "RICH",
    "value": "Richmond"
},
{
    "name": "ITT",
    "value": "in tank transfer"
},
{
    "name": "DEL",
    "value": "Delivered to track"
}
];
  
  indicatorDropDownValues!: any[];

  
  constructor(private store: Store, private cbStateService:DynamicDealsSharedStateService, private toastService: ToastService) {
   }


   ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.region$.unsubscribe();
  }

  ngOnInit() {

   this.region$ = this.cbStateService.regionState$.subscribe(reg => {
      this.region = reg
      if(this.region)
      {
        if(this.region == 'usec')
        {
            this.indicatorDropDownValues = this.indicatorsEC;
        }
        if(this.region == 'uswc')
          {
              this.indicatorDropDownValues = this.indicatorsWC;
          }
      }
   });

   this.selectedIndicators$
  .pipe(takeUntil(this.destroy$))
  .subscribe({
    next: (data:any) => {
    this.selectedIndicators = data;
    },
    error: (err:any) => {
      console.log(err);
    },
    complete: () => { }
  });
  this.dealContractId$
  .pipe(takeUntil(this.destroy$))
  .subscribe({
    next: (data:any) => {
    this.dealContractId = data;
    },
    error: (err:any) => {
      console.log(err);
    },
    complete: () => { }
  });

  this.isIndicatorUpdateFailed$
  .pipe(takeUntil(this.destroy$))
  .subscribe({
    next: (data:any) => {
      if(data == 'error')
      {
        this.toastService.setToastNotification({ show: true, type: "error", msg: "indicators cannot be changed as user has read only access" })
      }
    },
    error: (err:any) => {
      console.log(err);
    },
    complete: () => { }
  });

  }

  toggleDropdown() {
    this.showDropDown = !this.showDropDown;
  }
  closeDropdown() {
    this.showDropDown = false;
  }
  updateIndicators(indicator:udmIndicator) {
    if(!Object.isExtensible(this.selectedIndicators)) {
      this.selectedIndicators = [...this.selectedIndicators];
    }
    
     this.selectedIndicators = this.selectedIndicators.map(obj =>
      Object.isExtensible(obj)?obj:{...obj}
     )

    const index = this.selectedIndicators.findIndex(item => item.name === indicator.name);
    if(index !== -1)
    {
      this.selectedIndicators.splice(index,1);
    }
    else{
      this.selectedIndicators.push(indicator)
    }

    let payLoad = {
      dealContractId:this.dealContractId,
      udmIndicators:this.selectedIndicators
    }
    this.store.dispatch(updateIndicators({payLoad: payLoad}));
  }

  isIndicatorSelected(item:string)
  {
    let selected = false;
    selected = this.selectedIndicators.map(item => item.name).includes(item);
    return selected;
  }

}
