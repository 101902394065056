
import { enableClearFilter, enableTRSave, saveAppliedTRFilters, saveDefaultTRFilters, setAPIStatus, setClearFiltersBoolean, setReceivedResponseBoolean, triggerTRSave, setEnableTableView, setSwitchView, setSelectedDesk, setTableHeaders, saveEthanolTRFiltersResponse, updatedOfftakersFalse, getTrNominationListSuccess, createNominationSuccess, clearCreateNominationApiResponse, setUDMScreen, getTRTruckDataAPISuccess, saveTRDataPayload, modifyUpdatedFiltersSelection, updateNominationSuccess, setBCPView, setPipelineCompactView, triggerTankParamSave, triggerNotifParamSave, setBCPCompactView, saveTankMasterDetails } from '../action/app.action';
import { saveEthanolNomFiltersResponse, saveEthanolNomFiltersUpdated, userDetails, updateCBHeaderCheckboxesState, saveEthanolModalPopupRefResponse, savePipelineTRFiltersResponse, savePipelineNomFiltersResponse, getTRPipelineDataAPISuccess, setPipelineSwitchView, setPipelineTableHeaders, getTrPipelineNominationListSuccess, savePipelineNomFiltersUpdated, savePipelineTRFiltersUpdated, setPipelineFiltersResponse, initialisePipelineNomFilters, saveTankParamDataPayload, saveNotifParamDataPayload, setBcpSwitchView, enableTankParamSave, enableNotifParamSave, showHideDeletedRules, triggerAdminSettingsSave, enableAdminSettingsSave, updateURLParams, saveupdatedURLParams, setAdminView, setIsAdminViewSwitched, searchNomActionResponse, savePipelineNomSearchResponse, setdeskIDSearchedResponse, setSaveMdmSearchResponse, reInitiateFiltersForCCANomLinking, saveCargoPlanCcaFiltersUpdated, saveCargoPlanCcaFiltersResponse, saveCargoFiltersCCA, isCargoCCAUpdated, saveMktElsEFiltersResponse, setAllDeskInfo,setDeskWiseLocationsAndProducts, setDynamicDealsCount, savelinkedNomFiltersResponse } from './../action/app.action';

import { Action, createReducer, on } from '@ngrx/store';
import { AppliedFilters, FilterValues, RouteFilterValues } from '../../interface/terminal-replenishment.interface';
import { EthanolFilterValues } from '../../interface/ethanol-view.interface';
import { FilterObj } from '../../interface/contract-balancing.interface';
import { PipelineFilterValues } from '../../interface/pipeline-view.interface';
import { AdminView } from '../../interface/mdm-admin.interface';
import { MDMDeskValues } from '../../interface/mdm.interface';

export interface AppState {
    cargoPlanCcaToDate: any;
    cargoPlanCcaFromDate: any;
    cargoPlanCcaFilterValues: any;
    apiStatus: string;
    apiResponseMessage: string;
    totalTRFiltersResponse: FilterValues[];
    updateURLParamsState: RouteFilterValues;
    updatedFilterSelection: AppliedFilters;
    receivedTotalFiltersResponse: boolean;
    receivedUpdatedFilterSelection: boolean;
    isClearFilter: boolean;
    triggeredTRDataSave: boolean;
    isTRSaveEnabled: boolean;
    triggeredAdminSettingsSave: boolean;
    triggeredTankParamDataSave: boolean;
    isTankParamSaveEnabled: boolean;
    triggeredNotifParamDataSave: boolean;
    isNotifParamSaveEnabled: boolean;
    triggeredDeletedRules: boolean,
    isAdminSettingsSaveEnabled: boolean;
    adminView: string;
    isAdminViewSwitched: boolean;
    updatedOfftakers: boolean;
    isTableView: boolean;
    switchView: string;
    isSwitchUpdated: boolean;
    selectedDesk: string;
    selectedDeskParam: string;
    selectedDeskId: any;
    allTableHeaders: any;
    isTableHeadersUpdated: boolean;
    ethanolFilterValues: EthanolFilterValues[];
    ethanolTruckToggle: any,
    unitOfMeasure: any,
    ethanolFromDate: any,
    ethanolToDate: any,
    isfilterUpdated: any,
    isNomFilterUpdated: any,
    modeOfTransport: any
    trNominationList: any;
    tankParamDataPayload: any;
    notifParamDataPayload: any;
    trPipelineNominationList: any;
    isTRGrid: boolean;
    createNominationApiResponse: any;
    trTruckApiResponse: any;
    screen: string;
    trDataPayLoad: any;
    BCPView: string;
    cbHeaderCheckboxes: FilterObj[];
    cbHeaderCheckedCount: number;
    updateNominationApiResponse: any;
    publishOrDeleteNominationApiResponse: any;
    ethanolModalPopupRefResponse: any;
    userDetails: any;
    searchPayload: any;
    pipelineFilterValues: PipelineFilterValues[];
    pipelineFromDate: any,
    pipelineToDate: any,
    isPipelineFilterUpdated: any,
    pipelineNomFilterValues: PipelineFilterValues[];
    mktElsFilterValues: PipelineFilterValues[];
    isMktElsFilterUpdated: any;
    isPipelineNomFilterUpdated: any,
    isCargoPlanCcaFilterUpdated: any,
    isPipelineNomFilterInitialised: any,
    trPipelineApiResponse: any;
    pipelineSwitchView: string;
    isPipelineCompact: boolean;
    isPipelineSwitchUpdated: boolean;
    pipelineCompactView: boolean;
    allPipelineTableHeaders: any;
    isPipelineTableHeadersUpdated: boolean;
    isUpdated: boolean
    value: any;
    screenName: any;
    isPipelineNomUpdated: any;
    pipelineNomSearchValues: any,
    isPipelineNomSearchUpdated: any;
    deskSearchValues: any;
    deskSetUpdated?: boolean;
    deskSearchMdm: any;
    deskSetUpdate?:boolean
    reInitiateFiltersForCCA?: boolean;
    deskFilterValue?: any;
    bcpSwitchView: string;
    isBcpSwitchUpdated:boolean;
    bcpCompactView: boolean;
    isBCPCompact: boolean;
    cargoCCAFilters: any;
    cargoCCAStartDate: any;
    cargoCCAEndDate: any;
    isCargoCCAUpdated: any;
    deskInfo: MDMDeskValues[];
    deskWiseInfo :any;
    cbDynamicDealsCount:any;
    tankDetails:any;
    pipelineCurrentSelectedView? : any;
}
const checkboxValues: any[] = ['contract details table', 'mtd tracking', 'monthly performance review', 'deal performance review'];
const formattedCBHeaderCheckboxes = checkboxValues.map((value: any, i: any) => {
    const bool = (i === 0);
    return { name: value, checked: bool };
});
export const initialState: Readonly<AppState> = {
    apiStatus: '',
    apiResponseMessage: '',
    totalTRFiltersResponse: [],
    updateURLParamsState: {
        filteredDesk: "", filteredDeskId: "", filteredLocation: "", filteredProduct: "",
        filteredMaterialGroupNumber: "", filteredProductNumber: ""
    },
    updatedFilterSelection: {
        desk: "",
        offtakers: [],
        updatedProduct: "",
        updatedfromDate: "",
        updatedselectedCustomer: [],
        updateCheckedCustomer: "",
        updatedToDate: "",
        updateUncheckedCustomer: "",
        getFilterName: "",
        getIsChecked: false,
        udatedDateRangeFlag: true,
    },
    receivedTotalFiltersResponse: false,
    receivedUpdatedFilterSelection: false,
    isClearFilter: false,
    triggeredTankParamDataSave: false,
    isTankParamSaveEnabled: false,
    triggeredNotifParamDataSave: false,
    isNotifParamSaveEnabled: false,
    triggeredDeletedRules: false,
    triggeredTRDataSave: false,
    isTRSaveEnabled: false,
    triggeredAdminSettingsSave: false,
    isAdminSettingsSaveEnabled: false,
    adminView: AdminView.Locations,
    isAdminViewSwitched: false,
    updatedOfftakers: false,
    isTableView: true,
    switchView: 'terminal_supplier_nomination_carrier',
    isSwitchUpdated: false,
    selectedDesk: '',
    selectedDeskParam: '',
    selectedDeskId: '',
    allTableHeaders: [],
    isTableHeadersUpdated: false,
    ethanolFilterValues: [],
    ethanolTruckToggle: false,
    unitOfMeasure: 'bbl',
    ethanolFromDate: null,
    ethanolToDate: null,
    isfilterUpdated: false,
    tankParamDataPayload: null,
    notifParamDataPayload: null,
    isNomFilterUpdated: false,
    modeOfTransport: 'rail',
    trNominationList: '',
    trPipelineNominationList: '',
    isTRGrid: false,
    createNominationApiResponse: '',
    trTruckApiResponse: [],
    screen: 'replenishmentplanning',
    trDataPayLoad: null,
    BCPView: 'summary',
    cbHeaderCheckboxes: formattedCBHeaderCheckboxes,
    cbHeaderCheckedCount: 1,
    updateNominationApiResponse: null,
    publishOrDeleteNominationApiResponse: null,
    ethanolModalPopupRefResponse: null,
    userDetails: {},
    searchPayload: {},
    pipelineFilterValues: [],
    isPipelineFilterUpdated: false,
    isPipelineNomFilterUpdated: false,
    isCargoPlanCcaFilterUpdated: false,
    isPipelineNomFilterInitialised: false,
    pipelineFromDate: null,
    pipelineToDate: null,
    trPipelineApiResponse: {},
    pipelineSwitchView: 'product_terminal_nomination',
    pipelineNomFilterValues: [],
    mktElsFilterValues: [],
    isMktElsFilterUpdated: false,
    isPipelineSwitchUpdated: false,
    allPipelineTableHeaders: [],
    isPipelineTableHeadersUpdated: false,
    isUpdated: false,
    isPipelineCompact: false,
    pipelineCompactView: false,
    value: {},
    screenName: "",
    isPipelineNomUpdated: false,
    pipelineNomSearchValues: {},
    isPipelineNomSearchUpdated: false,
    deskSearchValues: {},
    deskSetUpdated: false,
    deskSearchMdm: {},
    deskSetUpdate: false,
    bcpSwitchView: 'product_terminal',
    isBcpSwitchUpdated: false,
    bcpCompactView: false,

    isBCPCompact: false,
    cargoPlanCcaFromDate: null,
    cargoPlanCcaFilterValues: [],
    cargoPlanCcaToDate: null,
    cargoCCAFilters: [],
    cargoCCAStartDate: null,
    cargoCCAEndDate: null,
    isCargoCCAUpdated: false,
    reInitiateFiltersForCCA: false,
    deskFilterValue: {},
    deskInfo: [],
    deskWiseInfo:{},
    cbDynamicDealsCount:0,    
    tankDetails:{},
    pipelineCurrentSelectedView : null,
};

export const appReducer = createReducer(
    initialState,
    on(setAPIStatus, (state, { apiStatus, apiResponseMessage }) => ({
        ...state,
        apiStatus,
        apiResponseMessage,
    })),
    on(userDetails, (state, { userDetails }) => ({
        ...state,
        userDetails
    })),
    on(saveDefaultTRFilters, (state, { totalTRFiltersResponse, receivedTotalFiltersResponse, updatedOfftakers, receivedUpdatedFilterSelection, isTRGrid }) => ({
        ...state,
        totalTRFiltersResponse,
        receivedTotalFiltersResponse,
        updatedOfftakers,
        receivedUpdatedFilterSelection,
        isClearFilter: false,
        isTRGrid
    })),
    on(updateURLParams, (state, { updateURLParamsState }) => ({
        ...state,
        updateURLParamsState
    })),
    on(saveupdatedURLParams, (state, { totalTRFiltersResponse, receivedTotalFiltersResponse, updatedOfftakers, receivedUpdatedFilterSelection }) => ({
        ...state,
        totalTRFiltersResponse,
        receivedTotalFiltersResponse,
        updatedOfftakers,
        receivedUpdatedFilterSelection,
        isClearFilter: false
    })),
    on(saveAppliedTRFilters, (state, { updatedFilterSelection, receivedUpdatedFilterSelection, isClearFilter, isTRSaveEnabled, isTRGrid }) => ({
        ...state,
        updatedFilterSelection,
        isClearFilter,
        isTRSaveEnabled,
        receivedUpdatedFilterSelection: receivedUpdatedFilterSelection,
        isTRGrid
    })),
    on(enableClearFilter, (state, { isClearFilter }) => ({
        ...state,
        receivedUpdatedFilterSelection: false,
        updatedFilterSelection: {
            desk: "",
            offtakers: [],
            updatedProduct: "",
            updatedfromDate: "",
            updatedselectedCustomer: [],
            updateCheckedCustomer: "",
            updatedToDate: "",
            updateUncheckedCustomer: "",
            getFilterName: "",
            getIsChecked: false,
            udatedDateRangeFlag: true,
        },
        isClearFilter,
    })),
    on(setReceivedResponseBoolean, (state, { isClearFilter, receivedTotalFiltersResponse, receivedUpdatedFilterSelection, isTRGrid }) => ({
        ...state,
        isClearFilter,
        receivedTotalFiltersResponse,
        receivedUpdatedFilterSelection,
        isTRGrid
    })),
    on(setClearFiltersBoolean, (state, { isClearFilter }) => ({
        ...state,
        isClearFilter,
        triggeredTRDataSave: false
    })),
    on(triggerTRSave, (state, { triggeredTRDataSave, isTRSaveEnabled, isClearFilter, receivedTotalFiltersResponse, receivedUpdatedFilterSelection }) => ({
        ...state,
        triggeredTRDataSave,
        isTRSaveEnabled,
        isClearFilter,
        receivedTotalFiltersResponse,
        receivedUpdatedFilterSelection
    })),
    on(triggerTankParamSave, (state, { triggeredTankParamDataSave, isTankParamSaveEnabled }) => ({
        ...state,
        triggeredTankParamDataSave,
        isTankParamSaveEnabled
    })),
    on(triggerNotifParamSave, (state, { triggeredNotifParamDataSave, isNotifParamSaveEnabled }) => ({
        ...state,
        triggeredNotifParamDataSave,
        isNotifParamSaveEnabled
    })),
    on(triggerAdminSettingsSave, (state, { triggeredAdminSettingsSave, isAdminSettingsSaveEnabled }) => ({
        ...state,
        triggeredAdminSettingsSave,
        isAdminSettingsSaveEnabled
    })),
    on(setAdminView, (state, { adminView }) => ({
        ...state,
        adminView,
        isAdminViewSwitched: true
    })),
    on(setPipelineCompactView, (state, { pipelineCompactView, isPipelineCompact }) => ({
        ...state,
        pipelineCompactView,
        isPipelineCompact
    })),
    on(setIsAdminViewSwitched, (state, { isAdminViewSwitched }) => ({
        ...state,
        isAdminViewSwitched: isAdminViewSwitched
    })),
    on(enableTRSave, (state, { isTRSaveEnabled }) => ({
        ...state,
        isTRSaveEnabled,
        isClearFilter: false
    })),
    on(enableTankParamSave, (state, { isTankParamSaveEnabled}) => ({
        ...state,
        isTankParamSaveEnabled,
      })),
      on(enableNotifParamSave, (state, { isNotifParamSaveEnabled}) => ({
        ...state,
        isNotifParamSaveEnabled,
      })),
    on(enableAdminSettingsSave, (state, { isAdminSettingsSaveEnabled}) => ({
        ...state,
        isAdminSettingsSaveEnabled,
    })),
    on(showHideDeletedRules, (state, {triggeredDeletedRules}) => ({
        ...state,
        triggeredDeletedRules
    })),
    on(setEnableTableView, (state, { isTableView }) => ({
        ...state,
        isTableView
    })),
    on(setSwitchView, (state, { switchView, isSwitchUpdated }) => ({
        ...state,
        switchView,
        isSwitchUpdated
    })),
    on(setSelectedDesk, (state, { selectedDesk, selectedDeskParam, selectedDeskId }) => ({
        ...state,
        selectedDesk,
        selectedDeskParam,
        selectedDeskId
    })),
    on(setTableHeaders, (state, { allTableHeaders, isTableHeadersUpdated }) => ({
        ...state,
        allTableHeaders,
        isTableHeadersUpdated
    })),
    on(saveEthanolTRFiltersResponse, (state, { ethanolFilterValues, ethanolFromDate, ethanolToDate, isfilterUpdated, ethanolTruckToggle, unitOfMeasure }) => ({
        ...state,
        ethanolFilterValues,
        ethanolFromDate, ethanolToDate, isfilterUpdated, ethanolTruckToggle, unitOfMeasure
    })),
    on(saveEthanolNomFiltersResponse, (state, { ethanolFilterValues, ethanolFromDate, ethanolToDate, isNomFilterUpdated }) => ({
        ...state,
        ethanolFilterValues,
        ethanolFromDate, ethanolToDate, isNomFilterUpdated
    })),
    on(updatedOfftakersFalse, (state, { updatedOfftakers }) => ({
        ...state,
        updatedOfftakers
    })),
    on(getTrNominationListSuccess, (state, { trNominationList }) => ({
        ...state,
        trNominationList: trNominationList,
    })),
    on(getTrPipelineNominationListSuccess, (state, { trPipelineNominationList }) => ({
        ...state,
        trPipelineNominationList: trPipelineNominationList,
    })),
    on(createNominationSuccess, (state, { createdNominationResponse }) => ({
        ...state,
        createNominationApiResponse: createdNominationResponse
    })),
    on(clearCreateNominationApiResponse, (state, { createNominationApiResponse }) => ({
        ...state,
        createNominationApiResponse: createNominationApiResponse,
    })),
    on(setUDMScreen, (state, { screen, receivedTotalFiltersResponse }) => ({
        ...state,
        screen,
        receivedTotalFiltersResponse
    })),
    on(getTRTruckDataAPISuccess, (state, { trTruckApiResponse }) => ({
        ...state,
        trTruckApiResponse: trTruckApiResponse
    })),
    on(getTRTruckDataAPISuccess, (state, { trTruckApiResponse }) => ({
        ...state,
        trTruckApiResponse: trTruckApiResponse
    })),
    on(saveTRDataPayload, (state, { trDataPayLoad }) => ({
        ...state,
        trDataPayLoad: trDataPayLoad
    })),
    on(saveTankParamDataPayload, (state, { tankParamDataPayload }) => ({
        ...state,
        tankParamDataPayLoad: tankParamDataPayload
    })),
    on(saveNotifParamDataPayload, (state, { notifParamDataPayload }) => ({
      ...state,
      notifParamDataPayLoad: notifParamDataPayload
    })),
    on(modifyUpdatedFiltersSelection, (state, { updatedFilters, updatedOfftakers }) => ({
        ...state,
        updatedFilterSelection: updatedFilters,
        updatedOfftakers
    })),
    on(setBCPView, (state, { BCPView }) => ({
        ...state,
        BCPView
      })),
    on(updateCBHeaderCheckboxesState, (state, { cbHeaderCheckboxes, cbHeaderCheckedCount }) => ({
        ...state,
        cbHeaderCheckboxes: cbHeaderCheckboxes,
        cbHeaderCheckedCount: cbHeaderCheckedCount
    })),
    on(updateNominationSuccess, (state, { updatedNominationResponse, publishOrDeleteNominationApiResponse }) => ({
        ...state,
        updateNominationApiResponse: updatedNominationResponse,
        publishOrDeleteNominationApiResponse,
    })),
    on(saveEthanolNomFiltersUpdated, (state, { isNomFilterUpdated }) => ({
        ...state,
        isNomFilterUpdated,
    })),
    on(savePipelineNomFiltersUpdated, (state, { isPipelineNomFilterUpdated }) => ({
        ...state,
        isPipelineNomFilterUpdated,
    })),
    on(saveCargoPlanCcaFiltersUpdated, (state, { isCargoPlanCcaFilterUpdated }) => ({
        ...state,
        isCargoPlanCcaFilterUpdated,
    })),
    on(initialisePipelineNomFilters, (state, { isPipelineNomFilterInitialised, screenName }) => ({
        ...state,
        isPipelineNomFilterInitialised,
        screenName
    })),
    on(savePipelineTRFiltersUpdated, (state, { isPipelineFilterUpdated }) => ({
        ...state,
        isPipelineFilterUpdated,
    })),
    on(saveEthanolModalPopupRefResponse, (state, { ethanolModalPopupRefResponse }) => ({
        ...state,
        ethanolModalPopupRefResponse,
    })),
    on(savePipelineTRFiltersResponse, (state, { pipelineFilterValues, pipelineFromDate, pipelineToDate, isPipelineFilterUpdated , pipelineCurrentSelectedView}) => ({
        ...state,
        pipelineFilterValues,
        pipelineFromDate,
        pipelineToDate,
        isPipelineFilterUpdated,  
        pipelineCurrentSelectedView      
    })),
    on(savePipelineNomFiltersResponse, (state, { pipelineNomFilterValues, pipelineFromDate, pipelineToDate, isPipelineNomFilterUpdated }) => ({
        ...state,
        pipelineNomFilterValues,
        pipelineFromDate,
        pipelineToDate,
        isPipelineNomFilterUpdated,
        isPipelineFilterUpdated: false,
    })),
    on(saveMktElsEFiltersResponse, (state, { mktElsFilterValues, pipelineFromDate, pipelineToDate, isMktElsFilterUpdated }) => ({
        ...state,
        mktElsFilterValues,
        pipelineFromDate,
        pipelineToDate,
        isMktElsFilterUpdated,
        isPipelineFilterUpdated: false,
    })),
    on(saveCargoPlanCcaFiltersResponse, (state, { cargoPlanCcaFilterValues, cargoPlanCcaFromDate, cargoPlanCcaToDate, isCargoPlanCcaFilterUpdated }) => ({
        ...state,
        cargoPlanCcaFilterValues,
        cargoPlanCcaFromDate,
        cargoPlanCcaToDate,
        isCargoPlanCcaFilterUpdated,
        isCargoPlanFilterUpdated: false,
    })),
    on(getTRPipelineDataAPISuccess, (state, { trPipelineApiResponse }) => ({
        ...state,
        trPipelineApiResponse: trPipelineApiResponse,
        isPipelineFilterUpdated: false
    })),
    on(setPipelineSwitchView, (state, { pipelineSwitchView, isPipelineSwitchUpdated }) => ({
        ...state,
        pipelineSwitchView,
        isPipelineSwitchUpdated
    })),
    on(setPipelineTableHeaders, (state, { allPipelineTableHeaders, isPipelineTableHeadersUpdated }) => ({
        ...state,
        allPipelineTableHeaders,
        isPipelineTableHeadersUpdated
    })),
    on(setPipelineFiltersResponse, (state, { value, isUpdated }) => ({
        ...state,
        value,
        isUpdated
    })),
    on(searchNomActionResponse, (state, { searchPayload, isPipelineNomUpdated }) => ({
        ...state,
        searchPayload,
        isPipelineNomUpdated
    })),
    on(savePipelineNomSearchResponse, (state, { pipelineNomSearchValues, isPipelineNomSearchUpdated }) => ({
        ...state,
        pipelineNomSearchValues,
        isPipelineNomSearchUpdated
    })),
    on(setdeskIDSearchedResponse, (state, { deskSearchValues,  deskSetUpdated }) => ({
        ...state,
        deskSearchValues,
        deskSetUpdated
    })),
    on(setSaveMdmSearchResponse, (state, { deskSearchMdm, deskSetUpdate})=> ({
        ...state,
        deskSearchMdm, deskSetUpdate
    })),
    on(setBcpSwitchView, (state, { bcpSwitchView, isBcpSwitchUpdated }) => ({
        ...state,
        bcpSwitchView: bcpSwitchView,
        isBcpSwitchUpdated: isBcpSwitchUpdated
    })),
    on(setBCPCompactView, (state, { bcpCompactView, isBCPCompact }) => ({
      ...state,
      bcpCompactView,
      isBCPCompact
    })),
    on(saveCargoFiltersCCA, (state, { cargoCCAFilters, cargoCCAStartDate, cargoCCAEndDate, isCargoCCAUpdated }) => ({
        ...state,
        cargoCCAFilters,
        cargoCCAStartDate,
        cargoCCAEndDate,
        isCargoCCAUpdated
    })),
    on(savelinkedNomFiltersResponse, (state, { cargoCCAFilters, cargoCCAStartDate, cargoCCAEndDate }) => ({
        ...state,
        cargoCCAFilters,
        cargoCCAStartDate,
        cargoCCAEndDate,
    })),
    on(reInitiateFiltersForCCANomLinking, (state, { reInitiateFilters, deskFilterValue }) => ({
        ...state,
        reInitiateFiltersForCCA: reInitiateFilters,
        deskFilterValue
    })),
    on(isCargoCCAUpdated, (state, { isCargoCCAUpdated }) => ({
        ...state,
        isCargoCCAUpdated: isCargoCCAUpdated
    })),
    on(setAllDeskInfo, (state, { deskInfo }) => ({
       ...state,
       deskInfo
    })),
    on(setDeskWiseLocationsAndProducts,(state,{ deskWiseInfo })=>({
        ...state,
        deskWiseInfo
    })),
    on(setDynamicDealsCount, (state, { cbDynamicDealsCount }) => ({
        ...state,
        cbDynamicDealsCount: cbDynamicDealsCount
    })),
    on(saveTankMasterDetails, (state, { tankDetails}) => ({
        ...state,
        tankDetails,
    }))
);


export function reducer(state: AppState | undefined, action: Action): any {
    return appReducer(state, action);
}

export const updatedURLParams = (state: AppState) => ({ params: state.updateURLParamsState, totalTRFiltersResponse: state.totalTRFiltersResponse, updatedFilterSelection: state.updatedFilterSelection, receivedTotalFiltersResponse: state.receivedTotalFiltersResponse, updatedOfftakers: state.updatedOfftakers, isTRGrid: state.isTRGrid, isClearFilter: state.isClearFilter, screen: state.screen });
export const getDefaultTRFilters = (state: AppState) => ({ totalTRFiltersResponse: state.totalTRFiltersResponse, updatedFilterSelection: state.updatedFilterSelection, receivedTotalFiltersResponse: state.receivedTotalFiltersResponse, updatedOfftakers: state.updatedOfftakers, isTRGrid: state.isTRGrid, isClearFilter: state.isClearFilter, screen: state.screen });
export const getAppliedTRFilters = (state: AppState) => ({ updatedFilterSelection: state.updatedFilterSelection, receivedUpdatedFilterSelection: state.receivedUpdatedFilterSelection, totalTRFiltersResponse: state.totalTRFiltersResponse, isTRGrid: state.isTRGrid });
export const checkisClearFilterEnabled = (state: AppState) => ({ isClearFilter: state.isClearFilter });
export const checkisTRSave = (state: AppState) => ({ triggeredTRDataSave: state.triggeredTRDataSave });
export const checkisTRSaveEnabled = (state: AppState) => ({ isTRSaveEnabled: state.isTRSaveEnabled });
export const checkIsAdminSettingsSave = (state: AppState) => ({ triggeredAdminSettingsSave: state.triggeredAdminSettingsSave });
export const checkIsAdminSettingsSaveEnabled = (state: AppState) => ({ isAdminSettingsSaveEnabled: state.isAdminSettingsSaveEnabled });
export const checkIsTableViewEnabled = (state: AppState) => ({ isTableView: state.isTableView });
export const getAdminView = (state: AppState) => ({ adminView: state.adminView, isAdminViewSwitched: state.isAdminViewSwitched });
export const getSwitchView = (state: AppState) => ({ switchView: state.switchView, isSwitchUpdated: state.isSwitchUpdated });
export const getSelectedDesk = (state: AppState) => ({ selectedDesk: state.selectedDesk, selectedDeskParam: state.selectedDeskParam, selectedDeskId: state.selectedDeskId });
export const getAllTableHeaders = (state: AppState) => ({ allTableHeaders: state.allTableHeaders, isTableHeadersUpdated: state.isTableHeadersUpdated });
export const getEthanolTRFiltersResponse = (state: AppState) => ({ ethanolFilterValues: state.ethanolFilterValues, ethanolFromDate: state.ethanolFromDate, ehtanolToDate: state.ethanolToDate, isfilterUpdated: state.isfilterUpdated, ethanolTruckToggle: state.ethanolTruckToggle, unitOfMeasure: state.unitOfMeasure });
export const getEthanolNomFiltersResponse = (state: AppState) => ({ ethanolFilterValues: state.ethanolFilterValues, ethanolFromDate: state.ethanolFromDate, ehtanolToDate: state.ethanolToDate, isNomFilterUpdated: state.isNomFilterUpdated });
export const checkisTankParamSave = (state: AppState) => ({ triggeredTankParamDataSave: state.triggeredTankParamDataSave });
export const checkisNotifParamSave = (state: AppState) => ({ triggeredNotifParamDataSave: state.triggeredNotifParamDataSave });
export const checkisAlertDeletedShowHide = (state: AppState) => ({ triggeredDeletedRules: state.triggeredDeletedRules });
export const checkisTankParamSaveEnabled = (state: AppState) => ({isTankParamSaveEnabled: state.isTankParamSaveEnabled});
export const checkisNotifParamSaveEnabled = (state: AppState) => ({isNotifParamSaveEnabled: state.isNotifParamSaveEnabled});

export const checkReceivedResponseBoolean = (state: AppState) => ({ receivedTotalFiltersResponse: state.receivedTotalFiltersResponse, receivedUpdatedFilterSelection: state.receivedUpdatedFilterSelection, isClearFilter: state.isClearFilter });
export const getUDMScreen = (state: AppState) => ({ screen: state.screen });
export const getTRDataPayload = (state: AppState) => ({ trDataPayLoad: state.trDataPayLoad });
export const getBCPView = (state: AppState) => ({ BCPView: state.BCPView });
export const loggedInUserDetails = (state: AppState) => ({ userDetails: state.userDetails });
export const getCBHeaderCheckboxesState = (state: AppState) => ({ cbHeaderCheckboxes: state.cbHeaderCheckboxes, cbHeaderCheckedCount: state.cbHeaderCheckedCount });
export const getTankParamDataPayload = (state: AppState) => ({ tankParamDataPayload: state.tankParamDataPayload})
export const getNotifParamDataPayload = (state: AppState) => ({ notifParamDataPayload: state.notifParamDataPayload})

export const getPipelineTRFiltersResponse = (state: AppState) => ({ pipelineFilterValues: state.pipelineFilterValues, pipelineFromDate: state.pipelineFromDate, pipelineToDate: state.pipelineToDate, isPipelineFilterUpdated: state.isPipelineFilterUpdated, pipelineCurrentSelectedView : state.pipelineCurrentSelectedView });

export const getPipelineNomFiltersResponse = (state: AppState) => ({ pipelineNomFilterValues: state.pipelineNomFilterValues, pipelineFromDate: state.pipelineFromDate, pipelineToDate: state.pipelineToDate, isPipelineNomFilterUpdated: state.isPipelineNomFilterUpdated });

export const getMktElsFiltersResponse = (state: AppState) => ({ mktElsFilterValues: state.mktElsFilterValues, pipelineFromDate: state.pipelineFromDate, pipelineToDate: state.pipelineToDate, isMktElsFilterUpdated: state.isMktElsFilterUpdated });

export const getCargoPlanCcaFiltersResponse = (state: AppState) => ({ cargoPlanCcaFilterValues: state.cargoPlanCcaFilterValues, cargoPlanCcaFromDate: state.cargoPlanCcaFromDate, cargoPlanCcaToDate: state.cargoPlanCcaToDate, isCargoPlanCcaFilterUpdated: state.isCargoPlanCcaFilterUpdated });

export const getTrPipelineApiResponse = (state: AppState) => ({ trPipelineApiResponse: state.trPipelineApiResponse, triggeredTRDataSave: state.triggeredTRDataSave, isTRSaveEnabled: state.isTRSaveEnabled });

export const getPipelineSwitchView = (state: AppState) => ({ pipelineSwitchView: state.pipelineSwitchView, isPipelineSwitchUpdated: state.isPipelineSwitchUpdated });
export const getPipelineCompactView = (state: AppState) => ({ pipelineCompactView: state.pipelineCompactView, isPipelineCompact: state.isPipelineCompact });

export const getAllPipelineTableHeaders = (state: AppState) => ({ allPipelineTableHeaders: state.allPipelineTableHeaders, isPipelineTableHeadersUpdated: state.isPipelineTableHeadersUpdated });

export const getPipelineFiltersResponse = (state: AppState) => ({ value: state.value, isUpdated: state.isUpdated });

export const getSearchNomResponse = (state: AppState) => ({ searchPayload: state.searchPayload, isPipelineNomUpdated: state.isPipelineNomUpdated});

export const getIsPipelineFilterUpdated = (state: AppState) => ({ isPipelineFilterUpdated: state.isPipelineFilterUpdated });

export const getIsPipelineNomFilterUpdated = (state: AppState) => ({ isPipelineNomFilterUpdated: state.isPipelineNomFilterUpdated });

export const getIsCargoPlanCcaFilterUpdated = (state: AppState) => ({ isCargoPlanCcaFilterUpdated: state.isCargoPlanCcaFilterUpdated });

export const getIsPipelineNomFilterInitialised = (state: AppState) => ({ isPipelineNomFilterInitialised: state.isPipelineNomFilterInitialised, screenName: state.screenName });

export const getPipelineNomDateDetails = (state: AppState) => ({pipelineFromDate: state.pipelineFromDate, pipelineToDate: state.pipelineToDate});

export const getSavePipelineNomSearchResponse = (state: AppState) => ({  pipelineNomSearchValues: state.pipelineNomSearchValues, isPipelineNomSearchUpdated: state.isPipelineNomSearchUpdated});

export const getdeskIDSearchedResponse = (state: AppState) => ({  deskSearchValues: state.deskSearchValues, deskSetUpdated: state.deskSetUpdated});

export const getSaveMdmSearchResponse = (state: AppState) => ({  deskSearchMdm: state.deskSearchMdm, deskSetUpdated: state.deskSetUpdate});

export const getBcpSwitchView = (state: AppState) => ({ bcpSwitchView: state.bcpSwitchView, isBcpSwitchUpdated: state.isBcpSwitchUpdated });

export const getBCPCompactView = (state: AppState) => ({ bcpCompactView: state.bcpCompactView, isBCPCompact: state.isBCPCompact });

export const getAllDeskInfoResponse = (state: AppState) =>({ deskInfo: state.deskInfo});

export const getDeskWiseLocationsAndProducts =(state:AppState)=>({deskWiseInfo: state.deskWiseInfo});

export const getCBDynamicDealsCount = (state: AppState) => ({ cbDynamicDealsCount: state.cbDynamicDealsCount });

export const getTankMasterDataDetails = (state: AppState) => ({ tankDetails: state.tankDetails});



