export interface SettingsParametersData {
  locationCode: string,
  locationName: string,
  locationMaterials: ParamsLocationMaterials[]
}

export interface ParamsLocationMaterials {
  materialNumber: string,
  materialDescription: string,
  uom: string,
  valuationTypes: ParametersData[]
}

export interface ParametersData {
  valuationType: string
  reserves: number,
  heels: number,
  minInventory: number,
  minESL: number,
  currentTarget: number,
  maxESL: number,
  maxInventory: number,
  udmLastModifiedOn: string,
  udmLastModifiedBy: string
}

export interface SettingsParametersFilter {
  location: string,
  material: string,
  parametername: string,  
  checked: boolean
}

export interface SettingsUniqueParametersFilter {   
  parametername: string,  
  checked: boolean
}

export interface SettingsParameterMaterialsFilter { 
  location: string,
  materialnumber: string,
  material: string,
  udmmaterial: string,
  checked: boolean 
}

export interface SettingsParameterUniqueMaterialsFilter {   
  materialnumber: string,
  material: string,
  udmmaterial: string,
  checked: boolean 
}

export interface SettingsParameterLocationsFilter {
  locationcode: string,
  location: string,
  checked: boolean 
}

export interface SettingsParameterFilters {
  locationsFilter: SettingsParameterLocationsFilter[],
  materialsFilter: SettingsParameterUniqueMaterialsFilter[],
  parametersFilter: SettingsUniqueParametersFilter[]
}

export interface AlertLocationData {
  location: string,
  locationMaterials: LocationMaterials[]
}

export interface LocationMaterials {
  material: string,
  locationMaterialRules: LocationMaterialRules[]
}

export interface LocationMaterialRules {
  ruleId: string,
  ruleName: string,
  ruleDescription: string | null,
  deskId: string,
  location: string,
  material: string,
  materialNumber: string,
  udmNickname: string,
  ruleType: string,
  NoOfdays: number,
  thersold: number,
  daysToValidate: {
    type: string,
    NoOfdays: number
  },
  ruleCondition: string | null,
  parameter: string | null,
  alertFrequency: string,
  subscription: {
    users: SubscriptionUsers[]
  },
  expression: string | null,
  successEvent: boolean | null,
  createdBy: string | null,
  createdDate: string | null,
  updatedBy: string | null,
  updatedDate: string | null,
  id?: string,
  isActive: boolean
}

export interface SubscriptionUsers {
  userEmail: string,
  isEmail: boolean,
  isTeams: boolean,
  isWorkspace: boolean,
  isActive: boolean
}

export interface CreateRuleInventory {
  ruleName: string,
  ruleDescription: string,
  location: string,
  material: string,
  materialNumber: string,
  materialGroupNumber: string | null,
  desk: string,
  deskId: string,
  deskTag: string | null,
  deskNickName: string | null,
  deskRegion: string | null,
  udmNickname: string,
  ruleType: string,
  daysToValidate: {
    type: string,
    NoOfdays: number,
  },
  ruleCondition: string,
  parameter: string,
  alertFrequency: string,
  subscription: {
    users: [{
      userEmail: string,
      isEmail: boolean,
      isTeams: boolean,
      isWorkspace: boolean,
      isActive: boolean
    }]
  },
  createdBy: string,
  createdDate: string
}

export interface CreateRuleReconciliation {
  ruleName: string,
  ruleDescription: string,
  location: string,
  material: string,
  materialNumber: string,
  udmNickname: string,
  ruleType: string,
  alertFrequency: string,
  thersold: number | null,
  subscription: {
    users: [{
      userEmail: string,
      isEmail: boolean,
      isTeams: boolean,
      isWorkspace: boolean,
      isActive: boolean
    }]
  },
  createdBy: string,
  createdDate: string
}

export interface CreateRuleActualDemand {
  ruleName: string,
  ruleDescription: string,
  location: string,
  material: string,
  materialNumber: string,
  materialGroupNumber: string | null,
  desk: string,
  deskId: string,
  deskTag: string | null,
  deskNickName: string | null,
  deskRegion: string | null,
  udmNickname: string,
  ruleType: string,
  alertFrequency: string,
  thersold: number,
  daysToValidate: {
    type: string,
    NoOfdays: number,
  },
  ruleCondition: string,
  subscription: {
    users: [{
      userEmail: string,
      isEmail: boolean,
      isTeams: boolean,
      isWorkspace: boolean,
      isActive: boolean
    }]
  },
  createdBy: string,
  createdDate: string
}

export interface UpdateRuleInventory {
  ruleId: string,
  ruleName: string,
  ruleDescription: string,
  location: string,
  material: string,
  materialNumber: string,
  materialGroupNumber: string | null,
  desk: string,
  deskId: string,
  deskTag: string | null,
  deskNickName: string | null,
  deskRegion: string | null,
  udmNickname: string,
  ruleType: string,
  daysToValidate: {
    type: string,
    NoOfdays: number,
  },
  ruleCondition: string,
  parameter: string,
  alertFrequency: string,
  subscription: {
    users: SubscriptionUsers[]
  },
  createdBy: string,
  createdDate: string,
  updatedBy: string,
  updatedDate: string,
  isActive: boolean
}

export interface UpdateRuleReconciliation {
  ruleId: string,
  ruleName: string,
  ruleDescription: string,
  location: string,
  material: string,
  materialNumber: string,
  udmNickname: string,
  ruleType: string,
  alertFrequency: string,
  thersold: number,
  subscription: {
    users: SubscriptionUsers[]
  },
  createdBy: string,
  createdDate: string,
  updatedBy: string,
  updatedDate: string,
  isActive: boolean
}

export interface UpdateRuleActualDemand {
  ruleId: string,
  ruleName: string,
  ruleDescription: string,
  location: string,
  material: string,
  materialNumber: string,
  materialGroupNumber: string | null,
  desk: string,
  deskId: string,
  deskTag: string | null,
  deskNickName: string | null,
  deskRegion: string | null,
  udmNickname: string,
  ruleType: string,
  alertFrequency: string,
  thersold: number,
  daysToValidate: {
    type: string,
    NoOfdays: number,
  },
  ruleCondition: string,
  subscription: {
    users: SubscriptionUsers[]
  },
  createdBy: string,
  createdDate: string,
  updatedBy: string,
  updatedDate: string,
  isActive: boolean
}

export interface UpdateTankParameters {
  locationCode: string,
  materialNumber: string,
  udmNickname: string,
  valuationType: string,
  reserves: number,
  heels: number,
  minInventory: number,
  minESL: number,
  currentTarget: number,
  maxESL: number,
  maxInventory: number,
  uom: string,
  udmLastModifiedOn: string,
  udmLastModifiedBy: string
}

export interface DeletePayload {
  ruleId: string,
  ruleType: string,
  isActive: boolean
}
export interface AlertLocationMaterialRulesData {
  location: string,
  material: string,
  ruleid: string,
  rulename: string
}

export interface SettingsAlertRulesFilter {
  location: string,
  material: string,
  ruleid: string,
  rulename: string,
  checked: boolean
}

export interface SettingsAlertMaterialsFilter { 
  location: string,
  materialid: string,
  material: string,
  udmmaterial: string,
  checked: boolean 
}

export interface SettingsAlertUniqueMaterialsFilter {   
  materialid: string,
  material: string,
  udmmaterial: string,
  checked: boolean 
}

export interface SettingsAlertLocationsFilter {
  locationid: string,
  location: string,
  checked: boolean 
}

export interface SettingsAlertFilters {
  locationsFilter: SettingsAlertLocationsFilter[],
  materialsFilter: SettingsAlertUniqueMaterialsFilter[],
  rulesFilter: SettingsAlertRulesFilter[]
}



export interface AlertRuleResponse{

  user: string,
  ruleId: string,
  ruleName: string, 
  ruleType: string,
  location: string,
  material: string,
  desk: string,
  deskId: string,
  materialNumber: string | null,
  materialGroupNumber: string, 
  message: string,
  alertDate: string,
  isActive: boolean,
  udmNickname: string | null,
  createdDate: string,
  createdBy: string, 
  updatedDate: string | null,
  updatedBy: string | null,
  id: string,
}

export interface AlertLocationMaterialRulesData {
  location: string,
  material: string,
  ruleid: string,
  rulename: string
}

export interface SettingsAlertRulesFilter {
  location: string,
  material: string,
  ruleid: string,
  rulename: string,
  checked: boolean
}

export interface SettingsAlertMaterialsFilter { 
  location: string,
  materialid: string,
  material: string,
  udmmaterial: string,
  checked: boolean 
}

export interface SettingsAlertUniqueMaterialsFilter {   
  materialid: string,
  material: string,
  udmmaterial: string,
  checked: boolean 
}

export interface SettingsAlertLocationsFilter {
  locationid: string,
  location: string,
  checked: boolean 
}

export interface SettingsAlertFilters {
  locationsFilter: SettingsAlertLocationsFilter[],
  materialsFilter: SettingsAlertUniqueMaterialsFilter[],
  rulesFilter: SettingsAlertRulesFilter[]
}

export interface DisplayValues {
  displayValue: string,
  dataValue: string,
}


export interface AlertRule{
    [key: string]: any;
  ruleId?: string,
  ruleName: string,
  ruleDescription: string,
  desk: string, //good
  deskTag: string, //good
  deskNickName: string | null, //good
  deskRegion: string | null, //good
  deskId: number, //good
  materialGroupNumber: string,
  location: string,
  material: string,
  materialNumber?: string,
  ruleType: string, //good
  daysToValidate?: DaysToValidate,
  ruleCondition?: string,
  parameter?: string,
  thersold?: string,
  isCreditHoldForNomination?: boolean,
  alertFrequency: string,
  subscription: UserSubscriptionList,
  expression: string,
  successEvent?: string,
  createdBy: string, //good
  createdDate: string, //good
  updatedBy?: string, //good
  updatedDate?: string, //good
  isActive: boolean,  //good
  udmNickname?: string, //good
  id?: string, //good
}

export interface DaysToValidate{
  type?: string,
  NoOfdays?: string,
} 

export interface UserSubscriptionList{
  users: userSubscription[],
}

export interface userSubscription{
  userEmail: string,
  isEmail: boolean,
  isTeams: boolean,
  isWorkspace: boolean,
  isActive: boolean,
  headerActive?: boolean,
}


/*
 * Response from API that uses bundles the rules via location -> material -> rule mapping order
 */
export interface LocationMaterialRuleResponse{
  locationList: ruleLocationList[],
}

export interface ruleLocationList{
  location: string,
  locationMaterials: RuleLocationMaterial[],
}

export interface RuleLocationMaterial{
  material: string,
  locMaterialRules: AlertRule[],
}


//display vs selected option
export interface displaySelection{
  displayValue: string,
  fieldValue: string,
}

//Enum used for the edit/creation logic type, EX: RP, NOM, NONE
export enum RuleCategory{
  RP = "Replenishment Planning",
  NOM = "Nomination",
  NONE = "Not Selected"
}


export interface visualDisplay{
  displayValue: string,
  actualValue: any,
}

export interface dropDownInfo{
  dropdownDisplayName: string,
  fieldName: string, 
  option: visualDisplay[],
}