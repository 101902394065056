<div class="panel-content">
  <ng-container>
    <div>
      <div class="content__header">location
      </div>
      <form class="branded">
        <input class="form-control contract-balancing-search " [name]="'searchText0'" type="search"
          placeholder="search" (input)="updateFilterList($any($event.target).value, 'locations',0)"
          [(ngModel)]="searchValue[0]">
          <div class="contract-balancing-side-panel">
            @for (obj of updateSearchData.locations; track obj; let i = $index) {
              <p
                class="align-items-center d-flex">
                <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name"
                  [value]="obj.name" [checked]="obj.checked" (change)="filterData($event, 'locations', i)">
                  <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                    {{obj.name}}
                  </label>
                </p>
              }
            </div>
          </form>
          <hr>
          </div>
          <div>
            <div class="content__header">supplier
            </div>
            <form class="branded">
              <input class="form-control contract-balancing-search " [name]="'searchText1'" type="search"
                placeholder="search" (input)="updateFilterList($any($event.target).value, 'suppliers',1)"
                [(ngModel)]="searchValue[1]">
                <div class="contract-balancing-side-panel">
                  @for (obj of updateSearchData.suppliers; track obj; let i = $index) {
                    <p
                      class="align-items-center d-flex">
                      <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name"
                        [value]="obj.name" [checked]="obj.checked" (change)="filterData($event, 'suppliers', i)">
                        <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                          {{obj.name}}
                        </label>
                      </p>
                    }
                  </div>
                </form>
                <hr>
                </div>
                <div>
                  <date-range-picker [dateRangeData]="dateRangeData" [restrictStartDate]="true"
                    (updateDateRangeData)="dateRangegroup($event)">
                  </date-range-picker>
                  <hr>
                  </div>
                  <div>
                    <div class="content__header">SAP contract no.
                    </div>
                    <form class="branded">
                      <input class="form-control contract-balancing-search " [name]="'searchText3'" type="search"
                        placeholder="search" (input)="updateFilterList($any($event.target).value, 'contractNumbers',3)"
                        [(ngModel)]="searchValue[2]">
                        <div class="contract-balancing-side-panel">
                          @for (obj of updateSearchData.contractNumbers; track obj; let i = $index) {
                            <p
                              class="align-items-center d-flex">
                              <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name"
                                [value]="obj.name" [checked]="obj.checked"
                                (change)="filterData($event, 'contractNumbers', i)">
                                <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                  {{obj.name}}
                                </label>
                              </p>
                            }
                          </div>
                        </form>
                        <hr>
                        </div>
                        <div>
                          <div class="content__header">SRA deal no.
                          </div>
                          <form class="branded">
                            <input class="form-control contract-balancing-search " [name]="'searchText4'" type="search"
                              placeholder="search" (input)="updateFilterList($any($event.target).value, 'sraDealNumbers',4)"
                              [(ngModel)]="searchValue[3]">
                              <div class="contract-balancing-side-panel">
                                @for (obj of updateSearchData.sraDealNumbers; track obj; let i = $index) {
                                  <p
                                    class="align-items-center d-flex">
                                    <input type="checkbox" class="flex-shrink-0" name="ethanolCheckbox" [id]="obj.name"
                                      [value]="obj.name" [checked]="obj.checked"
                                      (change)="filterData($event, 'sraDealNumbers', i)">
                                      <label class="text-ellipsis" [title]="obj.name" [htmlFor]="obj.name">
                                        {{obj.name}}
                                      </label>
                                    </p>
                                  }
                                </div>
                              </form>
                              <hr>
                              </div>


                            </ng-container>
                          </div>

                          <div class="panel-footer">
                            <span class="clear-filter" (click)="cbClearFilter()">reset to default</span>
                          </div>