import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'white-header',
  templateUrl: './white-header.component.html',
  styleUrls: ['./white-header.component.scss']
})
export class WhiteHeaderComponent implements OnInit {

  @Input() displayText: string = ''
  @Input() arrowVisible: boolean = false;
  @Input() flipArrow: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  changeArrowDirection(){
    this.flipArrow = !this.flipArrow;
  }

}
