import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ContractBalancingService } from 'src/app/services/contract-balancing.service';
import { selectDesk } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { DealDataUpdated } from '../../../../assets/data/DealDataUpdated';
import { SecondaryFilter,AppliedSecondaryFilters } from 'src/app/shared/interface/cb-dynamic-deals.interface';
import { dealsAllData, secondaryFilters, selectedSecondaryFilters } from 'src/app/dynamicdeals/store/selector/cb-dynamic-deals.selector';
import { applyDDSecondaryFilter, resetDDSecondaryFilter } from 'src/app/dynamicdeals/store/action/cb-dynamic-deals.action';
import { DynamicDealsSharedStateService } from 'src/app/services/dynamic-deals-shared-state.service';

@Component({
  selector: 'app-contract-dynamic-deals-filter',
  templateUrl: './contract-dynamic-deals-filter.component.html',
  styleUrls: ['./contract-dynamic-deals-filter.component.scss']
})
export class ContractDynamicDealsFilterComponent implements OnInit {
  searchText: any = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  desk$ = this.store.pipe(select(selectDesk));
  selectedDesk: any;
  selectedDeskId: number = 0;
  // filterApiInvoked: boolean = false;
  // dynamic deals secondary filters
  exposureList:any= [];
  termList:any = [];
  indicatorList:any = [];
  schedulertypesList:any = [];
  showSchedulerType = false;
  secondaryFilters$ = this.store.select(dealsAllData);
  secondaryFiltersObj:SecondaryFilter={exposure:{exposure_Array:[],displayCount:0},term:{term_Array:[],displayCount:0},indicators:{indicators_Array:[],displayCount:0},schedulertypes:{schedulertypes_Array:[],displayCount:0},counterParty:{counterParty_Array:[],displayCount:0},location:{location_Array:[],displayCount:0}};
  originalFiltersObj:SecondaryFilter={exposure:{exposure_Array:[],displayCount:0},term:{term_Array:[],displayCount:0},indicators:{indicators_Array:[],displayCount:0},schedulertypes:{schedulertypes_Array:[],displayCount:0},counterParty:{counterParty_Array:[],displayCount:0},location:{location_Array:[],displayCount:0}};
  @Output() selectedSecondaryFilters = new EventEmitter();
  deskState$!:Subscription;
  deskState!:string;
  constructor(private store: Store, private utilities: Utilities, private cbStateService:DynamicDealsSharedStateService ) {
  }

  ngOnInit(): void {
    this.initializeDefaultValues();

    this.desk$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        // this.filterApiInvoked = false;
        this.selectedDesk = this.utilities.cbDesk(response);
        this.selectedDeskId = +response.selectedDeskId;
        this.searchText = [];
        //scheduler types - Specific to CPL - not applicable for other desks
        if(this.selectedDeskId==6){
            this.showSchedulerType = true;
        }
        else{
          this.showSchedulerType = false;
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => { }
    });

    this.deskState$ = this.cbStateService.deskState$.subscribe((deskstate:any) => {
      if(deskstate){
      this.deskState = deskstate;
      }
    })

  }

  initializeDefaultValues(){
    this.searchText = [];
    this.secondaryFilters$.subscribe(item=> {

    this.exposureList= item.filters.secondary.exposure;
    this.termList = item.filters.secondary.term;
    this.indicatorList = item.filters.secondary.indicators;
    if(this.showSchedulerType==true){
      this.schedulertypesList = item.filters.secondary.schedulertypes;
    }
    else
    {
      this.schedulertypesList = [];
    }
    let uniqueCounterPartyArray:any = item.filters.secondary.counterParty;
    let uniqueLocationArray:any = item.filters.secondary.location;

    //Checking if selected filters value differs from original filters
    const selectedExposureList = item.selectedFilters.secondary.exposure;
    const selectedTermList = item.selectedFilters.secondary.term;
    const selectedIndicatorsList = item.selectedFilters.secondary.indicators;
    const selectedSchedulerTypesList = item.selectedFilters.secondary.schedulertypes;
    const selectedCounterPartyList = item.selectedFilters.secondary.counterParty;
    const selectedLocationList = item.selectedFilters.secondary.location;

    const areExposureListIdentical = this.areArraysIdentical(this.exposureList,selectedExposureList);
    const areTermListIdentical = this.areArraysIdentical(this.termList,selectedTermList);
    const areIndicatorsListIdentical = this.areArraysIdentical(this.indicatorList,selectedIndicatorsList);
    const areSchedulerTypesListIdentical = this.areArraysIdentical(this.indicatorList,selectedSchedulerTypesList);
    const areCounterPartyListIdentical = this.areArraysIdentical(uniqueCounterPartyArray,selectedCounterPartyList);
    const areLocationListIdentical = this.areArraysIdentical(uniqueLocationArray,selectedLocationList);

    if(!areExposureListIdentical){
      this.exposureList = this.updateSelectedFilters(this.exposureList,selectedExposureList);
    } else {
      //assigning initial values to secondary filters
      //exposure
      this.exposureList = this.exposureList.map((exposure:any)=>({
        name:exposure,
        checked: true,
        disabled: exposure !== 'all',
      }));
    }
    this.secondaryFiltersObj.exposure =  {exposure_Array:this.exposureList, displayCount: 4}; //this.exposureList;
    this.originalFiltersObj.exposure =  {exposure_Array:this.exposureList, displayCount: 4}; //this.exposureList;
  
    if(!areTermListIdentical){
      this.termList = this.updateSelectedFilters(this.termList,selectedTermList);
    }else {
      //assigning initial values to secondary filters
      //term
      this.termList = this.termList.map((term:any)=>({
        name:term,
        checked: true,
        disabled: term !== 'all',
      }));
    }
  
    this.secondaryFiltersObj['term'] = {term_Array:this.termList, displayCount: 4};//this.termList;
    this.originalFiltersObj['term'] = {term_Array:this.termList, displayCount: 4}; //this.termList;

    if(!areIndicatorsListIdentical){
      this.indicatorList = this.updateSelectedFilters(this.indicatorList,selectedIndicatorsList);
    }else {
      //assigning initial values to secondary filters
      //indicators
      this.indicatorList = this.indicatorList.map((indicator:any)=>({
        name:indicator,
        checked: true,
        disabled: indicator !== 'all',
      }));
    }
    const displayCount  = this.secondaryFiltersObj.indicators.indicators_Array.length > 4 ? 6:4;
    this.secondaryFiltersObj.indicators = {indicators_Array:this.indicatorList, displayCount: displayCount};
    this.originalFiltersObj.indicators = {indicators_Array:this.indicatorList, displayCount: displayCount};

    //scheduler types - Specific to CPL - not applicable for other desks
    if(this.showSchedulerType == true){
      if(!areSchedulerTypesListIdentical){
        this.schedulertypesList = this.updateSelectedFilters(this.schedulertypesList,selectedSchedulerTypesList);
      }else {
        //assigning initial values to secondary filters
        this.schedulertypesList = this.schedulertypesList.map((schedulertypes:any)=>({
          name:schedulertypes,
          checked: true,
          disabled: schedulertypes !== 'all',
        }));
      }
    }
    else
    {
      this.schedulertypesList=[];
    }
    this.secondaryFiltersObj['schedulertypes'] = {schedulertypes_Array:this.schedulertypesList, displayCount: 4};
    this.originalFiltersObj['schedulertypes'] = {schedulertypes_Array:this.schedulertypesList, displayCount: 4};
     
    if(!areCounterPartyListIdentical){
      uniqueCounterPartyArray = this.updateSelectedFilters(uniqueCounterPartyArray,selectedCounterPartyList);
    }else {
      //counterParty
      uniqueCounterPartyArray= uniqueCounterPartyArray.map((counterparty:any)=>({
        name:counterparty,
        checked: true,
        disabled: counterparty !== 'all',
      }));
    }
    this.secondaryFiltersObj['counterParty'] = {counterParty_Array:uniqueCounterPartyArray, displayCount: 4};
    this.originalFiltersObj['counterParty'] =  {counterParty_Array:uniqueCounterPartyArray, displayCount: 4};


    if(!areLocationListIdentical){
      uniqueLocationArray = this.updateSelectedFilters(uniqueLocationArray,selectedLocationList);
    }else {
      //location
      uniqueLocationArray= uniqueLocationArray.map((location:any)=>({
        name:location,
        checked: true,
        disabled: location !== 'all',
      }));
    }
    this.secondaryFiltersObj['location'] = {location_Array: uniqueLocationArray, displayCount: 4};
    this.originalFiltersObj['location'] = {location_Array: uniqueLocationArray, displayCount: 4};
     
  });
  }

  // to check and uncheck selected filters correctly after clicking apply
  updateSelectedFilters(originalFilters:any,selectedFilters:any) {
    originalFilters = originalFilters.map((exposure:any)=>({
      name:exposure,
      checked: selectedFilters?.find((value:any)=>value === exposure)? true: false,
      disabled: false,
    }));
    const checkIfAllValuesSelected = originalFilters?.filter((value:any)=>value.name.toLowerCase() !== 'all').every((value:any)=>value.checked);
    checkIfAllValuesSelected && originalFilters?.forEach((value:any)=>{if(value?.name?.toLowerCase() === 'all'){value.checked = true} });
    const checkIfAllSelected = originalFilters.find((value:any)=>value.name.toLowerCase() === 'all' && value.checked);
    if(checkIfAllSelected){
      originalFilters.forEach((value:any)=>{
        value.checked = true;
        value.disabled = value.name !== 'all';
    });
    }
    return originalFilters;
  }

  updateFilterList(event: any, filterName: any, index: number) {
    this.searchText[index] = { value: event?.toLowerCase(), filterName };
    this.searchText.forEach((text: any, i: any) => {
      if (text) {
        if (text.filterName === filterName) {
          let updatedFilterList = [...this.originalFiltersObj[filterName][`${filterName}_Array`].filter((ele: any) => ele.name?.toLowerCase().includes(text.value?.toLowerCase()) || ele.name?.toLowerCase() === 'all')];
          this.secondaryFiltersObj[filterName][`${filterName}_Array`] = JSON.parse(JSON.stringify(updatedFilterList));
        }
      } else {
        this.secondaryFiltersObj[filterName][`${filterName}_Array`] = JSON.parse(JSON.stringify(this.originalFiltersObj[filterName][`${filterName}_Array`]));
      }
    })
  }

  filterData(event: any, filterName: any, index: number) {
    const value = event?.target?.value?.toLowerCase();
    const isChecked = event?.target?.checked;
    if (value === 'all') {
      this.secondaryFiltersObj[filterName][`${filterName}_Array`].forEach((filterValue: any) => {
        filterValue.checked = isChecked;
        filterValue.disabled =  filterValue.name !== 'all' && isChecked;
      });
      this.originalFiltersObj[filterName][`${filterName}_Array`].forEach((filterValue: any) => {
        filterValue.checked = isChecked;
        filterValue.disabled =  filterValue.name !== 'all' && isChecked;
      });
    } else {
      // const indexInSecondaryFiltersObj = this.originalFiltersObj[filterName][`${filterName}_Array`].findIndex((filterValue:any)=>filterValue.name.toLowerCase() === value);
      const allIndexInOriginalFiltersObj = this.originalFiltersObj[filterName][`${filterName}_Array`].findIndex((filterValue:any)=>filterValue.name.toLowerCase() === 'all');
      const indexInOriginalFiltersObj = this.originalFiltersObj[filterName][`${filterName}_Array`].findIndex((filterValue:any)=>filterValue.name.toLowerCase() === value);
      // to enable the selected value's checkbox
      // this.secondaryFiltersObj[filterName][`${filterName}_Array`][indexInSecondaryFiltersObj].checked = isChecked;
      if(allIndexInOriginalFiltersObj != -1 && !isChecked)
      {
      this.originalFiltersObj[filterName][`${filterName}_Array`][allIndexInOriginalFiltersObj].checked = isChecked;
      }
      this.originalFiltersObj[filterName][`${filterName}_Array`][indexInOriginalFiltersObj].checked = isChecked;
      // to check if all other values checked and select 'all' checkbox automatically
      const isAllOtherValuesChecked = this.originalFiltersObj[filterName][`${filterName}_Array`].filter((filterValue:any)=>filterValue.name !== 'all').every((filterValue:any)=>filterValue.checked);
      const mockEvent = {target:{value:'all',checked: true}};
      isAllOtherValuesChecked && this.filterData(mockEvent,filterName,0);
    }
  }

  resetFilters() {
    this.initializeDefaultValues();
    this.applyFilters();
    this.store.dispatch(resetDDSecondaryFilter())
  }

  seeMoreValues(filterName:any){
    this.secondaryFiltersObj[filterName].displayCount +=5;
  }

  applyFilters(){
    const appliedFiltersObj = JSON.parse(JSON.stringify(this.originalFiltersObj));
    // formatting applied filters to consume in filter panel component
    const formattedAppliedFiltersObj:AppliedSecondaryFilters={};
    for(let key in appliedFiltersObj){
      formattedAppliedFiltersObj[key]= appliedFiltersObj[key][`${key}_Array`]?.filter((obj:any) => obj.checked && obj.name.toLowerCase() !== 'all').map((obj:any)=>obj.name);
    }

    this.store.dispatch(applyDDSecondaryFilter({filters:formattedAppliedFiltersObj}))
  }

  areArraysIdentical(array1:any,array2:any) {
    if(array1.length !== array2.length){
      return false;
    }

    for(let i=0;i<array1.length;i++) {
      if(array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.deskState$.unsubscribe();
  }

}
