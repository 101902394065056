<p class="header">{{cardValues.headerValue}}</p>
@if (cardValues.tableValues.length != 0) {
  <div class="table-responsive">
    <table class="table table-sm">
      <thead>
        <tr style="border-bottom: 1px solid #000;">
          <th resizable>New {{cardValues.headerValue}}</th>
          <th resizable>Original {{cardValues.headerValue}}</th>
          <th resizable>Changed By</th>
          <th resizable>Changed On</th>
        </tr>
      </thead>
      <tbody>
        @for (row of this.cardValues.tableValues; track row) {
          <tr class="cell-style">
            <td class="cell-value p-0 pl-3">{{row.newValue}}</td>
            <td class="cell-value p-0 pl-3">{{row.oldValue}}</td>
            <td class="cell-value p-0 pl-3">{{row.changedBy}}</td>
            <td class="cell-value p-0 pl-3">{{row.changedAt}}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}