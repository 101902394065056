import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'push-mcat-modal',
  templateUrl: './push-mcat-modal.component.html',
  styleUrls: ['./push-mcat-modal.component.scss']
})
export class PushMcatModalComponent implements OnInit {
  @Input() header: string = '';
  @Input() description: string = '';
  @Input() linkedMsg: string = '';
  @Input() message: string = '';
  @Input() modalPopupObjective: string = '';
  @Input() headerIcon: any;
  @Input() headerLinkIcon: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
