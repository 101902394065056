import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CellValueChangedEvent, ColDef, ColGroupDef, GetRowIdFunc, GetRowIdParams, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { CustomHeaderComponent } from '../../custom-header.component';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AutoCompleteComponent } from '../../autocomplete/autocomplete.component';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { NominationService } from 'src/app/services/nomination.service';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { getUserDetails, selectDesk, selectPipelineNomFiltersResponse, selectUDMScreen } from 'src/app/shared/store/selector/app.selector';
import { savePipelineNomFiltersResponse, savePipelineNomFiltersUpdated } from 'src/app/shared/store/action/app.action';
import { DatePipe } from '@angular/common';
import { CargoPlanningDetailCellRendererComponent } from '../cargo-planning-detail-cell-renderer/cargo-planning-detail-cell-renderer.component';
import { DatePickerComponent } from 'src/app/shared/modules/datapicker/datepicker.component';
import * as Nom from '../../../shared/constants/nominations.constant';
import { CreatePipelineNominationComponent } from "../../../core/components/create-pipeline-nomination/create-pipeline-nomination.component";
import { RestrictedInputLengthCellEditorComponent } from 'src/app/shared/modules/ag-grid-renderers/restricted-input-length-cell-editor-component/restricted-input-length-cell-editor.component';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-cargo-planning',
  templateUrl: './cargo-planning.component.html',
  styleUrls: ['./cargo-planning.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CargoPlanningComponent implements OnInit, OnDestroy {

  public components: {
    [p: string]: any;
  } = {
      agColumnHeader: CustomHeaderComponent,
      autoComplete: AutoCompleteComponent
    };
  public detailRowHeight = 100;
  public isCargoScreen = false;
  public selectedDesk: any;
  public vesselBargeDetails: any;
  public vesselBargeFilterDetails: any;
  public locationDetails: any;
  public updatedVesselBargeDetails: any;
  public detailCellRenderer: any = CargoPlanningDetailCellRendererComponent;
  public detailCellRendererParams: any;
  public updateDetails: any = [];
  public updateCargoPayload: any = [];
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  getUDMScreen$ = this.store.pipe(select(selectUDMScreen))
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  selectedPipelineNomFiltersResponse$ = this.store.pipe(select(selectPipelineNomFiltersResponse));
  destroy$: Subject<boolean> = new Subject<boolean>();
  gridApi: any;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  modalTitle = "creating new pipeline nomination";

  desk$ = this.store.pipe(select(selectDesk));

  createNominationPipelineModal = { open: false, modal: 'createPipelineNomination' }
  @ViewChild(CreatePipelineNominationComponent) createPipelineNominationComponent!: CreatePipelineNominationComponent;


  rowData: any[] = [];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    filter: true,
  };
  colDefs: (ColDef | ColGroupDef)[] = [
    {
      field: '',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      maxWidth: 50,
      resizable: false,
      sortable: false,
      lockVisible: true,
      suppressMovable: false,
    },
    { headerName: 'status', field: "status", colId: 'status', minWidth: 180, width: 200, maxWidth: 300, sortable: true, cellRenderer: 'agGroupCellRenderer' },
    { headerName: 'nom no', field: "nominationNo", minWidth: 170, width: 180, maxWidth: 300, sortable: true, editable: true, cellEditor: RestrictedInputLengthCellEditorComponent },
    {
      headerName: 'vessel/barge name',
      field: "vesselName",
      wrapHeaderText: true,
      minWidth: 150,
      width: 150,
      maxWidth: 200,
      sortable: true,
      editable: true,
      cellEditor: AutoCompleteComponent,
      cellEditorParams: () => {
        return {
          propertyRendered: 'name',
          showedColumn: 'name',
          region: this.region,
          desk: this.selectedDesk,
          deskParameter: this.selectedDeskParameter,
          rowData: {},
          columnDefs: [
            {
              filter: 'agTextColumnFilter',
              field: 'name',
              valueFormatter: (event: any) => {
                let data = event.data;
                return Object.keys(data).length > 0 ? `${data?.name}_(${data?.id})` : '';
              },
            },
            {
              field: 'name',
              valueGetter: (params: any) => console.log(params),
              filter: 'agTextColumnFilter',
              hide: true,
            },
          ],
          popup: true,
        }
      }
    },
    {
      headerName: "load laycan",
      groupId: "loadlaycan",

      marryChildren: true,
      headerClass: 'align-parent-item',
      children: [
        {
          headerName: 'start',
          field: 'uiLoadLaycanStartDate',
          minWidth: 150,
          width: 150,
          maxWidth: 200,
          sortable: true,
          headerClass: 'align-children-item',
          comparator: Utilities.dateComparator
        },
        {
          headerName: 'end',
          field: 'uiLoadLaycanEndDate',
          minWidth: 150,
          width: 150,
          maxWidth: 200,
          sortable: true,
          headerClass: 'align-children-item',
          editable: true, cellEditorParams: {resetToMin: true},
          cellEditor: 'datePicker',
          comparator: Utilities.dateComparator
        }
      ]
    },
    {
      headerName: "delivery laycan",
      groupId: 'delivery laycan',

      marryChildren: true,
      headerClass: 'align-parent-item',
      children: [
        {
          headerName: 'start',
          field: 'uiDeliveryLaycanStartDate',
          minWidth: 150,
          width: 150,
          maxWidth: 200,
          sortable: true,
          headerClass: 'align-children-item',
          comparator: Utilities.dateComparator
        },
        {
          headerName: 'end',
          field: 'uiDeliveryLaycanEndDate',
          minWidth: 150,
          width: 150,
          maxWidth: 200,
          sortable: true,
          headerClass: 'align-children-item',
          editable: true, cellEditorParams: {resetToMin: true},
          cellEditor: 'datePicker',
          comparator: Utilities.dateComparator
        }
      ]
    },
    {
      headerName: 'products',
      groupId: 'products',
      sortable: true,
      openByDefault: false,
      headerClass: 'align-parent-item',
      children: []
    },
    {
      headerName: 'mb total',
      valueGetter: (params: any) => {
        let total = 0;
        let OTYpe =  params?.data?.cargoSchedules?.some((element: any) => element.scheduleType === "O");
        let checkType = OTYpe ? "O" : "D";
        params?.data?.cargoSchedules?.forEach((element: any) => {
          if(element.scheduleType === checkType) {
          element?.products?.forEach((dt: any) => {
            total += dt?.scheduledQty;
          });
        }
        });
        return total;
      },
      minWidth: 150,
      width: 150,
      maxWidth: 200,
    },
    { headerName: 'comments', field: "comments", minWidth: 180, width: 180, maxWidth: 300, sortable: true, tooltipField: 'comments', editable: true },
  ];
  userEmail: any;
  userFullEmail: any;
  currentScreen: any;
  selectedDeskParameter: any;
  region: any;
  getCargoFilterResponse: any;
  public materialCode: any[] = [];
  filterData: any;
  selectedCargoData: any;
  frameworkComponents: any;
  copyRowData: any;
  deleteInitialPayload: any[] = [];
  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  constructor(public cargoPlanningService: CargoPlanningService, private nominationService: NominationService, private appStore: Store<AppState>,
    private utilities: Utilities, private store: Store, private datePipe: DatePipe, private toaterService: ToastService) {
    this.frameworkComponents = { datePicker: DatePickerComponent };
  }

  ngOnInit(): void {
    this.userDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.userDetails.isUser) {
            this.userEmail = this.utilities.getUserName(response.userDetails.email);
            this.userFullEmail = response.userDetails.email;
            this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
          }
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.getUDMScreen$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.currentScreen = response.screen
        },
        error: (err: any) => {
          console.log(err);
        },
        complete: () => { },
      });

      this.nominationService.triggerDeselectForCargo.subscribe(res => {
        if(res){
          this.gridApi?.deselectAll();
        }
      })

    this.selectedDesk$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.selectedDesk = response.selectedDesk;
          this.selectedDeskParameter = response.selectedDeskParam;
          this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        },
        error: (err: any) => { },
        complete: () => { },
      });
    this.isCargoScreen = this.nominationService?.isCargoScreen?.value;
    this.selectedPipelineNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        if (response.isPipelineNomFilterUpdated && this.nominationService?.isCargoScreen?.value) {
          this.getCargoFilterResponse = response;
          const vesselBargeFilterData = response?.pipelineNomFilterValues?.find((data: any) => data?.headerName === "vessel/barge name");
          const locationFilterData = response?.pipelineNomFilterValues?.find((data: any) => data?.headerName === "location" || data?.headerName === "terminal");
          this.vesselBargeFilterDetails = vesselBargeFilterData?.list?.filter((data: any) => data?.id).map((el: any) => { return { name: el?.name, id: el?.id } });
          this.locationDetails = locationFilterData?.list?.filter((data: any) => data?.id).map((el: any) => { return { name: el?.name, id: el?.id } });
          this.detailCellRendererParams = { locationDetails: this.locationDetails, userFullEmail: this.userFullEmail };
          this.nominationService.cpFilters.next(response);
          this.cargoPlanningService.isCargoScreen = false;
          this.appStore.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: false }));
          this.getCargoPlanningData(this.getCargoFilterResponse);
        }
      },
      error: (err: any) => { },
      complete: () => { }

    });


    this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
      if (response.function === 'pipelineCreateNominationWithReference') {
        this.createPipelineNominationComponent.presetNominationDataForCargo();
        this.toggleCreatePipelineNominationsModal();
      }
      if (response.function === 'createPipelineNomination') {
        this.toggleCreatePipelineNominationsModal();
      }
    })
  }

  onRowSelect(event: any) {
    let selectedRows = this.gridApi.getSelectedRows();
    this.nominationService.nomSelected$.next(selectedRows.length === 1);
    this.nominationService.isCpGridSelected = selectedRows.length ? selectedRows : "";
  }

  getCargoPlanningData(data: any) {
    const payload = {
      vesselName: this.setFilterData(data?.pipelineNomFilterValues?.find((el: any) => el.headerName === "vessel/barge name")),
      fromDate: data?.pipelineFromDate,
      mot: this.setFilterName(data?.pipelineNomFilterValues?.find((el: any) => el.headerName === "mode of transport")),
      toDate: data?.pipelineToDate,
      terminal: this.setFilterData(data?.pipelineNomFilterValues?.find((el: any) => el.headerName === "location" || el.headerName === "terminal")),
      transportSystem: this.setFilterData(data?.pipelineNomFilterValues?.find((el: any) => el.headerName === "transport system")),
      product: this.setFilterData(data?.pipelineNomFilterValues?.find((el: any) => el.headerName === "product")),
      desk: `pl${this.selectedDeskParameter}`,
      region: this.region
    }
    payload.mot = payload?.mot?.filter((el: any) => (el?.toLowerCase() === 'vessel' || el?.toLowerCase() === 'barge'))
    this.cargoPlanningService.getFilterCargoPlanning(payload).subscribe({
      next: (response) => {
        this.isCargoScreen = false;
        this.appStore.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: false }));
        this.rowData = JSON.parse(JSON.stringify(response.result));
        let products: any = [];
        const result = this.rowData?.map((el: any) => {
          el.cargoSchedules?.map((dt: any) => {
            dt.uiEtb = this.dateCellRenderer(dt?.uiEtb);
            dt.uiScheduledDate = this.dateCellRenderer(dt?.uiScheduledDate);
            dt.uiEndLoadDate = this.dateCellRenderer(dt?.uiEndLoadDate);
            dt?.products?.map((element: any) => {
              products.push(element.demandMaterialDesc);
            });
          });
          return el
        });
        Promise.all(result).then((value) => {
          this.copyRowData = JSON.parse(JSON.stringify(value));
        });
        this.colDefs?.forEach((el: any) => {
          if (el?.groupId === "products") {
            el["children"] = [];
          }
        });
        this.productHeaderNameCol(products);
        this.gridApi.setColumnDefs(this.colDefs);
        this.rowData.forEach(element => {
          element.uiLoadLaycanStartDate = this.dateCellRenderer(element?.uiLoadLaycanStartDate);
          element.uiLoadLaycanEndDate = this.dateCellRenderer(element?.uiLoadLaycanEndDate);
          element.uiDeliveryLaycanStartDate = this.dateCellRenderer(element?.uiDeliveryLaycanStartDate);
          element.uiDeliveryLaycanEndDate = this.dateCellRenderer(element?.uiDeliveryLaycanEndDate);
        });
        setTimeout(() => {
          this.gridApi.setRowData(this.rowData);
          this.gridApi?.deselectAll();
          this.gridApi?.redrawRows();
        }, 500);
      },
      error: (err) => {
        this.gridApi?.deselectAll();
        console.log(err);
      },
      complete: () => { }
    });
  }

  productHeaderNameCol(products: any) {
    const productHeaderName: any = new Set(products);
        productHeaderName?.forEach((element: any) => {
          this.colDefs?.forEach((el: any) => {
            if (el?.groupId === "products") {
              el["children"]?.push({
                headerName: element,
                valueGetter: (params: any) => {
                  let scheduledQtySum = 0;
                  return this.getScheduledQty(params, element, scheduledQtySum);;
                },
                minWidth: 200,
                width: 200,
                maxWidth: 300,
                wrapHeaderText: true,
                columnGroupShow: (params: any) => { return params?.column?.colDef?.headerName === productHeaderName[0] ? "closed" : "open" },
                headerClass: 'align-children-item'
              });
            }
          });
        });
  }

  getScheduledQty(params: any,element: any, scheduledQtySum: any){
    let OTYpe =  params?.data?.cargoSchedules?.some((element: any) => element.scheduleType === "O");
    let checkType = OTYpe ? "O" : "D";
    params?.data?.cargoSchedules?.forEach((xt: any) => {
      if(xt.scheduleType === checkType){
        xt?.products?.forEach((dt: any) => {
          if (element === dt?.demandMaterialDesc) {
            scheduledQtySum += dt?.scheduledQty;
          }
        });
      }
    });
    return scheduledQtySum;
  }

  updatePublishedNomComments(event: any) {
    if (event?.data?.status === "published" && event?.column?.getColId() === "comments") {
      return Nom.PUBLISHED
    } else if ((event?.data?.status === "published" || event?.data?.status === "failed") && event?.column?.getColId() !== "comments") {
      return Nom.UNPUBLISHED;
    } else {
      return event?.data?.status;
    }
  }

  onCellValueChanged(event: CellValueChangedEvent) {
    const payload: any = {
      id: event?.data?.id,
      status: this.updatePublishedNomComments(event),
      nomRefId: event?.data?.nomRefId,
      sys_modifiedOn: new Date().toISOString(),
      sys_modifiedBy: this.userFullEmail
    };
    const rowIndex = this.copyRowData?.map((el: any) => el?.id).indexOf(event?.data?.id);
    const keys = Object.keys(this.copyRowData[rowIndex]);
    for (const key of keys) {
      if (key !== "cargoSchedules" && this.copyRowData[rowIndex][key] !== event?.data[key] && key !== "id" && key !== "nomRefId") {
        this.cellValueChangeOtherThanCargoSchedules(event, key, payload);
      }
    }
    if (this.updateDetails?.map((el: any) => el?.id)?.includes(payload?.id)) {
      const rowIndex = this.updateDetails?.map((el: any) => el?.id)?.indexOf(payload?.id);
      this.updateDetails[rowIndex] = payload;
    } else {
      payload.nominationNo = payload?.nominationNo?.toUpperCase();
      this.updateDetails?.push(payload);
    }
    this.nominationService?.enableNominationsSaveButton$?.next(true);
  }

  cellValueChangeOtherThanCargoSchedules(event: any, key: any, payload: any){
    if (key === "vesselName") {
      const vesselNames: any = this.cargoPlanningService?.vesselNameOrVehicleName?.value;
      payload[key] = event?.data[key];
      payload["vesselId"] = vesselNames?.find((el: any) => el?.name === event?.data[key])?.id
      payload["tdVehNo"] = vesselNames?.find((el: any) => el?.name === event?.data[key])?.id
    } else if (key === "uiDeliveryLaycanEndDate") {
      let d = event?.data[key].split("/");
      const year = Number(d[2]);
      const month = Number(d[0]);
      const day = Number(d[1]);
      payload["deliveryLaycanEndDate"] = event?.data[key] === "01/01/0001" ? "0001-01-01T00:00:00" : new Date(Date.UTC(year, month - 1, day));
    } else if (key === "uiLoadLaycanEndDate") {
      let d = event?.data[key].split("/");
      const year = Number(d[2]);
      const month = Number(d[0]);
      const day = Number(d[1]);
      payload["loadLaycanEndDate"] = event?.data[key] === "01/01/0001" ? "0001-01-01T00:00:00" : new Date(Date.UTC(year, month - 1, day));
    } else {
      payload[key] = event?.data[key];
    }
  }

  dateCellRenderer(date: any) {
    if (date) {
      const year = Number(date.substr(0, 4));
      const month = Number(date.substr(4, 2));
      const day = Number(date.substr(6, 2));
      const dateFormat = new Date(year, month - 1, day);
      return this.datePipe?.transform(dateFormat, "MM/dd/yyyy");
    }
    return '';
  }

  setFilterData(filterArray: any) {
    let filterData: any = []
    if (filterArray?.list?.length > 0) {
      filterArray?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.id) {
          filterData?.push(dt?.id);
        }
      });
    }
    return filterData;
  }

  setFilterName(filterArray: any) {
    let filterData: any = []
    if (filterArray?.list?.length > 0) {
      filterArray?.list?.forEach((dt: any) => {
        if (dt?.checked && dt?.name !== "all") {
          filterData?.push(dt?.name);
        }
      });
    }
    return filterData;
  }
  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  ngOnDestroy(): void {
    this.nominationService.isNominationScreen = "nomination";
    this.nominationService.isCargoScreen.next(false);
    this.destroy$.unsubscribe();
  }

  updateCargoPlanningApi() {
    if (this.nominationService?.enableNominationsSaveButton$?.value) {
      if (this.updateDetails?.length > 0 && this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value?.length > 0) {
        this.updateCargoPlanningApiWhenupdateDetailsIsGrtThanZero();
      } else if (this.updateDetails?.length === 0 && this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value?.length > 0) {
        this.updateCargoPlanningApiWhenupdateDetailsIsZero();
      } else if (this.updateDetails?.length > 0 && this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value?.length === 0) {
        this.updateCargoPayload = JSON.parse(JSON.stringify(this.updateDetails));
      }
      if (this.updateCargoPayload?.length > 0) {
        this.updateCargoPlanning();
      }
    }
  }

  updateCargoPlanningApiWhenupdateDetailsIsGrtThanZero(){
    const detailPayload = JSON.parse(JSON.stringify(this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value));
        for (const element of detailPayload) {
          element?.cargoSchedules?.map((dt: any) => {
            let emptyProduct: any = []
            if (!dt?.products || dt?.products?.length === 0) {
              const rowIndex = this.copyRowData?.map((el: any) => el?.id)?.indexOf(element?.id);
              const detailRowIndex = this.copyRowData[rowIndex]?.cargoSchedules?.map((el: any) => el?.id)?.indexOf(dt?.id);
              this.copyRowData[rowIndex]?.cargoSchedules[detailRowIndex]?.products?.map((el: any) => emptyProduct?.push({ demandMaterial: el?.demandMaterial, scheduledMaterial: el?.scheduledMaterial, nomRefItemId: el?.nomRefItemId }));
              dt["products"] = JSON.parse(JSON.stringify(emptyProduct));
            } else {
              const rowIndex = this.copyRowData?.map((el: any) => el?.id)?.indexOf(element?.id);
              const detailRowIndex = this.copyRowData[rowIndex]?.cargoSchedules?.map((el: any) => el?.id)?.indexOf(dt?.id);
              this.copyRowData[rowIndex]?.cargoSchedules[detailRowIndex]?.products?.map((el: any) => {
                if (!dt?.products?.map((xt: any) => xt?.demandMaterial)?.includes(el?.demandMaterial)) {
                  dt?.products?.push({ demandMaterial: el?.demandMaterial, scheduledMaterial: el?.scheduledMaterial, nomRefItemId: el?.nomRefItemId });
                }
              });
            }
          });
          if (this.updateDetails?.map((el: any) => el?.id)?.includes(element?.id)) {
            const rowIndex = this.updateDetails?.map((el: any) => el?.id)?.indexOf(element?.id);
            this.updateDetails[rowIndex]["cargoSchedules"] = JSON.parse(JSON.stringify(element?.cargoSchedules));
          } else {
            this.updateDetails?.push(element?.cargoSchedules);
          }
        }
        this.updateCargoPayload = JSON.parse(JSON.stringify(this.updateDetails));
  }

  updateCargoPlanningApiWhenupdateDetailsIsZero(){
    const detailPayload = JSON.parse(JSON.stringify(this.cargoPlanningService?.cargoSchedulePayloadUpdates?.value));
        for (const element of detailPayload) {
          element?.cargoSchedules?.map((dt: any) => {
            let emptyProduct: any = []
            if (!dt?.products || dt?.products?.length === 0) {
              const rowIndex = this.copyRowData?.map((el: any) => el?.id)?.indexOf(element?.id);
              const detailRowIndex = this.copyRowData[rowIndex]?.cargoSchedules?.map((el: any) => el?.id)?.indexOf(dt?.id);
              this.copyRowData[rowIndex]?.cargoSchedules[detailRowIndex]?.products?.map((el: any) => emptyProduct?.push({ demandMaterial: el?.demandMaterial, scheduledMaterial: el?.scheduledMaterial, nomRefItemId: el?.nomRefItemId }));
              dt["products"] = JSON.parse(JSON.stringify(emptyProduct));
            } else {
              const rowIndex = this.copyRowData?.map((el: any) => el?.id)?.indexOf(element?.id);
              const detailRowIndex = this.copyRowData[rowIndex]?.cargoSchedules?.map((el: any) => el?.id)?.indexOf(dt?.id);
              this.copyRowData[rowIndex]?.cargoSchedules[detailRowIndex]?.products?.map((el: any) => {
                if (!dt?.products?.map((xt: any) => xt?.demandMaterial)?.includes(el?.demandMaterial)) {
                  dt?.products?.push({ demandMaterial: el?.demandMaterial, scheduledMaterial: el?.scheduledMaterial, nomRefItemId: el?.nomRefItemId });
                }
              });
            }
          });
          if (this.updateDetails?.map((el: any) => el?.id)?.includes(element?.id)) {
            const rowIndex = this.updateDetails?.map((el: any) => el?.id)?.indexOf(element?.id);
            this.updateDetails[rowIndex]["cargoSchedules"] = JSON.parse(JSON.stringify(element?.cargoSchedules));
          } else {
            this.updateDetails?.push(element?.cargoSchedules);
          }
        }
        this.updateCargoPayload = JSON.parse(JSON.stringify(detailPayload));
  }

  updateCargoPlanning(){
    this.cargoPlanningService?.updateCargoPlanning(this.updateCargoPayload).subscribe({
      next: (response: any) => {
        if(response.statusCode === 200 && response.success){
          this.toaterService.setToastNotification({
            show: true,
            type: "success",
            msg: "Cargo has been updated",
          });
        }
        this.nominationService?.enableNominationsSaveButton$?.next(false);
        const filterValues = this.nominationService?.cpFilters?.value
        this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterValues?.pipelineNomFilterValues)), pipelineFromDate: filterValues?.pipelineFromDate, pipelineToDate: filterValues?.pipelineToDate, isPipelineNomFilterUpdated: true }));
        this.updateCargoPayload = [];
        this.updateDetails = [];
        this.cargoPlanningService?.cargoSchedulePayloadUpdates?.next([]);

      },
      error: (err: any) => {
        console.log(err);
        this.toaterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message,
        });
        this.nominationService?.enableNominationsSaveButton$?.next(false);
        this.updateCargoPayload = [];
        this.updateDetails = [];
        this.cargoPlanningService?.cargoSchedulePayloadUpdates?.next([]);
      }
    });
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    let selectedRows = this.gridApi.getSelectedRows();
    this.nominationService.nomSelected$.next(selectedRows.length === 1);
    this.nominationService.isCpGridSelected = selectedRows.length ? selectedRows : "";
    this.deleteInitialPayload = JSON.parse(JSON.stringify(event?.api?.getSelectedRows()));
    this.nominationService.enableHeaderButton$?.next({ deleteButton: true, publishButton: false, vendorNotificationButton: false });
  }

  deleteCargoApi(event: any) {
    let deletePayload: any = [];
    this.deleteInitialPayload?.map((el: any) => {
      const payloadObject: any = {
        id: el?.id,
        cargoSchedules: [],
        sys_modifiedOn: new Date().toISOString(),
        sys_modifiedBy: this.userFullEmail,
        nomRefId:el?.nomRefId
      };
      el?.cargoSchedules?.map((dt: any) => {
        const cargoSchedules: any = {
          id: dt?.id,
          deletionIndicator: 1,
          products: []
        };
        dt?.products?.map((xt: any) => {
          const product: any = {
            demandMaterial: xt?.demandMaterial,
            scheduledMaterial: xt?.scheduledMaterial,
            nomRefItemId: xt?.nomRefItemId
          }
          cargoSchedules?.products?.push(product);
        });
        payloadObject?.cargoSchedules?.push(cargoSchedules);
      });
      const rowIndex = this.deleteInitialPayload?.map((el: any) => el?.id).indexOf(payloadObject?.id);
      this.deleteInitialPayload[rowIndex] = payloadObject;
    });
    deletePayload.push(...this.deleteInitialPayload);
    if (deletePayload?.length > 0) {
      this.cargoPlanningService?.updateCargoPlanning(deletePayload).subscribe({
        next: (response: any) => {
          if(response.statusCode === 200 && response.success){
            this.toaterService.setToastNotification({
              show: true,
              type: "success",
              msg: "Cargo has been deleted",
            });
          }
          this.deleteInitialPayload = [];
          const filterValues = this.nominationService?.cpFilters?.value
          this.appStore.dispatch(savePipelineNomFiltersResponse({ pipelineNomFilterValues: JSON.parse(JSON.stringify(filterValues?.pipelineNomFilterValues)), pipelineFromDate: filterValues?.pipelineFromDate, pipelineToDate: filterValues?.pipelineToDate, isPipelineNomFilterUpdated: true }));
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }


  closePipelineNominationModal(val: any) {
    const modal = { open: false, modal: 'createPipelineNomination' }
    this.createNominationPipelineModal = { ...modal };
    this.cargoPlanningService?.createNominaitonsFromCargo?.next({ open: false, modal: 'createPipelineNomination' });
    this.cargoPlanningService?.createNominationFromCargoWithReference?.next({ initiate: false, data: {} });
  }

  toggleCreatePipelineNominationsModal(): void {
    const modal = { open: !this.createNominationPipelineModal.open, modal: 'createPipelineNomination' }
    this.createNominationPipelineModal = { ...modal };
  }

}
