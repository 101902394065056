<div class="container">
  <h4 class="title mb-4">user vacation management</h4>

  <div class="d-flex flex-row  heightContainer">
    <div class="d-flex flex-column equalFlex max-height">
      <p class="left-title">your vacations <span class="hoverable" (click)="beginAdd()">
        <img class="hoverable" src="../../../assets/icons/icon_add_circle.svg" alt="add vacation"
          matTooltip="add vacation" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'">
        </span>
      </p>
      @if (userVacationData != null && userVacationData.userVacations?.vacationList != null) {
        <div class="vac-list mb-4">
          @for (vacation of userVacationData.userVacations.vacationList; track vacation; let i = $index) {
            <div
              class="mb-2 family">
              <span [ngClass]="{'boldText' : selectedIndex == i}">{{vacation.vacationStartDate | date}}
                - {{vacation.vacationEndDate | date}}
                <img class="" (click)="selectVacationDate(vacation, i)" class="hoverable" src="../../../assets/icons/icon_date_range.svg" alt="set vacation" alt="edit vacation"
                  matTooltip="edit vacation" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'">
                </span>
              </div>
            }
          </div>
        }
      </div>
      <div class="doubleFlex vertical-line calendar-holder"
        [ngClass]="{'calendarDisable' : !calendarInteractable}">
        @if (calendarInteractable && selectedDateRange != null) {
          <div class="d-flex flex-row justify-content-center dateheaderText">
            <span> @if (isNew) {
              <span>new vacation:</span>
              }{{selectedDateRange.start | date}}-{{selectedDateRange.end | date}}
              <img (click)="deleteVacation()" class="hoverable" src="../../../assets/icons/icon_trash.svg" alt="delete vacation" alt="delete vacation"
                matTooltip="delete vacation" [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'">
              </span>
            </div>
          }
          <mat-calendar #calendar disabled="selectedDateRange == null"
            [(selected)]="selectedDateRange" (selectedChange)="this.rangeChanged($event)">
          </mat-calendar>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-around">
        <button class="buttonSetup cancel" (click)="close()">cancel</button>
        <button class="buttonSetup save" (click)="save()">publish vacations</button>
      </div>
    </div>