<div class="d-flex flex-column">

  <div class="header d-flex flex-row justify-content-between align-items-center overflowScroll">
    <p style="margin-bottom: 0px; padding: 0 15px;" class="header-text">{{data.name}}</p>
    <p style="margin-bottom: 0px;">{{dateFormat}}</p>
  </div>

  <div class="d-flex flex-row justify-content-left overflowScroll">
    @for (header of headers; track header; let i = $index) {
      <p (click)="updateSelection(i)"
        class="m-0 mt-3 mr-2 ml-1 header-size"
        [ngClass]="this.selectedTabIndex == i ? 'tab-button-selected': 'tab-button'">
        <img [src]="header.displayIcon"/> {{header.displayValue}}
      </p>
    }
  </div>
  <hr class="seletion-line m-0 mb-2">

  @if (selectedSelector == 'activityTracker') {
    <activity-tracker
      [screenInformation]="data">
    </activity-tracker>
  }
  @if (selectedSelector == 'comments') {
    <comment-panel
      [data]="data">
    </comment-panel>
  }
  @if (selectedSelector != 'activityTracker' && selectedSelector != 'comments') {
    <div>Functionality coming soon</div>
  }
</div>