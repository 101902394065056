<div class="cell-information">
  <span class="text-truncate" [title]="value">{{ value }}</span>@if (isErrorPresent) {
  <button type="button"
  [class]="'cell-error-hover'" (click)="toggleDisplay($event)"></button>
}
@if (isDisplay) {
  <div class="cell-info-tooltip-content"  [ngClass]="[(params.rowIndex < 6)  ? '' : 'place-top']" (mouseleave)="isDisplay = false">
    {{errorMsg}}
  </div>
}
</div>