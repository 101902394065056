import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertSaveGuardService } from 'src/app/services/alert-save-guard.service';

@Component({
  selector: 'change-list-display',
  templateUrl: './change-list-display.component.html',
  styleUrls: ['./change-list-display.component.scss'],
})
export class ChangeListDisplayComponent implements OnInit {

  changeList: string[] = [];
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private saveGuard: AlertSaveGuardService) { }

  ngOnInit(): void {

    this.saveGuard.changeList.subscribe(list => {
      this.changeList = [];
      this.formatDisplayList(list);
    })
  }

  formatDisplayList(list: string[]){

    list.forEach(change => {

      let currentString = change.split(';')[0];
      let remaining = currentString.split(':')[1];
      let changeType = currentString.split(':')[0];

      let rule = remaining.split(',')[0];
      let location = remaining.split(',')[1]
      let material = remaining.split(',')[2]

      let finalString = `${rule} ${location} ${material} ${changeType}`;
      finalString = finalString.replace('undefined', ''); 
      this.changeList.push(finalString);
    });
  }

  fireClose(){
    this.close.emit(false);
  }
}
