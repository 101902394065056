import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { NominationService } from 'src/app/services/nomination.service';
import { Store, select } from '@ngrx/store';
import { getPipleineFilteredValues, selectDesk } from 'src/app/shared/store/selector/app.selector';
import { Subject, firstValueFrom, take, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { MdmService } from 'src/app/services/mdm.service';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { CargoPlanningCcaService } from 'src/app/services/cargo-planning-cca.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Supplier, productLabels, cargoTypes, Vessel, productList, newTSWCodeMap, newTSWDescMap} from 'src/app/shared/constants/cca-dummy.constatnt';
import { MyMonitoringService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-create-cargo-cca',
  templateUrl: './create-cargo-cca-popup.component.html',
  styleUrls: ['./create-cargo-cca-popup.component.scss']
})

export class CreateCargoCcaPopupComponent implements OnInit {
  @Input() isCcaNomEdit: any;
  @Output() closeModalPopup = new EventEmitter();
  public createCcaNominationForm!: FormGroup;
  vesselNameValue: any = "";
  planNameValue: any = "";
  cargoName: any = "";
  vesselArray:any = [];
  cargoModeList:any = []
  terminalData:any=[];
  supplierList:any=[];
  masterDeskList:any=[[]];
  deskList:any=[[]];
  isOriginRequired = [false];
  portcca:any=[[]];
  terminalCCA:any=[[]];
  supplierCCA:any=[[]];
  loadDischargeArray: any = [[]]
  deskArray:any = [[]];
  contryList: string[] = [];
  errorMessage= [false];
  allProductList = productLabels;
  originQtyValue: any = {
    "lsdQty": 0,
    "ron91Qty": 0,
    "ron95Qty": 0,
    "keroQty": 0,
    "jetAQty": 0,
    "avgasQty": 0,
    "ulsdQty": 0,
    "rdsQty": 0,
    "boQty": 0,
    "mgoQty": 0,
  };
  destinQtyValue: any = {
    "lsdQty": 0,
    "ron91Qty": 0,
    "ron95Qty": 0,
    "keroQty": 0,
    "jetAQty": 0,
    "avgasQty": 0,
    "ulsdQty": 0,
    "rdsQty": 0,
    "boQty": 0,
    "mgoQty": 0,
  };
  cargoIdsList: any = Object.fromEntries(Object.values(productLabels).map((key) => [key, ""]))
  inCpTab!:boolean;
  isCargoDesks: any;
  public userDetails!: any;
  nominationData!: any;
  desk$ = this.store.pipe(select(selectDesk))
  destroy$: Subject<boolean> = new Subject<boolean>();
  udmItineraryPlanName: string = '';
  getPipleineFilteredValues$ = this.store.pipe(select(getPipleineFilteredValues));
  scenario: any = "barge";
  transportData: any;
  selectedTerminal: any;
  cargoDeletedArr: any;
  portOptions:any=[[]];
  previousDropdownValues: {
    desk: string[];
    port: string[];
    terminal: string[];
  } = {
    desk: [],
    port: [],
    terminal: [],
  }
  countryList: string[] = []
  
  originArray: any;
  refOrginCount: any;
  qtyError: string = '';
  planBasedProducts: string[] = [];
  editCargoID: string = '';
  mcatVoyageStatus: string = '';
  editCargoDataRows: any;
  editedCargoDataRows: any;
  editCargoTopLevelData: any = {
    voyageName: "",
    vesselName: "",
    planName: "",
    comments: "",
    vesselTypeName: "",
  };
  refDataPayload = {
    type: "vesselType"
  };
  vesselTypeData: any;
  vesselTypeList: any[] = [];
  vesselTypeValue: string = '';
  tierMin: any = 0;
  tierMax: any = 0;
  loadTotal = 0;
  dischargeTotal = 0;
  loadMinMaxError = false;
  dischargeMinMaxError = false;
  dateArray: {start: string; end: string}[] = [];
  minDate = '';
  maxDate = '';
  isCanalCrossingError = '';
  mcatVoyageStatusList:any = ['on subs', 'commenced', 'completed', 'cancelled', 'fixed'];
  isLinkedNom: any;

  constructor(
    private fb: FormBuilder,
    private utilities: Utilities,
    public nominationService: NominationService,
    private router: Router,
    private toasterService: ToastService,
    private store: Store,
    public cpService: CargoPlanningService,
    public cpCCAService: CargoPlanningCcaService,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private appStore: Store<AppState>,
    private mdmService: MdmService,
    private myMonitoringService: MyMonitoringService
    ) {
    this.createCcaNominationForm= this.fb.group({
      cargoName:['', Validators.required],
      planName:['', Validators.required],
      vesselName:['', Validators.required],
      vesselType:['', Validators.required],
      canalCrossingDate: '',
      transport: this.fb.array([this.createFormGroupTransport(true)]),
      comments:'',
      isoriginDestiTouched: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.isCargoDesks=this.router.url === '/cargoPlanning';
    this.initialValues();
    this.setDummyVessels();
    this.getVesseTypes();
  }
  
  initialValues() {
    this.userDetails = this.utilities.getUserName(this.nominationService.userDetails$.value._email);
    this.desk$.pipe(takeUntil(this.destroy$), take(1)).subscribe({
      next: (response: any) => {
        this.udmItineraryPlanName = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        if (!this.isCargoDesks) {
          this.inCpTab = false;
          this.nominationService.isNominationScreen = '';
        }
        this.nominationService.isNominationScreen = (this.nominationService.isNominationForCP) ? 'nomination' : 'cargo';
        this.modifyFormForCp(0, true);
        this.updateFormFields(this.scenario)
        this.setDummyVessels();
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => {
      }
    })
  }

  getVesseTypes() {
    this.cpService.getCargoRefData(this.refDataPayload).subscribe({
      next: (response: any) => {
        if(response.success) {
          this.cpService.cargoVesselTypes = response.result;
          this.vesselTypeList = response.result.map((vesselType: any) => vesselType.vesselTypeName)
        }
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => {
      }
    })
  }

  origin(index: number) {
    return this.transportArray().at(index).get('origin') as FormArray;
  }
  
  transportArray() {
    return this.createCcaNominationForm.get('transport') as FormArray;
  }

  createFormGroupOrigin(): FormGroup {
    this.isCargoDesks=this.router.url === '/cargoPlanning';
    return this.fb.group({
      desk: ['', Validators.required],
      loadDischarge: ['', Validators.required],
      loadDischargeTotal:[''],
      terminal:[''],
      port:['', Validators.required],
      supplierReceiver:['', Validators.required],     
      lyacanStartDate:['', Validators.required],
      lyacanEndDate:['', Validators.required],   
      ron91Qty: [''],
      ron95Qty: [''],
      avgasQty: [''],
      boQty: [''],
      jetAQty: [''],
      keroQty: [''],
      lsdQty: [''],
      mgoQty: [''],
      rdsQty: [''],
      ulsdQty: [''],
    },
        { validator: [this. customRequiredValidatorOrig.bind(this)] }
    )    
  }

  createFormGroupTransport(init?: boolean): FormGroup {
    if(init) {
      return this.fb.group({
        origin: this.fb.array([this.createFormGroupOrigin(), this.createFormGroupOrigin(), this.createFormGroupOrigin()]),  
      })
    } else {
      return this.fb.group({
        origin: this.fb.array([this.createFormGroupOrigin()]),  
      })
    }
  }

  customRequiredValidatorOrig(g: any) {
    let fields = [
      'desk',
      'loadDischarge',
      'port',
      'supplierReceiver',
      'lyacanStartDate',
      'lyacanEndDate',
    ];
    if ( g?.value['desk']  || g?.value['loadDischarge']||  g?.value['supplierReceiver'] ||  g?.value['port'] || g?.value['lyacanStartDate'] || g?.value['lyacanEndDate'] ) {
      this.isOriginRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
      g.get(ele).setValidators(Validators.required);
      if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
    })
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createCcaNominationForm.get('isoriginDestiTouched')?.clearValidators()
    } else {
      this.isOriginRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();
      })
    }
  }

  onSetCanalCrossing() {
    if(this.createCcaNominationForm?.value?.canalCrossingDate) {
      const canalCrossing = new Date(JSON.parse(JSON.stringify(this.createCcaNominationForm.value.canalCrossingDate)));
      if(this.minDate && this.maxDate) {
        const maxDateObj = new Date(this.maxDate)
        const minDateObj = new Date(this.minDate)
        if(canalCrossing.getTime() < minDateObj.getTime() || canalCrossing.getTime() > maxDateObj.getTime()) {
          this.isCanalCrossingError = "Canal Crossing date outside Laycan Dates";
        } else {
          this.isCanalCrossingError = '';
        }
      } else {
        this.isCanalCrossingError = '';
      }
    } else {
      this.isCanalCrossingError = '';
    }
  }

  validateProducts() {
    let formOri = this.transportArray().at(0).get('origin') as FormArray;
    let row = 0;
    let isLoadThere = false;
    let isDischargeThere = false;
    this.resetAllQty();
    this.loadTotal = 0;
    this.dischargeTotal = 0;
    this.cargoDeletedArr =[];
    this.planBasedProducts.forEach((product) => {
    let checkSum = this.transportArray().at(0).get('origin')?.value?.map((item: any) => {
      return item[product] || 0;
    }).reduce((prev: any, next: any) => Number(prev) + Number(next));
    if(checkSum === 0){
      this.cargoDeletedArr.push(product)
    }
  })
    this.transportArray().at(0).get('origin')?.value?.forEach((e: any) => {
      let rowProdSum = 0
      this.planBasedProducts.forEach((product) => {
        if(product.includes('Qty')) {
          const numQty = typeof e[product] === 'number' ? e[product] : 0; 
          rowProdSum+= numQty
          if(formOri?.at(row)?.get('loadDischarge')?.value === 'Load'){
            this.originQtyValue[product]+=numQty
            this.loadTotal+=numQty
            isLoadThere = true;
          } else if (formOri?.at(row)?.get('loadDischarge')?.value === 'Discharge') {
            this.destinQtyValue[product]+=numQty
            this.dischargeTotal+=numQty
            isDischargeThere = true;
          }
        }
      })
      formOri?.at(row)?.get('loadDischargeTotal')?.setValue(rowProdSum);
      if(!rowProdSum && (
        formOri?.at(row)?.get('loadDischarge')?.value === 'Load' || 
        formOri?.at(row)?.get('loadDischarge')?.value === 'Discharge'
      )) {
        formOri?.at(row)?.get('ron95Qty')?.setErrors({ 'invalid': true, })
      } else {
        formOri?.at(row)?.get('ron95Qty')?.setErrors(null)
      }
      row++
    })
    if(JSON.stringify(this.originQtyValue) !== JSON.stringify(this.destinQtyValue) && isLoadThere && isDischargeThere) {
      this.qtyError = '';
      Object.keys(productLabels).forEach((prod: any) => {
        if(this.originQtyValue[prod] !== this.destinQtyValue[prod]) {
          this.qtyError = productLabels[prod] + (this.qtyError.length ? ', ' : ' ') + this.qtyError;
        }
      });
    } else {
      this.qtyError = '';
    }
    if(isLoadThere && this.loadTotal > 0) {
      if (this.loadTotal < this.tierMin || this.loadTotal > this.tierMax) {
        this.loadMinMaxError = true;
      } else  {
        this.loadMinMaxError = false;
      }
    } else {
      this.loadMinMaxError = false;
    }
    if(isDischargeThere && this.dischargeTotal > 0) {
      if (this.dischargeTotal < this.tierMin || this.dischargeTotal > this.tierMax) {
        this.dischargeMinMaxError = true;
      } else {
        this.dischargeMinMaxError = false;
      }
    } else {
      this.dischargeMinMaxError = false;
    }
  }

  onPlanSelect() {
    this.validateProducts()
    const selectedPlan = (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).planName;
    this.planNameValue = selectedPlan;
    this.planBasedProducts = productList[selectedPlan.toLowerCase()+'desk'].map((prod: string) => this.utilities.getKeyByValue(productLabels, prod.includes('BO') ? 'BO' : prod));
  }

  onSetSupplierReceiver(rowIndex: number) {
    const supplierReceiver = (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).transport[0].origin[rowIndex].supplierReceiver;
    this.supplierCCA[0][rowIndex] = supplierReceiver;
    if (this.cpService.isEditCargo) {
      this.editedCargoDataRows[rowIndex].supplierReceiver = supplierReceiver
      this.editedCargoDataRows[rowIndex].parentCompany = Supplier[supplierReceiver]
    }
  }

  setMinMaxDate(lyacanDate: string, minSet: boolean) {
    if (minSet) {
      if(!this.minDate) {
        this.minDate = lyacanDate;
      } else {
        const d1 = new Date(this.minDate);
        const d2 = new Date(lyacanDate);
        if(d1.getTime() > d2.getTime()) {
          this.minDate = lyacanDate;
        }
      }
    } else {
      if(!this.maxDate) {
        this.maxDate = lyacanDate;
      } else {
        const d1 = new Date(this.maxDate);
        const d2 = new Date(lyacanDate);
        if(d1.getTime() < d2.getTime()) {
          this.maxDate = lyacanDate;
        }
      }
    }
    this.onSetCanalCrossing();
  }

  checkAllStartDates() {
    this.minDate = '';
    if(this.loadDischargeArray[0].findIndex((el: string) => el === 'Load') >= 0) {
      this.loadDischargeArray[0].forEach((el: any, index: number) => {
        if(el === 'Load' && this.dateArray[index].start) {
          this.setMinMaxDate(this.dateArray[index].start, true);
        }
      })
    }
  }

  checkAllEndDates() {
    this.maxDate = '';
    if(this.loadDischargeArray[0].findIndex((el: string) => el === 'Discharge') >= 0) {
      this.loadDischargeArray[0].forEach((el: any, index: number) => {
        if(el === 'Discharge' && this.dateArray[index].end) {
          this.setMinMaxDate(this.dateArray[index].end, false);
        }
      })
    }
  }

  onLyacanStartSet(rowIndex: number) {
    const lyacanStartDate = JSON.parse(JSON.stringify(this.createCcaNominationForm.value.transport[0].origin[rowIndex].lyacanStartDate));
    this.dateArray[rowIndex] ?
      this.dateArray[rowIndex].start = lyacanStartDate : 
      this.dateArray[rowIndex] = {start: lyacanStartDate, end : ''};
    this.checkAllStartDates();
    if (this.cpService.isEditCargo) {
      this.editedCargoDataRows[rowIndex].laycanStart = lyacanStartDate
    }
  }
  
  onLyacanEndSet(rowIndex: number) {
    const lyacanEndDate = JSON.parse(JSON.stringify(this.createCcaNominationForm.value.transport[0].origin[rowIndex].lyacanEndDate));
    this.dateArray[rowIndex] ? 
      this.dateArray[rowIndex].end = lyacanEndDate :
      this.dateArray[rowIndex] = {end: lyacanEndDate, start : ''};
    this.checkAllEndDates();
    if (this.cpService.isEditCargo) {
      this.editedCargoDataRows[rowIndex].laycanEnd = lyacanEndDate;
    }
  }

  onVesselSelect() {
    const formVessel = this.createCcaNominationForm.get('vesselName') as FormControl;
    this.vesselNameValue =formVessel.value;
  }

  onVesselTypeSelect() {
    this.vesselTypeValue = JSON.parse(JSON.stringify(this.createCcaNominationForm.value)).vesselType;
    this.vesselTypeData = this.cpService.cargoVesselTypes.find((vesselType: any) => this.vesselTypeValue === vesselType.vesselTypeName);
    this.tierMin = parseInt(this.vesselTypeData.tierMin);
    this.tierMax = parseInt(this.vesselTypeData.tierMax);
    this.validateProducts();
  }

  onSetLoadDischarge(rowIndex: number) {
    const loadDischarge = (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).transport[0].origin[rowIndex].loadDischarge;
    this.loadDischargeArray[0][rowIndex] = loadDischarge;
    this.validateProducts();
    if(this.dateArray[rowIndex]?.start) {
      this.onLyacanStartSet(rowIndex);
    } 
    if (this.dateArray[rowIndex]?.end) {
      this.onLyacanEndSet(rowIndex);
    }
    if (this.cpService.isEditCargo) {
      this.editedCargoDataRows[rowIndex].loadDischarge = loadDischarge
    }
  }

  onDeskPortTerminalChange(rowIndex:number){
    const formOri = this.transportArray().at(0).get('origin') as FormArray;
    const desk = formOri?.at(rowIndex)?.get('desk')?.value
    const port = formOri?.at(rowIndex)?.get('port')?.value
    const terminal = formOri?.at(rowIndex)?.get('terminal')?.value
    let data =  this.mdmService.getDeskPortTerminal(this.mdmService?.pavisResponse, desk, port, terminal);
    this.countryList[rowIndex] = data.countryName;
    if(this.cpService.isEditCargo && this.editedCargoDataRows[rowIndex]) {
      this.editedCargoDataRows[rowIndex].locationName = data.countryName;
    }
    if(!this.masterDeskList[rowIndex]?.length) {
      this.masterDeskList[rowIndex] = data.deskArray;
    }
    if(!(this.portOptions[rowIndex]?.toString() === data.portArray?.toString())) {
      this.portOptions[rowIndex] = data.portArray;
    }
    if(!(this.terminalData[rowIndex]?.toString() === data.terminalArray?.toString())) {
      this.terminalData[rowIndex] = data.terminalArray;
    }
    if(this.previousDropdownValues.desk[rowIndex] !== desk) {
      this.deskList[0][rowIndex] = desk;
      this.previousDropdownValues.desk[rowIndex] = desk
      formOri?.at(rowIndex)?.get('desk')?.setValue(desk);
      if(this.cpService.isEditCargo && this.editedCargoDataRows[rowIndex]) {
        this.editedCargoDataRows[rowIndex].locationName = data.countryName;
        this.editedCargoDataRows[rowIndex].udmItineraryPlanName = desk;
      }
    }
    const isSetPort = data.portArray.indexOf(port) > -1 ? port : '';
    if(this.previousDropdownValues.port[rowIndex] !== isSetPort || isSetPort === '') {
      this.previousDropdownValues.port[rowIndex] = isSetPort
      this.portcca[0][rowIndex] = isSetPort;
      formOri?.at(rowIndex)?.get('port')?.setValue(isSetPort);
      if(this.cpService.isEditCargo && this.editedCargoDataRows[rowIndex]) {
        this.editedCargoDataRows[rowIndex].port = isSetPort
      }
    }
    const isSetTerminal = data.terminalArray.indexOf(terminal) > -1 ? terminal : '';
    if(this.previousDropdownValues.terminal[rowIndex] !== isSetTerminal || isSetTerminal === '') {
      this.terminalCCA[0][rowIndex] = isSetTerminal;
      this.previousDropdownValues.terminal[rowIndex] = isSetTerminal
      formOri?.at(rowIndex)?.get('terminal')?.setValue(isSetTerminal);
      if(this.cpService.isEditCargo && this.editedCargoDataRows[rowIndex]) {
        this.editedCargoDataRows[rowIndex].terminal = isSetTerminal
      }
    }
  }

  setDummyVessels(vessel?: string) {
    const formVessel = this.createCcaNominationForm.get('vesselName') as FormControl;
    this.vesselArray =  (!vessel || Vessel.includes(vessel)) ? Vessel : [...Vessel, vessel];
    if(vessel) {
      if(this.vesselArray.includes(vessel)) {
        this.vesselNameValue = vessel;
        formVessel.setValue(vessel);
      }
      else {
        this.vesselNameValue = ''
        formVessel.setValue('');
      }
    }
  }

  presetNominationDataForCargo() {
    if(this.cpService.isEditCargo) {
      this.createCargoWithReference(this.cpService.editCargoData);
    } else {
      this.createCargoWithReference(this.nominationService.isCpGridSelected[0]);
    }
  }
  
  createCargoWithReference(data: any) {
    if (this.cpService.isEditCargo) {
      this.setEditData(data)
    }
    this.setDummyVessels(data?.vesselName);
    let obj:any = [data]
    let count = 0;
    this.isOriginRequired[0] = false;
    while (this.transportArray()?.controls?.length !== 0) {
      this.transportArray().removeAt(0);
    }
    this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
    this.updateFormFields(this.scenario);
    obj.forEach((item:any) => {
      const ccd = item?.udmCanalCrossingDate?.substr(0, 10).split('-');
      this.createCcaNominationForm.get("cargoName")?.setValue(item?.voyageName);
      ccd && ccd.length && this.createCcaNominationForm.get("canalCrossingDate")?.setValue(this.datePipe.transform([ccd[1], ccd[2], ccd[0]].join('/'), "yyy-MM-dd"));
      this.createCcaNominationForm.get("comments")?.setValue(item?.comments);
      this.createCcaNominationForm.get("planName")?.setValue(item?.planName);
      this.createCcaNominationForm.get("vesselType")?.setValue(item?.vesselTypeName);
      this.planNameValue = item?.planName;
      this.vesselTypeValue = item?.vesselTypeName;
      let originPos = 0;
      this.transportArray().push(this.createFormGroupTransport());
      item?.cargoItineraryList.forEach((ele: any, index: number ) => {
        this.modifyFormForCp(index);
        let originsList = this.transportArray().at(0).get('origin') as FormArray;
        originPos > 0 ? originsList.push(this.createFormGroupOrigin()) : null
        this.populateCargoNestedData(ele, count,originPos);
        originPos = originPos + 1;
      })
      count = +1;
    })
  }

  populateCargoNestedData(ele:any,count:any,originPos:any){  
    if (ele.loadDischarge === 'Load' || ele.loadDischarge === 'Discharge') {
      this.originArray = this.transportArray().at(0).get('origin') as FormArray;
      ele?.products?.forEach((product: any, index:any) => {
        const updatelabel = this.utilities.getKeyByValue(productLabels, product.cargoGrade);
        this.originArray.at(originPos)?.get(updatelabel)?.setValue(product.plannedVolume);
      })
      if (this.refOrginCount > 0) {
        this.originArray.push(this.createFormGroupOrigin())
      }
      this.originArray.at(originPos)?.get('desk')?.setValue(ele.udmItineraryPlanName);
      this.originArray.at(originPos)?.get('loadDischarge')?.setValue(ele.loadDischarge);
      this.originArray.at(originPos)?.get('port')?.setValue(ele.port);
      this.originArray.at(originPos)?.get('terminal')?.setValue(ele.terminal);
      this.originArray.at(originPos)?.get('supplierReceiver')?.setValue(ele.supplierReceiver);
      this.originArray.at(originPos)?.get('lyacanStartDate')?.setValue(this.datePipe.transform(ele.laycanStart, "yyy-MM-dd"));
      this.originArray.at(originPos)?.get('lyacanEndDate')?.setValue(this.datePipe.transform(ele.laycanEnd, "yyy-MM-dd"));
      this.loadDischargeArray[0][originPos] = ele.loadDischarge;
      this.dateArray[originPos] = {start: ele.laycanStart, end: ele.laycanEnd};
      this.supplierCCA[0][originPos] = ele.supplierReceiver
      this.portcca[0][originPos]=ele.port;
      this.terminalCCA[0][originPos]=ele.terminal;
      this.deskList[0][originPos]=ele.udmItineraryPlanName;

      this.isOriginRequired[0] = true;
      this.refOrginCount += 1;
    }
  }

  setEditData(data: any) {
    this.editCargoTopLevelData = {
      planName: data?.planName,
      voyageName: data?.voyageName,
      comments: data?.comments,
      vesselName: data?.vesselName,
      vesselTypeName: data?.vesselTypeName,
      tierMin: parseInt(data?.tierMin),
      tierMax: parseInt(data?.tierMax),
    }
    this.editCargoDataRows = JSON.parse(JSON.stringify(data?.cargoItineraryList));
    this.editedCargoDataRows = JSON.parse(JSON.stringify(data?.cargoItineraryList));
    this.isLinkedNom = this.editedCargoDataRows.findIndex(((res:any)=>!!res.nominationNo))>= 0;
    this.editCargoID = data?.id;
    this.mcatVoyageStatus = data.voyageStatus;
    this.getAllCargoIds(data?.cargoItineraryList);
  }

  checkifOriginisEmpty() {
    let alloriginisempty = true;

    this.transportArray().controls.forEach((ele: any) => {
      ele.controls['origin'].controls.forEach((item: any) => {
        Object.keys(item.controls).forEach((e) => {
          // if any row any field has value remove the validation for isoriginDestiTouched
          if (e !== 'id' && item.controls[e].value !== '') {
            alloriginisempty = false
          }
        })
      })

    })
    if (alloriginisempty) {
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
    }
    else {
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createCcaNominationForm.get('isoriginDestiTouched')?.clearValidators()
    }
  }

  updateFormFields(data: any) {   
    this.scenario = data;
  }

  modifyFormForCp(rowIndex: number = 0, init: boolean = false){
    this.onDeskPortTerminalChange(rowIndex);
    if(init) {
      this.onDeskPortTerminalChange(1);
      this.onDeskPortTerminalChange(2);
    }
    this.cargoModeList = cargoTypes;
    this.supplierList = Object.keys(Supplier);
  }

  addOriginRow(index: number) {
    if(this.cpService.isEditCargo) {
      this.editedCargoDataRows = [
        ...this.editedCargoDataRows,
        {
          "laycanStart": "",
          "laycanEnd": "",
          "terminal": "",
          "supplierReceiver": "",
          "port": "",
          "loadDischarge": "",
          "parentCompany": "",
          "locationName": "",
          "udmItineraryPlanName": "",
          "products": []
        }
      ]
    }
    const originArray = this.origin(index);
    const newOriginRow = this.createFormGroupOrigin();
    originArray.push(newOriginRow);
    this.isOriginRequired[index] = false;
    this.loadDischargeArray[0].push('');
    this.onDeskPortTerminalChange(originArray.length - 1);
  }
  
  deleteOriginRow(transportIndex: number, rowIndex: number) {
    if(this.cpService.isEditCargo) {
      const isProdOld = this.editedCargoDataRows[rowIndex].uid ? this.editCargoDataRows.findIndex((row: any) => row.uid === this.editedCargoDataRows[rowIndex].uid) : -1
      isProdOld > -1 ? this.editCargoDataRows[isProdOld].deleted = true : null;
      this.editedCargoDataRows.splice(rowIndex, 1);
    }
    this.countryList.splice(rowIndex, 1);
    this.masterDeskList.splice(rowIndex, 1);
    this.portOptions.splice(rowIndex, 1);
    this.portcca[transportIndex].splice(rowIndex, 1);
    this.deskList[transportIndex].splice(rowIndex, 1);
    this.terminalCCA[transportIndex].splice(rowIndex, 1);
    const isLoadDischargeDeleted = this.loadDischargeArray[transportIndex][rowIndex];
    this.loadDischargeArray[transportIndex].splice(rowIndex, 1);
    this.dateArray.splice(rowIndex, 1);
    if (isLoadDischargeDeleted === 'Load') {
      this.checkAllStartDates();
    } else if (isLoadDischargeDeleted === 'Discharge') {
      this.checkAllEndDates();
    }
    this.supplierCCA[transportIndex].splice(rowIndex, 1);
    this.origin(transportIndex).removeAt(rowIndex);
    this.origin(transportIndex).value.forEach((element: any) => {
    this.isOriginRequired[transportIndex] = false;
      Object.keys(element).forEach((e: any) => {
        if (element[e] !== '') {
          this.isOriginRequired[transportIndex] = true;
        }
      })
    });
    this.validateProducts();
    this.checkifOriginisEmpty();
  }

  resetformControl() {
    this.createCcaNominationForm.reset({
      nominationNo: '',
      cargoName:'',
      canalCrossingDate:'',
      planName:'',
      vesselName:'',
      vesselType: '',
      comment: '',
      transport: this.fb.array([this.createFormGroupTransport(true)]),
      comments:'',
    });
    while (this.transportArray()?.controls?.length !== 0) {
      this.transportArray().removeAt(0);
    }
    this.transportArray().push(this.createFormGroupTransport(true));
  }

  resetAllQty() {
    this.originQtyValue.ron91Qty = 0;
    this.originQtyValue.ron95Qty = 0;
    this.originQtyValue.avgasQty = 0;
    this.originQtyValue.boQty = 0;
    this.originQtyValue.keroQty = 0;
    this.originQtyValue.lsdQty = 0;
    this.originQtyValue.ulsdQty = 0;
    this.originQtyValue.mgoQty = 0;
    this.originQtyValue.jetAQty = 0;
    this.originQtyValue.rdsQty = 0;
    
    this.destinQtyValue.ron91Qty = 0;
    this.destinQtyValue.ron95Qty = 0;
    this.destinQtyValue.avgasQty = 0;
    this.destinQtyValue.boQty = 0;
    this.destinQtyValue.keroQty = 0;
    this.destinQtyValue.lsdQty = 0;
    this.destinQtyValue.ulsdQty = 0;
    this.destinQtyValue.mgoQty = 0;
    this.destinQtyValue.jetAQty = 0;
    this.destinQtyValue.rdsQty = 0;
  }

  resetEditValues() {
    this.cargoIdsList = Object.fromEntries(Object.values(productLabels).map((key) => [key, ""]))
    this.editCargoID = "";
    this.mcatVoyageStatus ="";
    this.editCargoDataRows = [];
    this.editedCargoDataRows = [];
    this.editCargoTopLevelData = {
      voyageName: "",
      vesselName: "",
      planName: "",
      vesselTypeName: "",
      tierMin: 0,
      tierMax: 0,
      comments: "",
    };
  }

  resetEntry() {
    this.loadMinMaxError = false;
    this.dischargeMinMaxError =false;
    this.countryList = [];
    this.editCargoDataRows = [];
    this.editedCargoDataRows = [];
    this.editCargoID = '';
    this.mcatVoyageStatus ='';
    this.qtyError = '';
    this.planBasedProducts = [];
    this.portOptions=[[]]
    this.terminalData=[[]]
    this.portcca=[[]]
    this.terminalCCA=[[]]
    this.supplierCCA=[[]]
    this.loadDischargeArray=[[]]
    this.deskList=[[]]
    this.vesselNameValue="";
    this.vesselTypeValue="";
    this.planNameValue="";
    this.tierMin = 0;
    this.tierMax = 0;
    this.minDate = '';
    this.maxDate = '';
    this.dateArray = [];
  }

  retreiveTSWCode(type: string, product: string, desk: string){
    let prod = product.includes('BO') ? 'BO'+'_'+desk.toUpperCase()+"_"+type :product+'_'+desk.toUpperCase()+"_"+type
    return {
      tswMaterialCode: prod.includes('BO') ? null : newTSWCodeMap[prod]?.toString() ?? null, // TSW code
      tswMaterialDesc: newTSWDescMap[prod] ?? null
    }
  }

  getAllCargoIds(cargoItineraries: any) {
    cargoItineraries.forEach((itin: any) => itin.products.forEach((product: any) => {
      this.cargoIdsList[product.cargoGrade] = product.cargoId
    })) 
  }

  getCargoListItem(itineraray: any, product: string) {
    return {
      cargoGrade: product, // SRA product name
      cargoItineraryList: [itineraray],
    };
  }

  formCpPayload() {  
    this.nominationData = JSON.parse(JSON.stringify(this.createCcaNominationForm.value));
    const planDesk = this.nominationData?.planName;
    let cargoData: any = {
      sys_sourceSystem: "UDM",
      sys_createdBy:this.nominationService.userDetails$.value._email,
      vesselName: this.nominationData.vesselName,
      ccaStatus:'draft',
      vesselTypeName: this.vesselTypeValue, 
      tierMin: this.tierMin,
      tierMax: this.tierMax,
      voyageName: this.nominationData?.cargoName?.toUpperCase(),
      udmCanalCrossingDate: this.utilities.formatDateForPayload(this.nominationData?.canalCrossingDate) ?
        this.utilities.formatDateForPayload(this.nominationData?.canalCrossingDate) : null,
      comments:this.nominationData?.comments,
      planName: planDesk,
      tdVehIdentifier: this.scenario === 'barge' ? "B" : "S",
      mot: this.scenario === 'barge' ? "Barge" : "Vessel",
      cargoList: []
    }
    this.nominationData.transport.forEach((el: any) => {    
      el.origin.forEach((item: any, i: number) => {
        this.planBasedProducts.forEach((product) => {
          let itineraray: any = {}
          // cehck based on prod and desk if cargolist has any object as such
          // if it has push below create itinry else add item in cargolist and push itnry at 0 index
          if (item[product] && item[product] > 0) {
            const cargoGrade = productLabels[product]
            itineraray.windowFrom = item.lyacanStartDate // laycan start date
            itineraray.windowTo = item.lyacanEndDate
            itineraray.terminal = item.terminal
            itineraray.udmItineraryPlanName = item.desk;
            itineraray.port = item.port
            itineraray.parentCompany = Supplier[item.supplierReceiver]
            itineraray.locationName = this.countryList[i]
            itineraray.supplierReceiver = item.supplierReceiver
            itineraray.loadDischarge = item.loadDischarge
            itineraray.plannedVolumeUoM = this.scenario === 'barge' ? "MB" : "Vessel"
            itineraray.plannedVolume = item[product]
            itineraray.sys_createdBy = this.nominationService.userDetails$.value._email
            item.sys_CreatedBy = this.nominationService.userDetails$.value._email
            let newProdCodes = this.retreiveTSWCode(item.loadDischarge, cargoGrade, planDesk);
            itineraray.tswMaterialCode = newProdCodes.tswMaterialCode;
            itineraray.tswMaterialDesc = newProdCodes.tswMaterialDesc;

            const pushIndex = cargoData.cargoList.findIndex((el: any) => 
              el.cargoGrade === cargoGrade
            );
            if(pushIndex >= 0) {
              const itinStr = JSON.stringify(itineraray);
              let isSame = -1;
              cargoData.cargoList[pushIndex].cargoItineraryList.forEach((itiner: any, index: number) => {
                if(itinStr === JSON.stringify(itiner)) {
                  isSame = index;
                }
              })
              if(isSame >= 0)  {
                cargoData.cargoList[pushIndex].cargoItineraryList[isSame].plannedVolume+=itineraray.plannedVolume
              }
              else {
                isSame = -1
                cargoData.cargoList[pushIndex].cargoItineraryList.push(itineraray);
              }
            } else {
              cargoData.cargoList.push(
                this.getCargoListItem(itineraray, cargoGrade)
              )
            }
          }
        })
      })
    });
    this.createCargo([cargoData]);
  }

  formEditPayload() {
    const payload: any = {
      id: this.editCargoID,
      sys_modifiedBy: this.nominationService.userDetails$.value._email,
      ...(this.mcatVoyageStatusList.includes(this.mcatVoyageStatus.toLowerCase()) && { mcatStatus: "out of sync" }),
      ...((this.isLinkedNom && this.editedCargoDataRows.findIndex(((res:any)=>!!res.nominationNo))=== -1) && {isUdmMismatchVesselName:true})
    }
    const formOri = this.transportArray().at(0).get('origin') as FormArray;
    const isVesselTypeUpdate = this.editCargoTopLevelData.vesselTypeName !== this.vesselTypeValue
    
    this.editCargoTopLevelData.voyageName !== 
      (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).cargoName.toUpperCase() ?
      payload.voyageName = (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).cargoName.toUpperCase() :
      null;
    
    this.editCargoTopLevelData.vesselName !== this.vesselNameValue ? payload.vesselName = this.vesselNameValue : null
    this.editCargoTopLevelData.planName !== this.planNameValue ? payload.planName = this.planNameValue : null
    isVesselTypeUpdate ? payload.vesselTypeName = this.vesselTypeValue : null
    isVesselTypeUpdate ? payload.tierMin = this.tierMin : null
    isVesselTypeUpdate ? payload.tierMax = this.tierMax : null
    this.editCargoTopLevelData.comments !== 
      (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).comments ?
      payload.comments = (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).comments  :
      null;
    
    this.editCargoTopLevelData.udmCanalCrossingDate !== this.utilities.formatDateForPayload(
      (JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).canalCrossingDate
    ) ? payload.udmCanalCrossingDate = this.utilities.formatDateForPayload((JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).canalCrossingDate).length ? 
      this.utilities.formatDateForPayload((JSON.parse(JSON.stringify(this.createCcaNominationForm.value))).canalCrossingDate) :
      null : null;
    
    let productUpdatedEditedRows = this.editedCargoDataRows.map((row: any, index: number) => {
      return {
        laycanStart: this.utilities.formatDateForPayload(row.laycanStart),
        laycanEnd: this.utilities.formatDateForPayload(row.laycanEnd),
        terminal: row.terminal,
        supplierReceiver: row.supplierReceiver,
        port: row.port,
        loadDischarge: row.loadDischarge,
        parentCompany: row.parentCompany,
        locationName: row.locationName,
        udmItineraryPlanName: row.udmItineraryPlanName,
        products: this.planBasedProducts.map((product: any) => {
          const prodLabel = productLabels[product.includes('BO') ? 'BO' : product];
          const formProd = formOri.at(index).get(product)?.value;
          const isProdThere = row.products.find((prod: any) => prod.cargoGrade === prodLabel)
          const tswCodes = this.retreiveTSWCode(
            row.loadDischarge,
            prodLabel,
            this.editCargoTopLevelData.planName
          )
          if(isProdThere) {
            isProdThere.tswMaterialCode = tswCodes.tswMaterialCode
            isProdThere.tswMaterialDesc = tswCodes.tswMaterialDesc
            isProdThere.cargoGradeDeleted = this.cargoDeletedArr.includes(product)
            isProdThere.plannedVolume = formProd || 0
            return isProdThere
          } else if (!isProdThere && formProd) {
            return {
              ...tswCodes,
              cargoGrade: prodLabel,
              sraProductGrade: null,
              plannedVolume: formProd,
              cargoId: this.cargoIdsList[prodLabel] || null,
              cargoGradeDeleted:this.cargoDeletedArr.includes(product),
              cargoItineraryId: null,
            }
          } else return null
        }).filter((product) => product ?? false)
      }
    })
    payload.cargoItineraryList = productUpdatedEditedRows.map((row: any) => {
      return {
        laycanStart: row.laycanStart,
        laycanEnd: row.laycanEnd,
        terminal: row.terminal,
        supplierReceiver: row.supplierReceiver,
        port: row.port,
        loadDischarge: row.loadDischarge,
        parentCompany: row.parentCompany,
        locationName: row.locationName,
        udmItineraryPlanName: row.udmItineraryPlanName,
        products: row.products
      }
    });
    const deletedCargos = this.editCargoDataRows.map((itin: any) => itin.deleted ? {
      deleted: true,
      products: itin.products
    } : false).filter((itin: any) => itin.deleted)
    payload.cargoItineraryList = [...payload.cargoItineraryList, ...deletedCargos];
    this.editCargo([payload]);
    
  }

  createCargo(requestBodyArray: any) {
    this.loaderService?.setLoading(false);
    this.myMonitoringService.logEvent('Create cargo plan is clicked', {category: "Cargo Planning"});
    this.nominationService.createCargoCca(requestBodyArray).subscribe({
      next: (response) => {
        if (response.success) {
          this.cargoDeletedArr =[];
          if (this.isCargoDesks) {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: `cargo plan has been created`,
            });
          }
        }
        this.closePopup();
        this.closeModalPopup.emit(response);
      },
      error: (err:any) => {
          this.toasterService.setToastNotification({
            show: true,
            type: 'error',
            msg: err.message,
          });
      },
      complete: () => {
      },
    });
  }

  editCargo(requestBodyArray: any) {
    this.loaderService?.setLoading(false);
    this.myMonitoringService.logEvent('Edit cargo plan clicked', {category: "Cargo Planning"});
    this.cpCCAService.updateCargoPlanningCca(requestBodyArray).subscribe({
      next: (response) => {
        if (response.success) {
          this.cargoDeletedArr =[];
          if (this.isCargoDesks) {
            this.toasterService.setToastNotification({
              show: true,
              type: 'success',
              msg: `cargo plan has been updated`,
            });
          }
        }
        this.closePopup();
        this.closeModalPopup.emit(response);
      },
      error: (err:any) => {
          this.toasterService.setToastNotification({
            show: true,
            type: 'error',
            msg: err.message,
          });
      },
      complete: () => {
      },
    });
  }

  closePopup() {
    this.resetAllQty();
    this.resetformControl();
    this.resetEntry();
    this.initialValues();
    if(this.cpService.isEditCargo) {
      this.resetEditValues();
      this.cpService.isEditCargo = false;
    }
    this.closeModalPopup.emit();
  }

  submitForm() {
    if(this.isCargoDesks){
      if (this.cpService.isEditCargo) {
        this.formEditPayload();
      } else {
        this.formCpPayload();
      }
    }
  }
}
