import { DatePipe } from '@angular/common'; 
import { Injectable } from '@angular/core';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { PSD_COMMON_HEADERS, PSD_CONSUMPTION_COLS, WHAT_IF_PAYLOAD, WHAT_IF_PAYLOAD_2 } from './mkt-els-constants';
import { MoreMenuMktElsNomComponent } from 'src/app/shared/modules/more-menu-mkt-els-nom/more-menu-mkt-els-nom.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MktElsUtilities {

    filterResponse: any;
    componentMaterials: any;
    mdmData: any;
    currentDate = new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
    editPayload = {};
    columnGroupIds: any = [];
    editedRows: any = [];
    whatIfEditedRows: any = [];
    originalRowData: any;
    public notifyEditedDataPSD$: BehaviorSubject<any> = new BehaviorSubject<any>({ isSaveDisabled: true, isSubmitted: false });

    constructor(
        private datePipe: DatePipe,
        private utilities: Utilities,
    ) { }

    updateFilterResponse(filterResponse: any) {
        this.filterResponse = filterResponse;
    }

    copyOriginalGridRes(reponse: any) {
        this.originalRowData = JSON.parse(JSON.stringify(reponse));
    }

    getGridInfo(data: any, locations: any, products: any) {
        this.componentMaterials = data.componentMaterials;
        this.mdmData = data.mdmData;
        let gridData: any = { headers: PSD_COMMON_HEADERS, products: products, locations: locations };
        return this.genColDef(gridData);
    }

    getProdName(prodNum: any) {
        let filterRes = this.filterResponse?.mktElsFilterValues;
        const products = filterRes?.find((el: any) => el.headerName === 'product')
        const filteredProds = products.list.filter((prod: any) => prod.name !== 'all' && prod.checked).map((fprod: any) => ({ id: +fprod.id, name: fprod.name }));
        const prod = filteredProds.find((prod: any) => +prod.id === +prodNum);
        return prod?.name.toLowerCase() || prodNum;
    }

    isEditableCol(colName: string, params: any) {
        const cols: string[] = ['productionOverride', 'whatifShipment', 'shipmentAdjustment', 'comments', 'consumptionOverride'];
        const currentDate = new Date(this.currentDate).toLocaleDateString();
        const rowDate = new Date(new Date(params?.data.date).getTime()).toLocaleDateString();

        if (colName === 'totalInventory' && rowDate === currentDate) {
            return true
        }
        return (new Date(params?.data.date).getTime() >= this.currentDate) && cols.includes(colName);
    }

    getDisplayName(name: string) {
        const _name = name[0] + name.slice(1);
        return _name.split(/(?=[A-Z])/).join(" ").toLowerCase();
    }

    isOpenCol(index: any) {
        return index["childIndex"] == 0 ? '' : 'open';
    }

    getDateCol() {
        const col = {
            field: 'date',
            colId: 'date',
            width: 150,
            filter: 'agNumberColumnFilter',
            headerName: 'date',
            pinned: 'left',
            cellClass: 'text-right',
            spanHeaderHeight: true,
            cellRenderer: (data: any) => {
                return data.data.date ? this.datePipe.transform(new Date(data.data.date), 'EEEEEE, MM/dd/yy') : ''
            },
            headerClass: "ag-right-aligned-header ethonal-view_header-cell_date",
            cellStyle: (params: any) => {
                if (new Date(params.data?.date).getTime() > new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime()) {
                    return { fontWeight: 'bold' };
                }
                return null;
            }
        }
        return col
    }

    getHeaderClass(header: string) {
        const headers: any = {
            "whatifShipment": "menu-cell"
        }
        return headers[header] || ""
    }

    getCellClass(params: any) {
        let className = "";
        if (params?.column.colDef.headerName.includes("whatif ship") && params.value) {
            className = "overflow-visible"
        }
        return className
    }

    getMaterialHeelsVal(materialNumber: any) {
        const materialData = this.mdmData.find((material: any) => +materialNumber === +material.materialNumber);
        const heelValue = materialData?.heels || 0;        
        return heelValue;
    }

    getAvailInvVals(materialNumber: any) {
        const materialData = this.mdmData.find((material: any) => +materialNumber === +material.materialNumber);
        const minValue = materialData?.minInventory || 0;
        const maxValue = materialData?.maxInventory || 0;
        const minEsl = materialData?.minESL || 0;
        const maxEsl = materialData?.maxESL || 0;
        const createdOn = this.datePipe.transform(materialData?.createdOn, 'MMM dd, HH:mm') ?? 'NA';
        return [minValue, maxValue, minEsl, maxEsl, createdOn];
    }

    getCurrentRowInv(params: any) {
        const fieldName = params?.colDef?.field;
        return +params?.data[fieldName];
    }

    warnMinMaxLimit(params: any, col: any, minValue: any, maxValue: any) {
        const currentRowInv = this.getCurrentRowInv(params);
        const PST_TIME = new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
        return (currentRowInv <= minValue && params?.data?.date && new Date(params.data.date).getTime() > PST_TIME) || (currentRowInv >= maxValue && params?.data?.date && new Date(params.data.date).getTime() > PST_TIME);
    }

    warnEslMinMaxLimit(params: any, col: any, minEsl: any, maxEsl: any) {
        const currentRowInv = this.getCurrentRowInv(params);
        const PST_TIME = new Date(this.utilities.getPSTDateObj().toLocaleDateString()).getTime();
        return (currentRowInv <= minEsl && params?.data?.date && new Date(params.data.date).getTime() > PST_TIME) || (currentRowInv >= maxEsl && params?.data?.date && new Date(params.data.date).getTime() > PST_TIME);
    }

    setCellClassForAvailableInvCol(col: any, minValue: any, maxValue: any, minEsl: any, maxEsl: any,) {
        let compareCheckResult = false;
        const cellClassRules = {
            'warn-min-max-limit': (params: any) => this.warnMinMaxLimit(params, col, minValue, maxValue),
            'esl-warn-min-max-limit': (params: any) => this.warnEslMinMaxLimit(params, col, minEsl, maxEsl)
        }
        col['cellClassRules'] = cellClassRules;
    }

    setMinMaxVal(col: any) {
        col["wrapHeaderText"] = true;
        col["autoHeaderHeight"] = true;
        const materialNumber = col.field.split("_")[1];
        const [minValue, maxValue, minEsl, maxEsl, createdOn] = this.getAvailInvVals(materialNumber);
        col.headerComponentParams = {
            template:
                `<div class="ag-cell-label-container" role="presentation">
              <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
              <div ref="eLabel" class="ag-header-cell-label test" role="presentation">
                <div>
                    <div ref="eText" class="ag-header-cell-text"></div>
                        <div class="min-max">min: ${minValue}  | max: ${maxValue} </div>
                        <div class="inv-timestamp">opening inv. update: ${createdOn} </div>
                    </div>
                </div>
                </div>
              `
            ,
        };
        this.setCellClassForAvailableInvCol(col, minValue, maxValue, minEsl, maxEsl);
        return col;
    }

    isStringCol(header: string) {
        return ["nominationNumber", "comments"].includes(header)
    }

    getNumberVal(val: any) {
        const isStringNum = (typeof val === 'string' && (val.trim().length > 0))
        if( isStringNum || typeof val === 'number') {
            return Number(val)?.toFixed(2);
        } else {
            return val;
        }
    }

    getColDescForGroupHeaders(header: any, index: any, prodNum?: any, location?: any) {
        let col: any = {};
        col["headerName"] = this.getDisplayName(header)
        col["field"] = `${header}_${prodNum}_${location.toUpperCase()}`;
        col['groupId'] = `${header}_${prodNum}`
        col["editable"] = (params: any) => this.isEditableCol(header, params);
        col["width"] = header.includes("totalInventory") || header.includes("availableInventory") ? "234px" : "auto";
        col["resizable"] = true;
        col["wrapHeaderText"] = true;
        col["headerClass"] = this.getHeaderClass(header);
        col["columnGroupShow"] = this.isOpenCol(index);
        col['cellRendererFramework'] = header === "whatifShipment" && MoreMenuMktElsNomComponent;
        col["cellClass"] = (params: any) => this.getCellClass(params);
        // col["valueFormatter"] = (params: any) => this.isStringCol(header) ? params?.value : (this.utilities.formatNumberPipeline(params?.value));
        col["valueFormatter"] = (params: any) => this.isStringCol(header) ? params?.value : (this.getNumberVal(params?.value));
        col = header.includes("totalInventory") || header.includes("availableInventory") ? this.setMinMaxVal(col) : col;
        return col;
    }

    getColDescForCommonHeaders(header: any) {
        let col: any = {};
        col["headerName"] = this.getDisplayName(header);
        col["field"] = header;
        col["wrapHeaderText"] = true;
        return header != 'date' ? col : { ...col, ...this.getDateCol() };
    }

    genColDef(gridData: any) {
        let colsGroup: any = [];
        const commonColumns = ["date"];
        gridData.locations.forEach((location: any) => {
            gridData.products.forEach((prodNum: any, parentIndex: Number) => {
                let colGroup: any = {};
                let children: any = [];
                let headers: any = this.getHeaders(prodNum, gridData)

                headers.forEach((header: any, childIndex: number) => {
                    const indexes = { parentIndex, childIndex }
                    children.push(this.getColDescForGroupHeaders(header, indexes, prodNum, location));
                });
                colGroup["headerName"] = this.getProdName(prodNum);
                colGroup["children"] = children;
                colGroup["headerClass"] = "ethonal-view square-icon ethanol-header-text_supplier"
                colGroup["stickyLabel"] = true;
                colGroup["marryChildren"] = true;
                colGroup['groupId'] = `${parentIndex}_${prodNum}`
                colGroup["openByDefault"] = parentIndex === 0;
                colsGroup.push(colGroup)
                this.columnGroupIds.push(colGroup);
            });
        });
        colsGroup = [...this.getCommonColumns(commonColumns), ...colsGroup]
        return colsGroup;
    }

    getHeaders(prodNum: any, gridData: any) {
        return this.componentMaterials?.includes(prodNum) ? [...gridData.headers, ...PSD_CONSUMPTION_COLS] : gridData.headers;
    }

    getCommonColumns(commonColumns: any) {
        let cols: any = []
        commonColumns.forEach((col: any) => {
            cols.push(this.getColDescForCommonHeaders(col))
        })
        return cols;
    }

    getSelectedLocations(filterRes: any) {
        const locations = filterRes?.find((el: any) => el.headerName === 'terminal')
        return locations.list.filter((loc: any) => loc.name !== 'all' && loc.checked).map((floc: any) => floc.id);;
    }

    getSelectedProducts(filterRes: any) {
        const products = filterRes?.find((el: any) => el.headerName === 'product')
        return products.list.filter((prod: any) => prod.name !== 'all' && prod.checked).map((fprod: any) => +fprod.id);
    }

    getPayload(filterResponse: any) {
        const time = "T00:00:00";
        return {
            "locationCode": "PASADENA",
            "materialCode": this.getSelectedProducts(filterResponse?.mktElsFilterValues),
            "fromDate": this.datePipe.transform(filterResponse.pipelineFromDate, 'yyyy-MM-dd') + time,
            "toDate": this.datePipe.transform(filterResponse.pipelineToDate, 'yyyy-MM-dd') + time
        }
    }

    getValIfConsOverrideIsEmpty(prodName: string, locName: string, obj: any) {
        const consumptionOverrideVal = obj[`consumptionOverride_${prodName}_${locName}`] || 0;
        const consumptionVal = obj[`consumption_${prodName}_${locName}`] || 0;
        if (consumptionOverrideVal) {
            return +consumptionOverrideVal;
        } else {
            return +consumptionVal;
        }
    }

    getValIfProdOverrideIsEmpty(prodName: string, locName: string, obj: any) {
        const productionOverrideVal = obj[`productionOverride_${prodName}_${locName}`];
        const productionForecastVal = obj[`productionForecast_${prodName}_${locName}`] || 0;
        if (productionOverrideVal == 0 || productionOverrideVal) {
            return +productionOverrideVal;
        } else {
            return +productionForecastVal;
        }
    }

    getValIfShipmentAdjustmentIsEmpty(prodName: string, locName: string, obj: any) {
        const shipmentAdjustmentVal = obj[`shipmentAdjustment_${prodName}_${locName}`] || 0;
        const nominationVolVal = obj[`nominationVol_${prodName}_${locName}`] || 0;
        if (shipmentAdjustmentVal) {
            return (+nominationVolVal) + (+shipmentAdjustmentVal);
        } else {
            return +nominationVolVal;
        }
    }

    calcAvailableInventory(availableInventoryKey: string, obj: any) {
        const [propertyKey, prodName, locName] = availableInventoryKey.split("_")
        const availableInventoryVal = obj[`totalInventory_${prodName}_${locName}`] || 0;
        const valIfProdOverrideIsEmpty = this.getValIfProdOverrideIsEmpty(prodName, locName, obj);
        const vallIfShipmentAdjustmentIsEmpty = this.getValIfShipmentAdjustmentIsEmpty(prodName, locName, obj);
        const valIfConsOverrideIsEmpty = this.getValIfConsOverrideIsEmpty(prodName, locName, obj);
        const availableInventory = +availableInventoryVal + valIfProdOverrideIsEmpty - valIfConsOverrideIsEmpty + vallIfShipmentAdjustmentIsEmpty;
        return availableInventory;
    }

    getPostFixVal(key: string) {
        const [propertyKey, prodName, locName] = key.split("_")
        return `${prodName}_${locName}`;
    }

    setCurrentDayWhatIfInventory(row: any) {
        const keys = this.getWhatIfInvKeys(row);
        let currentDateLocal: any = new Date(this.currentDate).getTime();
        currentDateLocal = new Date(currentDateLocal).toLocaleDateString();
        const rowDateLocal = new Date(new Date(row?.date).getTime()).toLocaleDateString();
        if (rowDateLocal === currentDateLocal) {
            keys.forEach((key) => {
                const postfix = this.getPostFixVal(key);
                //row[key] = row[`availableInventory_${postfix}`];
                row[key] = row[`totalInventory_${postfix}`];
                row[`availableInventory_${postfix}`] =  row[`totalInventory_${postfix}`] - this.getMaterialHeelsVal(postfix.split('_')[0]);
                row[`whatifShipmentSum_${postfix}`] = +row[`whatifShipment_${postfix}`] || 0
            })
        }
    }

    getWhatIfInvRow(row: any, rowData: any, index: number) {
        const keys = this.getWhatIfInvKeys(row);
        const rowDate = new Date(row?.date).getTime();
        const nextDay = this.currentDate + 86400000;

        this.setCurrentDayWhatIfInventory(row)
        if (rowDate >= nextDay) {
            keys.forEach((key) => {
                const postfix = this.getPostFixVal(key);
                if(index > 0 && rowData[index - 1]?.fullWidth && rowData[index - 1]?.dow === "Sat") {
                    row[`whatifShipmentSum_${postfix}`] = +(rowData[index - 2][`whatifShipmentSum_${postfix}`] || 0) + (+row[`whatifShipment_${postfix}`] || 0)
                    row[key] = this.getWhatIfInventoryVal(postfix, row, rowData[index - 2]);
                } else {
                    row[`whatifShipmentSum_${postfix}`] = +(rowData[index - 1][`whatifShipmentSum_${postfix}`] || 0) + (+row[`whatifShipment_${postfix}`] || 0)
                    row[key] = this.getWhatIfInventoryVal(postfix, row, rowData[index - 1]);
                }
            })
        }
        return row;
    }

    getAvalInvRow(row: any, rowData: any, index: number) {
        const keys = this.getAvailableInvKeys(row);
        let rowDate = new Date(row?.date).getTime();
        let nextDay = this.currentDate + 86400000;
        this.setCurrentDayWhatIfInventory(row);

        if (rowDate >= nextDay) {
            keys.forEach((key) => {
                const [propertyKey, prodName, locName] = key.split("_")
                if(index > 0 && rowData[index - 1]?.fullWidth && rowData[index - 1]?.dow === "Sat") {
                    row[key] = this.calcAvailableInventory(key, rowData[index - 2]);
                    row[`availableInventory_${prodName}_${locName}`] = this.calcAvailableInventory(key, rowData[index - 2]) - this.getMaterialHeelsVal(prodName);
                    row[`whatifInventory_${prodName}_${locName}`] = row[`totalInventory_${prodName}_${locName}`];
                } else {
                    row[key] = this.calcAvailableInventory(key, rowData[index - 1]);
                    row[`availableInventory_${prodName}_${locName}`] = this.calcAvailableInventory(key, rowData[index - 1]) - this.getMaterialHeelsVal(prodName);
                    row[`whatifInventory_${prodName}_${locName}`] = row[`totalInventory_${prodName}_${locName}`];
                }
            })
        }
        return row
    }

    getWhatIfInventoryVal(postfix: string, currentDay: any, prevDay: any) {
        const availableInventory = +currentDay[`totalInventory_${postfix}`] || 0;
        const whatifShipmentSum = +prevDay[`whatifShipmentSum_${postfix}`] || 0;       
        
        const result = availableInventory + whatifShipmentSum;
        return result;
    }

    setWhatIfInventoryVal(key: string, row: any) {
        const [propertyKey, prodName, locName] = key.split("_")
        row[`whatifInventory_${prodName}_${locName}`] = row[`totalInventory_${prodName}_${locName}`] - row[`whatifShipment_${prodName}_${locName}`];
    }

    getAvailableInvKeys(obj: any) {
        return Object.keys(obj).filter(ele => ele.includes("totalInventory"));
    }

    getWhatIfInvKeys(obj: any) {
        return Object.keys(obj).filter(ele => ele.includes("whatifInventory"));
    }

    updateAvailableInv(data: any) {
        data.forEach((row: any, index: number) => {
            row = this.getAvalInvRow(row, data, index);
        });
        return data;
    }

    updateWhatIfInv(data: any) {
        data.forEach((row: any, index: number) => {
            row = this.getWhatIfInvRow(row, data, index);
        });
        return data;
    }

    addShimentSumProperty(row: any) {
        const keys = this.getWhatIfInvKeys(row)
        keys.forEach((key) => {
            const [propertyKey, prodName, locName] = key.split("_")
            row[`whatifShipmentSum_${prodName}_${locName}`] = 0;
        })
    }

    fullWidthRow(_rowData: any): void {
        let processData: any = [];
        const rowData = [..._rowData];
        let currentWeekDate = this.utilities.convertDatesToCurrentTZ(new Date().toISOString());
        let dayIndex = this.utilities.convertDatesToCurrentTZ(new Date().toISOString()).getDay();
        let currentWeek = currentWeekDate.setDate(currentWeekDate.getDate() - dayIndex);
        let nextWeek = new Date(currentWeek).setDate(new Date(currentWeek).getDate() + 7);
        let lastWeek = new Date(currentWeek).setDate(new Date(currentWeek).getDate() - 7);
        let nextMonth = new Date(currentWeek).setDate(new Date(currentWeek).getDate() + 14);

        rowData.forEach((val: any, index: number) => {

            if (new Date(currentWeek).toDateString() === new Date(val.date).toDateString()) {

                let cVal = { ...rowData[index - 1] };
                cVal.fullWidth = true;
                cVal.fullWidthText = 'this week';
                processData.push(cVal);
                processData.push(val);
            }
            else if (new Date(nextWeek).toDateString() === new Date(val.date).toDateString()) {
                let nVal = { ...rowData[index - 1] };
                nVal.fullWidth = true;
                nVal.fullWidthText = 'next week';
                processData.push(nVal);
                processData.push(val);
            }
            else if (new Date(lastWeek).toDateString() === new Date(val.date).toDateString()) {
                let lVal = { ...rowData[index - 1] };
                lVal.fullWidth = true;
                lVal.fullWidthText = 'last week';
                processData.push(lVal);
                processData.push(val);
            }
            else if (new Date(nextMonth).toDateString() === new Date(val.date).toDateString()) {
                let eVal = { ...rowData[index - 1] };
                eVal.fullWidth = true;
                eVal.fullWidthText = ' ';
                processData.push(eVal);
                processData.push(val);
            }
            else {
                processData.push(val);
            }
            val = this.addShimentSumProperty(val);
        })

        return processData;

    }

    validateInputVal(field: string, val: string) {
        if(!field.includes("comment")) {
            if(field.includes("productionOverride") && val.trim() === "") {
                return " ";
            }
            return val.trim().length > 0 ? +val : 0;
        } else {
            return val.trim().length === 0 ? "" : val; 
        }
    }

    saveOnEdit(row: any) {
        const field = row.colDef.field;
        const date = row.data.date;
        const newValue = this.validateInputVal(field, row.newValue);
        const [key, materialCode, location] = field.split("_");

        if(this.editedRows.length > 0) {
            const index = this.editedRows.findIndex((_row: any) => ((_row.productionDate === date) && (_row.materialCode === +materialCode)));
            if(index >= 0) {
                const _key = key === "totalInventory" ? "availableInventoryOverride" : key;
                this.editedRows[index] = {...this.editedRows[index], [_key]: newValue};
            } else {
                this.editedRows.push(this.genPayload(row, field, newValue));
            }
        } else {
            this.editedRows.push(this.genPayload(row, field, newValue));
        }
        this.notifyEditedDataPSD$.next({ isSaveDisabled: false, isSubmitted: false });
    }

    genPayload(row: any, field: string, newValue: any) {
        const [key, materialCode, location] = field.split("_");
        const _key = key === "totalInventory" ? "availableInventoryOverride" : key;
        let obj = {
            "locationCode": "Pasadena",
            "materialCode": +materialCode,
            "availableInventoryOverride": null,
            "productionDate": row.data.date,
            "comments": null,
            "productionOverride": null,
            "shipmentAdjustment": null,
            "consumptionOverride": null,
        }
        obj = {...obj, [_key]: newValue};
        return obj
    }

    resetUpdateValues() {
        this.editedRows = [];
        this.whatIfEditedRows = [];
        this.notifyEditedDataPSD$.next({ isSaveDisabled: true, isSubmitted: false });
    }

    updateCellColorOnEdit(event: any) {
        event.colDef.cellClass = (p: any) =>
            p.rowIndex.toString() === event.node.id ? "ag-cell-dirty" : "";
    }

    saveWhatIfShipment(row: any, userName: any) {
        const field = row.colDef.field;
        const newValue = this.validateInputVal(field, row.newValue);
        const [key, materialCode, location] = field.split("_");
        const date = this.datePipe.transform(row.data.date, "yyyyMMdd");
        
        this.genPayLoadForWhatIf(row, newValue, materialCode, userName, date);
    }

    getProdDesc(materialCode: any) {
        let filterRes = this.filterResponse?.mktElsFilterValues;
        const products = filterRes?.find((el: any) => el.headerName === 'product')
        const product = products.list.find((prod: any) => prod.id == +materialCode)

        return product.name;
    }

    getPayloadObj(row: any, newValue: any, materialCode: any, userName: string, date: any) {
        let positiveVal = newValue * -1;
        const obj = {
            "scheduledDate": date,
            "scheduledQty": newValue < 0 ? positiveVal : newValue,
            "scheduledMaterial": +materialCode,
            "scheduledMaterialDesc": this.getProdDesc(materialCode),
            "demandMaterial": +materialCode,
            "scheduleType": newValue < 0 ? "O" : "D",
            "textLine": `{Author:${userName},MdfBy:null,SS:UDM}`
        }
        return obj;
    }

    genPayLoadForWhatIf(row: any, newValue: any, materialCode: any, userName: any, date: any) {
        const obj = this.getPayloadObj(row, newValue, materialCode, userName, date);
        if(this.whatIfEditedRows.length == 0) {
            this.whatIfEditedRows.push(obj)
        } else {   
            const index = this.whatIfEditedRows.findIndex((_obj: any) => ((_obj.scheduledDate === date) && (_obj.scheduledMaterial === +materialCode)));
            if(index >= 0) {
                this.whatIfEditedRows[index] = {...obj};
            } else {
                this.whatIfEditedRows.push(obj)
            }
        }
        this.notifyEditedDataPSD$.next({ isSaveDisabled: false, isSubmitted: false });

    }

    getOrigObjVal(row: any) {
        const date = row.headerToItemNav[0].scheduledDate;
        const originalObj = this.originalRowData.find((obj: any) => date === this.datePipe.transform(obj.date, "yyyyMMdd"));
        const materialCode = row.headerToItemNav[0].scheduledMaterial;
        const originalVal = originalObj[`whatifShipment_${materialCode}_PASADENA`];
        const udmId = originalObj[`udmId_${materialCode}_PASADENA`];
        const lineItemId = originalObj[`lineItemId_${materialCode}_PASADENA`];
        return [originalVal, udmId, lineItemId];
    }

    getUpdatedWhatIfs(userName: string) {
     const updatedWhatIfs: any = []   
     const newWhatIfs: any = [];
     const whatIfEditedRows = this.getPayloadForWhatIf();
     whatIfEditedRows.forEach((row: any) => {
        const [originalVal, udmId, lineItemId] = this.getOrigObjVal(row)
        if(originalVal === null) {
            newWhatIfs.push(row);
        } else {
            row.id = udmId;
            row.headerToItemNav[0].itemId = lineItemId || null;
            row.headerToItemNav[0].textLine = `{Author:null,MdfBy:${userName},SS:UDM}`;
            updatedWhatIfs.push(row);
        }
     });
     return {newWhatIfs, updatedWhatIfs}
    }

    getPayloadForWhatIf() {
        let payload: any[] = [];
        const whatIfEditedRows = JSON.parse(JSON.stringify(this.whatIfEditedRows))
        whatIfEditedRows.forEach((ele: any) => {
            const obj: any = {
                ...WHAT_IF_PAYLOAD,
                headerToItemNav: [{...WHAT_IF_PAYLOAD_2, ...ele}]
            }
            payload.push(obj)
        })
        return payload;
    }


}