import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PipelineViewService {

  constructor(private apiService: ApiService) { }
  getPipelineViewData(payload: any, isPivotActive: any): Observable<any> {
    const deskParam = localStorage.getItem("desk");
    let urlUpdate = '';
    if (payload.viewtype && payload.viewtype.includes('Tank')) {
      urlUpdate = 'GetTankPipelineDataAsync';
    } 
    else{
          urlUpdate = deskParam?.includes('clean products + latam') ? 'GetCCADataAsync' : 'GetPipelineDataAsync';
    }  
     delete payload.viewtype; 
    let url = ''
    url = `${environment.replenishmentPlanningAPI}/${urlUpdate}?`;
    return this.apiService
      .post(
        url,
        'replenishmentPlanning',
        payload
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  savePipelineNominationData(updatedPipelineNominationBody: any[]): Observable<any> {
    return this.apiService
      .patch(
        `${environment.nominationAPI}/UpdateNominations`,
        'nomination',
        updatedPipelineNominationBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  savePipelineNominationDataAsDraft(updatedPipelineNominationBody: any[]): Observable<any> {
    return this.apiService
      .post(
        `${environment.nominationAPI}/CreateNomination?`,
        'nomination',
        updatedPipelineNominationBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
