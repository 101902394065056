<div class="confirm-modal">
  @if (alertMsg) {
    <p>{{delinkMsg}}</p>
  }
  <p>{{alertMsg}}</p>
    <ng-container *ngIf="showNominationMessage == 'delete'">
    <div class="confirm-buttons">
    <div class="button-container d-flex">
      <button class="ok-button button" (click)="confirm()">yes</button>
      <button class="cancel-button button" (click)="closePopup()">No</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="showNominationMessage == 'multiplelineitems'">
    <div class="confirm-buttons">
        <div class="button-container d-flex">
            <button class="cancel-button button" (click)="closePopup()">Ok</button>
        </div>
    </div>
</ng-container>
</div>