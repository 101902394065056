import { displaySelection, dropDownInfo, visualDisplay } from "../interface/settings.interface";


export const ruleModalType: Record<string, string> = {
    'deleteRule': 'deleteRuleModal',
    'restoreRule': 'restoreRuleModal',
    'ruleSubscribers': 'subscriberAlertModal',
    'saveRule': 'saveRuleModal',
}

//IMPORTANT: ADD HERE FOR NEW RULE 
export const ruleFields: Map<string, string[]> = new Map([
    ['sharedFields', ['ruleName', 'location', 'material', 'alertFrequency']],
    ['Inventory', ['daysToValidate;type', 'daysToValidate;NoOfdays', 'ruleCondition', 'parameter']],
    ['ActualDemand', ['daysToValidate;NoOfdays', 'ruleCondition', 'thersold']],
]);

//IMPORTANT: ADD HERE FOR NEW RULE;
export const ruleExpression: Map<string, string> = new Map([
    ['Inventory', 'if forecasted inventory in the next ${daysToValidate;NoOfdays} days is ${ruleCondition} ${parameter}'],
    ['ActualDemand', 'if actual liftings ${ruleCondition} the user-set target threshold ${thersold}% for ${daysToValidate;NoOfdays} days'],
]);


//IMPORTANT: ADD HERE FOR NEW RULE
export const ruleTypesRP: visualDisplay[] = [
    {
        displayValue:'Inventory', 
        actualValue:'Inventory',
    },
    {
        displayValue:'Demand', 
        actualValue:'ActualDemand',
    },
];

//IMPORTANT: ADD HERE FOR NEW RULE
export const ruleTypesNom: visualDisplay[] = [
    {
        displayValue:'Movement', 
        actualValue:'MovementVolumetric',
    },
];


export const ruleDropdownRP: dropDownInfo = {

    dropdownDisplayName: 'rule type',
    fieldName: 'ruleType',
    option: ruleTypesRP,
};
export const ruleDropdownNOM: dropDownInfo = {

    dropdownDisplayName: 'rule type',
    fieldName: 'ruleType',
    option: ruleTypesNom,
};

export interface dropDownSelection{
    fieldName: string,
    selectedValue: string,
}

