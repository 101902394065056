<div class="boundary">
  <div cdkDrag cdkDragBoundary=".boundary" class="list-display drag">
    <div class="d-flex flex-row justify-content-between">
      <p class="text m-1">changes</p>
      <img src="../../../assets/icons/icon_close_x.svg" alt="close arrow" class="hover" (click)="fireClose()">
    </div>
    <ul>
      @for (change of changeList; track change) {
        <li>{{change}}</li>
      }
    </ul>
  </div>
</div>

