<div class="modal fade" [ngClass]="{ show: show }" id="staticBackdrop" data-backdrop="static" data-keyboard="false"
  tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" [class.modal-dialog-cb-dynamic-deals]="isCBDynamicDeals">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title-wrap">
          @if (label) {
            <span class="label">{{ label }}</span>
          }
          <h5 class="modal-title" id="staticBackdropLabelTitle">
            {{ headerTitle }}
          </h5>
        </div>
        @if (subHeaderText !== '') {
          <em class="pull-right mandatory-text">{{ subHeaderText }}</em>
        }
      </div>
      <div class="modal-body d-flex flex-column">
        <ng-content></ng-content>
      </div>
      @if (!noModalButtons) {
        <div class="modal-footer d-flex justify-content-start">
          @if (secondaryButtonLabel.length) {
            <button type="button" (click)="secondaryClick()"
              class="button secondary m-0 mr-3" data-dismiss="modal">
              {{ secondaryButtonLabel }}
            </button>
          }
          @if (primaryButtonLabel.length) {
            <button type="button" (click)="primaryClick()"
              class="button primary m-0 mr-3">
              {{ primaryButtonLabel }}
            </button>
          }
        </div>
      }
    </div>
  </div>
</div>