<div class="create-nominations-panel-wrapper">
  <div class="panel-header d-flex justify-content-between">
    <div class="panel-title">nominations</div>
    <button class="nominations-btn nominations-panel nominations" (click)="closeNominationsPanel()">
      <span class="icon-remove open"></span>
    </button>
  </div>
  <div class="panel-content">
    <div class="accordion-item">
      <div class="accordion-header" [class.highlight-header]="draftNominations.length > 0"
        (click)="expandDraftAccordion()">
        draft({{draftNominations.length}})@if (!isDraftAccordionExpanded) {
        <span class="expand-icon"
        >+</span>
        }@if (isDraftAccordionExpanded) {
        <span class="expand-icon"
        >-</span>
      }
    </div>
    @if (isDraftAccordionExpanded) {
      <div class="accordion-content">
        @for (draftNom of draftNominations; track draftNom; let i = $index) {
          <div class="draft-nominations">
            <div class="row counter-part" [ngClass]="{'highlight_counterpart': getMatchedlineItem?.id=== draftNom?.id}">
              <div class="col-md-10 offtaker">
                <span class="content-header cpname">{{draftNom.counterPart}}</span>
                <span class="content-body">Created on {{draftNom.detailType[0].createdOn}}</span>
              </div>
              <div class="col-md-2">
                <div class="list">
                  <button class="ellipses-icon"></button>
                  <div class="list-content">
                    <a class="list-item create-nomination"
                      (click)="onCreateDraftNomination(draftNom)">create
                    nomination</a>
                  </div>
                </div>
              </div>
            </div>
            @if (draftNom.detailType.length === 0 || (draftNom.detailType.length > 0 && (draftNom.counterPart !== 'TERPEL' && draftNom.counterPart !== 'DELTA'))) {
              <div
                >
                <span class="content-header">nomination volume</span>
                <input type="number" class="form-control content-body" name="scheduledQty"
                  [(ngModel)]="draftNom.scheduledQty">
                </div>
              }
              @if (draftNom.detailType.length > 0 && draftNom.counterPart === 'TERPEL') {
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">utilization volume</span>
                      <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                        (input)="onInput($event, i, draftNom.inventoryDetailType[0]?.id, 'inventory')">{{
                      draftNom.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">reserve volume</span>
                      <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                        (input)="onInput($event, i, draftNom.reserveDetailType[0]?.id, 'reserve')">{{
                      draftNom.reserveDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">heels volume</span>
                      <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                        (input)="onInput($event, i, draftNom.heelsDetailType[0]?.id, 'heel')">{{
                      draftNom.heelsDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">product</span>
                      <span class="content-body">{{ draftNom.product }}</span>
                    </div>
                  </div>
                  <div>
                    <span class="content-header">destination date</span>
                    <span class="content-body" name="destinationDate">{{draftNom?.destinationDate}}</span>
                  </div>
                </div>
              }
              @if (draftNom.detailType.length > 0 && draftNom.counterPart === 'DELTA') {
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">utilization volume</span>
                      <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                        (input)="onInput($event, i, draftNom.inventoryDetailType[0]?.id, 'inventory')">{{
                      draftNom.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">safety volume</span>
                      <span contenteditable="true" class="content-body" (keypress)="onKeyPress($event)"
                        (input)="onInput($event, i, draftNom.safetyDetailType[0]?.id, 'safety')">{{
                      draftNom.safetyDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">product</span>
                      <span class="content-body">{{draftNom.product}}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">destination date</span>
                      <span class="content-body" name="destinationDate">{{draftNom?.destinationDate }}</span>
                    </div>
                  </div>
                </div>
              }
              @if (draftNom.detailType.length === 0 || (draftNom.detailType.length > 0 && (draftNom.counterPart !== 'TERPEL' && draftNom.counterPart !== 'DELTA'))) {
                <div class="row"
                  >
                  <div class="col-md-6">
                    <span class="content-header">product</span>
                    <span class="content-body">{{draftNom.product}}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="content-header">destination date</span>
                    <span class="content-body" name="destinationDate">{{draftNom?.destinationDate }}</span>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    <div class="accordion-item">
      <div class="accordion-header" [class.highlight-header]="publishedNominations.length > 0"
        (click)="expandPublishedAccordion()">
        published({{publishedNominations.length}})@if (!isPublishedAccordionExpanded) {
        <span class="expand-icon"
        >+</span>
        }@if (isPublishedAccordionExpanded) {
        <span class="expand-icon"
        >-</span>
      }
    </div>
    @if (isPublishedAccordionExpanded) {
      <div class="accordion-content">
        @for (publishedNom of publishedNominations; track publishedNom; let i = $index) {
          <div class="published-nominations">
            <div class="row" [ngClass]="{'highlight_counterpart-published': getMatchedlineItem?.id=== publishedNom?.id}">
              <div class="col-md-12 accordion-item-internal">
                <div class="accordion-header counter-part" (click)="expandPublishedAccordionInternal(publishedNom?.id)">
                  <div class="row headerPadding">
                    <div class="col-md-1 alligncentercontent">
                      @if (isPublishedAccordionExpandedInternal==publishedNom?.id) {
                        <span class="expand-icon"
                        >-</span>
                      } @else {
                        <span class="expand-icon">+</span>
                      }
                    </div>
                    <div class="col-md-9 offtaker">
                      <span class="content-header cpname">{{publishedNom?.counterPart}} </span>
                      <span class="content-body">Created on {{publishedNom?.detailType[0]?.createdOn}}</span>
                    </div>
                    <div class="col-md-1 alligncentercontent">
                      <div class="list">
                        <button class="ellipses-icon"></button>
                        <div class="list-content">
                          <a class="list-item create-nomination" (click)="onCreateDraftNomination(publishedNom)">create nom from reference</a>
                          <a class="list-item go-to-nomination" (click)="goToNominations(publishedNom)">go to
                          nominations</a>
                          <a class="list-item go-to-nomination" (click)="delete(publishedNom)">delete</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            @if (isPublishedAccordionExpandedInternal==publishedNom?.id) {
              <div class="accordion-content">
                <div class="row">
                  <div class="col-md-6">
                    <span class="content-header">nomination #</span>
                    <span class="content-body">{{publishedNom?.nominationNo}}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="content-header">vessel name</span>
                    <span class="content-body"></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span class="content-header">origin date</span>
                    @if (!publishedNom?.originDate) {
                      <span class="content-body">- -</span>
                    }
                    @if (publishedNom?.originDate) {
                      <span class="content-body">{{ publishedNom?.originDate?.substring(4, 6) }}/{{
                      publishedNom?.originDate?.substring(6, 8) }}</span>
                    }
                  </div>
                  <div class="col-md-6">
                    <span class="content-header">destination date</span>
                    <span class="content-body" name="destinationDate">{{publishedNom?.destinationDate }}</span>
                  </div>
                </div>
                @if (publishedNom?.detailType.length === 0 || (publishedNom?.detailType.length > 0 && (publishedNom?.counterPart !== 'TERPEL' && publishedNom?.counterPart !== 'DELTA'))) {
                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">utilization volume</span>
                        <span class="content-body">{{publishedNom?.scheduledQty}}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">product</span>
                        <span class="content-body">{{publishedNom?.product}}</span>
                      </div>
                    </div>
                  </div>
                }
                @if (publishedNom?.detailType.length > 0 && publishedNom?.counterPart === 'TERPEL') {
                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">utilization volume</span>
                        <span class="content-body">{{
                        publishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">reserve volume</span>
                        <span class="content-body">{{
                        publishedNom?.reserveDetailType[0]?.scheduledQty?? 0 }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">heels volume</span>
                        <span class="content-body">{{
                        publishedNom?.heelsDetailType[0]?.scheduledQty?? 0 }}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">product</span>
                        <span class="content-body">{{publishedNom?.product}}</span>
                      </div>
                    </div>
                  </div>
                }
                @if (publishedNom?.detailType.length > 0 && publishedNom?.counterPart === 'DELTA') {
                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">utilization volume</span>
                        <span class="content-body">{{
                        publishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                      </div>
                      <div class="col-md-6">
                        <span class="content-header">safety volume</span>
                        <span class="content-body">{{
                        publishedNom?.safetyDetailType[0]?.scheduledQty?? 0 }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span class="content-header">product</span>
                        <span class="content-body">{{publishedNom?.product}}</span>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    }
  </div>
  <div class="accordion-item">
    <div class="accordion-header" [class.highlight-header]="unpublishedNominations.length > 0"
      (click)="expandUnpublishedAccordion()">
      unpublished({{unpublishedNominations.length}})@if (!isUnpublishedAccordionExpanded) {
      <span class="expand-icon"
      >+</span>
      }@if (isUnpublishedAccordionExpanded) {
      <span class="expand-icon"
      >-</span>
    }
  </div>
  @if (isUnpublishedAccordionExpanded) {
    <div class="accordion-content">
      @for (unpublishedNom of unpublishedNominations; track unpublishedNom; let i = $index) {
        <div class="unpublished-nominations"
          >
          <div class="row" [ngClass]="{'highlight_counterpart-unpublished': getMatchedlineItem?.id=== unpublishedNom?.id}">
            <div class=" col-md-12 accordion-item-internal">
              <div class="accordion-header counter-part" (click)="expandUnPublishedAccordionInternal(unpublishedNom?.id)">
                <div class="row headerPadding">
                  <div class="col-md-1 alligncentercontent">
                    @if (isUnPublishedAccordionExpandedInternal==unpublishedNom?.id) {
                      <span class="expand-icon"
                      >-</span>
                    } @else {
                      <span class="expand-icon">+</span>
                    }
                  </div>
                  <div class="col-md-9 offtaker">
                    <span class="content-header cpname">{{unpublishedNom?.counterPart}}</span>
                    <span class="content-body">Created on {{unpublishedNom?.detailType[0]?.createdOn}}</span>
                  </div>
                  <div class="col-md-1 alligncentercontent">
                    <div class="list">
                      <button class="ellipses-icon"></button>
                      <div class="list-content">
                        <a class="list-item create-nomination"
                        (click)="onCreateDraftNomination(unpublishedNom)">create nom from reference</a>
                        <a class="list-item go-to-nomination" (click)="goToNominations(unpublishedNom)">go to
                        nominations</a>
                        <a class="list-item go-to-nomination" (click)="delete(unpublishedNom)">delete</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          @if (isUnPublishedAccordionExpandedInternal==unpublishedNom?.id) {
            <div class="accordion-content">
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">nomination #</span>
                  <span class="content-body">{{unpublishedNom?.nominationNo}}</span>
                </div>
                <div class="col-md-6">
                  <span class="content-header">vessel name</span>
                  <span class="content-body"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <span class="content-header">origin date</span>
                  @if (!unpublishedNom?.originDate) {
                    <span class="content-body">- -</span>
                  }
                  @if (unpublishedNom?.originDate) {
                    <span class="content-body">{{ unpublishedNom?.originDate?.substring(4, 6) }}/{{
                    unpublishedNom?.originDate?.substring(6, 8) }}</span>
                  }
                </div>
                <div class="col-md-6">
                  <span class="content-header">destination date</span>
                  <span class="content-body" name="destinationDate">{{unpublishedNom?.destinationDate }}</span>
                </div>
              </div>
              @if (unpublishedNom?.detailType.length === 0 || (unpublishedNom?.detailType.length > 0 && (unpublishedNom?.counterPart !== 'TERPEL' && unpublishedNom?.counterPart !== 'DELTA'))) {
                <div class="row">
                  <div class="col-md-6">
                    <span class="content-header">utilization volume</span>
                    <span class="content-body">{{unpublishedNom?.scheduledQty}}</span>
                  </div>
                  <div class="col-md-6">
                    <span class="content-header">product</span>
                    <span class="content-body">{{unpublishedNom?.product}}</span>
                  </div>
                </div>
              }
              @if (unpublishedNom?.detailType.length > 0 && unpublishedNom?.counterPart === 'TERPEL') {
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">utilization volume</span>
                      <span class="content-body">{{
                      unpublishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">reserve volume</span>
                      <span class="content-body">{{
                      unpublishedNom?.reserveDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">heels volume</span>
                      <span class="content-body">{{
                      unpublishedNom?.heelsDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">product</span>
                      <span class="content-body">{{unpublishedNom?.product}}</span>
                    </div>
                  </div>
                </div>
              }
              @if (unpublishedNom?.detailType.length > 0 && unpublishedNom?.counterPart === 'DELTA') {
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">utilization volume</span>
                      <span class="content-body">{{
                      unpublishedNom?.inventoryDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                    <div class="col-md-6">
                      <span class="content-header">safety volume</span>
                      <span class="content-body">{{
                      unpublishedNom?.safetyDetailType[0]?.scheduledQty?? 0 }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <span class="content-header">product</span>
                      <span class="content-body">{{unpublishedNom?.product}}</span>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>


</div>
</div>