import { createFeatureSelector, createSelector} from "@ngrx/store";
import { State } from "../reducer/dynamicdeal-detail.reducer";



export const selectDealDetailState = createFeatureSelector<State>('cbDynamicDealDetail');

export const getDynamicDealDetailData = createSelector(
    selectDealDetailState,
    (state) => state.cbDynamicDealDetailData
)

export const getDynamicDealDetailDataError = createSelector(
    selectDealDetailState,
    (state) => state.error
)


export const getSelectedIndicators = createSelector(
    selectDealDetailState,
    (state) => state.selectedIndicators
)

export const getDealContractId = createSelector(
    selectDealDetailState,
    (state) => state.dealContractId
)