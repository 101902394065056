import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { dropDownInfo, visualDisplay } from '../interface/settings.interface';

@Component({
  selector: 'reusable-dropdown',
  templateUrl: './reusable-dropdown.component.html',
  styleUrls: ['./reusable-dropdown.component.scss']
})
export class ReusableDropdownComponent implements OnInit, OnChanges {

  @Input() dropDownInfo!: dropDownInfo;
  @Input() disabled: boolean = false;
  @Input() selectedValue: string = ''
  @Output() valueSelected = new EventEmitter();
  previousValue: string = '';
  @Input() showLabel: boolean = true;

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(){
    if(this.selectedValue == ''){
      this.previousValue = ''
    }

    if(this.selectedValue == ';' || this.selectedValue == ';;'){
      this.selectedValue = '';
      this.previousValue = '';
    }
  }

  changeSelection(change: any){

    //no null or same value allowed for event
    if(change.target.value == null || this.previousValue == change.target.value){
      return;
    }

    this.valueSelected.emit({
      field: this.dropDownInfo.fieldName,
      valueSelected: change.target.value
    });

    this.previousValue = change.target.value;
  }

  showTooltipValue(){
    return this.selectedValue.split(';').join(" - ");
  }

}
