<div class="more-menu">
  <span class="text-truncate">{{ values['inTransitQty'] }}</span>
  @if (values['isEditable']) {
    <div class="more-menu-dropdown">
      <button class="ellipses-icon"></button>
      <div class="dropdown-content">
        @if (values['relatedNominations']?.length) {
          @for (nomination of values['relatedNominations']; track nomination) {
            <a class="dropdown-item edit-nomination" (click)="editNomination(nomination)">edit nomination {{nomination.nominationNo}}</a>
          }
        }
      </div>
    </div>
  }
</div>