<div class="more-menu">
  @if (!nominationTab) {
    @if (details) {
      <span [ngClass]="[moremenuIconShow ? details?.status : '']">{{value}}</span>
    } @else {
      <span [ngClass]="[moremenuIconShow ? '' : '']">{{value}}</span>
    }
  }
  <div class="more-menu-dropdown">
    @if (moremenuIconShow && ( (details?.id!== '' && details?.status!== ''))) {
      <button class="ellipses-icon"
      (click)="moremwnu()"></button>
    }
    @if (!isOpen) {
      <div class="dropdown-content" [ngClass]="[!isDetailGrid ? (getRowIndex(pipelineParentObj) < 10 ? '' : 'place-top') : (rowIndex < 5 ? '' : 'place-top')]">
        @if (!isDetailGrid) {
          <a class="dropdown-item edit-nomination" (click)="editNomination(pipelineParentKey)">edit
          nomination</a>
        }
        @if (!isDetailGrid) {
          <a class="dropdown-item create-nom-with-ref"
            (click)="createNomWithRef(pipelineParentKey)">create nom. with
          reference</a>
        }
        @if (!isDetailGrid && nominationTab && !lineItem && (desk === PipelineUsecFloridaDesk || desk === PipelineUsecRichmondDesk || desk === PipelineUsecpascagoulaDesk || desk === PipelineUswcPNWDesk)) {
          <a class="dropdown-item contract-balancinf"
          (click)="vesselScheduleUpdate()" [ngClass]="{'disableVesselOption': disableVesselOption(childGridData)}">vessel schedule update</a>
        }
        @if (!isDetailGrid) {
          <a class="dropdown-item contract-balancinf"
            (click)="contractBalancing()">contract
          balancing</a>
        }
        <a class="dropdown-item publish" (click)="publish(pipelineParentKey)">publish</a>
        <a class="dropdown-item delete"
        (click)="delete(pipelineParentKey, gridRowData)">delete</a>
        @if (!isDetailGrid) {
          <a class="dropdown-item see-details" (click)="seeDetails()">see details</a>
        }
        <a class="dropdown-item see-details" (click)="openActivityTracker()"> see nomination activity </a>
        @if (!isDetailGrid) {
          <a class="dropdown-item see-details" (click)="openComments()"> nomination comments </a>
        }
      </div>
    }
  </div>
</div>