
<div class="dismiss-alert-popup">
  <div class="">
    <p class="titleValue">
      {{this.alertTitle.toLowerCase()}}!
    </p>
  </div>

  <div class="middle-content">are you sure that you want to dismiss the {{this.alertTitle.toLowerCase()}} warning?</div>

  <div class="middle-content">The alert will continue to trigger until addressed</div>

  @if (this.alertTitle.toLowerCase().split(' ')[0] !== 'unreconciled') {
    <div class="middle-content">you'll still be able to access and manage your inventory</div>
  }


  <div class="d-flex justify-content-end align-items-space-between smaller-text">

    <div class="left-box" (click)="closePopup()">
      cancel and go back &#x2190;
    </div>
    <div class="left-box" (click)="dismissPopup()">
      yes, dismiss &#x2713;
    </div>
  </div>
</div>