
<div class="default-card"
  [ngClass]="{'largeCard': cardExpanded, 'smallCard': !cardExpanded, 'hasData': hasContent}"
  [@trans-expand]="cardExpanded? 'largeCard': 'smallCard'">
  <div class="d-flex justify-content-between full-value">
    <div class="epicWidth">
      @if (!cardExpanded) {
        <ng-content select="[front]">
        </ng-content>
      }
      @if (cardExpanded) {
        <ng-content select="[back]">
        </ng-content>
      }
    </div>
    <div class="d-flex flex-column justify-content-center">
      <img (click)="toggleExpand()" src="../../../assets/icons/icon_right_arrow_no-line.svg"
        [ngClass]="!cardExpanded ? 'arrowLeft' : 'arrowRight'">
      </div>
    </div>
  </div>