import { Pipe, PipeTransform } from '@angular/core';
import { checkDisplayOption } from './rule-filter.component';

@Pipe({
  name: 'ruleFilter'
})
export class RulePipe implements PipeTransform {

  transform(filterOptions: checkDisplayOption[], searchTerm: string): any[] {
    if (!filterOptions || !searchTerm || searchTerm == '') {
      return filterOptions;
    }
    
    let filteredList = filterOptions.slice(1).filter(filterOptions => filterOptions.displayValue.toLowerCase().includes(searchTerm.toLowerCase())
    || filterOptions.actualValue.toLowerCase().includes(searchTerm.toLowerCase()));

    filteredList.unshift(filterOptions[0])
    return filteredList;
  }

}
