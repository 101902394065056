import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AlertsService } from 'src/app/services/alerts.service';
import { MdmService } from 'src/app/services/mdm.service';
import { ruleTypesNom, ruleTypesRP } from 'src/app/shared/constants/rule.constants';
import { visualDisplay } from 'src/app/shared/interface/settings.interface';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { selectDesk } from 'src/app/shared/store/selector/app.selector';

@Component({
  selector: 'rule-filter',
  templateUrl: './rule-filter.component.html',
  styleUrls: ['./rule-filter.component.scss']
})
export class RuleFilterComponent implements OnInit {

  [key: string]: any;

  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  
  lock: boolean = false;
  private allOption: checkDisplayOption = {
    displayValue: 'all',
    actualValue: 'all',
    checked: true
  }

  deskLocations: checkDisplayOption[] = [Object.assign({}, this.allOption)];
  materialsList: checkDisplayOption[] = [Object.assign({}, this.allOption)];
  ruleTypeList: checkDisplayOption[] = [Object.assign({}, this.allOption)];

  //variables for filter typing option 
  locationSearchTerm: string = ''
  materialSearchTerm: string = ''
  ruleTypeSearchTerm: string = '';


  //lists for filtering
  selectedLocations: string[] = [];
  selectedMaterials: string[] = [];
  selectedTypes: string[] = [];

  //cache of mdm location materials for selection 
  locMaterials: any[] = [];

  constructor(private appStore: Store<AppState>, private mdmService: MdmService, 
    private changeDetector: ChangeDetectorRef, private alertService: AlertsService) { }

  ngOnInit(): void {

    this.alertService.generateMap();

    this.generateTypeList();

    this.selectedDesk$
      .subscribe({
        next: (response: any) => {

          if(!this.lock){
            this.lock = true;
            this.getLocationsForDesk();  
          }
        },
        error: (err: any) => { },
        complete: () => {}
      });


      this.alertService.showDeletedRules.subscribe((showRules: boolean ) => {
        this.updateRules();
      })

  }


  getLocationsForDesk(){
    const payload = {
      'deskIds': [parseInt(localStorage.getItem('deskId')!)]
    };

    this.deskLocations = [];
    this.deskLocations.push(this.allOption)

    this.mdmService.getDeskLocation(payload).subscribe({
      next: (response: any) => {

      let list: checkDisplayOption[] = response.value.filter((location: any) => 
        !location.locationCode.includes('-X')).map((location: any) => {
          return {
            displayValue: location.locationName,
            actualValue: location.locationCode,
            checked: true
          }  
        });
      
        list.forEach(location => this.deskLocations.push(location))
      },
      error: (error: any) => {

      },
      complete: () => {
        this.lock = false;
        this.generateSelectedList('deskLocations', 'selectedLocations')

        this.getMaterialsForDesk();
      }
    })
  }

  getMaterialsForDesk(){

    const payload = {
      'deskIds': [parseInt(localStorage.getItem('deskId')!)],
      locationIds: [],
      activeOnly: false,
    };
    this.materialsList = [];
    this.materialsList.push(this.allOption)

    this.mdmService.getMaterialMapping(payload).subscribe({
      next: (response: any) => {
        this.locMaterials = response.value.value;
      },
      error: (error: any) => {

      },
      complete: () => {

        this.generateMaterialDisplay();
        this.updateRules();
      }
    })


  }

  generateMaterialDisplay(){

    this.materialsList = [];

    this.locMaterials.forEach(locMaterial => {

      if(this.deskLocations.filter(location => location.checked).map(location => location.actualValue).includes(locMaterial.locationCode)){
        
        let materialsInLocation = locMaterial.locationMaterials;
        materialsInLocation.forEach((material: any) => {

          let valueToAdd: checkDisplayOption = {
            displayValue: material.materialDescription,
            actualValue: material.materialNumber,
            checked: true,
          }

          if(!this.materialsList.map(material => material.actualValue).includes(valueToAdd.actualValue)){
            this.materialsList.push(valueToAdd);
          }
        })
      }
    })

    this.materialsList.sort((a,b) => {
      return a.displayValue.localeCompare(b.displayValue);
    })

    this.generateSelectedList('materialsList', 'selectedMaterials');

    this.materialsList.unshift(this.allOption);
  }


  updateRules(){
    this.alertService.filterLocationMaterialRules(this.selectedLocations, this.selectedMaterials, this.selectedTypes)
  }

  generateTypeList(){
    this.createOptionFromList(ruleTypesRP);
    this.createOptionFromList(ruleTypesNom);
    this.generateSelectedList('ruleTypeList', 'selectedTypes')
  }

  createOptionFromList(list: visualDisplay[]){
    list.forEach(item => {
      this.ruleTypeList.push({
        displayValue: item.displayValue,
        actualValue: item.actualValue,
        checked: true,
      })
    })
  }

  generateSelectedList(listToIterateName: string, selectListName: string){

    this[selectListName] = [];

    this[listToIterateName].forEach((item: checkDisplayOption) => {

      if(item.checked && item.actualValue != 'all'){
        this[selectListName].push(item.actualValue);
      }
    })
  }


  //UTILITY FUNCTIONS
  processSelection(selectedOption: checkDisplayOption, listToCheck: string){
    this.updateList(selectedOption, listToCheck);
    this.updateOtherFilterLists(listToCheck);
    this.updateRules();
  }

  updateList(selectedOption: checkDisplayOption, listToCheck: string){

    let optionList = this[listToCheck] as checkDisplayOption[];

    if(selectedOption.actualValue == 'all' && selectedOption.checked){
      optionList.forEach(option => option.checked = true);
    }else if(selectedOption.displayValue == 'all' && !selectedOption.checked){
      optionList.forEach(option => option.checked = false);
    }else if(this.includesDisabled(optionList)){
      optionList[0].checked = false;
    }else if(!this.includesDisabled(optionList)){
      optionList[0].checked = true;
    }
    this[listToCheck] = optionList;
    this.updateFilter(listToCheck, this.getListToUpdate(listToCheck));
    this.changeDetector.detectChanges();
  }

  includesDisabled(list :checkDisplayOption[]): boolean{
    let checkList = list.slice(1).map(value => value.checked);
    return checkList.includes(false);
  }

  getListToUpdate(listThatWasUpdated: string): string{

    switch(listThatWasUpdated){
      case 'deskLocations':
        return 'selectedLocations';
      case 'materialsList':
        return 'selectedMaterials';
      case 'ruleTypeList':
        return 'selectedTypes';
      default:
        return ''
    }
  }

  updateFilter(listToCheck: string, nameToUpdate: string){
    let sourceList = this[listToCheck] as checkDisplayOption[];
    this[nameToUpdate] = sourceList.slice(1).filter(value => value.checked).map(value => value.actualValue);
  }

  updateOtherFilterLists(list: string){

    if(list == 'deskLocations'){
      this.generateMaterialDisplay();
    }
  }

  resetAllFilters(listsToReset: string[]){
    listsToReset.forEach(listName => {
      this.resetCheckValues(listName);
      this[this.getListToUpdate(listName)] = this[listName].slice(1).map((x: checkDisplayOption) => x.actualValue);
      this.updateOtherFilterLists(listName);
    })
    this.updateRules();
  }

  resetCheckValues(listName: string){
    let resetList = this[listName] as checkDisplayOption[];
    resetList.forEach(entry => entry.checked = true);
  }
}

export interface checkDisplayOption{
  displayValue: string,
  actualValue: string,
  checked: boolean,
}