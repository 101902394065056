import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { saveEthanolModalPopupRefResponse, saveEthanolTRFiltersResponse } from 'src/app/shared/store/action/app.action';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { SelectedTabFunction } from 'src/app/shared/interface/interface';
import { Subject, takeUntil } from 'rxjs';
import { EthanolTruckService } from 'src/app/services/ethanol-truck.service';
import { NominationService } from 'src/app/services/nomination.service';
import { ToastService } from 'src/app/services/toast.service';
import { SelectDropdownComponent } from 'src/app/shared/modules/select-dropdown/select-dropdown.component';
import { ethanolModalPopRef, getUserDetails, selectDesk, selectEthanolNomFiltersResponse } from 'src/app/shared/store/selector/app.selector';
import { Utilities } from 'src/app/shared/utilities/utilities';
import * as Nom from '../../../shared/constants/nominations.constant';
import { UDMDesk } from 'src/app/shared/constants/terminal-replenishment.constant';
import { LoaderService } from 'src/app/services/loader.service';
import { MdmService } from 'src/app/services/mdm.service';

@Component({
  selector: 'app-create-nomination-ethanol',
  moduleId: module.id,
  templateUrl: 'create-nomination-ethanol.component.html',
  styleUrls: ['create-nomination-ethanol.component.scss'],
})
export class CreateNominationEthanolComponent implements OnInit {
  bargeMot!: boolean
  @Output() closeModalPopup = new EventEmitter();
  public createTerminalNominationForm!: FormGroup;
  public transportSystemDropdown: any;
  public originNominationTypeDropdown: any;
  public destNominationTypeDropdown: any;
  public monthArr = Nom.MONTHS
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedDesk$ = this.store.pipe(select(selectDesk));
  desk: string = '';
  public destinationLocationDropdown: any[] = [];
  public supplierDropdown: any[] = [];
  public carrierDropdown: any[] = [];
  selectEthanolNomFiltersResponse$ = this.store.pipe(select(selectEthanolNomFiltersResponse));
  getEhtanolFilterResponse: any;
  public carrierKeyValue: any;
  public supplierKeyValue: any;
  public destinationLocationKeyValue: any;
  public bargeKeyValue: any;
  public inValidLocation!: boolean;
  public inValidLocationMsg: any;
  destLocationData!: any;
  originLocationData!: any;
  supplierData!: any;
  carrierData!: any;
  supplierReferenceData!: any;
  carrierReferenceData!: any;
  destinationReferenceData!: any;
  originReferenceData!: any;
  bargeNameReferenceData!: any;
  creatingFormWithReference: boolean = false;
  bargeSelectDropdown: any[] = [];
  qtyDisabled = false;
  isDeskType: string = '';
  storeScheduledDate: any;
  originQtyValue = [0];
  destinQtyValue = [0];
  hideDestination: boolean = true;
  isAdditionalData: boolean = false;
  disableAddLocation: boolean = true;
  modalPopUpRefData: any;
  editingNomination:boolean = false;
  addCheckbox:boolean = false;



  @ViewChild("SelectDropdownComponent") selectDropdown!: SelectDropdownComponent;
  @ViewChild("CustomerSelectDropdownComponent") originSelectDropdown!: SelectDropdownComponent;
  @ViewChild("SupplierSelectDropdownComponent") supplierSelectDropdown!: SelectDropdownComponent;
  @ViewChild("CarrierSelectDropdownComponent") carrierSelectDropdown!: SelectDropdownComponent;
  @ViewChild("BargeNameSelectDropdownComponent") bargeNameDropdown!: SelectDropdownComponent;
  public trNominationForEthanol: any;
  public isRailOrTankSelected!: any;
  public materialCode: any;
  public mot: any;
  public bargeTransportSystem: any;
  public currentMonth: any;
  public motOptions: any[] = [];
  public modeOfTransport: any;
  public modalPopRefData$ = this.appStore.pipe(select(ethanolModalPopRef));
  public createNomWithRef = false;
  public nominationReferenceDocument: any;
  public nominationReferenceDocumentItem: any;
  public motDesc: any;
  public motDescDraft: any;
  public lineItems: any[] = [];
  public lineItem: any;
  public scheduleDate: any;
  userEmail: any;
  userDetails$ = this.appStore.pipe(select(getUserDetails));
  public productsDropdown: any[] = [];
  public createWithRefData: any;
  isOriginRequired = [false];
  isDestinationRequired = [false];
  errorMessage= [false];
  isMultipleRowItemOrigin:any = [['O']];
  isMultipleRowItemDestination:any = [['D']];
  locationOriginArray: any = [[]]
  locationDestiArray: any = [[]]
  originMovementsValue = [0]
  destinMovementsValue = [0] 

  public motHeaderItemFrommodalPopRefData: any;
  getMonth: any;
  getYear: any;
  saleFields!: boolean;
  public customerDropdown: any[] = [];
  public customerKeyValue: any;
  customerReferenceData!: any;
  @ViewChild("CustomerSelectDropdownComponent") customerSelectDropdown!: SelectDropdownComponent;
  public motSale: any[] = [];
  masterbargeValues: any;
  deletelist: any = [];
  deletedValue: boolean = false;
  formItIsUpdated!: boolean;
  transPortDropDownValue: any;
  customerIsRequired!: boolean;
  showCustomerRequired: boolean = false;
  masterCustomerData: any;
  isOldTransportITE!: boolean;
  isOldTransport!: boolean;
  draftScheduleType: any;
  draftItemId: any;
  draftDate: any;
  allDestLineItemsDataStatus!: boolean;
  allOriginLineItemsDataStatus!: boolean;
  isDisableQTY: boolean = false;
  buttonActionForNom!: any;
  showCustomerWarning: boolean = false;
  isEditNomination:boolean =false;
  editNomData: any;
  truckTimeFrameOption = 'Truck-Monthly';
  showTruckMonthly!: boolean;
  selectedTimeFrame: any;

  @HostListener('keyup', ['$event'])
  handleTab(event:any){
    if(event.key === 'Tab'){
     let id = event.target['id'];
     switch (id) {
      case 'supplier':
        this.supplierSelectDropdown.focus();
        break;
      case 'carrier':
        this.carrierSelectDropdown.focus();
        break;

      case 'barge':
        this.bargeNameDropdown.focus();
        break;

      case 'customer':
        this.customerSelectDropdown.focus();
        break;

      default:
        break;
     }
    }
  }

  constructor(private fb: FormBuilder, private changeDetection: ChangeDetectorRef, private appStore: Store<AppState>, private store: Store, private datePipe: DatePipe, private utilities: Utilities, private ethanolService: EthanolTruckService, private nominationService: NominationService, private toasterService: ToastService, private loaderService: LoaderService, private mdmService: MdmService) {
    this.createTerminalNominationForm = this.fb.group({
      nominationNo: ['', [Validators.maxLength(16), Validators.required]],
      transportSystem: ['', [Validators.required]],
      nominationNotes:'',
      btCustodyChain:'',
      modeOfTransport: [''],
      customer:[''],
      supplier: [''],
      carrier: ['', ],
      bargeName: [''],
      transport: this.fb.array([this.createFormGroupTransport(null)]),
      isoriginDestiTouched: ['', Validators.required],
      selectMultipleCheckbox1:[false],
      udmTimeFrameOption:['', [Validators.required]]
    });
  }
  origin(index: number) {
    return this.transportArray().at(index).get('origin') as FormArray;
  }
  destination(index: number) {
    return this.transportArray().at(index).get('destination') as FormArray;
  }
  ite(index: number) {
    return this.transportArray().at(index).get('ite') as FormArray;
  }
  transportArray() {
    return this.createTerminalNominationForm.get('transport') as FormArray;
  }

  toggleAddi(){
    this.addCheckbox = !this.addCheckbox;
    if(this.addCheckbox){
      this.isAdditionalData = true;
    }
    else{
      this.isAdditionalData = false;
    }
  }


  onAddititonalData(ele: any, status?:any) {
    const selectMultipleCheckbox1 = this.createTerminalNominationForm.get('selectMultipleCheckbox1');
    if (selectMultipleCheckbox1) {
      if (ele.checked) {
        this.isAdditionalData = true
      }
      else {
        this.isAdditionalData = false;
      }
    }

  }

  addDestinationRow(index:any){
    if(this.modeOfTransport !== 'truck'){
    const destinationArray = this.destination(index);
    const newDestinationRow = this.createFormGroupDestination(index);
    destinationArray.push(newDestinationRow);
    this.isMultipleRowItemDestination[index]?.push('D');
    this.destinMovementsValue[index] = destinationArray.length;
    }
  }

  addOriginRow(index: number) {
    if(this.modeOfTransport !== 'truck'){
      const originArray = this.origin(index);
      const newOriginRow = this.createFormGroupOrigin(index);
      originArray.push(newOriginRow);
      this.isMultipleRowItemOrigin[index]?.push('O');
      this.originMovementsValue[index] = originArray.length;
    }
  }
  deleteOriginRow(transportIndex: number, rowIndex: number){   
    this.deletedNomList(transportIndex, rowIndex, 'origin')
    this.origin(transportIndex).removeAt(rowIndex);
    this.isMultipleRowItemOrigin[transportIndex]?.splice(rowIndex,1);
    this.originMovementsValue[0] -=1;
    setTimeout(() => {
      this.checkQTY(transportIndex, rowIndex)
    }, 1);

  }

  checkQTY(index: number, row: number) { 
    let originQty = 0;
    let destiQty = 0;  
    this.transportArray().at(index).get('origin')?.value?.forEach((e: any) => {
      const qty = Number(e.scheduledQty);
      if (!isNaN(qty)) {
        originQty += qty;
      }
    });
    this.transportArray().at(index).get('destination')?.value?.forEach((e: any) => {
      const qty = Number(e.scheduledQty);
      if (!isNaN(qty)) {
        destiQty += qty;
      }
    });

    this.originQtyValue[index] = originQty;
    this.destinQtyValue[index] = destiQty;
  }

  deletedestinationRow(transportIndex: number, rowIndex: number){
    this.deletedNomList(transportIndex, rowIndex, 'destination')
      this.destination(transportIndex).removeAt(rowIndex);
      this.isMultipleRowItemDestination[transportIndex]?.splice(rowIndex,1);
      this.destinMovementsValue[0] -=1;
      setTimeout(() => {
        this.checkQTY(transportIndex, rowIndex)
      }, 1);
  }

  sumMovements(row:any){
    switch (row.scheduleType) {
      case "O"://Origin 
        this.originMovementsValue[0] = isNaN(this.originMovementsValue[0]) ? 0 : this.originMovementsValue[0] += 1;
        break;
      case "D"://Destination
        this.destinMovementsValue[0] = isNaN(this.destinMovementsValue[0]) ? 0 : this.destinMovementsValue[0] += 1;
        break; 
      case "IT"://Destination
        this.originMovementsValue[0] = isNaN(this.originMovementsValue[0]) ? 0 : this.originMovementsValue[0] += 1;
        break; 
    } 
  }

  createFormGroupTransport(transportSystemControl?: any,index?:any): FormGroup {
    if(transportSystemControl?.toLowerCase() === 'ite' || transportSystemControl?.toLowerCase() === 'itw'){
       return this.fb.group({
        origin: this.fb.array([this.createFormGroupOrigin(index ?? 0)]),
       })
    }
    else{
      return this.fb.group({
        origin: this.fb.array([this.createFormGroupOrigin(index ?? 0)]),
        destination: this.fb.array([this.createFormGroupDestination(index ?? 0)]),
      });      
  }
}


  createFormGroupOrigin(index: number): FormGroup {
      return this.fb.group({ 
        nominationType :'',
        rdi: '',
        originLocation : '',
        demandMaterial : '',
        scheduledMaterial: '',
        sapcontract: '',
        scheduledQty: '',
        scheduledDate: '',
        tswStatus:'',
        contractLineNo: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '', 
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        id: null,
        indicator: index,
        confirmationIndicator: false,
        nominationItemStatus:''
      },
        { validator: [this.customRequiredValidator.bind(this)] }
      
    )
  }



  createFormGroupDestination(index: number): FormGroup {
      return this.fb.group({ 
        nominationTypeDesti: '',
        nominationTypeRdiDesti: '',
        scheduledMaterial : '',
        demandMaterial : '',
        scheduledDate: '',
        tswStatus:'',
        sapcontract: '',
        contractLineNo:'',
        scheduledQty: '',
      
        endLoadDate:'',
        originLocationDesti: '',
        batchOriginLocation: '',
        valuationTypeOriginLoc: '',
        valuationTypeDestinationLoc: '',
        batchDestinationLocation: '',
        itemId: '',
        partnerNomNo:'',
        id: null,
        indicator: index,
        confirmationIndicator: false,
        nominationItemStatus:''
      },
        { validator: [this.customRequiredValidatorDesti.bind(this)] }
      )
    
  }


  customRequiredValidator(g: any) { 
    let fields = ['nominationType','rdi', 'originLocation', 'demandMaterial',"scheduledMaterial", 'scheduledQty', 'scheduledDate'];
    if (g?.value['rdi'] || g?.value['nominationType'] || g?.value['originLocation'] || g?.value['demandMaterial'] || g?.value['scheduledMaterial'] || g?.value['scheduledQty'] || g?.value['scheduledDate']) {
      this.isOriginRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
        if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
      })
      this.createTerminalNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createTerminalNominationForm.get('isoriginDestiTouched')?.clearValidators()

    } else {
      this.isOriginRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();

      })
    }
  }

 
  customRequiredValidatorDesti(g: any) {
    let fields = ['nominationTypeDesti', 'nominationTypeRdiDesti', 'scheduledMaterial', 'demandMaterial', 'scheduledDate', 'scheduledQty',"originLocationDesti"];
    if (g?.value['nominationTypeDesti'] || g?.value['nominationTypeRdiDesti'] || g?.value['scheduledMaterial'] || g?.value['demandMaterial'] || g?.value['scheduledDate'] || g?.value['scheduledQty'] || g?.value['originLocationDesti'] ) {
      this.isDestinationRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
        g.get(ele).setValidators(Validators.required);
        if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
      })
      this.createTerminalNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createTerminalNominationForm.get('isoriginDestiTouched')?.clearValidators()
    } else {
      this.isDestinationRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();

      })
    }
  }

  
  ngAfterViewChecked() { this.changeDetection.detectChanges(); }
  ngOnInit(): void {
    this.initialValues();
    this.getMonth = new Date().toISOString().split('T')[0].substring(0, 7);
    this.getYear = `${+this.getMonth.substring(0, 4) + 3}-${this.getMonth.substring(5, 7)}`;
  }


  initialValues() {
    this.inValidLocation = false;
    this.inValidLocationMsg = '';
    this.createWithRefData = {};
    return new Promise<void>((resolve, reject) => {
      if (this.ethanolService.modalPopoOpen$.value) {
        this.userDetails$
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: any) => {
              if (response.userDetails.isUser) {
                this.userEmail = this.utilities.getUserName(response.userDetails.email);
              }
            },
            error: (err: any) => { },
            complete: () => { },
          });
        this.selectedDesk$
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response: any) => {
              this.desk = response.selectedDesk
              this.nominationService.isModalPopupOpen$.subscribe((res:any)=>{
                if (res === true) {
                   this.closePopup();
                }
              })
            },
            error: (err: any) => {
              console.log(err);
            },
            complete: () => { },
          });
        
        this.getModalPopRefData(resolve)
        this.currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');

        this.selectEthanolNomFiltersResponse$.pipe(takeUntil(this.destroy$)).subscribe({
          next: (response: any) => {
            this.getEhtanolFilterResponse = response
            this.setLocationSupplierCarrierData();
          }
        });

        this.utilities.getDataFromParent().subscribe((response: SelectedTabFunction) => {
          if (response.function === 'createEthanolWithRefFromMoremenu') {
            this.createNominationWithReference(response.type);
          }
        });

      }

    });
  }

  getEthanolDropdownList(){
    let data = this.utilities.getRequiredEthanolFilterJsonStructure((this.utilities.globalethanolAggregatorResponse), false, false);
    const lists = this.utilities.getEthanolMasterDataDropdownList(data);
    return lists;
  }

  getModalPopRefData(resolve:any){
    this.modalPopRefData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (this.isDeskType !== this.desk) {
        this.isDeskType = this.desk;
        const getType: any = {
          [UDMDesk.EthanolUswc]: 'ethanolfilterdata',
          [UDMDesk.EthanolUsec]: 'ethanolusecfilterdata'
        };
        let tOwnership = (this.utilities?.terminalOwnershipValue?.name || 'single entity') === 'single entity' ? 'singleentity' : 'multientity';
          if (this.utilities.globalethanolAggregatorResponse == null) {
            this.utilities.getEthanolFilterData(tOwnership, getType[this.desk]).subscribe(result => {
                this.utilities.globalethanolAggregatorResponse = result;
            })
          }
          if (!this.utilities.ethanolBargeResponse) {
            this.mdmService.getVehicles('B').subscribe({
              next: (response: any) => {
                if (response?.success && response?.statusCode === 200) {
                  this.utilities.ethanolBargeResponse = response.value.value.map((ele: any) => {
                    return {
                      value: ele.vehicle,
                      name: ele.vehicleName
                    }
                  })
                }
              }
            });
          }
          let mdmModalPopUpRefData = this.utilities.getRequiredEthanolFilterJsonStructure((this.utilities.globalethanolAggregatorResponse), false, false);
          this.modalPopUpRefData = mdmModalPopUpRefData;    
          this.modalPopUpRefData.TimeFrameOption.forEach((el: string, index: number) => {
            this.modalPopUpRefData.TimeFrameOption[index] = el.toLowerCase();
        });
          this.appStore.dispatch(saveEthanolModalPopupRefResponse({ ethanolModalPopupRefResponse:  mdmModalPopUpRefData }));
          this.modalpopRefData(mdmModalPopUpRefData);
          resolve();
      } else {
        this.modalpopRefData(data);
        resolve();
      }
    });
  }
  setLocationSupplierCarrierData() {
    if (this.getEhtanolFilterResponse.ethanolFromDate !== null && this.getEhtanolFilterResponse.ehtanolToDate !== null) {
      this.getEhtanolFilterResponse.ethanolFilterValues.forEach((element: any) => {
        if (element.headerName === 'terminal') {
          this.destinationLocationDropdown = this.getLocationId(element);
          this.destinationLocationKeyValue = this.getDetails(element);
        } else if (element.headerName === 'supplier') {
          this.supplierKeyValue = this.getDetails(element);
          this.supplierDropdown = this.getNames(element);
        } else if (element.headerName === 'carrier') {
          this.carrierKeyValue = this.getDetails(element);
          this.carrierDropdown = this.getNames(element);
        } else if (element.headerName === 'customer') {
          this.customerKeyValue = this.getDetails(element);
          this.customerDropdown = this.getNames(element);
          this.masterCustomerData = element;
        }
      });
    }
  }

  modalpopRefData(data: any) {
    this.motOptions = []; 
    let ethDropdownListMDMResponse = this.getEthanolDropdownList();
    this.setScheduledDemandProductMDM(ethDropdownListMDMResponse.value.product);
    this.modeOfTransport = 'truck';
    data.modeOfTransportDesc?.forEach((mot: any) => {
      this.motOptions.push({ name: mot?.toLowerCase(), checked: mot?.toLowerCase() === 'truck' })
    });
    this.transportSystemDropdown = data.motHeaderItem.filter((data: any) =>{ return data.modeOfTransportDesc.toLowerCase() === 'truck'})[0]?.transportSystem;
    this.getNominationDescription('truck');
    this.motHeaderItemFrommodalPopRefData = data.motHeaderItem;
    if (!this.createNomWithRef) {
      this.motBasedFormFields('truck');
    }
  }


  getDetails(element: any) {
    const list = element.list.filter((ele: any) => ele.id).map((item: any) => item);
    return list;
  }

  getNames(element: any) {
    const list = element.list.filter((ele: any) => ele.id).map((item: any) => item.name);
    return list;
  }
  
  getLocationId(element: any) {
    const list = element.list.filter((ele: any) => ele.id).map((item: any) => item.id);
    return list;
  }
  
  setScheduledDemandProductMDM(element: any) {
    if(this.productsDropdown.length == 0){
      element.forEach((item: any) => {
        this.productsDropdown.push({
          name:item.productName,
          value:item.productCode
        });
  
      });
    }
  }

  onValueChangeOfTransportSystem(event: any) {

  }
  onLocationValueChange(value: any) {
    this.inValidLocation = false;
    this.changeDetection.detectChanges();

  }


  resetModal() { 
    this.createTerminalNominationForm.reset({
      nominationNo:'',
      transportSystem: '',
      nominationNotes:'',
      btCustodyChain:'',
      customer:'',
      supplier:'',
      carrier: '',
      bargeName: '',
      selectMultipleCheckbox1:false,
      isoriginDestiTouched:'',
      udmTimeFrameOption:''
  });
  this.truckTimeFrameOption = '';
  this.deletelist = [];
  while (this.transportArray()?.controls?.length !== 0) {
  this.transportArray().removeAt(0);
  }
  this.disableAddLocation = true;
  this.showCustomerRequired = false;
  this.isAdditionalData = false;
  this.transPortDropDownValue = '';
  this.createTerminalNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true });
  this.transportArray().push(this.createFormGroupTransport(null));
  this.productsDropdown = [];
  this.locationOriginArray = [[]];
  this.locationDestiArray = [[]];
  this.isMultipleRowItemOrigin = [['O']];
  this.isMultipleRowItemDestination = [['D']];
  this.supplierReferenceData = null;
    this.carrierReferenceData = null;
    this.customerReferenceData = null;
    this.supplierReferenceData = null;
    this.carrierReferenceData = null;
    this.bargeNameReferenceData = null;
    this.selectDropdown?.resetInput();
    this.supplierSelectDropdown?.resetInput();
    this.carrierSelectDropdown?.resetInput();
    this.bargeNameDropdown?.resetInput();
    this.bargeNameDropdown?.resetInput();
    this.changeDetection.detectChanges();
    this.customerReferenceData = null;
    this.customerSelectDropdown?.resetInput();
    this.showCustomerWarning = false;
    this.editingNomination=false;
  }

  //pre set data to modal fields'
  presetNominationDataForEthanol() {
    if(this.trNominationForEthanol){
      this.draftDate = this.trNominationForEthanol.scheduledDate.split('/')[1];
      this.draftScheduleType = this.trNominationForEthanol.headerToItemNav[0].scheduleType;
      this.draftItemId = this.trNominationForEthanol.itemId;
    }    
    this.checkIfQtyCanDisabled();



  
    this.createNominationWithReference(this.trNominationForEthanol);
  }

  checkIfQtyCanDisabled(){
    if(this.trNominationForEthanol){
      if (this.trNominationForEthanol?.screenName === 'side-panel-tr' && this.trNominationForEthanol.sys_status
      === 'draft' && this.trNominationForEthanol.modeOfTransportDesc.toLowerCase() === 'truck') {
        let set = new Set();
      this.trNominationForEthanol.headerToItemNav[0].udmDailyBreakdownQty.forEach((item:any) => {
        set.add(item.scheduledQty)
      })
      let arr = Array.from(set);
      this.isDisableQTY = !(arr.includes(0) && arr.length === 2);
    }
    else{
      this.isDisableQTY = false;
    }
    }
   
    
    
  }


  //dynamically adding/removing validator for supplier fields
  setSupplierValidations() {
    if (this.createTerminalNominationForm.controls['originLocation']) {
      this.createTerminalNominationForm.removeControl('originLocation');
    }

    if (this.createTerminalNominationForm.controls['carrier']) {
      this.createTerminalNominationForm.get('carrier')?.clearValidators();
      this.createTerminalNominationForm.get('carrier')?.setErrors(null);
      this.createTerminalNominationForm.get('carrier')?.updateValueAndValidity();
    }

    if (this.createTerminalNominationForm.controls['supplier']) {
      this.createTerminalNominationForm.get('supplier')?.addValidators(Validators.required);
      this.createTerminalNominationForm.get('supplier')?.updateValueAndValidity();
    }

    if (this.createTerminalNominationForm.controls['customer']) {
      this.createTerminalNominationForm.removeControl('customer');
    }

  }

  //dynamically adding/removing validator for Carrier fields
  setCarrierValidations() {
    if (!this.createTerminalNominationForm.controls['originLocation']) {
      this.createTerminalNominationForm.addControl('originLocation', new FormControl('', Validators.required))
    }
    if (this.createTerminalNominationForm.controls['carrier']) {
      this.createTerminalNominationForm.get('carrier')?.addValidators(Validators.required);
      this.createTerminalNominationForm.get('carrier')?.updateValueAndValidity();
    }
    if (this.createTerminalNominationForm.controls['supplier']) {
      this.createTerminalNominationForm.get('supplier')?.clearValidators();
      this.createTerminalNominationForm.get('supplier')?.setErrors(null);
      this.createTerminalNominationForm.get('supplier')?.updateValueAndValidity();
    }

    if (this.createTerminalNominationForm.controls['customer']) {
      this.createTerminalNominationForm.removeControl('customer');
    }

  }

  //dynamically adding/removing validator for Direct fields
  setDirectValidations() {
    if (this.createTerminalNominationForm.controls['supplier']) {
      this.createTerminalNominationForm.get('supplier')?.clearValidators();
      this.createTerminalNominationForm.get('supplier')?.updateValueAndValidity();
    }
    if (this.createTerminalNominationForm.controls['carrier']) {
      this.createTerminalNominationForm.get('carrier')?.addValidators(Validators.required);
      this.createTerminalNominationForm.get('carrier')?.updateValueAndValidity();
    }

    if (!this.createTerminalNominationForm.controls['originLocation']) {
      this.createTerminalNominationForm.addControl('originLocation', new FormControl('', Validators.required));
    }

    if (this.createTerminalNominationForm.controls['customer']) {
      this.createTerminalNominationForm.removeControl('customer');
    }
  }

  setSaleValidations() {
    if (this.createTerminalNominationForm.controls['customer']) {
      this.createTerminalNominationForm.removeControl('customer');
    }
    if (!this.createTerminalNominationForm.controls['customer']) {
      this.createTerminalNominationForm.addControl('customer', new FormControl(''));
      this.createTerminalNominationForm.get('customer')?.updateValueAndValidity();
    }

    if (this.createTerminalNominationForm.controls['supplier']) {
      this.createTerminalNominationForm.get('supplier')?.clearValidators();
      this.createTerminalNominationForm.get('supplier')?.updateValueAndValidity();
    }
    if (this.createTerminalNominationForm.controls['carrier']) {
      this.createTerminalNominationForm.get('carrier')?.clearValidators();
      this.createTerminalNominationForm.get('carrier')?.updateValueAndValidity();
    }
  }

  setBargeNameValidation() {
    if (!this.createTerminalNominationForm.controls['bargeName']) {
      this.createTerminalNominationForm.addControl('bargeName', new FormControl(''));
    }
  }
  removeBargeNameValidation() {
    if (this.createTerminalNominationForm.controls['bargeName']) {
      this.createTerminalNominationForm.removeControl('bargeName');
    }
  }
  setSupplierValidation() {
    if (!this.createTerminalNominationForm.controls['supplier']) {
      this.createTerminalNominationForm.addControl('supplier', new FormControl('', Validators.required));
    }
  }
  removeSupplierValidation(){
    if (this.createTerminalNominationForm.controls['supplier']) {
        this.createTerminalNominationForm.removeControl('supplier');
    }
  }
  setCarrierValidation() {
    if (!this.createTerminalNominationForm.controls['carrier']) {
      this.createTerminalNominationForm.addControl('carrier', new FormControl('', Validators.required));
    }
  }
  removeCarrierValidation() {
    if (this.createTerminalNominationForm.controls['carrier']) {
      this.createTerminalNominationForm.removeControl('carrier');
    }
  }
  setCycleIdValidation() {
    if (!this.createTerminalNominationForm.controls['cycleId']) {
        this.createTerminalNominationForm.addControl('cycleId', new FormControl(''));
      }
  }
  removeCycleIdValidation() {
    if (this.createTerminalNominationForm.controls['cycleId']) {
      this.createTerminalNominationForm.removeControl('cycleId');
    }
  }

  updateFormFields(data: any) {
    this.createTerminalNominationForm.get('modeOfTransport')?.setValue('');
    if(data?.toLowerCase() !== this.createWithRefData?.scenario?.toLowerCase()) {
      this.createWithRefData.headerToItemNav = undefined;
    }
    this.changeDetection.detectChanges();
  }
  getNominationDescription(mot: any) {
    let nominationType = this.modalPopUpRefData.motHeaderItem.filter((data: any) =>{ return data.modeOfTransportDesc.toLowerCase() === mot})[0]?.nominationType;
    if(mot === 'in-tank'){
      this.originNominationTypeDropdown = nominationType.filter((nomType: any) => nomType.scheduleType === 'IT');
    }
    else{
      this.originNominationTypeDropdown = nominationType.filter((nomType: any) => nomType.scheduleType === 'O');
      this.destNominationTypeDropdown = nominationType.filter((nomType: any) => nomType.scheduleType === 'D');
    }

  }


  resetDateFormControl(){
    let origin =  this.transportArray().at(0).get('origin') as FormArray;
    let destination =  this.transportArray().at(0).get('destination') as FormArray;

    origin?.controls.forEach(eachItem => {
      eachItem.get('scheduledDate')?.setValue("")
    })

    destination?.controls.forEach(eachItem => {
      eachItem.get('scheduledDate')?.setValue("")
    })
    
  }


  motUpdateFormFields(mot: any) {
    if((this.modeOfTransport === 'truck' && ['rail','barge','in-tank','pipeline'].includes(mot)) ||  (mot === 'truck' && ['rail','barge','in-tank','pipeline'].includes(this.modeOfTransport))){
      this.resetDateFormControl();
    }
    this.modeOfTransport = mot;
    this.createTerminalNominationForm.get("udmTimeFrameOption")?.setValue("");
    this.updateTruckTimeFrame();
    this.createTerminalNominationForm.get('modeOfTransport')?.setValue(mot);
    this.motOptions.forEach((ele) => {
      ele.checked = ele?.name?.toLowerCase() === this.mot?.toLowerCase();
    });
    if(mot.toLowerCase() === 'barge'){
        this.getBargeValues(mot)
    }
    this.motOptions.forEach((ele: any) => {
      if (ele?.name.toLowerCase() === mot) { // need to check
        ele.checked = true;
      } else ele.checked = false;
    })
    this.transportSystemDropdown = this.modalPopUpRefData.motHeaderItem.filter((data: any) =>{ return data.modeOfTransportDesc.toLowerCase() === mot})[0]?.transportSystem;
    this.getNominationDescription(mot);
    this.motBasedFormFields(mot); 

   let transport =  this.createTerminalNominationForm.get('transportSystem');
   if(transport?.value?.toLowerCase() === 'ite' || transport?.value?.toLowerCase() === 'itw' ){
    transport.setValue("");
    this.onTransportSystemChange()
   }
   transport?.setValue("");
    this.disableAddLocation = mot.toLowerCase() === 'truck' ? true : false;
    if (mot.toLowerCase() === 'truck') {
      this.checkIfQtyCanDisabled();
    }
    else {
      this.isDisableQTY = false;
    }
    if(mot === 'in-tank'){
      let tSystem = this.desk.includes('uswc') ? 'itw' : 'ite'
      this.createTerminalNominationForm.get('transportSystem')?.setValue(tSystem.toLocaleUpperCase());
      this.onTransportSystemChange()
    }
  }

  onTransportSystemChange(){
    this.transPortDropDownValue = this.createTerminalNominationForm.get("transportSystem")?.value?.toLowerCase();
    if(this.transPortDropDownValue === 'ite' || this.transPortDropDownValue === 'itw'){
        this.formItIsUpdated = true;
        this.resetIteFormControl()
        this.locationOriginArray = [[]];
    }
    else if(this.formItIsUpdated){
        while (this.transportArray()?.controls?.length !== 0) {
            this.transportArray().removeAt(0);
        }
        this.transportArray().push(this.createFormGroupTransport(null));
        this.formItIsUpdated = false;
    }
  }

  resetIteFormControl() {
    this.hideDestination = true;
    while (this.transportArray()?.controls?.length !== 0) {
        this.transportArray().removeAt(0);
    }
    this.transportArray().push(this.createFormGroupTransport('ite'))
  }

  deletedNomList(transportIndex: number, rowIndex: number, type: string) {
    let obj: any = {}
    
    if (type === 'origin') {
      let originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
      let itemid = originArray.at(rowIndex).get('itemId');
        if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.udmItemStatus = this.editNomData.sys_status
        obj.deletionIndicator = "X"
       obj.textLine = `{Author:${this.userEmail},MdfBy:${this.userEmail},SS:UDM}`;
        this.deletelist.push(obj);
      }
    }
    else if (type === 'destination') {
      let destinArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
      let itemid = destinArray.at(rowIndex).get('itemId');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.udmItemStatus = this.editNomData.sys_status
        obj.deletionIndicator = "X";
        obj.textLine = `{Author:${this.userEmail},MdfBy:${this.userEmail},SS:UDM}`;
        this.deletelist.push(obj);
      }
    }
    this.deletedValue = true;
  }

  getBargeValues(mot:any){
    this.masterbargeValues = this.modalPopUpRefData.motHeaderItem.filter((data: any) =>{ return data.modeOfTransportDesc.toLowerCase() === mot})[0]?.bargeCarrierName;
    this.bargeSelectDropdown = this.masterbargeValues.length ? this.masterbargeValues.map((item:any) => item.name) : [];
     
  }

  motBasedFormFields(mot: any){

    if(this.modeOfTransport === 'truck'){
      if (!this.createTerminalNominationForm.controls['udmTimeFrameOption']) {
        this.createTerminalNominationForm.addControl('udmTimeFrameOption', new FormControl('', Validators.required));
      }
    } else{
      this.createTerminalNominationForm.removeControl('udmTimeFrameOption');
      this.createTerminalNominationForm.updateValueAndValidity();
    }

    
    switch(mot?.toLowerCase()) {
      case 'truck' : 
        this.setTruckValidations();
        break;
      case 'rail' :
        this.setRailValidations();
        break;
      case 'barge' :
        this.setBargeValidations();
        break;
      case 'tank transfer' :
        this.setTankTransferValidations();
        break;

    }
  }

  setTruckValidations(){
    this.removeBargeNameValidation();
    this.removeCycleIdValidation();
  }
  setRailValidations(){
    this.removeBargeNameValidation();
  }

  setBargeValidations(){
    this.setBargeNameValidation();
    this.removeCycleIdValidation();
  }

  setTankTransferValidations(){
    this.removeBargeNameValidation();
    this.setCycleIdValidation();
  }

 


  

  nominationTypeDesc(transportIndex:any, index:any) {
    let item;
    const originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
    const description = originArray.at(index)?.get('nominationType');
    const rdi = originArray.at(index)?.get('rdi');
    if(description?.value){
     item = this.originNominationTypeDropdown.filter((item:any) => { return item.name === description?.value})
    }
    
    description?.setValue(item ? item[0]?.name : '');
    rdi?.setValue(item ? item[0]?.referenceDocumentIndicator : '');


    return item?.length ? item[0] : "";


  }

  nominationTypeRdi(transportIndex:any, index:any) {
    let item;
    const originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
    const description = originArray.at(index)?.get('nominationType');
    const rdi = originArray.at(index)?.get('rdi');
    if(rdi?.value){
     item = this.originNominationTypeDropdown.filter((item:any) => { return item.referenceDocumentIndicator === rdi?.value});
    }
    description?.setValue(item ? item[0]?.name : '');
    rdi?.setValue(item ? item[0]?.referenceDocumentIndicator : '');


    return item.length ? item[0] : "";

  }


  nominationTypeDestDesc(transportIndex:any, index:any){
    let item;
    const destArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
    const description = destArray.at(index)?.get('nominationTypeDesti');
    const rdi = destArray.at(index)?.get('nominationTypeRdiDesti');
    if(description?.value){
     item = this.destNominationTypeDropdown.filter((item:any) => { return item.name === description?.value})
    }
    description?.setValue(item ? item[0]?.name : '');
    rdi?.setValue(item ? item[0]?.referenceDocumentIndicator : '');

    return item.length ? item[0] : "";


  }

  nominationTypeRdiDest(transportIndex:any, index:any){
    let item;
    const destArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
    const description = destArray.at(index)?.get('nominationTypeDesti');
    const rdi = destArray.at(index)?.get('nominationTypeRdiDesti');
    if(rdi?.value){
        item = this.destNominationTypeDropdown.filter((item:any) => { return item.referenceDocumentIndicator === rdi?.value});
       }
       description?.setValue(item ? item[0]?.name : '');
       rdi?.setValue(item ? item[0]?.referenceDocumentIndicator : '');


       return item.length ? item[0] : "";


  }

  setDetailsBasedOnMot(event: any) {
    this.createTerminalNominationForm.get('nominationType')?.setValue(null);
    this.createTerminalNominationForm.get('tswNominationType')?.setValue(null);
    const mot = this.createTerminalNominationForm.get('modeOfTransport')?.value;
    this.bargeMot = false;
    this.removeBargeNameValidation();
    const scheduleDate = this.storeScheduledDate?.split('/');
    let scheduledMonth: any;
    scheduledMonth = this.datePipe.transform(this.storeScheduledDate, "yyy-MM-dd");
    this.createTerminalNominationForm?.get('scheduledMonth')?.setValue(scheduledMonth);
    this.createTerminalNominationForm?.get('transportSystem')?.reset();
    if (mot?.toLowerCase() === Nom.TRUCK.value) {
      this.isRailOrTankSelected = false;
     
      if (scheduleDate?.length) {
        scheduledMonth = `${scheduleDate[2]}-${scheduleDate[0]}`
        this.createTerminalNominationForm.get('scheduledMonth')?.setValue(scheduledMonth);
      }
    } else if (mot?.toLowerCase() === Nom.RAIL.value) {
      this.isRailOrTankSelected = true;
    } else if (mot?.toLowerCase() === Nom.BARGE.value) {
      this.createTerminalNominationForm.get('transportSystem')?.reset();
      this.bargeMot = true;
      this.setBargeNameValidation();
      this.isRailOrTankSelected = false;
    } else {
      this.isRailOrTankSelected = true;
    }
    if (this.transportSystemDropdown?.length === 1) {
      this.createTerminalNominationForm.get('transportSystem')?.setValue(this.transportSystemDropdown[0].value);
    }
  }


  getIdForCorrespondingName(value: any, type: any) {
    if (type === 'location') {
      return this.destinationLocationKeyValue?.filter((ele: any) => ele.id === value).map((item: any) => item.id);
    } else if (type === 'supplier') {
      return this.supplierKeyValue?.filter((ele: any) => ele.name === value).map((item: any) => item.id);
    } else if (type === 'bargeName') {
      return this.bargeKeyValue?.filter((ele: any) => ele.name === value).map((item: any) => item.name);
    } else if (type === 'customer') {
      return this.customerKeyValue?.filter((ele: any) => ele.name === value).map((item: any) => item.id);
    } else return this.carrierKeyValue?.filter((ele: any) => ele.name === value).map((item: any) => item.id);
  }

  createNominationWithReference(data: any) {
    this.storeScheduledDate = data?.scheduledDate
    data.headerToItemNav.forEach((ele: any) => { 

      this.sumMovements(ele);
       
    }) 
    let scheduledMonth: any;
    let udmTime: String;
    if (data?.sys_status?.toLowerCase() !== Nom.DRAFT) {
      this.createNomWithRef = true;
    }
    this.editingNomination = data?.editNomination || data?.isEditNomination === 'editNomination' ? true :false;
    this.editNomData =  this.editingNomination ? data :"";
    if(this.modeOfTransport === 'truck'){
      udmTime = this.editNomData.udmTimeFrameOption ? this.editNomData.udmTimeFrameOption.toLowerCase() : "";
    this.editNomData.udmTimeFrameOption = udmTime; 
    }
   this.getInitialValues(data, scheduledMonth);
  }

  getInitialValues(data: any, scheduledMonth: any) {
    let count = 0;
    let originPos = 0;
    let destinPos = 0;
    let itePost = 0;
    let hasValue = false;
    this.initialValues().then(() => {

      if(['itw','ite'].includes(data?.transportSystem.toLowerCase())){
        data.modeOfTransportDesc = 'in-tank';
      }
      this.setQTYValues(data);
      this.motDescDraft = data?.modeOfTransportDesc.toLowerCase();
      this.disableAddLocation = data?.modeOfTransportDesc.toLowerCase() === 'truck';
      this.createTerminalNominationForm.get('modeOfTransport')?.setValue(this.motDescDraft);
      this.motOptions.forEach((mot: any) => {
        if (mot?.name?.toLowerCase() === this.motDescDraft?.toLowerCase()) { // need to check
          mot.checked = true;
        } else mot.checked = false;
      })

      this.modeOfTransport = data?.modeOfTransportDesc.toLowerCase();
      if(data.modeOfTransportDesc.toLowerCase() === 'barge'){
        this.getBargeValues(data.modeOfTransportDesc.toLowerCase());
    }
      this.getNominationDescription(data?.modeOfTransportDesc.toLowerCase());
      this.motBasedFormFields(data?.transportSystem?.toLowerCase());
      this.transportSystemDropdown = this.modalPopUpRefData.motHeaderItem.filter((item: any) =>{ return item.modeOfTransportDesc.toLowerCase() === data?.modeOfTransportDesc.toLowerCase()})[0]?.transportSystem;
      this.createTerminalNominationForm.get('transportSystem')?.setValue(data?.transportSystem);
      if( this.editingNomination ){
      this.createTerminalNominationForm.get('nominationNo')?.setValue(data?.nominationNo);
      this.createTerminalNominationForm.get('nominationNotes')?.setValue(data?.headerToItemNav[0]?.headerTextLine ?? data?.headerTextLine);
      }
      this.createTerminalNominationForm.get('btCustodyChain')?.setValue(data?.headerToItemNav[0]?.btCustodyChain);
      this.createTerminalNominationForm.get('supplier')?.setValue(data?.supplierName);
      this.createTerminalNominationForm.get('carrier')?.setValue(data?.carrierName);
      this.createTerminalNominationForm.get('customer')?.setValue(data?.customer ? this.getCustomerName(data?.customer) : data?.customerName);
      this.createTerminalNominationForm.get('bargeName')?.setValue(this.modeOfTransport === 'barge' ? data?.tdVehNo : null); 
      this.createTerminalNominationForm.get('udmTimeFrameOption')?.setValue(data?.udmTimeFrameOption ?? '');
      this.updateTruckTimeFrame();
      this.supplierReferenceData = data?.supplierName;
      this.customerReferenceData =data?.customer ? this.getCustomerName(data?.customer) : data?.customerName;
      this.carrierReferenceData = data?.carrierName;
      this.bargeNameReferenceData = this.modeOfTransport === 'barge' ? data?.tdVehNo : null;
      this.transPortDropDownValue = data?.transportSystem?.toLowerCase();
      this.modeOfTransport = data?.modeOfTransportDesc.toLowerCase();
      this.selectedTimeFrame = data?.udmTimeFrameOption;
      

      data.headerToItemNav.forEach((ele: any) => {
        this.checkAdditionalData(ele, hasValue)
      })
      
      data.headerToItemNav.forEach((ele: any, index:any) => {
        this.populateLineItems(ele, originPos, count, destinPos, itePost, index);
        if (['O','IT'].includes(ele.scheduleType)) {
          this.isOldTransport = true;
          this.isOldTransportITE = false
          originPos = originPos + 1
        }
        if (ele.scheduleType === 'D') {
          destinPos = destinPos + 1
          this.isOldTransport = true;
          this.isOldTransportITE = false
        }
        this.checkQTY(0,0)
        if(this.createTerminalNominationForm.get('supplier')?.value === null || this.createTerminalNominationForm.get('supplier')?.value === "" || this.createTerminalNominationForm.get('supplier')?.value === undefined){
          this.createTerminalNominationForm.get('supplier')?.setValue(ele?.supplierName);
          this.supplierReferenceData = ele?.supplierName;          
        }
        if(this.createTerminalNominationForm.get('customer')?.value === null || this.createTerminalNominationForm.get('customer')?.value === "" || this.createTerminalNominationForm.get('customer')?.value === undefined){          
          this.createTerminalNominationForm.get('customer')?.setValue(ele?.customer ? this.getCustomerName(ele?.customer) : ele?.customerName);
          this.customerReferenceData =ele?.customer ? this.getCustomerName(ele?.customer) : ele?.customerName;
        }

      });
      this.createNomWithRef = false;
      this.createWithRefData = data;
      if(this.createWithRefData.trMoreMenu){
        this.createWithRefData.headerToItemNav = null;
      }
      this.createTerminalNominationForm.updateValueAndValidity();
      this.changeDetection.detectChanges();
    });
  }

  checkAdditionalData(ele: any, hasValue: boolean) {
    if ((ele.batchDestinationLocation || ele.valuationTypeDestinationLoc || ele.valuationTypeOriginLoc || ele.batchOriginLocation) && !hasValue) {
      this.createTerminalNominationForm.get('selectMultipleCheckbox1')?.setValue(true)
      hasValue = true;
      this.isAdditionalData = true
    }
    return hasValue;
  }

  setQTYValues(data:any){
    if(this.isDisableQTY){
      let count = 0;
      let pos = 0;
      const originArray = this.transportArray()?.at(count)?.get('origin') as FormArray;
      const destinationArray = this.transportArray().at(count).get('destination') as FormArray;
      originArray.at(pos)?.get('scheduledQty')?.setValue(data.scheduledQty);
      destinationArray.at(pos)?.get('scheduledQty')?.setValue(data.scheduledQty)
    }
  }

  setOriginOrIteItems(ele:any, originPos:any, count:any){
    const originArray = this.transportArray()?.at(count)?.get('origin') as FormArray;
    
    if (originPos > 0) {
      this.isMultipleRowItemOrigin[count]?.push('O');
      originArray.push(this.createFormGroupOrigin(count))
    }
   const scheduleDate =  ele?.scheduledDate?.split('/');
   const scheduledMonth = `${scheduleDate[2]}-${scheduleDate[0].length === 1 ? '0' + scheduleDate[0] : scheduleDate[0] }`
    originArray.at(originPos)?.get('rdi')?.setValue(ele.referenceDocumentIndicator)
    originArray.at(originPos)?.get('nominationType')?.setValue(this.originNominationTypeDropdown.find((item: { referenceDocumentIndicator: any; }) => item.referenceDocumentIndicator === ele.referenceDocumentIndicator)?.name)
    originArray.at(originPos)?.get('originLocation')?.setValue(this.getCorrespondingNameFromid(ele.locationId,'location')[0]);
    originArray.at(originPos)?.get('demandMaterial')?.setValue(ele.demandMaterial ?? " ")
    originArray.at(originPos)?.get('scheduledMaterial')?.setValue(ele.scheduledMaterial ?? "")
    originArray.at(originPos)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument ?? "")
    originArray.at(originPos)?.get('scheduledQty')?.setValue(ele.scheduledQty)
    originArray.at(originPos)?.get('tswStatus')?.setValue((!this.editingNomination)? null: ele.nominationItemStatus) 
    
    originArray.at(originPos)?.get('scheduledDate')?.setValue( this.selectedTimeFrame?.toLowerCase() === 'truck-monthly'? scheduledMonth : this.datePipe.transform(ele?.scheduledDate, "yyy-MM-dd") );
    originArray.at(originPos)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem)
    originArray.at(originPos)?.get('batchOriginLocation')?.setValue(ele.batchOriginLocation)
    originArray.at(originPos)?.get('batchDestinationLocation')?.setValue(ele.batchDestinationLocation)
    originArray.at(originPos)?.get('valuationTypeDestinationLoc')?.setValue(ele.valuationTypeDestinationLoc)
    originArray.at(originPos)?.get('valuationTypeOriginLoc')?.setValue(ele.valuationTypeOriginLoc)
    originArray.at(originPos)?.get('itemId')?.setValue(ele.itemId)
    originArray.at(originPos)?.get('id')?.setValue(ele.id);
    originArray.at(originPos)?.get('indicator')?.setValue(count);
    ele.confirmationIndicator = (!this.editingNomination)? null:ele.confirmationIndicator;
    let confirmationIndicatorValue = ele.confirmationIndicator;
    confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
    originArray.at(originPos)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
    originArray.at(originPos)?.get('nominationItemStatus')?.setValue(ele.nominationItemStatus);
    if(this.editingNomination){
    if(ele?.nominationItemStatus === "5"){
      originArray.at(originPos)?.get('confirmationIndicator')?.disable();
     }
    }
    originArray.at(originPos)?.get('indicator')?.setValue(count)
    this.locationOriginArray[0][originPos] = this.getCorrespondingNameFromid(ele.locationId,'location')[0]; 
  }


  populateLineItems(ele:any, originPos:any, count:any, destinPos:any, itePost:any, index:any){
    if (['O','D','IT'].includes(ele.scheduleType)) {
      if ( ['O','IT'].includes(ele.scheduleType)) {
        this.setOriginOrIteItems(ele,originPos,count)
          
      }
      else {
          this.setDestItems(ele, count, destinPos);
      }

    }
  }

  setDestItems(ele:any, count:any, destinPos:any){
    const destinationArray = this.transportArray().at(count).get('destination') as FormArray;
        if (destinPos > 0) {
          destinationArray.push(this.createFormGroupDestination(count))
          this.isMultipleRowItemDestination[count]?.push('D');
        }

        const scheduleDate = ele?.scheduledDate?.split('/');
        const scheduledMonth = `${scheduleDate[2]}-${scheduleDate[0].length === 1 ? '0' + scheduleDate[0] : scheduleDate[0] }`

        this.setDestFormValues(destinationArray, ele, count, destinPos,scheduledMonth)
        if(this.editingNomination){
        if(ele?.nominationItemStatus === "5"){
          destinationArray.at(destinPos)?.get('confirmationIndicator')?.disable();
        }
      }
        this.locationDestiArray[0][destinPos] = this.getCorrespondingNameFromid(ele.locationId,'location')[0];
  }

  setDestFormValues(destinationArray:any, ele:any , count:any, destinPos:any, scheduledMonth: any){
    destinationArray.at(destinPos)?.get('nominationTypeRdiDesti')?.setValue(ele.referenceDocumentIndicator)
        destinationArray.at(destinPos)?.get('nominationTypeDesti')?.setValue(this.destNominationTypeDropdown.find((item: { referenceDocumentIndicator: any; }) => item.referenceDocumentIndicator === ele.referenceDocumentIndicator)?.name);
        destinationArray.at(destinPos)?.get('scheduledMaterial')?.setValue(ele.scheduledMaterial ?? "");
        destinationArray.at(destinPos)?.get('demandMaterial')?.setValue(ele.demandMaterial ?? "");

        destinationArray.at(destinPos)?.get('originLocationDesti')?.setValue(this.getCorrespondingNameFromid(ele.locationId,'location')[0]);
        destinationArray.at(destinPos)?.get('scheduledDate')?.setValue(this.selectedTimeFrame?.toLowerCase() === 'truck-monthly'? scheduledMonth : this.datePipe.transform(ele?.scheduledDate, "yyy-MM-dd") )
        destinationArray.at(destinPos)?.get('sapcontract')?.setValue(ele.nominationReferenceDocument);
        destinationArray.at(destinPos)?.get('tswStatus')?.setValue((!this.editingNomination)? null: ele.nominationItemStatus) 

        destinationArray.at(destinPos)?.get('scheduledQty')?.setValue(ele.scheduledQty)
     
        destinationArray.at(destinPos)?.get('batchDestinationLocation')?.setValue(ele.batchDestinationLocation)
        destinationArray.at(destinPos)?.get('valuationTypeDestinationLoc')?.setValue(ele.valuationTypeDestinationLoc)
        destinationArray.at(destinPos)?.get('valuationTypeOriginLoc')?.setValue(ele.valuationTypeOriginLoc)
        destinationArray.at(destinPos)?.get('batchOriginLocation')?.setValue(ele.batchOriginLocation)
        destinationArray.at(destinPos)?.get('itemId')?.setValue(ele.itemId)
        destinationArray.at(destinPos)?.get('contractLineNo')?.setValue(ele.nominationReferenceDocumentItem)
        destinationArray.at(destinPos)?.get('id')?.setValue(ele.id)
        destinationArray.at(destinPos)?.get('indicator')?.setValue(count);
        ele.confirmationIndicator = (!this.editingNomination)? null:ele.confirmationIndicator;
        let confirmationIndicatorValue = ele.confirmationIndicator;
        confirmationIndicatorValue = (confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === '')?false:true;
        destinationArray.at(destinPos)?.get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
        destinationArray.at(destinPos)?.get('nominationItemStatus')?.setValue(ele.nominationItemStatus);
  }
  onValueChange(event: any) {
    this.showCustomerWarning = event !== '' ? true : false;
    this.changeDetection.detectChanges();
  }

  setLocationFronReferenceData(data: any) {
    if(data?.trMoreMenu && data?.headerToItemNav?.length > 1) {
      this.setLocationFronReferenceDataInHeaderToItemNav(data)

    }else if (data?.headerToItemNav?.length > 1) {
      this.setLocationFronReferenceDataInHeaderToItemNav(data);
    } else {
        this.destinationReferenceData = this.getCorrespondingNameFromid(data?.locationId, 'location')[0];
    }
  }
  setLocationFronReferenceDataInHeaderToItemNav(data: any) {
    if(data?.trMoreMenu && data?.tswNominationType?.toLowerCase() === 'og'){
      this.destinationReferenceData = this.getCorrespondingNameFromid(data.locationId, 'location') ? this.getCorrespondingNameFromid(data.locationId, 'location')[0] : null;
    }
    else{
      this.setLocation(data);
    }
  }

  setLocation(data: any) {
    data?.headerToItemNav?.map((obj: any) => {
      if (obj.scheduleType === 'O') {
        this.originReferenceData = this.getCorrespondingNameFromid(obj.locationId, 'location') ? this.getCorrespondingNameFromid(obj.locationId, 'location')[0] : null;
      } else {
        this.destinationReferenceData = this.getCorrespondingNameFromid(obj.locationId, 'location') ? this.getCorrespondingNameFromid(obj.locationId, 'location')[0] : null;
      }
    })
  }

  getCorrespondingNameFromid(id: any, type: string) {
    if (type === 'location') {
      return this.destinationLocationKeyValue?.filter((ele: any) => ele.id === id).map((item: any) => item.id);
    } else if (type === 'supplier') {
      return this.supplierKeyValue?.filter((ele: any) => ele.id?.toLowerCase() === id?.toLowerCase()).map((item: any) => item.name);
    } else return this.carrierKeyValue?.filter((ele: any) => ele.id?.toLowerCase() === id?.toLowerCase()).map((item: any) => item.name);

  }



  submitForm(buttonAction?: any) {
    this.submitNomination(buttonAction)
  }

  submitNomination(buttonAction?: any) {
    this.lineItem = [];
    this.lineItems = [];
    this.creatingFormWithReference = false;
    this.inValidLocation = false;
    this.submitNomPayloadCreationForNew(buttonAction);
  }
  

  submitNomPayloadCreation() {
    
    const mot = this.createTerminalNominationForm.get('modeOfTransport')?.value;
    this.setMotDescDraft(mot);

    const status = this.trNominationForEthanol?.screenName === 'side-panel-tr' ? this.trNominationForEthanol?.sys_status?.toLowerCase() : 'unpublished';

    if (this.createWithRefData?.headerToItemNav) {
      this.createWithRefData?.headerToItemNav.forEach((val: any, index: any) => {
        if (this.createWithRefData?.headerToItemNav.length === 1) {
          this.setLineItem(mot, status, val);
        } else this.setLineItem(mot, status, val, index);
      });
    } else this.setLineItem(mot, status);

    this.callCreateNomination(mot, this.lineItems, status);
  }

  

  setLineItem(mot: any, status: any, val?: any, index?: any) {
    this.formateUdmBreakDown(val?.udmDailyBreakdownQty);
    const qty = index >= 0 ? val?.scheduledQty : this.createTerminalNominationForm.get('quantity')?.value;
    const qtyChangeCheck = val?.scheduledQty === this.createTerminalNominationForm.get('quantity')?.value; 
    this.lineItem = {
      itemId: null,
      udmItemStatus: status?.toLowerCase() === Nom.PUBLISHED?.toLowerCase() ? Nom.UNPUBLISHED?.toLowerCase() : status,
      scheduledDate: this.setScheduledDate(mot, val?.scheduledDate, index ?? 0),
      scheduledQty: +qty, 
      tswStatus: this.createTerminalNominationForm.get('tswStatus')?.value,
      scheduledUom: this.createTerminalNominationForm.get('unitOfMeasure')?.value,
      
      confirmationIndicator: val?.confirmationIndicator ? 'X' : "",
      scheduledMaterial: this.createTerminalNominationForm.get('scheduledMaterial')?.value,
      demandMaterial: this.createTerminalNominationForm.get('demandMaterial')?.value,
      nominationReferenceDocument: val?.nominationReferenceDocument ? val?.nominationReferenceDocument : undefined,
      nominationReferenceDocumentItem: val?.nominationReferenceDocumentItem ? val?.nominationReferenceDocumentItem : undefined,
      udmComments: val?.udmComments ?? undefined,
      partnerNominationNumber: val?.partnerNominationNumber ?? undefined,
      scheduleType: val?.scheduleType ?? undefined,
      referenceDocumentIndicator: val?.referenceDocumentIndicator ?? undefined,
      locationId: val?.locationId ?? undefined,
      udmDailyBreakdownQty: val?.udmDailyBreakdownQty?.length && qtyChangeCheck && mot?.toLowerCase() ===  Nom.TRUCK.value?.toLowerCase() ? val?.udmDailyBreakdownQty : undefined,
      valuationTypeDestinationLoc: this.trNominationForEthanol?.valuationTypeDestinationLoc ?? undefined
    }
    this.lineItems.push(this.lineItem);
  }

  formateUdmBreakDown(udmDailyBreakdownQty: any) {
    return udmDailyBreakdownQty?.forEach((value: any, i: any) => {
      udmDailyBreakdownQty[i]['bbl190Truck'] = value.bbl190Truck;
      udmDailyBreakdownQty[i]['bbl250Truck'] = value.bbl250Truck;
      const dateFromLineItem: any = value.scheduledDate?.split('/');
      const monthfromUI = this.createTerminalNominationForm.get('scheduledMonth')?.value.split('-').join("") // yyyy-mm-dd
      let formateScheduledDate;
      if (dateFromLineItem.length > 1) {
        formateScheduledDate = monthfromUI + dateFromLineItem[1]
      } else {
        formateScheduledDate = monthfromUI + value.scheduledDate.slice(-2)
      }
      udmDailyBreakdownQty[i]['scheduledDate'] = formateScheduledDate;
      udmDailyBreakdownQty[i]['scheduledQty'] = +value.scheduledQty;

    })
  }

  setScheduledDate(mot: any, scheduledDate?: any, i?: any) {
    const dateFromLineItem = scheduledDate?.split('/')[1];
    const monthandYearfromUI = this.createTerminalNominationForm.get('scheduledMonth')?.value?.split('-'); // yyyy-mm-dd
    const formateScheduledDate = monthandYearfromUI[0] + monthandYearfromUI[1] + dateFromLineItem
    const scheduledDateOtherThanTruck = i === 0 ? this.createTerminalNominationForm.get('scheduledMonth')?.value?.split('-').join('') : formateScheduledDate;
    return mot?.toLowerCase() === Nom.TRUCK.value?.toLowerCase() ? this.createTerminalNominationForm.get('scheduledMonth')?.value.split('-').join('') + '01' : scheduledDateOtherThanTruck;
  }
 

  setMotDescDraft(mot: any) {
    if (mot?.toLowerCase() === Nom.TRUCK.name?.toLowerCase()) {
      this.motDesc = Nom.TRUCK.name;
    } else if (mot?.toLowerCase() === Nom.RAIL.name?.toLowerCase()) {
      this.motDesc = Nom.RAIL.name;
    } else if (mot?.toLowerCase() === Nom.BARGE.name?.toLowerCase()) {
      this.motDesc = Nom.BARGE.name;
    } else if (mot?.toLowerCase() === Nom.TANKTRANSFER.name?.toLowerCase()) {
      this.motDesc = Nom.TANKTRANSFER.name;
    }
    else if (mot?.toLowerCase() === Nom.PIPELINEETHANOL.name?.toLowerCase()) {
      this.motDesc = Nom.PIPELINEETHANOL.name;
    }
    return this.motDesc;
  }

  //new function for enhancement
  getMotValue(){
    if(this.modeOfTransport.toLowerCase() === Nom.TRUCK.name.toLowerCase()){
      return Nom.TRUCK.value;
    }
    if(this.modeOfTransport.toLowerCase() === Nom.RAIL.name.toLowerCase()){
      return Nom.RAIL.value;
    }
    if(this.modeOfTransport.toLowerCase() === Nom.BARGE.name.toLowerCase()){
      return Nom.BARGE.value;
    }
    if(this.modeOfTransport.toLowerCase() === Nom.TANKTRANSFER.name.toLowerCase()){
      return Nom.TANKTRANSFER.value;
    }
    if(this.modeOfTransport.toLowerCase() === Nom.PIPELINEETHANOL.name.toLowerCase()){
      return Nom.PIPELINEETHANOL.value;
    }

    return "";
    
  }
  

  createPayloadForSubmission(buttonAction:any){
    let carrier:any;    
    carrier = this.getIdForCorrespondingName(this.createTerminalNominationForm.get('carrier')?.value,'carrier')[0]
    const payload:any = {
      id:null,
      carrier : this.modeOfTransport !== 'truck' ? carrier : null,
      carrierName:this.modeOfTransport !== 'truck' ? this.createTerminalNominationForm.get('carrier')?.value : null,
      modeOfTransport:this.getMotValue(),
      tdVehNo: this.getTdVehNo(),
      modeOfTransportDesc:this.setMotDescDraft(this.modeOfTransport) === 'in-tank' ? 'Pipeline' : this.setMotDescDraft(this.modeOfTransport),
      transportSystem: this.createTerminalNominationForm.get('transportSystem')?.value?.toUpperCase(),
      nominationNo: this.createTerminalNominationForm.get('nominationNo')?.value.toUpperCase(),
      textLine:this.createTerminalNominationForm.get('nominationNotes')?.value,
      btCustodyChain: this.createTerminalNominationForm.get('btCustodyChain')?.value,
      deskFilter: `${this.desk?.split("+").join("").replaceAll(" ",'')}`,
      tdVehIdentifier: this.getMotValue().toLowerCase() === Nom.BARGE.value?.toLowerCase() ? 'B' : null,
      udmModeOfTransportDesc: this.setMotDescDraft(this.modeOfTransport) === 'in-tank' ? 'Pipeline' : this.setMotDescDraft(this.modeOfTransport),
      sys_status: buttonAction === 'publish' ? 'inprocess':  Nom.UNPUBLISHED,
      headerToItemNav:[],
      udmTimeFrameOption:null
    };

    if(payload.modeOfTransportDesc.toLowerCase() === 'truck'){
      payload.udmTimeFrameOption = this.createTerminalNominationForm.get("udmTimeFrameOption")?.value;
    }

    return payload;

  }

  getTdVehNo() {
    if (this.modeOfTransport === "barge") {
      return this.createTerminalNominationForm.get('bargeName')?.value?.toUpperCase();
    } else if (this.modeOfTransport === "pipeline") {
      return this.desk.toLowerCase() === UDMDesk.EthanolUswc ? "ITW" : null
    } else {
      return this.createTerminalNominationForm.get('transportSystem')?.value.toUpperCase();
    }
  }

  submitNomPayloadCreationForNew(buttonAction?: any){
    let supplier: any;
    let carrier:any;
    let nominationData = JSON.parse(JSON.stringify(this.createTerminalNominationForm.value));
    let payload = this.createPayloadForSubmission(buttonAction);
    supplier = this.getIdForCorrespondingName(nominationData.supplier,'supplier')[0];
    carrier = this.getIdForCorrespondingName(this.createTerminalNominationForm.get('carrier')?.value,'carrier')[0];
    this.buttonActionForNom = buttonAction;
    if( this.trNominationForEthanol?.rowItemStatus?.toLowerCase() === Nom.DRAFT){
      payload.sys_status = Nom.DRAFT;
    }
    
    let list: any = {};

   nominationData.transport.forEach((el: any) => {
    el.origin.forEach((item: any, i:any) => {
      if (item.rdi) { 
        this.allOriginLineItemsDataStatus = true;
        list.referenceDocumentIndicator = item.rdi;
        list.scheduleType = ['ITE',"ITW"].includes(payload.transportSystem) ? 'IT' :'O';
        this.setList(list, item, payload?.transportSystem,carrier,supplier,i,payload.modeOfTransportDesc)
        let cloneObject:any = { ...list };
        payload.headerToItemNav.push(cloneObject);        
      }
      else{
        this.allOriginLineItemsDataStatus = false;
      }
    })

    el.destination?.forEach((item: any,i:any) => {
      if (item.nominationTypeRdiDesti) { 
        this.allDestLineItemsDataStatus = true;
        list.referenceDocumentIndicator = item.nominationTypeRdiDesti;
        list.scheduleType = 'D';
        this.setList(list, item,payload.transportSystem,carrier,supplier, i, payload.modeOfTransportDesc)
        let cloneObject = { ...list };
        payload.headerToItemNav.push(cloneObject);
      }
      else{
        this.allDestLineItemsDataStatus = false;
      }
    })
  })
  this.makeAPICall(payload,nominationData,buttonAction)
  }

  makeAPICall(payload:any,nominationData:any,buttonAction:any){
    if(this.showCustomerRequired && !nominationData.customer){
      this.toasterService.setToastNotification({
        show: true,
        type: 'warning',
        msg: `Customer information is required.`,
      });
      return;
    }

    if(!(this.allOriginLineItemsDataStatus || this.allDestLineItemsDataStatus)){
      this.toasterService.setToastNotification({
      show: true,
      type: 'warning',
      msg: `Either the origin or the destination is required`,
    });
    return;
  }
  if(this.editingNomination){
    payload.headerToItemNav.push(...this.deletelist)
    payload.id = this.editNomData?.id;   
    this.nominationUpdateAPiCall(payload);    
    return 
  }  
    if((this.trNominationForEthanol?.screenName?.toLowerCase() === 'ethanoltr' && this.trNominationForEthanol?.rowItemStatus?.toLowerCase() === Nom.DRAFT) || (this.trNominationForEthanol?.screenName?.toLowerCase() === 'side-panel-tr' && this.trNominationForEthanol?.sys_status?.toLowerCase() === Nom.DRAFT)){
      payload.IsUpdateFromSidePanel = this.trNominationForEthanol?.screenName?.toLowerCase() !== 'ethanoltr';
      payload.id = this.trNominationForEthanol?.id;
      this.nominationUpdateAPiCall(payload);
    }
    else{
      this.createNomination(payload);
    }
  }

  nominationUpdateAPiCall(payload:any){
    this.utilities.getCreateUpdateMasterDataPayload([payload]);
    this.nominationService.updateNomination([payload]).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: `nomination has been updated`,
          });
        }
        this.loaderService?.setLoading(true);
        setTimeout(() => {
          this.loaderService.setLoading(false);
          this.resetModal();
          this.closeModalPopup.emit(response);
          this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: true }));
        }, 8000)

      },
      error: (err) => {
        this.inValidLocation = true;
        this.inValidLocationMsg = err?.result;
      }
    })
  }

  getProductName(code:any){
    let productCode = this.productsDropdown.filter(product => product.value === code)[0];
    return productCode?.name
  }

  getCustomerCode(name:any){
    let customerCode = this.masterCustomerData.list.filter((customer: any) => customer.name === name )[0];
    return customerCode?.id;
  }

  getCustomerName(id:any){
    if(id){
      let customerCode = this.masterCustomerData.list.filter((customer: any) => customer.id === id )[0];
      return customerCode?.name;  
    }
    return "";
   
  }

  updateTruckTimeFrame(){

    let prevValue = this.truckTimeFrameOption;
    this.truckTimeFrameOption = this.createTerminalNominationForm.get("udmTimeFrameOption")?.value;

    if( (['Truck-Weekly','Truck-Daily'].includes(prevValue) &&  this.truckTimeFrameOption === 'Truck-Monthly') || ['Truck-Weekly','Truck-Daily'].includes(this.truckTimeFrameOption) &&  prevValue === 'Truck-Monthly'){
      this.resetDateFormControl();
    }

    if(this.modeOfTransport.toLowerCase() === 'truck' && this.truckTimeFrameOption?.toLowerCase() === 'truck-monthly'){
      this.showTruckMonthly = true;
    }
    else {
      this.showTruckMonthly = false;

    }
  }
  getFormmatedDateForTruck(scheduledDate:any){
    if(  ['truck-weekly','truck-daily'].includes(this.truckTimeFrameOption.toLowerCase())){
       return scheduledDate.replaceAll('-', '')
    } else {
      return scheduledDate.replaceAll('-', '') + "01";
    }
  }

  

  getDateFormattedForList(trasnportSys:any, scheduledDate:any){
    let dateFormat;
    if(trasnportSys.toLowerCase() === 'truck-us'){
      dateFormat = this.getFormmatedDateForTruck(scheduledDate); 
    }
    else{
      dateFormat = scheduledDate.replaceAll('-', '');
    }
    return dateFormat;
  }

  getListUdmStatus(buttonAction:any){
    let udmItemStatus;
    if(this.trNominationForEthanol?.sys_status?.toLowerCase() === Nom.DRAFT){
     udmItemStatus = Nom.DRAFT;
    }
    else{
     udmItemStatus = buttonAction === "publish" && this.modeOfTransport !== 'truck' ? "inprocess" : "unpublished";
    }

    return udmItemStatus;
  }

  getBreakDownQuantity(mot:any,scheduledDate:any,scheduledQty:any){
    let udmDailyBreakdownQty;
    if (mot.toLowerCase() === 'truck' && this.truckTimeFrameOption.toLowerCase() === 'truck-monthly') {
     udmDailyBreakdownQty = [{
        scheduledDate: scheduledDate?.replaceAll("-",'') + this.draftDate,
        scheduledQty: scheduledQty
      }]
    }
      else {
        udmDailyBreakdownQty = [{
          scheduledDate: scheduledDate?.replaceAll("-",''),
          scheduledQty: scheduledQty
        }]
      }
    return udmDailyBreakdownQty;

  }

  checkForDeleteList(list:any,item:any){
   return !(this.deletelist.filter((item:any) => item.itemId ===  this.draftItemId))
  }

  getItemId(i:any,list:any,item:any){
    return ((this.trNominationForEthanol?.screenName?.toLowerCase() === 'ethanoltr' && this.trNominationForEthanol?.rowItemStatus?.toLowerCase() === Nom.DRAFT) || (this.trNominationForEthanol?.screenName?.toLowerCase() === 'side-panel-tr' && this.trNominationForEthanol?.sys_status?.toLowerCase() === Nom.DRAFT) || (this.editingNomination && this.checkForDeleteList(list,item))) && (i === 0 && list.scheduleType === this.draftScheduleType ) ? this.draftItemId : null;
  }

  setUdmDailyBreakdownQty(list: any, i: any, mot: any,  item: any){
    if((list.scheduleType === this.draftScheduleType || (this.trNominationForEthanol?.screenName?.toLowerCase() === 'ethanoltr' && this.createNomWithRef)) && i === 0){
      list.udmDailyBreakdownQty =  this.getBreakDownQuantity(mot,item.scheduledDate,item.scheduledQty);
    }
  }

  setShipperText(list: any, trasnportSys:any,carrier:any, supplier:any){
    let supplierRef = list.referenceDocumentIndicator === 'G' ? this.getCustomerCode(this.createTerminalNominationForm.get('customer')?.value) : supplier;
    list.shipperText  = trasnportSys.toLowerCase() === 'truck-us' ? `{Type:null,Carrier:${this.utilities.padSupplierCarrier(carrier)??null},Supplier:${this.utilities.padSupplierCarrier(supplierRef)??null}}` : null;
  }
 
  setList(list: any, item: any, trasnportSys:any,carrier:any, supplier:any, i:any, mot:any){

    let useremail = this.editingNomination || this.trNominationForEthanol?.sys_status === 'draft'? this.userEmail : null;
    this.setUdmDailyBreakdownQty(list, i, mot, item);
    list.itemId = item.itemId.length && (this.editingNomination || this.trNominationForEthanol?.sys_status === 'draft') ? item.itemId : this.getItemId(i,list,item);
    list.udmItemStatus = this.getListUdmStatus(this.buttonActionForNom);
    list.scheduledDate =  this.getDateFormattedForList(trasnportSys,item.scheduledDate);
    list.scheduledQty = Number(item.scheduledQty);
    list.locationId = this.getIdForCorrespondingName(item.originLocation ? item.originLocation : item.originLocationDesti, 'location')[0];
    list.locationName = this.getCorrespondingNameFromid( list.locationId  ? list.locationId  : list.originLocationDesti,'location')[0];
    list.scheduledUom = 'BR' 
    list.scheduledMaterial = item.scheduledMaterial;
    list.demandMaterial = item.demandMaterial;
    list.textLine = `{Author:${this.userEmail},MdfBy:${useremail},SS:UDM}`;
    list.scheduledMaterialDesc =  this.getProductName(item.scheduledMaterial);
    list.demandMaterialDesc =  this.getProductName(item.demandMaterial);
    list.udmDisableValidations = true;
    list.confirmationIndicator = this.utilities.getConfirmationIndicatorValue(item,this.editingNomination);
    
    list.nominationReferenceDocument = item?.sapcontract  || null // set it as per multiple or single selected
    list.nominationReferenceDocumentItem = item?.contractLineNo  || null;
    list.batchOriginLocation = item?.batchOriginLocation  || null;
    list.valuationTypeOriginLoc = item?.valuationTypeOriginLoc  || null;
    list.valuationTypeDestinationLoc = item?.valuationTypeDestinationLoc || null;
    list.batchDestinationLocation = item?.batchDestinationLocation || null;
    list.contractPartner = this.getContractPartnerForList(trasnportSys,list.referenceDocumentIndicator, supplier) 
    this.setShipperText(list, trasnportSys,carrier,supplier)
    return list;
  }

  getContractPartnerForList(trasnportSys:any,referenceDocumentIndicator:any,supplier:any){
    let contractPartner;
    if(trasnportSys.toLowerCase() !== 'truck-us' && referenceDocumentIndicator === 'G'){
      contractPartner  = this.getCustomerCode(this.createTerminalNominationForm.get('customer')?.value);
    }
    else if(trasnportSys.toLowerCase() !== 'truck-us'){
       contractPartner = supplier;
    }
    else {
      contractPartner = null;
    }
    return contractPartner;
  
  }
  callCreateNomination(mot: any, lineItems: any, status: any) {
    let carrierValue;
    const payload = [{
      id: null,
      modeOfTransport: this.createTerminalNominationForm.get('modeOfTransport')?.value,
      modeOfTransportDesc: this.motDesc,
      nominationNo: this.createTerminalNominationForm.get('nominationNo')?.value,
      textLine:this.createTerminalNominationForm.get('nominationNotes')?.value,
      btCustodyChain:this.createTerminalNominationForm.get('btCustodyChain')?.value,
      transportSystem: this.createTerminalNominationForm.get('transportSystem')?.value.toUpperCase(),
      IsEthanolNominationType: 'true',
      tdVehNo: mot?.toLowerCase() === Nom.BARGE.value?.toLowerCase() ? this.getVehNomForBarge() : this.createTerminalNominationForm.get('transportSystem')?.value.toUpperCase(), // same as transportSystem
      sys_status: status?.toLowerCase() === Nom.PUBLISHED?.toLowerCase() ? Nom.UNPUBLISHED?.toLowerCase() : status,
      headerToItemNav: lineItems,
      tdVehIdentifier: mot?.toLowerCase() === Nom.BARGE.value?.toLowerCase() ? 'B' : undefined
    }];

    this.createNomination(payload);
  }

  getVehNomForBarge() {
    return this.getIdForCorrespondingName(this.createTerminalNominationForm.get('bargeName')?.value, 'bargeName')[0];
  }

  submitDraftNominationFromEthanol() {
    const destLocation = this.getIdForCorrespondingName(this.createTerminalNominationForm.get('destinationLocation')?.value, 'location')?.length > 0 ? this.getIdForCorrespondingName(this.createTerminalNominationForm.get('destinationLocation')?.value, 'location') : [this.trNominationForEthanol.locationId];
    const originLocation = this.getIdForCorrespondingName(this.createTerminalNominationForm.get('originLocation')?.value, 'location');
    const carrier = this.getIdForCorrespondingName(this.createTerminalNominationForm.get('carrier')?.value, 'carrier')?.length > 0 ? this.getIdForCorrespondingName(this.createTerminalNominationForm.get('carrier')?.value, 'carrier') : [this.trNominationForEthanol.carrierID];
    const supplier = this.getIdForCorrespondingName(this.createTerminalNominationForm.get('supplier')?.value, 'supplier')?.length > 0 ? this.getIdForCorrespondingName(this.createTerminalNominationForm.get('supplier')?.value, 'supplier') : [this.trNominationForEthanol.supplierId];
    const mot = this.createTerminalNominationForm.get('modeOfTransport')?.value;
    this.setMotDescDraftValue(mot);
    this.setScheduleDate(mot);
    let updateCarrierValue = this.updateCarrierValue(mot);
    this.updateAPI(mot, updateCarrierValue, destLocation, carrier, supplier, originLocation);
  
  }

  updateCarrierValue(mot:any){
    let carrierValue;
    if ((this.desk.toLowerCase() === UDMDesk.EthanolUswc || this.desk.toLowerCase() === UDMDesk.EthanolUsec) && mot?.toLowerCase() === Nom.BARGE.value?.toLowerCase()) { //
      carrierValue = this.createTerminalNominationForm.get('carrier')?.value ? this.getIdForCorrespondingName(this.createTerminalNominationForm.get('carrier')?.value, 'carrier')[0] : '';
    } else if (mot?.toLowerCase() === Nom.TANKTRANSFER.value?.toLowerCase()) {
      carrierValue = this.desk.toLowerCase() === UDMDesk.EthanolUswc ? '0000040001' : null;
    } else carrierValue = undefined

    return carrierValue
  }

  updateAPI(mot: any, carrierValue: any, destLocation: any, carrier: any, supplier: any, originLocation: any) {
    return mot;
  }

  setScheduleDate(mot: any) {
    if (mot?.toLowerCase() === Nom.TRUCK.value?.toLowerCase()) {
      this.scheduleDate = this.createTerminalNominationForm.get('scheduledMonth')?.value.split('-').join('') + '01';
    } else {
      this.scheduleDate = this.createTerminalNominationForm.get('scheduledMonth')?.value?.split('-').join('')
    }
  }

  setMotDescDraftValue(mot: any) {
    if (mot?.toLowerCase() === Nom.TRUCK.value?.toLowerCase()) {
      this.motDescDraft = Nom.TRUCK.name;
    } else if (mot?.toLowerCase() === Nom.RAIL.value?.toLowerCase()) {
      this.motDescDraft = Nom.RAIL.name;
    } else if (mot?.toLowerCase() === Nom.BARGE.value?.toLowerCase()) {
      this.motDescDraft = Nom.BARGE.name;
    } else if (mot?.toLowerCase() === Nom.TANKTRANSFER.value?.toLowerCase()) {
      this.motDescDraft = Nom.TANKTRANSFER.name;
    }
  }

  capitalize(value: any) {
    return value && value[0].toUpperCase() + value.slice(1);
  }

  createNomination(payload: any,buttonAction?:any,modeOfTransport?:any) {
    this.loaderService?.setLoading(false);
    this.utilities.getCreateUpdateMasterDataPayload([payload]);
    this.nominationService.createTerminalNomination([payload]).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: `nomination has been created`,
          });
        }
        setTimeout(() => {
          this.resetModal();
          this.closeModalPopup.emit(response);
        }, 5000);
        if (this.trNominationForEthanol?.screenName?.toLowerCase() === 'ethanoltr' || this.trNominationForEthanol?.screenName?.toLowerCase() === 'side-panel-tr') {
          this.loaderService.setLoading(true);
          setTimeout(() => {
            this.loaderService.setLoading(false);
            this.appStore.dispatch(saveEthanolTRFiltersResponse({ ethanolFilterValues: JSON.parse(JSON.stringify(this.getEhtanolFilterResponse.ethanolFilterValues)), ethanolFromDate: this.getEhtanolFilterResponse.ethanolFromDate, ethanolToDate: this.getEhtanolFilterResponse.ehtanolToDate, isfilterUpdated: true }));
          }, 5000);
        }
      },
      error: (err) => {
        this.inValidLocation = true;
        this.inValidLocationMsg = err?.result;
      }
    })
  }

  closePopup() {
    this.createTerminalNominationForm.reset({
        nominationNo:'',
        transportSystem: '',
        nominationNotes:'',
        btCustodyChain:'',
        customer:'',
        supplier:'',
        carrier: '',
        bargeName: '',
        udmTimeFrameOption:'',
        selectMultipleCheckbox1:false,
        isoriginDestiTouched:'',
    });
    while (this.transportArray()?.controls?.length !== 0) {
    this.transportArray().removeAt(0);
    }
    this.disableAddLocation = true;
    this.showCustomerRequired = false;
    this.transPortDropDownValue = '';
    this.productsDropdown = [];
    this.isAdditionalData = false;
    this.showCustomerWarning = false;
    this.deletelist = [];

    this.createTerminalNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true });
    this.transportArray().push(this.createFormGroupTransport(null));
    this.productsDropdown = [];
    this.originQtyValue = [0];
    this.destinQtyValue = [0];
    this.locationOriginArray = [[]];
    this.locationDestiArray = [[]];
    this.supplierReferenceData = null;
    this.carrierReferenceData = null;
    this.customerReferenceData = null;
    this.originReferenceData = null;
    this.bargeNameReferenceData = null;
    this.selectDropdown?.resetInput();
    this.supplierSelectDropdown?.resetInput();
    this.carrierSelectDropdown?.resetInput();
    this.changeDetection.detectChanges();
    this.bargeNameDropdown?.resetInput();

    this.ethanolService.modalPopoOpen$.next(false);
    this.nominationReferenceDocument = null;
    this.nominationReferenceDocumentItem = null;
    this.inValidLocation = false;
    this.inValidLocationMsg = '';
    this.lineItems = [];
    this.originMovementsValue = [0]
    this.destinMovementsValue = [0] 
    this.storeScheduledDate = null;
    this.customerReferenceData = null;
    this.customerSelectDropdown?.resetInput();
    this.closeModalPopup.emit(false);
    this.editingNomination=false;

  }

  onKeyPress(event: KeyboardEvent) {
    const keyCode = event.key;
    if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(keyCode))) {
      event.preventDefault();
    }
  }

  onContractLineChange(event: any, index: number, formGroupIndex:number, formArrayName: string) {
    const replaceLeadingZero = event.target.value.replace(/^0+/, "");
    if (formArrayName == 'origin') {
      this.origin(formGroupIndex).at(index)?.get('contractLineNo')?.setValue(replaceLeadingZero);
    }
    else if (formArrayName == 'destination') {
      this.destination(formGroupIndex).at(index)?.get('contractLineNo')?.setValue(replaceLeadingZero);
    }
    else if (formArrayName == 'ite') {
     this.ite(formGroupIndex).at(index)?.get('contractLineNo')?.setValue(replaceLeadingZero);
    }
  }
  copyOriginRow(formIndex:number, rowIndex:number){
    this.addOriginRow(formIndex)
    const copyRowData = this.origin(formIndex).at(rowIndex)
    const originList = this.origin(formIndex);
    const lastRowNo = originList.length - 1;
    const pasteNewOriginRowData = this.origin(formIndex).at(lastRowNo);
    pasteNewOriginRowData.patchValue(copyRowData.value);
    pasteNewOriginRowData.get('itemId')?.setValue('');
    this.locationOriginArray[formIndex][lastRowNo] = copyRowData.value.originLocation;
    setTimeout(() => {
      this.checkQTY(0, 0)
    }, 1);
  }
  clearOriginRow(formIndex:number, rowIndex:number){
    const originList = this.origin(formIndex);
    if(originList){
      const formGroup =  originList.at(rowIndex)
      const itemId = formGroup.value.itemId;
      formGroup.get('itemId')?.setValue(itemId);
      const dropdownvalue = 'select';
      this.locationOriginArray[formIndex][rowIndex] = dropdownvalue;
      this.isOriginRequired[formIndex] = false;
      this.origin(formIndex).value.forEach((element: any) => {
        Object.keys(element).forEach((e: any) => {
          if(e !== "indicator"){
          formGroup.get(e)?.setValue('')
          }
        })
      });
    }
  }
  copyDestinationRow(formIndex:number, rowIndex:number){
    this.addDestinationRow(formIndex)
    const copyRowData = this.destination(formIndex).at(rowIndex);
    const destinationList = this.destination(formIndex);
    const lastRowNo = destinationList.length - 1;
    const pasteNewdestinationRowData = this.destination(formIndex).at(lastRowNo);
    pasteNewdestinationRowData.patchValue(copyRowData.value);
    pasteNewdestinationRowData.get('itemId')?.setValue('');
    this.locationDestiArray[formIndex][lastRowNo] = copyRowData.value.originLocationDesti;
    setTimeout(() => {
      this.checkQTY(0, 0)
    }, 1);
  }
  clearDestinationRow(formIndex:number, rowIndex:number){
    const destinationList = this.destination(formIndex);
    if(destinationList){
      const formGroup =  destinationList.at(rowIndex)
      const itemId = formGroup.value.itemId;
      formGroup.get('itemId')?.setValue(itemId);
      const dropdownvalue = 'select';
      this.locationDestiArray[formIndex][rowIndex] = dropdownvalue;
      this.isDestinationRequired[formIndex] = false;
      this.destination(formIndex).value.forEach((element: any) => {
        Object.keys(element).forEach((e: any) => {
          if(e !== "indicator"){
          formGroup.get(e)?.setValue('')
          }
        })
      });
    }
  }
}
