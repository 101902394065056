import { Component, Inject, inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'app-custom-dialog-box',
  templateUrl: './custom-dialog-box.component.html',
  styleUrls: ['./custom-dialog-box.component.scss']
})
export class CustomDialogBoxComponent implements OnInit {

  constructor(
    public dialogRef:MatDialogRef<CustomDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{title:string; message:string}
  ) { }

  ngOnInit(): void {
  }

  onNoClick():void{
    this.dialogRef.close(false);
  }
  
  onYesClick():void{
    this.dialogRef.close(true);
  }
}
