<div class="save-alerts">
  <div class="label-1">It looks like you altered data without saving.</div>
  <div>&nbsp;</div>

  <div class="listView">
    <ul class="list-group">
      @for (change of changeList; track change) {
        <li class="list-group-item">{{change.split(';')[0]}}</li>
      }
    </ul>
  </div>
  <div>&nbsp;</div>

  @if (!choseHref) {
    <div class="label-2">You can go back to the grid view, discard changes and leave, or save changes and leave</div>
  }
  @if (choseHref) {
    <div class="label-2">You can go back to the grid view and save your changes or discard changes and leave</div>
  }

  <div>&nbsp;</div>
  <div class="d-flex justify-content-around">
    <div class="form-group text-center">
      <button class="cancel-btn" (click)="closePopup()">Cancel</button>
    </div>
    <div class="form-group text-center ">
      <button class="discard-btn" (click)="discardChanges()">Discard Changes</button>
    </div>
  </div>
</div>