@if (addButtonSelectable) {
  <div class="header active" (click)="toggleAddButton()">
    + <span class="underline">add comment</span>
  </div>
}
@if (!addButtonSelectable) {
  <div class="header disabled">
    + <span class="underline">add comment</span>
  </div>
}

@if (!addButtonSelectable) {
  <div class="mb-4">
    <comment-card [isInputActive]="true" [createNewComment]="true" [commentPublished]="false"
      [screenInformation]="screenInformation" (commentCreated)="resetAdd($event)">
    </comment-card>
  </div>
}