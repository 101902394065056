@if (showLabel) {
  <label for="terminal" class="label">{{dropDownInfo.dropdownDisplayName}}</label>
}
<select class="form-control" [disabled]="disabled" (change)="changeSelection($event)" [(ngModel)]="selectedValue"
    [matTooltip]="showTooltipValue() " [matTooltipPosition]="'above'" [matTooltipClass]="'alertConfigurationToolTip'">
<option value="" disabled selected>select {{dropDownInfo.dropdownDisplayName}}</option>
  @for (option of dropDownInfo.option; track option) {
    <option [value]="option.actualValue">
    {{ option.displayValue }}
</option>
  }
</select>