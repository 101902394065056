<header>
  <div class="udm-header">
    <div class="udm-header__desk" [class.disabled]="isDealPopOutPage" [style.--desksVisible]="desksVisible">
      <span class="title">desk</span>
      <custom-dropdown [optionList]="list" (change)="getDeskName($event)"></custom-dropdown>
    </div>
    <div class="udm-header__logo">
      <a id="hallmark" href="/" title = "Main Navigation Button"></a>
      <div class="app-name"><a href="/">{{siteName}}</a></div>
    </div>
    <div class="udm-header__settings">
      <div class="user-details-container" #userDetailsSection>
        <div>
          @if (!this.alertsNotificationService.showBell.value) {
            <a class="notif-icon"  (click)="toggleCreateAlertNotificationPanel()" title="Alert Notification Bell"></a>
          }
          @if (this.alertsNotificationService.showBell.value) {
            <a class="notif-icon-active"  (click)="toggleCreateAlertNotificationPanel()" title="Alert Notification Bell New Alert"></a>
          }
          <a class="notif-name"  (click)="toggleCreateAlertNotificationPanel()">notifications</a>
        </div>
        <div class="initals-avatar">
          <p class="initals-avatar">{{userAvatar}}</p>
        </div>
        <div class="user-details">
          <p class="details-heading">welcome,</p>
          <div class="dropdown-container">
            <p class="user-name-text" (click)="toggleDropdown()">
              {{userName}} @if ((selectedDesk === 'ethanol + uswc'|| selectedDesk === 'ethanol + usec'  || selectedDesk.includes('refinery + usec') || (selectedDesk.startsWith('clean products') && !nominationService.isCargoScreen.value) || selectedDesk === 'panama')) {
              <span
              [class]="dropdownIcon"></span>
            }
          </p>

          @if (isDropdownOpen && (selectedDesk === 'ethanol + uswc'|| selectedDesk === 'ethanol + usec' || selectedDesk.includes('refinery + usec') ||  (selectedDesk.startsWith('clean products') && !nominationService.isCargoScreen.value) || selectedDesk === 'panama')) {
            <div class="dropdown-list-container"
              >
              @for (item of dropdownItems; track item) {
                <div class="dropdown-box">
                  <span [class]="item.icon" class="dropdownItems-icon"></span>
                  @if (item.name !== 'admin' && item.name !== 'alerts & notifications') {
                    <p class="drop-down-item-text" (click)="onClickCustomViews($event)">{{ item.name }}</p>
                  }
                  @if (item.name === 'alerts & notifications') {
                    <p class="drop-down-item-text"><a class="drop-down-item-text" href="/settings/alerts">{{ item.name }}</a></p>
                  }
                  @if (item.name === 'admin') {
                    <p class="drop-down-item-text"><a class="drop-down-item-text" href="/admin/desks">{{ item.name }}</a></p>
                  }</div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</header>