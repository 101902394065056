import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HomeComponent } from 'src/app/home/home.component';
import { NavigationEnd, Router } from '@angular/router';
import * as Nom from '../../../shared/constants/nominations.constant';
import { ReplenishmentPlanningService } from 'src/app/services/replenishment-planning.service';
import { SelectDropdownComponent } from 'src/app/shared/modules/select-dropdown/select-dropdown.component';
import { Utilities } from 'src/app/shared/utilities/utilities';
import { AppState } from 'src/app/shared/store/reducer/app.reducer';
import { NominationService } from 'src/app/services/nomination.service';
import { Store, select } from '@ngrx/store';
import { getPipleineFilteredValues, selectDesk } from 'src/app/shared/store/selector/app.selector';
import { Subject, scheduled, take, takeUntil, Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { MdmService } from 'src/app/services/mdm.service';
import { CargoPlanningService } from 'src/app/services/cargo-planning.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Terminal, RegionTerminal, locOrigin, prodTSWCode, LocSraCode,nomPop,DeskNames,Supplier, productLabels, latamDesks, uomTypes} from 'src/app/shared/constants/cca-dummy.constatnt';
import { reInitiateFiltersForCCANomLinking, saveAppliedTRFilters, savePipelineNomFiltersUpdated, savePipelineTRFiltersUpdated } from 'src/app/shared/store/action/app.action';
import { MyMonitoringService } from 'src/app/services/logging.service';

interface RdiDescriptionOrigin {
  rdi: string;
  description: string;
}
interface RdiDescriptionDesc {
  key: string;
  value: string;
}

@Component({
  selector: 'app-create-nomination-cca',
  templateUrl: './create-nomination-cca.component.html',
  styleUrls: ['./create-nomination-cca.component.scss']
})

export class CreateNominationCcaComponent implements OnInit {
  @Input() isCcaNomEdit: any;
  @Output() closeModalPopup = new EventEmitter();
  @Output() callGridApi = new EventEmitter();
  @ViewChild("SelectDropdownComponent") selectDropdown!: SelectDropdownComponent;
  @ViewChild("transportSystemDropdown") transportSystemDropdDown!: SelectDropdownComponent;
  public createCcaNominationForm!: FormGroup;
  showMultipleTables: boolean = false;
  transportSystemArray: any = []
  defaultTransportSystem!: any
  motNameValue: any = "";
  motArray: any = [[]];
  vesselNameValue: any = "";
  planNameValue: any ;
  vesselArray: any = [[]];
  planArray: any = [[]];
  utilArray: any = [[]];
  utilNameValue: any = "";
  carrierArray: any = [[]];
  terminalData: any = [];
  locOriginData: any = [];
  scProductList: any = [];
  carrierNameValue: any = "";
  isOriginRequired = [false];
  originRDIArray: string[] = [];
  originRDIDescArray: string[] = [];
  destinationRDIArray: string[] = [];
  destinationRDIDescArray: string[] = [];
  qtyDiff: number = 0;
  destinationRDIDescSelected: any = [[]];
  originRDIDescSelected: any = [[]];
  destinationRDISelected: any = [[]];
  originRDISelected: any = [[]];
  portcca:any=[[]];
  portccaDesti:any=[[]];
  terminalDesti:any=[[]];
  terminalOrigin:any=[[]];
  deskDesti:any=[[]];
  deskOrigin:any=[[]];
  supplierOrigin:any=[];
  supplierData:any=[];
  terminalList: any = [[]]
  locationOriginArray: any = [[]]
  locationDestiArray: any = [[]]
  scheduledDemandProductList: any = [[]]
  scheduledDemandProductListDesc: any = [[]]
  scheduledTime: any = [['00:00']]
  scheduledTimeDesti: any = [['00:00']]
  defaultProduct: any = [[]]
  demandProductOrigin: any = [[]]
  demandProductDesti: any = [[]]
  terminalListDesc: any = [[]]
  demandProductIte: any = [[]]
  demandProductIteMaterialCode: any = [[]]
  demandProductList: any = [[]]
  demandProductListDesc: any = [[]]
  uomListOrigin:any = [[]];
  uomListDesc: any = [[]];
  UomDropdown:any = [];
  uomOrigin:any=[[]];
  uomDestination:any=[[]];
  sapcontractValidation: any;
  sapcontractValidationOrigin: any;
  contractLineItemsOrigin: any = [[]];
  contractLineItemsDestination: any = [[]];
  refContractLineItems: any = [[]];
  refContractLineItemsIte: any = [[]];
  productList: any = []
  productListMaterialCode: any = [];
  isDestinationRequired = [false];
  defaultProductdesti: any = [[]]
  refContractLineItemsDesti: any = [[]];
  errorMessage = [false];
  originQtyValue = [0];
  destinQtyValue = [0];
  inCpTab!: boolean;
  intankEast: string = 'ITE'
  intankWest: string = 'ITW'
  scheduledDemandProductListMaterialCode: any = [[]]
  scheduledDemandProductListDescMaterialCode: any = [[]]
  demandProductListMaterialCode: any = [[]]
  demandProductListDescMaterialCode: any = [[]]
  defaultProductMaterialCode: any = [[]]
  defaultProductdestiMaterialCode: any = [[]]
  demandProductOriginMaterialCode: any = [[]]
  demandProductDestiMaterialCode: any = [[]]
  masterProductListForCp: any = [];
  masterTerminalListForCp: any = [];
  masterProductList: any = []
  isMultipleRowItemOrigin: any = [['O']];
  isMultipleRowItemDestination: any = [['D']];
  isMultipleRowTransport = 0;
  deletelist: any = []
  deletedValue: boolean = false;
  olddeletedValue: boolean = false;
  motData: any = [];
  modeofTransportDesc: any = [];
  isCargoDesks: any;
  isNomDesks: any;
  originMovementsValue = [0]
  destinMovementsValue = [0]
  selectedRegion: string = '';
  showDetailFields = false;
  getLocation: any;
  getScheduledDate: any;
  getMaterial: any;
  transportDescArray: any = []
  selectedDeskParameter: any
  selectedDeskRefCca:any;
  public userDetails!: any;
  nominationData!: any;
  public referenceNominations: any;
  inValidDestinationLocation: boolean = false;
  invalidDestinationLocationsg: string = '';
  desk$ = this.store.pipe(select(selectDesk))
  destroy$: Subject<boolean> = new Subject<boolean>();
  region: string = '';
  getPipleineFilteredValues$ = this.store.pipe(select(getPipleineFilteredValues));
  deskNumber!: any;
  isSearchPopup = false;
  scenario: any = "vessel";
  fromSearchCalled: any;
  masterVesselArray: any = [];
  inCpNomTab!: boolean;
  nomCargoFieldObj = {
    bargeFields: true,
    vesselFields: false,
    inTankFields: false,
  }
  masterVesselApiCount: number = 0;
  modalClosed: any;
  individualList: any = []
  individualListMaterialCode: any = []
  masterTerminalList: any = [[]]
  deletedTransportSys: any = [];
  masterApiCount: number = 0;
  transportData: any;
  selectedTerminal: any;
  portOptions:any=[];
  deskNames:any=[];
  deskNamesOrigin:any=[];
  selectedPort: any;
  private subs!: Subscription;
  isnotcreatedFromReforEdited: boolean = true
  createCargoWithRef: boolean = false;
  updateDraftReference: boolean = false
  isAdditionalData: boolean = false;
  originPos: any = 0;
  openRefCount: number = 0;
  refOrginCount: any = 0;
  originArray: any;
  destinationArray: any;
  refDesCount: number = 0;
  isOldTransport: boolean = true;
  getContractNumber: any;
  contractLineItemsITE: any = [[]];
  updatedContractLineItems: any = [];
  isBarge = false; 
  createNomFromDraftId: any;
  destinationDataId: any;
  originDataId: any;
  isDraftData: any;
  isPopupClosed = false;
  deskFilaterData :any = {};
  pipelineAPIFilterTriggered = false;
  ccaAPIFilterTriggered = false
  selectedDeskId: number = 0;
  selectedDesk$ = this.appStore.pipe(select(selectDesk));
  filterLocationSelected: any;
  isPascagoulaRichmond = false;
  cargoLinkData: any={nomId: '', cargoData:''};
  isToggleChecked = false;
  
  constructor(private fb: FormBuilder,
    private replenishmentPlanningService: ReplenishmentPlanningService,
    private utilities: Utilities,
    public nominationService: NominationService,
    private router: Router,
    private toasterService: ToastService,
    private store: Store,
    private cpService: CargoPlanningService,
    private datePipe: DatePipe,
    private loaderService: LoaderService,
    private appStore: Store<AppState>,
    private mdmService: MdmService,
    private myMonitoringService: MyMonitoringService
  ) {
    this.createCcaNominationForm = this.fb.group({
      nominationNo: ['', [Validators.required, Validators.maxLength(16)]],
      cargoName: '',
      transportSystem: '',
      vesselName: ['', Validators.required],
      planName:'',
      transport: this.fb.array([this.createFormGroupTransport(null)]),
      comments: '',
      isoriginDestiTouched: ['', Validators.required],
      isTransportpicked: ['', Validators.required],

    },{ validator: [this.customRequiredValidatorHeader.bind(this)] });


  }
  rdiDescriptionMap: RdiDescriptionOrigin[] = [
    { rdi: 'K', description: 'Purchase into in-transit / Contract' },
    { rdi: 'G', description: 'Sales into in-transit / Contract' },
    { rdi: 'X', description: 'Transfer into in-transit / Reservation' }
  ];
  keyvalueMap: RdiDescriptionDesc[] = [
    { key: 'K', value: 'Purchase into destination / Contract' },
    { key: 'G', value: 'Sales out of in-transit  / Contract' },
    { key: 'X', value: 'Transfer into location / Reservation' }
  ];

  ngOnInit() {
    this.selectedDesk$
    .pipe(takeUntil(this.destroy$))
        .subscribe({
        next: (response: any) => {
        if((response.selectedDeskParam !== this.selectedDeskParameter) && !!this.selectedDeskParameter) {
          this.closePopup(true);
         }
         this.filterLocationSelected = localStorage.getItem('locationSelected');
         this.isPascagoulaRichmond = this.filterLocationSelected?.toUpperCase() === 'PASCAGOU' || this.filterLocationSelected?.toUpperCase() === 'RICHMOND';
        this.selectedDeskParameter = response.selectedDeskParam;
        this.selectedDeskId = +response.selectedDeskId;
        this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        },
      error: (err: any) => { },
      complete: () => { },
    });
    this.isCargoDesks = this.router.url === '/cargoPlanning';
    this.isNomDesks = this.router.url === '/nominations';
    this.loadDropdowns();
  }

 loadDropdowns(){
  this.initialValues()
  this.rdiDescriptionMapFn();
 }

  initialValues(accessMethod: boolean = true) {
    this.userDetails = this.utilities.getUserName(this.nominationService.userDetails$.value._email);
    this.selectedDeskParameter = ''
    this.desk$.pipe(takeUntil(this.destroy$), take(1)).subscribe({
      next: (response: any) => {
        this.selectedDeskParameter = response.selectedDeskParam?.toLowerCase();
        this.region = this.utilities.formatRegionDesk(response.selectedDesk?.toLowerCase());
        this.nominationService.isNominationScreen = '';
        this.nominationService.isNominationScreen = (this.nominationService.isNominationForCP) ? 'nomination' : 'cargo';
        this.modifyFormForCp();
        this.rdiDescriptionMapFn();
        this.modalClosed = this.selectedDeskParameter;
      }, error: (err: any) => {
        console.log(err);
      }, complete: () => {
      }
    })
    this.getPipleineFilteredValues$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.filteredListData(response, true, true);
      },
      error: (err: any) => { },
      complete: () => { }
    });
  }

  filteredListData  (response: any, isGetFilteredPipelineCalled: boolean, shouldGetFilteredResp: boolean){
    if (!this.deskFilaterData[this.selectedDeskParameter] && !(this.ccaAPIFilterTriggered)) { //keep the transport array check 1st for short circuit
      this.ccaAPIFilterTriggered = true;
      this.isSearchOpen(response, shouldGetFilteredResp);
    } else {
      this.deskFilaterData[this.selectedDeskParameter] = response;
      this.updatelists(this.deskFilaterData[this.selectedDeskParameter]);
    }
  }
  async isSearchOpen(response: any, shouldGetFilteredResp: any){
    let resp: any;
    if (this.isSearchPopup) {
      let desk = this.deskNumber;

      if (this.isCargoDesks) {

        resp = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
      } else {

        this.fromSearchCalled = false;
        resp = await this.utilities.getPipelineDropdownListforSearch(desk)
      }
    }
    else{
      if (this.isCargoDesks) {

        resp =  this.utilities.getPipelineCPDropdownList(this.scenario);
      } else {
        resp = await this.utilities.getPipelineDropdownList();
      }
    }
    this.ccaAPIFilterTriggered = false;
    this.pipelineAPIFilterTriggered = false;
    this.shouldFilterResponseCalled(resp, response, shouldGetFilteredResp)
  }
 shouldFilterResponseCalled(resp: any, response: any, shouldGetFilteredResp: any){
 
    if (Object.keys(resp?.value).length > 0) {
        this.updatelists(resp)
        this.deskFilaterData[this.selectedDeskParameter] = resp;
    
    }
  }
  updatelists(response: any) {
    this.terminalList[0] = [];
    this.transportSystemArray = [];
    this.demandProductList = [[]]
    this.demandProductListMaterialCode = [[]]
    this.demandProductListDesc = [[]]
    this.demandProductListDescMaterialCode = [[]]
    this.scheduledDemandProductList = [[]]
    this.scheduledDemandProductListMaterialCode = [[]]
    this.scheduledDemandProductListDesc = [[]]
    this.scheduledDemandProductListDescMaterialCode = [[]]
    this.masterTerminalList = [[]]
    this.productList= [];
    this.productListMaterialCode= [];
    response?.value?.product?.forEach((i: any) => {
      this.productList.push(i.productName)
      this.productListMaterialCode.push(i.productCode);
      this.masterProductList.push(i)
    })
    this.scheduledDemandProductList[0].push(this.productList)
    this.scheduledDemandProductListDesc[0].push(this.productList)
    this.demandProductList[0].push(this.productList)
    this.demandProductListDesc[0].push(this.productList)
    this.scheduledDemandProductListMaterialCode[0].push(this.productListMaterialCode)
    this.scheduledDemandProductListDescMaterialCode[0].push(this.productListMaterialCode)
    this.demandProductListMaterialCode[0].push(this.productListMaterialCode)
    this.demandProductListDescMaterialCode[0].push(this.productListMaterialCode)
    response?.value?.transportsystem?.forEach((i: any) => {
      this.transportSystemArray.push(i.transportSystemCode)
      this.transportDescArray.push(i)
    })
    response?.value?.terminal?.forEach((i: any) => {
      this.terminalList[0].push(i.terminalCode)
      this.masterTerminalList[0]?.push(i.terminalCode)
    })
    this.transportSystemArray = this.removeDuplicates(this.transportSystemArray);
    this.transportDescArray =this.removeDuplicates(this.transportDescArray);
    this.terminalList[0]=this.removeDuplicates(this.terminalList[0]);
    this.isPopupClosed = false;


  }

  rdiDescriptionMapFn() {
    this.originRDIArray = [];
    this.originRDIDescArray = [];
    this.destinationRDIArray = [];
    this.destinationRDIDescArray = [];
    this.originRDIDescSelected = [[]];
    this.originRDISelected = [[]];
    
    if(this.isPascagoulaRichmond) {
      this.originRDIArray.push('G');
      this.originRDIDescArray.push('Sales into in-transit / Contract');
      this.originRDIDescSelected[0][0] = 'Sales into in-transit / Contract'
      this.originRDISelected[0][0] = 'G'
    } else {
      this.rdiDescriptionMap.forEach((item) => {
        this.originRDIArray.push(item.rdi);
        this.originRDIDescArray.push(item.description);
      })
    }
    this.keyvalueMap.forEach((item) => {
      this.destinationRDIArray.push(item.key);
      this.destinationRDIDescArray.push(item.value);
    })
  }
  origin(index: number) {
    return this.transportArray().at(index).get('origin') as FormArray;
  }
  destination(index: number) {
    return this.transportArray().at(index).get('destination') as FormArray;
  }
  transportArray() {
    return this.createCcaNominationForm.get('transport') as FormArray;
  }

  createFormGroupOrigin(index: number): FormGroup {
      return this.fb.group({
        rdi: ['', Validators.required],
        description: ['', Validators.required],
        demandProduct: ['', Validators.required],
        demandProductMaterialCode: ['', Validators.required],
        scheduledProduct: ['', Validators.required],
        scheduledProductMaterialCode: ['', Validators.required],
        scheduledQty: ['', Validators.required],
        Uom:['', Validators.required],
        scheduledDate: ['', Validators.required],
        location: ['', Validators.required],
        sapcontract: '',
        tswStatus:'',
        contractLineNo: '',
        itemId: '',
        id: null,
        indicator: index,
        counterParty: '',
        confirmationIndicator: false,

      },
        { validator: [this.customRequiredValidator.bind(this)] }
      )

  }

createNomwithRefFromCargo(data:any, linkData:any){
  this.cargoLinkData = {cargoData: linkData, nomId:''};
  this.isCargoDesks=false;;
  this.isNomDesks=true;
  this.loadDropdowns();
  setTimeout(() => {
    this.createNominationFromReferenceForPanamaCCA(data)
  }, 1000);
}

  addNewLine(index: any) {
    this.originRDIDescSelected[index] = [];
    this.originRDISelected[index] = [];
    this.locationOriginArray[index] = [];
    this.defaultProductMaterialCode[index] = [];
    this.defaultProduct[index] = [];
    this.demandProductOriginMaterialCode[index] = [];
    this.destinationRDISelected[index] = [];
    this.locationDestiArray[index] = [];
    this.defaultProductdestiMaterialCode[index] = [];
    this.defaultProductdesti[index] = [];
    this.demandProductDestiMaterialCode[index] = [];
  }

  setDropdown(transportIndex: any){
    this.demandProductList[transportIndex] = [];
    this.demandProductListDesc[transportIndex] = [];
    this.scheduledDemandProductList[transportIndex] = []
    this.scheduledDemandProductListDesc[transportIndex] = []
    this.demandProductListMaterialCode[transportIndex]=[]
    this.demandProductListDescMaterialCode[transportIndex]=[]
    this.scheduledDemandProductListMaterialCode[transportIndex]=[]
    this.scheduledDemandProductListDescMaterialCode[transportIndex]=[] 
     this.demandProductList[transportIndex].push(this.productList)
     this.demandProductListDesc[transportIndex].push(this.productList)
     this.scheduledDemandProductList[transportIndex].push(this.productList)
     this.scheduledDemandProductListDesc[transportIndex].push(this.productList)
     this.demandProductListMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.demandProductListDescMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.scheduledDemandProductListMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.scheduledDemandProductListDescMaterialCode[transportIndex].push(this.productListMaterialCode)
     this.terminalList[transportIndex] = this.terminalList[0]

  }
  sumMovements(row:any){
    switch (row.scheduleType) {
      case "O"://Origin 
        this.originMovementsValue[0] = isNaN(this.originMovementsValue[0]) ? 0 : this.originMovementsValue[0] += 1;
        break;
      case "D"://Destination
        this.destinMovementsValue[0] = isNaN(this.destinMovementsValue[0]) ? 0 : this.destinMovementsValue[0] += 1;
        break;  
    } 
  }
  
  initialQty(row:any){   
    switch (row.scheduleType) {
      case "O"://Origin  
      if (!isNaN(row.scheduledQty)) {
        this.originQtyValue[0] += Number(row.scheduledQty);
      } 
        break;
      case "D"://Destination 
      if (!isNaN(row.scheduledQty)) {
        this.destinQtyValue[0] += Number(row.scheduledQty);
      } 
        break; 
    }
  }
  
  setQTYValues(data:any){ 
      let count = 0;
      let pos = 0;
      const originArray = this.transportArray()?.at(count)?.get('origin') as FormArray;
      const destinationArray = this.transportArray().at(count).get('destination') as FormArray;
      originArray.at(pos)?.get('scheduledQty')?.setValue(data.scheduledQty);
      destinationArray.at(pos)?.get('scheduledQty')?.setValue(data.scheduledQty) 
  }

  createNominationFromReferenceForPanamaCCA(data: any) {
    this.selectedDeskRefCca = data?.udmItineraryPlanName;
    data.headerToItemNav.forEach((ele: any) => {
      ele.forEach((val:any) => {
        this.sumMovements(val)
        this.initialQty(val) 
      })        
    })   
    this.initialValues();
    this.createNomFromDraftId = data?.sys_status?.toLowerCase === 'draft' ? data.id : null;
    this.scenario = data?.headerToItemNav[0]?.[0]?.modeOfTransportDesc?.toLowerCase();
    this.updateFormFields(this.scenario,false);
    this.modifyFormForCp(this.scenario, true);
    this.isDraftData = data?.sys_status;
    if (this.nominationService.ccaEditNomination) {
      if (data.nominationNo && typeof data.nominationNo === "object") {
        this.createCcaNominationForm.get("nominationNo")?.setValue(data.nominationNo.nominationNo);
      } else {
        this.createCcaNominationForm.get("nominationNo")?.setValue(data.nominationNo);
      }
    }

    this.defaultTransportSystem = data.transportSystem;
    this.createCcaNominationForm.get("transportSystem")?.setValue(data.transportSystem);
    this.vesselNameValue = (data?.vehicleName)?data.vehicleName:data?.headerToItemNav[0][0].vehicleName;
    this.createCcaNominationForm.get("vesselName")?.setValue(this.vesselNameValue);
    data?.headerToItemNav?.map((el: any, index1: any) => {
      if (index1 !== 0) {
        this.addNewLine(index1);
        this.transportArray().push(this.createFormGroupTransport(null));
      }
      this.setDropdown(index1)
      const transportSystem: any = this.transportArray().at(index1);
      const originData = el.filter((x: any) => x.scheduleType === "O");
      const destinationData = el.filter((x: any) => x.scheduleType === "D");
      originData?.map((dt: any, index2: any) => {
        this.originFormDataSetting(dt, index1, index2, transportSystem);
        this.uomListOrigin[index1].push(this.UomDropdown)
        this.onLocChangeOrigin(index1,index2);
      });
      destinationData?.map((dt: any, index2: any) => {
        this.destinationFormDataSetting(dt, index1, index2, transportSystem);
        this.uomListDesc[index1].push(this.UomDropdown)
        this.onLocChangeDest(index1,index2);
      });
      

    });
  }

  originFormDataSetting(data: any, index1: any, index2: any, transportSystem: any) {
    let scheduledMaterialName, demandMaterialName;
    if (this.selectedDeskParameter === "panamadesk") {
      scheduledMaterialName = this.getDemandMaterialName(data.scheduledMaterial);
      demandMaterialName = this.getDemandMaterialName(data.demandMaterial);
      console.log("origin", scheduledMaterialName, demandMaterialName);
    }
    const scheduledDate = this.datePipe.transform(data.scheduledDate, "yyyy-MM-dd");
    const originForm: any = transportSystem.get('origin') as FormArray;
    
    if (index2 === 0) {
      transportSystem.controls["origin"].controls[0].get("description").setValue(this.rdiDescriptionMap.find(item => item.rdi === data.referenceDocumentIndicator)?.description);
      transportSystem.controls["origin"].controls[0].get("rdi")?.setValue(data.referenceDocumentIndicator);
      transportSystem.controls["origin"].controls[0].get("counterParty")?.setValue(data.contractPartner);
      transportSystem.controls["origin"].controls[0].get("scheduledQty")?.setValue(data.scheduledQty);
      transportSystem.controls["origin"].controls[0].get("tswStatus")?.setValue(data.nominationItemStatus); 
      transportSystem.controls["origin"].controls[0].get("scheduledDate")?.setValue(scheduledDate);
      if (this.nominationService.ccaEditNomination){
        transportSystem.controls["origin"].controls[0].get("tswStatus")?.setValue(data.nominationItemStatus); 
      }
      transportSystem.controls["origin"].controls[0].get("location")?.setValue(data.locationId);
      transportSystem.controls["origin"].controls[0].get("itemId")?.setValue(data?.itemId);
      if (this.router.url === "/replenishmentPlanning") {
        transportSystem.controls["origin"].controls[0].get("Uom")?.setValue('BR');
      }
      else{
        transportSystem.controls["origin"].controls[0].get("Uom")?.setValue(data?.scheduledUOM);
      }
      transportSystem.controls["origin"].controls[0].get("id")?.setValue(data?.id);
      if (this.selectedDeskParameter === "panamadesk") {
        transportSystem.controls["origin"].controls[0].get("scheduledProductMaterialCode")?.setValue(scheduledMaterialName);
        transportSystem.controls["origin"].controls[0].get("scheduledProduct")?.setValue(data.scheduledMaterialName);
        transportSystem.controls["origin"].controls[0].get("demandProductDestiMaterialCode")?.setValue(data.demandMaterial);
        transportSystem.controls["origin"].controls[0].get("demandProductMaterialCode")?.setValue(data.demandMaterial);
        transportSystem.controls["origin"].controls[0].get("demandProduct")?.setValue(demandMaterialName);
      } else {
        transportSystem.controls["origin"].controls[0].get("scheduledProductMaterialCode")?.setValue(data.scheduledMaterial);
        transportSystem.controls["origin"].controls[0].get("scheduledProduct")?.setValue(data.scheduledMaterialName);
        transportSystem.controls["origin"].controls[0].get("demandProductDestiMaterialCode")?.setValue(data.demandMaterialDesc);
        transportSystem.controls["origin"].controls[0].get("demandProductMaterialCode")?.setValue(data.demandMaterial);
        transportSystem.controls["origin"].controls[0].get("demandProduct")?.setValue(data.demandMaterialName);
      }
      data.confirmationIndicator = (!this.nominationService.ccaEditNomination)? null: data.confirmationIndicator;
      let confirmationIndicatorValue = data.confirmationIndicator;
      confirmationIndicatorValue = !((confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === ''));
      transportSystem.controls["origin"].controls[0].get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
      if(data.nominationItemStatus === "5"){
        transportSystem.controls["origin"].controls[0].get('confirmationIndicator')?.disable();
       }
      transportSystem.controls["origin"].controls[0].get("sapcontract")?.setValue(data.nominationReferenceDocument);
      transportSystem.controls["origin"].controls[0].get("contractLineNo")?.setValue(data.nominationReferenceDocumentItem);
    } else {
      let confirmationIndicatorValue = data.confirmationIndicator;
      confirmationIndicatorValue = !((confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === ''));
      this.isMultipleRowItemOrigin[0]?.push('O');
      originForm.push(this.fb.group({
        description: [this.rdiDescriptionMap.find(item => item.rdi === data.referenceDocumentIndicator)?.description, Validators.required],
        rdi: [data.referenceDocumentIndicator, Validators.required],
        counterParty: data.contractPartner,
        location: [data.locationId, Validators.required],
        scheduledProductMaterialCode: [data.scheduledMaterial, Validators.required],
        scheduledProduct: [this.selectedDeskParameter === "panamadesk" ? scheduledMaterialName : data.scheduledMaterialName, Validators.required],
        demandProductDestiMaterialCode: this.selectedDeskParameter === "panamadesk" ? data.demandMaterial : data.demandMaterialDesc,
        demandProductMaterialCode: [data.demandMaterial, Validators.required],
        demandProduct: [this.selectedDeskParameter === "panamadesk" ? demandMaterialName : data.demandMaterialName, Validators.required],
        scheduledDate: [scheduledDate, Validators.required],
        tswStatus : [(!this.nominationService.ccaEditNomination) ? null : data.nominationItemStatus],
        scheduledQty: [data.scheduledQty, Validators.required],
        Uom:[this.router.url === "/replenishmentPlanning" ? 'BR' : data.scheduledUOM, Validators.required],
        sapcontract: data.nominationReferenceDocument,
        contractLineNo: data.nominationReferenceDocumentItem,
        confirmationIndicator: [{ value: (!this.nominationService.ccaEditNomination) ? null : confirmationIndicatorValue, disabled: data.nominationItemStatus === "5" }],
        id:data?.id,
        itemId: data?.itemId
      }));
    }
    this.refContractLineItems[index1][index2] = data.nominationReferenceDocumentItem;
    this.originRDIDescSelected[index1][index2] = this.rdiDescriptionMap.find(item => item.rdi === data.referenceDocumentIndicator)?.description;
    this.originRDISelected[index1][index2] = data.referenceDocumentIndicator;
    this.locationOriginArray[index1][index2] = data.locationId;
    this.demandProductOriginMaterialCode[index1][index2] = data.demandMaterial;
    this.uomOrigin[index1][index2]=this.router.url === "/replenishmentPlanning" ? 'BR' : data.scheduledUOM;
    this.demandProductOrigin[index1][index2] = this.selectedDeskParameter === "panamadesk" ? demandMaterialName : data.demandMaterialName;
    this.defaultProductMaterialCode[index1][index2] = data.scheduledMaterial;
    this.defaultProduct[index1][index2] = this.selectedDeskParameter === "panamadesk" ? scheduledMaterialName : data.scheduledMaterialName;
   
    this.scheduledTime[index1][index2] = '00:00';
    this.originDataId = data.id;
  }

  destinationFormDataSetting(data: any, index1: any, index2: any, transportSystem: any) {
    let scheduledMaterialName, demandMaterialName; 
    if (this.selectedDeskParameter === "panamadesk") {
      scheduledMaterialName = this.getDemandMaterialName(data.scheduledMaterial);
      demandMaterialName = this.getDemandMaterialName(data.demandMaterial);
      console.log(scheduledMaterialName, demandMaterialName);
    }
    const scheduledDate = this.datePipe.transform(data.scheduledDate, "yyyy-MM-dd");
    const destinationForm: any = transportSystem.get('destination') as FormArray;
    
    if (index2 === 0) {
      transportSystem.controls["destination"].controls[0].get("key")?.setValue(data.referenceDocumentIndicator);
      transportSystem.controls["destination"].controls[0].get("value")?.setValue( this.keyvalueMap.find(item => item.key === data.referenceDocumentIndicator)?.value);
      transportSystem.controls["destination"].controls[0].get("counterParty")?.setValue(data.contractPartner);
      transportSystem.controls["destination"].controls[0].get("scheduledQty")?.setValue(data.scheduledQty);
      if (this.nominationService.ccaEditNomination){
        transportSystem.controls["destination"].controls[0].get("tswStatus")?.setValue(data.nominationItemStatus); 
      }
      if (this.router.url === "/replenishmentPlanning") {
        transportSystem.controls["destination"].controls[0].get("Uom")?.setValue('BR');
      }
      else{
        transportSystem.controls["destination"].controls[0].get("Uom")?.setValue(data.scheduledUOM);
      }
      transportSystem.controls["destination"].controls[0].get("scheduledDate")?.setValue(scheduledDate);
      transportSystem.controls["destination"].controls[0].get("location")?.setValue(data.locationId);
      transportSystem.controls["destination"].controls[0].get("scheduledProductMaterialCode")?.setValue(data.scheduledMaterial);
      if (this.selectedDeskParameter === "panamadesk") {
        transportSystem.controls["destination"].controls[0].get("scheduledProduct")?.setValue(scheduledMaterialName);
        transportSystem.controls["destination"].controls[0].get("demandProductDestiMaterialCode")?.setValue(data.demandMaterial);
        transportSystem.controls["destination"].controls[0].get("demandProduct")?.setValue(demandMaterialName);
      } else {
        transportSystem.controls["destination"].controls[0].get("scheduledProduct")?.setValue(data.scheduledMaterialName);
        transportSystem.controls["destination"].controls[0].get("demandProductDestiMaterialCode")?.setValue(data.demandMaterialDesc);
        transportSystem.controls["destination"].controls[0].get("demandProduct")?.setValue(data.demandMaterialName);
      }
      data.confirmationIndicator = (!this.nominationService.ccaEditNomination)? null: data.confirmationIndicator;
      let confirmationIndicatorValue = data.confirmationIndicator;
      confirmationIndicatorValue = !((confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === ''));
      transportSystem.controls["destination"].controls[0].get('confirmationIndicator')?.setValue(confirmationIndicatorValue);
      if(data.nominationItemStatus === "5"){
        transportSystem.controls["destination"].controls[0].get('confirmationIndicator')?.disable();
       }
      transportSystem.controls["destination"].controls[0].get("demandProductMaterialCode")?.setValue(data.demandMaterial);
      transportSystem.controls["destination"].controls[0].get("sapcontract")?.setValue(data.nominationReferenceDocument);
      transportSystem.controls["destination"].controls[0].get("contractLineNo")?.setValue(data.nominationReferenceDocumentItem);
      transportSystem.controls["destination"].controls[0].get("itemId")?.setValue(data?.itemId);
      transportSystem.controls["destination"].controls[0].get("id")?.setValue(data?.id);  
      transportSystem.controls["destination"].controls[0].get("tswStatus")?.setValue(data?.nominationItemStatus);  
    } else {
      let confirmationIndicatorValue = data.confirmationIndicator;
      confirmationIndicatorValue = !((confirmationIndicatorValue === 'R' || !confirmationIndicatorValue || confirmationIndicatorValue === ''));
      this.isMultipleRowItemDestination[0]?.push('D');
      destinationForm.push(this.fb.group({
        value:[this.keyvalueMap.find(item => item.key === data.referenceDocumentIndicator)?.value, Validators.required],
        key: [data.referenceDocumentIndicator, Validators.required],
        counterParty: data.contractPartner,
        location: [data.locationId, Validators.required],
        scheduledProductMaterialCode: [data.scheduledMaterial, Validators.required],
        scheduledProduct: [this.selectedDeskParameter === "panamadesk" ? scheduledMaterialName : data.scheduledMaterialName, Validators.required],
        demandProductDestiMaterialCode: this.selectedDeskParameter === "panamadesk" ? data.demandMaterial : data.demandMaterialDesc,
        demandProductMaterialCode: [data.demandMaterial, Validators.required],
        demandProduct: [this.selectedDeskParameter === "panamadesk" ? demandMaterialName : data.demandMaterialName, Validators.required],
        scheduledDate: [scheduledDate, Validators.required],
        tswStatus : [(!this.nominationService.ccaEditNomination) ? null : data.nominationItemStatus],
        scheduledQty: [data.scheduledQty, Validators.required],
        Uom:[this.router.url === "/replenishmentPlanning" ? 'BR' : data.scheduledUOM, Validators.required],
        sapcontract: data.nominationReferenceDocument,
        contractLineNo: data.nominationReferenceDocumentItem,
        confirmationIndicator: [{ value: (!this.nominationService.ccaEditNomination) ? null : confirmationIndicatorValue, disabled: data.nominationItemStatus === "5" }],
        id:data?.id,
        itemId: data?.itemId,
      }));
    }
    this.refContractLineItemsDesti[index1][index2] = data.nominationReferenceDocumentItem;
    this.destinationRDISelected[index1][index2] = data.referenceDocumentIndicator; 
    this.destinationRDIDescSelected[index1][index2]=this.keyvalueMap.find(item => item.key === data.referenceDocumentIndicator)?.value;
    this.locationDestiArray[index1][index2] = data.locationId;
    this.uomDestination[index1][index2]=this.router.url === "/replenishmentPlanning" ? 'BR' : data.scheduledUOM;
    this.demandProductDestiMaterialCode[index1][index2] = data.demandMaterial;
    this.demandProductDesti[index1][index2] = this.selectedDeskParameter === "panamadesk" ? demandMaterialName : data.demandMaterialName;
    this.defaultProductdestiMaterialCode[index1][index2] = data.scheduledMaterial;
    this.defaultProductdesti[index1][index2] = this.selectedDeskParameter === "panamadesk" ? scheduledMaterialName : data.scheduledMaterialName;
    this.scheduledTimeDesti[index1][index2] = '00:00';
    this.destinationDataId = data.id;
  }


createFormGroupDestination(index: number): FormGroup {
      return this.fb.group({
        key: ['', Validators.required],
        value: ['', Validators.required],
        demandProduct: ['', Validators.required],
        demandProductMaterialCode: ['', Validators.required],
        scheduledProduct: ['', Validators.required],
        scheduledProductMaterialCode: ['', Validators.required],
        scheduledQty: ['', Validators.required],
        Uom:['', Validators.required],
        scheduledDate: ['', Validators.required],
        location: ['', Validators.required],
        tswStatus: '',
        sapcontract: '',
        contractLineNo: '',
        itemId: '',
        id: null,
        indicator: index,
        counterParty: '',
        confirmationIndicator: false,

      },
        { validator: [this.customRequiredValidatorDesti.bind(this)] }
      )
  }

  createFormGroupTransport(transportSystemControl: any, index?: number): FormGroup {
      return this.fb.group({
        transportSystemMode: new FormControl(),
        origin: this.fb.array([this.createFormGroupOrigin(index ?? 0)]),
        destination: this.fb.array([this.createFormGroupDestination(index ?? 0)]),

      })
  }

  customRequiredValidatorHeader(g: any){
    if(g?.value['nominationNo'] && g?.value['transportSystem']){
      let originheader, destinheader
        let origin = g?.controls['transport']?.controls[0]?.controls['origin']?.controls[0] as FormArray
        let desti = g?.controls['transport']?.controls[0]?.controls['destination']?.controls[0] as FormArray
        this.checkformValue(originheader, origin, desti, destinheader)
    }
  }

  toggleChange(){
    this.isToggleChecked = !this.isToggleChecked;
  }

  checkformValue(originheader: any, origin: any, desti: any, destinheader: any){
    if( !origin?.value['rdi'] && !origin?.value['location']&& !origin?.value['demandProduct'] &&! origin?.value['scheduledQty'] && !origin?.value['scheduledDate'] && ! origin?.value['endLoadDate'] &&   !origin?.value['scheduledProduct']){
         originheader = true
        }

    if( !desti?.value['rdi'] && !desti?.value['location'] && !desti?.value['demandProduct'] && !desti?.value['scheduledQty']&& !desti?.value['scheduledDate'] &&  !desti?.value['endLoadDate'] &&  !desti?.value['scheduledProduct']){
        destinheader = true
        }
       // if both origin and destination is empty
    if( originheader && destinheader ){
        this.createCcaNominationForm.get('isTransportpicked')?.setErrors({'invalid': true})
       }
      }

  customRequiredValidatorDesti(g: any) {
    let fields = ['key', 'location', 'demandProduct', 'scheduledProduct', 'scheduledQty','Uom','scheduledDate', 'value', 'scheduledProductMaterialCode', 'demandProductMaterialCode'];
    if (g?.value['key'] || g?.value['location'] || g?.value['demandProduct'] || g?.value['scheduledQty'] ||  g?.value['Uom'] || g?.value['scheduledDate'] || g?.value['scheduledProduct'] || g?.value['scheduledProductMaterialCode'] || g?.value['demandProductMaterialCode']) {
      this.isDestinationRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
        g.get(ele).setValidators(Validators.required);
        if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
      })
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createCcaNominationForm.get('isoriginDestiTouched')?.clearValidators()
      this.createCcaNominationForm.get('isTransportpicked')?.setErrors(null)
      this.createCcaNominationForm.get('isTransportpicked')?.clearValidators()


    } else {
      this.isDestinationRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();

      })
    }
  }


  customRequiredValidator(g: any) {

    let fields = ['rdi', 'location', 'demandProduct', 'scheduledProduct', 'scheduledQty','Uom','scheduledDate', 'description', 'scheduledProductMaterialCode', 'demandProductMaterialCode'];
    if (g?.value['rdi'] || g?.value['location'] || g?.value['demandProduct'] || g?.value['scheduledQty'] || g?.value['Uom'] || g?.value['scheduledDate'] || g?.value['endLoadDate'] || g?.value['scheduledProduct'] || g?.value['scheduledProductMaterialCode'] || g?.value['demandProductMaterialCode']) {
      this.isOriginRequired[g?.value['indicator']] = true;
      fields.forEach(ele => {
        g.get(ele).setValidators(Validators.required);
        if (!g?.value[ele]) { g.get(ele).setErrors({ 'invalid': true }) }
      })
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createCcaNominationForm.get('isoriginDestiTouched')?.clearValidators()
      this.createCcaNominationForm.get('isTransportpicked')?.setErrors(null)
      this.createCcaNominationForm.get('isTransportpicked')?.clearValidators()
    } else {
      this.isOriginRequired[g?.value['indicator']] = false;
      fields.forEach((ele) => {
        g.get(ele)?.setErrors(null)
        g.get(ele)?.clearValidators();

      })
    }
  }

  //function for origin rdi and desc
  onRdiChange(transportIndex: number, rowIndex: number) {

    const originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
    const rdiControl = originArray.at(rowIndex)?.get('rdi');
    const descriptionControl = originArray.at(rowIndex)?.get('description');

    if (rdiControl && descriptionControl) {
      const selectedRdi = rdiControl.value;
      const selectedRdiItem = this.rdiDescriptionMap.find(item => item.rdi === selectedRdi);
      if (selectedRdiItem) {
        descriptionControl.setValue(selectedRdiItem.description);
        this.originRDIDescSelected[transportIndex][rowIndex] = selectedRdiItem.description;
      } else {
        descriptionControl.setValue('');
        this.originRDIDescSelected[transportIndex][rowIndex] = '';
      }
    }

    this.onRdiChangeSplit(rowIndex, originArray, transportIndex);

  }
  ontransportSystemChange(){
    const transportSystemControl = this.createCcaNominationForm.get('transportSystem');
    let transport =this.createCcaNominationForm.get('transportSystem')?.value;
    this.defaultTransportSystem=transport;
    this.createCcaNominationForm.get('transportSystem')?.setValue(this.defaultTransportSystem);
    let payload = {"transportSystems": [transportSystemControl?.value]}
    this.mdmService.getVehiclesByTs(payload).subscribe({
      next: (response: any) => {
        if (response?.success && response?.statusCode === 200) {
          this.masterVesselApiCount = 0;
          let vesselResponse = response.value.value[0].vehicles.map((ele: any) => {
            return {
              vehicleCode: ele.vehicleIdentifier,
            vesselId: ele.vehicle,
            vesselName: ele.vehicleName
            }
        })
          this.masterVesselArray = vesselResponse;
          this.vesselArray = vesselResponse?.map((ts:any) => ts.vesselName);
          this.checkAndValidateForm();
        }
      }
    })
  }
    onRdiChangeSplit(rowIndex: any, originArray: any, transportIndex: any) {
    if (rowIndex > 0) {

      const scheduledProductinitial = originArray.at(0)?.get('scheduledProduct')?.value;
      const scheduledProductinitialMaterialCode = originArray.at(0)?.get('scheduledProductMaterialCode')?.value;
      const demandProductinitial = originArray.at(0)?.get('demandProduct')?.value;
      const demandProductinitialMaterialCode = originArray.at(0)?.get('demandProductMaterialCode')?.value;
      const scheduledDateinitial = originArray.at(0)?.get('scheduledDate')?.value;
      const locationinitial = originArray.at(0)?.get('location')?.value;

      const scheduledProductCurrent = originArray.at(rowIndex)?.get('scheduledProduct')?.value;
      const demandProductinitialCurrent = originArray.at(rowIndex)?.get('demandProduct')?.value;
      const scheduledDateCurrent = originArray.at(rowIndex)?.get('scheduledDate')?.value;
      const locationCurrent = originArray.at(rowIndex)?.get('location')?.value;
      if (scheduledProductinitial && scheduledProductCurrent == "") {
        this.defaultProduct[transportIndex][rowIndex] = scheduledProductinitial
        this.defaultProductMaterialCode[transportIndex][rowIndex] = scheduledProductinitialMaterialCode
      }
      if (demandProductinitial && demandProductinitialCurrent == "") {
        this.demandProductOrigin[transportIndex][rowIndex] = demandProductinitial
        this.demandProductOriginMaterialCode[transportIndex][rowIndex] = demandProductinitialMaterialCode
      }
      if (scheduledDateinitial && scheduledDateCurrent == "") {
        originArray?.at(rowIndex)?.get('scheduledDate')?.setValue(this.datePipe.transform(scheduledDateinitial, "yyy-MM-dd"))

      }
      if (locationinitial && locationCurrent == "") {
        this.locationOriginArray[transportIndex][rowIndex] = locationinitial
      }
    }
  }
  onDescriptionChange(transportIndex: number, rowIndex: number) {

    const originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
    const descriptionControl = originArray.at(rowIndex)?.get('description');
    const rdiControl = originArray.at(rowIndex)?.get('rdi');

    if (descriptionControl && rdiControl) {
      const selectedDescription = descriptionControl.value;
      const selectedRdiItem = this.rdiDescriptionMap.find(item => item.description === selectedDescription);
      if (selectedRdiItem) {
        rdiControl.setValue(selectedRdiItem.rdi);
        this.originRDISelected[transportIndex][rowIndex] = selectedRdiItem.rdi;
      } else {
        this.originRDISelected[transportIndex][rowIndex] = '';
        rdiControl.setValue('');
      }

    }
  }

  // function for destination table
  onDestinationRdiChange(transportIndex: number, rowIndex: number) {
    const destinationArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
    const rdiControl = destinationArray.at(rowIndex)?.get('key');
    const descriptionControl = destinationArray.at(rowIndex)?.get('value');

    if (rdiControl && descriptionControl) {
      const selectedRdi = rdiControl.value;
      const selectedRdiItem = this.keyvalueMap.find(item => item.key === selectedRdi);

      if (selectedRdiItem) {
        descriptionControl.setValue(selectedRdiItem.value);
        this.destinationRDIDescSelected[transportIndex][rowIndex] = selectedRdiItem.value;
      } else {
        descriptionControl.setValue('');
        this.destinationRDIDescSelected[transportIndex][rowIndex] = '';
      }
    }
    this.onDestinationRdiChangeSplit(rowIndex, destinationArray, transportIndex);

  }
  onDestinationRdiChangeSplit(rowIndex: any, destinationArray: any, transportIndex: any) {
    if (rowIndex > 0) {

      const scheduledProductinitial = destinationArray.at(0)?.get('scheduledProduct')?.value;
      const scheduledProductinitialMaterialCode = destinationArray.at(0)?.get('scheduledProductMaterialCode')?.value;
      const demandProductinitial = destinationArray.at(0)?.get('demandProduct')?.value;
      const demandProductinitialMaterialCode = destinationArray.at(0)?.get('demandProductMaterialCode')?.value;
      const scheduledDateinitial = destinationArray.at(0)?.get('scheduledDate')?.value;
      const locationinitial = destinationArray.at(0)?.get('location')?.value;

      const scheduledProductCurrent = destinationArray.at(rowIndex)?.get('scheduledProduct')?.value;
      const demandProductinitialCurrent = destinationArray.at(rowIndex)?.get('demandProduct')?.value;
      const scheduledDateCurrent = destinationArray.at(rowIndex)?.get('scheduledDate')?.value;
      const locationCurrent = destinationArray.at(rowIndex)?.get('location')?.value;
      if (scheduledProductinitial && scheduledProductCurrent == "") {
        this.defaultProductdesti[transportIndex][rowIndex] = scheduledProductinitial
        this.defaultProductdestiMaterialCode[transportIndex][rowIndex] = scheduledProductinitialMaterialCode
      }
      if (demandProductinitial && demandProductinitialCurrent == "") {
        this.demandProductDesti[transportIndex][rowIndex] = demandProductinitial
        this.demandProductDestiMaterialCode[transportIndex][rowIndex] = demandProductinitialMaterialCode
      }
      if (scheduledDateinitial && scheduledDateCurrent == "") {
        destinationArray?.at(rowIndex)?.get('scheduledDate')?.setValue(this.datePipe.transform(scheduledDateinitial, "yyy-MM-dd"))

      }
      if (locationinitial && locationCurrent == "") {
        this.locationDestiArray[transportIndex][rowIndex] = locationinitial
      }
    }
  }

  onDestinationDescriptionChange(transportIndex: number, rowIndex: number) {

    const destinationArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
    const descriptionControl = destinationArray.at(rowIndex)?.get('value');
    const rdiControl = destinationArray.at(rowIndex)?.get('key');

    if (descriptionControl && rdiControl) {
      const selectedDescription = descriptionControl.value;
      const selectedRdiItem = this.keyvalueMap.find(item => item.value === selectedDescription);

      if (selectedRdiItem) {
        rdiControl?.setValue(selectedRdiItem.key);
        this.destinationRDISelected[transportIndex][rowIndex] = selectedRdiItem.key;
      } else {
        rdiControl?.setValue('');
        this.destinationRDISelected[transportIndex][rowIndex] = '';
      }
    }

  }

  getDemandMaterialName(demandProduct: any) {
    let product: any;
    if (this.inCpTab) {
      product = this.masterProductListForCp.find((element: any) => element.productCode === demandProduct);
    }
    else {
      product = this.masterProductList.find((element: any) => element.productCode === demandProduct);
    }
    return product?.productName;
  }
  onDemandProductChangeMaterialCode(index: any, rowIndex: any, formType: any) {
   

    let formArray = this.transportArray()?.at(index).get(formType) as FormArray
    if (formType === 'origin') {
      this.defaultProductMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode'];
    }
    this.defaultProduct[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode']);
    if (!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['demandProductMaterialCode']) {
      formArray.at(rowIndex).get('demandProduct')?.setValue('');
      this.onDemandProductChange(index, rowIndex)
    }
  }

  onLocChangeOrigin(index: any, rowIndex: any) {
    const originArray = this.transportArray().at(index).get('origin') as FormArray;
    const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createCcaNominationForm.get('transportSystem');
    const location = originArray.at(rowIndex)?.get('location');
    this.locationOriginArray[index][rowIndex]=location?.value;
    this.callApi(transportSystemControl?.value, location?.value, index, rowIndex, 'origin');
    this.checkQTY(index, rowIndex) // to avoid reset pipelineform call once location is set


  }

  onLocChangeDest(index: any, rowIndex: any) {
    const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createCcaNominationForm.get('transportSystem');
    const destArray = this.transportArray().at(index).get('destination') as FormArray;
    const location = destArray.at(rowIndex)?.get('location');
    this.locationDestiArray[index][rowIndex]=location?.value;
    this.callApi(transportSystemControl?.value, location?.value, index, rowIndex, 'destination')
  }

  async callApi(transportSystemControl: any, location: any, index: any, rowIndex: any, tableType: string) {
    this.individualList = []
    this.individualListMaterialCode = []
    // let urBody: any = {
    //   desk: `pl${this.selectedDeskParameter}`,
    //   region: this.region,
    //   includeXLocations: true,
    //   terminal: location ? [location] : null,
    //   transportsystem: transportSystemControl ? [transportSystemControl] : null,
    //   requestFor: "dropdown",
    //   isCargoType: this.isCargoDesks ? true : undefined,
    //   isPipelineType: this.isCargoDesks ? true : undefined
    // }
    // if(this.inCpTab || (this.inCpNomTab && (this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields))){
    //   urBody.modeOfTransport = [this.scenario?.charAt(0).toUpperCase() + this.scenario.slice(1)];
    // }                                                          ``````                                                
    if (this.selectedDeskParameter) {
      let response: any;
      if (this.isSearchPopup) {
        let desk = this.deskNumber;

        if (this.isCargoDesks) {

          response = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
        } else {

          this.fromSearchCalled = false;
          response = await this.utilities.getPipelineDropdownListforSearch(desk)
        }
      }
      else {
        if (this.isCargoDesks) {

          response = this.utilities.getPipelineCPDropdownList(this.scenario, [transportSystemControl], [location]);
        } else {
          response = await this.utilities.getPipelineDropdownList([transportSystemControl], [location])
        }
      }
      this.individualList = []
      this.individualListMaterialCode = []
      if (response?.value?.product.length > 0) {
        this.individualList = [];
        this.individualListMaterialCode = []
        response?.value?.product.forEach((i: any) => {
          this.individualList.push(i.productName)
          this.individualListMaterialCode.push(i.productCode)
        })
        if (tableType === 'origin' || tableType === 'ite') {
          this.demandProductList[index][rowIndex] = this.individualList
          this.scheduledDemandProductList[index][rowIndex] = this.individualList
          this.demandProductListMaterialCode[index][rowIndex] = this.individualListMaterialCode
          this.scheduledDemandProductListMaterialCode[index][rowIndex] = this.individualListMaterialCode
        }
        else {
          this.demandProductListDesc[index][rowIndex] = this.individualList
          this.scheduledDemandProductListDesc[index][rowIndex] = this.individualList
          this.demandProductListDescMaterialCode[index][rowIndex] = this.individualListMaterialCode
          this.scheduledDemandProductListDescMaterialCode[index][rowIndex] = this.individualListMaterialCode
        }
        
        this.checkProductSelected(this.individualList, index, rowIndex, tableType)
      }
      if (response?.value?.terminal.length > 0) {
        this.masterTerminalList[index] = response?.value?.terminal.map((i: any) =>
          i.terminalCode
        )
      }
    }

  }

  checkProductSelected(list: any, index: any, rowIndex: any, val: string) {
    const formArray = this.transportArray().at(index).get(val) as FormArray;
    if (val === 'origin' && !list.includes(this.demandProductOrigin[index][rowIndex])) {
      this.demandProductOrigin[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProduct')?.setValue('')
      this.demandProductOriginMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProductMaterialCode')?.setValue('')
    }
    if (val === 'origin' && !list.includes(this.defaultProduct[index][rowIndex])) {
      this.defaultProduct[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('')
      this.defaultProductMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('')
    }
    if (val === 'destination' && !list.includes(this.demandProductDesti[index][rowIndex])) {
      this.demandProductDesti[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProduct')?.setValue('')
      this.demandProductDestiMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProductMaterialCode')?.setValue('')
    }
    if (val === 'destination' && !list.includes(this.demandProductDesti[index][rowIndex])) {
      this.defaultProductdesti[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('')
      this.defaultProductdestiMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('')
    }
    if (val === 'ite' && !list.includes(this.demandProductIte[index][rowIndex])) {
      this.demandProductIte[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProduct')?.setValue('')
      this.demandProductIteMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('demandProductMaterialCode')?.setValue('')
    }
    if (val === 'ite' && !list.includes(this.defaultProduct[index][rowIndex])) {
      this.defaultProduct[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('')
      this.defaultProductMaterialCode[index][rowIndex] = ''
      formArray.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('')
    }
  }

  checkIndividualLocationProduct(val: string, transportCount?: any, rowIndex?: any) {
    this.transportArray().controls.forEach((element: any, index: any) => {

      if (element.value['origin'] && rowIndex < element.value['origin'].length) {

        element.controls['origin'].controls.forEach((e: any, originIndex: any) => {
          if (e.controls['location'] && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('');
            e.controls['demandProduct'].setValue('');
            e.controls['demandProductMaterialCode'].setValue('');
            this.locationOriginArray[index][originIndex] = ''
            this.demandProductOrigin[index][originIndex] = ''
            this.defaultProduct[index][originIndex] = ''
            this.demandProductOriginMaterialCode[index][originIndex] = ''
            this.defaultProductMaterialCode[index][originIndex] = ''
          }
          else {
            this.locationOriginArray[index][originIndex] = e.controls['location'].value
            this.demandProductOrigin[index][originIndex] = e.controls['demandProduct'].value
            this.defaultProduct[index][originIndex] = e.controls['scheduledProduct'].value
            this.demandProductOriginMaterialCode[index][originIndex] = e.controls['demandProductMaterialCode'].value
            this.defaultProductMaterialCode[index][originIndex] = e.controls['scheduledProductMaterialCode'].value
          }
        }

        )

      }
      if (element.value['destination'] && rowIndex < element.value['destination'].length) {

        element.controls['destination'].controls.forEach((e: any, destiIndex: any) => {
          if (e.controls['location'] && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('')
            e.controls['demandProduct'].setValue('')
            e.controls['demandProductMaterialCode'].setValue('')
            this.locationDestiArray[index][destiIndex] = ''
            this.demandProductDesti[index][destiIndex] = ''
            this.defaultProductdesti[index][destiIndex] = ''
            this.demandProductDestiMaterialCode[index][destiIndex] = ''
            this.defaultProductdestiMaterialCode[index][destiIndex] = ''
          }
          else {
            this.locationDestiArray[index][destiIndex] = e.controls['location'].value
            this.demandProductDesti[index][destiIndex] = e.controls['demandProduct'].value
            this.defaultProductdesti[index][destiIndex] = e.controls['scheduledProduct'].value
            this.demandProductDestiMaterialCode[index][destiIndex] = e.controls['demandProductMaterialCode'].value
            this.defaultProductdestiMaterialCode[index][destiIndex] = e.controls['scheduledProductMaterialCode'].value
          }
        })
      }
    })
  }

  checkLocationProduct(val: string, transportCount?: any, rowIndex?: any) {
    this.transportArray().controls.forEach((element: any, index: any) => {

      let originRow = 0;
      let destiRow = 0;
      let iteRow = 0;
      if (element.value['origin'] && originRow < element.value['origin'].length) {

        element.controls['origin'].controls.forEach((e: any) => {
          if (e.controls['location'] && this.masterTerminalList[index]?.length && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('');
            e.controls['demandProduct'].setValue('');
            this.locationOriginArray[index][originRow] = ''
            this.demandProductOrigin[index][originRow] = ''
            this.defaultProduct[index][originRow] = ''
            this.demandProductOriginMaterialCode[index][originRow] = ''
            this.defaultProductMaterialCode[index][originRow] = ''
          }
          else {
            this.locationOriginArray[index][originRow] = e.controls['location']?.value
            this.demandProductOrigin[index][originRow] = e.controls['demandProduct']?.value
            this.defaultProduct[index][originRow] = e.controls['scheduledProduct']?.value
            this.demandProductOriginMaterialCode[index][originRow] = e.controls['demandProductMaterialCode']?.value
            this.defaultProductMaterialCode[index][originRow] = e.controls['scheduledProductMaterialCode']?.value
          }
          originRow++;
        })

      }
      if (element.value['destination'] && destiRow < element.value['destination'].length) {

        element.controls['destination'].controls.forEach((e: any) => {
          if (e.controls['location'] && this.masterTerminalList[index]?.length && !this.masterTerminalList[index]?.includes(e.controls['location'].value)) {
            e.controls['location'].setValue('')
            e.controls['demandProduct'].setValue('')
            this.locationDestiArray[index][destiRow] = ''
            this.demandProductDesti[index][destiRow] = ''
            this.defaultProductdesti[index][destiRow] = ''
            this.demandProductDestiMaterialCode[index][destiRow] = ''
            this.defaultProductdestiMaterialCode[index][destiRow] = ''
          }
          else {
            this.locationDestiArray[index][destiRow] = e.controls['location']?.value
            this.demandProductDesti[index][destiRow] = e.controls['demandProduct']?.value
            this.defaultProductdesti[index][destiRow] = e.controls['scheduledProduct']?.value
            this.demandProductDestiMaterialCode[index][destiRow] = e.controls['demandProductMaterialCode']?.value
            this.defaultProductdestiMaterialCode[index][destiRow] = e.controls['scheduledProductMaterialCode']?.value
          }
          destiRow++;
        })
      }

    })
  }

  async onDemandProductChange(index: any, rowIndex: any) {

    //response from api will be set to scheduleddemandproductlist
    if (this.createCcaNominationForm.get('transportSystem')?.value === this.intankEast || this.createCcaNominationForm.get('transportSystem')?.value === this.intankWest) {
      let ite = this.transportArray()?.at(index).get('ite') as FormArray
      let res = await this.filterProductCategory(ite.at(rowIndex)?.get('demandProduct')?.value, 'ite', index, rowIndex, this.demandProductList[index][rowIndex])
      if (!this.arraysEqual(res.productList, this.scheduledDemandProductList[index][rowIndex])) {
        this.scheduledDemandProductList[index][rowIndex] = []
        this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.scheduledDemandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.scheduledDemandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct']) {
        ite.at(rowIndex).get('demandProductMaterialCode')?.setValue('');
      }
      if ((!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProductMaterialCode'])) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'];
        this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'])
      } else if (this.scheduledDemandProductList[index][rowIndex].includes(ite.at(rowIndex)?.get('scheduledProduct')?.value)) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct']
        this.defaultProductMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProductMaterialCode']
      }
      this.demandProductIteMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'])
    }
    else {
      let origin = this.transportArray()?.at(index).get('origin') as FormArray
      let res = await this.filterProductCategory(origin.at(rowIndex)?.get('demandProduct')?.value, 'origin', index, rowIndex, this.demandProductList[index][rowIndex])

      if (!this.arraysEqual(res.productList, this.scheduledDemandProductList[index][rowIndex])) {
        this.scheduledDemandProductList[index][rowIndex] = []
        this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.scheduledDemandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.scheduledDemandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct']) {
        origin.at(rowIndex).get('demandProductMaterialCode')?.setValue('');
      }
      if ((!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProductMaterialCode'])) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'];
        this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'])
      }
      else if (this.scheduledDemandProductList[index][rowIndex].includes(origin.at(rowIndex)?.get('scheduledProduct')?.value)) {
        this.defaultProduct[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'];
        this.defaultProductMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProductMaterialCode'];
      }
      this.demandProductOriginMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'])
    }

  }

  checkQTY(index: number, row: number) {
    let originQty = 0;
    let destiQty = 0
    let i = 0;
    let j = 0;
    let formOri = this.transportArray().at(index).get('origin') as FormArray;
    let formDesti = this.transportArray().at(index).get('destination') as FormArray;
    this.transportArray().at(index).get('origin')?.value?.forEach((e: any) => {
      const qty = Number(e.scheduledQty);
      if (!isNaN(qty)) {
        originQty += qty;
      }
    });
    this.transportArray().at(index).get('destination')?.value?.forEach((e: any) => {
      const qty = Number(e.scheduledQty);
      if (!isNaN(qty)) {
        destiQty += qty;
      }
    });
    this.originQtyValue[index] = originQty; 
    this.destinQtyValue[index] = destiQty;
    if (this.isOriginRequired[index] === true && this.isDestinationRequired[index] === true) {
      if (originQty !== destiQty) {
        this.errorMessage[index] = true;
        this.resetRowManullay(formOri, originQty, formDesti, destiQty, index)
      }
      else {
        while (i <= formOri.length) {
          formOri?.at(i)?.get('scheduledQty')?.setErrors(null)
          i++;
        }
        while (j <= formDesti.length) {
          formDesti?.at(j)?.get('scheduledQty')?.setErrors(null)
          j++;
        }
        this.errorMessage[index] = false
      }
    } else {
      this.resetRowManullay(formOri, originQty, formDesti, destiQty, index)
    }
  }

  resetRowManullay(formOri: any, originQty: any, formDesti: any, destiQty: any, index: number) {
    if (formOri.length === 1 && !originQty) {

      this.transportArray().at(index).get('origin')?.value?.forEach((e: any, ind: number) => {
        let checkValid = true;
        Object.keys(e).forEach((elem) => {
          if (elem !== 'id' && !!(e[elem])) {
            checkValid = false;
          }
        })
        if (checkValid) {
          this.isOriginRequired[index] = false;
          this.errorMessage[index] = false
          formOri?.at(ind)?.get('scheduledQty')?.setErrors(null);
        }
      })
      this.transportArray().at(index).get('destination')?.value?.forEach((e: any, ind: number) => {
        if (!(isNaN(parseFloat(e.scheduledQty)))) formDesti?.at(ind)?.get('scheduledQty')?.setErrors(null)
      })
    }
    if (formDesti.length === 1 && !destiQty) {
      this.transportArray().at(index).get('destination')?.value?.forEach((e: any, ind: number) => {
        let checkValid = true;
        Object.keys(e).forEach((elem) => {
          if (elem !== 'id' && !!(e[elem])) {
            checkValid = false;
          }
        })
        if (checkValid) {
          this.isDestinationRequired[index] = false;
          this.errorMessage[index] = false
          formDesti?.at(ind)?.get('scheduledQty')?.setErrors(null)
        }
      })
      this.transportArray().at(index).get('origin')?.value?.forEach((e: any, ind: number) => {
        if (!(isNaN(parseFloat(e.scheduledQty)))) formOri?.at(ind)?.get('scheduledQty')?.setErrors(null)
      })
    }
  }

  onContractLineChange(event: any, index: number, formGroupIndex:number, formArrayName: string) {
    const replaceLeadingZero = event.target.value.replace(/\b0+/g, "")?.trim();
    if (formArrayName == 'origin') {
      this.origin(formGroupIndex).at(index)?.get('sapcontract')?.setValue(replaceLeadingZero);
    }
    else if (formArrayName == 'destination') {
      this.destination(formGroupIndex).at(index)?.get('sapcontract')?.setValue(replaceLeadingZero);
    }
  }

  fetchContractLineItem(index: number, j: number, formType: any) {
    if (this.checkFetchContractItemArray(index, j, formType)) {
      return;
    }
    let payload1: any = {
      desk: `pl${this.selectedDeskParameter}`,
      contractNumber: this.getContractNumber,
      location: this.getLocation || null,
      scheduledDate: this.getScheduledDate || null,
      materials: this.getMaterial?.productCode!== undefined ? [this.getMaterial?.productCode] :[]
    }
    this.replenishmentPlanningService.getContractBalancingSmartFilter(payload1).subscribe({
      next: (response: any) => {
        if (response?.length) {
          switch (formType) {
            case 'origin':
              if (this.matchArray(response, this.contractLineItemsOrigin[index][j])) {
                this.updatedContractLineItems = [...this.contractLineItemsOrigin[index][j]]
                return;
              }
              this.contractLineItemsOrigin[index][j] = [];
              break;
            case 'destination':
              if (this.matchArray(response, this.contractLineItemsDestination[index][j])) {
                this.updatedContractLineItems = [...this.contractLineItemsDestination[index][j]]
                return;
              }
              this.contractLineItemsDestination[index][j] = [];
              break;
            case 'ite':
              if (this.matchArray(response, this.contractLineItemsITE[index][j])) {
                this.updatedContractLineItems = [...this.contractLineItemsITE[index][j]]
                return;
              }
              this.contractLineItemsITE[index][j] = [];
              break;
            default:
              break;
          }
          this.updatedContractLineItems = [];
          response.forEach((data: any) => {
            this.updatedContractLineItems.push(`${data.contractLineNumber} | ${data.sraDealDetailReferenceNumber} | ${data.location} | ${data.modeOfTransportDescription} | ${data.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}`);
          })
          this.setUpdateContractLineItem(index, j, formType, true);
        } else {
          this.updatedContractLineItems = [];
          this.setUpdateContractLineItem(index, j, formType, false);
        }
      }
    })
  }

  setUpdateContractLineItem(index: number, j: number, formType: any, isLength: boolean) {
    switch (formType) {
      case 'origin':
        this.contractLineItemsOrigin[index][j] = [...this.updatedContractLineItems];
        if (!isLength) {
          this.refContractLineItems[index][j] = 'select';
        }
        break;
      case 'destination':
        this.contractLineItemsDestination[index][j] = [...this.updatedContractLineItems];
        if (!isLength) {
          this.refContractLineItemsDesti[index][j] = 'select';
        }
        break;
      case 'ite':
        this.contractLineItemsITE[index][j] = [...this.updatedContractLineItems];
        if (!isLength) {
          this.refContractLineItemsIte[index][j] = 'select';
        }
        break;
      default:
        break;
    }
  }

  checkFetchContractItemArray(index: number, j: number, formType: string) {
    let updateType = this.transportArray().at(index).get(formType) as FormArray;
    if (this.getContractNumber === updateType?.at(j)?.get('sapcontract')?.value && this.getLocation === updateType?.at(j)?.get('location')?.value && this.getScheduledDate === updateType?.at(j)?.get('scheduledDate')?.value && (this.getMaterial?.productName || "") === updateType?.at(j)?.get('scheduledProduct')?.value) {
      switch (formType) {
        case 'origin':
          this.contractLineItemsOrigin[index][j] = [...this.updatedContractLineItems];
          this.refContractLineItems[index][j] = updateType?.at(j)?.get('contractLineNo')?.value;
          break;
        case 'destination':
          this.contractLineItemsDestination[index][j] = [...this.updatedContractLineItems];
          this.refContractLineItemsDesti[index][j] = updateType?.at(j)?.get('contractLineNo')?.value;
          break;
        case 'ite':
          this.contractLineItemsITE[index][j] = [...this.updatedContractLineItems];
          this.refContractLineItemsIte[index][j] = updateType?.at(j)?.get('contractLineNo')?.value;
          break;
        default:
          break;
      }
      return true;
    }
    this.getContractNumber = updateType?.at(j)?.get('sapcontract')?.value;
    this.getLocation = updateType?.at(j)?.get('location')?.value;
    this.getScheduledDate = updateType?.at(j)?.get('scheduledDate')?.value;
    if (this.inCpTab) {
      this.getMaterial = this.masterProductListForCp.find((element: any) => element.productName === updateType?.at(j)?.get('scheduledProduct')?.value);
    }
    else {
      this.getMaterial = this.masterProductList.find((element: any) => element.productName === updateType?.at(j)?.get('scheduledProduct')?.value);
    }
    return false
  }

  objectsEqual(data: any, o2: any) {
    let o1: any = `${data.contractLineNumber} | ${data.sraDealDetailReferenceNumber} | ${data.location} | ${data.modeOfTransportDescription} | ${data.contractQuantity} | ${data?.supplier} | ${data?.contractDateRange}`
    let match = false
    if (typeof o1 === 'object' && Object.keys(o1).length > 0) {
      match = (Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => { this.objectsEqual(o1[p], o2[p]) }))
    } else {
      match = (o1 === o2)
    }
    return match
  }

  matchArray(res: any, prevArr: any) {
    let finalMatch: any = []
    let itemFound: any = []
    if (res.length === prevArr?.length) {
      finalMatch = []
      res.forEach((data: any) => {
        itemFound = []
        prevArr.forEach((i2: any) => {
          itemFound.push(this.objectsEqual(data, i2))
        })
        finalMatch.push(itemFound.some((i: any) => i === true))
      })
    }
    return (finalMatch?.length === 0) ? false : finalMatch.every((i: any) => i === true)
  }

  onScheduledProductDestiChangeMaterialCode(index: any, rowIndex: any) {

    let formArray = this.transportArray()?.at(index).get('destination') as FormArray
      this.demandProductDestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'];
    this.defaultProductdesti[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'])
    this.demandProductDesti[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'])
    if (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']) {
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('');
      this.onScheduledProductDestiChange(index, rowIndex)
    }
  }

  async onScheduledProductDestiChange(index: any, rowIndex: any) {

    let desti = this.transportArray()?.at(index).get('destination') as FormArray

    let res = await this.filterProductCategory(desti.at(rowIndex)?.get('scheduledProduct')?.value, 'destination', index, rowIndex, this.scheduledDemandProductListDesc[index][rowIndex])
    if (!this.arraysEqual(res.productList, this.demandProductListDesc[index][rowIndex])) {

      this.demandProductListDesc[index][rowIndex] = []
      this.demandProductListDescMaterialCode[index][rowIndex] = []
      res.productList.forEach((item: any) => {
        this.demandProductListDesc[index][rowIndex].push(item)
      })
      res.productCode.forEach((item: any) => {
        this.demandProductListDescMaterialCode[index][rowIndex].push(item)
      })
    }
    //response from api will be set to demandproduct list

    //response from api will be set to demandproduct list
    if (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct']) {
      desti.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('');
    }
    if ((!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode'])) {
      this.demandProductDesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'];
      this.demandProductDestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'])
    }
    else if (this.demandProductListDesc[index][rowIndex].includes(desti.at(rowIndex)?.get('demandProduct')?.value)) {
      this.demandProductDesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct']
      this.demandProductDestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']
    }

    this.defaultProductdestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'])
  }

  onDemandProductDestiChangeMaterialCode(index: any, rowIndex: any) {
    let formArray = this.transportArray()?.at(index).get('destination') as FormArray
   
      this.defaultProductdestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode'];
    

      this.defaultProductdesti[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']);
    if (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']) {
      formArray.at(rowIndex).get('demandProduct')?.setValue('');
      this.onDemandProductDestiChange(index, rowIndex)
    }
  }

  async onDemandProductDestiChange(index: any, rowIndex: any) {
    let desti = this.transportArray()?.at(index).get('destination') as FormArray

    let res = await this.filterProductCategory(desti.at(rowIndex)?.get('demandProduct')?.value, 'destination', index, rowIndex, this.demandProductListDesc[index][rowIndex])
    //response from api will be set to scheduleddemandproductlist
    if (!this.arraysEqual(res.productList, this.scheduledDemandProductListDesc[index][rowIndex])) {
      this.scheduledDemandProductListDesc[index][rowIndex] = []
      this.scheduledDemandProductListDescMaterialCode[index][rowIndex] = []
      res.productList.forEach((item: any) => {
        this.scheduledDemandProductListDesc[index][rowIndex].push(item)
      })
      res.productCode.forEach((item: any) => {
        this.scheduledDemandProductListDescMaterialCode[index][rowIndex].push(item)
      })
    }

    if (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct']) {
      desti.at(rowIndex).get('demandProductMaterialCode')?.setValue('');
    }
    if ((!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'] || !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct'] && !this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode'])) {
      this.defaultProductdesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'];
      this.defaultProductdestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'])
    }
    else if (this.scheduledDemandProductListDesc[index][rowIndex].includes(desti.at(rowIndex)?.get('scheduledProduct')?.value)) {
      this.defaultProductdesti[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProduct']
      this.defaultProductdestiMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['scheduledProductMaterialCode']
    }
    this.demandProductDestiMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('destination')?.value[rowIndex]['demandProduct'])
  }

  onScheduledProductChangeMaterialCode(index: any, rowIndex: any, formType: any) {
    let formArray = this.transportArray()?.at(index).get(formType) as FormArray
      if (formType === 'origin') {
        this.demandProductOriginMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode'];
        this.demandProductOrigin[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode']);
      }
    this.defaultProduct[index][rowIndex] = this.getDemandMaterialName(this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode']);
    if (!this.transportArray()?.at(index)?.get(formType)?.value[rowIndex]['scheduledProductMaterialCode']) {
      formArray.at(rowIndex).get('scheduledProduct')?.setValue('');
      this.onScheduledProductChange(index, rowIndex)
    }
  }

  async onScheduledProductChange(index: any, rowIndex: any) {
    //response from api will be set to demandproduct list
    if (this.createCcaNominationForm.get('transportSystem')?.value === this.intankEast || this.createCcaNominationForm.get('transportSystem')?.value === this.intankWest) {
      let ite = this.transportArray()?.at(index).get('ite') as FormArray
      let res = await this.filterProductCategory(ite.at(rowIndex)?.get('scheduledProduct')?.value, 'ite', index, rowIndex, this.scheduledDemandProductList[index][rowIndex])
      if (!this.arraysEqual(res.productList, this.demandProductList[index][rowIndex])) {
        this.demandProductList[index][rowIndex] = []
        this.demandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.demandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.demandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct']) {
        ite.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('');
      }

      if ((!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProductMaterialCode'])) {
        this.demandProductIte[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'];
        this.demandProductIteMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'])
      }
      else if (this.demandProductList[index][rowIndex].includes(ite.at(rowIndex)?.get('demandProduct')?.value)) {
        this.demandProductIte[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProduct']
        this.demandProductIteMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['demandProductMaterialCode']
      }
      this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('ite')?.value[rowIndex]['scheduledProduct'])
    }
    else {
      let origin = this.transportArray()?.at(index).get('origin') as FormArray
      let res = await this.filterProductCategory(origin.at(rowIndex)?.get('scheduledProduct')?.value, 'origin', index, rowIndex, this.scheduledDemandProductList[index][rowIndex]) //error async

      if (!this.arraysEqual(res.productList, this.demandProductList[index][rowIndex])) {
        this.demandProductList[index][rowIndex] = []
        this.demandProductListMaterialCode[index][rowIndex] = []
        res.productList.forEach((item: any) => {
          this.demandProductList[index][rowIndex].push(item)
        })
        res.productCode.forEach((item: any) => {
          this.demandProductListMaterialCode[index][rowIndex].push(item)
        })
      }
      if (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct']) {
        origin.at(rowIndex).get('scheduledProductMaterialCode')?.setValue('');
      }
      if ((!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'] || !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProductMaterialCode']) && (!this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct'] && !this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProductMaterialCode'])) {
        this.demandProductOrigin[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'];
        this.demandProductOriginMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'])
      }
      else if (this.demandProductList[index][rowIndex].includes(origin.at(rowIndex)?.get('demandProduct')?.value)) {
        this.demandProductOrigin[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProduct']
        this.demandProductOriginMaterialCode[index][rowIndex] = this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['demandProductMaterialCode']
      }
      this.defaultProductMaterialCode[index][rowIndex] = this.getDemandMaterialID(this.transportArray()?.at(index)?.get('origin')?.value[rowIndex]['scheduledProduct'])
    }
  }

  arraysEqual(a1: any, a2: any) {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
  }

  async filterProductCategory(product: any, type: string, index: number, rowIndex: number, productList: any) {
    // filter list of all product present in productlist and then find category.
    let result = this.masterProductList.filter((e: any) => {
      if (productList?.includes(e.productName)) {
        return e;
      }
    })
    let filteredList: any = []
    let filteredListMaterialCode: any = []
    let category: any = []
    if (product) {
      let productFiltered = result.find((element: any) => element.productName === product);
      category = result.filter((e: any) => {
        return e.productCategory === productFiltered?.productCategory;
      })
      category.forEach((i: any) => {
        filteredList.push(i.productName)
        filteredListMaterialCode.push(i.productCode)
      })
      filteredList = this.removeDuplicates(filteredList)
      filteredListMaterialCode = this.removeDuplicates(filteredListMaterialCode)
      return { productList: filteredList, productCode: filteredListMaterialCode }

    }
    else {
      const formArray = this.transportArray().at(index).get(type) as FormArray;
      const transportSystemControl = this.transportArray().at(index).get('transportSystemMode')?.value ? this.transportArray().at(index).get('transportSystemMode') : this.createCcaNominationForm.get('transportSystem');
      const location = formArray.at(rowIndex)?.get('location');
      let ts = transportSystemControl?.value ? [transportSystemControl.value] : null;
      let term = location?.value ? [location.value] : null;
      let response: any;
      if (this.isSearchPopup) {
        let desk = this.deskNumber;

        if (this.isCargoDesks) {

          response = this.utilities.getPipelineCPDropdownListForSearch(this.scenario);
        } else {

          this.fromSearchCalled = false;
          response = await this.utilities.getPipelineDropdownListforSearch(desk)
        }
      }
      else {
        if (this.isCargoDesks) {

          response = this.utilities.getPipelineCPDropdownList(this.scenario, ts, term);
        } else {
          response = await this.utilities.getPipelineDropdownList(ts, term)
        }
      }
      response?.value?.product.forEach((i: any) => {
        filteredList.push(i.productName)
        filteredListMaterialCode.push(i.productCode)
      })

      this.productListReupload(index, rowIndex, type, filteredList, filteredListMaterialCode)
      filteredList = this.removeDuplicates(filteredList)
      filteredListMaterialCode = this.removeDuplicates(filteredListMaterialCode)
      return { productList: filteredList, productCode: filteredListMaterialCode }
    }
  }

  removeDuplicates(arr: any) {
    let newArr: any = []
    arr.forEach((ele: any) => {
      if (newArr.length === 0) {
        newArr.push(ele)
      }
      else {
        newArr.forEach((e: any) => {
          if (!newArr.includes(ele)) {
            newArr.push(ele)
          }
        })
      }
    })
    return newArr

  }


  productListReupload(index: any, rowIndex: any, type: any, res: any, resCode: any) {

    //reset the default to select  and dropdown of scheduled product and demand product to old data list

    if (type === 'destination') {
      this.demandProductDesti[index][rowIndex] = ''
      this.demandProductListDesc[index][rowIndex] = []
      this.scheduledDemandProductListDesc[index][rowIndex] = []
      this.defaultProductdesti[index][rowIndex] = ''

      this.demandProductDestiMaterialCode[index][rowIndex] = ''
      this.demandProductListDescMaterialCode[index][rowIndex] = []
      this.scheduledDemandProductListDescMaterialCode[index][rowIndex] = []
      this.defaultProductdestiMaterialCode[index][rowIndex] = ''
      res.forEach((e: any) => {
        this.scheduledDemandProductListDesc[index][rowIndex].push(e)
        this.demandProductListDesc[index][rowIndex].push(e)
      })
      resCode.forEach((e: any) => {
        this.scheduledDemandProductListDescMaterialCode[index][rowIndex].push(e)
        this.demandProductListDescMaterialCode[index][rowIndex].push(e)
      })
    }
    if (type === 'origin') {
      this.demandProductOrigin[index][rowIndex] = ''
      this.defaultProduct[index][rowIndex] = ''
      this.scheduledDemandProductList[index][rowIndex] = []
      this.demandProductList[index][rowIndex] = []
      this.demandProductOriginMaterialCode[index][rowIndex] = ''
      this.defaultProductMaterialCode[index][rowIndex] = ''
      this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
      this.demandProductListMaterialCode[index][rowIndex] = []
      res.forEach((e: any) => {
        this.scheduledDemandProductList[index][rowIndex].push(e)
        this.demandProductList[index][rowIndex].push(e)
      })
      resCode.forEach((e: any) => {
        this.scheduledDemandProductListMaterialCode[index][rowIndex].push(e)
        this.demandProductListMaterialCode[index][rowIndex].push(e)
      })
    }
    if (type === 'ite') {
      this.demandProductIte[index][rowIndex] = ''
      this.defaultProduct[index][rowIndex] = ''
      this.scheduledDemandProductList[index][rowIndex] = []
      this.demandProductList[index][rowIndex] = []
      this.demandProductIteMaterialCode[index][rowIndex] = ''
      this.defaultProductMaterialCode[index][rowIndex] = ''
      this.scheduledDemandProductListMaterialCode[index][rowIndex] = []
      this.demandProductListMaterialCode[index][rowIndex] = []
      res.forEach((e: any) => {
        this.scheduledDemandProductList[index][rowIndex].push(e)
        this.demandProductList[index][rowIndex].push(e)
      })
      resCode.forEach((e: any) => {
        this.scheduledDemandProductListMaterialCode[index][rowIndex].push(e)
        this.demandProductListMaterialCode[index][rowIndex].push(e)
      })

    }


  }

  addOriginRow(index: number) {
    const originArray = this.origin(index);
    const newOriginRow = this.createFormGroupOrigin(index);
    originArray.push(newOriginRow);
    this.isMultipleRowItemOrigin[index]?.push('O');
    this.demandProductList[index][originArray.length - 1] = this.productList
    this.scheduledDemandProductList[index][originArray.length - 1] = this.productList
    this.demandProductListMaterialCode[index][originArray.length - 1] = this.productListMaterialCode
    this.scheduledDemandProductListMaterialCode[index][originArray.length - 1] = this.productListMaterialCode
    this.uomListOrigin[index][originArray.length - 1] = this.UomDropdown
    this.isOriginRequired[index] = false;
    this.scheduledTime[index][originArray.length - 1] = ['00:00']
    this.originMovementsValue[index] = originArray.length;

  }
  addDestinationRow(index: number) {
    const destinationArray = this.destination(index);
    const newDestinationRow = this.createFormGroupDestination(index);
    destinationArray.push(newDestinationRow);
    this.destinMovementsValue[index] = destinationArray.length; 
    this.scheduledTimeDesti[index][destinationArray.length - 1] = ['00:00']
    this.isMultipleRowItemDestination[index]?.push('D')
    this.demandProductListDesc[index][destinationArray.length - 1] = this.productList
    this.scheduledDemandProductListDesc[index][destinationArray.length - 1] = this.productList
    this.demandProductListDescMaterialCode[index][destinationArray.length - 1] = this.productListMaterialCode
    this.scheduledDemandProductListDescMaterialCode[index][destinationArray.length - 1] = this.productListMaterialCode
    this.uomListDesc[index][destinationArray.length - 1] = this.UomDropdown

  }
  checkifOriginDestiisEmpty() {
    let alloriginisempty = true;
    let alldestisempty = true;

    this.transportArray().controls.forEach((ele: any) => {
      ele.controls['origin'].controls.forEach((item: any) => {
        Object.keys(item.controls).forEach((e) => {
          // if any row any field has value remove the validation for isoriginDestiTouched
          if (e !== 'id' && item.controls[e].value !== '') {
            alloriginisempty = false
          }
        })
      })

      ele.controls['destination'].controls.forEach((item: any) => {
        Object.keys(item.controls).forEach((e) => {
          if (e !== 'id' && item.controls[e].value !== '') {
            alldestisempty = false
          }
        })

      })

    })
    if (alloriginisempty && alldestisempty) {
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true })
    }
    else {
      this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors(null)
      this.createCcaNominationForm.get('isoriginDestiTouched')?.clearValidators()
    }
  }
  copyDestinationRow(formIndex:number, rowIndex:number){
    this.addDestinationRow(formIndex)
    const copyRowData = this.destination(formIndex).at(rowIndex);
    const destinationList = this.destination(formIndex);
    const lastRowNo = destinationList.length - 1;
    const pasteNewdestinationRowData = this.destination(formIndex).at(lastRowNo);
    pasteNewdestinationRowData.patchValue(copyRowData.value);
    pasteNewdestinationRowData.get('itemId')?.setValue('');
    this.destinationRDIDescSelected[formIndex][lastRowNo] = copyRowData.value.value;
    this.destinationRDISelected[formIndex][lastRowNo] = copyRowData.value.key;
    this.locationDestiArray[formIndex][lastRowNo] = copyRowData.value.location;
    this.defaultProductdestiMaterialCode[formIndex][lastRowNo] = copyRowData.value.scheduledProductMaterialCode;
    this.defaultProductdesti[formIndex][lastRowNo] = copyRowData.value.scheduledProduct;
    this.demandProductDestiMaterialCode[formIndex][lastRowNo] = copyRowData.value.demandProductMaterialCode
    this.demandProductDesti[formIndex][lastRowNo] = copyRowData.value.demandProduct;
    this.refContractLineItemsDesti[formIndex][lastRowNo] = copyRowData.value.contractLineNo;
    this.uomDestination[formIndex][lastRowNo]=copyRowData.value.Uom;
    this.checkQTY(formIndex, rowIndex);
  }


  deletedestinationRow(transportIndex: number, rowIndex: number) {
    this.deletedNomList(transportIndex, rowIndex, 'destination');
    this.destination(transportIndex).removeAt(rowIndex);
      this.isMultipleRowItemDestination[transportIndex]?.splice(rowIndex,1);
      this.locationDestiArray[transportIndex].splice(rowIndex, 1);
      this.demandProductDesti[transportIndex].splice(rowIndex, 1);
      this.destinMovementsValue[0] -=1;
      this.defaultProductdesti[transportIndex].splice(rowIndex, 1);
      this.demandProductList[transportIndex].splice(rowIndex, 1);
      this.scheduledDemandProductList[transportIndex].splice(rowIndex, 1);
      this.demandProductDestiMaterialCode[transportIndex].splice(rowIndex, 1);
      this.defaultProductdestiMaterialCode[transportIndex].splice(rowIndex, 1);
      this.demandProductListMaterialCode[transportIndex].splice(rowIndex, 1);
      this.scheduledDemandProductListMaterialCode[transportIndex].splice(rowIndex, 1);
      this.destinationRDIDescSelected[transportIndex].splice(rowIndex, 1);
      this.destinationRDISelected[transportIndex].splice(rowIndex, 1);
      this.scheduledTimeDesti[transportIndex].splice(rowIndex,1)
      this.contractLineItemsDestination[transportIndex].splice(rowIndex,1)
      this.refContractLineItemsDesti[transportIndex].splice(rowIndex,1)
      this.uomDestination[transportIndex].splice(rowIndex,1)

      this.destination(transportIndex).value.forEach((element: any) => {
        this.isDestinationRequired[transportIndex] = false;
  
          Object.keys(element).forEach((e: any) => {
            if (element[e] !== '') {
              this.isDestinationRequired[transportIndex] = true;
            }
  
          })
        });
      

      this.destination(transportIndex).value.forEach((element: any) => {
        this.isDestinationRequired[transportIndex] = false;
  
          Object.keys(element).forEach((e: any) => {
            if (element[e] !== '') {
              this.isDestinationRequired[transportIndex] = true;
            }
  
          })
        });
      

    this.checkifOriginDestiisEmpty()
    setTimeout(() => {
      this.checkQTY(transportIndex, rowIndex)
    }, 1);
  }


  updateFormFields(data: any, openModal: boolean = true) {
    this.scenario = data;
    this.isBarge = this.scenario=== 'barge';
  }


  async getMasterDataForCargo() {
    this.masterApiCount = 1;
    this.nominationService.triggerMasterCargoAPI?.next(false);
    if (this.selectedDeskParameter) {
      let response: any;
      if (this.isCargoDesks) {

        response = this.utilities.getPipelineCPDropdownList(this.scenario);
      } else {
        response = await this.utilities.getPipelineDropdownList();
      }
      this.setTransportVesselBargeCarrier(response);
      this.masterApiCount = 0;
    }
  }

  setTransportVesselBargeCarrier(response: any) {
    this.transportData = response;
    this.terminalList[0] = [];
    this.productList = [];
    this.productListMaterialCode = [];
    this.demandProductList[0] = [];
    this.masterProductList = [];
    this.masterProductListForCp = [];
    this.transportSystemArray = [];
    this.transportDescArray = [];
      this.transportSystemArray = response.value?.transportsystem.map((ts: any) => {
        this.transportSystemArray.push(ts.transportSystemCode);
        this.transportDescArray.push(ts);
        return ts.transportSystemCode;
      });
    response?.value?.terminal.forEach((i: any) => {
      this.terminalList[0].push(i.terminalCode)
      this.masterTerminalListForCp.push(i);

    })

    if (!this.masterProductListForCp.length) {
      response?.value?.product.forEach((i: any) => {
        this.productList.push(i.productName)
        this.productListMaterialCode.push(i.productCode);
        this.masterProductList.push(i);
        this.masterProductListForCp.push(i);
      })
      this.demandProductList[0].push(this.productList);
      this.scheduledDemandProductList[0].push(this.productList);
      this.demandProductListDesc[0].push(this.productList);
      this.scheduledDemandProductListDesc[0].push(this.productList);
      this.demandProductListMaterialCode[0].push(this.productListMaterialCode);
      this.scheduledDemandProductListMaterialCode[0].push(this.productListMaterialCode);
      this.demandProductListDescMaterialCode[0].push(this.productListMaterialCode);
      this.scheduledDemandProductListDescMaterialCode[0].push(this.productListMaterialCode);
    }
  }

  getMasterVesselData(vessel?:any){
    this.masterVesselApiCount = 1;
    if ((this.scenario?.includes('vessel') || this.scenario?.includes('barge'))) {
      let cargoPayload: any = {
        desk: `cpvessels`,
        mot: this.scenario?.charAt(0).toUpperCase() + this.scenario.slice(1),
      }
  
     
      this.mdmService.getVehicles(this.scenario === 'barge' ? 'B' : 'S').subscribe({
        next: (response: any) => {
          if (response?.success && response?.statusCode === 200) {
            this.masterVesselApiCount = 0;
            let vesselResponse = response.value.value.map((ele: any) => {
              return {
                vehicleCode: ele.vehicleIdentifier,
              vesselId: ele.vehicle,
              vesselName: ele.vehicleName
              }
          })
            this.masterVesselArray = vesselResponse;
            this.vesselArray = vesselResponse?.map((ts:any) => ts.vesselName);
            if(vessel){
              this.createCcaNominationForm.get("vesselName")?.setValue(vessel);
            }
            this.checkForVesselvalue();
          }
        }
      });
    }
  }

  checkAndValidateForm(){
    if(!this.defaultTransportSystem){
      this.createCcaNominationForm.get('transportSystem')?.setValue("");
    }
    this.checkForVesselvalue()
    if(!this.vesselNameValue){
       this.createCcaNominationForm.get('vesselName')?.setValue("");
    }
  }

checkForVesselvalue() {
  this.vesselNameValue = this.masterVesselArray?.filter((ts: any) => {
    return ts.vesselName === this.createCcaNominationForm.get('vesselName')?.value
  }).length ? this.createCcaNominationForm.get('vesselName')?.value : '';
}

  modifyFormForCp(scenario?:any, withRef?:boolean) {
  this.motData = [];
  this.terminalData = [];
  let tempNomData = Nom.cargoMots;
  // let regionData = this.mdmService.getDeskPortTerminal();
  if(withRef && scenario){
    tempNomData.forEach((nom:any, index:any) => {
      this.motData.push({key:nom.key, value:nom.key, identifier:nom.identifier, checked: nom.key === scenario})
    });
  }
  else{
  tempNomData.forEach((nom: any, index: any) => {
    this.motData.push({ key: nom.key, value: nom.key, identifier:nom.identifier, checked: index === 1 })
  });
  }
 this.UomDropdown=uomTypes;
 this.uomListOrigin[0].push(this.UomDropdown)
 this.uomListDesc[0].push(this.UomDropdown)  
}

  deletedNomList(transportIndex: number, rowIndex: number, type: string) {
    let obj: any = {}
    if (type === 'origin') {
      let originArray = this.transportArray().at(transportIndex).get('origin') as FormArray;
      let itemid = originArray.at(rowIndex).get('itemId');
      let parentId = originArray.at(rowIndex).get('id');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.referenceDocumentIndicator = 'X'
        obj.scheduleType = 'O';
        obj.deletionIndicator = "X"
        obj.parentId = parentId?.value;
        this.deletelist.push(obj);

      }


    }
    else if (type === 'destination') {
      let destinArray = this.transportArray().at(transportIndex).get('destination') as FormArray;
      let itemid = destinArray.at(rowIndex).get('itemId');
      let parentId = destinArray.at(rowIndex).get('id');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.referenceDocumentIndicator = 'X'
        obj.scheduleType = 'D';
        obj.deletionIndicator = "X"
        obj.parentId = parentId?.value;
        this.deletelist.push(obj);
      }

    }
    else {
      let iteArray = this.transportArray().at(transportIndex).get('ite') as FormArray;
      let itemid = iteArray.at(rowIndex).get('itemId');
      let parentId = iteArray.at(rowIndex).get('id');
      if (itemid?.value) {
        obj.itemId = itemid?.value
        obj.referenceDocumentIndicator = 'X'
        obj.scheduleType = 'IT';
        obj.deletionIndicator = "X";
        obj.parentId = parentId?.value;
        this.deletelist.push(obj);

      }

    }
    this.deletedValue = true;

  }

  copyOriginRow(formIndex:number, rowIndex:number){
    this.addOriginRow(formIndex)
    const copyRowData = this.origin(formIndex).at(rowIndex)
    const originList = this.origin(formIndex);
    const lastRowNo = originList.length - 1;
    const pasteNewOriginRowData = this.origin(formIndex).at(lastRowNo);
    pasteNewOriginRowData.patchValue(copyRowData.value);
    pasteNewOriginRowData.get('itemId')?.setValue('');
    this.originRDIDescSelected[formIndex][lastRowNo] = copyRowData.value.description;
    this.originRDISelected[formIndex][lastRowNo] = copyRowData.value.rdi;
    this.locationOriginArray[formIndex][lastRowNo] = copyRowData.value.location;
    this.defaultProductMaterialCode[formIndex][lastRowNo] = copyRowData.value.scheduledProductMaterialCode;
    this.defaultProduct[formIndex][lastRowNo] = copyRowData.value.scheduledProduct;
    this.demandProductOriginMaterialCode[formIndex][lastRowNo] = copyRowData.value.demandProductMaterialCode;
    this.demandProductOrigin[formIndex][lastRowNo] = copyRowData.value.demandProduct;
    this.refContractLineItems[formIndex][lastRowNo] = copyRowData.value.contractLineNo;
    this.uomOrigin[formIndex][lastRowNo]=copyRowData.value.Uom;
    this.checkQTY(formIndex, rowIndex);
  }

  deleteOriginRow(transportIndex: number, rowIndex: number) {
    this.deletedNomList(transportIndex, rowIndex, 'origin')
    this.origin(transportIndex).removeAt(rowIndex);
    this.isMultipleRowItemOrigin[transportIndex]?.splice(rowIndex, 1);
    this.demandProductOrigin[transportIndex].splice(rowIndex, 1);
    this.originMovementsValue[0] -=1;
    this.defaultProduct[transportIndex].splice(rowIndex, 1);
    this.demandProductOriginMaterialCode[transportIndex].splice(rowIndex, 1);
    this.defaultProductMaterialCode[transportIndex].splice(rowIndex, 1);
    this.locationOriginArray[transportIndex].splice(rowIndex, 1);
    this.originRDIDescSelected[transportIndex].splice(rowIndex, 1);
    this.originRDISelected[transportIndex].splice(rowIndex, 1);
    this.scheduledDemandProductList[transportIndex].splice(rowIndex, 1);
    this.refContractLineItems[transportIndex].splice(rowIndex, 1);
    this.contractLineItemsOrigin[transportIndex].splice(rowIndex, 1)
    this.scheduledDemandProductListMaterialCode[transportIndex].splice(rowIndex, 1);
    this.uomOrigin[transportIndex].splice(rowIndex,1);
    this.origin(transportIndex).value.forEach((element: any) => {
      this.isOriginRequired[transportIndex] = false;
      this.scheduledTime[transportIndex].splice(rowIndex, 1);
      Object.keys(element).forEach((e: any) => {
        if (element[e] !== '') {
          this.isOriginRequired[transportIndex] = true;
        }

      })
    });

    this.checkifOriginDestiisEmpty()
    setTimeout(() => {
      this.checkQTY(transportIndex, rowIndex)
    }, 1);

  }


  closePopup(initClose:boolean = false) {
    this.selectedDeskParameter = '';
    this.deskNumber = '';
    this.cpService?.createNominationFromCargoWithReference?.next({ initiate: false, data: {} });
    this.createCcaNominationForm.reset({
      nominationNo: '',
      cargoName: '',
      transportSystem: '',
      vesselName: '',
      transport: this.fb.array([this.createFormGroupTransport(null)]),
      comments: '',
    });
    while (this.transportArray()?.controls?.length !== 0) {
      this.transportArray().removeAt(0);
    }
    this.createCcaNominationForm.get('isoriginDestiTouched')?.setErrors({ 'invalid': true });
    this.createCcaNominationForm.get('isTransportpicked')?.setErrors({ 'invalid': true });
    this.nominationService.nomSelected$.next(false);
    this.transportArray().push(this.createFormGroupTransport(null))
    this.isMultipleRowTransport = 0
    this.demandProductList = [[]]
    this.transportDescArray = []
  this.locationOriginArray = [[]]
  this.deletedValue=false;
  this.locationDestiArray = [[]]
  this.demandProductOrigin = [[]]
  this.vesselArray=[[]]
  this.deletelist = []
  this.vesselNameValue="";
  this.terminalData=[[]]
  this.isBarge=false;
  this.demandProductDesti = [[]]
  this.destinationRDIDescSelected = [[]];
  this.originRDIDescSelected = [[]];
  this.destinationRDISelected = [[]];
  this.originRDISelected = [[]];
  this.isMultipleRowItemDestination = [['D']];
  this.isMultipleRowItemOrigin = [['O']];
  this.scheduledTime = [['00:00']]
  this.originMovementsValue = [0]
  this.destinMovementsValue = [0]
  this.scheduledTimeDesti = [['00:00']]
  this.contractLineItemsOrigin = [[]];
  this.contractLineItemsDestination = [[]];
  this.refContractLineItems = [[]];
  this.refContractLineItemsDesti = [[]];
  this.refContractLineItemsIte =[[]];
  this.uomDestination=[[]];
  this.uomOrigin=[[]];
  this.refDesCount=0;
  this.refOrginCount=0;
  this.originArray=[];
  this.destinationArray=[];
  this.getLocation = "";
  this.qtyDiff = 0;
  this.getMaterial = ""
  this.originQtyValue = [0]
  this.destinQtyValue = [0]
  this.getScheduledDate = ""
  this.productList = []
  this.terminalList = [[]]
  this.terminalListDesc = [[]]
  this.uomListOrigin= [[]];
  this.uomListDesc= [[]];
  this.resetEntry(false)
  this.initialValues();
  this.closeModalPopup.emit(initClose);
  this.nominationService.ccaEditNomination = false
  this.nominationService.isNomEditorRefCca=false;
  this.isToggleChecked = false;
  this.relatedData = {};
  
  if(this.router.url === "/cargoPlanning") {
    let deskObj = latamDesks.find((res:any)=>{return (res.deskName?.toLowerCase() === this.selectedDeskParameter?.toLowerCase() || res.deskId === this.selectedDeskId)});
    const navigateToDesk: any = {
      deskValue: deskObj?.deskValue,
      deskParameter: deskObj?.deskName,
      id: deskObj?.deskId,
      screenName:'closenominationpopupincargoscreen'
    }
    this.store.dispatch(reInitiateFiltersForCCANomLinking({ reInitiateFilters: true, deskFilterValue: navigateToDesk }));
  }
}

  resetEntry(str: boolean) {
    this.locationOriginArray = [[]]
    this.defaultTransportSystem = null
    this.locationDestiArray = [[]]
    this.demandProductOrigin = [[]]
    this.vesselArray = [[]]
    this.portOptions = [[]]
    this.terminalData = [[]]
    this.portccaDesti = [[]]
    this.demandProductDesti = [[]]
    this.destinationRDIDescSelected = [[]];
    this.originRDIDescSelected = [[]];
    this.destinationRDISelected = [[]];
    this.originRDISelected = [[]];
    this.scheduledTime = [['00:00']]
    this.scheduledTimeDesti = [['00:00']]
    this.contractLineItemsOrigin = [[]];
    this.contractLineItemsDestination = [[]];
    this.refContractLineItems = [[]];
    this.refContractLineItemsDesti = [[]];
    this.refContractLineItemsIte = [[]];
    this.getLocation = "";
    this.getMaterial = ""
    this.getScheduledDate = ""
    if (!str) {
      this.productList = []
      this.productListMaterialCode = [];
      this.transportSystemArray = []
      this.defaultProduct = [[]]
      this.defaultProductdesti = [[]]
      this.defaultProductMaterialCode = [[]]
      this.defaultProductdestiMaterialCode = [[]]
      this.demandProductOriginMaterialCode = [[]]
      this.demandProductDestiMaterialCode = [[]]
    }

  }

  setList(list: any, item: any) {
    list.itemId = (this.nominationService.ccaEditNomination || this.isDraftData === 'draft') ? item.itemId : null;
    list.udmItemStatus = Nom.INPROCESS;
    list.scheduledDate = item.scheduledDate.replaceAll('-', '');
    list.scheduledQty = item.scheduledQty;
    list.tswStatus = item.nominationItemStatus; 
    list.locationName = this.getLocationName(item.location) || "";
    list.locationId = item.location;
    list.scheduledUom = item.Uom;
    list.tswStatus = item.nominationItemStatus; 
    list.scheduledMaterial = this.getDemandMaterialID(item.scheduledProduct);
    list.demandMaterial = this.getDemandMaterialID(item.demandProduct);
    list.textLine = `{Author:${this.userDetails},MdfBy:${this.userDetails},SS:UDM}`;
    list.contractPartner = item.counterParty === "" ? " " : item.counterParty;
    list.udmDisableValidations = true;
    list.confirmationIndicator = this.utilities.getConfirmationIndicatorValue(item,this.nominationService.ccaEditNomination );
    list.nominationReferenceDocument = item?.sapcontract // set it as per multiple or single selected
    list.nominationReferenceDocumentItem = typeof item?.contractLineNo === 'string' && item?.contractLineNo.includes("|") ? item?.contractLineNo.split('|')[0].split(' ')[0] : item?.contractLineNo;
    list.batchOriginLocation = item?.batchOriginLocation;
    list.valuationTypeOriginLoc = item?.valuationTypeOriginLoc;
    list.valuationTypeDestinationLoc = item?.valuationTypeDestinationLoc;
    list.batchDestinationLocation = item?.batchDestinationLocation;
    let udmKeys = Object.keys(nomPop);
    if (this.nominationService.isNomEditorRefCca) {
      udmKeys.forEach(key => {
        if (key.includes(this.selectedDeskRefCca?.toLowerCase())){
          list.udmDesk = nomPop[key];
        }
      });
    } else {
      udmKeys.forEach(key => {
        if (this.selectedDeskParameter === key) {
          list.udmDesk = nomPop[key]; 
        } 
      });
    }
    list.scheduledMaterialDesc = item.scheduledProduct;
    list.demandMaterialDesc = item.demandProduct;
    return list
  }
  getDemandMaterialID(demandProduct: any) {
    let product = this.masterProductList.find((element: any) => element.productName === demandProduct);
    return product?.productCode;
  }

  getScreenName() {
    let screenName;
    switch (this.router.url) {
      case "/replenishmentPlanning":
        screenName = "Replenishment Planning";
        break;
      case "/nominaions":
        screenName = "Nominations";
        break;
      case "/cargoPlanning":
        screenName = "Cargo Planning";
        break;
      default:
        screenName = "Replenishment Planning";
    }
    return screenName;
  }

  createNomination(requestBodyArray: any) {
    const screenName = this.getScreenName();
    this.myMonitoringService.logEvent('Nomination creation clicked', {category: screenName});
    this.nominationService.createCcaNomination(requestBodyArray).subscribe({
      next: (response) => {
        this.inValidDestinationLocation = false;
        this.invalidDestinationLocationsg = '';
        if (response.success) {
          this.cargoLinkData.nomId = response.result[0]?.Id;
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: `nomination has been created`,
          });
          if(this.router.url === "/cargoPlanning") {
            this.nominationService.passLinkNomId$.next(this.cargoLinkData);
          }
        }
        if (this.router.url === "/replenishmentPlanning" && this.selectedDeskParameter !== 'panamadesk') {
          this.nominationService.isTriggeredFromNomModalCCAPopup$.next(true);
          this.store.dispatch(savePipelineTRFiltersUpdated({ isPipelineFilterUpdated: true }));
        }
        else if(this.router.url === "/nominations" && this.selectedDeskParameter !== 'panamadesk'){
          setTimeout(() => {
            this.store.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: true }));
          }, 5000);
        }
        else if(this.selectedDeskParameter === 'panamadesk'){
          setTimeout(() => {
           this.callGridApi.emit();
          }, 5000);
        }
        this.closePopup()
        this.closeModalPopup.emit(response);
      },
      error: (err) => {
        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err;
        this.toasterService.setToastNotification({
          show: true,
          type: 'error',
          msg: err.message,
        });
      },
      complete: () => {
        this.selectDropdown?.resetInput();

      },
    });
  }

  fetchId(el: any) {
    let id = null;
    if (el?.origin || el?.destination) {
      if (el?.origin[0]?.id && typeof (el?.origin[0]?.id) !== 'number') {
        id = el?.origin[0]?.id;
      }
      if (el?.destination[0]?.id && typeof (el?.destination[0]?.id) !== 'number') {
        id = el?.destination[0]?.id;
      }
    }
    return id;
  }

  onFormSubmit() {
      const transportSystemControl = this.createCcaNominationForm.get('transportSystem');
      let arr: any = []
      this.nominationData = JSON.parse(JSON.stringify(this.createCcaNominationForm.value));
      this.nominationData.transport.forEach((el: any) => {
        let id: any;
        if (this.nominationService.ccaEditNomination || this.isDraftData === 'draft') {
          id = this.originDataId || this.destinationDataId;
        } else {
          id = this.idVal();
        }
        let list: any = {}
        let vesselData = this.getVesselInfo(this.nominationData.vesselName);
        let ele: any = this.deskParamsCreated(id, vesselData);
          el.origin.forEach((item: any) => {
            if (item.rdi) { // check if origin has value then only add
              list.referenceDocumentIndicator = item.rdi;
              list.scheduleType = 'O';
              this.setList(list, item)
              let cloneObject = { ...list };
              ele.headerToItemNav.push(cloneObject)
            }
          })

          el.destination.forEach((item: any) => {
            if (item.key) { // check if desti has value then only add
              list.referenceDocumentIndicator = item.key
              list.scheduleType = 'D';
              this.setList(list, item)
              let cloneObject = { ...list };
              ele.headerToItemNav.push(cloneObject)
            }
          })
        arr.push(ele);
      });
      //If we delete individual line item(origin row or destination row)
      this.updatePayloadForLineItemDeleted(arr);
      const requestBodyArray = arr;
      if (this.nominationService.ccaEditNomination || this.isDraftData === 'draft') {
        this.updateAPI(requestBodyArray);
      } else {
        this.createNomination(requestBodyArray);
    }
  }

  updatePayloadForLineItemDeleted(arr:any){
    if (this.deletedValue) {
      arr.forEach((a:any)=>{
        this.deletelist.forEach((e: any) => {
          if(e.parentId === a.id){
            delete e.parentId;
            a.headerToItemNav.push(e);
          }
          this.deletedValue = false
        })
      })
    }
  }

  idVal() {
    let id;
    if(this.createNomFromDraftId) {
      id = this.createNomFromDraftId;
    } else if (this.nominationData?.id) {
      id = this.nominationData?.id;
    } else if (this.referenceNominations?.id) {
      id = this.referenceNominations?.id;
    } else {
      id = null
    }
    return id
  }

  deskParamsCreated(id: any, vesselData: any) {
    let udmKeys = Object.keys(nomPop);
    if (this.nominationService.isNomEditorRefCca) {
      udmKeys.forEach(key => {
        if (key.includes(this.selectedDeskRefCca?.toLowerCase())){
          this.deskNumber = nomPop[key];
        }
      });
    }
    else{
      udmKeys.forEach(key => {
        if (this.selectedDeskParameter === key) {
          this.deskNumber = nomPop[key];
        }
      });
    }
    let ele = {
      "id": id,
      "modeOfTransport": "S",
      "modeOfTransportDesc": this.scenario === 'barge' ? "Barge" : "Vessel",
      "transportSystem": this.nominationData?.transportSystem,
      "nominationNo": this.nominationData?.nominationNo?.toUpperCase(),
      "textLine": "",
      "tdVehNo": vesselData?.vesselId,
      "tdVehIdentifier": this.scenario === 'barge' ? "B" : "S",
      "udmModeoftransportDesc": this.scenario === 'barge' ? "Barge" : "Vessel",
      "sys_status": Nom.INPROCESS,
      "udmDesk": this.deskNumber,
      "vehicleName": this.nominationData?.vesselName,
      "vehicleCode": vesselData?.vesselId,
      "headerToItemNav": [],
      "deskFilter": "ccadesk"
    }
    return ele;
  }

  callTdVehNo(vesselData: any) {
    return ((this.nomCargoFieldObj.bargeFields || this.nomCargoFieldObj.vesselFields)) ? vesselData?.vesselId : ""
  }
  getVesselInfo(vesselName: string) {
    let vessel = this.masterVesselArray?.find((element: any) => element.vesselName === vesselName);
    return vessel;
  }

  getLocationName(locationId: any) {
    let location = this.masterTerminalListForCp.find((element: any) => element.terminalCode === locationId);
    return location?.terminalName;
  }

  submitForm() {
    // if ((this.trNominationForPipeline?.screenName?.toLowerCase() === 'side-panel-tr' && this.trNominationForPipeline?.sys_status?.toLowerCase() === Nom.DRAFT)) {
    //   this.submitDraftNominationFromPipeline()
    // } else {
    this.onFormSubmit()
  }

  updateAPI(requestBodyArray: any) {
    const screenName = this.getScreenName();
    this.myMonitoringService.logEvent('Nomination updation clicked', {category: screenName});
    this.nominationService.updateNomination(requestBodyArray).subscribe({
      next: (response) => {
        this.inValidDestinationLocation = false;
        this.invalidDestinationLocationsg = '';
        if (response.success) {
          this.toasterService.setToastNotification({
            show: true,
            type: 'success',
            msg: `nomination has been updated`,
          });
        }
        if (this.router.url === "/replenishmentPlanning" && this.selectedDeskParameter !== 'panamadesk' ) {
          this.nominationService.isTriggeredFromNomModalCCAPopup$.next(true);
          this.store.dispatch(savePipelineTRFiltersUpdated({ isPipelineFilterUpdated: true }));
        }
        else if(this.router.url === "/nominations" && this.selectedDeskParameter !== 'panamadesk'){
          setTimeout(() => {
            this.store.dispatch(savePipelineNomFiltersUpdated({ isPipelineNomFilterUpdated: true }));
          }, 5000);
        }
        else if(this.selectedDeskParameter === 'panamadesk'){
          setTimeout(() => {
            this.callGridApi.emit();
          }, 5000);
        }
        this.nominationService.ccaEditNomination = false
        this.deletelist = []
        this.closePopup()
        this.closeModalPopup.emit(response);
      },
      error: (err) => {
        this.inValidDestinationLocation = true;
        this.invalidDestinationLocationsg = err;
      },
      complete: () => {
        this.selectDropdown?.resetInput();

      },
    });
  }

  @Input() relatedData: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['relatedData'] && changes['relatedData'].currentValue) {
      this.loadDropdowns();
      setTimeout(() => {
        this.createNominationFromReferenceForPanamaCCA(changes['relatedData'].currentValue)
      },200);
    }
  }

}
